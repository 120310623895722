import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { HashRouter, Route, Switch, useLocation } from 'react-router-dom'
import * as Actions  from '../../Actions';
import * as comMod from '../../commonModule';
import * as mui from '../common/materialUi';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import SnackMsg from './SnackMsg';
import {ResetPassWd} from '../../component/account/Account';
import teal from '@material-ui/core/colors/teal';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { faLess } from '@fortawesome/free-brands-svg-icons';
import { fetchAll } from '../../albCommonModule';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '40ch',
    },
    '& .MuiSelect-root' : {
      width: '40ch',
    },
    '& .displayOff' :{
      display:'none',
    }
  },
  formControl: {
    margin: theme.spacing(1),
  },
  certificating: {
    paddingTop: '10vh',fontSize: '1.4rem',color: teal[500], textAlign:'center',
  },
  chkBox: {width: '100%', marginBottom: 16, textAlign: 'center',},
}));


// albcm にも同盟のモジュールあり
// ログイン動作のときはこちらが使われていると思われる
// albcmに統一してこちらは廃止予定
export const _fetchAll = (hid, bid, dispatch, )=>{
  // 当月の取得
  let thisDate = new Date();
  thisDate.setDate(1);
  // クッキー取得
  const cookeiStdDate = comMod.getCookeis('stdDate');
  const stdDate = (cookeiStdDate)? 
  cookeiStdDate : comMod.formatDate(thisDate, 'YYYY-MM-DD');
  dispatch(Actions.setStdDate(stdDate));

  // ユーザー設定の読み込み
  const ui = 'ui';
  const ck = (comMod.getCookeis(ui))? comMod.getCookeis(ui): '{}';
  const cUiVals = JSON.parse(ck);
  const uiVals = {};
  Object.keys(cUiVals).map(e=>{
    uiVals[e] = (cUiVals[e] === '1')? true: false;
  });
  dispatch(Actions.setStore({controleMode:{ui: uiVals}}));


  let prms = {
    a: 'companybrunchM', //company and branch
    hid: hid,
    bid: bid,
    date: stdDate,
  }
  // 法人情報の取得
  dispatch(Actions.fetchCom(prms));

  // アカウントが形成されているか確認
  // 出来ていなかったらアカウントのオブジェクトを作成
  // if (Object.keys(account).length === 0){
  // }

  // 送信状態の取得
  prms = {
    hid: hid,
    bid: bid,
    date: stdDate,
    reg: 1,
    a: 'listSent',
  }
  dispatch(Actions.fetchTransfer(prms));

  prms = {
    a: 'lu',  // リストユーザー指定
    hid: hid,
    bid: bid,
    date: stdDate,
    // service: this.props.service,
  };
  // if (prms.service === '') delete prms.service;
  // ユーザーリストの取得
  dispatch(Actions.listUsers(prms));

  // カレンダーの取得
  prms = {
    date: stdDate,
    stdDate,
    hid: hid,
    bid: bid,
    a: 'fetchCalender',
  }
  dispatch(Actions.fetchCalender(prms));
  // スケジュールの取得
  prms = { ...prms, a: 'fetchSchedule' }
  dispatch(Actions.fetchSchedule(prms));
  // 売上データの初期化
  dispatch(Actions.setStore({billingDt: {}}));    
}

const LoginMain = ()=>{
  const [mail, setmail] = useState('');
  const [pass, setpass] = useState('');
  const [selectAcount, setselectAcount] = useState();
  const [disabledation, setdisabledation] = useState({mail:null});
  // クッキー取得により自動ログインするかどうか
  let cookieAutoLogin = comMod.getCookeis('autoLogin');
  if (cookieAutoLogin === '1')  cookieAutoLogin = true;
  else if (cookieAutoLogin === undefined)  cookieAutoLogin = false;
  else if (cookieAutoLogin === '0')  cookieAutoLogin = false;
  const [autoLogin, setAutoLogin] = useState(cookieAutoLogin);

  const classes = useStyles();
  const dispatch = useDispatch();
  const accountLst = useSelector(state => state.accountLst);
  // stateのアカウント監視用
  const saccount = useSelector(state => state.account);
  // const saccount = useSelector(state => state.account);
  const fetchAccountStatus = useSelector(state => state.fetchAccountStatus);
  const sessionStatus = useSelector(state => state.sessionStatus);
  // const session = useSelector(state => state.session);
  // const comFtc = useSelector(state => state.comFtc);
  useEffect(()=>{
    const ss = sessionStatus;
    const as = fetchAccountStatus;
    if (!ss.loading && !ss.done && !ss.err 
      && !as.loading && !as.done && !as.err && autoLogin
    ){
      dispatch(Actions.makeAcountByKey());

    }
  }, [sessionStatus, fetchAccountStatus, ]);
  
  const SelectAcountOpt = ()=>{
    const lst = accountLst.map((e, i)=>{
      return (
        <option key={i} value={i}>
          {e.sbname + ' ' + e.lname + ' ' + e.fname + 'さん'}
        </option>
      );
    });
    return lst;
  }
  
  const handleFormChenage =()=>{
    const form = document.getElementById('dghrt573');
    const elms = form.elements;
    setmail(elms['sometext'].value);
    setpass(elms['passinput'].value);
    setselectAcount(elms['selectAcount'].value);
  }
  // バリデーションはblurで
  // 関数は一括して発火元のname属性で処理を判断する
  const handlBlur=(e)=>{
    const target = e.currentTarget;
    const name = target.getAttribute('name');
    if (name === 'mail'){
      setdisabledation({mail: comMod.isMailAddress(mail)});
    }
  }

  // ログインクリック
  const logInClick=()=>{
    const prms = {
      a:'getAccountByPw',
      mail:mail,
      passwd:pass,
    }
    dispatch(Actions.fetchAccount(prms));
  }
  // ログインキャンセル
  const cancelLogin =()=>{
    setmail('');
    setpass('');
    // あとから法人情報などのクリアも実施すること
    dispatch(Actions.clearAcount());
  }
  // アカウント選択クリック
  const selectedClick =()=>{
    const elms = document.getElementById('dghrt573').elements;
    const selected = elms['selectAcount'].value;
    const key = new Date().getTime();

    if (selected === ''){
      dispatch(Actions.setSnackMsg(
        'アカウントを選択してください。', 'warning'
      ))
      return false;
    }
    // ステイトのアカウントに値をセット
    const account = accountLst[parseInt(selected)];
    // アカウントリストからアカウントにセット
    dispatch(Actions.setAcount(account));
    // セッションを作成。認証キーを送信。
    const prms = {
      hid: account.hid,
      bid: account.bid,
      mail: account.mail,
      key: comMod.randomStr(8),
    }
    dispatch(Actions.sendNewKey(prms));

    const cookeiStdDate = comMod.getCookeis('stdDate');
    const t = new Date();
    t.setDate(1);
    const stdDate = (cookeiStdDate)? 
    cookeiStdDate : comMod.formatDate(t, 'YYYY-MM-DD');
    dispatch(Actions.setStdDate(stdDate));

    // 色々と取得
    fetchAll({stdDate, hid: account.hid, bid: account.bid, dispatch, account});
  }

  // ログインと事業所選択で表示を切り替える
  const beforeLoginDisp = 
    (fetchAccountStatus.loginResult === true)?'displayOff':'';
  const afterLoginDisp = 
    (fetchAccountStatus.loginResult !== true)?'displayOff':'';

  useEffect(()=>{
    const v = autoLogin? 1: 0;
    comMod.setCookeis('autoLogin', v);
    // return (()=>{
    //   })
  }, [autoLogin])
  const handleChkChange = (ev) => {
    if (autoLogin){
      setAutoLogin(false);
    }
    else{
      setAutoLogin(true);
    }
  }
  return(
    <>
    <form 
      className={classes.root + ' loginFrom'} /*nodisabledate */
      id = "dghrt573"
      autoComplete="off"
    >
      {/* <img src={`${window.location.origin}/img/logoMarkW800.png`}/> */}
      <img src={`/img/logoMarkW800.png`}/>
      <div className={beforeLoginDisp}>
          
        {fetchAccountStatus.loading === true &&
          <div className={classes.certificating}>アカウント確認中</div>
        }
        {
          (
            fetchAccountStatus.loading === false &&
            fetchAccountStatus.loginResult !== false && 
            disabledation.mail !== false
          ) &&
          <TextField 
            required
            id="loginMail"
            name="sometext"
            label="メールアドレス"
            value={mail}
            autoComplete="off"
            // defaultValue=""
            onBlur={(e)=>handlBlur(e)}
            onChange={handleFormChenage}
          />
        }
        {
          (
            fetchAccountStatus.loading === false &&
            fetchAccountStatus.loginResult !== false && 
            disabledation.mail === false
          ) &&
          <TextField 
            required
            error
            id="loginMail"
            name="sometext"
            label="メールアドレス"
            value={mail}
            autoComplete="off"
            // defaultValue=""
            onBlur={(e)=>handlBlur(e)}
            helpertext="メールアドレスが正しくありません。"
            onChange={handleFormChenage}
          />
        }
        { (
            fetchAccountStatus.loading === false &&
            fetchAccountStatus.loginResult === false && 
            disabledation.mail !== false
          ) &&
          <TextField 
            required
            error
            id="loginMail"
            name="sometext"
            label="メールアドレス"
            value={mail}
            autoComplete="off"
            // defaultValue=""
            onBlur={(e)=>handlBlur(e)}
            helpertext="メールアドレスまたはパスワードが違います。"
            onChange={handleFormChenage}
          />
        }
        
      </div>
      <div className={beforeLoginDisp}>
        {
          (
            fetchAccountStatus.loginResult !== false &&
            fetchAccountStatus.loading === false
          ) &&

          <TextField 
            required 
            type="password"          
            id="loginPass" 
            name="passinput"
            label="パスワード"
            value={pass}
            autoComplete="off"
            // defaultValue=""
            onChange={handleFormChenage}
          />
        }
        {
          (
            fetchAccountStatus.loginResult === false &&
            fetchAccountStatus.loading === false 
        
          ) &&
          <TextField 
            required 
            error
            type="password"       
            id="loginPass" 
            name="passinput"
            autoComplete="off"
            label="パスワード"
            value={pass}
            // defaultValue=""
            onChange={handleFormChenage}
          />
        }
      </div>
            
      {fetchAccountStatus.loading === false && <>
        <div className={beforeLoginDisp + ' ' + classes.chkBox}>
          <FormControlLabel
            control={
              <Checkbox
                checked={(autoLogin)}
                onChange={(ev)=>handleChkChange(ev)}
                name='autoLogin'
                color="primary"
              />
            }
            label='ログイン状態を維持する'
          />

        </div>
        <div className={beforeLoginDisp + ' buttonWrapper'}>
          <mui.ButtonGP
            color='secondary'
            label='キャンセル'
            onClick={cancelLogin}
          />
          <mui.ButtonGP
            color='primary'
            label='ログイン'
            onClick={logInClick}
          />
        </div>

        <div className={"acountSelectWrapper " + afterLoginDisp}>
        <FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor="age-native-label-placeholder">
            アカウントを選択してください。
          </InputLabel>
          <Select
            name='selectAcount'
            native 
            value = {selectAcount}
            label = ''
            onChange = {handleFormChenage}
          >
            <option value="">未選択</option>
            <SelectAcountOpt/>
          </Select>
        </FormControl>
      </div>
      <div className={afterLoginDisp + ' buttonWrapper'}>
        <mui.ButtonGP
          color='secondary'
          label='キャンセル'
          onClick={cancelLogin}
        />
        <mui.ButtonGP
          color='primary'
          label='アカウント選択'
          onClick={selectedClick}
        />
      </div>
    
      </>}
    </form>
    <SnackMsg storeStateOpen={true}/>
    </>
  )
}

const Login = () => {
  return(
    <HashRouter>
      <Switch>
        <Route path = '/restpassword' component={ResetPassWd} />
        <Route path = '/' component={LoginMain} />
      </Switch>
    </HashRouter>
  )

}

export default Login;
