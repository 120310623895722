import React, { useEffect, useState } from 'react';
import * as Actions from '../../Actions';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as comMod from '../../commonModule';
import * as albcm from '../../albCommonModule';
import { LoadingSpinner , LoadErr} from '../common/commonParts';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import teal from '@material-ui/core/colors/teal';
import lightBlue from '@material-ui/core/colors/lightBlue';


const useStyles = makeStyles({
  buttonWrapper: {
    display:'flex', padding: 8, justifyContent:'flex-end',
    '& .oneButtonWrap': {
      marginLeft: 16,
    }
  },
});

const ClenderTitle = (props)=>{
  const first = props.dateList[0].date;
  const titleMonth = comMod.formatDate(first,'YYYY年MM月');
  return (<div className="cldTitle">{titleMonth}</div>)
}

const WeekDays = ()=>{
  const wday = ['日','月','火','水','木','金','土',];
  const rt = wday.map((e, i)=>{
    return(<div key={i}>{e}</div>);
  });
  return rt;
}

const makeDaysGrid = (dateList) =>{
  const monthGrid = [];
  const week = [];
  dateList.map(e=>{
    week.push(e); // これで大丈夫？？？
    if (e.date.getDay() == 6){
      monthGrid.push(week.concat());
      week.length = 0; // 配列を空にする
    }
  });
  if (week.length)  monthGrid.push(week.concat()); // 最後の一週
  // 先頭の一行、配列の長さが7になるまで空白を挿入
  while (monthGrid[0].length < 7) monthGrid[0].unshift('');
  // 最後の一行。長さを揃える
  const lastWeek = monthGrid[monthGrid.length - 1];
  const filler = Array(7 - lastWeek.length).fill('');
  lastWeek.push(...filler);
  return (monthGrid);
}

const Days = (props) =>{
  const dispatch = useDispatch();
  const path = useLocation().pathname;
  const {dateLocal, setDateLocal, setHolidayTarget, holidayTarget} = props;
  const [daysGrid, setDaysGrid] = useState([]);
  const oneDayClick = (dayobj)=>{
    // e.preventDefault();
    console.log(dayobj);
    let holidayMode = holidayTarget;
    // if (holidayMode === undefined)  holidayMode = 1;
    if (dayobj.holiday !== 0) holidayMode = 0;
    // props.calSetHoliday(dayobj.date, holidayMode);
    // comMod.setSchedleLastUpdate(dispatch, path);
    setDateLocal(()=>{
      const t = [...dateLocal];
      const p = t.findIndex(e=>e.date === dayobj.date);
      if (p > -1){
        t[p].holiday = holidayMode;
      }
      return t;
    });
  }
  // 日曜始まりになるように日付を一週間ごとの配列化
  // const Inner = props.children.CalenderInnerDateItem;
  // const daysGrid = makeDaysGrid(props.dateList);
  useEffect(()=>{
    setDaysGrid(makeDaysGrid(dateLocal));
  }, [dateLocal])
  console.log('daysGrid',daysGrid);
  const Week = (weeks) =>{
    const daysInWeek = weeks.weeks.map((elm, i)=>{
      let hClass = "";
      if (typeof elm == 'object'){
        if (elm.holiday === 1) hClass = 'schoolOff';
        else if (elm.holiday === 2) hClass = 'off';
        else if (elm.holiday === 0) hClass = 'weekday';
        return (
          <div 
            className={"oneDay " + hClass} key = {i}
            onClick={()=>oneDayClick(elm)}
          >
            {elm.date.getDate()}
          </div>
        )
      }
      else{
        return (
          <div key={i} className="oneDay"></div>
        )
      }
    });
    return (
      <div className="cldBodyRow cldDays">
        {daysInWeek}
      </div>
    )
  }
  const month = daysGrid.map((weeks, i)=>{
    return(<Week key={i} weeks={weeks}/>)
  });
  return month;
}

const Lower = (props) =>{
  const dispatch = useDispatch();
  const path = useLocation().pathname;
  const {holidayTarget, setHolidayTarget, dateLocal, setDateLocal} = props;
  const radioHandler = (v) => {
    setHolidayTarget(v);
    // props.calHolidaySetMode(v);
    // comMod.setSchedleLastUpdate(dispatch, path);
  }
  const clickHandler = (v) =>{
    // 2=休業日以外をv(1:休校日、0:平日)に変更する
    // props.calSetHolidayAll(v, 2);
    // comMod.setSchedleLastUpdate(dispatch, path);
    setDateLocal(()=>{
      const t = [...dateLocal];
      t.map(e=>{
        if (e.holiday !== 2){
          e.holiday = v;
        }
      });
      return t;
    });
  }
  // ラジオボタンのプロパティを格納する配列
  const radioDefault = ['','','',''];
  // if (props.controleMode.calHoliday === undefined){
  //   radioDefault[1] = 'checked';
  // }
  // else{
  //   radioDefault[props.controleMode.calHoliday] = 'checked';
  // }
  radioDefault[holidayTarget] = 'checked';

  return (<div className="clenderLower">
    <div className="controlewrapper">
      <label className={"radioWrapper " + radioDefault[1]}>
        <input 
          type="radio" value="2" name="offday" 
          checked={radioDefault[1]}
          onChange={()=>radioHandler(1)}
        />
        休校日
      </label>
      <label className={"radioWrapper " + radioDefault[2]}>
        <input 
          type="radio" value="4" name="offday" 
          checked={radioDefault[2]}
          onChange={()=>radioHandler(2)}
        />
        休業日
      </label>
      <span className={"radioWrapper"} onClick={()=>clickHandler(1)}>
        <span>全て休校日</span>
      </span>
      <span className={"radioWrapper"} onClick={() =>clickHandler(0)}>
        <span>全て平日</span>
      </span>
    </div>
  </div>)
}
const SchCalenderMain = (props) =>{
  const classes = useStyles();
  const dispatch = useDispatch();
  const hid = useSelector(state=>state.hid);
  const bid = useSelector(state=>state.bid);
  const stdDate = useSelector(state=>state.stdDate);
  const t = [...props.dateList];
  const [dateLocal, setDateLocal] = useState(t);
  // 日付をクリックしたときに休校日にするか休業日にするか
  const [holidayTarget, setHolidayTarget] = useState(1);
  const prms = {holidayTarget, setHolidayTarget, dateLocal, setDateLocal};
  const [res, setRes] = useState({data: false});
  useEffect(()=>{
    if (res.data.result){
      dispatch(Actions.setStore({dateList: dateLocal}));
      dispatch(Actions.setSnackMsg('カレンダーを送信しました。'));
    }
    else if (res.data.result === false){
      dispatch(Actions.setSnackMsg(
        'カレンダーを送信出来ませんでした', 'error', 'E32W33' 
      ));
    }
  }, [res]);
  const handleClick = (send) => {
    if (send){
      albcm.sendCalender(
        {hid, bid, date: stdDate, dateList: dateLocal}, setRes
      );
    }

  }
  return (<>
    <div className='cld'>
      <ClenderTitle {...props} />
      <div className='cldBody'>
        <div className='cldBodyRow cldWeekDayTitle'>
          <WeekDays />
        </div>
        <Days {...prms} />
      </div>
      <Lower {...prms} />
      <div className={classes.buttonWrapper}>
        <div className='oneButtonWrap'>
          <Button 
            variant="contained" color="secondary" 
            onClick={()=>handleClick(false)}
          >
            キャンセル
          </Button>
        </div>
        <div className='oneButtonWrap'>
          <Button 
            variant="contained" color="primary" 
            onClick={()=>handleClick(true)}
          >
            送信
          </Button>
        </div>
      </div>
    </div>
  </>)

}

class SchCalender extends React.Component{
  render(){
    const tp = this.props;
    const done = (
      tp.userFtc.done && tp.fetchCalenderStatus.done
    );
    const errorOccured = (
      tp.userFtc.err || tp.fetchCalenderStatus.err
    );
    const loading = (
      tp.userFtc.loading || tp.fetchCalenderStatus.loading
    )
    if (done) {
      return (<SchCalenderMain {...this.props} />)
    }
    else if (errorOccured) {
      return (<div>error occured.</div>)
    }
    else {
      return <LoadingSpinner />
    }

  }
}
function mapStateToProps(state) {
  return (state);
}
export default connect(mapStateToProps, Actions)(SchCalender);
