import React, {useEffect, useState} from 'react';
import * as Actions from '../../Actions';
import { connect, useDispatch, useSelector } from 'react-redux';
// import SchHeadNav from './SchHeadNav';
import SchTableHead from './SchTableHead';
import SchTableBody from './SchTableBody';
import SchTableBody2 from './SchTableBody2';
import * as comMod from '../../commonModule';
import { 
  SnapberAlert, /*FabAddEdit,*/ /* RadioScheduleEdit */
} from '../common/materialUi';
import * as mui from '../common/materialUi';
import {LoadingSpinner, LoadErr, StdErrorDisplay} from '../common/commonParts';
import SimpleModal from '../common/modal.sample';
import SchDailyDialog from './SchDailyDialog';
import SimpleDialogDemo from '../../DrowerMenu'
import SchSaveLater from './SchSaveLater';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Link, useLocation } from 'react-router-dom';
import {LinksTab} from '../common/commonParts';
const useStyles = makeStyles({
  linktabRoot: {
    marginTop: 47,
    '& > .MuiButton-text': {
      // margin: theme.spacing(1),
      padding: 0,
    },
  },
});
export const menu = [
  // { link: "/schedule", label: "月間" },
  { link: "/schedule/", label: "月間" },
  { link: "/schedule/dsetting/", label: "利用者別日別設定" },
  { link: "/schedule/weekly/", label: "週間" },
  { link: "/schedule/weekly/transfer/", label: "送迎" },
  { link: "/schedule/users/", label: "利用者別" },
  // { link: "/schedule/users2/", label: "利用者別B" },
  { link: "/schedule/calender/", label: "休校・休日設定" },
]

const ScheduleMain = (props) => {
  const dispatch = useDispatch();
  const [localFabSch, setLocalFabSch] = useState(0);
  const favSchProps = {localFabSch, setLocalFabSch};
  const users = useSelector(state=>state.users);
  const schedule = useSelector(state=>state.schedule);
  const dateList = useSelector(state=>state.dateList);
  const stdDate = useSelector(state=>state.stdDate);
  const hid = useSelector(state=>state.hid);
  const bid = useSelector(state=>state.bid);
  // 日付別利用者数を出すためのstateを定義
  const tmpCountsOfUSe = {};
  const a = Array(dateList.length).fill(0);
  users.map(e=>{tmpCountsOfUSe['UID' + e.uid] = [...a];});
  const [countsOfUse, setCountsOfUse] = useState(tmpCountsOfUSe);
  // 日付別の操作を行う前にストアにdispatchする必要がある。そのためのフラグ
  // const [schStoreDispatch, setSchStoreDispatch] = useState(false);
  const tb2Props = {
    localFabSch, setLocalFabSch, countsOfUse, setCountsOfUse, 
  }
  const service = useSelector(state=>state.service);
  
  // サービスの設定済みでスケジュールの中身がない場合スケジュールの中身を初期設定する
  // usersの加算内容をscheduleに転記する
  useEffect(()=>{
    if (service && !schedule[service]){
      const p = {dateList, stdDate, schedule, hid, bid, users, dispatch }
      comMod.addUsersAddictionToSch(p);
    }
  }, [schedule, service]);

  return (<>
    <LinksTab menu={menu} />
    <div className='AppPage schedule fixed'>
      <SchTableHead {...tb2Props}/>
    </div>
    <div className='AppPage schedule scroll'>
      <SchTableBody2 {...tb2Props} />
      <SnapberAlert />
      <mui.FabSchedule {...favSchProps}/>
    </div>
  </>)

}

const Sch2 = () => {
  const dispatch = useDispatch();
  const allstate = useSelector(state=>state);
  const loadingStatus = comMod.getLodingStatus(allstate);
  const com = useSelector(state=>state.com);
  const users = useSelector(state=>state.users);
  // サービス未設定の場合
  const service = useSelector(state=>state.service);
  const serviceItems = useSelector(state=>state.serviceItems);
  // useEffect(()=>{
  //   if (!service){
  //     dispatch(Actions.changeService(serviceItems[0]));
  //   }  
  // }, [service])
  const comAdic = comMod.findDeepPath(com, ['addiction', service]);  
  
  // 基本設定項目の確認
  if (loadingStatus.loaded && !comAdic && service){
    console.log('E49412');
    return(
      <StdErrorDisplay 
        errorText = '請求設定項目が未設定です。'
        errorSubText = {`予定作成開始に必要な基本設定項目がありません。設定メニューの
        「請求・加算」から定員や地域区分などを設定して下さい。`}
        errorId = 'E49412'
      />

    )
  }
  else if (loadingStatus.loaded && !users.length){
    console.log('E49413');
    return(
      <StdErrorDisplay 
        errorText = '利用者が未登録です。'
        errorSubText = {`利用者の登録をしてから予定の登録を行って下さい。`}
        errorId = 'E49413'
      />
    )
  }
  else if (loadingStatus.loaded){
    return(
      <ScheduleMain />
    )
  }
  else if (loadingStatus.error){
    return (
      <LoadErr loadStatus={loadingStatus} errorId={'E4941'} />
    )
  }
  else{
    return <LoadingSpinner/>
  }
}

export default Sch2;
