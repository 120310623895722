import React, {useEffect, useState, useRef} from 'react';
import { HashRouter, Route, Switch, useLocation, useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector, } from 'react-redux';
import * as comMod from '../../commonModule';
import * as albcm from '../../albCommonModule';
import * as Actions from '../../Actions';
import * as mui from './materialUi';
import Rev from '../../Rev';
import SchIntervalSave from '../schedule/SchIntervalSave';
import DrowerMenu from '../../DrowerMenu';
import { red } from '@material-ui/core/colors';
import { Button, IconButton, makeStyles } from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';
import { useStyles } from './FormPartsCommon';
import { SendBillingToSomeState } from './commonParts';

const useStylesLocal = makeStyles({
  fetchAllButton: {
    '& .MuiButton-root': {fontSize: '.7rem', marginLeft: 8},
    '& .MuiIconButton-root': {color: '#fff', padding: 6, marginLeft: 8},
    '& .MuiSvgIcon-root': {fontSize: '1.3rem'},
  },
  updateLink: {
    display: 'flex', justifyContent: 'center', alignItems: 'center',
    color: '#fff', fontSize: '.7rem', paddingLeft: 8, paddingTop: 4,
  },
});

// state serviceItemsの設定をusesから行う
// nullしか返さないComponent
// serviceItemsの 更新がある時だけディスパッチを行う
// 比較は配列の長さだけという雑なもの
// -> 2021-10-13 雑ではダメっぽい
const SetServiceItems = () => {
  const users = useSelector(state=>state.users);
  const userFtc = useSelector(state=>state.userFtc);
  const serviceItems = useSelector(state=>state.serviceItems);
  const serviceItemsInit = useSelector(state=>state.serviceItemsInit);
  const ref = useLocation().pathname;
  const dispatch = useDispatch();
  // 発火はuserFtcとrefで行う。usersで発火させると頻繁に動きすぎるため
  useEffect(()=>{
    const svcSet = new Set();
    if (userFtc.done){
      users.map(e=>{svcSet.add(e.service)});
      // usersを検出できたら初期化済みフラグをセットする
      if (!serviceItemsInit){ // 初期化済みのときはなんどもやらない！ 2021/11/03
        dispatch(Actions.setStore({serviceItemsInit: true}))
      }
      const newItems = Array.from(svcSet);
      // 比較を厳密に行うようにした
      if (comMod.compareArrays(newItems, serviceItems).length){
        // 何もなかったら心配なので放デイ入れとく
        // if (!newItems.length) newItems.push('放課後等デイサービス');
        dispatch(Actions.setStore({serviceItems:newItems}));
        // サービスアイテムが変更になったらサービスを初期化
        dispatch(Actions.setStore({service:""}));
      }
    };
  },[ref, userFtc]);
  return null;
}
// db名を取得する。db名にtestが含まれていたら表示を返す
const DbName = () =>{
  const dispatch = useDispatch();
  const allState = useSelector(state=>state);
  const ls = comMod.getLodingStatus(allState);
  const {controleMode} = allState;
  const [res, setRes] = useState({});
  useEffect(()=>{
    if (ls.loaded && !controleMode.dbname && !res.data){
      albcm.fetchDbname(setRes);
    }
  }, []);
  useEffect(()=>{
    if (ls.loaded && !controleMode.dbname && res.data){
      const t = {...controleMode};
      t.dbname = res.data;
      dispatch(Actions.setStore({controleMode: t}))
    }
  }, [res])
  console.log(res);
  const style = {padding: 8, paddingTop:12, color: red[200], fontSize: '.8rem'}
  if (!controleMode.dbname && !res.data){
    return (null);
  }
  else if (controleMode.dbname){
    if (controleMode.dbname.indexOf('test') > -1){
      return (
        <div style={style}>TEST DB</div>
      )
    }
    else return null;
  }
  else if (res.data.indexOf('test') > -1){
    return (
      <div style={style}>TEST DB</div>
    )
  }
  else{
    return (null);
  }
}
// const DbName = () =>{
//   const controleMode = useSelector(state=>state.controleMode);
//   const [res, setRes] = useState({});
//   const dispatch = useDispatch();
//   let fetchDbTimer;
//   useEffect(()=>{
//     clearTimeout(fetchDbTimer);
//     return () => {
//       const f = async () => {
//         if (!controleMode.dbname){
//           fetchDbTimer = setTimeout(() => {
//             albcm.fetchDbname(setRes);
//           }, 1000 * .5);
//         }
//       }
//       f();
//     }
//   }, [controleMode]);
//   useEffect(()=>{
//     if (res.data){
//       return () => {
//         const t = {...controleMode};
//         t.dbname = res.data;
//         dispatch(Actions.setStore({controleMode: t}));
//       }
//     }
//   }, [res])
//   console.log(res);
//   const style = {padding: 8, paddingTop:12, color: red[200], fontSize: '.8rem'}
//   if (!controleMode.dbname){
//     return (null);
//   }
//   else if (controleMode.dbname.indexOf('test') > -1){
//     return (
//       <div style={style}>TEST DB</div>
//     )
//   }
//   else{
//     return (null);
//   }
// }


const FetchAllButton = () => {
  const dispatch = useDispatch();
  const classes = useStylesLocal();
  const hid = useSelector(state=>state.hid);
  const bid = useSelector(state=>state.bid);
  const stdDate = useSelector(state=>state.stdDate);
  const weekDayDefaultSet = 
  useSelector(state => state.config.weekDayDefaultSet);
  const clickHandler = () =>{
    albcm.fetchAll({stdDate, hid, bid, weekDayDefaultSet, dispatch})
  }
  return (
    <div className={classes.fetchAllButton}>
      {/* <Button
        variant="contained"
        color="primary"
        // startIcon={<ReplayIcon />}
        onClick={clickHandler}
      >
        <ReplayIcon/>
        再読込
      </Button> */}
      <IconButton onClick={clickHandler}><ReplayIcon/></IconButton>

    </div>
  )

}

const Header = (props) => {
  const dispatch = useDispatch();
  const classes = useStylesLocal();
  const ref = useLocation().pathname;
  const hist = useHistory();
  const account = useSelector(state=>state.account);
  // サービス未指定を可能とするパスリストを列挙する
  // reportsは削除するべきじゃないの？ 2022/01/25 ->　様子見
  // const dispAllPath = ['/', '/proseed', '/reports', ];
  const dispAllPath = [
    '/', '/proseed', '/users/bankinfo', '/users/belongs',
    '/proseed/upperlimit', '/reports'
    // '/schedule/calender/',
    // '/schedule/users/',
    // '/schedule/weekly/transfer/',
    // '/schedule/weekly/',
    // '/schedule/',
    // '/schedule',
  ];
  const dispAll = dispAllPath.findIndex(_=>_ === ref) > -1;
  
  // サービス指定不可とするパス
  const notDispSeirvicePath = ['/billing', '/Account/ch', '/Account/'];
  const notDispService = notDispSeirvicePath.findIndex(_ => _ === ref) > -1;
  // 教室指定不可とするパスを列挙
  const notDispClassRoomPath = [
    '/billing', '/billing/upperlimit', '/proseed/upperlimit'
  ];
  const notDispClassRoom = notDispClassRoomPath.findIndex(_ => _ === ref) > -1;

  const allState = useSelector(state=>state);
  const loadingStatus = comMod.getLodingStatus(allState);

  let hname = '', bname = '';
  if (props.props.comFtc.done && !props.props.comFtc.err){
    hname = props.props.com.hname;
    bname = props.props.com.bname;
  }
  let dispName = '';
  if (props.props.fetchAccountStatus.done 
    && !props.props.fetchAccountStatus.err) 
  {
    dispName = props.props.account.sbname + ' ' + props.props.account.lname;
  }
  // 2021/08/27
  // パスワードログインのときしかスナック表示されなかったため追加
  // stateのアカウントが成立したら発火。
  // 通知したら通知済みのstateをdispatch
  useEffect(()=>{
    if (Object.keys(account).length && !account.noticeDone){
      const permissionName = comMod.getPermissionName(account);
      const text = 
        `${account.lname} ${account.fname}さんは${permissionName}です。`;
      const t = {...account, noticeDone: true};
      dispatch(Actions.setStore({account: t}));
      dispatch(Actions.setSnackMsg(text, ''));
    }
  }, [account])
  const accountClickHandler = () => {
    hist.push('/Account/ch');
  }
  return (
    <div className='headBar'>
      <div className='leftSide'>
        <DrowerMenu />
        <div className='titleWrapper'>
          <div className='title'>
            <a href = './'>
              <img 
                src={`${window.location.origin}/img/logoa_eee.svg`} 
                width="120px" alt="logo" />
            </a>
          </div>
        </div>
        <a 
          className={classes.updateLink} 
          href={'/?rev=' + comMod.randomStr(12)}
        >
          <Rev short/>  
        </a>
        {/* <SchIntervalSave /> */}
        <FetchAllButton/>
        <mui.MonthButtons />
        {notDispService === false &&
          <mui.ChangeService dispAll={dispAll} loadingStatus={loadingStatus} />
        }
        {notDispClassRoom === false &&
          <mui.ChangeClassRoom loadingStatus={loadingStatus}/>
        }
        <DbName/>
        <SendBillingToSomeState />
      </div>
      <div>
        {/* <mui.ButtonSave color="primary" /> */}
      </div>
      <a onClick={accountClickHandler} style={{color:'#fff'}}>
        <div className='comWrapper' >
          {/* <div className='com'>{hname}</div>
          <div className='branch'>{bname}</div> */}
          <div>{dispName} さん</div>
        </div>
      </a>
      <SetServiceItems />
    </div>
  )
}

export default Header;