import React, { useEffect, useState } from 'react';
import * as comMod from '../../commonModule'
import * as albcm from '../../albCommonModule'
import { useDispatch, useSelector } from 'react-redux';
import * as mui from '../common/materialUi';
import * as Actions from '../../Actions';
import * as sfp from '../common/StdFormParts';
import * as afp from '../common/AddictionFormParts';
import { makeStyles } from '@material-ui/core/styles';
import { setBillInfoToSch, } from '../Billing/blMakeData';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, FilledInput, IconButton } from '@material-ui/core';
import { blue, teal, red } from '@material-ui/core/colors';


// 上限管理の情報を入力する
// 自社が上限管理事業所の場合の他事業所の情報と利用学、負担額を入力する
// 自社が協力事業所の場合もこのコンポーネントを使うことにした
// 指定タイプ specifyType=1の場合、協力事業所として管理事業所を登録、
// 上限額を入力する
// 負担方法 自社優先 自社ができるだけ多く請求する
//          最多額優先 一番多く利用があったところができるだけ多く請求する
//          等分 利用額にあわせて按分する
const useStyles = makeStyles({
  brosInKyoudai: {
    display: 'flex',
    '& .name' :{ paddingTop:30, marginLeft:4, marginRight: 8,}
  },
  jiButtonsRoot: {
    marginTop: 12,
    '& .deleteIcon .MuiSvgIcon-root': {
      marginInlineStart: 40,
    },
    '& .trush .MuiSvgIcon-root': {color:red[300]}
  },
  deleteConfirm:{
    width: '100%', paddingLeft: 8,
    '& >span': {fontSize: '.9rem',background: red[50]},
    '& .yes .MuiButton-label': {color: red[800]},
    '& .no .MuiButton-label': {color: teal[800]},
  }, 
})
export const UpperLimitKanri = (props)=>{
  const classes = useStyles();
  const { uid, specifyType, snack, setSnack} = props;
  // const { uid, specifyType, } = props;
  const dispatch = useDispatch();
  const UID = comMod.convUID(uid).str;
  const hid = useSelector(state => state.hid);
  const bid = useSelector(state => state.bid);
  const users = useSelector(state=>state.users);
  const thisUser = comMod.getUser(uid, users);
  const schedule = useSelector(state => state.schedule);
  const service = useSelector(state=>state.service);
  const com = useSelector(state=>state.com);
  const serviceItems = useSelector(state=>state.serviceItems);
  const stdDate = useSelector(state=>state.stdDate);
  const [sendPrms, setSendPrms] = useState({}); // スケジュール分割送信用
  // 協力事業所削除用
  // [{ndx: 0, deleted: ture}] deletedがtrueで削除済み falseは削除確認中
  const [deleteJi, setDeleteJi] = useState([]);
  

  // specifyType=1の場合、協力事業所として操作を行うが
  // その場合の操作対象は管理事業所となる
  const objKey = (specifyType === '1') ? '管理事業所':'協力事業所';
  // sendPartOfSchのみ非同期処理なのでクリーンアップで処理を行う
  useEffect(()=>{
    const sendItems = async (v) => {
      let prms = {
        hid, bid, date: stdDate, 
        partOfSch: {...v.serviceAddic, ...v.usersSchedule}
      }
      const res = {sch: {}, users: {}};
      res.sch = await albcm.sendPartOfSchedule(prms, 'setRes', 'setSnack');
      if (v.newUser){
        prms = {...v.newUser}
        res.users = await albcm.sendUser(prms, 'setRes', 'setSnack');
        console.log('sendItems', res);
      }
      return res;
    }
    const dispatchItems = (v, res) => {
      // console.log('dispatch something.');
      let t = [...users];
      const i = t.findIndex(e=>parseInt(e.uid) === comMod.convUID(uid).num);
      if (v.newUser){
        t[i] = {...v.newUser};
      }
      const u = {...schedule, ...v.serviceAddic, ...v.usersSchedule};
      const p = {stdDate, schedule:u, users: t, com, service, serviceItems};
      const {
        billingDt, masterRec, result, errDetail
      } = setBillInfoToSch(p);
      dispatch(Actions.setStore({schedule: u, users: t, masterRec, billingDt}));
      // usersを送信していないパターンが有る
      const userRes = (res.users.data)? res.users.data.result: true;
      const r = res.sch.data.result && userRes;
      const severity = (r) ? '': 'error';
      const msg = (r) ? '情報を登録しました。' : '情報の登録に失敗しました。';
      const errorId  = (r) ? 'E544735': '';
      dispatch(Actions.setSnackMsg(msg, severity, errorId));
    }
    const makeData = () => {
      const {outPuts, fvals} = sendPrms;
      // サービスの取得
      const usersService = (thisUser.service)? thisUser.service: service;
      // サービスごとの加算オブジェクト
      schedule[usersService] = schedule[usersService]
      ? schedule[usersService]: {};

      schedule[usersService][UID] = schedule[usersService][UID]
      ? schedule[usersService][UID]: {};
      
      const serviceAddic = {
        [usersService]:{
          ...schedule[usersService],
          [UID]:{
            ...schedule[usersService][UID],
            addiction:{
              ...schedule[usersService][UID].addiction,
              利用者負担上限額管理加算: fvals.利用者負担上限額管理加算,
              上限管理結果: fvals.kanriKekka, // 2022/05/25 上限管理結果手動設定のため
            }
          }
        }
      };
      if (parseInt(specifyType) < 2){
        const usersSchedule = {
          [UID]:{...schedule[UID],[objKey]:[...outPuts],}
        };
        const newUser = {
          ...thisUser,
          etc:{...thisUser.etc, [objKey]: outPuts}
        }
        return {serviceAddic, usersSchedule, newUser};
      }
      else{
        return {serviceAddic, usersSchedule: null, newUser: null};
      }
    };
    // 兄弟ダイアログには他のユーザー情報も書かれているのでそれを
    // schedule[servicce][uid].addictionに配置する
    const makeBrosData = (rt, ) => {
      const {fvals} = sendPrms;
      const {serviceAddic} = rt;
      // 兄弟管理のときは兄弟の管理結果のselectのname属性がkanriKekka-[UID]で示される
      // それを拾ってスケジュールの加算オブジェクトに追加する
      Object.keys(fvals).filter(e=>(e.indexOf('kanriKekka-') === 0)).map(e=>{
        const bUid = 'UID' + e.split('-')[1];
        const bUser = comMod.getUser(bUid, users);
        const bService = bUser.service;
        const sch = {...schedule}
        sch[bService] = sch[bService]? sch[bService]: {};
        sch[bService][bUid] = sch[bService][bUid]? sch[bService][bUid]: {};
        sch[bService][bUid].addiction = 
        sch[bService][bUid].addiction? sch[bService][bUid].addiction: {};
        const t = {...sch[bService][bUid].addiction};
        t.上限管理結果 = fvals[e];
        if (!serviceAddic[bService]) serviceAddic[bService] = {};
        if (!serviceAddic[bService][bUid]) 
          serviceAddic[bService][bUid] = {};
        serviceAddic[bService][bUid].addiction = {
          ...serviceAddic[bService][bUid].addiction , ...t
        };
      });
      rt.serviceAddic = serviceAddic;
    }
    return (()=>{
      setTimeout(()=>{
        // node消失確認
        const closed = !document.querySelector('#nodeExist0743e34');
        const prmsExist = (Object.keys(sendPrms).length > 0);
        if (closed && prmsExist){
          const rt = makeData();
          makeBrosData(rt);
          console.log('UpperLimitKanri cleanup.', rt);
          sendItems(rt)
          .then(res=>{
            dispatchItems(rt, res);
          })
        }
      }, 100);
    })
  }, [sendPrms]);
  // submitHandlerより変更
  // データの更新とダイアログのクロースを行う
  // storeへのdispatchと送信はuseeffect cleanupへ移行
  const updateClickHandler = () => {
    // 値が必要なエレメント
    const inputs = document.querySelectorAll('#ttydo55 input');
    const selects = document.querySelectorAll('#ttydo55 select');
    // フォームの値を取得
    const fvals = comMod.getFormDatas([inputs, selects], true);
    // 兄弟の管理結果を取得のために未設定のデータも取得
    const tmpBrosKanri = comMod.getFormDatas([selects], true, true);
    Object.keys(tmpBrosKanri).forEach(e=>{
      if (e.indexOf('kanriKekka-') === 0){
        fvals[e] = tmpBrosKanri[e];
      }
    });
    // それぞれバラバラのnameタグをグループ化するための配列
    const namesTags = [
      [
        'office1', 'no1', 'amount1', 'kettei', 
        '上限管理配分種別', 'kanriKekka',
      ], 
      ['office2', 'no2', 'amount2'], 
      ['office3', 'no3', 'amount3'], 
      ['office4', 'no4', 'amount4'], 
      ['office5', 'no5', 'amount5'], 
      ['office6', 'no6', 'amount6'], 
      ['office7', 'no7', 'amount7'], 
      ['office8', 'no8', 'amount8'], 
      ['office9', 'no9', 'amount9'], 
    ];
    // dispatch用の配列
    const outPuts = namesTags.map(e=>{
      if (fvals[e[0]]){ // 事業所名に記述がある場合
        return{
          name: fvals[e[0]],
          no: fvals[e[1]],
          amount: fvals[e[2]], // amountというキーをstoreに書き込めない！
          kettei: fvals[e[3]], // 協力事業所のときのみ有効
          haibun: fvals[e[4]], // 協力事業所のときのみ有効
          kanriKekka: fvals[e[5]], // 協力事業所のときのみ有効
        }
      }
    }).filter(e=>e !== undefined);
    // 同一の事業所番号が配列に含まれていないかチェック
    const tmp = outPuts.map(e=>e.no);
    const dup = tmp.filter((e, i, a)=>a.indexOf(e) !== a.lastIndexOf(e));
    if (dup.length){
      setSnack({
        msg: '事業所番号が重複しているため登録できません。',
        severity: 'warning',
      })
      return false;
    };
    // 事業所名記載済み事業所番号未記載の場合はエラー扱い
    const jinoSet = new Set(outPuts.filter(e=>e.no).map(e=>e.no));
    const jiNameSet = new Set(outPuts.filter(e=>e.name).map(e=>e.name));
    if (jinoSet.size !== jiNameSet.size){
      setSnack({
        msg: '事業所名または事業所番号未記載があるため保存できません。',
        severity: 'warning',
      })
      return false;
    }
    setSendPrms({outPuts, fvals});
    props.close()
  }
  const canselHandler = ()=>{
    console.log('cancel clicked.')
    props.close();
  }
  // Scheduleに記載されている値を優先して読み込む。
  // 管理事業者の場合、複数の協力事業所を読み込むので何番目の事業所を見るかは
  // iで指定する
  const getFormDef = (i)=>{
    const ts = comMod.findDeepPath(schedule, [UID, objKey]);
    let rts;
    if (!ts) rts = ['', '', '', ''];
    else{
      const vs = ts[i];
      rts = (vs !== undefined) ? 
        [vs.name, vs.no, vs.amount, vs.kettei] : ['', '', '', ''];
    }
    const tu = comMod.findDeepPath(thisUser, ['etc', objKey]);
    let rtu;
    if (!tu) rtu = (['', '', '', '']);
    else{
      const vu = tu[i];
      // ユーザーから値を返すときは金額情報は返さない
      rtu = (vu !== undefined) ?
        [vu.name, vu.no, '', ''] : ['', '', '', ''];
    }
    // rtsに値が入っていればそちらを返す。なければuserから取得した値を
    const rt = (rts[0])? rts : rtu;
    return rt;
  }
  // OtherOfficeは子コンポーネント側でstate管理されているがこちらの
  // コンポーネントでも使う必要が出てきたので改めてstateとして定義
  const [officeNames, setOfficeNames] = useState([
    getFormDef(0)[0],getFormDef(1)[0],getFormDef(2)[0],getFormDef(3)[0],
  ]);

  // const haibunDef = comMod.findDeepPath(thisUser, 'etc.上限管理配分種別');
  // const kanriDef = comMod.findDeepPath(thisUser, 'etc.上限管理結果');
  // 管理事業所利用者は上限管理結果をschedule[service][uid]以下に記述する
  // 協力時魚利用者は管理事業所配列内に記述されている
  const kanriKekkaDef = (ev, buid) =>{
    const brosIndex = parseInt(thisUser.brosIndex);
    let svc = thisUser.service;
    if (thisUser.kanri_type == '管理事業所' || brosIndex === 1){
      // buid:兄弟のuidが指定されていたらそれで検索する
      let tuid = buid? buid: UID;
      tuid = albcm.convUid(tuid).s;
      if (buid){
        svc = comMod.getUser(buid, users).service;
      }
      const v = comMod.findDeepPath(
        schedule, [svc, tuid, 'addiction', '上限管理結果']
      );
      return v;
    }
    const v = comMod.findDeepPath(schedule, [UID, '管理事業所']);
    console.log('v', v);
    // ここはオブエジェクトで帰ってくるので変な処理
    const w = (v && Object.keys(v).length)? v['0'].kanriKekka : '';
    return (w) ? w : '';
  }
  const jiButtonClick = (ev, func, ndx) => {
    // func: -1=up, 1=down 0=delete
    // 値が入力されているノードを取得
    const names = document.querySelectorAll('#ttydo55 .name input');
    const nos = document.querySelectorAll('#ttydo55 .no input');
    const vols = document.querySelectorAll('#ttydo55 .volume input');
    // ノードの数を取得
    const nodesLen = names.length;
    const namenode = names[ndx];
    const volnode = vols[ndx];
    const nonode = nos[ndx];
    const nameTarget = names[ndx + func]
    const volTarget = vols[ndx + func]
    const noTarget = nos[ndx + func]
    // 最上段でup
    if (func === -1 && ndx === 0){
      return false;
    }
    // 最下段でdawn
    if (func === 1 && ndx === nodesLen){
      return false;
    }
    if (func === 0){
      const t = [...deleteJi];
      const p = t.findIndex(e=>e.ndx === ndx);
      if (p >= 0){
        // t.deleted = true;
        t.splice(p, 1)
      }
      else{
        t.push({ndx, deleted: false})
      }
      setDeleteJi(t);
      return false;
    }
    // 未入力の場合は処理しない
    if (!namenode.value){
      return false;
    }
    // ターゲットが未入力の場合
    if (!nameTarget.value){
      return false;
    }
    // 値を複写
    const current = {
      jiname: namenode.value, 
      jino: nonode.value, 
      vol: volnode.value,
    }
    const target = {
      jiname: nameTarget.value, 
      jino: noTarget.value, 
      vol: volTarget.value,
    }
    // 値をスワップ
    namenode.value = target.jiname;
    nonode.value = target.jino;
    volnode.value = target.vol;
    nameTarget.value = current.jiname;
    volTarget.value = current.vol;
    noTarget.value = current.jino;
  }
  const JiButtons = (props) => {
    const classes = useStyles();
    const {ndx} = props;
    return(
      <div className={classes.jiButtonsRoot}>
        <IconButton 
          onClick={(ev)=>jiButtonClick(ev, -1, ndx)}
          tabIndex={-1} color='primary'
        >
          <ArrowUpwardIcon/>
        </IconButton>
        <IconButton 
          onClick={(ev)=>jiButtonClick(ev, 1, ndx)}
          tabIndex={-1} color='primary'
        >
          <ArrowDownwardIcon/>
        </IconButton>
        <span style={{width: 40, display:'inline-block'}}></span>
        <IconButton 
          className='trush'
          onClick={(ev)=>jiButtonClick(ev, 0, ndx)}
          tabIndex={-1}
        >
          <DeleteIcon/>
        </IconButton>
      </div>
    )
  }
  // 事業所削除のときはdeleteJiに値が入る deleted = falseで削除確認中

  const DeleteConfirm = (props) => {
    const {ndx} = props;
    const t = [...deleteJi];
    const existDeleteJi = t.find(f=>f.ndx === ndx);
    if (!existDeleteJi) return null;
    const handleCleck = (ev, v) => {
      const p = t.findIndex(e=>e.ndx === ndx);
      // はいをクリック
      if (v){
        t[p].deleted = true;
        setDeleteJi(t);
      }
      // いいえ
      else{
        t.splice(p, 1);
        setDeleteJi(t);
      }
    }
    return (
      <div className={classes.deleteConfirm}>
        <span>この事業所を削除しますか？</span>
        <Button 
          onClick={(ev)=>handleCleck(ev, 1)} className='yes'
        >
          はい
        </Button>
        <Button 
          onClick={(ev)=>handleCleck(ev, 0)} className='no'
        >
          いいえ
        </Button>
      </div>
    )
  }
  const KanriFrormParts = () => {
    let p = 0;
    const jigyousyoRow = Array(6).fill(0).map((e, i) => {
      // 該当ノードが削除済みであるかどうか
      const existDeleteJi = deleteJi.find(f=>f.ndx === i);
      if (existDeleteJi && existDeleteJi.deleted)  return null;
      return (
        <div className="cntRow" key={i} >
          <sfp.OtherOffice
            label={'事業所名' + (i + 1)} name={'office' + (i + 1)}
            labelNo={'番号' + (i + 1)} nameNo={'no' + (i + 1)}
            def={getFormDef(i)}
            officeNames={officeNames} setOfficeNames={setOfficeNames} ndx = {i}
          />
          <sfp.NumInputGP 
            name={'amount' + (i + 1)} label={'利用総額' + (i + 1)} 
            def={getFormDef(i)[2]}
            required={officeNames[i] !== ''}
          />
          <JiButtons ndx={i}/>
          <DeleteConfirm ndx={i} />
        </div>
      )    
    });
  
    return (<>
      {jigyousyoRow}
    {/* <div className="cntRow">
      <sfp.OtherOffice
        label='事業所名1' name='office1'
        labelNo='番号1' nameNo='no1'
        def={getFormDef(0)}
        officeNames={officeNames} setOfficeNames={setOfficeNames} ndx = {0}
      />
      <sfp.NumInputGP 
        name='amount1' label='利用総額1' def={getFormDef(0)[2]}
        required={officeNames[0] !== ''}
      />
      <JiButtons ndx={0}/>
    </div>
    <div className="cntRow">
      <sfp.OtherOffice
        label='事業所名2' name='office2'
        labelNo='番号2' nameNo='no2'
        def={getFormDef(1)}
        officeNames={officeNames} setOfficeNames={setOfficeNames} ndx = {1}
      />
      <sfp.NumInputGP 
        name='amount2' label='利用総額2' def={getFormDef(1)[2]} 
        required={officeNames[1] !== ''}
      />
      <JiButtons ndx={1}/>
    </div>
    <div className="cntRow">
      <sfp.OtherOffice
        label='事業所名3' name='office3'
        labelNo='番号3' nameNo='no3'
        def={getFormDef(2)}
        officeNames={officeNames} setOfficeNames={setOfficeNames} ndx = {2}
      />
      <sfp.NumInputGP 
        name='amount3' label='利用総額3' def={getFormDef(2)[2]} 
        required={officeNames[2] !== ''}
      />
      <JiButtons ndx={2}/>
    </div>
    <div className="cntRow">
      <sfp.OtherOffice
        label='事業所名4' name='office4'
        labelNo='番号4' nameNo='no4'
        def={getFormDef(3)}
        officeNames={officeNames} setOfficeNames={setOfficeNames} ndx = {3}
      />
      <sfp.NumInputGP 
        name='amount4' label='利用総額4' def={getFormDef(3)[2]} 
        required={officeNames[3] !== ''}
      />
      <JiButtons ndx={3}/>
    </div>
    <div className="cntRow">
      <sfp.OtherOffice
        label='事業所名5' name='office5'
        labelNo='番号5' nameNo='no5'
        def={getFormDef(4)}
        officeNames={officeNames} setOfficeNames={setOfficeNames} ndx = {3}
      />
      <sfp.NumInputGP 
        name='amount5' label='利用総額5' def={getFormDef(4)[2]} 
        required={officeNames[3] !== ''}
      />
      <JiButtons ndx={4}/>
    </div>
    <div className="cntRow">
      <sfp.OtherOffice
        label='事業所名6' name='office6'
        labelNo='番号6' nameNo='no6'
        def={getFormDef(5)}
        officeNames={officeNames} setOfficeNames={setOfficeNames} ndx = {3}
      />
      <sfp.NumInputGP 
        name='amount6' label='利用総額6' def={getFormDef(5)[2]} 
        required={officeNames[3] !== ''}
      />
      <JiButtons ndx={5}/>
    </div> */}
      <div className="cntRow">
        {/* <sfp.HaibunSyubetu def={haibunDef }/> */}
        <div style={{paddingTop: 4, paddingLeft: 0,}}>
          <afp.JougenKanri uid={UID} size='middle' dLayer={1} />
        </div>
        <sfp.KanriKekka kanri def={ev=>kanriKekkaDef(ev)} />
      </div>
    </>)
  }
  const KyoudaiFormParts = () => {
    const bros = comMod.getBrothers(uid, users);
    bros.sort((a, b)=>(a.brosIndex < b.brosIndex)? -1: 1);
    const brosKanrikekka = bros.map((e, i)=>{
      return (
        <div className={classes.brosInKyoudai}>
          <div className='name'>{e.name}</div>
          <sfp.KanriKekka kyoudai 
            name={'kanriKekka-' + e.uid} 
            def={ev=>kanriKekkaDef(ev, e.uid)} 
          />
        </div>
      );
    });
    return (<>
      <div className="cntRow">
        <div style={{paddingTop: 4, paddingLeft: 0,}}>
          <afp.JougenKanri uid={UID} size='middle' dLayer={1} />
        </div>
        <sfp.KanriKekka kyoudai def={ev=>kanriKekkaDef(ev)} />
      </div>
      <div className='cntRow'>
        {brosKanrikekka}
      </div>
    </>)

  }

  const KyouryokuFrormParts = () => (<>
    <div className="cntRow">
      <sfp.OtherOffice
        label='事業所名' name='office1'
        labelNo='番号' nameNo='no1'
        def={getFormDef(0)}
        officeNames={officeNames} setOfficeNames={setOfficeNames} ndx = {0}

      />
      {/* <sfp.NumInputGP name='amount1' label='利用総額' def={getFormDef(1)[2]} /> */}
    </div>
    <div className="cntRow">
      <sfp.NumInputGP 
        name='kettei' label='決定請求額' 
        def={getFormDef(0)[3]} 
      />
      <sfp.KanriKekka def={ev=>kanriKekkaDef(ev)}/>
    </div>
  </>)
  const formStyle = {maxWidth: 700};
  return(<>
    <form id = "ttydo55" className="dialogForm" style={formStyle}>
      {parseInt(specifyType) === 0 && <KanriFrormParts />}
      {parseInt(specifyType) === 1 && <KyouryokuFrormParts />}
      {parseInt(specifyType) === 2 && <KyoudaiFormParts />}
    </form>
    <div className="buttonWrapper">
      <mui.ButtonCancel onClick={() => canselHandler()} />
      <mui.ButtonOK onClick={(e) => updateClickHandler(e)} />
    </div>
    <div id = 'nodeExist0743e34'></div>

  </>)
}