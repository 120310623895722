// commmonModuleが肥大化しているので。
// アルバトロスでしか使わないモジュールはこちらに移す方向
import Cookies from 'js-cookie';
import * as Actions from './Actions';
import axios from 'axios';
// import {endPoint} from './Rev';
import * as cmd from './commonModule'
import { TrendingUp } from '@material-ui/icons';
import { faBullseye, faSleigh } from '@fortawesome/free-solid-svg-icons';

export const defaultTitle = 'Albatross 放課後デイ業務支援';

// stateのstdDateを更新するため、次月、前月などを示す文字列を返す
// setの分、月をオフセットする
const getNewMonth = (stdDate, set)=>{
  const m = new Date(
    stdDate.split('-')[0], stdDate.split('-')[1] - 1,1
  );
  m.setMonth(m.getMonth() + set);
  return (cmd.formatDate(m ,'YYYY-MM-DD'));
}


// 配列化された請求データを確認する
// サービスコードを検索したときのエラーを検出する
export const chekBillingDt = (bdt) => {
  const rtn = {result:true, done:false, detail:[]};
  const a = cmd.typeOf(bdt);
  if (cmd.typeOf(bdt) !== 'array') return {result:false, done:false, detail:[]};
  bdt.map(e=>{
    rtn.done = true;
    const itemTotal = e.itemTotal;
    // エラー検出
    itemTotal.filter(f=>f.err).map(f=>{
      rtn.result = false;
      rtn.detail.push({
        name: e.name,
        content: f,
      });
    });
  });
  return rtn;
}

// すべてロードする手続きをまとめるよ。なんで今までなかったの？
export const fetchAll = (prms) => {
  const {stdDate, hid, bid, weekDayDefaultSet, dispatch, } = prms;
  let sendPrms = {};
  sendPrms = {
    a: 'companybrunchM', //company and branch
    hid: hid,
    bid: bid,
    date: stdDate,
  }
  // 法人情報の取得
  dispatch(Actions.fetchCom(sendPrms));
  
  // 伝送状態の更新
  sendPrms = {
    hid: hid,
    bid: bid,
    date: stdDate,
    reg: 1,
    a: 'listSent',
  }
  dispatch(Actions.fetchTransfer(sendPrms));
  // csv db登録のリセット
  dispatch(Actions.resetTransfer());

  // ユーザーリストの取得
  sendPrms = {
    a: 'lu',  // リストユーザー指定
    hid,
    bid,
    date: stdDate,
    // service,
  };
  dispatch(Actions.listUsers(sendPrms));
  // カレンダーの取得
  sendPrms = {
    date: stdDate,
    hid,
    bid,
    weekDayDefaultSet,
    a: 'fetchCalender',
  }
  dispatch(Actions.fetchCalender(sendPrms));
  // スケジュールの取得
  sendPrms = {
    date: stdDate,
    stdDate,
    hid,
    bid,
    a: 'fetchSchedule',
  }
  dispatch(Actions.fetchSchedule(sendPrms));
  // リセットするものをリセット
  dispatch(Actions.setStore({
    billingDt: "", masterRec: "",
    nextUsers: undefined, nextCom: undefined
  }));
  // 追加しておく
  dispatch(Actions.setStdDate(stdDate));

}

// 月のセットを行う
// stdDateに対して set = 1 で次月 -1 で前月、0で当月
export const setMonth = (prms) => {
  const {set, stdDate, hid, bid, weekDayDefaultSet, dispatch, } = prms;
  let sendPrms = {};
  const curMonth = cmd.formatDate(new Date(), 'YYYY-MM-01');
  const newStdDate = (set === 0)? curMonth: getNewMonth(stdDate, set);
  const newPrms = {...prms, stdDate: newStdDate};
  fetchAll(newPrms);
  // 基準日の変更
  dispatch(Actions.setStdDate(newStdDate));
  // クッキーをセット
  cmd.setCookeis('stdDate', newStdDate);
}

// ドキュメントタイトルなどの文字列に使う用
export const getReportTitle = (allState, str='') => {
  const prefix = (allState.com.fprefix)? allState.com.fprefix + '-': '';
  const month = allState.stdDate.slice(0, 7).replace('-', '');
  const service = allState.service;
  const classroom = allState.classroom;
  const svc = cmd.shortWord(service);
  const cls = cmd.shortWord(classroom);
  let attrStr = '';
  // 両方指定がある、またはいずれか指定がある
  if (service || classroom) attrStr = '[' + svc + cls + ']';
  if (service && classroom) attrStr = '[' + svc + ' ' + cls + ']';
  const rt = prefix + month + attrStr + ' ' + str;
  return rt;
}

// ユーザー別の予定書き込み
// ここで関数にしちゃうよ
// store Dispatch型のスナックバーの表示はしない
// 代わりにスナックバーのセッターを受けたら表示することにする
export const sendUsersSchedule = async (
  prms, setRes='', setSnack='', userName = ''
) => {
  let response;
  // prms = {hid, bid, date, uid, schedule, } uidはUIDXX方式 dateはstdDate
  prms.a = 'sendUsersSchedule';
  prms.schedule = JSON.stringify(prms.schedule);
  try{
    response = await axios.post(endPoint(), cmd.uPrms(prms));
    if (response.status !== 200)  throw response;
    if (!response.data) throw response;
    if ((typeof setRes) === 'function'){
      setRes(response);
    }
    if ((typeof setSnack) === 'function'){
      const id = new Date().getTime();
      const msg = userName? 
      userName + 'さんの予定を送信しました。': '利用者の予定を送信しました。'
      setSnack({msg, id})
    }
    return(response);
  }
  catch(e){
    console.log(e);
    response.data = false;
    const errorId = '2E34435';
    if ((typeof setRes) === 'function'){
      setRes(response);
    }
    if ((typeof setSnack) === 'function'){
      const msg = userName? 
      userName + 'さんの予定で送信エラーが発生しました。': 
      '利用者の予定を送信エラーが発生しました。'
      setSnack({msg, severity:'error', errorId});
    }
    console.log('sendUsersScheduleErr', response);
    console.log(errorId)
    return(response);
  }
}

// スケジュールの一部更新
export const sendPartOfSchedule = async (
  prms, setRes='', setSnack='', msg = '', errMsg=''
) => {
  let response;
  // prms = {hid, bid, date, partOfSch, } partOfSchはscheduleを一部切り出したもの
  prms.a = 'sendPartOfSchedule';
  prms.partOfSch = JSON.stringify(prms.partOfSch);
  try{
    response = await axios.post(endPoint(), cmd.uPrms(prms));
    if (response.status !== 200)  throw response;
    if (!response.data) throw response;
    if (!response.data.result)  throw response;
    if ((typeof setRes) === 'function'){
      setRes(response);
    }
    if ((typeof setSnack) === 'function'){
      const id = new Date().getTime();
      if (!msg) msg = '予定を更新しました。';
      setSnack({msg, severity: ''})
    }
    console.log('sendPartOfScheduleNorm', response.data.affected_rows);
    return(response);

  }
  catch(e){
    console.log(e);
    response.data = false;
    if ((typeof setRes) === 'function'){
      setRes(response);
    }
    if ((typeof setSnack) === 'function'){
      if (!errMsg) errMsg = '予定を更新できませんでした。今月のスケジュールの' +
      '初期化が完了していないとこのエラーが発生することがあります。' +
      '一度スケジュールの画面を表示して初期化を完了して下さい。';
      setSnack({msg: errMsg, severity:'error', errorId:'2E3446'});
    }
    console.log('2E3445', 'errorId')
    console.log('sendPartOfScheduleErr', response)
    return(response);
  }
}

// カレンダーの送信
export const sendCalender = async (
  prms, setRes='', setSnack='', msg = '', errMsg=''
) => {
  let response;
  // prms = {hid, bid, date, dateList, }
  prms.a = 'sendCalender';
  // json化による時差対策
  const newList = prms.dateList.map(e => {
    return ({
      date: cmd.formatDate(e.date, 'YYYY-MM-DD'),
      holiday: e.holiday,
    });
  });
  prms.dateList = JSON.stringify(newList);
  try{
    response = await axios.post(endPoint(), cmd.uPrms(prms));
    if (response.status !== 200)  throw response;
    if (!response.data) throw response;
    if (!response.data.result)  throw response;
    if ((typeof setRes) === 'function'){
      setRes(response);
    }
    if ((typeof setSnack) === 'function'){
      const id = new Date().getTime();
      if (!msg) msg = 'カレンダーを送信しました。';
      setSnack({msg})
    }
    console.log('sendCalenderNorm', response.data.affected_rows);
    return(response);

  }
  catch(e){
    console.log(e);
    response.data = false;
    if ((typeof setRes) === 'function'){
      setRes(response);
    }
    if ((typeof setSnack) === 'function'){
      if (!errMsg) errMsg = 'カレンダーを送信できませんでした。';
      setSnack({msg: errMsg, severity:'error', errorId:'2C3398'});
    }
    console.log('errId', '2C3398')
    console.log('sendCalenderErr', response)
    return(response);
  }
}

// ユーザー情報の更新
// sendUserWithEtcのapiを使う
export const sendUser = async (
  prms, setRes='', setSnack='', msg = '', errMsg=''
) => {
  let response;
  // prms = {...user } ユーザーstate一人分
  prms.a = 'sendUserWithEtc';
  prms.etc = JSON.stringify(prms.etc); // json化
  try{
    response = await axios.post(endPoint(), cmd.uPrms(prms));
    if (response.status !== 200)  throw response;
    if (!response.data) throw response;
    if (!response.data.result)  throw response;
    if ((typeof setRes) === 'function'){
      setRes(response);
    }
    if ((typeof setSnack) === 'function'){
      const id = new Date().getTime();
      if (!msg) msg = `${prms.name}さんの情報を送信しました。`;
      setSnack({msg})
    }
    console.log('sendUserWithEtcNorm', response.data.affected_rows);
    return(response);
  }
  catch(e){
    console.log(e);
    response.data = false;
    if ((typeof setRes) === 'function'){
      setRes(response);
    }
    if ((typeof setSnack) === 'function'){
      if (!errMsg) errMsg = `${prms.name}さんの情報を送信出来ませんでした。`;
      setSnack({msg: errMsg, severity:'error', errorId:'2C3392'});
    }
    console.log('errorId', '2C3392');
    console.log('sendUserWithEtcErr', response)
    return(response);
  }
}



// stateとかの何らかのデータオブジェクトをDBにまるっと送信
export const genFKdatas = async (
  prms, setRes='', setSnack='', msg = '', errMsg=''
) => {
  let response;
  // prms = {jina, date, item, prefix, format, encode, target, rnddir } 
  prms.a = 'genFKdatas';
  try{
    response = await axios.post(endPoint(), cmd.uPrms(prms));
    if (response.status !== 200)  throw response;
    if (!response.data) throw response;
    if (!response.data.fname)  throw response;
    if ((typeof setRes) === 'function'){
      setRes(response);
    }
    if ((typeof setSnack) === 'function'){
      const id = new Date().getTime();
      if (!msg) msg = 'データを取得しました。';
      setSnack({msg})
    }
    return(response);
  }
  catch(e){
    console.log(e);
    response.data = false;
    if ((typeof setRes) === 'function'){
      setRes(response);
    }
    if ((typeof setSnack) === 'function'){
      if (!errMsg) errMsg = 'データを取得できませんでした。' + 
      'インターネットの接続を確認してください。' + 
      'このメッセージが複数回表示されるときは管理者またはサポートに連絡してください。';
      setSnack({msg: errMsg, severity:'error', errorId:'2E5567'});
    }
    console.log('errorId', '2E5567');
    return(response);
  }
}
// パスで指定した外部ファイルを
export const transferFile = async (
  path, dir, setRes='', setSnack='', msg = '', errMsg=''
) => {

}

// stateとかの何らかのデータオブジェクトをDBにまるっと送信
export const sendSomeState = async (
  prms, setRes='', setSnack='', msg = '', errMsg=''
) => {
  let response;
  // prms = {hid, bid, date, jino, item, state, } 
  prms.a = 'sendSomeState';
  prms.partOfSch = JSON.stringify(prms.partOfSch);
  try{
    response = await axios.post(endPoint(), cmd.uPrms(prms));
    if (response.status !== 200)  throw response;
    if (!response.data) throw response;
    if (!response.data.result)  throw response;
    if ((typeof setRes) === 'function'){
      setRes(response);
    }
    if ((typeof setSnack) === 'function'){
      const id = new Date().getTime();
      if (!msg) msg = 'データを送信しました。';
      setSnack({msg})
    }
    return(response);
  }
  catch(e){
    console.log(e);
    response.data = false;
    if ((typeof setRes) === 'function'){
      setRes(response);
    }
    if ((typeof setSnack) === 'function'){
      if (!errMsg) errMsg = 'データを送信できませんでした。' +
      'インターネット接続を見直して再度実行して下さい。' +
      '再びこのメッセージが表示されるときは管理者またはサポートまでご連絡をお願いします。';
      setSnack({msg: errMsg, severity:'error', errorId:'2E5543'});
    }
    console.log('errorId','2E5543')
    return(response);
  }
}

// dbに格納されているstateを取得
export const fetchSomeState = async (
  prms, setRes='', setSnack='', msg = '', errMsg=''
) => {
  let response;
  // prms = {jino, date, item, } 
  prms.a = 'fetchSomeState';
  try{
    response = await axios.post(endPoint(), cmd.uPrms(prms));
    if (response.status !== 200)  throw response;
    if (!response.data) throw response;
    if (!response.data.result)  throw response;
    if ((typeof setRes) === 'function'){
      setRes(response);
    }
    if ((typeof setSnack) === 'function'){
      const id = new Date().getTime();
      if (!msg) msg = 'データを受信しました。';
      setSnack({msg})
    }
    return(response);
  }
  catch(e){
    console.log(e);
    response.data = false;
    if ((typeof setRes) === 'function'){
      setRes(response);
    }
    if ((typeof setSnack) === 'function'){
      if (!errMsg) errMsg = 'データを受信できませんでした。' +
      'インターネット接続を見直して再度実行して下さい。' +
      '再びこのメッセージが表示されるときは管理者またはサポートまでご連絡をお願いします。';
      setSnack({msg: errMsg, severity:'error', errorId:'2E5543'});
    }
    console.log('errorId','2E5543')
    return(response);
  }
}

// 接続されているdb名を取得
export const fetchDbname = async (
  setRes='', setSnack='', msg = '', errMsg=''
) => {
  let response;
  // prms = {jino, date, item, } 
  const prms = {a: 'getDbname'};
  try{
    response = await axios.post(endPoint(), cmd.uPrms(prms));
    if (response.status !== 200)  throw response;
    if (!response.data) throw response;
    // if (!response.data.result)  throw response;
    if ((typeof setRes) === 'function'){
      setRes(response);
    }
    if ((typeof setSnack) === 'function'){
      const id = new Date().getTime();
      if (!msg) msg = 'データを受信しました。';
      setSnack({msg})
    }
    return(response);
  }
  catch(e){
    console.log(e);
    response.data = false;
    if ((typeof setRes) === 'function'){
      setRes(response);
    }
    if ((typeof setSnack) === 'function'){
      if (!errMsg) errMsg = 'データを受信できませんでした。' +
      'インターネット接続を見直して再度実行して下さい。' +
      '再びこのメッセージが表示されるときは管理者またはサポートまでご連絡をお願いします。';
      setSnack({msg: errMsg, severity:'error', errorId:'2E5543'});
    }
    console.log('errorId','2E5543')
    return(response);
  }
}


// comとuserの次月以降の変更予定を取得する
export const getNextHist = async (
  prms, setRes='', setSnack='', msg = '', errMsg=''
) => {
  let response = {};
  let r;
  // prms = {hid, bid, date: stdDate} 
  try{
    prms.a = 'fetchNextUserInfo';
    r = await axios.post(endPoint(), cmd.uPrms(prms));
    response.fetchNextUserInfo = r;
    if (r.status !== 200)  throw response;
    if (!r.data) throw response;
    prms.a = 'fetchNextComInfo';
    r = await axios.post(endPoint(), cmd.uPrms(prms));
    response.fetchNextComInfo = r;
    if (r.status !== 200)  throw response;
    if (!r.data) throw response;
    
    // if (!response.data.result)  throw response;
    if ((typeof setRes) === 'function'){
      setRes(response);
    }
    if ((typeof setSnack) === 'function'){
      const id = new Date().getTime();
      if (!msg) msg = 'データを受信しました。';
      setSnack({msg})
    }
    response.result = true;
    return(response);
  }
  catch(e){
    console.log(e);
    response.data = false;
    if ((typeof setRes) === 'function'){
      setRes(response);
    }
    if ((typeof setSnack) === 'function'){
      if (!errMsg) errMsg = 'データを受信できませんでした。' +
      'インターネット接続を見直して再度実行して下さい。' +
      '再びこのメッセージが表示されるときは管理者またはサポートまでご連絡をお願いします。';
      setSnack({msg: errMsg, severity:'error', errorId:'2E3398'});
    }
    console.log('errorId','2E5543')
    return(response);
  }
}


// 指定されたhid,bidの最大日付と最小日付を求める
export const getMinMaxOfMonnth = async (
  prms, setRes='', setSnack='', msg = '', errMsg=''
) => {
  let response;
  // prms = {hid, bid, } 
  prms.a = 'getMinMaxOfMonnth';
  try{
    response = await axios.post(endPoint(), cmd.uPrms(prms));
    if (response.status !== 200)  throw response;
    if (!response.data) throw response;
    // if (!response.data.result)  throw response;
    if ((typeof setRes) === 'function'){
      setRes(response);
    }
    if ((typeof setSnack) === 'function'){
      const id = new Date().getTime();
      if (!msg) msg = 'データを受信しました。';
      setSnack({msg})
    }
    return(response);
  }
  catch(e){
    console.log(e);
    response.data = false;
    if ((typeof setRes) === 'function'){
      setRes(response);
    }
    if ((typeof setSnack) === 'function'){
      if (!errMsg) errMsg = 'データを受信できませんでした。' +
      'インターネット接続を見直して再度実行して下さい。' +
      '再びこのメッセージが表示されるときは管理者またはサポートまでご連絡をお願いします。';
      setSnack({msg: errMsg, severity:'error', errorId:'2E3398'});
    }
    console.log('errorId','2E5543')
    return(response);
  }
}



// serviceとclassroomでユーザーの絞り込みを行う 
export const getFilteredUsers = (users, service, classroom) => {
  const ret = users.filter(e=>{
    const chkService = (service === '' || e.service === service)? true: false;
    const chkClassroom = (isClassroom(e, classroom))?
    true: false;
    return (chkService && chkClassroom);
  });
  return ret;
};

// 同じdid,classroom,serviceを持つスケジュールで一個でもロックがあったら
// ロック済みのスケジュールとして判断する
// 該当するスケジュールオブジェクトが一個もない場合は全スケジュールを見て
// すべてロック済みならロックする
export const schLocked = (
  schedule, users, thisUser, did, service, classroom
) => {
  let exist = 0;
  let rt = false;
  // 該当日スケジュール舐める
  Object.keys(schedule).filter(e=>e.match(/^UID[0-9]*/)).map(e=>{
    Object.keys(schedule[e]).filter(f=>f.match(/^D2[0-9]*/))
    .filter(f=>f>=did).map(f=>{
    // Object.keys(schedule[e]).filter(f=>f===did).map(f=>{
      // 日毎のスケジュールオブジェクト
      const o = schedule[e][f];
      const uClassroom = cmd.getUser(e, users).classroom; // ユーザー固有の単位
      const cClassroom = (o.classroom)? o.classroom: uClassroom;
      if (service !== '' && service !== o.service)  return false;
      if (classroom !== '' && cClassroom !== classroom) 
        return false;
      // if (classroom !== '' && o.classroom !== classroom) return false;
      exist = true;
      if (o.useResult){
        rt = true;
      }
    })
  });
  if (!exist) rt = false;
  return rt;
}


// 利用されているclassroomの配列を得る
export const getClassrooms = (users) => {
  const u = [...users];
  const valTmp = [];
  u.map(e=>{
    const clr = e.classroom;
    if (!clr) return false;
    if (Array.isArray(clr)) valTmp.push(...clr);
    else if (clr.indexOf(',')) valTmp.push(...clr.split(','));
    else valTmp.push(clr);
  })
  const r = Array.from(new Set(valTmp));
  r.sort((a, b)=>(a < b)? -1: 1);
  return r;
}

// MTU対応 複数単位ユーザーの略
// ユーザーデータから類推しそのclassroomが該当するか否か
// 個別のユーザーオブジェクトを引数として受け取るがthisUserが無効な場合はuidとusersで
// 特定できるようにする
export const isClassroom = (thisUser, classroom, uid, users) => {
  let classrooms;
  let userClr;
  if (!classroom) return 1;
  if (Object.keys(thisUser).length && thisUser.classroom){
    userClr = thisUser.classroom; // ユーザーのクラスルーム 配列または文字列
  }
  else{
    const u = cmd.getUser(uid, users);
    if (!u)  return false;
    if (Object.keys(u).length && u.classroom) userClr = u.classroom
    else return false;
  }
  if (Array.isArray(classroom))  classrooms = userClr;
  else if (classroom.indexOf(','))  classrooms = userClr.split(',');
  else classrooms = [userClr];
  if (classrooms.indexOf(classroom) > -1) return 1;
  else return 0;
}
// １ユーザーオブジェクトを受け取り所属するclassroomの数を検出する
export const classroomCount = (thisUser) => {
  if (!thisUser)  return 0;
  let userClr = '';

  if (Object.keys(thisUser).length && thisUser.classroom){
    userClr = thisUser.classroom; // ユーザーのクラスルーム 配列または文字列
  }
  if (!userClr) return 0;
  else if (Array.isArray(userClr))  return userClr.length;
  else if (userClr.indexOf(',')) return userClr.split(',').length;
  else return 1;

}
// MTUが存在するかどうかを調べる
export const getExistMtu = (users) => {
  let svcByUserMax = 0;
  users.forEach(e => {
    const v = classroomCount(e);
    if (v > svcByUserMax) svcByUserMax = v;
  });
  return (svcByUserMax > 1)? true: false;
}
// スケジュールを削除する
export const deleteSchedule = async (
  prms, setRes='', setSnack='', msg = '', errMsg=''
) => {
  let response;
  // prms = { hid, bid, data,  } 
  prms.a = 'deleteSchedule';
  try{
    response = await axios.post(endPoint(), cmd.uPrms(prms));
    if (response.status !== 200)  throw response;
    if (!response.data) throw response;
    if (!response.data.result)  throw response;
    if ((typeof setRes) === 'function'){
      setRes(response);
    }
    if ((typeof setSnack) === 'function'){
      const id = new Date().getTime();
      if (!msg) msg = '当月のスケジュールを削除しました。';
      setSnack({msg})
    }
    return(response);
  }
  catch(e){
    console.log(e);
    if (!response.data) response.data = false;
    if ((typeof setRes) === 'function'){
      setRes(response);
    }
    if ((typeof setSnack) === 'function'){
      if (!errMsg) errMsg = '当月のスケジュールを削除できませんでした。'
      setSnack({msg: errMsg, severity:'error', errorId:'3E5546'});
    }
    console.log('errorId','3E5546')
    return(response);
  }
}

// fsonのExcel変換をコールする
export const fetchAccountsByBid = async (
  prms, setRes='', setSnack='', msg = '', errMsg=''
) => {
  let response;
  // prms = { hid, bid, mail, key, permission } 
  prms.a = 'fetchAccountsByBid';
  try{
    response = await axios.post(endPoint(), cmd.uPrms(prms));
    if (response.status !== 200)  throw response;
    if (!response.data) throw response;
    if (!response.data.result)  throw response;
    if ((typeof setRes) === 'function'){
      setRes(response);
    }
    if ((typeof setSnack) === 'function'){
      const id = new Date().getTime();
      if (!msg) msg = '変換を実行しました。';
      setSnack({msg})
    }
    const tmpDt = response.data.dt.map(e=>{
      const p = cmd.parsePermission(e)[0][0];
      if (p < prms.permission) return e;
    }).filter(e=>e);
    response.data.dt = tmpDt;     
    return(response);
  }
  catch(e){
    console.log(e);
    if (!response.data) response.data = false;
    if ((typeof setRes) === 'function'){
      setRes(response);
    }
    if ((typeof setSnack) === 'function'){
      if (!errMsg) errMsg = '変換できませんでした。'
      setSnack({msg: errMsg, severity:'error', errorId:'3E5543'});
    }
    console.log('errorId','3E5543')
    return(response);
  }
}
// 汎用APIコールモジュール
export const univApiCall = async (
  prms, errorId, setRes='', setSnack='', msg = '', errMsg='', 
) => {
  let response;
  try{
    response = await axios.post(endPoint(), cmd.uPrms(prms));
    if (response.status !== 200)  throw response;
    if (!response.data) throw response;
    if (!response.data.result)  throw response;
    if ((typeof setRes) === 'function'){
      setRes(response);
    }
    if ((typeof setSnack) === 'function'){
      const id = new Date().getTime();
      if (!msg) msg = '実行しました。';
      setSnack({msg})
    }
    return(response);
  }
  catch(e){
    console.log(e);
    if (!response.data) response.data = false;
    if ((typeof setRes) === 'function'){
      setRes(response);
    }
    if ((typeof setSnack) === 'function'){
      if (!errMsg) errMsg = '実行できませんでした。'
      setSnack({msg: errMsg, severity:'error', errorId});
    }
    console.log('errorId', errorId)
    return(response);
  }
}



// fsonのExcel変換をコールする
export const fsConCnvExcel = async (
  prms, setRes='', setSnack='', msg = '', errMsg=''
) => {
  let response;
  // prms = { jino,  } 
  prms.a = 'fsConCnvExcel';
  try{
    response = await axios.post(endPoint(), cmd.uPrms(prms));
    if (response.status !== 200)  throw response;
    if (!response.data) throw response;
    if (!response.data.result)  throw response;
    if ((typeof setRes) === 'function'){
      setRes(response);
    }
    if ((typeof setSnack) === 'function'){
      const id = new Date().getTime();
      if (!msg) msg = '変換を実行しました。';
      setSnack({msg})
    }
    return(response);
  }
  catch(e){
    console.log(e);
    if (!response.data) response.data = false;
    if ((typeof setRes) === 'function'){
      setRes(response);
    }
    if ((typeof setSnack) === 'function'){
      if (!errMsg) errMsg = '変換できませんでした。'
      setSnack({msg: errMsg, severity:'error', errorId:'3E5544'});
    }
    console.log('errorId','3E5544')
    return(response);
  }
}
// 違う月のデータを削除する
export const deleteOtherMonthInSchedule = (sch, stdDate) => {
  const didPtn = 'D' + stdDate.replace(/\-/g, '').slice(0, 6);
  Object.keys(sch).filter(e=>e.indexOf('UID') === 0).forEach(e=>{
    Object.keys(sch[e]).filter(f=>f.indexOf('D2') === 0).forEach(f=>{
      if (f.indexOf(didPtn) !== 0){
        delete sch[e][f];
        console.log(e, f, 'deleted');
      }
    })
  });
  return sch;
}

export const endPoint = () => {
  const ep = cmd.getCookeis('endPoint');
  const apiList = ['api', 'apixfg', 'apidev'];
  if (!ep){
    return 'https://houday.rbatos.com/api/api.php';
  }
  else if (apiList.indexOf(ep)> -1){
    return `https://houday.rbatos.com/api/${ep}.php`;
  }
  else{
    return 'https://houday.rbatos.com/api/api.php';
  }
}
// UID999も999もまとめて解釈
export const convUid = (uid) => {
  uid = uid + '';
  const n = uid.replace('UID', '');
  const s = 'UID' + n;
  return {n, s};
}

