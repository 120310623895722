import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import * as mui from '../common/materialUi';
import * as afp from '../common/AddictionFormParts'; 
import * as sfp from '../common/StdFormParts'; 
import * as Actions from '../../Actions';
import * as comMod from '../../commonModule';
import { LoadingSpinner, LoadErr} from '../common/commonParts';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { red, teal } from '@material-ui/core/colors';



const useStyles = makeStyles({
  officesEditForm:{
    width:'100%',
    maxWidth: 650,
    '& .oneOffice': {
      display:'flex', padding: 8, flexWrap: 'wrap', margin: '8px 0',
      '& > div': {margin : '0 8px'},
      '& .officeHead':{
        // '& .title': {color: '#666',},
        // '& .no':{color:'#333'},
        paddingTop: 20,
        fontSize: '.8rem',
      },
      '& .name ':{width: '18ch'},
      '& .lname ':{width: '100%', marginTop: 12,},
    },
  },
  cityForm: {
    width:'100%',
    maxWidth: 650,
    marginBottom: 16,
    '& .oneCity':{
      '& .row':{
        display:'flex', padding: 8, margin: '8px 0',justifyContent:'center',
      },
      '& .name ':{width: '16ch'},
      '& .no' : {paddingTop: 10, marginRight: 16},
      '& .button': {marginLeft: 24, marginTop: 16, height: 36},
      '& .joseiNo': {width: '11ch'},
      '& .josei':{'& .MuiCheckbox-root': {padding: 0},},
    }
  },
  bottomSpacer:{marginBottom: 24,},
  checkboxFormLabel: {
    marginInlineStart: 24,
    // '& .MuiSvgIcon-root': {color: red[700]}
  },
  deleteWarning: {padding: '8px 0 8px 16px', color:red[700], fontSize: '.8rem'},

});


// 自動登録されたパラメータを表示して編集する。
// 現在上限管理で利用されている他事業所の情報と、市区町村名、番号が該当する
// 複数箇所に複数登録されているので全部置き換える
// スケジュール以外の情報はデータベース送信まで行う
// スケジュールに登録されているものは送信予約を行う


// 管理事業所、協力事業所を持つユーザーの配列を求める
const relatedUsers = (users) => {
  const t1 = [];  // まずは単純配列で取得する
  users.filter(e=>e.etc).filter(e=>e.etc.協力事業所).map(e=>{
    e.etc.協力事業所.filter(f=>f.name).map(f=>{
      t1.push({
        name: f.name, no: f.no, lname: (f.lname)? f.lname: '', 
      });
    });
  });
  users.filter(e=>e.etc).filter(e=>e.etc.管理事業所).map(e=>{
    e.etc.管理事業所.filter(f=>f.name).map(f=>{
      t1.push({
        name: f.name, no: f.no, lname: (f.lname)? f.lname: '', 
      });
    });
  });
  return t1;
}

// 他のオフィスを users stateから取得する
const getOtherOffices = (users) => {
  const t1 = relatedUsers(users);
  // 他事業所のnoをユニークにする
  const officeNumbers = new Set();
  t1.map(e=>officeNumbers.add(e.no));
  // ユニークにした番号からオフィス名などを付加した配列にする
  const uOffices = Array.from(officeNumbers).map(e=>({
    no: e, 
    name: t1.find(f=>f.no === e).name,
    lname: t1.find(f=>f.no === e).lname,
  }));
  console.log(uOffices);
  return (uOffices);
}

const EachOfficeFormParts = (p) => {
  const classes = useStyles();
  const {n, offices, setOffices} = p;
  // フォーム用のstateを一旦作成する
  // 親コンポーネントのstateを更新するとこのコンポーネントは再レンダーされる
  const [val, setVal] = useState(offices[n]);
  const [chk, setChk] = useState({
    name:{error:false, helperText:''},
    lname:{error:false, helperText:''},
  });
  // const [deleteCheckBox, setDeleteCheckBox] = useState(
  //   val.delete? val.delete: false
  // );

  const handleChange = (ev) => {
    const t = {...val};
    t[ev.currentTarget.name] = ev.currentTarget.value;
    setVal(t);
  }
  // 他の項目はblurで上位stateを更新しているが
  // チェックボックスはここで更新する
  const handeleCheckBoxChange = (ev) => {
    const t = {...val};
    t.delete = ev.currentTarget.checked;
    setVal(t);
    const u = [...offices];
    u[n].delete = ev.currentTarget.checked;
    setOffices(u);
  }
  // ブラーで親コンポーネントのstateを更新する
  const handleBlur = (ev) => {
    const elm = ev.currentTarget;
    const u = {...chk};
    if (elm.required && !elm.value){
      u[elm.name].error = true;
      u[elm.name].helperText = '入力必須項目です。';
    }
    else{
      u[elm.name].error = false;
      u[elm.name].helperText = '';
    }
    setChk(u);
    const t = [...offices];
    t[n][elm.name] = elm.value;
    setOffices(t);
  }
  // 事業所正式名称にフォーカスあたったら略称をコピー
  const handleFocus = () => {
    const t = {...val};
    if (!t.lname) t.lname = t.name;
    setVal(t);
  }

  return(<>
    <div className='oneOffice'>
      <TextField 
        className='name'
        name='name'
        label='事業所略称'
        value={val.name}
        required
        helperText={chk.name.helperText}
        error={chk.name.error}
        onChange={(ev)=>handleChange(ev)}
        onBlur={(ev)=>handleBlur(ev)}
      />
      <div className='officeHead'>
        事業所番号 {val.no}
      </div>
      <FormControlLabel className={classes.checkboxFormLabel}
        control={
          <Checkbox 
            checked={val.delete? val.delete: false} 
            onChange={(ev) => handeleCheckBoxChange(ev)} name="delete"
          />
        }
        label="削除"
      />

      <TextField 
        className='lname'
        name='lname'
        label='事業所正式名称'
        value={val.lname}
        helperText={chk.lname.helperText}
        error={chk.lname.error}
        onChange={(ev)=>handleChange(ev)}
        onBlur={(ev)=>handleBlur(ev)}
        onFocus={handleFocus}
      />
    </div>
  </>)
}


const EditOtherOffice = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const path = useLocation().pathname;
  const {otherOffices, ...others} = props;
  const [offices, setOffices] = useState(otherOffices);
  const users = useSelector(state=>state.users);
  const schedule = useSelector(state=>state.schedule);
  const hid = useSelector(state=>state.hid);
  const bid = useSelector(state=>state.bid);
  const dateList = useSelector(state=>state.dateList);
  const stdDate = useSelector(state=>state.stdDate);

  // 編集結果監視用
  const [respons, setResponse] = useState({});
  useEffect(()=>{
    //ここは機能してない！
    console.log(respons, 'respons')
  }, [respons]);
  // stateの書き換え
  // usersとscheduleの書き換えになると思われ
  const handleSubmit = () => {
    // ユーザーの更新
    const tmpUsers = [...users];
    const etcs = []; // db送信用の配列を作成する
  
    // usersのstateから管理事業所、協力事業所のキーを持っているデータを
    // 検索、フォームに入力された値と置き換える
    ['管理事業所','協力事業所'].map(d=>{
      tmpUsers.filter(e=>e.etc).filter(e=>e.etc[d]).map(e=>{
        e.etc[d].filter(f=>f.name).map(f=>{
          const edited = offices.find(g=>g.no === f.no);
          if (edited ){
            f.name = edited.name;
            f.lname = edited.lname;
            if (edited.delete)  f.delete = edited.delete;
            etcs.push({uid: e.uid, etc:e.etc});
          }
          else{
            // これは表示されないはず
            console.log('user state update err.');
          }
        });
        const t = e.etc[d].filter(f=>f.name && !f.delete).map(f=>f);
        e.etc[d] = t;
      });
    })

    // ---- スケジュール更新
    const tmpSch = {...schedule};
    // 協力事業所/管理事業所を持つキーを作成
    ['管理事業所','協力事業所'].map(d=>{
      const uidsk = Object.keys(tmpSch).filter(e=>(tmpSch[e][d]));
      uidsk.map(e=>{
        tmpSch[e][d].map(f=>{
          const edited = offices.find(g=>g.no === f.no);
          if (edited){
            f.name = edited.name;
            f.lname = edited.lname;
            if (edited.delete)  f.delete = edited.delete;
            etcs.push({uid: e.uid, etc:e.etc});
          }
          else{
            // これは表示されないはず
            console.log('user state update err.');
          }
        });
        const t = tmpSch[e][d].filter(f=>f.name && !f.delete).map(f=>f);
        tmpSch[e][d] = t;
      });
    });
    // スケジュールstateの更新
    dispatch(Actions.setStore({schedule:tmpSch}));
    // スケジュール即時送信
    comMod.callDisptchForSendSchedule(
      { dateList, stdDate, schedule, hid, bid, dispatch }
    );

    // ユーザーstateの更新
    dispatch(Actions.setStore({users:tmpUsers}));
    // db送信 コール先でdisptchを実行
    comMod.sendUserEtcMulti(
      {hid, bid, etcs,date: stdDate }, setResponse, dispatch
    );
  }

  const handleCancel = () =>{
    dispatch(Actions.resetStore());

  }

  const officeFormParts = offices.map((e, i)=>{
    const p = {n: i, offices:[...offices], setOffices}
    return(<EachOfficeFormParts {...p} key={i}/>)
  });
  // 削除チェックされているかどうかのフラグ
  const deleteCheked = offices.filter(e=>e.delete).length > 0;
  return(<>
    <div className='H'>
      協力事業所・管理事業所編集
      <div className='small'>
        登録されている管理事業所・協力事業所を編集します。
        正式名称を登録すると帳票などに反映されます。
      </div>
    </div>
    <form className={classes.officesEditForm}>
      {officeFormParts}
    </form>
    {deleteCheked === true &&
      <div className={classes.deleteWarning}>
        削除がチェックされています。
        更新すると対象の事業所と上限管理情報はすべて削除されます。
      </div>
    }
    <div className={classes.bottomSpacer + ' buttonWrapper'}>
      <Button 
        variant='contained'
        onClick={handleCancel}
        color='secondary'
      >
        キャンセル
      </Button>

      <Button 
        variant='contained'
        onClick={handleSubmit}
        color='primary'
      >
        更新
      </Button>

    </div>
  </>)
}
// 市区町村情報の編集
// 市区町村情報はusersの情報を基本とするが自治体助成金の絡みで管理するパラメータが増えた
// 請求にも影響するため月ごとに管理が可能なbrunchのetcにも情報を格納する
// state上では
const OneCity = (props) => {
  const {no, name, /*scities, setScities*/} = props;
  const dispatch = useDispatch();
  const users = useSelector(state=>state.users);
  const hid = useSelector(state=>state.hid);
  const bid = useSelector(state=>state.bid);
  const com = useSelector(state=>state.com);
  const stdDate = useSelector(state=>state.stdDate);
  // comに記述されている市区町村情報を取得
  const cities = com.etc.cities;
  let thisCitiy = (cities)
  ? cities.find(e=>e.no === no): {no, joseiNo:no, josei:false};
  thisCitiy = thisCitiy ? thisCitiy: {no, joseiNo:no, josei:false};
  // フォーム用ステイト
  const [val, setVal] = useState({
    name,
    joseiNo: thisCitiy.joseiNo,
    josei: thisCitiy.josei,
  });
  const [err, setErr] = useState({
    name: {err: false, msg: ''},
    joseiNo: {err: false, msg: ''},
  });
  const [existError, setExistError] = useState(true);
  
  const handleChange = (ev) =>{
    const targetName = ev.currentTarget.name;
    const type = ev.currentTarget.type;
    const targetVal = (type === 'checkbox')
    ? ev.currentTarget.checked: ev.currentTarget.value;
    const tVal = {...val};
    tVal[targetName] = targetVal;
    setVal(tVal);
  }
  // バリデーション
  const handleBlur = (ev) => {
    const targetName = ev.currentTarget.name;
    const targetVal = ev.currentTarget.value;
    const tErr = {...err};
    if (targetName === 'name'){
      if (!targetVal){
        tErr[targetName].err = true;
        tErr[targetName].msg = '入力必須項目';
      }
      else if (targetVal.length > 16){
        tErr[targetName].err = true;
        tErr[targetName].msg = '16文字以内';
      }
      else{
        tErr[targetName].err = false;
        tErr[targetName].msg = '';
      }
    }
    if (targetName === 'joseiNo'){
      if (!targetVal){
        tErr[targetName].err = true;
        tErr[targetName].msg = '入力必須項目';
      }
      else if (!/^[0-9]{6}$/.test(targetVal)){
        tErr[targetName].err = true;
        tErr[targetName].msg = '6桁の数字';
      }
      else{
        tErr[targetName].err = false;
        tErr[targetName].msg = '';
      }
    }
    setErr(tErr);
  }
  const handleSubmit = () => {
    // ユーザーstateのセットと書き換え
    const tmpUsers = [...users];
    tmpUsers.filter(e=>e.scity_no === no).map(e=>{
      e.scity = val.name;
    });
    dispatch(Actions.setStore({users: tmpUsers}));
    const p = {hid, bid, scity: val.name, scity_no: no};
    comMod.sendUsersCity(p, ()=>null, dispatch);
    // state.comの書き換え
    const newCiteis = (cities)? cities: []; // 未構築なら空の配列
    const cityNdx = newCiteis.findIndex(e=>e.no===no);
    if (cityNdx < 0){ // 見つからないときは追加
      newCiteis.push({no, ...val});
    }
    else{ // 見つかったら更新
      newCiteis[cityNdx] = {no, ...val};
    }
    // ストア更新
    dispatch(Actions.setStore(
      {com: {...com, date:stdDate, etc:{...com.etc, cities:newCiteis}}}
    ));
    // db上の事業所更新
    dispatch(Actions.sendBrunch(
      { ...com, hid, bid, date:stdDate, etc: {...com.etc, cities:newCiteis}}
    ));

  }
  // サブミットボタンの有効無効を切り替えるためにエラーのステイトを確認する
  useEffect(()=>{
    let t = false;
    Object.keys(err).map(e=>{
      if (err[e].err) t = true;
    });
    setExistError(t);
  }, [err]);
  return(
    <div className='oneCity'>
      <div className='row'>
        <div className='no'>{no}</div>
        <TextField
          name='name'
          value={val.name}
          onChange={(ev)=>handleChange(ev)}
          className='name'
          onBlur={(ev)=>handleBlur(ev)}
          label='市区町村名'
          error={err.name.err}
          helperText={err.name.msg}
        />
        <FormControlLabel
          className='josei'
          control={
            <Checkbox
              checked={val.josei}
              onChange={(ev)=>handleChange(ev)}
              name='josei'
              color="primary"
            />
          }
          labelPlacement="bottom"
          label='自治体助成'
        />
        <TextField
          name='joseiNo'
          value={val.joseiNo}
          onChange={(ev)=>handleChange(ev)}
          className='joseiNo'
          onBlur={(ev)=>handleBlur(ev)}
          label='助成自治体番号'
          error={err.joseiNo.err}
          helperText={err.joseiNo.msg}
        />

        <Button
          variant='contained' color='primary'
          onClick={handleSubmit}
          className='button'
          disabled={existError}
        >
          更新
        </Button>
      </div>
    </div>
  )
}

// 市区町村の編集
const EditCties = () =>{
  const classes = useStyles();
  const users = useSelector(state=>state.users);
  // 市区町村番号と市区町村名のユニークなセットを作成
  const scityNoSet = new Set();
  users.map(e=>{scityNoSet.add(e.scity_no)});
  const cities = Array.from(scityNoSet).map(e=>{
    const name = users.find(f=>f.scity_no === e).scity;
    return {no: e, name};
  });
  cities.sort((a, b)=>(a.no > b.no)? 1: -1);
  // const [scities, setScities] = useState(cities);
  const earchCity = cities.map((e, i)=>{
    return(
      <OneCity {...e} key={i}/>
    )
  });
  return (<>
    <div className='H'>
      市区町村名編集
      <div className='small'>
        登録されている市区町村名称や自治体助成の有無などを登録します。
      </div>
    </div>

    <form className={classes.cityForm}>
      {earchCity}
    </form>
    {/* <div className='buttonWrapper'>
      <Button
        variant='contained' color='primary'
        onClick={handleSubmit}
      >
        送信
      </Button>
    </div> */}
  </>);
}

const RegParamsMain = () => {
  const users = useSelector(state=>state.users);
  const otherOffices = getOtherOffices(users);
  return(<>
    <EditOtherOffice otherOffices={otherOffices} />
    <EditCties />
  </>);
  
} 


export const RegistedParams = () => {
  const allstate = useSelector(state=>state);
  const loadingStatus = comMod.getLodingStatus(allstate);
  if (loadingStatus.loaded){
    return (<RegParamsMain />)
  }
  else if (loadingStatus.error){
    return (
      <LoadErr loadStatus={loadingStatus} errorId={'E4946'} />
    )
  }
  else{
    return <LoadingSpinner/>
  }

}
export default RegistedParams;