import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import {setBillInfoToSch} from '../Billing/blMakeData';
import * as comMod from '../../commonModule';

const useStyles = makeStyles({
    tokyoPage:{
        breakAfter: 'page',
        marginBottom: "5em",
        '& #tokyoHeader>div':{
            marginBottom: '2em'
        },
        '& #tokyoTitle':{
            marginTop: '2em',
            marginBottom: '2em',
            fontWeight: 'bold',
            textAlign: 'center',
            fontSize: '1.5em'
        },
        '& #tokyoAbout':{
            marginBottom: '1em',
            lineHeight: 1.5
        },
        '& #tokyoTable':{
            width: '100%',
            border: '1px solid',
            borderWidth: '2px',
            marginTop: '2em',
            display: 'flex',
            flexDiretion: 'row',
            '& .tokyo_th':{
                width: '45%',
                borderRight: '1px solid',
                '& div:nth-child(4)':{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                },
                '& .tokyo_subtable > div':{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    '& p':{
                        textAlign: 'center',
                    },
                    '& p:nth-child(1)':{
                        width: '90%'
                    }
                }
            },
            '& .tokyo_td':{
                width: '55%',
                '& p':{
                    margin: '0 11em'
                }
            },
            '& #tokyoSubth':{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                '& >div':{
                    width: '50%'
                },
                '& >div:nth-child(1)':{
                    borderRight: '1px solid',
                }
            },
            '& .tokyo_cell':{
                borderBottom: '1px solid',
            },
            '& .tokyo_row':{
                height: '5em',
                display: 'flex',
                alignItems: 'center',
            },
            '& .tokyo_num':{
                justifyContent: 'flex-end',
            },
            '& p':{
                margin: '0 0.5em'
            }
        },
        '& #tokyoAnnotation':{
            marginTop: 4,
            textAlign: 'right'
        }
    }
})

const TokyoDairiTsuchiOne = (props) => {
    const classes = useStyles();
    const {e, users, com, schedule, service, stdDate} = props;
    const user = users.filter(x => x.uid == e.UID.replace("UID", ""))[0];
    const c_price = "0";
    const dairiJuryoPrice = comMod.formatNum(parseInt(e.userSanteiTotal) - parseInt(e.tanniTotal) + parseInt(c_price), 1);
    const stateDate = comMod.findDeepPath(
        schedule, ['report', service, '代理受領通知日']
    );
    const date = stateDate
      ? stateDate: comMod.formatDate(new Date(), 'YYYY-MM-DD');
    const date_array = date.split('-');
    const stddate_array = stdDate.split('-')
    const wareki =comMod.getDateEx(date_array[0], date_array[1], date_array[2]);
    const serv_wareki = comMod.getDateEx(stddate_array[0], stddate_array[1], stddate_array[2]);
    
    const print_year = ("0"+wareki.wr.y).slice(-2);
    const print_month = ("0"+wareki.m).slice(-2);
    const print_date = ("0"+wareki.d).slice(-2);

    return(
        // <div className={classes.tokyoPage}>
        //     <div id='tokyoHeader'>
        //         <div style={{"text-align": "right"}}>令和{("0"+wareki.wr.y).slice(-2)}年{("0"+wareki.m).slice(-2)}月{("0"+wareki.d).slice(-2)}日</div>
        //         <div style={{"text-align": "left"}}>{e.pname}様</div>
        //         <div style={{"text-align": "right"}}>{com.hname}　{com.bname}</div>
        //     </div>
        //     <div id='tokyoTitle'>障害児通所給付費　代理受領のお知らせ</div>
        //     <div id = 'tokyoAbout'>
        //     {e.name}様に提供した下記のサービスに要した費用について、{user.scity}から下記のとおり利用者様に代わり支払いを受けましたので、お知らせします。
        //     このお知らせの内容に疑義がある場合は、当事業所もしくは{user.scity}にお問い合わせください。
        //     </div>
        //     <div style={{"text-align": "center"}}>記</div>
        //     <div id='tokyoTable'>
        //         <div class='tokyo_th'>
        //             <div class='tokyo_cell tokyo_row'><p>サービス提供年月</p></div>
        //             <div class='tokyo_cell tokyo_row'><p>サービス内容</p></div>
        //             <div class='tokyo_cell tokyo_row'><p>受領日</p></div>
        //             <div class='tokyo_cell tokyo_row'><p>代理受領金額</p><p>（A）-（B）+（C）</p></div>
        //             <div id='tokyoSubth'>
        //                 <div style={{'display': 'flex', 'align-items': 'center', 'justify-content': 'center'}}><p>代理受領額の内訳</p></div>
        //                 <div class='tokyo_subtable'>
        //                     <div class='tokyo_cell tokyo_row'><p>サービスに要した<br/>費用の全体の額</p><p>（A）</p></div>
        //                     <div class='tokyo_cell tokyo_row'><p>利用者負担額</p><p>（B）</p></div>
        //                     <div class='tokyo_row'><p>障害児通所給付費<br/>代理受領額</p><p>（C）</p></div>
        //                 </div>
        //             </div>
        //         </div>
        //         <div class='tokyo_td'>
        //             <div class='tokyo_cell tokyo_row'><p>令和{("0"+serv_wareki.wr.y).slice(-2)}年{("0"+serv_wareki.m).slice(-2)}月</p></div>
        //             <div class='tokyo_cell tokyo_row'><p>{service}事業</p></div>
        //             <div class='tokyo_cell tokyo_row'><p>令和{("0"+wareki.wr.y).slice(-2)}年{("0"+wareki.m).slice(-2)}月{("0"+wareki.d).slice(-2)}日</p></div>
        //             <div class='tokyo_cell tokyo_row tokyo_num'><p>金{String(dairiJuryoPrice)}円</p></div>
        //             <div class='tokyo_subtable'>
        //                 <div class='tokyo_cell tokyo_row tokyo_num'><p>金{comMod.formatNum(e.userSanteiTotal, 1)}円</p></div>
        //                 <div class='tokyo_cell tokyo_row tokyo_num'><p>金{comMod.formatNum(e.tanniTotal, 1)}円</p></div>
        //                 <div class='tokyo_row tokyo_num'><p>金{String(c_price)}円</p></div>
        //             </div>
        //         </div>
        //     </div>
        //     <div id='tokyoAnnotation'>※このお知らせは、請求書ではありません。</div>
        // </div>
        <div className={classes.tokyoPage}>
            <div id='tokyoHeader'>
                <div style={{"text-align": "right"}}>令和{print_year}年{print_month}月{print_date}日</div>
                <div style={{"text-align": "left"}}>{e.pname}様</div>
                <div style={{"text-align": "right"}}>{com.hname}　{com.bname}</div>
            </div>
            <div id='tokyoTitle'>介護給付費の受領のお知らせ<br/><span style={{"font-size": "0.75em"}}>（法定代理受領のお知らせ）</span></div>
            <div id = 'tokyoAbout'>
            　{e.name}様に提供した下記のサービスに要した費用について、{user.scity}から下記のとおり利用者様に代わり支払いを受けましたので、お知らせします。<br/>
            　このお知らせの内容に疑義がある場合は、当事業所もしくは{user.scity}にお問い合わせください。
            </div>
            <div style={{"text-align": "center"}}>記</div>
            <div id='tokyoTable'>
                <div class='tokyo_th'>
                    <div class='tokyo_cell tokyo_row'><p>サービス提供年月</p></div>
                    <div class='tokyo_cell tokyo_row'><p>サービス内容</p></div>
                    <div class='tokyo_cell tokyo_row'><p>受領日</p></div>
                    <div class='tokyo_cell tokyo_row'><p>代理受領金額</p><p>（A）-（B）+（C）</p></div>
                    <div id='tokyoSubth'>
                        <div style={{'display': 'flex', 'align-items': 'center', 'justify-content': 'center'}}><p>代理受領額の内訳</p></div>
                        <div class='tokyo_subtable'>
                            <div class='tokyo_cell tokyo_row'><p>サービスに要した<br/>費用の全体の額</p><p>（A）</p></div>
                            <div class='tokyo_cell tokyo_row'><p>利用者負担額</p><p>（B）</p></div>
                            <div class='tokyo_row'><p>特定障害者特別<br/>給付費</p><p>（C）</p></div>
                        </div>
                    </div>
                </div>
                <div class='tokyo_td'>
                    <div class='tokyo_cell tokyo_row'><p>令和{("0"+serv_wareki.wr.y).slice(-2)}年{("0"+serv_wareki.m).slice(-2)}月</p></div>
                    <div class='tokyo_cell tokyo_row'><p>{service}事業</p></div>
                    <div class='tokyo_cell tokyo_row'><p>令和{print_year}年{print_month}月{print_date}日</p></div>
                    <div class='tokyo_cell tokyo_row tokyo_num'><p>金{String(dairiJuryoPrice)}円</p></div>
                    <div class='tokyo_subtable'>
                        <div class='tokyo_cell tokyo_row tokyo_num'><p>金{comMod.formatNum(e.userSanteiTotal, 1)}円</p></div>
                        <div class='tokyo_cell tokyo_row tokyo_num'><p>金{comMod.formatNum(e.tanniTotal, 1)}円</p></div>
                        <div class='tokyo_row tokyo_num'><p>金{String(c_price)}円</p></div>
                    </div>
                </div>
            </div>
            <div id='tokyoAnnotation'>※このお知らせは、請求書ではありません。</div>
        </div>
    )
}

const TokyoDairiTsuchi = (props) => {
    const {userList, preview, selects, ...others} = props;
    const allState = useSelector(state=>state);
    const {stdDate, schedule, users, com, service, serviceItems} = allState;

    const nameList = ['代理受領通知'];

    if (nameList.indexOf(preview) < 0)  return null;
    if (selects[preview] !== "東京都形式") return null;
    const bdprms = { stdDate, schedule, users, com, service, serviceItems };
    const { billingDt, masterRec } = setBillInfoToSch(bdprms);
    const pages = billingDt.map((e, i)=>{
        // const thisUser = comMod.getUser(e.UID, users);
        // if (!userList.find(f => f.uid === thisUser.uid))  return null;
        // if (!userList.find(f => f.uid === thisUser.uid).checked) return null;
        // 請求額なしはスキップ
        if (!e.tanniTotal) return null;
        const rpprmas = {
            e, users, com, schedule, service, stdDate
        };
        return (
            <TokyoDairiTsuchiOne {...rpprmas} />
        )
    })
    return pages
}

export default TokyoDairiTsuchi;