import React, { useState, useEffect } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Dialog from '@material-ui/core/Dialog';
import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as comMod from '../../commonModule'
import { useDispatch, useSelector } from 'react-redux';
import { setUseResult } from '../../Actions';
import * as mui from '../common/materialUi';
import * as Actions from '../../Actions';
import CancelIcon from '@material-ui/icons/Cancel';
import PersonIcon from '@material-ui/icons/Person';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import * as sfp from '../common/StdFormParts';
import * as afp from '../common/AddictionFormParts';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import IconButton from '@material-ui/core/IconButton';
import { endPoint, getClassrooms, isClassroom } from '../../albCommonModule'
import axios from 'axios';
import teal from '@material-ui/core/colors/teal';
import { faSleigh } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles({
  usersSortDialog:{
    ' & .MuiDialog-paperWidthSm': {
      width: '65%',
      minWidth: '500px',
      maxWidth: '600px',  
    },
    ' & .MuiDialogContent-root': {
      margin: 0,
      padding: 0,
      overflowX: 'hidden', // この設定がないと横スクロールバーが出ちゃう
    },
    '& .MuiDialogTitle-root': {
      padding: 0,
    },
    '& .MuiIconButton-root' : {
      padding: 6,
      marginLeft: 6,
    },
    '& .formTitle': {
      marginBottom: 0,
    },
    '& .flxRow .buttonWrap' :{
      padding: 0,
      backgroundColor: 'transparent',
    },
    '& .flxRow.cUser>div ': {
      backgroundColor: teal[50],      
    }
  },
});

// ユーザーインデックスを更新する
async function requestUserIndexUpdate(prms) {
  // susers ソート済みのユーザー
  // users Storeのユーザー
  const {
    susers, hid, bid, setres, dispatch,
  } = prms;

  // dbに送信するための配列作成
  const indexset = susers.map(e => {
    return [e.uid, e.sindex];
  });
  const jindexset = JSON.stringify(indexset);
  // dbのアップデート
  let res;
  const urlPrms = {
    hid, bid, indexset: jindexset, a: 'sendUsersIndex'
  };
  try {
    res = await axios.post(endPoint(), comMod.uPrms(urlPrms));
    if (!res.data.resulttrue > 0 || res.data.resultfalse) {
      throw new Error(res);
    }
    setres(res);
    dispatch(Actions.setSnackMsg('ユーザーの並び順を更新しました。', ''));
  }
  catch {
    setres(res);
    dispatch(
      Actions.setSnackMsg('ユーザーの並び替えで問題が発生しました。', 'error')
    );
  }
}


export const UserSortDialog = (props) =>{
  // stateのopenで開く、uidsはuidを持つ
  // editOnで修正モード、uidに従って修正を行う
  const dispatch = useDispatch();
  const {open, setopen, res, setres, ...other} = props;
  const hid = useSelector(state => state.hid);
  const bid = useSelector(state => state.bid);
  const classroom = useSelector(state => state.classroom);
  const service = useSelector(state => state.service);
  const classes = useStyles();
  const users = useSelector(state=>state.users);
  // 直前でクリックされたuid保持。画面強調表示用
  const [cuid, setCuid] = useState('');
  const classrooms = getClassrooms(users);
  // classroomによりsindexの末尾一桁を変更する
  const clsOffset = (classroom && users.length)
  ? classrooms.findIndex(e=>e === classroom) + 1 : 0;
  // 順番入れ替え用のuser配列をstateに突っ込む
  const [tusers, setTusers] = useState(
    ()=>{
      const r = users.map(e=>{
        // if (classroom !== '' && classroom !== e.classroom)  return false;
        if (!isClassroom(e, classroom)) return false;
        if (service !== '' && service !== e.service) return false;
        return e;
      }).filter(e=>e);
      r.sort((a, b)=>(a.sindex > b.sindex? 1: -1));
      // 2022/03/03 インデックスが重複することがあるので変更しておく
      let v = 100 + clsOffset;
      r.forEach(e => {
        e.sindex = v;
        v += 10;
      });
      return r;
    }
  );
  // dbapi レスポンス格納用
  // const [res, setres] = useState({});

  const handleSubmit = (e)=>{
    console.log('this is handele submit');
    const t = [...users];
    tusers.map(e=>{
      const i = t.findIndex(f=>f.uid === e.uid);
      if (i > -1) t[i] = e;
    });
    t.sort((a, b)=>(parseInt(a.sindex) > parseInt(b.sindex)? 1: -1));
    dispatch(Actions.updateUsersAll(t));
    const prms = {susers: tusers, hid, bid, setres, dispatch}
    requestUserIndexUpdate(prms);
    setopen(false);
  }
  const cancelSubmit = ()=>{
    setopen(false);
  }

  // アップダウンのクリックハンドラ
  const handleUpDownClick = (ev) =>{
    let uid = ev.currentTarget.getAttribute('uid');
    if (uid) setCuid(uid);
    else uid = cuid;
    const dir = parseInt(ev.currentTarget.getAttribute('dir'));
    const ndx = tusers.findIndex(e => e.uid === uid);
    const wk = [...tusers];
    if (ndx === -1) return false;
    if (ndx === 0 && dir < 0) return false;
    if (ndx === tusers.length - 1 && dir > 0) return false;
    let nextNdx = ndx + dir;
    if (dir > 0) nextNdx++;
    if (nextNdx < 0) nextNdx = 0;
    if (nextNdx > tusers.length) nextNdx = tusers.length;
    const current = { ...wk[ndx] };
    const target = { ...wk[nextNdx] };
    wk.splice(nextNdx, 0, current);
    if (dir > 0){
      wk.splice(ndx, 1);
    }
    else{
      wk.splice(ndx + 1, 1);
    }
    if (dir > 0) nextNdx--;

    const t = wk[ndx].sindex;
    wk[ndx].sindex = wk[nextNdx].sindex;
    wk[nextNdx].sindex = t;

    // if (dir === 'up') {
    //   const current = { ...wk[ndx] };
    //   const target = { ...wk[ndx - 1] };
    //   wk.splice(ndx, 1, target);
    //   wk.splice(ndx - 1, 1, current);
    //   const t = wk[ndx].sindex;
    //   wk[ndx].sindex = wk[ndx - 1].sindex;
    //   wk[ndx - 1].sindex = t;
    // }
    // else if (dir === 'down') {
    //   const current = { ...wk[ndx] };
    //   const target = { ...wk[ndx + 1] };
    //   wk.splice(ndx, 1, target);
    //   wk.splice(ndx + 1, 1, current);
    //   const t = wk[ndx].sindex;
    //   wk[ndx].sindex = wk[ndx + 1].sindex;
    //   wk[ndx + 1].sindex = t;
    // }
    setTusers(wk);
  }
  const rowClickHandler = (ev) => {
    ev.stopPropagation();
    const uid = ev.currentTarget.getAttribute('uid');
    setCuid(uid);

  }
  let ln = 0; // 行番号
  const userSortContentRows = tusers.map((e, i)=>{
    // if (classroom !== '' && classroom !== e.classroom)  return false;
    // if (service !== '' && service !== e.service) return false;
    const cUserClass = (e.uid === cuid) ? 'cUser' : '';
    ln++;
    return(
      <div 
        className={'flxRow oddColor ' + cUserClass} key={i}
        uid={e.uid} onClick={rowClickHandler} 
      >
        <div className='wmin'>{ln}</div>
        <div className='w25'>{e.name}</div>
        <div className='w07'>{e.ageStr}</div>
        <div className='w50'>{e.belongs1}</div>
        <div className='w20 buttonWrap'>
          <IconButton onClick={handleUpDownClick} uid={e.uid} dir={-1}>
            <ArrowUpwardIcon />
          </IconButton>
          <IconButton onClick={handleUpDownClick} uid={e.uid} dir={1}>
            <ArrowDownwardIcon />
          </IconButton>
        </div>

      </div>
    );
  });

  return(<>
    <Dialog className={classes.usersSortDialog}
      open={open}
      onClose={()=>setopen(false)}
    >
      <DialogTitle>
        <div className='formTitle'>
          ユーザー並び替え
        </div>
      </DialogTitle>
      <DialogContent className={classes.userDialogContentRoot}>
        {userSortContentRows}    
      </DialogContent>
      <DialogActions>
        <IconButton onClick={handleUpDownClick} dir={-1}>
          <ArrowUpwardIcon />
        </IconButton>
        <IconButton onClick={handleUpDownClick} dir={1}>
          <ArrowDownwardIcon />
        </IconButton>
        <Button 
          startIcon={<ArrowUpwardIcon />}
          onClick={handleUpDownClick} dir={-5}
        >
          5
        </Button>
        <Button 
          startIcon={<ArrowDownwardIcon />}
          onClick={handleUpDownClick} dir={5}
        >
          5
        </Button>
        <div className='buttonWrapper'>
          <mui.ButtonGP
            color='secondary'
            label='キャンセル'
            onClick={cancelSubmit}
          />
          <mui.ButtonGP
            color='primary'
            label='書き込み'
            type="submit"
            onClick={handleSubmit}
          />

        </div>
      </DialogActions>
      
    </Dialog>
    
  </>)
}
export default UserSortDialog;