import React, { useEffect, useState } from 'react';
import * as mui from '../common/materialUi'
import * as Actions from '../../Actions';
import * as comMod from '../../commonModule';
import * as albcm from '../../albCommonModule';
import {formatNum, formatDate, getUser, } from '../../commonModule';
import { useDispatch, useSelector, } from 'react-redux';
// import { common } from '@material-ui/core/colors';
import { 
  LoadingSpinner, UserSelectDialog, LoadErr, StdErrorDisplay, RecalcButton, PermissionDenied
} from '../common/commonParts';
// import { 
//   houdySirvice, houdayKasan, chiikiKubun, unitPrice,
//   serviceSyubetu, ketteiScodeOld,
// } from './BlCalcData';
// import { keys } from '@material-ui/core/styles/createBreakpoints';
// import { BlockRounded, Details, FormatAlignJustifyOutlined } from '@material-ui/icons';
import { 
  setBillInfoToSch, makeBiling, makeJugenkanri, makeTeikyouJisseki
} from './blMakeData';
import { makeStyles } from '@material-ui/core/styles';
import {
  SOUGEY_SVC_CODE, // 送迎サービスコード
  // KATEI_SVC_CODE,
  // HOUMON_SVC_CODE,
  // IREN_SVC_CODE,
} from './BlCalcData';
import PrintIcon from '@material-ui/icons/Print';
import Button from '@material-ui/core/Button';
import teal from '@material-ui/core/colors/teal';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator } from "@fortawesome/free-solid-svg-icons";
// import { KanriKekka, PriceLimit } from '../common/StdFormParts';
// import { findByLabelText } from '@testing-library/react';
// import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
// import Typography from '@material-ui/core/Typography';
import { UpperLimitKanri } from '../schedule/SchUpperLimit';
import {LinksTab} from '../common/commonParts';
import CheckProgress, {doCheckProgress} from '../common/CheckProgress';
import EditIcon from '@material-ui/icons/Edit';
import GroupIcon from '@material-ui/icons/Group';
// import CheckIcon from '@material-ui/icons/Check';
// import CloseIcon from '@material-ui/icons/Close';
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { JOUGEN_KANRI } from './blMakeData';
import ServiceCountNotice from './ServiceCountNotice'
import SnackMsg from '../common/SnackMsg';
import { Album } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import { grey } from '@material-ui/core/colors';
// import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
// import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

const fdp = (obj, path) => comMod.findDeepPath(obj, path);

// 売上情報を表示するモジュール。
// billingDt masterRec はstore stateに持ってく予定

// fontAWESOMEアイコンのスタイル
const iconStyle = { padding: 0, fontSize: 18, marginRight: 8 };
const appPageStyle = { paddingTop: 92 }
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  nodata: {
    maxWidth: 400,
    textAlign:'center',
    margin: '0 auto',
  },
  proseedDiv: {
    pageBreakInside: 'avoid',
    marginBottom: 24,
    ' & .H': {
      backgroundColor: teal[50],
      padding: 8,
      paddingLeft: 16,
      paddingTop: 12,
      borderBottom: '1px solid ' + teal[900],

    },
    '& .mtuNotice': {fontSize: '.7rem', color: teal[600], padding: '8px 0'},
    '& .countSummary': {
      paddingTop: 16,
      '& .title': {
        fontSize: '.9rem', background: '#F5F5F5', padding: '6px 4px 5px',
        borderBottom: '1px #bbb solid',
      },
      '& .detail': {
        display: 'flex', flexWrap: 'wrap', // paddingTop: 8, paddingBottom: 8,
        padding: '8px 4px',
        '& >div': {
          fontSize: '.8rem', marginRight: 12,
          '& > span': {
            fontSize: '1.0rem', color: teal[900], 
            paddingInlineStart: 4, paddingInlineEnd: 0,
          }
        }
      },
    }
  },
  narrow: {
    maxWidth: 600,
    margin: '24px auto',
  },
  upperLimitRoot: {
    width: 800,
    margin: '24px auto',
    '& .userWrap': {
      display: 'flex',
      borderBottom: '1px solid #aaa',
      '& .Num': {
        width: 32, padding: '6px 4px', textAlign: 'right',
        '& .MuiSvgIcon-root': {
          fontSize: '1.2rem', marginTop: 16, color: teal[500]
        },
        '& .brosCloseIcon .MuiSvgIcon-root': {
          fontSize: '1.2rem', marginTop: 16, color: grey[500]
        },

      },
      '& .user': {
        width: 'calc(45% - 15px)',
        alignContent: 'flex-start',
      },
      '& .detail': {
        width: 'calc(55% - 17px)',
        '& .detailRow': {
          backgroundColor: 'transparent',
          '>div': {
            padding: '6px 4px',
          }
        },
        '& .detailRow:last-child': {
          borderBottom: 'none',
        },
      },
    },
    '& .user':{
      display: 'flex',
      flexWrap: 'wrap',
      '& >div' :{
        padding: '6px 4px',
      },
      '& .userName':{
        width: '50%',
        cursor:'pointer',
        position: 'relative',
        '& .icon':{
          right: 2,
          top: 4,
          fontSize: '1.2rem',
          position:'absolute',
          color:teal[800],
        },
      },
      // 兄弟表示のときはこのクラスが付与される
      '& .userName.bros':{
        cursor:'auto',
        // '& .icon':{display:'none'},
      },
      '& .haibunStatus': {
        width: '50%',
        fontSize: '.8rem',
        display:'flex',
        justifyContent: 'space-between',
      },
      '& .userInfo': {
        width: '50%',
      },
      '& .userInfo .t': {
        width: '50%',
        display: 'inline-block',
      },
      
      '& .userInfo .num': {
        width: '50%',
        textAlign: 'right',
        display: 'inline-block',
      },
      '& .userInfo .kanriNum': {
        fontWeight: 600,
        color:teal[500],
      },
      '& .userInfo .kanriNum.kyouryoku': { // 協力事業所用
        fontWeight: 600,
        color:blue[500],
      },
      '& .jougenStatus': {
        // padding: 2, 
        // marginTop: -6, 
        fontSize: '.8rem', color: teal[500],
        // marginBottom: -2, width: '100%', 
        fontWeight: 600,
      },
      '& .jougenStatus.hand': {color: blue[500]}, 
      '& .jougenStatus.off': {color: red[500]}, 
      // '& .userInfo .kanriNumKj': {
      //   fontWeight: 600,
      //   color:blue[500],
      // },
    },
    '& .titleOuter': {
      display: 'flex',
      '& .Num': {
        width: 32,
        padding: '6px 4px',
      },
      '& .titleLeft' :{
        width: '45%',
      },
      '& .titleRight':{
        width: '55%',
      },
    },
    '& .small':{
      fontSize: '.7rem',
    }
  },
  H: {
    backgroundColor: teal[50],padding: 8,paddingLeft: 16,paddingTop: 12,
    position: 'relative',
    '& .notice':{
      position: 'absolute', top:12, right:8, fontSize: '.8rem',
      '& .MuiSvgIcon-root':{
        position: 'absolute', top: -4, left: -20,fontSize: '1.2rem',
        color: blue[800],
      }
    }

  },
  innerButton:{
    padding: 0,
  },
  dialogOpenButtonRoot:{
    position: 'fixed',top: 90,right: 16,
    '& .buttonText':{display: 'flex',},
    '& .buttonText soan':{display: 'block',},
    '& .buttonText span:nth-of-type(1)' :{
      fontSize: '.6rem',margin: '.7rem 2px 0',marginLeft: '.6rem',
    },
    '& .buttonText span:nth-of-type(2)': {
      fontSize: '1.2rem',margin: '0 2px 0'
    },
    '& .buttonText span:nth-of-type(3)': {
      fontSize: '.6rem',margin: '.7rem 2px 0'
    },
    '& .scheduleCount' : {
      padding: 6,textAlign: 'center',
      '& span' :{color:'#00695c',fontWeight: 'bold',}
    },
  },
  plusIcon: {
    '& .MuiSvgIcon-root': {
      display:'block', position: 'absolute',
      top: 4, right: 22,
      fontSize: '1.2rem', color: blue[800],
    }
  },
  brosOnlyDisplay: {
    width:'100%', textAlign:'center', marginBottom: 16,
  },
  detailOutputBrosRoot:{
    borderBottom: '1px #ddd solid',
    '& .detailRow': {borderBottom: 'none'},
    '& .kanrikekka': {
      fontSize: '.8rem', marginLeft:4, marginBottom: 2,
      '& .label': {color: teal[800]},
      '& .value': {color: blue[800], marginInlineStart: 8, },
    },
  },
  upperLimitDialogRoot: {
    '& .MuiDialog-paperWidthSm':{
      maxWidth: 700,
    }
  }
}));

const subMenu = [
  { link: "/proseed", label: "一般" },
  { link: "/proseed/upperlimit", label: "上限管理" },
];

// 上限管理編集用のコンポーネントをラップするダイアログ
const UpperLimitWrapDialog = (props) =>{
  const classes = useStyles();
  const { open, setOpen, close, uid, specifyType } = props;
  const titleStr = ['管理事業所', '協力事業所', '兄弟管理'][parseInt(specifyType)]
  // const titleStr = (specifyType==='1') ? '協力事業所' : '管理事業所';
  const stdDate = useSelector(state => state.stdDate);
  const users = useSelector(state=>state.users);
  const monthStr = stdDate.substr(0, 4) + '年' + stdDate.substr(5, 2) + '月';
  const thisUser = getUser(uid, users);
  const [snack, setSnack] = useState({msg: '', severity: ''});
  return (<>
    <Dialog 
      className={classes.upperLimitDialogRoot}
      open={open} onClose={close}
    >
      <div className='formTitle'>上限管理({titleStr})</div>
      <div className='formSubTitle'>
        <div className='date'>{monthStr}</div>
        <div className='user'>{thisUser.name}</div>
        <div className='age'>{thisUser.ageStr}</div>
      </div>
      <UpperLimitKanri 
        uid={uid} specifyType={specifyType} close={close} 
        snack={snack} setSnack={setSnack}
      />
    </Dialog>
    <SnackMsg {...snack} />
  </>)
}
// 表示するためのデータを作成。使ってない
// const makeData = (prms) =>{
//   // const {stdDate, schedule, users, com, service, dispatch} = prms;
//   // const useResult = makeUseResult(prms);
//   const { billingDt, masterRec } = setBillInfoToSch(prms);
//   console.log('billingDt', billingDt);
//   console.log('masterRec', masterRec);
//   // const billing = makeBiling(billingDt, masterRec);
//   // const upperLimit = makeJugenkanri(billingDt, masterRec);
//   // const useResult = makeTeikyouJisseki(billingDt, masterRec);
//   // console.log('upperLimit', upperLimit);
//   // console.log('useResult', useResult);
//   // return { useResult, upperLimit, billing, billingDt, masterRec };
//   return { billingDt, masterRec};
// }


// MTU用の実費を計算する
// actualCostDetail: Array(2)
// 0: {name: 'おやつ', count: 3, price: 300, unitPrice: 100, classroom: 'tako'}
// 1: {name: 'おやつ', count: 5, price: 500, unitPrice: 100, classroom: 'ika'}
const actualCostMTU = (actualCostDetail, classroom) => {
  // 対象オブジェクトがMTU用かどうか。
  let isMTU = false;
  actualCostDetail.forEach(e=>{if (e.classroom) isMTU = true});
  if (classroom && isMTU){
    const v = actualCostDetail.filter(e=>e.classroom === classroom)
    .reduce((v, e)=>(v + e.price), 0);
    return v;
  }
  else{
    const v = actualCostDetail.reduce((v, e)=>(v + e.price), 0);
    return v;
  }
}
const JihatsuMusyou = () => {
  const allState = useSelector(state=>state);
  const {schedule, com, serviceItems} = allState;
  const isJihatsu = serviceItems.indexOf('児童発達支援') >= 0;
  // 全体設定
  const comSetting = comMod.findDeepPath(
    com, 'addiction.児童発達支援.児童発達支援無料化'
  ) === '1';
  const comSettingStr = comSetting? '設定済み': '未設定';
  // 個別設定
  const jhSchSetting = comMod.findDeepPath(schedule, '児童発達支援', {});
  const pSetting = Object.keys(jhSchSetting).reduce((v, e)=>{
    const t = comMod.findDeepPath(
      jhSchSetting[e], 'addiction.児童発達支援無償化'
    )
    if (t === '1')  v++;
    return v;
  }, 0);
  const style = {paddingTop:8, fontSize: '.8rem', color: blue[900],}
  if (!isJihatsu){
    return null;
  }
  else {
    return (
      <div style={style}>{`
        事業所全体の児発無償化${comSettingStr}です。
        ${pSetting}件のユーザー別無償化設定がされています。
      `}</div>

    )
  }
}

// 売上サマリを出力する
const ProseedSummary = (props) =>{
  const classes = useStyles();
  const {data, checkBilling} = props;
  const users = useSelector(state=>state.users);
  const schedule = useSelector(state=>state.schedule);
  const service = useSelector(state=>state.service);
  const serviceItems = useSelector(state=>state.serviceItems);
  const classroom = useSelector(state=>state.classroom);
  const com = useSelector(state=>state.com);
  // 利用回数、欠席数、欠席加算数を算定
  const schInfo = comMod.getScheduleInfo(schedule, service, users, classroom);
  console.log(schInfo, 'schInfo');
  const weekDayCnt = Object.keys(schInfo.uidCounts).reduce(
    (v, e)=>(v + schInfo.uidCounts[e].weekDayCnt), 0
  );
  const schoolOffCnt = Object.keys(schInfo.uidCounts).reduce(
    (v, e)=>(v + schInfo.uidCounts[e].schoolOffCnt), 0
  );
  const absenceCnt = Object.keys(schInfo.uidCounts).reduce(
    (v, e)=>(v + schInfo.uidCounts[e].absenceCnt), 0
  );
  const kessekiAdicCnt = Object.keys(schInfo.uidCounts).reduce(
    (v, e)=>(v + schInfo.uidCounts[e].kessekiAdicCnt), 0
  );
  // 請求データのチェックが通ってない
  if (!checkBilling.result)  return null;
  // mtuが存在していたら利用回数を別の方法で取得する
  const existMtu =  albcm.getExistMtu(users);
  let cntDt = {};
  if (existMtu){
    cntDt = comMod.getScheduleInfo(schedule, service, users, classroom);
    console.log(cntDt, 'cntDt')
  }
  let kanriOk = true;
  const Summary = ()=>{
    const sumDt = {
      利用人数: 0,
      利用回数: 0,
      単位数: 0,
      算定額: 0,
      利用者負担額: 0,
      自治体助成額: 0,
      実費: 0,
      国保連請求額: 0,
    }
    if (data) {
      data.map(e => {
        const u = comMod.getUser(e.UID, users);
        if (service && service !== u.service) return false;
        if (classroom && !albcm.isClassroom(u, classroom)) return false;
        if (e.countOfUse) sumDt.利用人数++;
        sumDt.利用回数 += parseInt(e.countOfUse);
        sumDt.利用者負担額 += isNaN(e.kanrikekkagaku)? 0: parseInt(e.kanrikekkagaku);
        sumDt.自治体助成額 += isNaN(e.jichiJosei)? 0: parseInt(e.jichiJosei);
        if (existMtu && classroom){
          sumDt.単位数 += parseInt(e.clsTanniTotal[classroom]);
          sumDt.算定額 += parseInt(e.clsUserSanteiTotal[classroom]);
          sumDt.実費 += actualCostMTU(e.actualCostDetail, classroom);
        }
        else{
          sumDt.単位数 += parseInt(e.tanniTotal);
          sumDt.算定額 += parseInt(e.userSanteiTotal);
          sumDt.実費 += parseInt(e.actualCost);
        }
        // 上限管理完了フラグは一度でもfalseになったらfalse
        kanriOk = (kanriOk && e.kanriOk)? true : false;
      });
      sumDt.国保連請求額 = sumDt.算定額 - sumDt.利用者負担額;
      // MTUは利用回数カウント方法変更
      if (existMtu){
        // sumDt.利用回数 = cntDt.reduce((v, e) => (v + e.count, 0));
        let v = 0, w = 0;
        Object.keys(cntDt.uidCounts).forEach(e=>{
          v += cntDt.uidCounts[e].count;
          w += ((cntDt.uidCounts[e].count)? 1: 0);// 利用回数があれば利用人数カウント
        });
        sumDt.利用回数 = v;
        sumDt.利用人数 = w; 
      }
    }
    const rt = Object.keys(sumDt).filter(e=>sumDt[e]).map((e, i)=>{
      return(
        <div key={i} className='flxRow' >
          <div className='rowh w50'>{e}</div>
          <div className='rowd w50 right' >{
            comMod.formatNum(sumDt[e], 1)}
          </div>
        </div>
      )
    });
    const Jichitai = () => {
      const style = {paddingTop:8, fontSize: '.8rem', color: teal[900],}
      if (sumDt.自治体助成額){
        return (
          <div style={style}>自治体助成金が設定済みです。</div>
        )
      }
      else{
        return (
          <div style={style}>自治体助成金は適用されていません。</div>
        )
      }
    }
    const CountSummary = () =>{
      return (
        <div className='countSummary'>
          <div className='title'>利用回数等概要</div>
          <div className='detail'>
            <div>平日利用:<span>{weekDayCnt}</span>回</div>
            <div>休日利用:<span>{schoolOffCnt}</span>回</div>
            <div>利用計:<span>{schoolOffCnt + weekDayCnt}</span>回</div>
            <div>欠席回数:<span>{absenceCnt}</span>回</div>
            <div>うち欠席加算:<span>{kessekiAdicCnt}</span>回</div>
          </div>
        </div>
      )
    }
    return (<>
      <div className={classes.proseedDiv}>
        <div className='H'>
          売上概要
        </div>
        <div className={classes.narrow}>
          {rt}
          {/* {kanriOk &&
            <div className='comment'>利用者負担額は未確定です。</div>
          } */}
          <Jichitai/>
          <JihatsuMusyou/>
          <CountSummary />
        </div>
      </div>
    </>);
  }
  const NoData = () => {
    return (
      <div className={classes.nodata}>
        <div className='icon'>
          <faCalculator fontSize='large'/>
        </div>
        <div className='text'>売上計算実行ボタンを押して下さい。</div>
      </div>

    )
  }
  return(<>
    { data && <Summary />}
    { !data && <NoData /> }
  </>)
}
// ユーザー別売り上げを表示するための配列を作成する
// dataはここではbillingDt
export const proseedByUsersDt = (users, data, service, classroom) => {
  let outa = []; // 出力用配列
  const existMtu = albcm.getExistMtu(users);
  outa = users.map(e => {
    // console.log("test")
    // console.log(e)
    // サービスによるスキップ
    if (service && service !== e.service) return false;
    // 単位（教室）によるスキップ
    if (classroom && !albcm.isClassroom(e, classroom)) return false;

    // このユーザーの請求データ
    const thisBdt = data.find(f => e.hno === f.hno);
    // console.log(thisBdt)
    // console.log("/test")
    if (!thisBdt) return false; //請求データが無いときはfalseを返す
    // 2022/01/24 MTU対策
    let itemTotal;
    if (existMtu && classroom && thisBdt.clsItemTotal){
      itemTotal = thisBdt.clsItemTotal[classroom];
    }
    else{
      itemTotal = thisBdt.itemTotal;
    }
    // サービスコードカウントオブジェクトより送迎に関するコードを抽出
    const sougei = ((itemTotal)?itemTotal:[]).filter(
      f => SOUGEY_SVC_CODE.indexOf(f.s) > -1
    )
    // 送迎回数を算出
    let sougeiCnt = 0;
    sougei.map(f => {
      sougeiCnt += f.count;
    });
    const userFutan = thisBdt.kanrikekkagaku ? thisBdt.kanrikekkagaku : 0;
    // const kokuhoSeikyuu = thisBdt.userSanteiTotal - userFutan;

    //橋本追加　平日休日利用回数取得
    const hashimoto_ddate = Object.keys(thisBdt).filter(x => /^D[0-9]{8}$/.test(x));
    const hashimoto_schedule = hashimoto_ddate.map(x => thisBdt[x]);
    let hashimoto_holiday = 0;
    let hashimoto_weekday = 0;
    hashimoto_schedule.forEach(x => {
      if(x["offSchool"] == 1) hashimoto_holiday++;
      else if(x["offSchool"] == 0) hashimoto_weekday++;
    })
    
    // 2022/01/24 MTU対策
    let tanniTotal, userSanteiTotal, actualCost, countOfUse;
    if (existMtu && classroom && thisBdt.clsItemTotal){
      tanniTotal = thisBdt.clsTanniTotal[classroom];
      userSanteiTotal = thisBdt.clsUserSanteiTotal[classroom];
      actualCost = actualCostMTU(thisBdt.actualCostDetail, classroom);
      countOfUse = thisBdt.clsItemTotal[classroom]
      .filter(f => f.baseItem).reduce((v, f)=>(v + f.count), 0);
    }
    else{
      tanniTotal = thisBdt.tanniTotal;
      userSanteiTotal = thisBdt.userSanteiTotal;
      actualCost = thisBdt.actualCost;
      countOfUse = thisBdt.countOfUse;
    }
    return ({
      pname: e.pname,
      userName: e.name,
      tanni: tanniTotal,
      santei: userSanteiTotal,
      userFutan,
      kokuhoSeikyuu: userSanteiTotal - userFutan,
      sougeiCnt,
      kanriOk: thisBdt.kanriOk,
      countOfUse: countOfUse,
      actualCost: actualCost,
      actualCostDetail: thisBdt.actualCostDetail,
      jichiJosei: thisBdt.jichiJosei,
      hno: thisBdt.hno,
      UID: thisBdt.UID,
      brosIndex: thisBdt.brosIndex,
      holidayNum: hashimoto_holiday ?hashimoto_holiday :0, //橋本追加
      weekdayNum: hashimoto_weekday ?hashimoto_weekday :0, //橋本追加
    });
  }).filter(e=>e);
  outa = outa.filter(e => e !== false).filter(e=>e.tanni);
  return outa;
}
// 利用者ごとの売上を求める
// ユーザー名、単位数、算定額、負担額、請求額
const ProseedByUsers = (props) => {
  const classes = useStyles();
  const {users, data, checkBilling} = props;
  const service = useSelector(state=>state.service);
  const classroom = useSelector(state=>state.classroom);
  // 請求データのチェックが通ってない
  if (!checkBilling.result)  return null;
  let outa = []; // 出力用配列
  // 出力用配列を作成
  if (data){
    outa = proseedByUsersDt(users, data, service, classroom);
  }
  const existMtu = albcm.getExistMtu(users);

  const brosCnt = outa.filter(e=>parseInt(e.brosIndex)>0).length;
  const jichiJoseiCnt = outa.filter(e=>e.jichiJosei).length;
  const userRows = outa.map((e, i)=>{
    return(
      <div className='flxRow' key={i}>
        <div className='wmin rowd right'>{i + 1}</div>
        <div className='w30 rowd'>{e.userName}</div>
        {brosCnt > 0 &&
          <div className='wzen3 rowd right'>{e.brosIndex}</div>
        }
        <div className='w15 rowd right'>
          {comMod.formatNum(e.tanni, 1)}
        </div>
        <div className='w20 rowd right'>
          {comMod.formatNum(e.santei, 1)}
        </div>
        <div className='w15 rowd right'>
          {comMod.formatNum(e.userFutan, 1)}
        </div>
        {jichiJoseiCnt > 0 &&
          <div className='w15 rowd right'>
            {comMod.formatNum(comMod.null2Zero(e.jichiJosei), 1)}
          </div>
        }        
        <div className='w15 rowd right'>
          {comMod.formatNum(e.actualCost, 1)}
        </div>
        <div className='w15 rowd right'>
          {comMod.formatNum(
            e.userFutan + e.actualCost -
            comMod.null2Zero(e.jichiJosei),
            1
          )}
        </div>
        <div className='w20 rowd right'>
          {comMod.formatNum(e.kokuhoSeikyuu, 1)}
        </div>
        <div className='w10 rowd right'>{e.sougeiCnt}</div>
        <div className='w10 rowd right'>{e.countOfUse}</div>
      </div>

    )
  })
  if (!data){
    return null;
  }
  else {
    return(
      <div className={classes.proseedDiv}>
        <div className='H'>
          利用者別売上
        </div>
        <div className='byUser'>
          <div className='flxTitle'>
            <div className='wmin rowd'>No</div>
            <div className='w30 rowd'>利用者名</div>
            {brosCnt > 0 &&
              <div className='wzen3 rowd'>兄弟</div>
            }
            <div className='w15 rowd'>単位数</div>
            <div className='w20 rowd'>算定額</div>
            <div className='w15 rowd'>負担額</div>
            {jichiJoseiCnt > 0 &&
              <div className='w15 rowd'>助成額</div>
            }
            <div className='w15 rowd'>実費</div>
            <div className='w15 rowd'>請求計</div>
            <div className='w20 rowd'>国保請求</div>
            <div className='w10 rowd'>送迎</div>
            <div className='w10 rowd'>利用</div>

          </div>
          {userRows}
          {existMtu === true && classroom !== '' &&
            <div className='mtuNotice'>
              複数単位利用者が存在します。計算結果の端数処理により誤差が生じることがあります。
              また、利用者負担額など按分できない項目もあるのでご注意下さい。    
            </div>
          }
        </div>
      </div>
    )    
  }
}
// サービスコード別の売上を集計する
const ProseedByService = (props) =>{
  const classes = useStyles();
  const service = useSelector(state=>state.service);
  const classroom = useSelector(state=>state.classroom);

  const { users, billingDt, checkBilling } = props;
  if (!checkBilling.result){
    return null;
  }
  const existMtu = albcm.getExistMtu(users);

  let outa = [];
  let itemTotal = [];
  if (billingDt){
    // ユニークなサービスコード作成
    // サービスアイテムの個人別集計を取り出す
    const uscd = new Set();
    billingDt.map(e=>{
      const u = comMod.getUser(e.UID, users);
      const isMtu = albcm.classroomCount(u) > 1;
      if (service && service !== u.service) return false;
      if (classroom && !albcm.isClassroom(u, classroom)) return false;
      const thisItemTotal = (isMtu && classroom)
      ? e.clsItemTotal[classroom]: e.itemTotal;
      if (Array.isArray(thisItemTotal)){
        thisItemTotal.map(f=>{
          uscd.add(f.s);
          itemTotal.push({...f});
        });
      }
    });
    // 配列化
    const uscda = Array.from(uscd);
    uscda.map(e=>{
      itemTotal.map(f=>{
        if (e === f.s){
          const ndx = outa.findIndex(g=>g.s === e);
          // すでに配列に存在する場合は合算
          if (ndx > -1){
            outa[ndx].count += f.count;
            outa[ndx].tanniNum += f.tanniNum;
          }
          // 配列に存在しない場合はそのままpush
          else{
            outa.push(f);
          }
        }
      });
    });
  }

  outa.sort((a, b)=>{
    if (a.baseItem && !b.baseItem) return -1;
    if (!a.baseItem && b.baseItem) return 1;
    if (a.s > b.s) return 1;
    if (a.s < b.s) return -1;
  })  
  const serviceRows = outa.map((e, i) => {
    return (
      <div className='flxRow' key={i}>
        <div className='wmin rowd right'>{i + 1}</div>
        <div className='w10 rowd'>{e.s}</div>
        <div className='w50 rowd'>{(e.c.slice(0, -1))}</div>
        <div className='w10 rowd right'>{comMod.formatNum(e.v, 1)}</div>
        <div className='w10 rowd right'>{comMod.formatNum(e.count, 1)}</div>
        <div className='w15 rowd right'>{comMod.formatNum(e.tanniNum, 1)}</div>
      </div>
    )
  });

  if (!billingDt){
    return null;
  }
  else{
    return (
      <div className={classes.proseedDiv}>
        <div className='H'>
          サービスコード別一覧
        </div>
        <div className='serviceCode'>
          <div className='flxTitle'>
            <div className='wmin rowd'>No</div>
            <div className='w10 rowd'>コード</div>
            <div className='w50 rowd'>サービス名</div>
            <div className='w10 rowd'>単位</div>
            <div className='w10 rowd'>数量</div>
            <div className='w15 rowd'>単位数</div>
          </div>
          {serviceRows}
          {existMtu === true && classroom !== '' &&
            <div className='mtuNotice'>
              複数単位利用者が存在します。計算結果の端数処理により誤差が生じることがあります。
            </div>
          }

        </div>
      </div>
    )
  }
}

const PlusIcon = () =>{
  const classes = useStyles()
  return (
    <span className={classes.plusIcon} >
      <LocalHospitalIcon/>
    </span>
  )
}

// 兄弟上限表示モードであることの表示とその解除
const BrosOnlyDisplay = () => {
  const classes = useStyles();
  const users = useSelector(state=>state.users);
  const controleMode = useSelector(state=>state.controleMode);
  const dispatch = useDispatch();
  const upperLimitBrosUid = 
  controleMode.upperLimitBrosUid?controleMode.upperLimitBrosUid:''
  const name = getUser(upperLimitBrosUid, users).name;
  const handleClick = () => {
    const t = controleMode;
    t.upperLimitBrosUid = '';
    dispatch(Actions.setStore({controleMode: t}))
  }
  
  if (!upperLimitBrosUid) return null;
  return (<>
    <a onClick={handleClick}>
      <div className={classes.brosOnlyDisplay}>
        {name}さんの兄弟のみ表示しています。
        <Button
          variant='contained'
          color='secondary'
          startIcon={<CloseIcon/>}
        >
          解除
        </Button>
      </div>
    </a>
  </>)
}

// 上限管理の表示
const UpperLimitView = (props) =>{
  const classes = useStyles();
  const susers = useSelector(state=>state.users);
  const schedule = useSelector(state=>state.schedule);
  const service = useSelector(state=>state.service);
  const classroom = useSelector(state=>state.classroom) + '';
  const controleMode = useSelector(state=>state.controleMode);
  const upperLimitBrosUid = 
  controleMode.upperLimitBrosUid?controleMode.upperLimitBrosUid:''
  const dispatch = useDispatch();
  // 単位指定を無効にする
  useEffect(()=>{
    if (classroom){
      dispatch(Actions.setStore({classroom: ''}));
    }
  }, [classroom]);
  // 兄弟上限用フィルタ出力
  // ストアにdispatchして兄弟上限のみ表示するようにする
  const brosClickHandler = (ev) => {
    const uid = ev.currentTarget.getAttribute('firstbros');
    if (!uid) return false;
    const t = {...controleMode};
    t.upperLimitBrosUid = t.upperLimitBrosUid? '': uid
    dispatch(Actions.setStore({controleMode: t}));
  }
  const users = susers.filter(e=>(service==='' || e.service === service))
  .filter(e=>(classroom==='' || e.classroom === classroom));
  const [userSelectOpen, setUserSelectOpen] = useState(false);
  const { 
    billingDt , open, setOpen, dialogPrms, setDailogPrms, ...others
  } = props;

  // 利用実績ありのuidを配列化
  const existUsing = (Array.isArray(billingDt))?
  billingDt.filter(e=>e.countOfUse>0 || e.userSanteiTotal > 0)
  .map(e=>comMod.convUID(e.UID).num):[];
  // 管理タイプ設定済みのユーザーを列挙
  const userListInit = users
  .filter(e=>(e.kanri_type || e.brosIndex === '1')).map(e=>{
    const r = {uid: e.uid, checked: false, kanriType: e.kanri_type};
    // 利用実績のあるユーザー
    if (existUsing.indexOf(parseInt(e.uid)) > -1) r.checked = true;
    return r;
  });
  // 手動上限管理設定されているユーザーを追加
  if (!schedule[service]) schedule[service] = {};
  Object.keys(schedule[service]).map(e=>{
    // eはUIDが格納されている
    const o = schedule[service][e]
    const thisJougen = fdp(o, 'addiction.利用者負担上限額管理加算');
    if (thisJougen !== null && thisJougen !== '0'){
      const u = String(comMod.convUID(e).num);
      const p = userListInit.findIndex(f=>f.uid === u);
      if (p > 0){
        userListInit[p].checked = true;
      }
    }
  });
  // すでに協力事業所の利用情報が登録されている場合もチェック
  Object.keys(schedule).filter(e=>e.match(/^UID[0-9]+/)).forEach(e=>{
    let amount = 0;
    if (Array.isArray(schedule[e].協力事業所)){
      amount = schedule[e].協力事業所.reduce((v, e)=>(v + parseInt(e.amount)),0);
    }
    if (amount){
      const u = String(comMod.convUID(e).num);
      const p = userListInit.findIndex(f=>f.uid === u);
      if (p > 0){
        userListInit[p].checked = true;
      }
    }
  });
  // チェックボックスのステイトとして設定
  const [userList, setUserList] = useState(userListInit);
  if (!Array.isArray(billingDt)) return null;
  // ユーザーリストにより選択された請求データ
  const selectedBdt = billingDt.map(e=>{
    const t = userList.filter(f=>f.checked);
    const v = t.find(f=>parseInt(f.uid)===comMod.convUID(e.UID).num);
    if (v)  return e;
  }).filter(e=>e);
  // 管理事業所データ
  const kanri = selectedBdt.filter(e => e.kanriType === "管理事業所");
  // 協力事業所
  const kyouryoku = selectedBdt.filter(e => e.kanriType === "協力事業所");
  // 兄弟用、長兄のみの配列
  const kyoudaiFirst = selectedBdt.filter(e=>parseInt(e.brosIndex) === 1);
  // 数値を表示する。数値ではない場合---を表示
  const NumDisp = (props) => {
    const {n} = props;
    if (isNaN(n)){
      return(
        <span style={{color:red[500], fontWeight: 600,}}>---</span>
      );
    }
    else {
      return(
        <span>{formatNum(n, 1)}</span>
      )
    }
  }
  const DetailOutput = (props) => {
    if (!props.dt) return null;
    // 空白の配列が入ってきたときの処理。異常終了するので対応が必要
    if (!Array.isArray(props.dt)) return null;
    if (!props.dt.length) return null;
    
    // 管理事業所用出力
    if (!props.kyouryoku){
      const thisDetail = props.dt.map((e, i)=>{
        return (
          <div className='flxRow detailRow' key = {i}>
            <div className='w50 noBkColor textEclips'>
              {e.name === 'thisOffice' &&
                '当事業所'
              }
              {e.name !== 'thisOffice' &&
                e.name              
              }
              {e.name !== 'thisOffice' &&
                <span 
                  className='small'
                  style={{marginInlineStart: '8px'}}
                >
                  ({e.no})
                </span>
              }
            </div>
            <div className='w20 right noBkColor'><NumDisp n={e.amount}/></div>
            <div className='w15 right noBkColor'><NumDisp n={e.ichiwari}/></div>
            <div className='w15 right noBkColor'><NumDisp n={e.kettei}/></div>
          </div>
        )
      });
      return thisDetail;
    }
    // 協力事業所用出力
    else if (props.kyouryoku){
      const e = props.dt[0]; // データは一個！
      return(<>
        <div className='flxRow detailRow' key={0}>
          <div className='w30 noBkColor textEclips' >
            管理事業所
          </div>
          <div className='w70 noBkColor textEclips'>
            {e.name}
            <span
              style={{ marginInlineStart: '8px' }}
            >
              ({e.no})
            </span>
          </div>
        </div>
        <div className='flxRow detailRow' key={1}>
          <div className='w50 noBkColor textEclips' >
            当事業所
          </div>
          <div className='w35 right noBkColor'></div>
          <div className='w15 right noBkColor'>
            {formatNum(
              (isNaN(e.kettei)?0 : e.kettei), 1
            )}
          </div>
        </div>
      </>)
    }
  }
  // 複数児童用の詳細出力
  const DetailOutputBros = (props) => {
    const bros = (comMod.getBrothers(props.UID, users));
    const brosUid = bros.map(e=>('UID' + e.uid));
    const bilBros = billingDt.filter(e=>brosUid.indexOf(e.UID) > -1);
    bilBros.sort((a, b)=>(a.brosIndex < b.brosIndex)? -1: 1);
    const KanrikekkaDispa = (props) => {
      if (!props.k){
        return (
          <div className={'kanrikekka'}>
            <span className='label'>上限管理結果設定無し</span>
          </div>
        )
      }
      return (
        <div className={'kanrikekka'}>
          <span className='label'>上限管理結果</span>
          <span className='value'>{props.k}</span>
        </div>
      )
    }
    const thisDetail = bilBros.map((e, i)=>{
      return (
        <div key={i} className={classes.detailOutputBrosRoot}>
          <div className='flxRow detailRow' key = {i}>
            <div className='w50 noBkColor textEclips'>
              {e.name + '(' + e.brosIndex + ')'}
            </div>
            <div className='w20 right noBkColor'>
              {formatNum(e.userSanteiTotal, 1)}
            </div>
            <div className='w15 right noBkColor'>
              {formatNum(Math.floor(e.userSanteiTotal / 10, .1), 1)}
            </div>
            <div className='w15 right noBkColor'>{formatNum(e.ketteigaku, 1)}</div>
          </div>
          <KanrikekkaDispa k={e.kanriKekka}/>
        </div>
      )
    });
    return thisDetail;
  }

  // 配分が配列のどこに書いてあるかわからない！？
  const getHaibun = (ary) =>{
    let v = '未定';
    ary.map(e=>{
      if (e.haibun) v = e.haibun;
    });
    v = (v === '最多利用最大')? '最多最大': v;
    return v;
  }
  const userClickHandler = (ev) =>{
    const uid = ev.currentTarget.getAttribute('uid');
    const specifyType = ev.currentTarget.getAttribute('specifytype');
    setDailogPrms({uid, specifyType});
    setOpen(true);
  }
  // 管理結果フラグに対応したラベルを返す
  const kanriKekkaName = (v) => {
    v = isNaN(v) || !v ? 0: v;
    return ['未設定', '管理事業所充当', '上限内', '調整済み', '設定しない'][parseInt(v)]
  }
  

  const kanriTitle = (
    <div className='titleOuter'>
      <div className='flxTitle'>
        <div className='wmin noBkColor'>No</div>
      </div>
      <div className='titleLeft'>
        <div className='flxTitle'>
          <div className='noBkColor'>利用者 / 概要</div>
        </div>
      </div>
      <div className='titleRight'>
        <div className='flxTitle'>
          <div className='w50 noBkColor'>事業所名/兄弟</div>
          <div className='w20 noBkColor'>算定額</div>
          <div className='w15 noBkColor'>一割</div>
          <div className='w15 noBkColor'>決定額</div>
        </div>
      </div>
    </div>
  )

  // ユーザー表示用。混乱しているので統合する
  const UserNodes = (props) => {
    const {ary, items, numOffset} = props;

    const haibun = (v) => {
      if (items === 'kanri'){
        return '管理 / ' + ((v.協力事業所) ? getHaibun(v.協力事業所) : '未定');
      }
      else if (items === 'kyouryoku') return '協力'
      else if (items === 'bros') return '兄弟'
    }
    // スケジュールオブジェクトに上限管理設定項目もあることを確認する
    // 引数は請求データの1ユーザー分
    const getJougenStatus = (v) => {
      const UID = v.UID;
      const service = v.service;
      // 手動設定の状態を取得
      const forth = fdp(
        schedule, [service, UID, 'addiction', '利用者負担上限額管理加算']
      );
      const rt = {};
      // 手動でonならその状態を返す
      if (forth !== null && parseInt(forth) !== 0){
        rt.forth = true;
        rt.set = (forth === '手動')? true: false;
        return rt;
      }
      // itemTotalに上限管理加算があるか
      // --上限管理サービスコード取得して配列化
      const jougenSvc = Object.keys(JOUGEN_KANRI).map(e=>JOUGEN_KANRI[e].s);
      let jougenFound = false;
      jougenSvc.map(e=>{
        if (v.itemTotal.find(f=>f.s === e)) jougenFound = true;
      });
      rt.forth = false;
      rt.set = jougenFound;
      return rt;
    }
    const JougenDisplay = (props) => {
      const {jStatus} = props;
      return (<>
        {(jStatus.forth && jStatus.set) &&
          <div className='jougenStatus hand'>
            <span>上限手動</span>
          </div>
        }
        {(!jStatus.forth && jStatus.set) &&
          <div className='jougenStatus'>
            <span>上限自動</span>
          </div>
        }
        {(jStatus.forth && !jStatus.set) &&
          <div className='jougenStatus off'>
            <span>上限オフ</span>
          </div>
        }
      </>)
    }
    // ユーザータイプによる設定。ダイアログで使われる
    const st = ['kanri', 'kyouryoku', 'bros'].findIndex(e=>e===items);
    const nodes = ary.map((e, i)=>{
      const firstBros = comMod.getFirstBros(e.UID, users);
      console.log(firstBros, 'firstBros');
      const jStatus = getJougenStatus(e);
      // 兄弟上限モード 出力抑制
      if (upperLimitBrosUid && upperLimitBrosUid !== firstBros) return null;
      const numDivStyle = firstBros? {cursor: 'pointer'}: {};
      // 兄弟を検索するためのアイコンを提供する
      const BrosIcon = () => {
        if (!firstBros) return null;
        if (!upperLimitBrosUid){
          return (
            <div className='brosSerchIcon' >
              <SearchIcon />
            </div>
          )
        }
        else{
          return (
            <div className='brosCloseIcon' style={{color: grey[400]}} >
              <CloseIcon />
            </div>
          )

        }
      }
      return(
        <div className='userWrap' key = {i}>
          <div className='Num' style={numDivStyle}
            firstBros={firstBros?firstBros: ''} // 長兄がいるなら長兄UID
            onClick={ev=>brosClickHandler(ev)}
          >
            <div>
              {i + numOffset}
            </div>
            <BrosIcon />
          </div>
          <div className='user'>
            {/* // 兄弟表示ではクリックできないように見せる。カーソル表示は通常
            // specifyTypeでクリックイベント制御 */}
            <div className={'userName ' + items} 
              onClick={(ev)=>userClickHandler(ev)}
              uid={e.UID} 
              // specifytype={(items)=>getSpecifyType(items)}
              specifytype={st}
            >
              <div style={{width: 'calc(100% - 40px'}}>
                {e.name? e.name: e.pname}
              </div>
              {/* 上限管理が付与されているかどうかを表示 */}
              {/* {jStatus.set === true && items === 'kanri' && <PlusIcon/>} */}
              {jStatus.set === true && <PlusIcon/>}
              <EditIcon className='icon'/>
            </div>
            <div className='haibunStatus'>
              {haibun(e)}
              {items !== 'bros' &&
                <JougenDisplay jStatus={jStatus} />
              }
            </div>

            <div className='userInfo'>
              <span className='t'>算定額</span>
              {/* <span className='num'>{formatNum(e.userSanteiTotal, 1)}</span> */}
              <span className='num'><NumDisp n={e.userSanteiTotal}/></span>
            </div>
            <div className='userInfo'>
             <span className='t'>上限額</span>
              {/* <span className='num'>{formatNum(e.priceLimit, 1)}</span> */}
              <span className='num'><NumDisp n={e.priceLimit}/></span>
            </div>
            <div className='userInfo'>
              <span className='t'>決定額</span>
              <span className='num'><NumDisp n={e.ketteigaku}/></span>
            </div>

            <div className='userInfo'>
              <span className={'kanriNum ' + items}>
                {isNaN(e.kanriKekka) || !e.kanriKekka ?0: e.kanriKekka}
              </span> : 
              {kanriKekkaName(e.kanriKekka)}
            </div>
          </div>
          <div className='detail'>
            {items === 'bros' && <DetailOutputBros UID={e.UID}/> }
            {items === 'kanri' && <DetailOutput dt={e.協力事業所} /> }
            {items === 'kyouryoku' && 
              <DetailOutput dt={e.管理事業所} kyouryoku /> 
            }
            
          </div>
        </div>
      )
    });
    return nodes;
  }
  return(<>
    <div className={classes.H + ' noprint'}>
      上限管理一覧
      <div className='notice'>
        <LocalHospitalIcon/>上限管理加算算定しています。
      </div>
    </div>
    <div className={classes.upperLimitRoot}>
      {/* 兄弟上限フィルタモード */}
      <BrosOnlyDisplay  />
      {kanriTitle}
      {/* {brosOutput} */}
      <UserNodes 
        ary={kyoudaiFirst} items='bros'
        numOffset={1}
      />
      <UserNodes 
        ary={kanri} items='kanri'
        numOffset={kyoudaiFirst.length + 1}
      />
      <UserNodes 
        ary={kyouryoku} items='kyouryoku'
        numOffset={kyoudaiFirst.length + kanri.length + 1}
      />
    </div>
    <div className={classes.dialogOpenButtonRoot + ' noprint'}>
      <Button
        onClick={() => setUserSelectOpen(true)}
        color='secondary'
        variant='contained'
      >
      <GroupIcon fontSize='large' />
        <div className='buttonText'>
          <span>設定済み</span>
          <span>{userList.filter(e=>e.checked).length}</span>
          <span>人</span>
        </div>
      </Button>
    </div>

    <UserSelectDialog
      open={userSelectOpen}
      setOpen={setUserSelectOpen}
      userList={userList}
      setUserList={setUserList}
    />

  </>);
}

export const UpperLimitInner = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [dialogPrms, setDailogPrms] = useState({ uid: '', specifyType: '' });
  const closeHandler = () => setOpen(false);
  const billingDt = useSelector(state => state.billingDt);
  const account = useSelector(state => state.account);
  const permission = comMod.parsePermission(account)[0][0];
  if (permission < 90){
    return <PermissionDenied marginTop='90' />
  }
  else{
    return (<>
      <div className="AppPage proseed" style={props.style}>
        <CheckProgress inline />
        <RecalcButton />
        <div style={{ height: 16 }}></div>
        <UpperLimitView
          billingDt={billingDt}
          open={open} setOpen={setOpen}
          dialogPrms={dialogPrms} setDailogPrms={setDailogPrms}
        />
        <UpperLimitWrapDialog
          open={open} setOpen={setOpen}
          close={closeHandler}
          uid={dialogPrms.uid} specifyType={dialogPrms.specifyType}
        />
      </div>

    </>)
  }
}

export const ProseedUpperLimit = () =>{
  const allState = useSelector(state=>state);
  const ls = comMod.getLodingStatus(allState);
  if (!ls.loaded){
    return (
      <LoadingSpinner />
    )
  }
  else if (ls.error){
    return (
      <LoadErr loadStatus={ls} errorId={'E4935'} />
    )
  }
  else{
    return (<>
      <LinksTab menu={subMenu} />
      <UpperLimitInner style={appPageStyle} />
      <ServiceCountNotice />

    </>)
  }
}

const Proseed = () => {
  const classes = useStyles();
  const users = useSelector(state => state.users);
  const dispatch = useDispatch();
  // // const [proseedDatas, setproseedDatas] = useState('');
  // // ダイアログ用のあれこれを準備
  // // const { open, setOpen, closeHandler, uid, specifyType } = props;
  // const [open, setOpen] = useState(false);
  // const [dialogPrms, setDailogPrms] = useState({uid: '', specifyType: ''});
  // const closeHandler = () => setOpen(false);
  const allState = useSelector(state=>state);
  const {com, service} = allState;
  const billingDt = useSelector(state=>state.billingDt);
  const {account} = allState;
  const ls = comMod.getLodingStatus(allState);
  const [checkBilling, setCheckBilling] = useState({result: false, done: false});
  const [errorState, setErrorState] = useState({
    errorText: '', errorSubText: '', errorId:'', errorDetail:'',
    erroOccured: false,
  });
  const permission = comMod.parsePermission(account)[0][0];
  // 請求データのチェックを行う
  useEffect(()=>{
    const checkResult = albcm.chekBillingDt(billingDt);
    setCheckBilling(checkResult);
    if (checkResult.done && !checkResult.result){
      let msgDetail = '';
      checkResult.detail.map(e=>{
        msgDetail += (e.name + '/');
        msgDetail += (e.content.key) + '、';
      });
      msgDetail = msgDetail.slice(0, -1); // 末尾削除
      setErrorState({
        errorText: 'サービスコードエラーです。', 
        errorSubText: 
          '正しくないサービス、加算が指定されていると思われます。内容をご確認の上' +
          'データの訂正などを行って下さい。' + 
          'データに問題がないのにこのメッセージが表示されるときはサポートまで' +
          'ご連絡をお願いいたします。以下の項目でエラーが発生しています。', 
        errorId:'E334558', 
        errorDetail:msgDetail,
        erroOccured: true,
      });
    }
  },[billingDt]);

  const comAdic = comMod.findDeepPath(com, ['addiction', service]);  
  // 基本設定項目の確認
  if (ls.loaded && !comAdic && service){
    return(
      <StdErrorDisplay 
        errorText = '請求設定項目が未設定です。'
        errorSubText = {`予定作成開始に必要な基本設定項目がありません。設定メニューの
        「請求・加算」から定員や地域区分などを設定して下さい。`}
        errorId = 'E49419'
      />
    )
  }


  if (permission < 90){
    return (<PermissionDenied marginTop='120' />)
  }
  else if (!ls.loaded){
    return (
      <LoadingSpinner />
    )
  }
  else if (ls.error){
    return (
      <LoadErr errorId={'E4932'} loadStatus={ls} />
    )
  }
  else if (errorState.erroOccured){
    return(
      <StdErrorDisplay {...errorState} />
    )
  }
  else{
    return(<>
      <LinksTab menu={subMenu} />
      <div className="AppPage proseed" style={{paddingTop: 92}} >
        <CheckProgress inline />
        <RecalcButton />
        <div style={{height:16}}></div>
        <ProseedSummary data={billingDt} checkBilling={checkBilling}/>
        <ProseedByUsers 
          data={billingDt} users={users} checkBilling={checkBilling}
        />
        <ProseedByService 
          billingDt={billingDt} checkBilling={checkBilling} users={users}
        />
        <ServiceCountNotice />
      </div>
    </>)
  }
}
export default Proseed;