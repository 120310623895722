import React, {useState, useEffect,useRef, } from 'react';
import * as Actions from '../../Actions';
import { useSelector, useDispatch } from 'react-redux';
// import SchHeadNav from './SchHeadNav';
import SchEditDetailDialog from './SchEditDetailDialog';
// import SchTableHead from './SchTableHead';
import * as comMod from '../../commonModule';
import * as mui from '../common/materialUi';
import {LoadingSpinner, LoadErr} from '../common/commonParts';
// import SimpleModal from '../common/modal.sample';
// import SchDailyDialog from './SchDailyDialog';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
// import { common } from '@material-ui/core/colors';
// import AccessTimeIcon from '@material-ui/icons/AccessTime';
// import DriveEtaIcon from '@material-ui/icons/DriveEta';
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
// import AddCircleIcon from '@material-ui/icons/AddCircle';
import EachScheduleContent from './SchEachScheduleContent';
// import SchSaveLater from './SchSaveLater';
import { menu } from './Sch2';
import { LinksTab } from '../common/commonParts';
import { useLocation, useParams, } from 'react-router-dom';
import SnackMsg from '../common/SnackMsg';
import SchUserSettingDialog from './SchUserSettingDialog';
import teal from '@material-ui/core/colors/teal';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';
import { Uaddiction } from '../common/commonParts';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {EachAddiction, } from './SchDaySetting';
import { Block } from '@material-ui/icons';
import { isClassroom } from '../../albCommonModule';


const useStyles = makeStyles({
  usersRoot: {
    width: 180, height:'calc(100vh - 180px)', overflowY:'scroll',
    position: 'relative',
    '& .userWrap':{
      display:'flex', alignItems:'flex-end' ,cursor:'pointer',
      padding: 8, paddingTop: 4,
      '& .n': {width: 16, textAlign: 'center', fontSize:'.8rem', marginRight: 8},
      '& .name': {
        flex: 1, whiteSpace: 'nowrap',textOverflow: 'ellipsis',
        paddingTop: 12,
        position: 'relative',
        '& .uaddiction': {
          position: 'absolute', top:0, left: 0, fontSize: '.5rem',
          color: teal[300],
        },
      },
      '& .kanri': {width: 16, fontSize:'.8rem',fontWeight:600},
    },
    '& .detailWrap': {fontSize: '.7rem', paddingLeft: '2rem', paddingBottom: 8,},
    '& .oneUserWrap:nth-of-type(even)':{backgroundColor: grey[100]},
  },
  showDetailSw: {
    cursor: 'pointer', position: 'absolute', right: 0, top: -34,
    display: 'flex', alignItems: 'center', 
    '& .MuiSvgIcon-root':{display:'Block', fontSize:'2rem', color:teal[500]},
    '& .text': {display:'inline-block', padding:4, fontSize: '.7rem'},
  },

});

const OneUser = (props) => {
  const classes = useStyles();
  const {thisUser, n, riyouCount, showDetail, } = props;
  const kan = thisUser.kanri_type.slice(0, 1);
  const schedule = useSelector(state=>state.schedule);
  const kanStyle = (kan === '管')? {color: teal[500]}: {color:blue[500]};
  const typeLetter = (thisUser.type === '障害児')? '': 'J';
  const service = thisUser.service;
  const UID = 'UID' + thisUser.uid;
  const thisUserAddiction = comMod.findDeepPath(
    schedule, [service, UID, 'addiction']
  );
  const showOrHide = showDetail? {display: 'block'}: {display:'none'}

  return (<>
    <div className='userWrap'>
      <div className='n'>{n + 1}</div>
      <div className='name'>
        {thisUser.name}{typeLetter}
        <div className='uaddiction'>
          <Uaddiction {...thisUserAddiction} />
        </div>
      </div>
      <div className='kanri' style={kanStyle}>{kan}</div>
      {/* <div className='riyouCnt'>{riyouCount}</div>
      <div className='age'>{thisUser.ageStr}</div> */}
    </div>
    <div className='detailWrap' style={showOrHide}>
      <EachAddiction thisAdc={thisUserAddiction} />
    </div>
  </>)
}


const MainSchUserSetting = () =>{
  const classes = useStyles();
  const users = useSelector(state=>state.users);
  const service = useSelector(state=>state.service);
  const classroom = useSelector(state=>state.classroom);
  const schedule = useSelector(state=>state.schedule);
  // ダイアログに渡すためのユーザID
  const [suid, setSuid] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  // 柱の中で加算等の詳細情報を表示するかどうか
  const [showDetail, setShowDetail] = useState(true);
  const [snack, setSnack] = useState({msg: '', severity: ''})
  const handleClick = (ev) => {
    const uid = ev.currentTarget.getAttribute('uid');
    setSuid(uid);
    setDialogOpen(true);
  }
  const eachUser = users
  .filter(e=>e.service === service)
  // .filter(e=>e.classroom === classroom || classroom === '')
  .filter(e=>isClassroom(e, classroom))
  .map((e, i)=>{
    const UID = 'UID' + e.uid;
    const r = comMod.getRiyouCountOfUser(schedule[UID]);
    const thisUser=comMod.getUser(UID, users);
    return (
      <div className='oneUserWrap' 
        uid={e.uid} key={i} onClick={(ev)=>handleClick(ev)}
      >
        <OneUser 
          uid={e.uid} key={i} onClick={(ev)=>handleClick(ev)}
          thisUser={thisUser} riyouCount={r.riyou} n={i} 
          showDetail={showDetail}
        />
      </div>
    );
  });
  const handleExpandClick = () =>{
    if (showDetail) setShowDetail(false);
    else setShowDetail(true);
  }
  return (<>
    <div className={classes.showDetailSw} onClick={handleExpandClick}>
      {!showDetail && 
        <><div className='text'>詳細表示</div><ExpandMoreIcon/></>
      }
      {showDetail && 
        <><div className='text'>詳細表示を隠す</div><ExpandLessIcon/></>
      }
    </div>
    <div className={classes.usersRoot}>
      <div className='usersWrap'>
        {eachUser}
      </div>
      <SchUserSettingDialog 
        open={dialogOpen} setOpen={setDialogOpen} uid={suid} 
      />
    </div>
  </>);
}

const ErrSchUserSetting = ()=>{
  return(<div>error occured.</div>)
}

const SchUserSetting = ()=>{
  const userFtc = useSelector(state => state.userFtc);
  const fetchCalenderStatus = useSelector(state => state.fetchCalenderStatus);
  // fetch状態の取得
  const done = (
    userFtc.done && fetchCalenderStatus.done
  );
  const errorOccured = (
    userFtc.err || fetchCalenderStatus.err
  );
  const loading = (
    userFtc.loading || fetchCalenderStatus.loading
  );

  if (done) return (<MainSchUserSetting />);
  else if (loading) return (<LoadingSpinner />);
  else if (errorOccured) return (<ErrSchUserSetting />);
  else return null;
}
export default SchUserSetting;