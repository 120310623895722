import React from 'react';
import * as Actions from '../../Actions';
import { useSelector, useDispatch } from 'react-redux';
import * as comMod from '../../commonModule';
import * as mui from '../common/materialUi';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { makeStyles } from '@material-ui/core/styles';
import teal from '@material-ui/core/colors/teal';
import CheckIcon from '@material-ui/icons/Check';
import { grey, orange,blue,red } from '@material-ui/core/colors';

const useStyles = makeStyles({
  absencedRoot:{
    '& .inner' :{
      opacity: .6,
    },
    '& .dateCellAbsenceIcon' : {
      position: 'absolute',
      // top: '5px',
      color: red[900],
      opacity: 1,
      width: '20%',
      left: '30%',
      top: '25%',
      '& .MuiSvgIcon-root' : {
        fontSize: '2rem',
      }
    },
    '& .virticalAbsenceIcon' : {top: 0, left: 74,},
    '& .kasan': {color: blue[900]}
  },
  normalRoot:{
    '& .dateCellAbsenceIcon': {
      display: 'none',
    }
  },
  schoolOffRoot: {
    '& .icon':{color: '#e77052'},
    '& .dateCellAbsenceIcon': {
      display: 'none',
    },
  },
  useCheck :{
    position:'absolute',
    top: 0,
    right: 0,
    color: teal[400],
    '& .MuiSvgIcon-root': {
      fontSize: '1.5rem',
    }
  },
  // グリッド表示時の共有。index.cssの定義を削除してこちらで再定義
  sqCommon: {
    '& .inner': {
      display:'flex',
      '& .icon': {
        width:'25%',
        '& svg':{fontSize: '1.1rem',},
      },
      '& .icon.fa': {fontSize: '.9rem',paddingRight: '.2rem',},
      '& .text':{
        width: '75%',display: 'flex',
        flexDirection: 'column',justifyContent: 'center',
        '& .transfer' :{
          paddingTop: 4,
          '& .transferChildren': {
            display: 'inline-block',maxWidth: '4rem',
            textOverflow: 'ellipsis',whiteSpace: 'nowrap',
            overflow: 'hidden',
          },
          '& svg': {fontSize: '.7rem',verticalSlign: 'top',}
        },
      },
    },
  },
  // クリッド表示ではなく縦積み表形式
  // コンテンツは横長になる
  hzCommon: {
    display: 'flex',height:32, alignItems: 'center', position:'relative',
    width: 840, margin: '0 auto',
    '& .inner': {
      display:'flex',heigt: 28,
      '& .icon': {
        width:'40px',textAlign:'right',padding: 3,
        '& svg':{fontSize: '1.1rem',},
      },
      '& .icon.fa': {fontSize: '.9rem',paddingRight: '.2rem', marginTop: 2},
      '& .text.timeWrap': {
        display: 'flex', width: 180,
        '& .time': {width: 58, },
      },
      '& .text':{
        display:'flex',padding: 4,
        '& .transfer' :{
          width: 164,
          '& .transferChildren': {
            display: 'inline-block',maxWidth:80,textOverflow:'ellipsis',
            overflow: 'hidden', whiteSpace: 'nowrap',
          },
          '& svg': {fontSize: '.7rem',verticalAlign: 'top', marginTop: 2,}
        },
      },
    },
    '& .inner.date': {
      width: 90, justifyContent: 'center',
      '& .d': {width: 30, textAlign:'center'},
      '& .w': {width: 20, textAlign:'center'},
    },
  },
});

export const EachScheduleContent = (props) => {
  const classes = useStyles();
  const virtical = props.virtical;
  if (!props.thisSchedule && !virtical) return null;
  const e = (props.thisSchedule)? props.thisSchedule: false;
  const transfer = (e)? e.transfer.concat(): ['',''];
  transfer[0] = (transfer[0] === '') ? '無し' : transfer[0];
  transfer[1] = (transfer[1] === '') ? '無し' : transfer[1];
  // 実費項目をjsxで表現しやすくする
  const actual = () => {
    let text = '';
    let cost = 0;
    let full = '';
    if (!e) return null;
    Object.keys(e.actualCost).map(f => {
      text += f + ', ';
      cost += parseInt(e.actualCost[f]);
      full += f + ' : ' + e.actualCost[f] + '円';
    });
    text = text.slice(0, -2); // 末尾二文字を削除
    let short = text;
    const length = Object.keys(e.actualCost).length;
    if (short.length > 4) short = short.substr(0, 4) + '..';
    return { text, short, cost, length, full };
  }
  const actualObj = actual();
  //　加算項目を表現しやすいようにオブジェクト化
  const aKeys = (e.dAddiction === undefined) ? [] : Object.keys(e.dAddiction);
  const addiction = () => {
    let long = '';
    let short = '';
    aKeys.map(f => {
      long += e.dAddiction[f] + ', ';
      short += f + ', ';
    });
    if (short.length > 5) short = short.substr(0, 5) + '..';
    const length = aKeys.length;
    return { long, short, length };
  }
  const addictionObj = addiction();
  // 欠席の表示を行う
  // 欠席加算のありなしを確認
  const kessekiItem = aKeys.find(f=>f.indexOf('欠席時対応加算') > -1);
  const AbsenseIcon = (props) => {
    const {virtical} = props;
    const classes = useStyles();
    const virticalStyle = virtical ? 'virticalAbsenceIcon ': '';
    const kasan = (kessekiItem)? 'kasan ': ''
    return (
      <div className={'dateCellAbsenceIcon ' + virticalStyle + kasan}>
        <NotInterestedIcon />
      </div>
    )
  }
  // 欠席表示のためのクラス設定。
  let rootClass = (parseInt(e.offSchool) === 0)? 
    classes.normalRoot : classes.schoolOffRoot;
  rootClass = (e.absence) ? classes.absencedRoot : rootClass;
  const commonRoot = (virtical)? classes.hzCommon: classes.sqCommon;
  const d = (props.d)? props.d: {holiday: 0}; // 日付オブジェクト
  const wdClass = ['', 'schoolOff', 'off'][d.holiday];
  const ThisContent = () => (<>
    <div className="inner">
      <div className='icon'>
        <AccessTimeIcon />
      </div>
      <div className='text timeWrap'>
        <div className="time">{e.start}</div>
        <div className="time">{e.end}</div>
      </div>
    </div>
    <div className="inner">
      <div className='icon'>
        <DriveEtaIcon />
      </div>
      <div className="text">
        <div className={"transfer" }>
          <div className={'transferChildren'}>
            {transfer[0]}
          </div>
          <ArrowForwardIosIcon />
          <div className={'transferChildren'}>
            {transfer[1]}
          </div>
        </div>
      </div>
    </div>
    <div className="inner">
      <div className='icon fa'>
        <i className="fas fa-yen-sign fa-fw "></i>
      </div>
      <div className="text">
        <div className="actual">
          {actualObj.length + '件 ' + actualObj.cost + '円'}
        </div>
      </div>
    </div>
    <div className="inner">
      <div className="icon">
        <AddCircleIcon />
      </div>
      <div className="text">
        <div className="addiction">
          {addictionObj.short + addictionObj.length + '件'}
        </div>
      </div>
    </div>
  </>);

  return (
    <div className={"dateContent " + rootClass + ' ' + commonRoot}>
      {/* 利用実績チェック表示 */}
      {e.useResult === true &&
        <div className={classes.useCheck}>
          <CheckIcon />
        </div>
      }
      <AbsenseIcon virtical={virtical}/>
      {virtical === true &&
        <div className={'inner date ' + wdClass}>
          <div className='d'>{d.date.getDate()}</div>
          <div className='w'>
            {'日月火水木金土'.substr(d.date.getDay(), 1)}
          </div>        
        </div>
      }
      {e !== false && <ThisContent/>}
    </div>
  )
}
export default EachScheduleContent;