import React, { useEffect, useState, useRef } from 'react';
import * as Actions from '../../Actions';
import { 
  connect, useDispatch, useSelector, 
  // ReactReduxContext  
} from 'react-redux';
import * as comMod from '../../commonModule';
import * as albcm from '../../albCommonModule';
import SchEditDetailDialog from './SchEditDetailDialog';
import SchByUserDialog from './SchByUserDialog';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {red, teal, blue} from '@material-ui/core/colors/';
import SnackMsg from '../common/SnackMsg';
import * as mui from '../common/materialUi'
import {Uaddiction} from '../common/commonParts';
import { faSleigh } from '@fortawesome/free-solid-svg-icons';
import { faLess } from '@fortawesome/free-brands-svg-icons';

const useStyles = makeStyles({
  absIconRoot: {
    position: 'absolute', top: 2, color: red[900],
    '& .MuiSvgIcon-root':{
      width: '.85em'
    },
    '& .kasan': {color: blue[900]}
  },
  namerator: {
    width: 30,
  }
});

// 管理タイプを表示
const KanriType = (props)=>{
  if (!props.kanri_type)  return '';
  else{
    const c = (props.kanri_type.substr(0, 1) === '管') ? '管':'協';
    const cn = (props.kanri_type.substr(0, 1) === '管') ? '' : 'kyo'
    return(
      <span className={"kanriType " + cn}>{c}</span>
    )
  }
}

// ユーザーごとの利用回数を求める
// 欠席加算などを取得したときは absence の後でも回数に入れるべき？
const countVisit = (sch) => {
  if (!sch){
    return {cnt: 0, other: 0};
  }
  else{
    let cnt = 0, other = 0;
    Object.keys(sch).map(e =>{
      if (e.indexOf('D') !== 0){
        return false;
      }
      if (!sch[e].absence){
        cnt++;
      }
      else if (comMod.findDeepPath(sch[e], 'dAddiction.欠席時対応加算')){
        other++;
      }
      else if (comMod.findDeepPath(sch[e], 'dAddiction.家庭連携加算')){
        other++;
      }
      else if (comMod.findDeepPath(sch[e], 'dAddiction.事業所内相談支援加算')){
        other++;
      }
    });
    return {cnt, other};
  }
}



const RowTitle = (props) => {
  const classes = useStyles();
  const {
    thisUser, index, sch, setSch, setSnack, // userCh, setUserCh
    setUserOpe,
  } = props;
  const count = countVisit(sch);
  const countClass = count.cnt + count.other > thisUser.volume ? ' over' : '';
  const schedule = useSelector(state=>state.schedule);
  const service = thisUser.service;
  const UID = 'UID' + thisUser.uid;
  const thisUserAddiction = comMod.findDeepPath(
    schedule, [service, UID, 'addiction']
  );
  return (<>
    <div 
      className='wmin center rowTitle noBkColor' 
      id={'UID' + thisUser.uid + 'RT'}
    >
      <div>{index + 1}</div>
      <div>
        <KanriType kanri_type={thisUser.kanri_type} />
      </div>
    </div>
    <div className='w15 scdRowUserTitle'
      // onClick={clickHandler}
      uid={'UID' + thisUser.uid}
      did={""}
      service={thisUser.service}
      operation={1}
      off={""}
    >
      <div className="name">
        {thisUser.name}
        {/* <KanriType kanri_type = {thisUser.kanri_type} /> */}
      </div>
      <div className='small rowInformation'>
        {thisUser.ageStr}
        <span className={'counter' + countClass}>
          <span className={'inNameVol namerator'}>
            {count.cnt}
            {count.other > 0 &&
              '+' + count.other
            }
          </span>
          /
          <span className='inNameVol'>{thisUser.volume}</span>
        </span>
        <span className="uaddiction">
          <Uaddiction {...thisUserAddiction} />
        </span>
      </div>
      <SchByUserDialog 
        uid={thisUser.uid} sch={sch} setSch={setSch} setSnack={setSnack}
        setUserOpe={setUserOpe}
        // userCh={userCh} setUserCh={setUserCh}
      />
    </div>

  </>)
}

// 利用実績のチェック
const UseChecked = (props) =>{
  const dsch = (props.dsch)? props.dsch: {};
  if (dsch.useResult){
    return(<div className='useChecked' /*key={did}*/ />)
  }
  else{
    return null;
  }
}
// 日付セルの内側に描画するコンテンツ
const DateCellInner = (props)=>{
  const {dsch, did, otherClassroomItem} = props;
  
  const  Cnt = ()=>{
    const iconClass = (dsch.offSchool === 1) ? 'offSchool' : ''
    const transfer = dsch.transfer.filter(e=>e !== '').length;
    const actualCost = (dsch.actualCost)?Object.keys(dsch.actualCost).length: 0;
    const dAddiction = (dsch.dAddiction !== undefined)?
      Object.keys(dsch.dAddiction).length : 0;
    const otherClassroomStyle = (otherClassroomItem)? {opacity: .3}: {};
    return (
      <div key={did} style={otherClassroomStyle}>
        <div>
          {/* 送迎数を表示 */}
          <i className={"fas fa-car fa-fw " + iconClass}></i>
          <span className="num">{transfer}</span>
        </div>
        <div>
          {/* 実費の数を表示 */}
          <i className={"fas fa-yen-sign fa-fw " + iconClass}></i>
          <span className="num">{actualCost}</span>
        </div>
        <div>
          {/* 加算減算数を表示 */}
          <i className={"fas fa-plus-circle fa-fw " + iconClass}></i>
          <span className="num">{dAddiction}</span>
        </div>
      </div>
    )
  }
  if (!dsch)  return null;
  else if (Object.keys(dsch).length) return <Cnt/>;
  else return null;
}

// 欠席表示を行う
const Absense = (props) =>{
  const classes = useStyles();
  const dsch = props;
  if (!dsch)  return null;
  // 欠席対応加算があるか
  const isKasan = (dsch.dAddiction)?
  Object.keys(dsch.dAddiction)
  .find(e=>e.indexOf('欠席時対応加算') > -1): null;
  const kasan = (isKasan)? 'kasan ': ''
  // const kasan = 'kasan';
  if (dsch.absence){
    return(
      // <div className={'dateCellAbsenceIcon ' + classes.absIconRoot}>
      <div className={classes.absIconRoot}>
        <div className={kasan}>
          <NotInterestedIcon/>
        </div>
      </div>
    )
  }
  else{
    return null;
  }
}

// テンプレートの位置が分かりづらいのでこの関数で取得
const getTemplate = (allState) => {
  // ストア初期値からテンプレートを取得
  let service = allState.service;
  const serviceItems = allState.serviceItems;
  service = service? service: serviceItems[0];
  const storeTemplate = allState.scheduleTemplate[service];
  // ストア comからテンプレート取得
  const com = allState.com;
  const comTemplate = comMod.findDeepPath(
    com, ['etc', 'scheduleTemplate', service]
  );
  // テンプレートを設定
  const scheduleTemplate = comTemplate? comTemplate: storeTemplate;
  // クラスルームが付与されていることがあるので削除
  delete scheduleTemplate.classroom;
  return scheduleTemplate;
}

// それぞれの日付セル
const DateCellOne = (props) => {
  const {
    thisUser, sch, setSch, dateList, did, UID, date, localFabSch,
    dialogOpen, setDialogOpen, setSnack, setUserOpe,
  } = props;
  const hid = useSelector(state=>state.hid);
  const bid = useSelector(state=>state.bid);
  const stdDate = useSelector(state=>state.stdDate);
  const service = useSelector(state=>state.service);
  const classroom = useSelector(state=>state.classroom);
  const users = useSelector(state=>state.users);

  const dsch = (sch[did])? {...sch[did]}: {}; // 該当日のスケジュールオブジェクト
  if (dsch.useResult){
    console.log(dsch, dsch.useResult, 'dsch');
  }
  
  // 休業日、休校日を指定するクラス名
  const holidayClass = ['', 'schoolOff', 'off'][date.holiday];
  // 月水金が有効になるクラス
  const mwfClass = [1, 3, 5].indexOf(date.date.getDay()) >= 0 ? ' mwfClass' : '';
  // 日曜日が有効になるクラス
  const sunClass = date.date.getDay() === 0 ? ' sunClass' : '';

  // 欠席のときのクラス名
  const absenceClass = (dsch.absence) ? 'absensed' : '';
  // セルがホバーしたときの色を変える
  const hoverClass = ['', 'hoverAddRemove', 'hoverAddEdit'][localFabSch];
  // スケジュールテンプレートの取得
  const allState = useSelector(state=>state);
  const scheduleTemplate = getTemplate(allState);
  // ストアのスケジュール取得
  const schedule = useSelector(state=>state.schedule);
  // ユーザー送信API用
  const [res, setRes] = useState('');
  // 追加削除監視用
  const [watchAddRemove, setWatchAddRemove] = useState(false);
  // 利用実績フラグ
  const useResult = dsch.useResult;
  const isMtu = albcm.classroomCount(thisUser) > 1;
  // ダイアログの監視
  useEffect(()=>{
    // dialogOpenのstateを監視。変化があればschを更新
    // この処理必要なのか？？ 直接ダイアログにsch持っていっても良かったような
    const uidDidMatch = (dialogOpen.uid === UID && dialogOpen.did === did);
    const existUscch = Object.keys(dialogOpen.usch).length;

    if (existUscch && uidDidMatch /*&& !dialogOpen.open*/){
      setSch(dialogOpen.usch);
    }
  }, [dialogOpen]);

  // 追加削除の監視
  useEffect(()=>{
    const sendPrms = {
      hid, bid, date:stdDate, uid: UID, schedule: sch
    };
    if (watchAddRemove){
      setWatchAddRemove(false);      
    }
  }, [watchAddRemove]);

  // 追加削除を行う
  const addRemove = () => {
    const t = {open: true, did, uid: UID, usch: sch};
    const thisClr = (sch[did])? sch[did].classroom: '';
    if (thisClr && thisClr !== classroom){
      const id = new Date().getTime();
      setSnack({
        msg: '別単位の予定なので編集できません。', 
        severity: 'warning', id
      });
      return false;
    }

    const template = (date.holiday)? 
    {...scheduleTemplate.schoolOff}: {...scheduleTemplate.weekday};
    // templateにclassroomが付与されていることがある？？？
    delete template.classroom;
    if (isMtu){
      template.classroom = classroom;
    }
    if (Object.keys(dsch).length){
      // スケジュールが存在するので削除
      const t = {...sch};
      delete t[did];
      setSch(t);
    }
    else{
      // スケジュールが存在しないので追加
      const t = {...sch};
      t[did] = {...template};
      setSch(t);
    }
    setWatchAddRemove(true);
  }
  // 詳細設定のダイアログを開く
  // スケジュールがないときは単純追加
  const addEdit = () => {
    const t = {open: true, did, uid: UID, usch: sch};
    const thisClr = (sch[did])? sch[did].classroom: '';
    if (thisClr && thisClr !== classroom){
      const id = new Date().getTime();
      setSnack({
        msg: '別単位の予定なので編集できません。', 
        severity: 'warning', id
      });
      return false;
    }
    // なければ追加
    if (!Object.keys(dsch).length){
      addRemove();
      return true;
    }
    else{
      setSnack({msg: '', severity: '', id: new Date().getTime()});
    }
    setDialogOpen(t);
  }

  const clickHandler = (ev) => {
    if (!localFabSch)  return false;
    const locked = albcm.schLocked(
      schedule, users, thisUser, did, service, classroom
    )

    if (!classroom && isMtu){
      const id = new Date().getTime();
      setSnack({
        msg: 'この利用者は複数単位があるので編集できません。', 
        severity: 'warning', id
      });
      return false;
    }
    if (locked){
      const id = new Date().getTime();
      setSnack({msg: '予定はロックされています。', severity: 'warning', id});
      return false;
    }
    else{
      setSnack({msg: '', severity: '', id: 0});
    }
    // const trg = ev.currentTarget;
    // addRemove();
    if (localFabSch === 2){
      addEdit();
    }
    else if (localFabSch === 1){
      addRemove();
    }
    // ユーザによるオペレーションのスイッチをセット
    setUserOpe(true);
  }

  const UseResult = () => {
    if (useResult){
      return (
        <div className='useChecked' key={did} />
      )
    }
    else{
      return null;
    }
  }
  // 別単位でのクラス設定
  const otherClassroomItem = 
  (classroom && dsch.classroom && dsch.classroom !== classroom)
  
  return (
    <div
      uid={UID}
      did={did}
      // onMouseEnter={dateCellGetHover}
      // onMouseLeave={dateCellLostHover}
      className={
        hoverClass +
        ' dateCell w03 center small ' 
        + holidayClass + ' ' + mwfClass + ' ' + sunClass + ' ' + absenceClass
      }
      // style={cellStyleHover}
      id={props.UID + did}
      holiday={date.holiday}
      // service={props.service}
      // operation={0}
      onClick={ev=>clickHandler(ev)}
      // onClick={e=>clickHandler(e, open, setopen)}
    >
      <UseChecked {...dsch}/>
      <DateCellInner 
        dsch={dsch} did={did} 
        otherClassroomItem={otherClassroomItem}
      />
      <Absense {...dsch}/>
      <UseResult />
    </div>
  )
} 

// 日付のセルのローダー
const DateCells = (props) => {
  const {
    thisUser, sch, setSch, dateList, UID, dialogOpen, setDialogOpen,
    localFabSch, setUserOpe, setSnack,
  } = props;
  
  // 日付ごとに処理
  const days = dateList.map((e, i)=>{
    const did = comMod.convDid(e.date);
    const dProps = {...props, did, date: e}
    return (
      <DateCellOne {...dProps} key={i}/>
    )
  });
  return days;
}

const SchRow = (props) => {
  const {
    thisUser, index, localFabSch, setLocalFabSch, 
    countsOfUse, setCountsOfUse, 
    setSnack, 
  } = props;
  // console.log(thisUser.name, thisUser.uid, 'SchRow');
  const ssch = useSelector(state=>state.schedule);
  const users = useSelector(state=>state.users);
  const dateList = useSelector(state=>state.dateList);
  const stdDate = useSelector(state=>state.stdDate);
  const hid = useSelector(state=>state.hid);
  const bid = useSelector(state=>state.bid);
  const classroom = useSelector(state=>state.classroom);
  const dispatch = useDispatch();
  // ユーザーによる操作があったかどうか。
  const [userOpe, setUserOpe] = useState(false);
  const schedule = ssch? ssch: {}; // スケジュールデータのnullの時は空白オブジェクト
  const UID = 'UID' + thisUser.uid;
  const [sch, setSch] = useState(schedule[UID]? schedule[UID]: {});
  // ユーザごとのスケジュールデータが変更されたかどうかのフラグ。
  const [schCh, setSchCh] = useState(false);
  // ユーザー情報が更新されたら値を格納。unmountでdispatch
  // const [userCh, setUserCh] = useState(null);
  const rowProps = {
    ...props, sch, setSch, UID, dateList, localFabSch,
    schCh, setSchCh,
    setUserOpe,
  }
  const [res, setRes] = useState('');
  const userName = thisUser.name;
  // const path = useLocation().pathname;

  // countsOfUseの要素に該当するdidを特定するための文字列
  const keyBase = 'D' + stdDate.replace(/\-/g, '').slice(0, 6);

  // useRef使ってみるよ
  const firstRender = useRef(false);
  useEffect(() => { // このeffectは初回レンダー時のみ呼ばれるeffect
    firstRender.current = true
  }, []);
  // schの監視
  useEffect(()=>{
    // 利用数の更新
    const c = {...countsOfUse};
    c[UID].map((e, i)=>{
      const key = keyBase + comMod.zp(i + 1, 2);
      if (sch[key]){
        if (sch[key].absence) c[UID][i] = 0;
        else                  c[UID][i] = 1;
        // MTU対応
        if (sch[key].classroom && sch[key].classroom !== classroom)
          c[UID][i] = 0; 
        // 欠席時対応加算を確認
        // const kAddic = comMod.findDeepPath(sch[key], 'dAddiction.欠席時対応加算');
        // if (kAddic) c[UID][i] = 1;
      }
      else{
        c[UID][i] = 0;
      }
    });
    setCountsOfUse(c);
    // dbに送信 初回は送信しない！
    if (firstRender.current){
      firstRender.current = false;
    }
    else if (userOpe){ // ユーザの操作があったの時のみディスパッチを実行
      const sendPrms = {
        uid: UID, hid, bid, date: stdDate, schedule: sch 
      }
      const f = async () => {
        const a = await albcm.sendUsersSchedule(
          sendPrms, setRes, setSnack, userName
        );
      }
      f();
    }
    
    // Component破棄時にstoreにDispatch
    const t = schedule;
    t[UID] = sch;
    
    return () => {
      // ここで場合によっては無限レンダリング。
      if (userOpe){
        dispatch(Actions.setStore({schedule: t, }));
      }
    }   
  },[sch]);

  return(
    <div className='scdRow flxRow'>
      <RowTitle {...rowProps} />
      <DateCells {...rowProps} />
    </div>
  )
}

const SchTableBody2 = (props) => {
  const dispatch = useDispatch();
  const users = useSelector(state=>state.users);
  const schedule = useSelector(state=>state.schedule);
  const service = useSelector(state=>state.service);
  const classroom = useSelector(state => state.classroom);
  const comAdic = useSelector(state=>state.com.addiction);
  const hid = useSelector(state=>state.hid);
  const bid = useSelector(state=>state.bid);
  const [res, setRes] = useState(); 
  const [dialogOpen, setDialogOpen] = useState({
     open: false, uid: '', did: '' , usch: {}
  });
  const [snack, setSnack] = useState({msg: '', severity: '', id: 0});
  // const [work, setWork] = useState({});
  // scheduleの中間データ 子Componentでの変更を保持する
  // const [schWork, setSchWork] = useState({...schedule});
  const rProps = {
    ...props, dialogOpen, setDialogOpen, setSnack, // work, setWork
    // schWork, setSchWork,
  };

  const serviceAsTanni = comAdic[service]? comAdic[service].サービスごと単位: null;
  const {countsOfUse, setCountsOfUse} = props;
  // サービスごと単位ではない場合、別サービスの回数をカウントする必要あり
  // なので対象レコードに予め書き込んでおく処理を追加した
  useEffect(()=>{
    if (!serviceAsTanni){
      const tUsers = users.filter(e=>albcm.isClassroom(e, classroom));
      const t = {...countsOfUse}
      tUsers.forEach(e=>{
        const k = 'UID' + e.uid;
        if (t[k] && schedule[k]){
          Object.keys(schedule[k]).filter(f=>f.indexOf('D2') === 0).forEach(f=>{
            // didの下二桁（日付）-1がcountsOfUseのインデックスになる
            const ndx = parseInt(f.slice(-2)) - 1;
            if (schedule[k][f].absence) return false;
            t[k][ndx]++;
          })
        }
      });
      setCountsOfUse(t);
    }
  }, [])

  // デバッグ時には一行だけ表示させるなど。
  const r = 500;
  const rows = users
  .filter(e=>e.service === service || service === '')
  .filter(e=>albcm.isClassroom(e, classroom))
  .slice(0, r)
  .map((e, i) => {
  // const rows = users.slice(0, r).map((e, i) => {
    return (<SchRow 
      thisUser={e} key={i} index={i} {...rProps}
    />)
  });
  return (<>
    {rows}
    <SchEditDetailDialog 
      stateOpen={dialogOpen} setStateOpen={setDialogOpen}
      setSnack={setSnack}
    />
    <SnackMsg {...snack} />

  </>);
}

export default SchTableBody2;

