import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import * as mui from '../common/materialUi';
import * as comMod from '../../commonModule';
import * as Actions from '../../Actions';
import * as sfp from '../common/StdFormParts';
import { useLocation } from 'react-router-dom';
import { sendPartOfSchedule } from '../../albCommonModule';

const useStyles = makeStyles({
  cntRow: {
    display: 'flex',
    justifyContent: 'center',
    '& .date' : {
      marginTop: 4,
      marginRight: 8,
      padding: '12px 12px 0 ',
      height: 'calc(1rem + 20px)',
    },
    '& .offSchool' : {
      backgroundColor: 'rgb(248, 227, 203)',
    },
    '& .off': {
      backgroundColor: 'rgb(202,202,217)',
    },
    '& .chkBoxWrap':{
      paddingLeft: 8,
      width: 120,
      '& .MuiFormControlLabel-root': {
        marginRight: 0,
      }
    }
  },
  formHead: {
    display: 'flex',
    justifyContent: 'center',
    '& .title': {
      width: 160,
      padding: '12px 12px 8px',
    },
    '& .date': {
      width: 100,
      padding: '12px 0 8px',
      fontSize: '1rem',
      textAlign: 'left',
    },
    '& .time': {
      width: 120,
      padding: '12px 0 8px',
    },
    '& .times': {
      width: 68,
      padding: '12px 0 8px',
    },
    '& .transfer': {
      width: 128,
      padding: '12px 0 8px',
    },
    '& .actualCost': {
      width: 120,
      overflow: 'hidden',
      fontSize: '.8rem',
      padding: '12px 0 8px',
    },
  },

});

const OneDay = (props) =>{
  const classes = useStyles();
  const {schedule, did, uid, ...other} = props;
  const dObj = comMod.convDid(did);
  const dStr = comMod.formatDate(dObj, 'MM月DD日 AAA');
  const start = schedule[uid][did].start;
  const end = schedule[uid][did].end;
  // 休校日、休日に対応したクラス名を取得
  let offClass = ['', 'offSchool', 'off'][schedule[uid][did].offSchool];
  offClass = (!offClass)? '' : offClass;

  const nameStart = did + '-start';
  const nameEnd = did + '-end';
  // 欠席扱いの場合
  if (schedule[uid][did].absence) return null;
  return(
    <div className={classes.cntRow}>
      <div className={'date ' + offClass}>{dStr}</div>
      <sfp.TimeInput 
        size='middle' name={nameStart} label={'開始'} def={start} noLabel 
      />
      <sfp.TimeInput 
        size='middle' name={nameEnd} label={'終了'} def={end} noLabel 
      />
    </div>
  )
}

const OneDayEx = (props) =>{
  const classes = useStyles();
  const {schedule, did, uid, ...other} = props;
  const classroom = useSelector(state=>state.classroom);
  const dObj = comMod.convDid(did);
  const dStr = comMod.formatDate(dObj, 'MM月DD日 A');
  const start = schedule[uid][did].start;
  const end = schedule[uid][did].end;
  // 休校日、休日に対応したクラス名を取得
  let offClass = ['', 'offSchool', 'off'][schedule[uid][did].offSchool];
  offClass = (!offClass)? '' : offClass;

  const nameStart = did + '-start';
  const nameEnd = did + '-end';
  // 欠席扱いの場合
  const o = schedule[uid][did];
  if (o.absence) return null;
  // mtu対応 対象外を除外
  if (o.classroom && o.classroom !== classroom) return null;
  // ロックされている予定は表示しない
  if (o.useResult)  return null;
  return(
    <div className={classes.cntRow}>
      <div className={'date ' + offClass}>{dStr}</div>
      <sfp.TimeInput 
        size='small' name={nameStart} label={'開始'} def={start} noLabel 
      />
      <sfp.TimeInput 
        size='small' name={nameEnd} label={'終了'} def={end} noLabel 
      />
      <sfp.Transfer 
        name={did + '-pickup'} value={schedule} uid={uid} did={did} 
        reqired size='middle' noLabel
      />
      <sfp.Transfer 
        name={did + '-send'} value={schedule} uid={uid} did={did} 
        reqired size='middle' noLabel
      />
      <div className='chkBoxWrap'>
        <sfp.ActualCostCheckBox
          value={schedule} uid={uid} did={did}
          required size='small' exName={did} noLabel
        />

      </div>

    </div>
  )
}


export const SchByUserStartEndInput = (props)=>{
  const classes = useStyles();
  const {uid, ...othe} = props;
  const UID = 'UID' + uid
  const schedule = useSelector(state=>state.schedule);
  const dispatch = useDispatch();
  const path = useLocation().pathname;

  const handleSubmit = (e) => {
    const inputs = document.querySelectorAll('#wky78 input');
    const fDt = comMod.getFormDatas([inputs]);
    const target = {};
    // fdt={D20201010-start:'10:30', D20201010-end:'17:30'}
    // target={D20201010:{start:'10:30, end:'17:30}}
    Object.keys(fDt).map(e => {
      const s = e.split('-');
      if (target[s[0]] == undefined) target[s[0]] = {};
      target[s[0]][s[1]] = fDt[e];
    });
    console.log(target);

    Object.keys(target).map(e=>{
      const sch = schedule[UID][e];
      const newsch = {
        ...sch, 
        start: target[e].start, 
        end: target[e].end,
      }
      dispatch(Actions.replaceSchedule(UID, e, newsch));
    });
    comMod.setSchedleLastUpdate(dispatch, path);
  }
  const cancelSubmit = () => {
    // console.log('cancelSubmit');
    props.close();
  }


  const ptn = /D2[0-9]+/; // didのパターン
  // uidが持つdidの配列
  const dids = Object.keys(schedule[UID]).filter(e=>e.match(ptn));
  dids.sort((a, b)=>a > b ? 1: -1);
  const days = dids.map(e=>{
    return (<OneDay key={uid} schedule={schedule} did={e} uid={UID} />)
  });
  
  return (<>
    <div className={classes.formHead}>
      <div className='title'>日付</div>
      <div className='time'>開始</div>
      <div className='time'>終了</div>

    </div>

    <form id="wky78" className="dialogForm withSpace">
      {days}
    </form>
    <div className="buttonWrapper">
      <mui.ButtonGP
        color='secondary'
        label='キャンセル'
        onClick={cancelSubmit}
      />
      <mui.ButtonGP
        color='primary'
        label='書き込み'
        type="submit"
        onClick={handleSubmit}
      />

    </div>
  </>)
}

// 時刻だけではなく他の項目も編集できるようにする！
export const SchByUserMultiInput = (props)=>{
  const classes = useStyles();
  const {uid, sch, setSch, setSnack, close, ...othe} = props;
  const UID = 'UID' + uid
  const schedule = useSelector(state=>state.schedule);
  const com = useSelector(state=>state.com);
  const hid = useSelector(state=>state.hid);
  const bid = useSelector(state=>state.bid);
  const stdDate = useSelector(state=>state.stdDate);
  const users = useSelector(state=>state.users);
  const config = useSelector(state=>state.config);
  const thisUser = comMod.getUser(uid, users);

  // 実費の初期値を取得
  const acCostVals = 
    comMod.findDeepPath(com, 'etc.actualCostList') ?
    comMod.findDeepPath(com, 'etc.actualCostList') : config.actualCostList;

  const dispatch = useDispatch();
  const path = useLocation().pathname;

  const handleSubmit = (e) => {
    console.log('handlesubmit');
    const inputs = document.querySelectorAll('#wky78 input');
    const selects = document.querySelectorAll('#wky78 select');
    const fDt = comMod.getFormDatas([inputs, selects]);
    const target = {};
    // fdt={D20201010-start:'10:30', D20201010-end:'17:30'}
    // target={D20201010:{start:'10:30, end:'17:30}}
    Object.keys(fDt).map(e => {
      const s = e.split('-');
      if (target[s[0]] == undefined) target[s[0]] = {};
      target[s[0]][s[1]] = fDt[e];
    });
    console.log(target);
    const u2sp = v => (v ? v :'');
    const t = {...sch};
    Object.keys(target).map(e=>{
      // 入力された実費項目を取得して中身を舐める
      const acst = target[e].actualCost;
      const newAcst = {};
      Object.keys(acst).map(f=>{
        if (acst[f]){
          newAcst[f] = acCostVals[f];
        }
      });
      if (t){
        console.log('hoge');
        t[e].start = target[e].start;
        t[e].end = target[e].end;
        t[e].transfer = [u2sp(target[e].pickup), u2sp(target[e].send)];
        t[e].actualCost = newAcst;
      }
      else{
        const dsch = schedule[UID][e];
        const newsch = {
          ...dsch, 
          start: target[e].start, 
          end: target[e].end,
          // 空白入力のときにnullを書き込むことになるので''に変換を行う
          transfer: [u2sp(target[e].pickup), u2sp(target[e].send)],
          actualCost: newAcst,
        }
        dispatch(Actions.replaceSchedule(UID, e, newsch));  
      }
    });
    if (t){
      setSch(t);
      // ここでのsendは必要なし。親モジュールのuseEffectで動いている。
      // SchTableBody2.js
      // sendPartOfSchedule({hid, bid, date:stdDate, t}, '', setSnack);
      close();
    }
    else{
      comMod.setSchedleLastUpdate(dispatch, path);
    }
  }
  const cancelSubmit = () => {
    // console.log('cancelSubmit');
    props.close();
  }

  const ActulCostTitle = () => {
    let str = '';
    Object.keys(acCostVals).map(e=>{
      str += e + '/';
    });
    return (<span>{str.slice(0, -1)}</span>);
  }
  const ptn = /D2[0-9]+/; // didのパターン
  // uidが持つdidの配列
  const dids = Object.keys(schedule[UID]).filter(e=>e.match(ptn));
  dids.sort((a, b)=>a > b ? 1: -1);
  const days = dids.map((e, i)=>{
    return (<OneDayEx key={i} schedule={schedule} did={e} uid={UID} />)
  });
  return (<>
    <div className={classes.formHead}>
      <div className='date'>日付</div>
      <div className='times'>開始</div>
      <div className='times'>終了</div>
      <div className='transfer'>迎え</div>
      <div className='transfer'>送り</div>
      <div className='actualCost'><ActulCostTitle /></div>

    </div>

    <form id="wky78" className="dialogForm withSpace">
      {days}
    </form>
    <div className="buttonWrapper">
      <mui.ButtonGP
        color='secondary'
        label='キャンセル'
        onClick={cancelSubmit}
      />
      <mui.ButtonGP
        color='primary'
        label='書き込み'
        type="submit"
        onClick={handleSubmit}
      />
    </div>
  </>)
}


export default SchByUserStartEndInput;