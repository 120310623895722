import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector, /*useSelector,*/ } from 'react-redux';
import { useHistory, } from 'react-router-dom';
import * as Actions from './Actions'
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import BugReportIcon from '@material-ui/icons/BugReport';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import MoodIcon from '@material-ui/icons/Mood';
import TuneIcon from '@material-ui/icons/Tune';
import DescriptionIcon from '@material-ui/icons/Description';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import SendIcon from '@material-ui/icons/Send';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ReceiptIcon from '@material-ui/icons/Receipt';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import Rev from './Rev';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTwitter } from "@fortawesome/free-brands-svg-icons";
// import { faCalendar } from "@fortawesome/free-regular-svg-icons";
// import { faCoffee } from "@fortawesome/free-solid-svg-icons";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { Link } from 'react-router-dom';
import { InsertInvitationOutlined } from '@material-ui/icons';
import teal from '@material-ui/core/colors/teal';
import blue from '@material-ui/core/colors/blue';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import * as comMod from './commonModule'; 

// fontAWESOMEアイコンのスタイル
const iconStyle = { padding: 0, fontSize: 22, marginRight: 8, marginLeft: 2,};

const useStyles = makeStyles({
  list: {
    width: 250,
    ' & .MuiList-padding': {
      paddingTop: 4,
      paddingBottom: 4,
    },
    ' & .MuiListItemText-root': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  fullList: {
    width: 'auto',
  },
  root:{
    width:40,
    ' & .MuiButton-text': {
      padding: 0,
      color: '#eee',

    },
    ' & .MuiButton-label': {
      display: 'block',
    },
    ' & .MuiButton-root': {
      minWidth: 40,
    },
  },
  devider:{
    marginTop: 8,
    marginBottom: 8,
  },
  sideToolBarRoot:{
    position: 'fixed',
    top: 87,
    left: 0,
    ' & .MuiListItemIcon-root':{
      minWidth: 'initial',
      color:teal[500],
    },
  },
  itemsRoot: {
    ' & .MuiListItemIcon-root':{
      color:teal[500],
    },
    
  },
});

const menuItems = [
  { label: '利用者情報', link: '/', icon: <MoodIcon /> ,p: 70},
  // { label: '予実績登録', link: '/schedule', icon: <CalendarTodayIcon /> },
  { 
    label: '予実績登録', link: '/schedule', 
    icon: <FontAwesomeIcon icon={faCalendarAlt} style={iconStyle} /> ,p: 60
  },
  { label: '帳票', link: '/reports', icon: <DescriptionIcon /> ,p: 70},
  { label: '売上管理', link: '/proseed', icon: <TrendingUpIcon /> ,p: 90},
  { label: '設定', link: '/setting', icon: <TuneIcon /> ,p: 90},
  { label: '請求処理', link: '/billing', icon: <SendIcon /> ,p: 90},
  { 
    label: 'アップロード', link: '/upload/fscon', 
    icon: <CloudUploadIcon /> ,p: 90
  },
  { label: 'アカウント', link: '/account', icon: <AccountCircleIcon /> ,p: 90},
  { label: 'テスト用', link: '/test', icon: <BugReportIcon /> ,p: 100},
];

export const SideToolBar = () =>{
  const classes = useStyles();
  const hist = useHistory();
  const dispatch = useDispatch();
  const account = useSelector(state=>state.account);
  const [permission, setPermission] = useState(0);
  useEffect(()=>{
    setPermission(comMod.parsePermission(account)[0][0]);
  },[account]);
  
  const handleLogout = () => {
    dispatch(Actions.clearAcount());
  }
  const handleClick = (e) => {
    // e.preventdefault();
    const t = menuItems.filter(e=>e.p<=permission);
    const i = parseInt(e.currentTarget.getAttribute('index'));
    if (!t[i].link) {
      return false;
    }
    hist.push(t[i].link);
  }
  // パーミッションによる表示制限
  const items = menuItems.filter(e=>e.p<=permission).map((e, i) => {
    return (
      <List key={i} title={menuItems[i].label}>
        <ListItem button index={i} onClick={(e) => handleClick(e)}>
          <ListItemIcon>{e.icon}</ListItemIcon>
        </ListItem>
      </List>
    )
  });
  return (
    <div className={classes.sideToolBarRoot + ' sideToolBar'}>
      {items}
      <List  title='ログアウト'>
        <ListItem button onClick={handleLogout} >
          <ListItemIcon><ExitToAppIcon /> </ListItemIcon>
        </ListItem>
      </List>
    </div>
  )
}

export default function DrowerMenu() {
  const classes = useStyles();
  const hist = useHistory();
  const dispatch = useDispatch();
  const [open, setopen] = useState(false);
  const account = useSelector(state=>state.account);
  const [permission, setPermission] = useState(0);
  useEffect(()=>{
    setPermission(comMod.parsePermission(account)[0][0]);
  },[account]);

  const handleLogout = () => {
    dispatch(Actions.clearAcount());
  }

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setopen(open);
  };

  const handleClick = (e) =>{
    // e.preventdefault();
    const tmenu = menuItems.filter(e=>e.p<=permission);
    const i = parseInt(e.currentTarget.getAttribute('index'));
    if (!tmenu[i].link){
      return false;
    }
    hist.push(tmenu[i].link);
  }

  const ItemList = () => {
    const items = menuItems.filter(e=>e.p<=permission).map((e, i)=>{
      return(
        <List key = {i} className={classes.itemsRoot} >
          {/* <Link className='menuItem' to={e.link}> */}
            <ListItem button index={i} onClick={(e)=>handleClick(e)}>
              <ListItemIcon>{e.icon}</ListItemIcon>
              <ListItemText>
                  {e.label}
              </ListItemText>
            </ListItem>
          {/* </Link> */}
        </List>
      )
    });
    return(
      <div
        className={classes.list + ' drowerMenu'}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <List  >
          <ListItem button >
            <ListItemIcon><CloseIcon color='secondary' /> </ListItemIcon>
            <ListItemText>
              メニューを閉じる
            </ListItemText>
          </ListItem>
        </List>
        {items}
        <Divider className={classes.devider} />
        <List  >
          <ListItem button onClick={handleLogout} >
            <ListItemIcon><ExitToAppIcon/> </ListItemIcon>
            <ListItemText>
              ログアウト
            </ListItemText>
          </ListItem>
        </List>
        <Divider className={classes.devider}/>
        <List>
          <ListItem >
            <ListItemIcon><InfoIcon /> </ListItemIcon>
            <ListItemText>
              <Rev />
            </ListItemText>
          </ListItem>
        </List>
      </div>
    )
  }

  return (
    <div className={classes.root + ' drowerButton'} >
      <Button 
        onClick={toggleDrawer(true)}
      >
        <MenuIcon/>
      </Button>
      <Drawer anchor='left' open={open} onClose={toggleDrawer(false)}>
        <ItemList/>
      </Drawer>
    </div>
  );
}
