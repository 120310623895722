import React from 'react';
import * as Actions from '../../Actions';
import { connect, useSelector } from 'react-redux';
import * as comMod from '../../commonModule';
import SchDailyDialog from './SchDailyDialog';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import * as cp from '../common/commonParts';
import {OccupancyRate} from './SchHeadNav';
import { classroomCount, isClassroom, } from '../../albCommonModule';
import { faSleigh } from '@fortawesome/free-solid-svg-icons';

// 日毎の利用数をカウント
const countVisitOfDate = (props, did) => {
  let cnt = 0;
  const {countsOfUse, schedule, sSchedule, comAdic, users, classroom} = props;
  // サービスごと単位かどうか
  // const serviceAsTanni = comAdic? comAdic.サービスごと単位: null;
  // カウント用のstateが設定されているときはそちらで確認
  if (countsOfUse){
    // didの下二桁（日付）-1がcountsOfUseのインデックスになる
    const ndx = parseInt(did.slice(-2)) - 1;
    const c = {...countsOfUse};
    // countsOfUseに1が立っているところをカウント
    cnt = Object.keys(c).filter(e=>c[e][ndx]).length;
    return cnt;
  }
  else return 0;
  // countofuseが定義されてないってあるの？以下のコードは意味無さそうな気がする。
  // console.log('countofuse not found.')
  // // サービスごと単位の場合は素直にschedule(この場合はサービス、単位で切り出されたスケジュール)
  // // でカウントを行う。サービスごと単位でない場合は
  // const schedule4Count = (serviceAsTanni === 1)
  // ? schedule: {...sSchedule, ...schedule}; 
  // Object.keys(schedule4Count).map(e => {
  //   if (e.indexOf('UID') !== 0) return false; // uid以外はスキップ
  //   const thisUserSch = schedule4Count[e];
  //   const thisUser = comMod.getUser(users, e);
  //   const isClas = isClassroom(thisUser, classroom);
  //   const classCnt = classroomCount(thisUser);
  //   if (isClas) return false;
  //   Object.keys(thisUserSch).map(f => {
  //     // スケジュールオブジェクト以外はスキップ
  //     if (f !== did) return false;
  //     const sch = thisUserSch[f];
  //     if (sch.absence) return false; // 欠席の場合はスキップ
  //     // MTU対策
  //     if (classCnt > 1 && thisUserSch.classroom !== classroom)  return false;
  //     cnt++;
  //     return false;
  //   });
  // });
  // return (cnt);
}
// // 加算の数をドットで表現
// const DAddictionContent = (props)=>{
//   const {did, ...other} = props;
//   const schedule = useSelector(state => state.schedule);
//   const service = useSelector(state => state.service);
//   // 日毎の加算項目をカウント
//   const countAddiction = () => {
//     if (schedule[service] === undefined) return 0;
//     if (schedule[service][did] === undefined) return 0;
//     return (Object.keys(schedule[service][did]).length);
//   }
//   const c = countAddiction();
//   if (c >= 4) return (
//     <div className="dAddiction">
//       <FiberManualRecordIcon fontSize='inherit' />{c}
//     </div>
//   );
//   const a = new Array(c).fill(1);
//   const b = a.map(e=>{
//     return <FiberManualRecordIcon fontSize='inherit' />
//   });
//   const styleObj = (c === 3)? {fontSize: '.4rem'} : {}
//   return(
//     <div className="dAddiction" style={styleObj}>
//       {b}
//     </div>
//   )
// }

const DateOfTitle = (props) =>{
  const holidayClass = ['', 'schoolOff', 'off'];
  const com = useSelector(state=>state.com);
  const users = useSelector(state=>state.users);
  const classroom = useSelector(state=>state.classroom);
  const comAdic = com.addiction[props.service];
  // この時点でサービスのステートが確定していない場合がある.
  // その場合は定員を借り設定する。
  const teiin = (comAdic)? parseInt(comAdic.定員): 10;
  const limit = comMod.upperLimitOfUseByDay(teiin);
  const {setSchStoreDispatch, } = props;
  // const handleClick = (ev) => {
  //   if (setSchStoreDispatch){
  //     setSchStoreDispatch(true);
  //   }
  // }
  // ここでのpropsのscheduleは切り出されたものなので全体のスケジュールも取得する
  const sSchedule = useSelector(state=>state.schedule);
  // countVisitOfDate で使うためにprmsに追加
  const prms = {...props, ...sSchedule, comAdic, users, classroom};
  const elm = props.value.map((e, i) => {
    const d = 'D' + comMod.formatDate(e.date, 'YYYYMMDD');
    // 月水金で有効になるクラス名
    const mwfClass = [1, 3, 5].indexOf(e.date.getDay()) >= 0 ? ' mwfClass' : '';
      // 日曜日が有効になるクラス
    const sunClass = e.date.getDay() === 0 ? ' sunClass' : '';

    const hClass = holidayClass[e.holiday];
    const visit = countVisitOfDate(prms, d);
    // 定員やリミットを超過したときのクラス名定義
    let classOver = visit > teiin ? 'higher ' : '';
    classOver = visit > limit ? 'over ' : classOver;

    return (
      <div
        className={
          'w03 lower small flxCenter dateTitle flxVirtical dateTitle '
          + hClass + mwfClass + sunClass
        }
        key={i} id={d}
        // onClick={handleClick}
      >
        <div className='center'>
          {e.date.getDate()}<br />{comMod.getWd(e.date.getDay()).jp}
        </div>
        <div className={'totalOfDay ' + classOver}>{visit}</div>
        <cp.DAddictionContent did={d} />
        {/* 暫定処置。無限レンダリング回避できないため */}
        {/* {setSchStoreDispatch === undefined &&
          <SchDailyDialog date={e.date}/>
        } */}
        
      </div>
    )
  });
  return(elm);
}

export const SchTableHead = (props) =>{
  const dateList = useSelector(state=>state.dateList);
  const schedule = useSelector(state=>state.schedule);
  const service = useSelector(state=>state.service);
  const com = useSelector(state=>state.com);
  const teiin = comMod.findDeepPath(
    com, props.service + '.定員'
  );
  const {countsOfUse, /* setSchStoreDispatch */} = props;
  return (
    <div className='flxTitle scheduleDays30' >
      <div className='wmin lower noBkColor'>No</div>
      <div className="w15 lower" > <div>氏名</div></div>
      <DateOfTitle
        value={dateList}
        schedule={schedule}
        service={service}
        teiin={teiin}
        countsOfUse={countsOfUse}
        // setSchStoreDispatch={setSchStoreDispatch}
      />
      <OccupancyRate/>
    </div>
  );
}
export default SchTableHead;


// class SchTableHead extends React.Component{
//   render() {
//     const teiin = comMod.findDeepPath(
//       this.props, 'com.addiction.' + this.props.service + '.定員'
//     );
//     return (
//       <div className='flxTitle scheduleDays30' >
//         <div className='wmin lower noBkColor'>No</div>
//         <div className="w15 lower" > <div>氏名</div></div>
//         <DateOfTitle
//           value={this.props.dateList}
//           schedule={this.props.schedule}
//           service={this.props.service}
//           teiin={teiin}
//         />
//         <OccupancyRate/>
//       </div>
//     );
//   }
// }
// function mapStateToProps(state) {
//   return (state);
// }
// export default connect(mapStateToProps, Actions)(SchTableHead);
