import React, { useEffect, useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Dialog from '@material-ui/core/Dialog';
import * as comMod from '../../commonModule'
import * as albcm from '../../albCommonModule'
import { useDispatch, useSelector } from 'react-redux';
import * as mui from '../common/materialUi';
import * as Actions from '../../Actions';
import CancelIcon from '@material-ui/icons/Cancel';
import PersonIcon from '@material-ui/icons/Person';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import * as sfp from '../common/StdFormParts';
import * as afp from '../common/AddictionFormParts';
import red from '@material-ui/core/colors/red';
import indigo from '@material-ui/core/colors/indigo';
import { useLocation, useHistory } from 'react-router-dom';
import BlockRoundedIcon from '@material-ui/icons/BlockRounded';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import SchTableBody2 from './SchTableBody2';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import teal from '@material-ui/core/colors/teal';
import { AddCircle, CenterFocusStrong } from '@material-ui/icons';
import { TextGP } from '../common/FormPartsCommon';
import { faSleigh } from '@fortawesome/free-solid-svg-icons';

const hiddenInput = 'hiddenInput';
const formId = 'dkkjug53' 

const useStyle = makeStyles((theme)=>({
  root :{
    ' & .MuiDialog-paperWidthSm':{
      // minWidth:100,
      width: 700,
      maxWidth: 700,
    }
  },
  absenceButton: {
    backgroundColor: red[500],
    color: "#fff",
    '&:hover' : {
      backgroundColor: red[600],
    },
  },
  allSubmitButton: {
    backgroundColor: indigo[700],
    color: "#fff",
    '&:hover': {
      backgroundColor: indigo[800],
    },
  },
  holidayInputRoot:{
    '& .MuiSelect-root':{
      paddingTop: 10,
    },
  },
  userScheduleLinkButton:{
    padding: 12,
    paddingTop: 16,
    paddingBottom: 0,
  },
  thisBttonWrap:{
    display:'flex',
    justifyContent: 'flex-end',
    padding: 8,
    '& button': {
      marginTop: 20,
    },
    '& .allSubmitOuter': {
      backgroundColor: indigo[50],
      borderRadius: 4,
      '& button': {
        marginTop: 0,
      },
      '& .small':{
        fontSize:'.8rem',
        paddingBottom: 2,
        paddingTop: 4,
        lineHeight: '14px',
        textAlign: 'center',
      },
    },
  },
  textAreaWrap:{
    width:'48%',margin:'0 1%',
    '& textarea': {
      padding:4, backgroundColor:'#eee', border: 'none', width: '100%'
    },
    '& .title': {
      fontSize: '.8rem', padding:0, transition: '.4s', padding: '0 4px'
    },
  },
  subTitle : {
    '& .titleSmall': {fontSize:'.7rem'},
    '& .titleLarge': {fontSize:'1.2rem'},
  },
  preBackButtonsRoot:{
    display: 'flex',
    marginTop: -32,
    justifyContent: 'space-between',
    '& .MuiIconButton-root':{display: 'block', padding: 4, color: teal[500],},
  },
  freeAcostInputAnc:{
    display: 'flex', alignItems: 'center', padding: 8,
    '& >div': {paddingLeft: 8, },
    '& .text': {paddingBottom: 4},
    '& .MuiSvgIcon-root': {color: teal[800]},
  },
  freeAcostInputCnt:{
    display: 'flex', alignItems: 'center', padding: '0 0 16px 0',
    width: '100%'
  }

}));

const CommentInput = (props) => {
  const classes = useStyle();
  const {placeholder, name, title, initVal} = props;
  const [val, setVal] = useState(initVal);
  const [titleStyle, setTitleStyle] = useState({height:0, opacity: 0});
  useEffect(()=>{
    if (val)  setTitleStyle({height:16, opacity: 1});
    else      setTitleStyle({height:0, opacity: 0});
  }, [val]);
  return (
    <div className={classes.textAreaWrap}>
      <div className='title' style={titleStyle}>{title}</div>
      <TextareaAutosize
        placeholder={placeholder} name={name} value={val}
        onChange={(e)=>setVal(e.currentTarget.value)}
      />
    </div>
  )
}
// // 実費の自由入力を行う。自由入力項目は一個だけとする
const FreeActualCost = (props) => {
  const {freeACostOpen, setFreeAconstOpen, schedule, uid, did} = props;
  const classes = useStyle();
  const actualCostList = useSelector(
    state => state.config.actualCostList
  )
  const thisSch = schedule[uid][did];
  const actualCost = comMod.findDeepPath(thisSch, 'actualCost', {});
  // 設定済みの自由入力項目を検出 stateにセット
  const [freeACost, setFreeAconst] = useState(()=>{
    const t = {...actualCost};
    Object.keys(t).forEach(e=>{
      if (Object.keys(actualCostList).indexOf(e) > -1){
        delete t[e];
      }
    });
    if (Object.keys(t).length){
      return {
        name: Object.keys(t)[0], value: t[Object.keys(t)[0]],
        nameError: false, nameHelperText: '',
        valueError: false, valueHelperText: ''
      }
    }
    else return ({
      name: '', value: 0, 
      nameError: false, nameHelperText: '',
      valueError: false, valueHelperText: ''
    });
  });
  
  // すでに自由項目が設定されていたら強制オープン
  useEffect(()=>{
    if (freeACost.name){
      const t = {...freeACostOpen};
      t.open = true;
      t.value = freeACost.value;
      setFreeAconstOpen(t);
    }
    else{
      const t = {...freeACostOpen};
      t.open = false;
      t.value = 0;
      setFreeAconstOpen(t);

    }
  }, [freeACost])
  const handleChange = (e) => {
    const v = comMod.getInputInfo(e);
    const t = {...freeACost}
    const u = {...freeACostOpen}
    if (v.name === 'freeACostName'){
      t['name'] = v.value;
    }
    else if (v.name === 'freeACostValue'){
      t['value'] = v.value;
      u['value'] = v.value;
    }
    setFreeAconst(t);
    setFreeAconstOpen(u);
  }
  const handleClick = () => {
    const t = {...freeACostOpen}
    if (freeACostOpen.open){
      setFreeAconst({name: '', value: 0});
      t.open = false;
      t.value = 0;
      setFreeAconstOpen(t);
    }
    else{
      const t = {...freeACostOpen}
      t.open = true;
      setFreeAconstOpen(t);
    }
  }
  const OpenCloseButton = () => {
    return (
      <div onClick={handleClick} className={classes.freeAcostInputAnc}>
        <div className='button'>
          {freeACostOpen.open === false &&
            <a><AddCircle /></a>
          }
          {freeACostOpen.open === true &&
            <a><CancelIcon /></a>
          }
        </div>
        <div className='text'>追加項目</div>
      </div>
    )
  }
  // 既存の項目は追加できないようにする
  const handleBlur = (ev) => {
    const target = ev.currentTarget;
    const value = target.value;
    if (target.name == 'freeACostName'){
      // 既存の項目は入力不可にする
      const t = {...freeACost};
      if (actualCostList[value]){
        t.nameError = true;
        t.nameHelperText = '既存の項目は追加できません'
        setFreeAconst(t);
      }
      else {
        t.nameError = false;
        t.nameHelperText = ''
        setFreeAconst(t);
      }
    }
  }
  return (
    <>
      <OpenCloseButton />
      {freeACostOpen.open === true && 
        <div className={classes.freeAcostInputCnt}>
          <TextGP 
            name='freeACostName' label='項目名'
            value={freeACost.name} 
            onChange={(e) => handleChange(e)}
            onBlur={(e) => handleBlur(e)}
            errMsg={freeACost.nameHelperText}
            err={freeACost.nameError}
          />
          <sfp.NumInputGP 
            name='freeACostValue' label='金額'
            cls='tfSmallNum'
            def={freeACost.value} 
            lower={-10000}
            onChange={(e) => handleChange(e)}
          />
        </div>
      }
    </>
  )
}
// 月間予定のユーザーごとコンポーネント化SchTableBody2に合わせて
// store stateからではなくpropsから受けた情報を編集できるようにする
// スケジュールデータそのものもpropsから受けるようにする
// スケジュールデータはuidごとに渡すようにする
// スケジュールデータはsfpに渡せるように整えなければならない
const SchEditDetailDialog = (props)=> {
  const dispatch = useDispatch();
  const classes = useStyle();
  const path = useLocation().pathname;
  const hist = useHistory();
  const {setSnack } = props;

  // 必要な情報の取得
  const stdDate = useSelector(state=>state.stdDate);
  const actualCostList = useSelector(
    state => state.config.actualCostList
  )
  const users = useSelector(state => state.users);
  const service = useSelector(state => state.service);
  const classroom = useSelector(state => state.classroom);
  const bid = useSelector(state => state.bid);
  const hid = useSelector(state => state.hid);
  const dateList = useSelector(state => state.dateList);
  // 自由実費項目 open close 値保持
  const [freeACostOpen, setFreeAconstOpen] = useState({open:false, value:0});
  // 自由実費項目 値保持用
  
  // propsからopenさせる
  let stateOpen = props.stateOpen;
  
  const storeStateOpen =useSelector(
    state => state.controleMode.openSchEditDetailDialog
  )

  // storeからopenさせる
  if (stateOpen === undefined){
    stateOpen = storeStateOpen?
    storeStateOpen :{ open: false, uid: '', did: '' }
  }
  
  const UID = stateOpen.uid;
  const did = stateOpen.did;
  const thisUser = comMod.getUser(UID, users);
  const classroomCount = albcm.classroomCount(thisUser);
  const propsOpen = (props.stateOpen)? true: false; // propからopenしたことを示す
  // スケジュールはプロップスを見てstoreから取得
  const storeSch = useSelector(state=>state.schedule);
  const schedule = (propsOpen)? {[UID]: stateOpen.usch}: storeSch

  const handleClose = () => {
    // ストアによるオープンの場合
    if (!propsOpen){
      comMod.setOpenSchEditDetailDialog(
        dispatch, { open: false, uid: '', did: '' }
      );
      comMod.setSchedleLastUpdate(dispatch, path);
    }
    // propsによるopen
    else{
      const t = stateOpen; 
      props.setStateOpen({...t, open: false});
    }
  }

  // handlesubmit handleAbSenseがほぼ同じ動作になるので
  // 別関数として再定義
  // addprmsはサブミットのときに追加される
  // deleteKeysはそれらのキーが削除される
  // 20210303追加 didsに値があるときは全てのdidに対してdispatchする
  const doSubmit =( addPrms = {}, deleteKeys=[], dids=[], didMove=0 )=>{
    // これ以外の要素は加算項目として扱う
    const mainPrms = [
      'start', 'end', 'offSchool', 'actualCost', 'pickup', 'send',
      'notice', 'memo', 'freeACostName', 'freeACostValue'
    ];

    const addVal = (obj, name, val) => {
      if (mainPrms.indexOf(name) > -1)
        // 数値だったらint化
        formsVal[name] = (isNaN(val)) ? val : parseInt(val);
      else {
        if (obj.dAddiction === undefined) obj.dAddiction = {};
        if (val)
          obj.dAddiction[name] = val;
      }
    }
    // エラーの箇所を検出
    const errInputs = document.querySelectorAll(`#${formId} .Mui-error`);
    if (errInputs.length){
      setSnack({
        msg: '入力を再確認してください。', severity:'warning',
        id: new Date().getTime(),
      });
      return false;
    }

    // 値が必要なエレメントを用意しておく
    const inputs = document.querySelectorAll(`#${formId} input`);
    const selects = document.querySelectorAll(`#${formId} select`);
    const textareas = document.querySelectorAll(`#${formId} textarea`);
    const formsVal = comMod.getFormDatas([inputs, selects, textareas])

    // 実費項目
    Object.keys(formsVal.actualCost).map(e => {
      if (formsVal.actualCost[e]) {
        formsVal.actualCost[e] = actualCostList[e];
      }
      else {
        delete formsVal.actualCost[e];
      }
    });
    // 実費項目に自由項目を追加
    if (formsVal.freeACostName){
      formsVal.actualCost = {
        ...formsVal.actualCost, 
        [formsVal.freeACostName]: formsVal.freeACostValue
      }
      delete formsVal.freeACostName;
    }

    // 加算項目をdaddictionとしてひとまとめにする
    Object.keys(formsVal).forEach(e => {
      addVal(formsVal, e, formsVal[e])
      // formsVal[e.getAttribute('name')] = e.value;
    });
    // 送迎だけ配列になっているので処理を追加
    formsVal.transfer = [];
    formsVal.transfer[0] =
      (formsVal.pickup !== undefined) ? formsVal.pickup : '';
    formsVal.transfer[1] =
      (formsVal.send !== undefined) ? formsVal.send : '';
    delete formsVal.pickup; delete formsVal.send;

    // formデータにサービスが含まれていないため追加する
    formsVal.service = service;

    // addPrmsの追加
    Object.assign(formsVal, addPrms);
    // deleteKeysの削除
    deleteKeys.map(elm=>{delete formsVal[elm];})

    // 欠席時対応加算があったら欠席予定として追加を行う
    if (comMod.findDeepPath(formsVal, 'dAddiction.欠席時対応加算')){
      formsVal.absence = true;
    }
    const isMtu = classroomCount > 1;
    // mtuならクラスルームを付与。これしないと消えるっぽい
    if (isMtu && classroom){
      formsVal.classroom = classroom;
    }

    // 多数同時更新
    if (dids.length){
      // memoとnoticeは複数書き込みしない
      const formsVal_ = {...formsVal};
      delete formsVal_.notice;
      delete formsVal_.memo;
      if (!propsOpen){
        dids.map(_=>{
          dispatch(Actions.replaceSchedule(UID, _, formsVal_));
        });  
      }
      else{ // ユーザーごとのステイと管理の場合
        const t = {...stateOpen};
        dids.map(_=>{
          t.usch[_] = {...formsVal_};
        });
        props.setStateOpen(t);
      }
    }
    // 通常
    if (!propsOpen){
      dispatch(Actions.replaceSchedule(UID, did, formsVal));
      comMod.setSchedleLastUpdate(dispatch, path);
      handleClose();
    }
    else { // ユーザーごとのステイト管理の場合
      const t = stateOpen;
      const u = {...t.usch, [did]:formsVal};
      const v = {...t, usch: u};
      // スケジュールオブジェクトからdidを抽出してソート
      const ds = Object.keys(u).filter(e=>e.indexOf('D2') === 0);
      ds.sort((a, b)=>a > b? 1: -1);
      // 現在のdidの位置を取得
      const cDidNdx = ds.indexOf(did);
      if (didMove === 0){
        // 日付移動なし stateに値をセットしてダイアログクローズ
        props.setStateOpen({...t, usch: u, open: false});
      }
      else if (didMove > 0){
        // クローズしないでdid変更。スケジュールは更新する
        const nDid = (cDidNdx === ds.length - 1)? did: ds[cDidNdx + 1];
        props.setStateOpen(
          {...t, usch:u , did: nDid, open: true, }
        );
      }
      else if (didMove < 0){
        const nDid = (cDidNdx === 0)? did: ds[cDidNdx - 1];
        props.setStateOpen(
          {...t, usch:u , did: nDid, open: true, }
        );
      }
    }
  }

  // 全て変更するときのDIDリストを作成
  // UIDが確定しているときのみ
  const getDidsForEdit = () =>{
    const pi = v => parseInt(v);
    if (UID){
      const tmpDids = comMod.setOfUidDid(schedule, UID);
      // --- 現在の放課後休日を取得
      const thisOffSchool = schedule[UID][did].offSchool;
      // 現在の放課後休日フラグと一致していないdidは削除
      // 利用実績があるdidも削除
      // 現在のdid未満も削除
      const dids = tmpDids
      .filter(_ => 
        pi(schedule[UID][_].offSchool) === pi(thisOffSchool) &&
        _ >= did &&
        schedule[UID][_].useResult !== true
      );
      return dids;
    }
    else{
      return [];
    }
  }
  const keyHandler = (e) =>{
    if (e.which === 13 && e.shiftKey){
      const forBlur = document.querySelector(`#${hiddenInput}`);
      forBlur.focus();
      e.persist();
      // document.querySelector(`#${formId}`).blur();
      setTimeout(()=>{
        handleSubmit(e);
      }, 300);
    }
  }
  const dids = getDidsForEdit();
  const didLength = dids.length;
  const handleAllSubmit = (e) => {
    e.preventDefault();
    doSubmit({}, [], dids);  // 追加オブジェクトなし 削除なし 複数更新
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    doSubmit({}, ['absence']);  // 追加オブジェクトなし abSenceを削除
  }
  // 欠席扱いにする
  // scheduleの日付オブジェクト配下にabsence:trueを挿入して
  // ダイアログを閉じる
  const handleAbcense =(e)=>{
    e.preventDefault();
    doSubmit({ absence:true }, []);  // absenceを追加
  }
  // 次！
  const handleNext = (e) => {
    // didを移動する
    doSubmit({}, [], [], 1);
  } 
  const handlePre = (e) => {
    // didを移動する
    doSubmit({}, [], [], -1);
  } 

  // Scheduleオブジェクトの値を読み取ってボタンテキストを制御
  const thisAbsenced = comMod.findDeepPath(schedule, [UID, did, 'absence']);
  const submitBtnText = (thisAbsenced) ? '出席にする' : '書き込み';
  const absenceBtnText = (thisAbsenced) ? '欠席として保存' : '欠席にする';
  // ユーザー別スケジュールページから開かれたらユーザーリンクは表示しない
  const userScheduleLinkButton = (path.indexOf('users') > -1) ?
    {display:'none'}: {display:'block'};

  // noticeとmemoの初期値をstateから取得
  const thisNotice = comMod.findDeepPath(schedule, [UID, did, 'notice']);
  const thisMemo = comMod.findDeepPath(schedule, [UID, did, 'memo']);
  const nextPreButtonStyle = {paddingLeft: 4, paddingRight: 4}
  return (<>
    <Dialog 
      open={stateOpen.open}
      onClose={handleClose}
      className={classes.root}
    >
      <form 
        className="dialogForm " id={formId} onKeyPress={(e=>keyHandler(e))}
      >
        <div className='formTitle'>
          利用予定詳細設定
        </div>
        <div className={"formSubTitle " + classes.subTitle}>
          {/* <div className="date">{
            did.substr(1, 4) + "年 " + 
            did.substr(5, 2) + "月 " + 
            did.substr(7, 2) + "日 "
          }</div> */}
          <div className='date'>
            {/* <span className='titlesmall'>{did.substr(1, 4) + ' / '}</span> */}
            <span className='titlelarge'>{did.substr(5, 2)}</span>
            <span className='titlesmall'>{' / '}</span>
            <span className='titlelarge'>{did.substr(7, 2)}</span>
          </div>
          <div className="user ">
            <span className='titleLarge'>{thisUser.name}</span>
            <span className='titlesmall'>{' 様'}</span>

          </div>
          <div className="age" style={{paddingTop: 8}}>
            <span className='titlesmall'>{thisUser.ageStr}</span>
          </div>
          <div className="belongs" style={{paddingTop: 8}}>
            <span className='titlesmall'>{thisUser.belongs1}</span>
          </div>

        </div>
        {propsOpen &&
          <div className={classes.preBackButtonsRoot}>
            <IconButton onClick={handlePre}>
              <ArrowBackIosRoundedIcon />
            </IconButton>
            <IconButton onClick={handleNext}>
              <ArrowForwardIosRoundedIcon />
            </IconButton>
          </div>
        }
        <div className='cntRow'>
          <sfp.TimeInput 
            name='start' label='開始' 
            value={schedule} uid={UID} did={did}
            required size='middle' 
            key={did + 'start'}
          />
          <sfp.TimeInput
            name='end' label='終了'
            value={schedule} uid={UID} did={did}
            required size='middle'
            key={did + 'end'}
          />
          <div className={classes.holidayInputRoot}>
            {/* パディング調整用のラッピングをする */}
            <sfp.HolidayOrNot
              name='offSchool' label='平日/休日利用'
              value={schedule} uid={UID} did={did}
              required size='middle'
              key={did}
            />
          </div>
          {/* ユーザー別スケジュールページから開かれたときは非表示
          スタイルで設定を行う */}
          <div 
            className={classes.userScheduleLinkButton} 
            style={userScheduleLinkButton}
          >
            <Button
              variant='outlined'
              color='primary'
              onClick={() => {
                hist.push('/schedule/users/' + thisUser.uid + '/');
                handleClose();
              }}
            >
              ユーザー別予定へ
            </Button>
          </div>
        </div>
        <div className='cntRow'>
          <sfp.Transfer
            name='pickup' label='迎え'
            value={schedule} uid={UID} did={did}
            required size='middle'
            key={did + 'pu'}
          />
          <sfp.Transfer
            name='send' label='送り'
            value={schedule} uid={UID} did={did}
            required size='middle'
            key={did  + 'snd'}
          />

        </div>
        <div className='cntRow'>
          <sfp.ActualCostCheckBox
            value={schedule} uid={UID} did={did}
            required size='middle'
            key={did + 'acb'}
            freeACost={freeACostOpen.value}

          />
          <FreeActualCost 
            uid={UID} did={did} schedule={schedule} 
            freeACostOpen={freeACostOpen} setFreeAconstOpen={setFreeAconstOpen}
          />
        </div>
        <div className='cntRow'>
          <afp.KessekiTaiou 
            uid={UID} did={did} size='middle' dLayer={3} key={did + 'a5'}
          />
          <afp.JiShidouKaHai1 
            uid={UID} did={did} size='middle' dLayer={3} key={did + 'a0'}
          />
          {/* <afp.KangoKahai 
            uid={UID} did={did} size='middle' dLayer={3} key={did + 'a1'}
          /> */}
          <afp.EnchouShien 
            uid={UID} did={did} size='middle' dLayer={3} key={did + 'a2'}
          />
          <afp.TokubetsuShien 
            uid={UID} did={did} size='middle' dLayer={3} key={did + 'a3'}
          />
          {/* <afp.KateiRenkei 
            uid={UID} did={did} size='middle' dLayer={3} key={did + 'a4'}
          /> */}
          <afp.KateiRenkeiNum
            uid={UID} did={did} size='middle' dLayer={3} key={did + 'a4'}
          />
          {/* <afp.KobetsuSuport1
            uid={UID} did={did} size='middle' dLayer={3} key={did + 'a6'}
          />
          <afp.KobetsuSuport2
            uid={UID} did={did} size='middle' dLayer={3} key={did + 'a6'}
          /> */}
          <afp.IryouRenkei 
            uid={UID} did={did} size='middle' dLayer={3} key={did + 'a7'}
          />
          <afp.JigyousyoSoudan 
            uid={UID} did={did} size='middle' dLayer={3} key={did + 'a8'}
          />
          <afp.KyoudoKoudou 
            uid={UID} did={did} size='middle' dLayer={3} key={did + 'a9'}
          />
          {/* <afp.HoikuKyouiku 
            uid={UID} did={did} size='middle' dLayer={3} key={did + 'aa'}
          /> */}
          <afp.ShokujiTeikyou 
            uid={UID} did={did} size='middle' dLayer={3} key={did + 'ab'}
          />
          <afp.EiyoushiHaichi
            uid={UID} did={did} size='middle' dLayer={3} key={did + 'ac'}
          />
          <afp.IryouCareJi
            uid={UID} did={did} size='middle' dLayer={3} key={did + 'ad'}
          />
          

        </div>
        <div className='cntRow'>
          <CommentInput 
            name='notice' initVal={thisNotice}
            placeholder='家庭連携加算、欠席加算の説明など' title='備考（加算説明等）' 
            key={did + 'ab'}
          />
          <CommentInput 
            name='memo' initVal={thisMemo}
            placeholder='事業所内の連絡事項' title='メモ（事業所内連絡）' 
            key={did + 'ac'}
          />
        </div>
      </form>
      <div className={'buttonWrapper ' + classes.thisBttonWrap}>
        <button id={hiddenInput} style={{
          tabindex: '-1',
          width: 0,
          opacity: 0,  
          // visibility:'hidden'
        }}></button>

        {/* 複数単位所有ユーザは一括変更しない */}
        {classroomCount < 2 &&
          <div className={'allSubmitOuter'}>
          <div className='small'>
            {
              (
                did.substr(5, 2) + "月" + 
                did.substr(7, 2) + "日").replace(/0/g, ''
              )
            }以降
          </div>
          <div>
            <Button
              className={classes.allSubmitButton}
              variant="contained"
              startIcon={<PeopleAltIcon />}
              onClick={handleAllSubmit}
            >
              一括変更<span>{didLength}</span>件
            </Button>
          </div>
          </div>
        }

        <Button
          className={classes.absenceButton}
          variant="contained"
          startIcon={<BlockRoundedIcon />}
          onClick={handleAbcense}
        >
          { absenceBtnText }
        </Button>

        <mui.ButtonGP
          color='secondary'
          label='キャンセル'
          onClick={handleClose}
        />
        <mui.ButtonGP
          color='primary'
          label={submitBtnText}
          type="submit"
          onClick={handleSubmit}
        />
      </div>

    </Dialog>
  </>);
}

export default SchEditDetailDialog;