export const unitPrice = {
  放課後等デイサービス:{
    一級地: 11.20, 二級地: 10.96, 三級地: 10.90, 四級地: 10.72,
    五級地: 10.60, 六級地: 10.36, 七級地: 10.18, その他: 10.00,
  },
  児童発達支援:{
    一級地: 11.20, 二級地: 10.96, 三級地: 10.90, 四級地: 10.72,
    五級地: 10.60, 六級地: 10.36, 七級地: 10.18, その他: 10.00,
  },
}

export const chiikiKubun = {
  一級地: 11, 二級地: 12, 三級地: 13, 四級地: 14,
  五級地: 15, 六級地: 16, 七級地: 17, その他: 23,
}

export const serviceSyubetu = {
  放課後等デイサービス: 63, 児童発達支援: 61,
  '63': '放課後等デイサービス', '61': '児童発達支援',
}

// 決定サービスコード
export const ketteiScodeOld = {
  放課後等デイサービス: 631000, 児童発達支援: 611000,
}

// 決定サービスコード新
export const ketteiScode = [
  {service:'児童発達支援',iCare:0,type:'障害児',kettei:'611000',},
  {service:'児童発達支援',iCare:0,type:'難聴児',kettei:'612000',},
  {service:'児童発達支援',iCare:0,type:'重症心身障害児',kettei:'613000',},
  {service:'児童発達支援',iCare:3,type:'障害児',kettei:'614000',},
  {service:'児童発達支援',iCare:16,type:'障害児',kettei:'615000',},
  {service:'児童発達支援',iCare:32,type:'障害児',kettei:'616000',},
  {service:'児童発達支援',iCare:3,type:'難聴児',kettei:'617000',},
  {service:'児童発達支援',iCare:16,type:'難聴児',kettei:'618000',},
  {service:'児童発達支援',iCare:32,type:'難聴児',kettei:'619000',},
  {service:'児童発達支援',iCare:3,type:'重症心身障害児',kettei:'61A000',},
  {service:'児童発達支援',iCare:16,type:'重症心身障害児',kettei:'61B000',},
  {service:'児童発達支援',iCare:32,type:'重症心身障害児',kettei:'61C000',},
  {service:'放課後等デイサービス',iCare:0,type:'障害児',kettei:'631000',},
  {service:'放課後等デイサービス',iCare:0,type:'重症心身障害児',kettei:'632000',},
  {service:'放課後等デイサービス',iCare:3,type:'障害児',kettei:'633000',},
  {service:'放課後等デイサービス',iCare:16,type:'障害児',kettei:'634000',},
  {service:'放課後等デイサービス',iCare:32,type:'障害児',kettei:'635000',},
  {service:'放課後等デイサービス',iCare:3,type:'重症心身障害児',kettei:'636000',},
  {service:'放課後等デイサービス',iCare:16,type:'重症心身障害児',kettei:'637000',},
  {service:'放課後等デイサービス',iCare:32,type:'重症心身障害児',kettei:'638000',},
]
// 送迎をカウントするために送迎のサービスコードを定義
export const SOUGEY_SVC_CODE = [
  '636240', '636243', '636241', '636244', '636242',
  '616240', '616423', '616241', '616424', '616422', 
];
// 同じく家族連携
export const KATEI_SVC_CODE = ['635350', '635351', '615350', '615351', ];
// 同じく訪問支援 2021コード表には見当たらない。廃止っぽい
// export const = ['635360', '635361',]
// 同じく医療連携
export const IREN_SVC_CODE = [
  '636230', '636231', '636232', '636280', '636281', '636282', '636283', 
  '636284', '636285', '639992', '636233', 
  '616230', '616231', '616232', '616280', 
  '616281', '616282', '616283', '616284', '616285', '619992', '616233', 
];
// 事業所内相談支援加算
export const SOUDANSIEN_SVC_CODE = [
  '635493', '635494', '615493', '615494', 
]
// 欠席対応加算確認用
export const KESSEKI_SVC_CODE = ['635496', '635495', '635500', '615495', '615496', ];

// 食事提供加算
export const SYOKUJI_SVC_CODE = ['615310', '615311', ];

// 放デイ サービス名ベース
export const serviceNameBase = [
  {wd:'放デイ２４',hd:'放デイ４２',target:'障害児',ku:'区分１',iryoucare:32,min:0,max:10},
  {wd:'放デイ３３',hd:'放デイ４２',target:'障害児',ku:'区分２',iryoucare:32,min:0,max:10},
  {wd:'放デイ２５',hd:'放デイ４３',target:'障害児',ku:'区分１',iryoucare:32,min:11,max:20},
  {wd:'放デイ３４',hd:'放デイ４３',target:'障害児',ku:'区分２',iryoucare:32,min:11,max:20},
  {wd:'放デイ２６',hd:'放デイ４４',target:'障害児',ku:'区分１',iryoucare:32,min:21,max:100},
  {wd:'放デイ３５',hd:'放デイ４４',target:'障害児',ku:'区分２',iryoucare:32,min:21,max:100},
  {wd:'放デイ２７',hd:'放デイ４５',target:'障害児',ku:'区分１',iryoucare:16,min:0,max:10},
  {wd:'放デイ３６',hd:'放デイ４５',target:'障害児',ku:'区分２',iryoucare:16,min:0,max:10},
  {wd:'放デイ２８',hd:'放デイ４６',target:'障害児',ku:'区分１',iryoucare:16,min:11,max:20},
  {wd:'放デイ３７',hd:'放デイ４６',target:'障害児',ku:'区分２',iryoucare:16,min:11,max:20},
  {wd:'放デイ２９',hd:'放デイ４７',target:'障害児',ku:'区分１',iryoucare:16,min:21,max:100},
  {wd:'放デイ３８',hd:'放デイ４７',target:'障害児',ku:'区分２',iryoucare:16,min:21,max:100},
  {wd:'放デイ３０',hd:'放デイ４８',target:'障害児',ku:'区分１',iryoucare:3,min:0,max:10},
  {wd:'放デイ３９',hd:'放デイ４８',target:'障害児',ku:'区分２',iryoucare:3,min:0,max:10},
  {wd:'放デイ３１',hd:'放デイ４９',target:'障害児',ku:'区分１',iryoucare:3,min:11,max:20},
  {wd:'放デイ４０',hd:'放デイ４９',target:'障害児',ku:'区分２',iryoucare:3,min:11,max:20},
  {wd:'放デイ３２',hd:'放デイ５０',target:'障害児',ku:'区分１',iryoucare:3,min:21,max:100},
  {wd:'放デイ４１',hd:'放デイ５０',target:'障害児',ku:'区分２',iryoucare:3,min:21,max:100},
  {wd:'放デイ１',hd:'放デイ４',target:'障害児',ku:'区分１',iryoucare:'',min:0,max:10},
  {wd:'放デイ２１',hd:'放デイ４',target:'障害児',ku:'区分２',iryoucare:'',min:0,max:10},
  {wd:'放デイ２',hd:'放デイ５',target:'障害児',ku:'区分１',iryoucare:'',min:11,max:20},
  {wd:'放デイ２２',hd:'放デイ５',target:'障害児',ku:'区分２',iryoucare:'',min:11,max:20},
  {wd:'放デイ３',hd:'放デイ６',target:'障害児',ku:'区分１',iryoucare:'',min:21,max:100},
  {wd:'放デイ２３',hd:'放デイ６',target:'障害児',ku:'区分２',iryoucare:'',min:21,max:100},
  {wd:'放デイ１２',hd:'放デイ１９',target:'重症心身障害児',ku:'',iryoucare:'',min:0,max:10},
  {wd:'放デイ１３',hd:'放デイ２０',target:'重症心身障害児',ku:'',iryoucare:'',min:11,max:100},
  {wd:'放デイ７',hd:'放デイ１４',target:'重症心身障害児',ku:'',iryoucare:'',min:5,max:5},
  {wd:'放デイ８',hd:'放デイ１５',target:'重症心身障害児',ku:'',iryoucare:'',min:6,max:6},
  {wd:'放デイ９',hd:'放デイ１６',target:'重症心身障害児',ku:'',iryoucare:'',min:7,max:7},
  {wd:'放デイ１０',hd:'放デイ１７',target:'重症心身障害児',ku:'',iryoucare:'',min:8,max:8},
  {wd:'放デイ１１',hd:'放デイ１８',target:'重症心身障害児',ku:'',iryoucare:'',min:9,max:9},
  {wd:'共生型放デイ１',hd:'共生型放デイ２',target:'共生型',ku:'',iryoucare:'',min:'',max:null},
  {wd:'基準該当放デイⅠ１',hd:'基準該当放デイⅠ２',target:'基準該当Ⅰ',ku:'',iryoucare:'',min:null,max:null},
  {wd:'基準該当放デイⅡ１',hd:'基準該当放デイⅡ２',target:'基準該当Ⅱ',ku:'',iryoucare:'',min:null,max:null},
];


export const houdySirvice = [
  {s:'63G001', v:2604 , c:'放デイ２４・', baseItem: true},
  {s:'63G002', v:2213 , c:'放デイ２４・評減・', baseItem: true},
  {s:'63G003', v:1823 , c:'放デイ２４・未計画・', baseItem: true},
  {s:'63G004', v:1550 , c:'放デイ２４・未計画・評減・', baseItem: true},
  {s:'63G005', v:1302 , c:'放デイ２４・未計画２・', baseItem: true},
  {s:'63G006', v:1107 , c:'放デイ２４・未計画２・評減・', baseItem: true},
  {s:'63G007', v:2402 , c:'放デイ２５・', baseItem: true},
  {s:'63G008', v:2042 , c:'放デイ２５・評減・', baseItem: true},
  {s:'63G009', v:1681 , c:'放デイ２５・未計画・', baseItem: true},
  {s:'63G010', v:1429 , c:'放デイ２５・未計画・評減・', baseItem: true},
  {s:'63G011', v:1201 , c:'放デイ２５・未計画２・', baseItem: true},
  {s:'63G012', v:1021 , c:'放デイ２５・未計画２・評減・', baseItem: true},
  {s:'63G013', v:2302 , c:'放デイ２６・', baseItem: true},
  {s:'63G014', v:1957 , c:'放デイ２６・評減・', baseItem: true},
  {s:'63G015', v:1611 , c:'放デイ２６・未計画・', baseItem: true},
  {s:'63G016', v:1369 , c:'放デイ２６・未計画・評減・', baseItem: true},
  {s:'63G017', v:1151 , c:'放デイ２６・未計画２・', baseItem: true},
  {s:'63G018', v:978 , c:'放デイ２６・未計画２・評減・', baseItem: true},
  {s:'63G019', v:1604 , c:'放デイ２７・', baseItem: true},
  {s:'63G020', v:1363 , c:'放デイ２７・評減・', baseItem: true},
  {s:'63G021', v:1123 , c:'放デイ２７・未計画・', baseItem: true},
  {s:'63G022', v:955 , c:'放デイ２７・未計画・評減・', baseItem: true},
  {s:'63G023', v:802 , c:'放デイ２７・未計画２・', baseItem: true},
  {s:'63G024', v:682 , c:'放デイ２７・未計画２・評減・', baseItem: true},
  {s:'63G025', v:1402 , c:'放デイ２８・', baseItem: true},
  {s:'63G026', v:1192 , c:'放デイ２８・評減・', baseItem: true},
  {s:'63G027', v:981 , c:'放デイ２８・未計画・', baseItem: true},
  {s:'63G028', v:834 , c:'放デイ２８・未計画・評減・', baseItem: true},
  {s:'63G029', v:701 , c:'放デイ２８・未計画２・', baseItem: true},
  {s:'63G030', v:596 , c:'放デイ２８・未計画２・評減・', baseItem: true},
  {s:'63G031', v:1302 , c:'放デイ２９・', baseItem: true},
  {s:'63G032', v:1107 , c:'放デイ２９・評減・', baseItem: true},
  {s:'63G033', v:911 , c:'放デイ２９・未計画・', baseItem: true},
  {s:'63G034', v:774 , c:'放デイ２９・未計画・評減・', baseItem: true},
  {s:'63G035', v:651 , c:'放デイ２９・未計画２・', baseItem: true},
  {s:'63G036', v:553 , c:'放デイ２９・未計画２・評減・', baseItem: true},
  {s:'63G037', v:1271 , c:'放デイ３０・', baseItem: true},
  {s:'63G038', v:1080 , c:'放デイ３０・評減・', baseItem: true},
  {s:'63G039', v:890 , c:'放デイ３０・未計画・', baseItem: true},
  {s:'63G040', v:757 , c:'放デイ３０・未計画・評減・', baseItem: true},
  {s:'63G041', v:636 , c:'放デイ３０・未計画２・', baseItem: true},
  {s:'63G042', v:541 , c:'放デイ３０・未計画２・評減・', baseItem: true},
  {s:'63G043', v:1069 , c:'放デイ３１・', baseItem: true},
  {s:'63G044', v:909 , c:'放デイ３１・評減・', baseItem: true},
  {s:'63G045', v:748 , c:'放デイ３１・未計画・', baseItem: true},
  {s:'63G046', v:636 , c:'放デイ３１・未計画・評減・', baseItem: true},
  {s:'63G047', v:535 , c:'放デイ３１・未計画２・', baseItem: true},
  {s:'63G048', v:455 , c:'放デイ３１・未計画２・評減・', baseItem: true},
  {s:'63G049', v:969 , c:'放デイ３２・', baseItem: true},
  {s:'63G050', v:824 , c:'放デイ３２・評減・', baseItem: true},
  {s:'63G051', v:678 , c:'放デイ３２・未計画・', baseItem: true},
  {s:'63G052', v:576 , c:'放デイ３２・未計画・評減・', baseItem: true},
  {s:'63G053', v:485 , c:'放デイ３２・未計画２・', baseItem: true},
  {s:'63G054', v:412 , c:'放デイ３２・未計画２・評減・', baseItem: true},
  {s:'631111', v:604 , c:'放デイ１・', baseItem: true},
  {s:'631135', v:513 , c:'放デイ１・評減・', baseItem: true},
  {s:'631112', v:423 , c:'放デイ１・未計画・', baseItem: true},
  {s:'631136', v:360 , c:'放デイ１・未計画・評減・', baseItem: true},
  {s:'631137', v:302 , c:'放デイ１・未計画２・', baseItem: true},
  {s:'631138', v:257 , c:'放デイ１・未計画２・評減・', baseItem: true},
  {s:'631121', v:402 , c:'放デイ２・', baseItem: true},
  {s:'631155', v:342 , c:'放デイ２・評減・', baseItem: true},
  {s:'631122', v:281 , c:'放デイ２・未計画・', baseItem: true},
  {s:'631156', v:239 , c:'放デイ２・未計画・評減・', baseItem: true},
  {s:'631157', v:201 , c:'放デイ２・未計画２・', baseItem: true},
  {s:'631158', v:171 , c:'放デイ２・未計画２・評減・', baseItem: true},
  {s:'631131', v:302 , c:'放デイ３・', baseItem: true},
  {s:'631175', v:257 , c:'放デイ３・評減・', baseItem: true},
  {s:'631132', v:211 , c:'放デイ３・未計画・', baseItem: true},
  {s:'631176', v:179 , c:'放デイ３・未計画・評減・', baseItem: true},
  {s:'631177', v:151 , c:'放デイ３・未計画２・', baseItem: true},
  {s:'631178', v:128 , c:'放デイ３・未計画２・評減・', baseItem: true},
  {s:'63G055', v:2591 , c:'放デイ３３・', baseItem: true},
  {s:'63G056', v:2202 , c:'放デイ３３・評減・', baseItem: true},
  {s:'63G057', v:1814 , c:'放デイ３３・未計画・', baseItem: true},
  {s:'63G058', v:1542 , c:'放デイ３３・未計画・評減・', baseItem: true},
  {s:'63G059', v:1296 , c:'放デイ３３・未計画２・', baseItem: true},
  {s:'63G060', v:1102 , c:'放デイ３３・未計画２・評減・', baseItem: true},
  {s:'63G061', v:2393 , c:'放デイ３４・', baseItem: true},
  {s:'63G062', v:2034 , c:'放デイ３４・評減・', baseItem: true},
  {s:'63G063', v:1675 , c:'放デイ３４・未計画・', baseItem: true},
  {s:'63G064', v:1424 , c:'放デイ３４・未計画・評減・', baseItem: true},
  {s:'63G065', v:1197 , c:'放デイ３４・未計画２・', baseItem: true},
  {s:'63G066', v:1017 , c:'放デイ３４・未計画２・評減・', baseItem: true},
  {s:'63G067', v:2295 , c:'放デイ３５・', baseItem: true},
  {s:'63G068', v:1951 , c:'放デイ３５・評減・', baseItem: true},
  {s:'63G069', v:1607 , c:'放デイ３５・未計画・', baseItem: true},
  {s:'63G070', v:1366 , c:'放デイ３５・未計画・評減・', baseItem: true},
  {s:'63G071', v:1148 , c:'放デイ３５・未計画２・', baseItem: true},
  {s:'63G072', v:976 , c:'放デイ３５・未計画２・評減・', baseItem: true},
  {s:'63G073', v:1591 , c:'放デイ３６・', baseItem: true},
  {s:'63G074', v:1352 , c:'放デイ３６・評減・', baseItem: true},
  {s:'63G075', v:1114 , c:'放デイ３６・未計画・', baseItem: true},
  {s:'63G076', v:947 , c:'放デイ３６・未計画・評減・', baseItem: true},
  {s:'63G077', v:796 , c:'放デイ３６・未計画２・', baseItem: true},
  {s:'63G078', v:677 , c:'放デイ３６・未計画２・評減・', baseItem: true},
  {s:'63G079', v:1393 , c:'放デイ３７・', baseItem: true},
  {s:'63G080', v:1184 , c:'放デイ３７・評減・', baseItem: true},
  {s:'63G081', v:975 , c:'放デイ３７・未計画・', baseItem: true},
  {s:'63G082', v:829 , c:'放デイ３７・未計画・評減・', baseItem: true},
  {s:'63G083', v:697 , c:'放デイ３７・未計画２・', baseItem: true},
  {s:'63G084', v:592 , c:'放デイ３７・未計画２・評減・', baseItem: true},
  {s:'63G085', v:1295 , c:'放デイ３８・', baseItem: true},
  {s:'63G086', v:1101 , c:'放デイ３８・評減・', baseItem: true},
  {s:'63G087', v:907 , c:'放デイ３８・未計画・', baseItem: true},
  {s:'63G088', v:771 , c:'放デイ３８・未計画・評減・', baseItem: true},
  {s:'63G089', v:648 , c:'放デイ３８・未計画２・', baseItem: true},
  {s:'63G090', v:551 , c:'放デイ３８・未計画２・評減・', baseItem: true},
  {s:'63G091', v:1258 , c:'放デイ３９・', baseItem: true},
  {s:'63G092', v:1069 , c:'放デイ３９・評減・', baseItem: true},
  {s:'63G093', v:881 , c:'放デイ３９・未計画・', baseItem: true},
  {s:'63G094', v:749 , c:'放デイ３９・未計画・評減・', baseItem: true},
  {s:'63G095', v:629 , c:'放デイ３９・未計画２・', baseItem: true},
  {s:'63G096', v:535 , c:'放デイ３９・未計画２・評減・', baseItem: true},
  {s:'63G097', v:1060 , c:'放デイ４０・', baseItem: true},
  {s:'63G098', v:901 , c:'放デイ４０・評減・', baseItem: true},
  {s:'63G099', v:742 , c:'放デイ４０・未計画・', baseItem: true},
  {s:'63G100', v:631 , c:'放デイ４０・未計画・評減・', baseItem: true},
  {s:'63G101', v:530 , c:'放デイ４０・未計画２・', baseItem: true},
  {s:'63G102', v:451 , c:'放デイ４０・未計画２・評減・', baseItem: true},
  {s:'63G103', v:962 , c:'放デイ４１・', baseItem: true},
  {s:'63G104', v:818 , c:'放デイ４１・評減・', baseItem: true},
  {s:'63G105', v:673 , c:'放デイ４１・未計画・', baseItem: true},
  {s:'63G106', v:572 , c:'放デイ４１・未計画・評減・', baseItem: true},
  {s:'63G107', v:481 , c:'放デイ４１・未計画２・', baseItem: true},
  {s:'63G108', v:409 , c:'放デイ４１・未計画２・評減・', baseItem: true},
  {s:'631271', v:591 , c:'放デイ２１・', baseItem: true},
  {s:'631272', v:502 , c:'放デイ２１・評減・', baseItem: true},
  {s:'631273', v:414 , c:'放デイ２１・未計画・', baseItem: true},
  {s:'631274', v:352 , c:'放デイ２１・未計画・評減・', baseItem: true},
  {s:'631275', v:296 , c:'放デイ２１・未計画２・', baseItem: true},
  {s:'631276', v:252 , c:'放デイ２１・未計画２・評減・', baseItem: true},
  {s:'631381', v:393 , c:'放デイ２２・', baseItem: true},
  {s:'631382', v:334 , c:'放デイ２２・評減・', baseItem: true},
  {s:'631383', v:275 , c:'放デイ２２・未計画・', baseItem: true},
  {s:'631384', v:234 , c:'放デイ２２・未計画・評減・', baseItem: true},
  {s:'631385', v:197 , c:'放デイ２２・未計画２・', baseItem: true},
  {s:'631386', v:167 , c:'放デイ２２・未計画２・評減・', baseItem: true},
  {s:'631481', v:295 , c:'放デイ２３・', baseItem: true},
  {s:'631482', v:251 , c:'放デイ２３・評減・', baseItem: true},
  {s:'631483', v:207 , c:'放デイ２３・未計画・', baseItem: true},
  {s:'631484', v:176 , c:'放デイ２３・未計画・評減・', baseItem: true},
  {s:'631485', v:148 , c:'放デイ２３・未計画２・', baseItem: true},
  {s:'631486', v:126 , c:'放デイ２３・未計画２・評減・', baseItem: true},
  {s:'63G109', v:2721 , c:'放デイ４２・', baseItem: true},
  {s:'63G110', v:2313 , c:'放デイ４２・評減・', baseItem: true},
  {s:'63G111', v:1905 , c:'放デイ４２・開減１・', baseItem: true},
  {s:'63G112', v:1619 , c:'放デイ４２・開減１・評減・', baseItem: true},
  {s:'63G113', v:2313 , c:'放デイ４２・開減２・', baseItem: true},
  {s:'63G114', v:1966 , c:'放デイ４２・開減２・評減・', baseItem: true},
  {s:'63G115', v:1905 , c:'放デイ４２・未計画・', baseItem: true},
  {s:'63G116', v:1619 , c:'放デイ４２・未計画・評減・', baseItem: true},
  {s:'63G117', v:1334 , c:'放デイ４２・未計画・開減１・', baseItem: true},
  {s:'63G118', v:1134 , c:'放デイ４２・未計画・開減１・評減・', baseItem: true},
  {s:'63G119', v:1619 , c:'放デイ４２・未計画・開減２・', baseItem: true},
  {s:'63G120', v:1376 , c:'放デイ４２・未計画・開減２・評減・', baseItem: true},
  {s:'63G121', v:1361 , c:'放デイ４２・未計画２・', baseItem: true},
  {s:'63G122', v:1157 , c:'放デイ４２・未計画２・評減・', baseItem: true},
  {s:'63G123', v:953 , c:'放デイ４２・未計画２・開減１・', baseItem: true},
  {s:'63G124', v:810 , c:'放デイ４２・未計画２・開減１・評減・', baseItem: true},
  {s:'63G125', v:1157 , c:'放デイ４２・未計画２・開減２・', baseItem: true},
  {s:'63G126', v:983 , c:'放デイ４２・未計画２・開減２・評減・', baseItem: true},
  {s:'63G127', v:2480 , c:'放デイ４３・', baseItem: true},
  {s:'63G128', v:2108 , c:'放デイ４３・評減・', baseItem: true},
  {s:'63G129', v:1736 , c:'放デイ４３・開減１・', baseItem: true},
  {s:'63G130', v:1476 , c:'放デイ４３・開減１・評減・', baseItem: true},
  {s:'63G131', v:2108 , c:'放デイ４３・開減２・', baseItem: true},
  {s:'63G132', v:1792 , c:'放デイ４３・開減２・評減・', baseItem: true},
  {s:'63G133', v:1736 , c:'放デイ４３・未計画・', baseItem: true},
  {s:'63G134', v:1476 , c:'放デイ４３・未計画・評減・', baseItem: true},
  {s:'63G135', v:1215 , c:'放デイ４３・未計画・開減１・', baseItem: true},
  {s:'63G136', v:1033 , c:'放デイ４３・未計画・開減１・評減・', baseItem: true},
  {s:'63G137', v:1476 , c:'放デイ４３・未計画・開減２・', baseItem: true},
  {s:'63G138', v:1255 , c:'放デイ４３・未計画・開減２・評減・', baseItem: true},
  {s:'63G139', v:1240 , c:'放デイ４３・未計画２・', baseItem: true},
  {s:'63G140', v:1054 , c:'放デイ４３・未計画２・評減・', baseItem: true},
  {s:'63G141', v:868 , c:'放デイ４３・未計画２・開減１・', baseItem: true},
  {s:'63G142', v:738 , c:'放デイ４３・未計画２・開減１・評減・', baseItem: true},
  {s:'63G143', v:1054 , c:'放デイ４３・未計画２・開減２・', baseItem: true},
  {s:'63G144', v:896 , c:'放デイ４３・未計画２・開減２・評減・', baseItem: true},
  {s:'63G145', v:2372 , c:'放デイ４４・', baseItem: true},
  {s:'63G146', v:2016 , c:'放デイ４４・評減・', baseItem: true},
  {s:'63G147', v:1660 , c:'放デイ４４・開減１・', baseItem: true},
  {s:'63G148', v:1411 , c:'放デイ４４・開減１・評減・', baseItem: true},
  {s:'63G149', v:2016 , c:'放デイ４４・開減２・', baseItem: true},
  {s:'63G150', v:1714 , c:'放デイ４４・開減２・評減・', baseItem: true},
  {s:'63G151', v:1660 , c:'放デイ４４・未計画・', baseItem: true},
  {s:'63G152', v:1411 , c:'放デイ４４・未計画・評減・', baseItem: true},
  {s:'63G153', v:1162 , c:'放デイ４４・未計画・開減１・', baseItem: true},
  {s:'63G154', v:988 , c:'放デイ４４・未計画・開減１・評減・', baseItem: true},
  {s:'63G155', v:1411 , c:'放デイ４４・未計画・開減２・', baseItem: true},
  {s:'63G156', v:1199 , c:'放デイ４４・未計画・開減２・評減・', baseItem: true},
  {s:'63G157', v:1186 , c:'放デイ４４・未計画２・', baseItem: true},
  {s:'63G158', v:1008 , c:'放デイ４４・未計画２・評減・', baseItem: true},
  {s:'63G159', v:830 , c:'放デイ４４・未計画２・開減１・', baseItem: true},
  {s:'63G160', v:706 , c:'放デイ４４・未計画２・開減１・評減・', baseItem: true},
  {s:'63G161', v:1008 , c:'放デイ４４・未計画２・開減２・', baseItem: true},
  {s:'63G162', v:857 , c:'放デイ４４・未計画２・開減２・評減・', baseItem: true},
  {s:'63G163', v:1721 , c:'放デイ４５・', baseItem: true},
  {s:'63G164', v:1463 , c:'放デイ４５・評減・', baseItem: true},
  {s:'63G165', v:1205 , c:'放デイ４５・開減１・', baseItem: true},
  {s:'63G166', v:1024 , c:'放デイ４５・開減１・評減・', baseItem: true},
  {s:'63G167', v:1463 , c:'放デイ４５・開減２・', baseItem: true},
  {s:'63G168', v:1244 , c:'放デイ４５・開減２・評減・', baseItem: true},
  {s:'63G169', v:1205 , c:'放デイ４５・未計画・', baseItem: true},
  {s:'63G170', v:1024 , c:'放デイ４５・未計画・評減・', baseItem: true},
  {s:'63G171', v:844 , c:'放デイ４５・未計画・開減１・', baseItem: true},
  {s:'63G172', v:717 , c:'放デイ４５・未計画・開減１・評減・', baseItem: true},
  {s:'63G173', v:1024 , c:'放デイ４５・未計画・開減２・', baseItem: true},
  {s:'63G174', v:870 , c:'放デイ４５・未計画・開減２・評減・', baseItem: true},
  {s:'63G175', v:861 , c:'放デイ４５・未計画２・', baseItem: true},
  {s:'63G176', v:732 , c:'放デイ４５・未計画２・評減・', baseItem: true},
  {s:'63G177', v:603 , c:'放デイ４５・未計画２・開減１・', baseItem: true},
  {s:'63G178', v:513 , c:'放デイ４５・未計画２・開減１・評減・', baseItem: true},
  {s:'63G179', v:732 , c:'放デイ４５・未計画２・開減２・', baseItem: true},
  {s:'63G180', v:622 , c:'放デイ４５・未計画２・開減２・評減・', baseItem: true},
  {s:'63G181', v:1480 , c:'放デイ４６・', baseItem: true},
  {s:'63G182', v:1258 , c:'放デイ４６・評減・', baseItem: true},
  {s:'63G183', v:1036 , c:'放デイ４６・開減１・', baseItem: true},
  {s:'63G184', v:881 , c:'放デイ４６・開減１・評減・', baseItem: true},
  {s:'63G185', v:1258 , c:'放デイ４６・開減２・', baseItem: true},
  {s:'63G186', v:1069 , c:'放デイ４６・開減２・評減・', baseItem: true},
  {s:'63G187', v:1036 , c:'放デイ４６・未計画・', baseItem: true},
  {s:'63G188', v:881 , c:'放デイ４６・未計画・評減・', baseItem: true},
  {s:'63G189', v:725 , c:'放デイ４６・未計画・開減１・', baseItem: true},
  {s:'63G190', v:616 , c:'放デイ４６・未計画・開減１・評減・', baseItem: true},
  {s:'63G191', v:881 , c:'放デイ４６・未計画・開減２・', baseItem: true},
  {s:'63G192', v:749 , c:'放デイ４６・未計画・開減２・評減・', baseItem: true},
  {s:'63G193', v:740 , c:'放デイ４６・未計画２・', baseItem: true},
  {s:'63G194', v:629 , c:'放デイ４６・未計画２・評減・', baseItem: true},
  {s:'63G195', v:518 , c:'放デイ４６・未計画２・開減１・', baseItem: true},
  {s:'63G196', v:440 , c:'放デイ４６・未計画２・開減１・評減・', baseItem: true},
  {s:'63G197', v:629 , c:'放デイ４６・未計画２・開減２・', baseItem: true},
  {s:'63G198', v:535 , c:'放デイ４６・未計画２・開減２・評減・', baseItem: true},
  {s:'63G199', v:1372 , c:'放デイ４７・', baseItem: true},
  {s:'63G200', v:1166 , c:'放デイ４７・評減・', baseItem: true},
  {s:'63G201', v:960 , c:'放デイ４７・開減１・', baseItem: true},
  {s:'63G202', v:816 , c:'放デイ４７・開減１・評減・', baseItem: true},
  {s:'63G203', v:1166 , c:'放デイ４７・開減２・', baseItem: true},
  {s:'63G204', v:991 , c:'放デイ４７・開減２・評減・', baseItem: true},
  {s:'63G205', v:960 , c:'放デイ４７・未計画・', baseItem: true},
  {s:'63G206', v:816 , c:'放デイ４７・未計画・評減・', baseItem: true},
  {s:'63G207', v:672 , c:'放デイ４７・未計画・開減１・', baseItem: true},
  {s:'63G208', v:571 , c:'放デイ４７・未計画・開減１・評減・', baseItem: true},
  {s:'63G209', v:816 , c:'放デイ４７・未計画・開減２・', baseItem: true},
  {s:'63G210', v:694 , c:'放デイ４７・未計画・開減２・評減・', baseItem: true},
  {s:'63G211', v:686 , c:'放デイ４７・未計画２・', baseItem: true},
  {s:'63G212', v:583 , c:'放デイ４７・未計画２・評減・', baseItem: true},
  {s:'63G213', v:480 , c:'放デイ４７・未計画２・開減１・', baseItem: true},
  {s:'63G214', v:408 , c:'放デイ４７・未計画２・開減１・評減・', baseItem: true},
  {s:'63G215', v:583 , c:'放デイ４７・未計画２・開減２・', baseItem: true},
  {s:'63G216', v:496 , c:'放デイ４７・未計画２・開減２・評減・', baseItem: true},
  {s:'63G217', v:1388 , c:'放デイ４８・', baseItem: true},
  {s:'63G218', v:1180 , c:'放デイ４８・評減・', baseItem: true},
  {s:'63G219', v:972 , c:'放デイ４８・開減１・', baseItem: true},
  {s:'63G220', v:826 , c:'放デイ４８・開減１・評減・', baseItem: true},
  {s:'63G221', v:1180 , c:'放デイ４８・開減２・', baseItem: true},
  {s:'63G222', v:1003 , c:'放デイ４８・開減２・評減・', baseItem: true},
  {s:'63G223', v:972 , c:'放デイ４８・未計画・', baseItem: true},
  {s:'63G224', v:826 , c:'放デイ４８・未計画・評減・', baseItem: true},
  {s:'63G225', v:680 , c:'放デイ４８・未計画・開減１・', baseItem: true},
  {s:'63G226', v:578 , c:'放デイ４８・未計画・開減１・評減・', baseItem: true},
  {s:'63G227', v:826 , c:'放デイ４８・未計画・開減２・', baseItem: true},
  {s:'63G228', v:702 , c:'放デイ４８・未計画・開減２・評減・', baseItem: true},
  {s:'63G229', v:694 , c:'放デイ４８・未計画２・', baseItem: true},
  {s:'63G230', v:590 , c:'放デイ４８・未計画２・評減・', baseItem: true},
  {s:'63G231', v:486 , c:'放デイ４８・未計画２・開減１・', baseItem: true},
  {s:'63G232', v:413 , c:'放デイ４８・未計画２・開減１・評減・', baseItem: true},
  {s:'63G233', v:590 , c:'放デイ４８・未計画２・開減２・', baseItem: true},
  {s:'63G234', v:502 , c:'放デイ４８・未計画２・開減２・評減・', baseItem: true},
  {s:'63G235', v:1147 , c:'放デイ４９・', baseItem: true},
  {s:'63G236', v:975 , c:'放デイ４９・評減・', baseItem: true},
  {s:'63G237', v:803 , c:'放デイ４９・開減１・', baseItem: true},
  {s:'63G238', v:683 , c:'放デイ４９・開減１・評減・', baseItem: true},
  {s:'63G239', v:975 , c:'放デイ４９・開減２・', baseItem: true},
  {s:'63G240', v:829 , c:'放デイ４９・開減２・評減・', baseItem: true},
  {s:'63G241', v:803 , c:'放デイ４９・未計画・', baseItem: true},
  {s:'63G242', v:683 , c:'放デイ４９・未計画・評減・', baseItem: true},
  {s:'63G243', v:562 , c:'放デイ４９・未計画・開減１・', baseItem: true},
  {s:'63G244', v:478 , c:'放デイ４９・未計画・開減１・評減・', baseItem: true},
  {s:'63G245', v:683 , c:'放デイ４９・未計画・開減２・', baseItem: true},
  {s:'63G246', v:581 , c:'放デイ４９・未計画・開減２・評減・', baseItem: true},
  {s:'63G247', v:574 , c:'放デイ４９・未計画２・', baseItem: true},
  {s:'63G248', v:488 , c:'放デイ４９・未計画２・評減・', baseItem: true},
  {s:'63G249', v:402 , c:'放デイ４９・未計画２・開減１・', baseItem: true},
  {s:'63G250', v:342 , c:'放デイ４９・未計画２・開減１・評減・', baseItem: true},
  {s:'63G251', v:488 , c:'放デイ４９・未計画２・開減２・', baseItem: true},
  {s:'63G252', v:415 , c:'放デイ４９・未計画２・開減２・評減・', baseItem: true},
  {s:'63G253', v:1039 , c:'放デイ５０・', baseItem: true},
  {s:'63G254', v:883 , c:'放デイ５０・評減・', baseItem: true},
  {s:'63G255', v:727 , c:'放デイ５０・開減１・', baseItem: true},
  {s:'63G256', v:618 , c:'放デイ５０・開減１・評減・', baseItem: true},
  {s:'63G257', v:883 , c:'放デイ５０・開減２・', baseItem: true},
  {s:'63G258', v:751 , c:'放デイ５０・開減２・評減・', baseItem: true},
  {s:'63G259', v:727 , c:'放デイ５０・未計画・', baseItem: true},
  {s:'63G260', v:618 , c:'放デイ５０・未計画・評減・', baseItem: true},
  {s:'63G261', v:509 , c:'放デイ５０・未計画・開減１・', baseItem: true},
  {s:'63G262', v:433 , c:'放デイ５０・未計画・開減１・評減・', baseItem: true},
  {s:'63G263', v:618 , c:'放デイ５０・未計画・開減２・', baseItem: true},
  {s:'63G264', v:525 , c:'放デイ５０・未計画・開減２・評減・', baseItem: true},
  {s:'63G265', v:520 , c:'放デイ５０・未計画２・', baseItem: true},
  {s:'63G266', v:442 , c:'放デイ５０・未計画２・評減・', baseItem: true},
  {s:'63G267', v:364 , c:'放デイ５０・未計画２・開減１・', baseItem: true},
  {s:'63G268', v:309 , c:'放デイ５０・未計画２・開減１・評減・', baseItem: true},
  {s:'63G269', v:442 , c:'放デイ５０・未計画２・開減２・', baseItem: true},
  {s:'63G270', v:376 , c:'放デイ５０・未計画２・開減２・評減・', baseItem: true},
  {s:'631211', v:721 , c:'放デイ４・', baseItem: true},
  {s:'631701', v:613 , c:'放デイ４・評減・', baseItem: true},
  {s:'631212', v:505 , c:'放デイ４・開減１・', baseItem: true},
  {s:'631702', v:429 , c:'放デイ４・開減１・評減・', baseItem: true},
  {s:'631215', v:613 , c:'放デイ４・開減２・', baseItem: true},
  {s:'631703', v:521 , c:'放デイ４・開減２・評減・', baseItem: true},
  {s:'631213', v:505 , c:'放デイ４・未計画・', baseItem: true},
  {s:'631710', v:429 , c:'放デイ４・未計画・評減・', baseItem: true},
  {s:'631214', v:354 , c:'放デイ４・未計画・開減１・', baseItem: true},
  {s:'631711', v:301 , c:'放デイ４・未計画・開減１・評減・', baseItem: true},
  {s:'631216', v:429 , c:'放デイ４・未計画・開減２・', baseItem: true},
  {s:'631712', v:365 , c:'放デイ４・未計画・開減２・評減・', baseItem: true},
  {s:'631719', v:361 , c:'放デイ４・未計画２・', baseItem: true},
  {s:'631720', v:307 , c:'放デイ４・未計画２・評減・', baseItem: true},
  {s:'631721', v:253 , c:'放デイ４・未計画２・開減１・', baseItem: true},
  {s:'631722', v:215 , c:'放デイ４・未計画２・開減１・評減・', baseItem: true},
  {s:'631723', v:307 , c:'放デイ４・未計画２・開減２・', baseItem: true},
  {s:'631724', v:261 , c:'放デイ４・未計画２・開減２・評減・', baseItem: true},
  {s:'631221', v:480 , c:'放デイ５・', baseItem: true},
  {s:'631771', v:408 , c:'放デイ５・評減・', baseItem: true},
  {s:'631222', v:336 , c:'放デイ５・開減１・', baseItem: true},
  {s:'631772', v:286 , c:'放デイ５・開減１・評減・', baseItem: true},
  {s:'631225', v:408 , c:'放デイ５・開減２・', baseItem: true},
  {s:'631773', v:347 , c:'放デイ５・開減２・評減・', baseItem: true},
  {s:'631223', v:336 , c:'放デイ５・未計画・', baseItem: true},
  {s:'631780', v:286 , c:'放デイ５・未計画・評減・', baseItem: true},
  {s:'631224', v:235 , c:'放デイ５・未計画・開減１・', baseItem: true},
  {s:'631781', v:200 , c:'放デイ５・未計画・開減１・評減・', baseItem: true},
  {s:'631226', v:286 , c:'放デイ５・未計画・開減２・', baseItem: true},
  {s:'631782', v:243 , c:'放デイ５・未計画・開減２・評減・', baseItem: true},
  {s:'631789', v:240 , c:'放デイ５・未計画２・', baseItem: true},
  {s:'631790', v:204 , c:'放デイ５・未計画２・評減・', baseItem: true},
  {s:'631791', v:168 , c:'放デイ５・未計画２・開減１・', baseItem: true},
  {s:'631792', v:143 , c:'放デイ５・未計画２・開減１・評減・', baseItem: true},
  {s:'631793', v:204 , c:'放デイ５・未計画２・開減２・', baseItem: true},
  {s:'631794', v:173 , c:'放デイ５・未計画２・開減２・評減・', baseItem: true},
  {s:'631231', v:372 , c:'放デイ６・', baseItem: true},
  {s:'631841', v:316 , c:'放デイ６・評減・', baseItem: true},
  {s:'631232', v:260 , c:'放デイ６・開減１・', baseItem: true},
  {s:'631842', v:221 , c:'放デイ６・開減１・評減・', baseItem: true},
  {s:'631235', v:316 , c:'放デイ６・開減２・', baseItem: true},
  {s:'631843', v:269 , c:'放デイ６・開減２・評減・', baseItem: true},
  {s:'631233', v:260 , c:'放デイ６・未計画・', baseItem: true},
  {s:'631850', v:221 , c:'放デイ６・未計画・評減・', baseItem: true},
  {s:'631234', v:182 , c:'放デイ６・未計画・開減１・', baseItem: true},
  {s:'631851', v:155 , c:'放デイ６・未計画・開減１・評減・', baseItem: true},
  {s:'631236', v:221 , c:'放デイ６・未計画・開減２・', baseItem: true},
  {s:'631852', v:188 , c:'放デイ６・未計画・開減２・評減・', baseItem: true},
  {s:'631859', v:186 , c:'放デイ６・未計画２・', baseItem: true},
  {s:'631860', v:158 , c:'放デイ６・未計画２・評減・', baseItem: true},
  {s:'631861', v:130 , c:'放デイ６・未計画２・開減１・', baseItem: true},
  {s:'631862', v:111 , c:'放デイ６・未計画２・開減１・評減・', baseItem: true},
  {s:'631863', v:158 , c:'放デイ６・未計画２・開減２・', baseItem: true},
  {s:'631864', v:134 , c:'放デイ６・未計画２・開減２・評減・', baseItem: true},
  {s:'631311', v:1756 , c:'放デイ７・', baseItem: true},
  {s:'632141', v:1493 , c:'放デイ７・評減・', baseItem: true},
  {s:'631312', v:1229 , c:'放デイ７・未計画・', baseItem: true},
  {s:'632142', v:1045 , c:'放デイ７・未計画・評減・', baseItem: true},
  {s:'632143', v:878 , c:'放デイ７・未計画２・', baseItem: true},
  {s:'632144', v:746 , c:'放デイ７・未計画２・評減・', baseItem: true},
  {s:'631341', v:1467 , c:'放デイ８・', baseItem: true},
  {s:'632151', v:1247 , c:'放デイ８・評減・', baseItem: true},
  {s:'631342', v:1027 , c:'放デイ８・未計画・', baseItem: true},
  {s:'632152', v:873 , c:'放デイ８・未計画・評減・', baseItem: true},
  {s:'632153', v:734 , c:'放デイ８・未計画２・', baseItem: true},
  {s:'632154', v:624 , c:'放デイ８・未計画２・評減・', baseItem: true},
  {s:'631351', v:1263 , c:'放デイ９・', baseItem: true},
  {s:'632161', v:1074 , c:'放デイ９・評減・', baseItem: true},
  {s:'631352', v:884 , c:'放デイ９・未計画・', baseItem: true},
  {s:'632162', v:751 , c:'放デイ９・未計画・評減・', baseItem: true},
  {s:'632163', v:632 , c:'放デイ９・未計画２・', baseItem: true},
  {s:'632164', v:537 , c:'放デイ９・未計画２・評減・', baseItem: true},
  {s:'631361', v:1108 , c:'放デイ１０・', baseItem: true},
  {s:'632171', v:942 , c:'放デイ１０・評減・', baseItem: true},
  {s:'631362', v:776 , c:'放デイ１０・未計画・', baseItem: true},
  {s:'632172', v:660 , c:'放デイ１０・未計画・評減・', baseItem: true},
  {s:'632173', v:554 , c:'放デイ１０・未計画２・', baseItem: true},
  {s:'632174', v:471 , c:'放デイ１０・未計画２・評減・', baseItem: true},
  {s:'631371', v:989 , c:'放デイ１１・', baseItem: true},
  {s:'632181', v:841 , c:'放デイ１１・評減・', baseItem: true},
  {s:'631372', v:692 , c:'放デイ１１・未計画・', baseItem: true},
  {s:'632182', v:588 , c:'放デイ１１・未計画・評減・', baseItem: true},
  {s:'632183', v:495 , c:'放デイ１１・未計画２・', baseItem: true},
  {s:'632184', v:421 , c:'放デイ１１・未計画２・評減・', baseItem: true},
  {s:'631321', v:893 , c:'放デイ１２・', baseItem: true},
  {s:'632191', v:759 , c:'放デイ１２・評減・', baseItem: true},
  {s:'631322', v:625 , c:'放デイ１２・未計画・', baseItem: true},
  {s:'632192', v:531 , c:'放デイ１２・未計画・評減・', baseItem: true},
  {s:'632193', v:447 , c:'放デイ１２・未計画２・', baseItem: true},
  {s:'632194', v:380 , c:'放デイ１２・未計画２・評減・', baseItem: true},
  {s:'631331', v:686 , c:'放デイ１３・', baseItem: true},
  {s:'632201', v:583 , c:'放デイ１３・評減・', baseItem: true},
  {s:'631332', v:480 , c:'放デイ１３・未計画・', baseItem: true},
  {s:'632202', v:408 , c:'放デイ１３・未計画・評減・', baseItem: true},
  {s:'632203', v:343 , c:'放デイ１３・未計画２・', baseItem: true},
  {s:'632204', v:292 , c:'放デイ１３・未計画２・評減・', baseItem: true},
  {s:'631411', v:2038 , c:'放デイ１４・', baseItem: true},
  {s:'632211', v:1732 , c:'放デイ１４・評減・', baseItem: true},
  {s:'631412', v:1427 , c:'放デイ１４・開減１・', baseItem: true},
  {s:'632212', v:1213 , c:'放デイ１４・開減１・評減・', baseItem: true},
  {s:'631415', v:1732 , c:'放デイ１４・開減２・', baseItem: true},
  {s:'632213', v:1472 , c:'放デイ１４・開減２・評減・', baseItem: true},
  {s:'631413', v:1427 , c:'放デイ１４・未計画・', baseItem: true},
  {s:'632220', v:1213 , c:'放デイ１４・未計画・評減・', baseItem: true},
  {s:'631414', v:999 , c:'放デイ１４・未計画・開減１・', baseItem: true},
  {s:'632221', v:849 , c:'放デイ１４・未計画・開減１・評減・', baseItem: true},
  {s:'631416', v:1213 , c:'放デイ１４・未計画・開減２・', baseItem: true},
  {s:'632222', v:1031 , c:'放デイ１４・未計画・開減２・評減・', baseItem: true},
  {s:'632229', v:1019 , c:'放デイ１４・未計画２・', baseItem: true},
  {s:'632230', v:866 , c:'放デイ１４・未計画２・評減・', baseItem: true},
  {s:'632231', v:713 , c:'放デイ１４・未計画２・開減１・', baseItem: true},
  {s:'632232', v:606 , c:'放デイ１４・未計画２・開減１・評減・', baseItem: true},
  {s:'632233', v:866 , c:'放デイ１４・未計画２・開減２・', baseItem: true},
  {s:'632234', v:736 , c:'放デイ１４・未計画２・開減２・評減・', baseItem: true},
  {s:'631441', v:1706 , c:'放デイ１５・', baseItem: true},
  {s:'632241', v:1450 , c:'放デイ１５・評減・', baseItem: true},
  {s:'631442', v:1194 , c:'放デイ１５・開減１・', baseItem: true},
  {s:'632242', v:1015 , c:'放デイ１５・開減１・評減・', baseItem: true},
  {s:'631443', v:1450 , c:'放デイ１５・開減２・', baseItem: true},
  {s:'632243', v:1233 , c:'放デイ１５・開減２・評減・', baseItem: true},
  {s:'631444', v:1194 , c:'放デイ１５・未計画・', baseItem: true},
  {s:'632250', v:1015 , c:'放デイ１５・未計画・評減・', baseItem: true},
  {s:'631445', v:836 , c:'放デイ１５・未計画・開減１・', baseItem: true},
  {s:'632251', v:711 , c:'放デイ１５・未計画・開減１・評減・', baseItem: true},
  {s:'631446', v:1015 , c:'放デイ１５・未計画・開減２・', baseItem: true},
  {s:'632252', v:863 , c:'放デイ１５・未計画・開減２・評減・', baseItem: true},
  {s:'632259', v:853 , c:'放デイ１５・未計画２・', baseItem: true},
  {s:'632260', v:725 , c:'放デイ１５・未計画２・評減・', baseItem: true},
  {s:'632261', v:597 , c:'放デイ１５・未計画２・開減１・', baseItem: true},
  {s:'632262', v:507 , c:'放デイ１５・未計画２・開減１・評減・', baseItem: true},
  {s:'632263', v:725 , c:'放デイ１５・未計画２・開減２・', baseItem: true},
  {s:'632264', v:616 , c:'放デイ１５・未計画２・開減２・評減・', baseItem: true},
  {s:'631451', v:1466 , c:'放デイ１６・', baseItem: true},
  {s:'632271', v:1246 , c:'放デイ１６・評減・', baseItem: true},
  {s:'631452', v:1026 , c:'放デイ１６・開減１・', baseItem: true},
  {s:'632272', v:872 , c:'放デイ１６・開減１・評減・', baseItem: true},
  {s:'631453', v:1246 , c:'放デイ１６・開減２・', baseItem: true},
  {s:'632273', v:1059 , c:'放デイ１６・開減２・評減・', baseItem: true},
  {s:'631454', v:1026 , c:'放デイ１６・未計画・', baseItem: true},
  {s:'632280', v:872 , c:'放デイ１６・未計画・評減・', baseItem: true},
  {s:'631455', v:718 , c:'放デイ１６・未計画・開減１・', baseItem: true},
  {s:'632281', v:610 , c:'放デイ１６・未計画・開減１・評減・', baseItem: true},
  {s:'631456', v:872 , c:'放デイ１６・未計画・開減２・', baseItem: true},
  {s:'632282', v:741 , c:'放デイ１６・未計画・開減２・評減・', baseItem: true},
  {s:'632289', v:733 , c:'放デイ１６・未計画２・', baseItem: true},
  {s:'632290', v:623 , c:'放デイ１６・未計画２・評減・', baseItem: true},
  {s:'632291', v:513 , c:'放デイ１６・未計画２・開減１・', baseItem: true},
  {s:'632292', v:436 , c:'放デイ１６・未計画２・開減１・評減・', baseItem: true},
  {s:'632293', v:623 , c:'放デイ１６・未計画２・開減２・', baseItem: true},
  {s:'632294', v:530 , c:'放デイ１６・未計画２・開減２・評減・', baseItem: true},
  {s:'631461', v:1288 , c:'放デイ１７・', baseItem: true},
  {s:'632301', v:1095 , c:'放デイ１７・評減・', baseItem: true},
  {s:'631462', v:902 , c:'放デイ１７・開減１・', baseItem: true},
  {s:'632302', v:767 , c:'放デイ１７・開減１・評減・', baseItem: true},
  {s:'631463', v:1095 , c:'放デイ１７・開減２・', baseItem: true},
  {s:'632303', v:931 , c:'放デイ１７・開減２・評減・', baseItem: true},
  {s:'631464', v:902 , c:'放デイ１７・未計画・', baseItem: true},
  {s:'632310', v:767 , c:'放デイ１７・未計画・評減・', baseItem: true},
  {s:'631465', v:631 , c:'放デイ１７・未計画・開減１・', baseItem: true},
  {s:'632311', v:536 , c:'放デイ１７・未計画・開減１・評減・', baseItem: true},
  {s:'631466', v:767 , c:'放デイ１７・未計画・開減２・', baseItem: true},
  {s:'632312', v:652 , c:'放デイ１７・未計画・開減２・評減・', baseItem: true},
  {s:'632319', v:644 , c:'放デイ１７・未計画２・', baseItem: true},
  {s:'632320', v:547 , c:'放デイ１７・未計画２・評減・', baseItem: true},
  {s:'632321', v:451 , c:'放デイ１７・未計画２・開減１・', baseItem: true},
  {s:'632322', v:383 , c:'放デイ１７・未計画２・開減１・評減・', baseItem: true},
  {s:'632323', v:547 , c:'放デイ１７・未計画２・開減２・', baseItem: true},
  {s:'632324', v:465 , c:'放デイ１７・未計画２・開減２・評減・', baseItem: true},
  {s:'631471', v:1150 , c:'放デイ１８・', baseItem: true},
  {s:'632331', v:978 , c:'放デイ１８・評減・', baseItem: true},
  {s:'631472', v:805 , c:'放デイ１８・開減１・', baseItem: true},
  {s:'632332', v:684 , c:'放デイ１８・開減１・評減・', baseItem: true},
  {s:'631473', v:978 , c:'放デイ１８・開減２・', baseItem: true},
  {s:'632333', v:831 , c:'放デイ１８・開減２・評減・', baseItem: true},
  {s:'631474', v:805 , c:'放デイ１８・未計画・', baseItem: true},
  {s:'632340', v:684 , c:'放デイ１８・未計画・評減・', baseItem: true},
  {s:'631475', v:564 , c:'放デイ１８・未計画・開減１・', baseItem: true},
  {s:'632341', v:479 , c:'放デイ１８・未計画・開減１・評減・', baseItem: true},
  {s:'631476', v:684 , c:'放デイ１８・未計画・開減２・', baseItem: true},
  {s:'632342', v:581 , c:'放デイ１８・未計画・開減２・評減・', baseItem: true},
  {s:'632349', v:575 , c:'放デイ１８・未計画２・', baseItem: true},
  {s:'632350', v:489 , c:'放デイ１８・未計画２・評減・', baseItem: true},
  {s:'632351', v:403 , c:'放デイ１８・未計画２・開減１・', baseItem: true},
  {s:'632352', v:343 , c:'放デイ１８・未計画２・開減１・評減・', baseItem: true},
  {s:'632353', v:489 , c:'放デイ１８・未計画２・開減２・', baseItem: true},
  {s:'632354', v:416 , c:'放デイ１８・未計画２・開減２・評減・', baseItem: true},
  {s:'631421', v:1039 , c:'放デイ１９・', baseItem: true},
  {s:'632361', v:883 , c:'放デイ１９・評減・', baseItem: true},
  {s:'631422', v:727 , c:'放デイ１９・開減１・', baseItem: true},
  {s:'632362', v:618 , c:'放デイ１９・開減１・評減・', baseItem: true},
  {s:'631425', v:883 , c:'放デイ１９・開減２・', baseItem: true},
  {s:'632363', v:751 , c:'放デイ１９・開減２・評減・', baseItem: true},
  {s:'631423', v:727 , c:'放デイ１９・未計画・', baseItem: true},
  {s:'632370', v:618 , c:'放デイ１９・未計画・評減・', baseItem: true},
  {s:'631424', v:509 , c:'放デイ１９・未計画・開減１・', baseItem: true},
  {s:'632371', v:433 , c:'放デイ１９・未計画・開減１・評減・', baseItem: true},
  {s:'631426', v:618 , c:'放デイ１９・未計画・開減２・', baseItem: true},
  {s:'632372', v:525 , c:'放デイ１９・未計画・開減２・評減・', baseItem: true},
  {s:'632379', v:520 , c:'放デイ１９・未計画２・', baseItem: true},
  {s:'632380', v:442 , c:'放デイ１９・未計画２・評減・', baseItem: true},
  {s:'632381', v:364 , c:'放デイ１９・未計画２・開減１・', baseItem: true},
  {s:'632382', v:309 , c:'放デイ１９・未計画２・開減１・評減・', baseItem: true},
  {s:'632383', v:442 , c:'放デイ１９・未計画２・開減２・', baseItem: true},
  {s:'632384', v:376 , c:'放デイ１９・未計画２・開減２・評減・', baseItem: true},
  {s:'631431', v:810 , c:'放デイ２０・', baseItem: true},
  {s:'632391', v:689 , c:'放デイ２０・評減・', baseItem: true},
  {s:'631432', v:567 , c:'放デイ２０・開減１・', baseItem: true},
  {s:'632392', v:482 , c:'放デイ２０・開減１・評減・', baseItem: true},
  {s:'631435', v:689 , c:'放デイ２０・開減２・', baseItem: true},
  {s:'632393', v:586 , c:'放デイ２０・開減２・評減・', baseItem: true},
  {s:'631433', v:567 , c:'放デイ２０・未計画・', baseItem: true},
  {s:'632400', v:482 , c:'放デイ２０・未計画・評減・', baseItem: true},
  {s:'631434', v:397 , c:'放デイ２０・未計画・開減１・', baseItem: true},
  {s:'632401', v:337 , c:'放デイ２０・未計画・開減１・評減・', baseItem: true},
  {s:'631436', v:482 , c:'放デイ２０・未計画・開減２・', baseItem: true},
  {s:'632402', v:410 , c:'放デイ２０・未計画・開減２・評減・', baseItem: true},
  {s:'632409', v:405 , c:'放デイ２０・未計画２・', baseItem: true},
  {s:'632410', v:344 , c:'放デイ２０・未計画２・評減・', baseItem: true},
  {s:'632411', v:284 , c:'放デイ２０・未計画２・開減１・', baseItem: true},
  {s:'632412', v:241 , c:'放デイ２０・未計画２・開減１・評減・', baseItem: true},
  {s:'632413', v:344 , c:'放デイ２０・未計画２・開減２・', baseItem: true},
  {s:'632414', v:292 , c:'放デイ２０・未計画２・開減２・評減・', baseItem: true},
  {s:'632431', v:426 , c:'共生型放デイ１・', baseItem: true},
  {s:'632432', v:362 , c:'共生型放デイ１・評減・', baseItem: true},
  {s:'632441', v:549 , c:'共生型放デイ２・', baseItem: true},
  {s:'632442', v:467 , c:'共生型放デイ２・評減・', baseItem: true},
  {s:'632443', v:384 , c:'共生型放デイ２・開減１・', baseItem: true},
  {s:'632444', v:326 , c:'共生型放デイ２・開減１・評減・', baseItem: true},
  {s:'632445', v:467 , c:'共生型放デイ２・開減２・', baseItem: true},
  {s:'632446', v:397 , c:'共生型放デイ２・開減２・評減・', baseItem: true},
  {s:'632461', v:529 , c:'基準該当放デイⅠ１・', baseItem: true},
  {s:'632462', v:450 , c:'基準該当放デイⅠ１・評減・', baseItem: true},
  {s:'632463', v:370 , c:'基準該当放デイⅠ１・未計画・', baseItem: true},
  {s:'632464', v:315 , c:'基準該当放デイⅠ１・未計画・評減・', baseItem: true},
  {s:'632465', v:265 , c:'基準該当放デイⅠ１・未計画２・', baseItem: true},
  {s:'632466', v:225 , c:'基準該当放デイⅠ１・未計画２・評減・', baseItem: true},
  {s:'632467', v:652 , c:'基準該当放デイⅠ２・', baseItem: true},
  {s:'632468', v:554 , c:'基準該当放デイⅠ２・評減・', baseItem: true},
  {s:'632469', v:456 , c:'基準該当放デイⅠ２・開減１・', baseItem: true},
  {s:'632470', v:388 , c:'基準該当放デイⅠ２・開減１・評減・', baseItem: true},
  {s:'632471', v:554 , c:'基準該当放デイⅠ２・開減２・', baseItem: true},
  {s:'632472', v:471 , c:'基準該当放デイⅠ２・開減２・評減・', baseItem: true},
  {s:'632473', v:456 , c:'基準該当放デイⅠ２・未計画・', baseItem: true},
  {s:'632474', v:388 , c:'基準該当放デイⅠ２・未計画・評減・', baseItem: true},
  {s:'632475', v:319 , c:'基準該当放デイⅠ２・未計画・開減１・', baseItem: true},
  {s:'632476', v:271 , c:'基準該当放デイⅠ２・未計画・開減１・評減・', baseItem: true},
  {s:'632477', v:388 , c:'基準該当放デイⅠ２・未計画・開減２・', baseItem: true},
  {s:'632478', v:330 , c:'基準該当放デイⅠ２・未計画・開減２・評減・', baseItem: true},
  {s:'632479', v:326 , c:'基準該当放デイⅠ２・未計画２・', baseItem: true},
  {s:'632480', v:277 , c:'基準該当放デイⅠ２・未計画２・評減・', baseItem: true},
  {s:'632481', v:228 , c:'基準該当放デイⅠ２・未計画２・開減１・', baseItem: true},
  {s:'632482', v:194 , c:'基準該当放デイⅠ２・未計画２・開減１・評減・', baseItem: true},
  {s:'632483', v:277 , c:'基準該当放デイⅠ２・未計画２・開減２・', baseItem: true},
  {s:'632484', v:235 , c:'基準該当放デイⅠ２・未計画２・開減２・評減・', baseItem: true},
  {s:'632491', v:426 , c:'基準該当放デイⅡ１・', baseItem: true},
  {s:'632492', v:362 , c:'基準該当放デイⅡ１・評減・', baseItem: true},
  {s:'632493', v:549 , c:'基準該当放デイⅡ２・', baseItem: true},
  {s:'632494', v:467 , c:'基準該当放デイⅡ２・評減・', baseItem: true},
  {s:'632495', v:384 , c:'基準該当放デイⅡ２・開減１・', baseItem: true},
  {s:'632496', v:326 , c:'基準該当放デイⅡ２・開減１・評減・', baseItem: true},
  {s:'632497', v:467 , c:'基準該当放デイⅡ２・開減２・', baseItem: true},
  {s:'632498', v:397 , c:'基準該当放デイⅡ２・開減２・評減・', baseItem: true},
];


export const houdayKasan = [
  {s:'63ZZ01', v:0.1 , c:'令和３年９月３０日までの上乗せ分（放デイ）・' ,name:'新型コロナウイルス感染症への対応', value:'1' , opt:'', limit:'',syoguu:'', tokubetsu:'1'},
  {s:'63Z001', v:-5 , c:'放デイ身体拘束廃止未実施減算・' ,name:'身体拘束廃止未実施減算', value:'1' , opt:'', limit:'',syoguu:'', tokubetsu:''},
  {s:'634000', v:187 , c:'放デイ児童指導員等加配加算Ⅰ１・１・' ,name:'児童指導員等加配加算（Ⅰ）', value:'理学療法士等' , opt:'理学療法士等・平日・区分１・10人以下・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634001', v:125 , c:'放デイ児童指導員等加配加算Ⅰ１・２・' ,name:'児童指導員等加配加算（Ⅰ）', value:'理学療法士等' , opt:'理学療法士等・平日・区分１・11から20・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634002', v:75 , c:'放デイ児童指導員等加配加算Ⅰ１・３・' ,name:'児童指導員等加配加算（Ⅰ）', value:'理学療法士等' , opt:'理学療法士等・平日・区分１・21人以上・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634003', v:187 , c:'放デイ児童指導員等加配加算Ⅰ１・４・' ,name:'児童指導員等加配加算（Ⅰ）', value:'理学療法士等' , opt:'理学療法士等・平日・区分２・10人以下・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634004', v:125 , c:'放デイ児童指導員等加配加算Ⅰ１・５・' ,name:'児童指導員等加配加算（Ⅰ）', value:'理学療法士等' , opt:'理学療法士等・平日・区分２・11から20・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634005', v:75 , c:'放デイ児童指導員等加配加算Ⅰ１・６・' ,name:'児童指導員等加配加算（Ⅰ）', value:'理学療法士等' , opt:'理学療法士等・平日・区分２・21人以上・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634012', v:187 , c:'放デイ児童指導員等加配加算Ⅰ１・１３・' ,name:'児童指導員等加配加算（Ⅰ）', value:'理学療法士等' , opt:'理学療法士等・休日・10人以下・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634013', v:125 , c:'放デイ児童指導員等加配加算Ⅰ１・１４・' ,name:'児童指導員等加配加算（Ⅰ）', value:'理学療法士等' , opt:'理学療法士等・休日・11から20・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634014', v:75 , c:'放デイ児童指導員等加配加算Ⅰ１・１５・' ,name:'児童指導員等加配加算（Ⅰ）', value:'理学療法士等' , opt:'理学療法士等・休日・21人以上・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634018', v:374 , c:'放デイ児童指導員等加配加算Ⅰ１・１９・' ,name:'児童指導員等加配加算（Ⅰ）', value:'理学療法士等' , opt:'理学療法士等・平日・重心・5人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634019', v:312 , c:'放デイ児童指導員等加配加算Ⅰ１・２０・' ,name:'児童指導員等加配加算（Ⅰ）', value:'理学療法士等' , opt:'理学療法士等・平日・重心・6人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634020', v:267 , c:'放デイ児童指導員等加配加算Ⅰ１・２１・' ,name:'児童指導員等加配加算（Ⅰ）', value:'理学療法士等' , opt:'理学療法士等・平日・重心・7人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634021', v:234 , c:'放デイ児童指導員等加配加算Ⅰ１・２２・' ,name:'児童指導員等加配加算（Ⅰ）', value:'理学療法士等' , opt:'理学療法士等・平日・重心・8人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634022', v:208 , c:'放デイ児童指導員等加配加算Ⅰ１・２３・' ,name:'児童指導員等加配加算（Ⅰ）', value:'理学療法士等' , opt:'理学療法士等・平日・重心・9人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634023', v:187 , c:'放デイ児童指導員等加配加算Ⅰ１・２４・' ,name:'児童指導員等加配加算（Ⅰ）', value:'理学療法士等' , opt:'理学療法士等・平日・重心・10人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634024', v:125 , c:'放デイ児童指導員等加配加算Ⅰ１・２５・' ,name:'児童指導員等加配加算（Ⅰ）', value:'理学療法士等' , opt:'理学療法士等・平日・重心・11以上・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634025', v:374 , c:'放デイ児童指導員等加配加算Ⅰ１・２６・' ,name:'児童指導員等加配加算（Ⅰ）', value:'理学療法士等' , opt:'理学療法士等・休日・重心・5人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634026', v:312 , c:'放デイ児童指導員等加配加算Ⅰ１・２７・' ,name:'児童指導員等加配加算（Ⅰ）', value:'理学療法士等' , opt:'理学療法士等・休日・重心・6人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634027', v:267 , c:'放デイ児童指導員等加配加算Ⅰ１・２８・' ,name:'児童指導員等加配加算（Ⅰ）', value:'理学療法士等' , opt:'理学療法士等・休日・重心・7人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634028', v:234 , c:'放デイ児童指導員等加配加算Ⅰ１・２９・' ,name:'児童指導員等加配加算（Ⅰ）', value:'理学療法士等' , opt:'理学療法士等・休日・重心・8人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634029', v:208 , c:'放デイ児童指導員等加配加算Ⅰ１・３０・' ,name:'児童指導員等加配加算（Ⅰ）', value:'理学療法士等' , opt:'理学療法士等・休日・重心・9人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634030', v:187 , c:'放デイ児童指導員等加配加算Ⅰ１・３１・' ,name:'児童指導員等加配加算（Ⅰ）', value:'理学療法士等' , opt:'理学療法士等・休日・重心・10人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634031', v:125 , c:'放デイ児童指導員等加配加算Ⅰ１・３２・' ,name:'児童指導員等加配加算（Ⅰ）', value:'理学療法士等' , opt:'理学療法士等・休日・重心・11以上・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634040', v:123 , c:'放デイ児童指導員等加配加算Ⅰ２・１・' ,name:'児童指導員等加配加算（Ⅰ）', value:'児童指導員' , opt:'児童指導員・平日・区分１・10人以下・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634041', v:82 , c:'放デイ児童指導員等加配加算Ⅰ２・２・' ,name:'児童指導員等加配加算（Ⅰ）', value:'児童指導員' , opt:'児童指導員・平日・区分１・11から20・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634042', v:49 , c:'放デイ児童指導員等加配加算Ⅰ２・３・' ,name:'児童指導員等加配加算（Ⅰ）', value:'児童指導員' , opt:'児童指導員・平日・区分１・21人以上・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634043', v:123 , c:'放デイ児童指導員等加配加算Ⅰ２・４・' ,name:'児童指導員等加配加算（Ⅰ）', value:'児童指導員' , opt:'児童指導員・平日・区分２・10人以下・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634044', v:82 , c:'放デイ児童指導員等加配加算Ⅰ２・５・' ,name:'児童指導員等加配加算（Ⅰ）', value:'児童指導員' , opt:'児童指導員・平日・区分２・11から20・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634045', v:49 , c:'放デイ児童指導員等加配加算Ⅰ２・６・' ,name:'児童指導員等加配加算（Ⅰ）', value:'児童指導員' , opt:'児童指導員・平日・区分２・21人以上・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634052', v:123 , c:'放デイ児童指導員等加配加算Ⅰ２・１３・' ,name:'児童指導員等加配加算（Ⅰ）', value:'児童指導員' , opt:'児童指導員・休日・10人以下・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634053', v:82 , c:'放デイ児童指導員等加配加算Ⅰ２・１４・' ,name:'児童指導員等加配加算（Ⅰ）', value:'児童指導員' , opt:'児童指導員・休日・11から20・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634054', v:49 , c:'放デイ児童指導員等加配加算Ⅰ２・１５・' ,name:'児童指導員等加配加算（Ⅰ）', value:'児童指導員' , opt:'児童指導員・休日・21人以上・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634058', v:247 , c:'放デイ児童指導員等加配加算Ⅰ２・１９・' ,name:'児童指導員等加配加算（Ⅰ）', value:'児童指導員' , opt:'児童指導員・平日・重心・5人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634059', v:206 , c:'放デイ児童指導員等加配加算Ⅰ２・２０・' ,name:'児童指導員等加配加算（Ⅰ）', value:'児童指導員' , opt:'児童指導員・平日・重心・6人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634060', v:176 , c:'放デイ児童指導員等加配加算Ⅰ２・２１・' ,name:'児童指導員等加配加算（Ⅰ）', value:'児童指導員' , opt:'児童指導員・平日・重心・7人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634061', v:154 , c:'放デイ児童指導員等加配加算Ⅰ２・２２・' ,name:'児童指導員等加配加算（Ⅰ）', value:'児童指導員' , opt:'児童指導員・平日・重心・8人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634062', v:137 , c:'放デイ児童指導員等加配加算Ⅰ２・２３・' ,name:'児童指導員等加配加算（Ⅰ）', value:'児童指導員' , opt:'児童指導員・平日・重心・9人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634063', v:123 , c:'放デイ児童指導員等加配加算Ⅰ２・２４・' ,name:'児童指導員等加配加算（Ⅰ）', value:'児童指導員' , opt:'児童指導員・平日・重心・10人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634064', v:82 , c:'放デイ児童指導員等加配加算Ⅰ２・２５・' ,name:'児童指導員等加配加算（Ⅰ）', value:'児童指導員' , opt:'児童指導員・平日・重心・11以上・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634065', v:247 , c:'放デイ児童指導員等加配加算Ⅰ２・２６・' ,name:'児童指導員等加配加算（Ⅰ）', value:'児童指導員' , opt:'児童指導員・休日・重心・5人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634066', v:206 , c:'放デイ児童指導員等加配加算Ⅰ２・２７・' ,name:'児童指導員等加配加算（Ⅰ）', value:'児童指導員' , opt:'児童指導員・休日・重心・6人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634067', v:176 , c:'放デイ児童指導員等加配加算Ⅰ２・２８・' ,name:'児童指導員等加配加算（Ⅰ）', value:'児童指導員' , opt:'児童指導員・休日・重心・7人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634068', v:154 , c:'放デイ児童指導員等加配加算Ⅰ２・２９・' ,name:'児童指導員等加配加算（Ⅰ）', value:'児童指導員' , opt:'児童指導員・休日・重心・8人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634069', v:137 , c:'放デイ児童指導員等加配加算Ⅰ２・３０・' ,name:'児童指導員等加配加算（Ⅰ）', value:'児童指導員' , opt:'児童指導員・休日・重心・9人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634070', v:123 , c:'放デイ児童指導員等加配加算Ⅰ２・３１・' ,name:'児童指導員等加配加算（Ⅰ）', value:'児童指導員' , opt:'児童指導員・休日・重心・10人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634071', v:82 , c:'放デイ児童指導員等加配加算Ⅰ２・３２・' ,name:'児童指導員等加配加算（Ⅰ）', value:'児童指導員' , opt:'児童指導員・休日・重心・11以上・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634081', v:90 , c:'放デイ児童指導員等加配加算Ⅰ３・１・' ,name:'児童指導員等加配加算（Ⅰ）', value:'その他' , opt:'その他・平日・区分１・10人以下・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634082', v:60 , c:'放デイ児童指導員等加配加算Ⅰ３・２・' ,name:'児童指導員等加配加算（Ⅰ）', value:'その他' , opt:'その他・平日・区分１・11から20・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634083', v:36 , c:'放デイ児童指導員等加配加算Ⅰ３・３・' ,name:'児童指導員等加配加算（Ⅰ）', value:'その他' , opt:'その他・平日・区分１・21人以上・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634084', v:90 , c:'放デイ児童指導員等加配加算Ⅰ３・４・' ,name:'児童指導員等加配加算（Ⅰ）', value:'その他' , opt:'その他・平日・区分２・10人以下・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634085', v:60 , c:'放デイ児童指導員等加配加算Ⅰ３・５・' ,name:'児童指導員等加配加算（Ⅰ）', value:'その他' , opt:'その他・平日・区分２・11から20・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634086', v:36 , c:'放デイ児童指導員等加配加算Ⅰ３・６・' ,name:'児童指導員等加配加算（Ⅰ）', value:'その他' , opt:'その他・平日・区分２・21人以上・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634093', v:90 , c:'放デイ児童指導員等加配加算Ⅰ３・１３・' ,name:'児童指導員等加配加算（Ⅰ）', value:'その他' , opt:'その他・休日・10人以下・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634094', v:60 , c:'放デイ児童指導員等加配加算Ⅰ３・１４・' ,name:'児童指導員等加配加算（Ⅰ）', value:'その他' , opt:'その他・休日・11から20・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634095', v:36 , c:'放デイ児童指導員等加配加算Ⅰ３・１５・' ,name:'児童指導員等加配加算（Ⅰ）', value:'その他' , opt:'その他・休日・21人以上・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634099', v:180 , c:'放デイ児童指導員等加配加算Ⅰ３・１９・' ,name:'児童指導員等加配加算（Ⅰ）', value:'その他' , opt:'その他・平日・重心・5人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634100', v:150 , c:'放デイ児童指導員等加配加算Ⅰ３・２０・' ,name:'児童指導員等加配加算（Ⅰ）', value:'その他' , opt:'その他・平日・重心・6人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634101', v:129 , c:'放デイ児童指導員等加配加算Ⅰ３・２１・' ,name:'児童指導員等加配加算（Ⅰ）', value:'その他' , opt:'その他・平日・重心・7人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634102', v:113 , c:'放デイ児童指導員等加配加算Ⅰ３・２２・' ,name:'児童指導員等加配加算（Ⅰ）', value:'その他' , opt:'その他・平日・重心・8人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634103', v:100 , c:'放デイ児童指導員等加配加算Ⅰ３・２３・' ,name:'児童指導員等加配加算（Ⅰ）', value:'その他' , opt:'その他・平日・重心・9人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634104', v:90 , c:'放デイ児童指導員等加配加算Ⅰ３・２４・' ,name:'児童指導員等加配加算（Ⅰ）', value:'その他' , opt:'その他・平日・重心・10人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634105', v:60 , c:'放デイ児童指導員等加配加算Ⅰ３・２５・' ,name:'児童指導員等加配加算（Ⅰ）', value:'その他' , opt:'その他・平日・重心・11以上・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634106', v:180 , c:'放デイ児童指導員等加配加算Ⅰ３・２６・' ,name:'児童指導員等加配加算（Ⅰ）', value:'その他' , opt:'その他・休日・重心・5人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634107', v:150 , c:'放デイ児童指導員等加配加算Ⅰ３・２７・' ,name:'児童指導員等加配加算（Ⅰ）', value:'その他' , opt:'その他・休日・重心・6人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634108', v:129 , c:'放デイ児童指導員等加配加算Ⅰ３・２８・' ,name:'児童指導員等加配加算（Ⅰ）', value:'その他' , opt:'その他・休日・重心・7人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634109', v:113 , c:'放デイ児童指導員等加配加算Ⅰ３・２９・' ,name:'児童指導員等加配加算（Ⅰ）', value:'その他' , opt:'その他・休日・重心・8人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634110', v:100 , c:'放デイ児童指導員等加配加算Ⅰ３・３０・' ,name:'児童指導員等加配加算（Ⅰ）', value:'その他' , opt:'その他・休日・重心・9人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634111', v:90 , c:'放デイ児童指導員等加配加算Ⅰ３・３１・' ,name:'児童指導員等加配加算（Ⅰ）', value:'その他' , opt:'その他・休日・重心・10人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634112', v:60 , c:'放デイ児童指導員等加配加算Ⅰ３・３２・' ,name:'児童指導員等加配加算（Ⅰ）', value:'その他' , opt:'その他・休日・重心・11以上・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636810', v:187 , c:'専門的支援加算１・' ,name:'専門的支援加算', value:'1' , opt:'1・平日・区分１・10人以下・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636811', v:125 , c:'専門的支援加算２・' ,name:'専門的支援加算', value:'1' , opt:'1・平日・区分１・11から20・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636812', v:75 , c:'専門的支援加算３・' ,name:'専門的支援加算', value:'1' , opt:'1・平日・区分１・21人以上・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636813', v:187 , c:'専門的支援加算４・' ,name:'専門的支援加算', value:'1' , opt:'1・平日・区分２・10人以下・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636814', v:125 , c:'専門的支援加算５・' ,name:'専門的支援加算', value:'1' , opt:'1・平日・区分２・11から20・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636815', v:75 , c:'専門的支援加算６・' ,name:'専門的支援加算', value:'1' , opt:'1・平日・区分２・21人以上・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636816', v:187 , c:'専門的支援加算７・' ,name:'専門的支援加算', value:'1' , opt:'1・休日・10人以下・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636817', v:125 , c:'専門的支援加算８・' ,name:'専門的支援加算', value:'1' , opt:'1・休日・11から20・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636818', v:75 , c:'専門的支援加算９・' ,name:'専門的支援加算', value:'1' , opt:'1・休日・21人以上・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636819', v:374 , c:'専門的支援加算１０・' ,name:'専門的支援加算', value:'1' , opt:'1・平日・5人・重心・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636820', v:312 , c:'専門的支援加算１１・' ,name:'専門的支援加算', value:'1' , opt:'1・平日・6人・重心・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636821', v:267 , c:'専門的支援加算１２・' ,name:'専門的支援加算', value:'1' , opt:'1・平日・7人・重心・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636822', v:234 , c:'専門的支援加算１３・' ,name:'専門的支援加算', value:'1' , opt:'1・平日・8人・重心・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636823', v:208 , c:'専門的支援加算１４・' ,name:'専門的支援加算', value:'1' , opt:'1・平日・9人・重心・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636824', v:187 , c:'専門的支援加算１５・' ,name:'専門的支援加算', value:'1' , opt:'1・平日・10人・重心・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636825', v:125 , c:'専門的支援加算１６・' ,name:'専門的支援加算', value:'1' , opt:'1・平日・11以上・重心・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636826', v:374 , c:'専門的支援加算１７・' ,name:'専門的支援加算', value:'1' , opt:'1・休日・5人・重心・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636827', v:312 , c:'専門的支援加算１８・' ,name:'専門的支援加算', value:'1' , opt:'1・休日・6人・重心・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636828', v:267 , c:'専門的支援加算１９・' ,name:'専門的支援加算', value:'1' , opt:'1・休日・7人・重心・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636829', v:234 , c:'専門的支援加算２０・' ,name:'専門的支援加算', value:'1' , opt:'1・休日・8人・重心・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636830', v:208 , c:'専門的支援加算２１・' ,name:'専門的支援加算', value:'1' , opt:'1・休日・9人・重心・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636831', v:187 , c:'専門的支援加算２２・' ,name:'専門的支援加算', value:'1' , opt:'1・休日・10人・重心・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636832', v:125 , c:'専門的支援加算２３・' ,name:'専門的支援加算', value:'1' , opt:'1・休日・11以上・重心・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634218', v:400 , c:'放デイ看護職員加配加算１・１９・' ,name:'看護職員加配加算', value:'看護職員加配加算Ⅰ' , opt:'看護職員加配加算Ⅰ・平日・重心・5人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634219', v:333 , c:'放デイ看護職員加配加算１・２０・' ,name:'看護職員加配加算', value:'看護職員加配加算Ⅰ' , opt:'看護職員加配加算Ⅰ・平日・重心・6人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634220', v:286 , c:'放デイ看護職員加配加算１・２１・' ,name:'看護職員加配加算', value:'看護職員加配加算Ⅰ' , opt:'看護職員加配加算Ⅰ・平日・重心・7人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634221', v:250 , c:'放デイ看護職員加配加算１・２２・' ,name:'看護職員加配加算', value:'看護職員加配加算Ⅰ' , opt:'看護職員加配加算Ⅰ・平日・重心・8人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634222', v:222 , c:'放デイ看護職員加配加算１・２３・' ,name:'看護職員加配加算', value:'看護職員加配加算Ⅰ' , opt:'看護職員加配加算Ⅰ・平日・重心・9人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634223', v:200 , c:'放デイ看護職員加配加算１・２４・' ,name:'看護職員加配加算', value:'看護職員加配加算Ⅰ' , opt:'看護職員加配加算Ⅰ・平日・重心・10人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634224', v:133 , c:'放デイ看護職員加配加算１・２５・' ,name:'看護職員加配加算', value:'看護職員加配加算Ⅰ' , opt:'看護職員加配加算Ⅰ・平日・重心・11以上・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634225', v:400 , c:'放デイ看護職員加配加算１・２６・' ,name:'看護職員加配加算', value:'看護職員加配加算Ⅰ' , opt:'看護職員加配加算Ⅰ・休日・重心・5人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634226', v:333 , c:'放デイ看護職員加配加算１・２７・' ,name:'看護職員加配加算', value:'看護職員加配加算Ⅰ' , opt:'看護職員加配加算Ⅰ・休日・重心・6人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634227', v:286 , c:'放デイ看護職員加配加算１・２８・' ,name:'看護職員加配加算', value:'看護職員加配加算Ⅰ' , opt:'看護職員加配加算Ⅰ・休日・重心・7人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634228', v:250 , c:'放デイ看護職員加配加算１・２９・' ,name:'看護職員加配加算', value:'看護職員加配加算Ⅰ' , opt:'看護職員加配加算Ⅰ・休日・重心・8人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634229', v:222 , c:'放デイ看護職員加配加算１・３０・' ,name:'看護職員加配加算', value:'看護職員加配加算Ⅰ' , opt:'看護職員加配加算Ⅰ・休日・重心・9人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634230', v:200 , c:'放デイ看護職員加配加算１・３１・' ,name:'看護職員加配加算', value:'看護職員加配加算Ⅰ' , opt:'看護職員加配加算Ⅰ・休日・重心・10人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634231', v:133 , c:'放デイ看護職員加配加算１・３２・' ,name:'看護職員加配加算', value:'看護職員加配加算Ⅰ' , opt:'看護職員加配加算Ⅰ・休日・重心・11以上・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634258', v:800 , c:'放デイ看護職員加配加算２・１９・' ,name:'看護職員加配加算', value:'看護職員加配加算Ⅱ' , opt:'看護職員加配加算Ⅱ・平日・重心・5人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634259', v:666 , c:'放デイ看護職員加配加算２・２０・' ,name:'看護職員加配加算', value:'看護職員加配加算Ⅱ' , opt:'看護職員加配加算Ⅱ・平日・重心・6人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634260', v:572 , c:'放デイ看護職員加配加算２・２１・' ,name:'看護職員加配加算', value:'看護職員加配加算Ⅱ' , opt:'看護職員加配加算Ⅱ・平日・重心・7人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634261', v:500 , c:'放デイ看護職員加配加算２・２２・' ,name:'看護職員加配加算', value:'看護職員加配加算Ⅱ' , opt:'看護職員加配加算Ⅱ・平日・重心・8人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634262', v:444 , c:'放デイ看護職員加配加算２・２３・' ,name:'看護職員加配加算', value:'看護職員加配加算Ⅱ' , opt:'看護職員加配加算Ⅱ・平日・重心・9人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634263', v:400 , c:'放デイ看護職員加配加算２・２４・' ,name:'看護職員加配加算', value:'看護職員加配加算Ⅱ' , opt:'看護職員加配加算Ⅱ・平日・重心・10人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634264', v:266 , c:'放デイ看護職員加配加算２・２５・' ,name:'看護職員加配加算', value:'看護職員加配加算Ⅱ' , opt:'看護職員加配加算Ⅱ・平日・重心・11以上・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634265', v:800 , c:'放デイ看護職員加配加算２・２６・' ,name:'看護職員加配加算', value:'看護職員加配加算Ⅱ' , opt:'看護職員加配加算Ⅱ・休日・重心・5人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634266', v:666 , c:'放デイ看護職員加配加算２・２７・' ,name:'看護職員加配加算', value:'看護職員加配加算Ⅱ' , opt:'看護職員加配加算Ⅱ・休日・重心・6人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634267', v:572 , c:'放デイ看護職員加配加算２・２８・' ,name:'看護職員加配加算', value:'看護職員加配加算Ⅱ' , opt:'看護職員加配加算Ⅱ・休日・重心・7人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634268', v:500 , c:'放デイ看護職員加配加算２・２９・' ,name:'看護職員加配加算', value:'看護職員加配加算Ⅱ' , opt:'看護職員加配加算Ⅱ・休日・重心・8人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634269', v:444 , c:'放デイ看護職員加配加算２・３０・' ,name:'看護職員加配加算', value:'看護職員加配加算Ⅱ' , opt:'看護職員加配加算Ⅱ・休日・重心・9人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634270', v:400 , c:'放デイ看護職員加配加算２・３１・' ,name:'看護職員加配加算', value:'看護職員加配加算Ⅱ' , opt:'看護職員加配加算Ⅱ・休日・重心・10人・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634271', v:266 , c:'放デイ看護職員加配加算２・３２・' ,name:'看護職員加配加算', value:'看護職員加配加算Ⅱ' , opt:'看護職員加配加算Ⅱ・休日・重心・11以上・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634300', v:181 , c:'放デイ共生型サービス体制強化加算１・' ,name:'共生型サービス体制強化加算', value:'児発管かつ保育士又は児童指導員' , opt:'児発管かつ保育士又は児童指導員・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634301', v:103 , c:'放デイ共生型サービス体制強化加算２・' ,name:'共生型サービス体制強化加算', value:'児発管の場合' , opt:'児発管の場合・', limit:'',syoguu:'', tokubetsu:''},
  {s:'634302', v:78 , c:'放デイ共生型サービス体制強化加算３・' ,name:'共生型サービス体制強化加算', value:'保育士又は児童指導員の場合' , opt:'保育士又は児童指導員の場合・', limit:'',syoguu:'', tokubetsu:''},
  {s:'635350', v:187 , c:'放デイ家庭連携加算１・' ,name:'家庭連携加算', value:0 , opt:'1時間未満・', limit:'4',syoguu:'', tokubetsu:''},
  {s:'635351', v:280 , c:'放デイ家庭連携加算２・' ,name:'家庭連携加算', value:60 , opt:'1時間以上・', limit:'4',syoguu:'', tokubetsu:''},
  {s:'635493', v:100 , c:'放デイ事業所内相談支援加算Ⅰ・' ,name:'事業所内相談支援加算', value:'事業所内相談支援加算Ⅰ' , opt:'事業所内相談支援加算Ⅰ・', limit:'1',syoguu:'', tokubetsu:''},
  {s:'635494', v:80 , c:'放デイ事業所内相談支援加算Ⅱ・' ,name:'事業所内相談支援加算', value:'事業所内相談支援加算Ⅱ' , opt:'事業所内相談支援加算Ⅱ・', limit:'1',syoguu:'', tokubetsu:''},
  {s:'635370', v:150 , c:'放デイ上限額管理加算・' ,name:'利用者負担上限額管理加算', value:'1' , opt:'1・', limit:'once',syoguu:'', tokubetsu:''},
  {s:'635492', v:15 , c:'放デイ福祉専門職員配置等加算Ⅰ・' ,name:'福祉専門職員配置等加算', value:'福祉専門職員配置等加算Ⅰ' , opt:'福祉専門職員配置等加算Ⅰ・', limit:'',syoguu:'', tokubetsu:''},
  {s:'635490', v:10 , c:'放デイ福祉専門職員配置等加算Ⅱ・' ,name:'福祉専門職員配置等加算', value:'福祉専門職員配置等加算Ⅱ' , opt:'福祉専門職員配置等加算Ⅱ・', limit:'',syoguu:'', tokubetsu:''},
  {s:'635491', v:6 , c:'放デイ福祉専門職員配置等加算Ⅲ・' ,name:'福祉専門職員配置等加算', value:'福祉専門職員配置等加算Ⅲ' , opt:'福祉専門職員配置等加算Ⅲ・', limit:'',syoguu:'', tokubetsu:''},
  {s:'635495', v:94 , c:'放デイ欠席時対応加算Ⅰ・' ,name:'欠席時対応加算', value:'欠席時対応加算１' , opt:'欠席時対応加算１・', limit:'4',syoguu:'', tokubetsu:''},
  {s:'635496', v:94 , c:'放デイ欠席時対応加算Ⅰ（重心）・' ,name:'欠席時対応加算', value:'欠席時対応加算１' , opt:'欠席時対応加算１・重心・', limit:'8',syoguu:'', tokubetsu:''},
  {s:'635500', v:94 , c:'放デイ欠席時対応加算Ⅱ・' ,name:'欠席時対応加算', value:'欠席時対応加算２' , opt:'欠席時対応加算２・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636220', v:54 , c:'放デイ特別支援加算・' ,name:'特別支援加算', value:'1' , opt:'1・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636225', v:155 , c:'放デイ強度行動障害児支援加算・' ,name:'強度行動障害児支援加算', value:'1' , opt:'1・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636762', v:100 , c:'放デイ個別サポート加算Ⅰ・' ,name:'個別サポート加算', value:'個別サポート加算Ⅰ' , opt:'個別サポート加算Ⅰ・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636763', v:125 , c:'放デイ個別サポート加算Ⅱ・' ,name:'個別サポート加算', value:'個別サポート加算Ⅱ' , opt:'個別サポート加算Ⅱ・', limit:'',syoguu:'', tokubetsu:''},
  // 個別サポート加算の１と２は同時算定可能なのでComponentを書き換えた。旧コンポーネントで作成したスケジュール等の
  // オブジェクトがあるので互換性のために二通りのデータを用意する。
  {s:'636762', v:100 , c:'放デイ個別サポート加算Ⅰ・' ,name:'個別サポート加算１', value:'1' , opt:'1・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636763', v:125 , c:'放デイ個別サポート加算Ⅱ・' ,name:'個別サポート加算２', value:'1' , opt:'1・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636230', v:32 , c:'放デイ医療連携体制加算Ⅰ・' ,name:'医療連携体制加算', value:'医療連携体制加算Ⅰ' , opt:'医療連携体制加算Ⅰ・', limit:'',syoguu:'', tokubetsu:'', iryouRenkeiNum: 1},
  {s:'636231', v:63 , c:'放デイ医療連携体制加算Ⅱ・' ,name:'医療連携体制加算', value:'医療連携体制加算Ⅱ' , opt:'医療連携体制加算Ⅱ・', limit:'',syoguu:'', tokubetsu:'', iryouRenkeiNum: 2},
  {s:'636232', v:125 , c:'放デイ医療連携体制加算Ⅲ・' ,name:'医療連携体制加算', value:'医療連携体制加算Ⅲ' , opt:'医療連携体制加算Ⅲ・', limit:'',syoguu:'', tokubetsu:'', iryouRenkeiNum: 3},
  {s:'636280', v:800 , c:'放デイ医療連携体制加算Ⅳ１・' ,name:'医療連携体制加算', value:'医療連携体制加算Ⅳ１' , opt:'医療連携体制加算Ⅳ１・', limit:'',syoguu:'', tokubetsu:'', iryouRenkeiNum: 4},
  {s:'636281', v:500 , c:'放デイ医療連携体制加算Ⅳ２・' ,name:'医療連携体制加算', value:'医療連携体制加算Ⅳ２' , opt:'医療連携体制加算Ⅳ２・', limit:'',syoguu:'', tokubetsu:'', iryouRenkeiNum: 4},
  {s:'636282', v:400 , c:'放デイ医療連携体制加算Ⅳ３・' ,name:'医療連携体制加算', value:'医療連携体制加算Ⅳ３' , opt:'医療連携体制加算Ⅳ３・', limit:'',syoguu:'', tokubetsu:'', iryouRenkeiNum: 4},
  {s:'636283', v:1600 , c:'放デイ医療連携体制加算Ⅴ１・' ,name:'医療連携体制加算', value:'医療連携体制加算Ⅴ１' , opt:'医療連携体制加算Ⅴ１・', limit:'',syoguu:'', tokubetsu:'', iryouRenkeiNum: 5},
  {s:'636284', v:960 , c:'放デイ医療連携体制加算Ⅴ２・' ,name:'医療連携体制加算', value:'医療連携体制加算Ⅴ２' , opt:'医療連携体制加算Ⅴ２・', limit:'',syoguu:'', tokubetsu:'', iryouRenkeiNum: 5},
  {s:'636285', v:800 , c:'放デイ医療連携体制加算Ⅴ３・' ,name:'医療連携体制加算', value:'医療連携体制加算Ⅴ３' , opt:'医療連携体制加算Ⅴ３・', limit:'',syoguu:'', tokubetsu:'', iryouRenkeiNum: 5},
  
  
  {s:'636240', v:54 , c:'放デイ送迎加算Ⅰ・' ,name:'送迎加算', value:'送迎加算Ⅰ' , opt:'送迎加算Ⅰ・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636243', v:38 , c:'放デイ送迎加算Ⅰ（同一敷地）・' ,name:'送迎加算', value:'送迎加算Ⅰ同一敷地内' , opt:'送迎加算Ⅰ同一敷地内・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636241', v:37 , c:'放デイ送迎加算Ⅱ・' ,name:'送迎加算', value:'送迎加算Ⅱ' , opt:'送迎加算Ⅱ・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636244', v:26 , c:'放デイ送迎加算Ⅱ（同一敷地）・' ,name:'送迎加算', value:'送迎加算Ⅱ同一敷地内' , opt:'送迎加算Ⅱ同一敷地内・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636242', v:37 , c:'放デイ送迎加算Ⅰ（一定条件）・' ,name:'送迎加算', value:'送迎加算Ⅰ一定条件' , opt:'送迎加算Ⅰ一定条件・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636250', v:61 , c:'放デイ延長支援加算１・' ,name:'延長支援加算', value:'1時間未満' , opt:'1時間未満・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636251', v:92 , c:'放デイ延長支援加算２・' ,name:'延長支援加算', value:'1時間以上2時間未満' , opt:'1時間以上2時間未満・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636252', v:123 , c:'放デイ延長支援加算３・' ,name:'延長支援加算', value:'2時間以上' , opt:'2時間以上・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636253', v:128 , c:'放デイ延長支援加算４・' ,name:'延長支援加算', value:'1時間未満' , opt:'1時間未満・重心・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636254', v:192 , c:'放デイ延長支援加算５・' ,name:'延長支援加算', value:'1時間以上2時間未満' , opt:'1時間以上2時間未満・重心・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636255', v:256 , c:'放デイ延長支援加算６・' ,name:'延長支援加算', value:'2時間以上' , opt:'2時間以上・重心・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636760', v:200 , c:'放デイ関係機関連携加算Ⅰ・' ,name:'関係機関連携加算', value:'関係機関連携加算Ⅰ' , opt:'関係機関連携加算Ⅰ・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636761', v:200 , c:'放デイ関係機関連携加算Ⅱ・' ,name:'関係機関連携加算', value:'関係機関連携加算Ⅱ' , opt:'関係機関連携加算Ⅱ・', limit:'',syoguu:'', tokubetsu:''},
  {s:'636765', v:500 , c:'放デイ保育教育等移行支援加算・' ,name:'保育・教育等移行支援加算', value:'1' , opt:'1・', limit:'1',syoguu:'', tokubetsu:''},
  {s:'636621', v:8.4 , c:'放デイ処遇改善加算Ⅰ・' ,name:'福祉・介護職員処遇改善加算', value:'福祉・介護職員処遇改善加算Ⅰ' , opt:'福祉・介護職員処遇改善加算Ⅰ・', limit:'',syoguu:'1', tokubetsu:''},
  {s:'636616', v:6.1 , c:'放デイ処遇改善加算Ⅱ・' ,name:'福祉・介護職員処遇改善加算', value:'福祉・介護職員処遇改善加算Ⅱ' , opt:'福祉・介護職員処遇改善加算Ⅱ・', limit:'',syoguu:'1', tokubetsu:''},
  {s:'636596', v:3.4 , c:'放デイ処遇改善加算Ⅲ・' ,name:'福祉・介護職員処遇改善加算', value:'福祉・介護職員処遇改善加算Ⅲ' , opt:'福祉・介護職員処遇改善加算Ⅲ・', limit:'',syoguu:'1', tokubetsu:''},
  {s:'636601', v:3.4*0.9 , c:'放デイ処遇改善加算Ⅳ・' ,name:'福祉・介護職員処遇改善加算', value:'福祉・介護職員処遇改善加算Ⅳ' , opt:'福祉・介護職員処遇改善加算Ⅳ・', limit:'',syoguu:'1', tokubetsu:''},
  {s:'636606', v:3.4*0.8 , c:'放デイ処遇改善加算Ⅴ・' ,name:'福祉・介護職員処遇改善加算', value:'福祉・介護職員処遇改善加算Ⅴ' , opt:'福祉・介護職員処遇改善加算Ⅴ・', limit:'',syoguu:'1', tokubetsu:''},
  {s:'636611', v:1.1 , c:'放デイ処遇改善特別加算・' ,name:'福祉・介護職員処遇改善特別加算', value:'1' , opt:'1・', limit:'',syoguu:'1', tokubetsu:''},
  {s:'636772', v:1.3 , c:'放デイ特定処遇改善加算Ⅰ・' ,name:'特定処遇改善加算', value:'特定処遇改善加算Ⅰ' , opt:'特定処遇改善加算Ⅰ・', limit:'',syoguu:'1', tokubetsu:''},
  {s:'636773', v:1.0 , c:'放デイ特定処遇改善加算Ⅱ・' ,name:'特定処遇改善加算', value:'特定処遇改善加算Ⅱ' , opt:'特定処遇改善加算Ⅱ・', limit:'',syoguu:'1', tokubetsu:''},
];

export const serviceNameBaseHD = [
  {name:'児発２８',fcl:'センター',type:'障害児',syuugaku:'',iCare:32,teiin:30,},
  {name:'児発２９',fcl:'センター',type:'障害児',syuugaku:'',iCare:32,teiin:40,},
  {name:'児発３０',fcl:'センター',type:'障害児',syuugaku:'',iCare:32,teiin:50,},
  {name:'児発３１',fcl:'センター',type:'障害児',syuugaku:'',iCare:32,teiin:60,},
  {name:'児発３２',fcl:'センター',type:'障害児',syuugaku:'',iCare:32,teiin:70,},
  {name:'児発３３',fcl:'センター',type:'障害児',syuugaku:'',iCare:32,teiin:80,},
  {name:'児発３４',fcl:'センター',type:'障害児',syuugaku:'',iCare:32,teiin:200,},
  {name:'児発３５',fcl:'センター',type:'障害児',syuugaku:'',iCare:16,teiin:30,},
  {name:'児発３６',fcl:'センター',type:'障害児',syuugaku:'',iCare:16,teiin:40,},
  {name:'児発３７',fcl:'センター',type:'障害児',syuugaku:'',iCare:16,teiin:50,},
  {name:'児発３８',fcl:'センター',type:'障害児',syuugaku:'',iCare:16,teiin:60,},
  {name:'児発３９',fcl:'センター',type:'障害児',syuugaku:'',iCare:16,teiin:70,},
  {name:'児発４０',fcl:'センター',type:'障害児',syuugaku:'',iCare:16,teiin:80,},
  {name:'児発４１',fcl:'センター',type:'障害児',syuugaku:'',iCare:16,teiin:200,},
  {name:'児発４２',fcl:'センター',type:'障害児',syuugaku:'',iCare:3,teiin:30,},
  {name:'児発４３',fcl:'センター',type:'障害児',syuugaku:'',iCare:3,teiin:40,},
  {name:'児発４４',fcl:'センター',type:'障害児',syuugaku:'',iCare:3,teiin:50,},
  {name:'児発４５',fcl:'センター',type:'障害児',syuugaku:'',iCare:3,teiin:60,},
  {name:'児発４６',fcl:'センター',type:'障害児',syuugaku:'',iCare:3,teiin:70,},
  {name:'児発４７',fcl:'センター',type:'障害児',syuugaku:'',iCare:3,teiin:80,},
  {name:'児発４８',fcl:'センター',type:'障害児',syuugaku:'',iCare:3,teiin:200,},
  {name:'児発１',fcl:'センター',type:'障害児',syuugaku:'',iCare:0,teiin:30,},
  {name:'児発２',fcl:'センター',type:'障害児',syuugaku:'',iCare:0,teiin:40,},
  {name:'児発３',fcl:'センター',type:'障害児',syuugaku:'',iCare:0,teiin:50,},
  {name:'児発４',fcl:'センター',type:'障害児',syuugaku:'',iCare:0,teiin:60,},
  {name:'児発５',fcl:'センター',type:'障害児',syuugaku:'',iCare:0,teiin:70,},
  {name:'児発６',fcl:'センター',type:'障害児',syuugaku:'',iCare:0,teiin:80,},
  {name:'児発７',fcl:'センター',type:'障害児',syuugaku:'',iCare:0,teiin:200,},
  {name:'児発４９',fcl:'センター',type:'難聴児',syuugaku:'',iCare:32,teiin:20,},
  {name:'児発５０',fcl:'センター',type:'難聴児',syuugaku:'',iCare:32,teiin:30,},
  {name:'児発５１',fcl:'センター',type:'難聴児',syuugaku:'',iCare:32,teiin:40,},
  {name:'児発５２',fcl:'センター',type:'難聴児',syuugaku:'',iCare:32,teiin:200,},
  {name:'児発５３',fcl:'センター',type:'難聴児',syuugaku:'',iCare:16,teiin:20,},
  {name:'児発５４',fcl:'センター',type:'難聴児',syuugaku:'',iCare:16,teiin:30,},
  {name:'児発５５',fcl:'センター',type:'難聴児',syuugaku:'',iCare:16,teiin:40,},
  {name:'児発５６',fcl:'センター',type:'難聴児',syuugaku:'',iCare:16,teiin:200,},
  {name:'児発５７',fcl:'センター',type:'難聴児',syuugaku:'',iCare:3,teiin:20,},
  {name:'児発５８',fcl:'センター',type:'難聴児',syuugaku:'',iCare:3,teiin:30,},
  {name:'児発５９',fcl:'センター',type:'難聴児',syuugaku:'',iCare:3,teiin:40,},
  {name:'児発６０',fcl:'センター',type:'難聴児',syuugaku:'',iCare:3,teiin:200,},
  {name:'児発８',fcl:'センター',type:'難聴児',syuugaku:'',iCare:0,teiin:20,},
  {name:'児発９',fcl:'センター',type:'難聴児',syuugaku:'',iCare:0,teiin:30,},
  {name:'児発１０',fcl:'センター',type:'難聴児',syuugaku:'',iCare:0,teiin:40,},
  {name:'児発１１',fcl:'センター',type:'難聴児',syuugaku:'',iCare:0,teiin:200,},
  {name:'児発１２',fcl:'センター',type:'重症心身障害児',syuugaku:'',iCare:0,teiin:15,},
  {name:'児発１３',fcl:'センター',type:'重症心身障害児',syuugaku:'',iCare:0,teiin:20,},
  {name:'児発１４',fcl:'センター',type:'重症心身障害児',syuugaku:'',iCare:0,teiin:200,},
  {name:'児発６１',fcl:'事業所',type:'障害児',syuugaku:'主に未就学児',iCare:32,teiin:10,},
  {name:'児発６２',fcl:'事業所',type:'障害児',syuugaku:'主に未就学児',iCare:32,teiin:20,},
  {name:'児発６３',fcl:'事業所',type:'障害児',syuugaku:'主に未就学児',iCare:32,teiin:200,},
  {name:'児発６４',fcl:'事業所',type:'障害児',syuugaku:'主に未就学児',iCare:16,teiin:10,},
  {name:'児発６５',fcl:'事業所',type:'障害児',syuugaku:'主に未就学児',iCare:16,teiin:20,},
  {name:'児発６６',fcl:'事業所',type:'障害児',syuugaku:'主に未就学児',iCare:16,teiin:200,},
  {name:'児発６７',fcl:'事業所',type:'障害児',syuugaku:'主に未就学児',iCare:3,teiin:10,},
  {name:'児発６８',fcl:'事業所',type:'障害児',syuugaku:'主に未就学児',iCare:3,teiin:20,},
  {name:'児発６９',fcl:'事業所',type:'障害児',syuugaku:'主に未就学児',iCare:3,teiin:200,},
  {name:'児発１５',fcl:'事業所',type:'障害児',syuugaku:'主に未就学児',iCare:0,teiin:10,},
  {name:'児発１６',fcl:'事業所',type:'障害児',syuugaku:'主に未就学児',iCare:0,teiin:20,},
  {name:'児発１７',fcl:'事業所',type:'障害児',syuugaku:'主に未就学児',iCare:0,teiin:200,},
  {name:'児発７０',fcl:'事業所',type:'障害児',syuugaku:'主に未就学児以外',iCare:32,teiin:10,},
  {name:'児発７１',fcl:'事業所',type:'障害児',syuugaku:'主に未就学児以外',iCare:32,teiin:20,},
  {name:'児発７２',fcl:'事業所',type:'障害児',syuugaku:'主に未就学児以外',iCare:32,teiin:200,},
  {name:'児発７３',fcl:'事業所',type:'障害児',syuugaku:'主に未就学児以外',iCare:16,teiin:10,},
  {name:'児発７４',fcl:'事業所',type:'障害児',syuugaku:'主に未就学児以外',iCare:16,teiin:20,},
  {name:'児発７５',fcl:'事業所',type:'障害児',syuugaku:'主に未就学児以外',iCare:16,teiin:200,},
  {name:'児発７６',fcl:'事業所',type:'障害児',syuugaku:'主に未就学児以外',iCare:3,teiin:10,},
  {name:'児発７７',fcl:'事業所',type:'障害児',syuugaku:'主に未就学児以外',iCare:3,teiin:20,},
  {name:'児発７８',fcl:'事業所',type:'障害児',syuugaku:'主に未就学児以外',iCare:3,teiin:200,},
  {name:'児発１８',fcl:'事業所',type:'障害児',syuugaku:'主に未就学児以外',iCare:0,teiin:10,},
  {name:'児発１９',fcl:'事業所',type:'障害児',syuugaku:'主に未就学児以外',iCare:0,teiin:20,},
  {name:'児発２０',fcl:'事業所',type:'障害児',syuugaku:'主に未就学児以外',iCare:0,teiin:200,},
  {name:'児発２１',fcl:'事業所',type:'重症心身障害児',syuugaku:'主に未就学児以外',iCare:0,teiin:5,},
  {name:'児発２２',fcl:'事業所',type:'重症心身障害児',syuugaku:'主に未就学児以外',iCare:0,teiin:6,},
  {name:'児発２３',fcl:'事業所',type:'重症心身障害児',syuugaku:'主に未就学児以外',iCare:0,teiin:7,},
  {name:'児発２４',fcl:'事業所',type:'重症心身障害児',syuugaku:'主に未就学児以外',iCare:0,teiin:8,},
  {name:'児発２５',fcl:'事業所',type:'重症心身障害児',syuugaku:'主に未就学児以外',iCare:0,teiin:9,},
  {name:'児発２６',fcl:'事業所',type:'重症心身障害児',syuugaku:'主に未就学児以外',iCare:0,teiin:10,},
  {name:'児発２７',fcl:'事業所',type:'重症心身障害児',syuugaku:'主に未就学児以外',iCare:0,teiin:200,},
];

export const jihatsuService = [
  {s:'61D001',v:'3086',c:'児発２８・',baseItem: true,},
  {s:'61D002',v:'2623',c:'児発２８・評価減・',baseItem: true,},
  {s:'61D003',v:'2160',c:'児発２８・開所減１・',baseItem: true,},
  {s:'61D004',v:'1836',c:'児発２８・開所減１・評価減・',baseItem: true,},
  {s:'61D005',v:'2623',c:'児発２８・開所減２・',baseItem: true,},
  {s:'61D006',v:'2230',c:'児発２８・開所減２・評価減・',baseItem: true,},
  {s:'61D007',v:'2160',c:'児発２８・未計画・',baseItem: true,},
  {s:'61D008',v:'1836',c:'児発２８・未計画・評価減・',baseItem: true,},
  {s:'61D009',v:'1512',c:'児発２８・未計画・開所減１・',baseItem: true,},
  {s:'61D010',v:'1285',c:'児発２８・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D011',v:'1836',c:'児発２８・未計画・開所減２・',baseItem: true,},
  {s:'61D012',v:'1561',c:'児発２８・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D013',v:'1543',c:'児発２８・未計画２・',baseItem: true,},
  {s:'61D014',v:'1312',c:'児発２８・未計画２・評価減・',baseItem: true,},
  {s:'61D015',v:'1080',c:'児発２８・未計画２・開所減１・',baseItem: true,},
  {s:'61D016',v:'918',c:'児発２８・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D017',v:'1312',c:'児発２８・未計画２・開所減２・',baseItem: true,},
  {s:'61D018',v:'1115',c:'児発２８・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D019',v:'2978',c:'児発２８・地公体・',baseItem: true,},
  {s:'61D020',v:'2531',c:'児発２８・地公体・評価減・',baseItem: true,},
  {s:'61D021',v:'2085',c:'児発２８・地公体・開所減１・',baseItem: true,},
  {s:'61D022',v:'1772',c:'児発２８・地公体・開所減１・評価減・',baseItem: true,},
  {s:'61D023',v:'2531',c:'児発２８・地公体・開所減２・',baseItem: true,},
  {s:'61D024',v:'2151',c:'児発２８・地公体・開所減２・評価減・',baseItem: true,},
  {s:'61D025',v:'2085',c:'児発２８・地公体・未計画・',baseItem: true,},
  {s:'61D026',v:'1772',c:'児発２８・地公体・未計画・評価減・',baseItem: true,},
  {s:'61D027',v:'1460',c:'児発２８・地公体・未計画・開所減１・',baseItem: true,},
  {s:'61D028',v:'1241',c:'児発２８・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D029',v:'1772',c:'児発２８・地公体・未計画・開所減２・',baseItem: true,},
  {s:'61D030',v:'1506',c:'児発２８・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D031',v:'1489',c:'児発２８・地公体・未計画２・',baseItem: true,},
  {s:'61D032',v:'1266',c:'児発２８・地公体・未計画２・評価減・',baseItem: true,},
  {s:'61D033',v:'1042',c:'児発２８・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'61D034',v:'886',c:'児発２８・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D035',v:'1266',c:'児発２８・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'61D036',v:'1076',c:'児発２８・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D037',v:'3005',c:'児発２９・',baseItem: true,},
  {s:'61D038',v:'2554',c:'児発２９・評価減・',baseItem: true,},
  {s:'61D039',v:'2104',c:'児発２９・開所減１・',baseItem: true,},
  {s:'61D040',v:'1788',c:'児発２９・開所減１・評価減・',baseItem: true,},
  {s:'61D041',v:'2554',c:'児発２９・開所減２・',baseItem: true,},
  {s:'61D042',v:'2171',c:'児発２９・開所減２・評価減・',baseItem: true,},
  {s:'61D043',v:'2104',c:'児発２９・未計画・',baseItem: true,},
  {s:'61D044',v:'1788',c:'児発２９・未計画・評価減・',baseItem: true,},
  {s:'61D045',v:'1473',c:'児発２９・未計画・開所減１・',baseItem: true,},
  {s:'61D046',v:'1252',c:'児発２９・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D047',v:'1788',c:'児発２９・未計画・開所減２・',baseItem: true,},
  {s:'61D048',v:'1520',c:'児発２９・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D049',v:'1503',c:'児発２９・未計画２・',baseItem: true,},
  {s:'61D050',v:'1278',c:'児発２９・未計画２・評価減・',baseItem: true,},
  {s:'61D051',v:'1052',c:'児発２９・未計画２・開所減１・',baseItem: true,},
  {s:'61D052',v:'894',c:'児発２９・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D053',v:'1278',c:'児発２９・未計画２・開所減２・',baseItem: true,},
  {s:'61D054',v:'1086',c:'児発２９・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D055',v:'2900',c:'児発２９・地公体・',baseItem: true,},
  {s:'61D056',v:'2465',c:'児発２９・地公体・評価減・',baseItem: true,},
  {s:'61D057',v:'2030',c:'児発２９・地公体・開所減１・',baseItem: true,},
  {s:'61D058',v:'1726',c:'児発２９・地公体・開所減１・評価減・',baseItem: true,},
  {s:'61D059',v:'2465',c:'児発２９・地公体・開所減２・',baseItem: true,},
  {s:'61D060',v:'2095',c:'児発２９・地公体・開所減２・評価減・',baseItem: true,},
  {s:'61D061',v:'2030',c:'児発２９・地公体・未計画・',baseItem: true,},
  {s:'61D062',v:'1726',c:'児発２９・地公体・未計画・評価減・',baseItem: true,},
  {s:'61D063',v:'1421',c:'児発２９・地公体・未計画・開所減１・',baseItem: true,},
  {s:'61D064',v:'1208',c:'児発２９・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D065',v:'1726',c:'児発２９・地公体・未計画・開所減２・',baseItem: true,},
  {s:'61D066',v:'1467',c:'児発２９・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D067',v:'1450',c:'児発２９・地公体・未計画２・',baseItem: true,},
  {s:'61D068',v:'1233',c:'児発２９・地公体・未計画２・評価減・',baseItem: true,},
  {s:'61D069',v:'1015',c:'児発２９・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'61D070',v:'863',c:'児発２９・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D071',v:'1233',c:'児発２９・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'61D072',v:'1048',c:'児発２９・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D073',v:'2930',c:'児発３０・',baseItem: true,},
  {s:'61D074',v:'2491',c:'児発３０・評価減・',baseItem: true,},
  {s:'61D075',v:'2051',c:'児発３０・開所減１・',baseItem: true,},
  {s:'61D076',v:'1743',c:'児発３０・開所減１・評価減・',baseItem: true,},
  {s:'61D077',v:'2491',c:'児発３０・開所減２・',baseItem: true,},
  {s:'61D078',v:'2117',c:'児発３０・開所減２・評価減・',baseItem: true,},
  {s:'61D079',v:'2051',c:'児発３０・未計画・',baseItem: true,},
  {s:'61D080',v:'1743',c:'児発３０・未計画・評価減・',baseItem: true,},
  {s:'61D081',v:'1436',c:'児発３０・未計画・開所減１・',baseItem: true,},
  {s:'61D082',v:'1221',c:'児発３０・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D083',v:'1743',c:'児発３０・未計画・開所減２・',baseItem: true,},
  {s:'61D084',v:'1482',c:'児発３０・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D085',v:'1465',c:'児発３０・未計画２・',baseItem: true,},
  {s:'61D086',v:'1245',c:'児発３０・未計画２・評価減・',baseItem: true,},
  {s:'61D087',v:'1026',c:'児発３０・未計画２・開所減１・',baseItem: true,},
  {s:'61D088',v:'872',c:'児発３０・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D089',v:'1245',c:'児発３０・未計画２・開所減２・',baseItem: true,},
  {s:'61D090',v:'1058',c:'児発３０・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D091',v:'2827',c:'児発３０・地公体・',baseItem: true,},
  {s:'61D092',v:'2403',c:'児発３０・地公体・評価減・',baseItem: true,},
  {s:'61D093',v:'1979',c:'児発３０・地公体・開所減１・',baseItem: true,},
  {s:'61D094',v:'1682',c:'児発３０・地公体・開所減１・評価減・',baseItem: true,},
  {s:'61D095',v:'2403',c:'児発３０・地公体・開所減２・',baseItem: true,},
  {s:'61D096',v:'2043',c:'児発３０・地公体・開所減２・評価減・',baseItem: true,},
  {s:'61D097',v:'1979',c:'児発３０・地公体・未計画・',baseItem: true,},
  {s:'61D098',v:'1682',c:'児発３０・地公体・未計画・評価減・',baseItem: true,},
  {s:'61D099',v:'1385',c:'児発３０・地公体・未計画・開所減１・',baseItem: true,},
  {s:'61D100',v:'1177',c:'児発３０・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D101',v:'1682',c:'児発３０・地公体・未計画・開所減２・',baseItem: true,},
  {s:'61D102',v:'1430',c:'児発３０・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D103',v:'1414',c:'児発３０・地公体・未計画２・',baseItem: true,},
  {s:'61D104',v:'1202',c:'児発３０・地公体・未計画２・評価減・',baseItem: true,},
  {s:'61D105',v:'990',c:'児発３０・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'61D106',v:'842',c:'児発３０・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D107',v:'1202',c:'児発３０・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'61D108',v:'1022',c:'児発３０・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D109',v:'2859',c:'児発３１・',baseItem: true,},
  {s:'61D110',v:'2430',c:'児発３１・評価減・',baseItem: true,},
  {s:'61D111',v:'2001',c:'児発３１・開所減１・',baseItem: true,},
  {s:'61D112',v:'1701',c:'児発３１・開所減１・評価減・',baseItem: true,},
  {s:'61D113',v:'2430',c:'児発３１・開所減２・',baseItem: true,},
  {s:'61D114',v:'2066',c:'児発３１・開所減２・評価減・',baseItem: true,},
  {s:'61D115',v:'2001',c:'児発３１・未計画・',baseItem: true,},
  {s:'61D116',v:'1701',c:'児発３１・未計画・評価減・',baseItem: true,},
  {s:'61D117',v:'1401',c:'児発３１・未計画・開所減１・',baseItem: true,},
  {s:'61D118',v:'1191',c:'児発３１・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D119',v:'1701',c:'児発３１・未計画・開所減２・',baseItem: true,},
  {s:'61D120',v:'1446',c:'児発３１・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D121',v:'1430',c:'児発３１・未計画２・',baseItem: true,},
  {s:'61D122',v:'1216',c:'児発３１・未計画２・評価減・',baseItem: true,},
  {s:'61D123',v:'1001',c:'児発３１・未計画２・開所減１・',baseItem: true,},
  {s:'61D124',v:'851',c:'児発３１・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D125',v:'1216',c:'児発３１・未計画２・開所減２・',baseItem: true,},
  {s:'61D126',v:'1034',c:'児発３１・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D127',v:'2759',c:'児発３１・地公体・',baseItem: true,},
  {s:'61D128',v:'2345',c:'児発３１・地公体・評価減・',baseItem: true,},
  {s:'61D129',v:'1931',c:'児発３１・地公体・開所減１・',baseItem: true,},
  {s:'61D130',v:'1641',c:'児発３１・地公体・開所減１・評価減・',baseItem: true,},
  {s:'61D131',v:'2345',c:'児発３１・地公体・開所減２・',baseItem: true,},
  {s:'61D132',v:'1993',c:'児発３１・地公体・開所減２・評価減・',baseItem: true,},
  {s:'61D133',v:'1931',c:'児発３１・地公体・未計画・',baseItem: true,},
  {s:'61D134',v:'1641',c:'児発３１・地公体・未計画・評価減・',baseItem: true,},
  {s:'61D135',v:'1352',c:'児発３１・地公体・未計画・開所減１・',baseItem: true,},
  {s:'61D136',v:'1149',c:'児発３１・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D137',v:'1641',c:'児発３１・地公体・未計画・開所減２・',baseItem: true,},
  {s:'61D138',v:'1395',c:'児発３１・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D139',v:'1380',c:'児発３１・地公体・未計画２・',baseItem: true,},
  {s:'61D140',v:'1173',c:'児発３１・地公体・未計画２・評価減・',baseItem: true,},
  {s:'61D141',v:'966',c:'児発３１・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'61D142',v:'821',c:'児発３１・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D143',v:'1173',c:'児発３１・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'61D144',v:'997',c:'児発３１・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D145',v:'2830',c:'児発３２・',baseItem: true,},
  {s:'61D146',v:'2406',c:'児発３２・評価減・',baseItem: true,},
  {s:'61D147',v:'1981',c:'児発３２・開所減１・',baseItem: true,},
  {s:'61D148',v:'1684',c:'児発３２・開所減１・評価減・',baseItem: true,},
  {s:'61D149',v:'2406',c:'児発３２・開所減２・',baseItem: true,},
  {s:'61D150',v:'2045',c:'児発３２・開所減２・評価減・',baseItem: true,},
  {s:'61D151',v:'1981',c:'児発３２・未計画・',baseItem: true,},
  {s:'61D152',v:'1684',c:'児発３２・未計画・評価減・',baseItem: true,},
  {s:'61D153',v:'1387',c:'児発３２・未計画・開所減１・',baseItem: true,},
  {s:'61D154',v:'1179',c:'児発３２・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D155',v:'1684',c:'児発３２・未計画・開所減２・',baseItem: true,},
  {s:'61D156',v:'1431',c:'児発３２・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D157',v:'1415',c:'児発３２・未計画２・',baseItem: true,},
  {s:'61D158',v:'1203',c:'児発３２・未計画２・評価減・',baseItem: true,},
  {s:'61D159',v:'991',c:'児発３２・未計画２・開所減１・',baseItem: true,},
  {s:'61D160',v:'842',c:'児発３２・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D161',v:'1203',c:'児発３２・未計画２・開所減２・',baseItem: true,},
  {s:'61D162',v:'1023',c:'児発３２・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D163',v:'2731',c:'児発３２・地公体・',baseItem: true,},
  {s:'61D164',v:'2321',c:'児発３２・地公体・評価減・',baseItem: true,},
  {s:'61D165',v:'1912',c:'児発３２・地公体・開所減１・',baseItem: true,},
  {s:'61D166',v:'1625',c:'児発３２・地公体・開所減１・評価減・',baseItem: true,},
  {s:'61D167',v:'2321',c:'児発３２・地公体・開所減２・',baseItem: true,},
  {s:'61D168',v:'1973',c:'児発３２・地公体・開所減２・評価減・',baseItem: true,},
  {s:'61D169',v:'1912',c:'児発３２・地公体・未計画・',baseItem: true,},
  {s:'61D170',v:'1625',c:'児発３２・地公体・未計画・評価減・',baseItem: true,},
  {s:'61D171',v:'1338',c:'児発３２・地公体・未計画・開所減１・',baseItem: true,},
  {s:'61D172',v:'1137',c:'児発３２・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D173',v:'1625',c:'児発３２・地公体・未計画・開所減２・',baseItem: true,},
  {s:'61D174',v:'1381',c:'児発３２・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D175',v:'1366',c:'児発３２・地公体・未計画２・',baseItem: true,},
  {s:'61D176',v:'1161',c:'児発３２・地公体・未計画２・評価減・',baseItem: true,},
  {s:'61D177',v:'956',c:'児発３２・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'61D178',v:'813',c:'児発３２・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D179',v:'1161',c:'児発３２・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'61D180',v:'987',c:'児発３２・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D181',v:'2804',c:'児発３３・',baseItem: true,},
  {s:'61D182',v:'2383',c:'児発３３・評価減・',baseItem: true,},
  {s:'61D183',v:'1963',c:'児発３３・開所減１・',baseItem: true,},
  {s:'61D184',v:'1669',c:'児発３３・開所減１・評価減・',baseItem: true,},
  {s:'61D185',v:'2383',c:'児発３３・開所減２・',baseItem: true,},
  {s:'61D186',v:'2026',c:'児発３３・開所減２・評価減・',baseItem: true,},
  {s:'61D187',v:'1963',c:'児発３３・未計画・',baseItem: true,},
  {s:'61D188',v:'1669',c:'児発３３・未計画・評価減・',baseItem: true,},
  {s:'61D189',v:'1374',c:'児発３３・未計画・開所減１・',baseItem: true,},
  {s:'61D190',v:'1168',c:'児発３３・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D191',v:'1669',c:'児発３３・未計画・開所減２・',baseItem: true,},
  {s:'61D192',v:'1419',c:'児発３３・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D193',v:'1402',c:'児発３３・未計画２・',baseItem: true,},
  {s:'61D194',v:'1192',c:'児発３３・未計画２・評価減・',baseItem: true,},
  {s:'61D195',v:'981',c:'児発３３・未計画２・開所減１・',baseItem: true,},
  {s:'61D196',v:'834',c:'児発３３・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D197',v:'1192',c:'児発３３・未計画２・開所減２・',baseItem: true,},
  {s:'61D198',v:'1013',c:'児発３３・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D199',v:'2706',c:'児発３３・地公体・',baseItem: true,},
  {s:'61D200',v:'2300',c:'児発３３・地公体・評価減・',baseItem: true,},
  {s:'61D201',v:'1894',c:'児発３３・地公体・開所減１・',baseItem: true,},
  {s:'61D202',v:'1610',c:'児発３３・地公体・開所減１・評価減・',baseItem: true,},
  {s:'61D203',v:'2300',c:'児発３３・地公体・開所減２・',baseItem: true,},
  {s:'61D204',v:'1955',c:'児発３３・地公体・開所減２・評価減・',baseItem: true,},
  {s:'61D205',v:'1894',c:'児発３３・地公体・未計画・',baseItem: true,},
  {s:'61D206',v:'1610',c:'児発３３・地公体・未計画・評価減・',baseItem: true,},
  {s:'61D207',v:'1326',c:'児発３３・地公体・未計画・開所減１・',baseItem: true,},
  {s:'61D208',v:'1127',c:'児発３３・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D209',v:'1610',c:'児発３３・地公体・未計画・開所減２・',baseItem: true,},
  {s:'61D210',v:'1369',c:'児発３３・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D211',v:'1353',c:'児発３３・地公体・未計画２・',baseItem: true,},
  {s:'61D212',v:'1150',c:'児発３３・地公体・未計画２・評価減・',baseItem: true,},
  {s:'61D213',v:'947',c:'児発３３・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'61D214',v:'805',c:'児発３３・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D215',v:'1150',c:'児発３３・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'61D216',v:'978',c:'児発３３・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D217',v:'2778',c:'児発３４・',baseItem: true,},
  {s:'61D218',v:'2361',c:'児発３４・評価減・',baseItem: true,},
  {s:'61D219',v:'1945',c:'児発３４・開所減１・',baseItem: true,},
  {s:'61D220',v:'1653',c:'児発３４・開所減１・評価減・',baseItem: true,},
  {s:'61D221',v:'2361',c:'児発３４・開所減２・',baseItem: true,},
  {s:'61D222',v:'2007',c:'児発３４・開所減２・評価減・',baseItem: true,},
  {s:'61D223',v:'1945',c:'児発３４・未計画・',baseItem: true,},
  {s:'61D224',v:'1653',c:'児発３４・未計画・評価減・',baseItem: true,},
  {s:'61D225',v:'1362',c:'児発３４・未計画・開所減１・',baseItem: true,},
  {s:'61D226',v:'1158',c:'児発３４・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D227',v:'1653',c:'児発３４・未計画・開所減２・',baseItem: true,},
  {s:'61D228',v:'1405',c:'児発３４・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D229',v:'1389',c:'児発３４・未計画２・',baseItem: true,},
  {s:'61D230',v:'1181',c:'児発３４・未計画２・評価減・',baseItem: true,},
  {s:'61D231',v:'972',c:'児発３４・未計画２・開所減１・',baseItem: true,},
  {s:'61D232',v:'826',c:'児発３４・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D233',v:'1181',c:'児発３４・未計画２・開所減２・',baseItem: true,},
  {s:'61D234',v:'1004',c:'児発３４・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D235',v:'2681',c:'児発３４・地公体・',baseItem: true,},
  {s:'61D236',v:'2279',c:'児発３４・地公体・評価減・',baseItem: true,},
  {s:'61D237',v:'1877',c:'児発３４・地公体・開所減１・',baseItem: true,},
  {s:'61D238',v:'1595',c:'児発３４・地公体・開所減１・評価減・',baseItem: true,},
  {s:'61D239',v:'2279',c:'児発３４・地公体・開所減２・',baseItem: true,},
  {s:'61D240',v:'1937',c:'児発３４・地公体・開所減２・評価減・',baseItem: true,},
  {s:'61D241',v:'1877',c:'児発３４・地公体・未計画・',baseItem: true,},
  {s:'61D242',v:'1595',c:'児発３４・地公体・未計画・評価減・',baseItem: true,},
  {s:'61D243',v:'1314',c:'児発３４・地公体・未計画・開所減１・',baseItem: true,},
  {s:'61D244',v:'1117',c:'児発３４・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D245',v:'1595',c:'児発３４・地公体・未計画・開所減２・',baseItem: true,},
  {s:'61D246',v:'1356',c:'児発３４・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D247',v:'1341',c:'児発３４・地公体・未計画２・',baseItem: true,},
  {s:'61D248',v:'1140',c:'児発３４・地公体・未計画２・評価減・',baseItem: true,},
  {s:'61D249',v:'939',c:'児発３４・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'61D250',v:'798',c:'児発３４・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D251',v:'1140',c:'児発３４・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'61D252',v:'969',c:'児発３４・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D253',v:'2086',c:'児発３５・',baseItem: true,},
  {s:'61D254',v:'1773',c:'児発３５・評価減・',baseItem: true,},
  {s:'61D255',v:'1460',c:'児発３５・開所減１・',baseItem: true,},
  {s:'61D256',v:'1241',c:'児発３５・開所減１・評価減・',baseItem: true,},
  {s:'61D257',v:'1773',c:'児発３５・開所減２・',baseItem: true,},
  {s:'61D258',v:'1507',c:'児発３５・開所減２・評価減・',baseItem: true,},
  {s:'61D259',v:'1460',c:'児発３５・未計画・',baseItem: true,},
  {s:'61D260',v:'1241',c:'児発３５・未計画・評価減・',baseItem: true,},
  {s:'61D261',v:'1022',c:'児発３５・未計画・開所減１・',baseItem: true,},
  {s:'61D262',v:'869',c:'児発３５・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D263',v:'1241',c:'児発３５・未計画・開所減２・',baseItem: true,},
  {s:'61D264',v:'1055',c:'児発３５・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D265',v:'1043',c:'児発３５・未計画２・',baseItem: true,},
  {s:'61D266',v:'887',c:'児発３５・未計画２・評価減・',baseItem: true,},
  {s:'61D267',v:'730',c:'児発３５・未計画２・開所減１・',baseItem: true,},
  {s:'61D268',v:'621',c:'児発３５・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D269',v:'887',c:'児発３５・未計画２・開所減２・',baseItem: true,},
  {s:'61D270',v:'754',c:'児発３５・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D271',v:'2013',c:'児発３５・地公体・',baseItem: true,},
  {s:'61D272',v:'1711',c:'児発３５・地公体・評価減・',baseItem: true,},
  {s:'61D273',v:'1409',c:'児発３５・地公体・開所減１・',baseItem: true,},
  {s:'61D274',v:'1198',c:'児発３５・地公体・開所減１・評価減・',baseItem: true,},
  {s:'61D275',v:'1711',c:'児発３５・地公体・開所減２・',baseItem: true,},
  {s:'61D276',v:'1454',c:'児発３５・地公体・開所減２・評価減・',baseItem: true,},
  {s:'61D277',v:'1409',c:'児発３５・地公体・未計画・',baseItem: true,},
  {s:'61D278',v:'1198',c:'児発３５・地公体・未計画・評価減・',baseItem: true,},
  {s:'61D279',v:'986',c:'児発３５・地公体・未計画・開所減１・',baseItem: true,},
  {s:'61D280',v:'838',c:'児発３５・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D281',v:'1198',c:'児発３５・地公体・未計画・開所減２・',baseItem: true,},
  {s:'61D282',v:'1018',c:'児発３５・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D283',v:'1007',c:'児発３５・地公体・未計画２・',baseItem: true,},
  {s:'61D284',v:'856',c:'児発３５・地公体・未計画２・評価減・',baseItem: true,},
  {s:'61D285',v:'705',c:'児発３５・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'61D286',v:'599',c:'児発３５・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D287',v:'856',c:'児発３５・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'61D288',v:'728',c:'児発３５・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D289',v:'2005',c:'児発３６・',baseItem: true,},
  {s:'61D290',v:'1704',c:'児発３６・評価減・',baseItem: true,},
  {s:'61D291',v:'1404',c:'児発３６・開所減１・',baseItem: true,},
  {s:'61D292',v:'1193',c:'児発３６・開所減１・評価減・',baseItem: true,},
  {s:'61D293',v:'1704',c:'児発３６・開所減２・',baseItem: true,},
  {s:'61D294',v:'1448',c:'児発３６・開所減２・評価減・',baseItem: true,},
  {s:'61D295',v:'1404',c:'児発３６・未計画・',baseItem: true,},
  {s:'61D296',v:'1193',c:'児発３６・未計画・評価減・',baseItem: true,},
  {s:'61D297',v:'983',c:'児発３６・未計画・開所減１・',baseItem: true,},
  {s:'61D298',v:'836',c:'児発３６・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D299',v:'1193',c:'児発３６・未計画・開所減２・',baseItem: true,},
  {s:'61D300',v:'1014',c:'児発３６・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D301',v:'1003',c:'児発３６・未計画２・',baseItem: true,},
  {s:'61D302',v:'853',c:'児発３６・未計画２・評価減・',baseItem: true,},
  {s:'61D303',v:'702',c:'児発３６・未計画２・開所減１・',baseItem: true,},
  {s:'61D304',v:'597',c:'児発３６・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D305',v:'853',c:'児発３６・未計画２・開所減２・',baseItem: true,},
  {s:'61D306',v:'725',c:'児発３６・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D307',v:'1935',c:'児発３６・地公体・',baseItem: true,},
  {s:'61D308',v:'1645',c:'児発３６・地公体・評価減・',baseItem: true,},
  {s:'61D309',v:'1355',c:'児発３６・地公体・開所減１・',baseItem: true,},
  {s:'61D310',v:'1152',c:'児発３６・地公体・開所減１・評価減・',baseItem: true,},
  {s:'61D311',v:'1645',c:'児発３６・地公体・開所減２・',baseItem: true,},
  {s:'61D312',v:'1398',c:'児発３６・地公体・開所減２・評価減・',baseItem: true,},
  {s:'61D313',v:'1355',c:'児発３６・地公体・未計画・',baseItem: true,},
  {s:'61D314',v:'1152',c:'児発３６・地公体・未計画・評価減・',baseItem: true,},
  {s:'61D315',v:'949',c:'児発３６・地公体・未計画・開所減１・',baseItem: true,},
  {s:'61D316',v:'807',c:'児発３６・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D317',v:'1152',c:'児発３６・地公体・未計画・開所減２・',baseItem: true,},
  {s:'61D318',v:'979',c:'児発３６・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D319',v:'968',c:'児発３６・地公体・未計画２・',baseItem: true,},
  {s:'61D320',v:'823',c:'児発３６・地公体・未計画２・評価減・',baseItem: true,},
  {s:'61D321',v:'678',c:'児発３６・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'61D322',v:'576',c:'児発３６・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D323',v:'823',c:'児発３６・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'61D324',v:'700',c:'児発３６・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D325',v:'1930',c:'児発３７・',baseItem: true,},
  {s:'61D326',v:'1641',c:'児発３７・評価減・',baseItem: true,},
  {s:'61D327',v:'1351',c:'児発３７・開所減１・',baseItem: true,},
  {s:'61D328',v:'1148',c:'児発３７・開所減１・評価減・',baseItem: true,},
  {s:'61D329',v:'1641',c:'児発３７・開所減２・',baseItem: true,},
  {s:'61D330',v:'1395',c:'児発３７・開所減２・評価減・',baseItem: true,},
  {s:'61D331',v:'1351',c:'児発３７・未計画・',baseItem: true,},
  {s:'61D332',v:'1148',c:'児発３７・未計画・評価減・',baseItem: true,},
  {s:'61D333',v:'946',c:'児発３７・未計画・開所減１・',baseItem: true,},
  {s:'61D334',v:'804',c:'児発３７・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D335',v:'1148',c:'児発３７・未計画・開所減２・',baseItem: true,},
  {s:'61D336',v:'976',c:'児発３７・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D337',v:'965',c:'児発３７・未計画２・',baseItem: true,},
  {s:'61D338',v:'820',c:'児発３７・未計画２・評価減・',baseItem: true,},
  {s:'61D339',v:'676',c:'児発３７・未計画２・開所減１・',baseItem: true,},
  {s:'61D340',v:'575',c:'児発３７・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D341',v:'820',c:'児発３７・未計画２・開所減２・',baseItem: true,},
  {s:'61D342',v:'697',c:'児発３７・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D343',v:'1862',c:'児発３７・地公体・',baseItem: true,},
  {s:'61D344',v:'1583',c:'児発３７・地公体・評価減・',baseItem: true,},
  {s:'61D345',v:'1303',c:'児発３７・地公体・開所減１・',baseItem: true,},
  {s:'61D346',v:'1108',c:'児発３７・地公体・開所減１・評価減・',baseItem: true,},
  {s:'61D347',v:'1583',c:'児発３７・地公体・開所減２・',baseItem: true,},
  {s:'61D348',v:'1346',c:'児発３７・地公体・開所減２・評価減・',baseItem: true,},
  {s:'61D349',v:'1303',c:'児発３７・地公体・未計画・',baseItem: true,},
  {s:'61D350',v:'1108',c:'児発３７・地公体・未計画・評価減・',baseItem: true,},
  {s:'61D351',v:'912',c:'児発３７・地公体・未計画・開所減１・',baseItem: true,},
  {s:'61D352',v:'775',c:'児発３７・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D353',v:'1108',c:'児発３７・地公体・未計画・開所減２・',baseItem: true,},
  {s:'61D354',v:'942',c:'児発３７・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D355',v:'931',c:'児発３７・地公体・未計画２・',baseItem: true,},
  {s:'61D356',v:'791',c:'児発３７・地公体・未計画２・評価減・',baseItem: true,},
  {s:'61D357',v:'652',c:'児発３７・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'61D358',v:'554',c:'児発３７・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D359',v:'791',c:'児発３７・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'61D360',v:'672',c:'児発３７・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D361',v:'1859',c:'児発３８・',baseItem: true,},
  {s:'61D362',v:'1580',c:'児発３８・評価減・',baseItem: true,},
  {s:'61D363',v:'1301',c:'児発３８・開所減１・',baseItem: true,},
  {s:'61D364',v:'1106',c:'児発３８・開所減１・評価減・',baseItem: true,},
  {s:'61D365',v:'1580',c:'児発３８・開所減２・',baseItem: true,},
  {s:'61D366',v:'1343',c:'児発３８・開所減２・評価減・',baseItem: true,},
  {s:'61D367',v:'1301',c:'児発３８・未計画・',baseItem: true,},
  {s:'61D368',v:'1106',c:'児発３８・未計画・評価減・',baseItem: true,},
  {s:'61D369',v:'911',c:'児発３８・未計画・開所減１・',baseItem: true,},
  {s:'61D370',v:'774',c:'児発３８・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D371',v:'1106',c:'児発３８・未計画・開所減２・',baseItem: true,},
  {s:'61D372',v:'940',c:'児発３８・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D373',v:'930',c:'児発３８・未計画２・',baseItem: true,},
  {s:'61D374',v:'791',c:'児発３８・未計画２・評価減・',baseItem: true,},
  {s:'61D375',v:'651',c:'児発３８・未計画２・開所減１・',baseItem: true,},
  {s:'61D376',v:'553',c:'児発３８・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D377',v:'791',c:'児発３８・未計画２・開所減２・',baseItem: true,},
  {s:'61D378',v:'672',c:'児発３８・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D379',v:'1794',c:'児発３８・地公体・',baseItem: true,},
  {s:'61D380',v:'1525',c:'児発３８・地公体・評価減・',baseItem: true,},
  {s:'61D381',v:'1256',c:'児発３８・地公体・開所減１・',baseItem: true,},
  {s:'61D382',v:'1068',c:'児発３８・地公体・開所減１・評価減・',baseItem: true,},
  {s:'61D383',v:'1525',c:'児発３８・地公体・開所減２・',baseItem: true,},
  {s:'61D384',v:'1296',c:'児発３８・地公体・開所減２・評価減・',baseItem: true,},
  {s:'61D385',v:'1256',c:'児発３８・地公体・未計画・',baseItem: true,},
  {s:'61D386',v:'1068',c:'児発３８・地公体・未計画・評価減・',baseItem: true,},
  {s:'61D387',v:'879',c:'児発３８・地公体・未計画・開所減１・',baseItem: true,},
  {s:'61D388',v:'747',c:'児発３８・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D389',v:'1068',c:'児発３８・地公体・未計画・開所減２・',baseItem: true,},
  {s:'61D390',v:'908',c:'児発３８・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D391',v:'897',c:'児発３８・地公体・未計画２・',baseItem: true,},
  {s:'61D392',v:'762',c:'児発３８・地公体・未計画２・評価減・',baseItem: true,},
  {s:'61D393',v:'628',c:'児発３８・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'61D394',v:'534',c:'児発３８・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D395',v:'762',c:'児発３８・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'61D396',v:'648',c:'児発３８・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D397',v:'1830',c:'児発３９・',baseItem: true,},
  {s:'61D398',v:'1556',c:'児発３９・評価減・',baseItem: true,},
  {s:'61D399',v:'1281',c:'児発３９・開所減１・',baseItem: true,},
  {s:'61D400',v:'1089',c:'児発３９・開所減１・評価減・',baseItem: true,},
  {s:'61D401',v:'1556',c:'児発３９・開所減２・',baseItem: true,},
  {s:'61D402',v:'1323',c:'児発３９・開所減２・評価減・',baseItem: true,},
  {s:'61D403',v:'1281',c:'児発３９・未計画・',baseItem: true,},
  {s:'61D404',v:'1089',c:'児発３９・未計画・評価減・',baseItem: true,},
  {s:'61D405',v:'897',c:'児発３９・未計画・開所減１・',baseItem: true,},
  {s:'61D406',v:'762',c:'児発３９・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D407',v:'1089',c:'児発３９・未計画・開所減２・',baseItem: true,},
  {s:'61D408',v:'926',c:'児発３９・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D409',v:'915',c:'児発３９・未計画２・',baseItem: true,},
  {s:'61D410',v:'778',c:'児発３９・未計画２・評価減・',baseItem: true,},
  {s:'61D411',v:'641',c:'児発３９・未計画２・開所減１・',baseItem: true,},
  {s:'61D412',v:'545',c:'児発３９・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D413',v:'778',c:'児発３９・未計画２・開所減２・',baseItem: true,},
  {s:'61D414',v:'661',c:'児発３９・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D415',v:'1766',c:'児発３９・地公体・',baseItem: true,},
  {s:'61D416',v:'1501',c:'児発３９・地公体・評価減・',baseItem: true,},
  {s:'61D417',v:'1236',c:'児発３９・地公体・開所減１・',baseItem: true,},
  {s:'61D418',v:'1051',c:'児発３９・地公体・開所減１・評価減・',baseItem: true,},
  {s:'61D419',v:'1501',c:'児発３９・地公体・開所減２・',baseItem: true,},
  {s:'61D420',v:'1276',c:'児発３９・地公体・開所減２・評価減・',baseItem: true,},
  {s:'61D421',v:'1236',c:'児発３９・地公体・未計画・',baseItem: true,},
  {s:'61D422',v:'1051',c:'児発３９・地公体・未計画・評価減・',baseItem: true,},
  {s:'61D423',v:'865',c:'児発３９・地公体・未計画・開所減１・',baseItem: true,},
  {s:'61D424',v:'735',c:'児発３９・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D425',v:'1051',c:'児発３９・地公体・未計画・開所減２・',baseItem: true,},
  {s:'61D426',v:'893',c:'児発３９・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D427',v:'883',c:'児発３９・地公体・未計画２・',baseItem: true,},
  {s:'61D428',v:'751',c:'児発３９・地公体・未計画２・評価減・',baseItem: true,},
  {s:'61D429',v:'618',c:'児発３９・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'61D430',v:'525',c:'児発３９・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D431',v:'751',c:'児発３９・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'61D432',v:'638',c:'児発３９・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D433',v:'1804',c:'児発４０・',baseItem: true,},
  {s:'61D434',v:'1533',c:'児発４０・評価減・',baseItem: true,},
  {s:'61D435',v:'1263',c:'児発４０・開所減１・',baseItem: true,},
  {s:'61D436',v:'1074',c:'児発４０・開所減１・評価減・',baseItem: true,},
  {s:'61D437',v:'1533',c:'児発４０・開所減２・',baseItem: true,},
  {s:'61D438',v:'1303',c:'児発４０・開所減２・評価減・',baseItem: true,},
  {s:'61D439',v:'1263',c:'児発４０・未計画・',baseItem: true,},
  {s:'61D440',v:'1074',c:'児発４０・未計画・評価減・',baseItem: true,},
  {s:'61D441',v:'884',c:'児発４０・未計画・開所減１・',baseItem: true,},
  {s:'61D442',v:'751',c:'児発４０・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D443',v:'1074',c:'児発４０・未計画・開所減２・',baseItem: true,},
  {s:'61D444',v:'913',c:'児発４０・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D445',v:'902',c:'児発４０・未計画２・',baseItem: true,},
  {s:'61D446',v:'767',c:'児発４０・未計画２・評価減・',baseItem: true,},
  {s:'61D447',v:'631',c:'児発４０・未計画２・開所減１・',baseItem: true,},
  {s:'61D448',v:'536',c:'児発４０・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D449',v:'767',c:'児発４０・未計画２・開所減２・',baseItem: true,},
  {s:'61D450',v:'652',c:'児発４０・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D451',v:'1741',c:'児発４０・地公体・',baseItem: true,},
  {s:'61D452',v:'1480',c:'児発４０・地公体・評価減・',baseItem: true,},
  {s:'61D453',v:'1219',c:'児発４０・地公体・開所減１・',baseItem: true,},
  {s:'61D454',v:'1036',c:'児発４０・地公体・開所減１・評価減・',baseItem: true,},
  {s:'61D455',v:'1480',c:'児発４０・地公体・開所減２・',baseItem: true,},
  {s:'61D456',v:'1258',c:'児発４０・地公体・開所減２・評価減・',baseItem: true,},
  {s:'61D457',v:'1219',c:'児発４０・地公体・未計画・',baseItem: true,},
  {s:'61D458',v:'1036',c:'児発４０・地公体・未計画・評価減・',baseItem: true,},
  {s:'61D459',v:'853',c:'児発４０・地公体・未計画・開所減１・',baseItem: true,},
  {s:'61D460',v:'725',c:'児発４０・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D461',v:'1036',c:'児発４０・地公体・未計画・開所減２・',baseItem: true,},
  {s:'61D462',v:'881',c:'児発４０・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D463',v:'871',c:'児発４０・地公体・未計画２・',baseItem: true,},
  {s:'61D464',v:'740',c:'児発４０・地公体・未計画２・評価減・',baseItem: true,},
  {s:'61D465',v:'610',c:'児発４０・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'61D466',v:'519',c:'児発４０・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D467',v:'740',c:'児発４０・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'61D468',v:'629',c:'児発４０・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D469',v:'1778',c:'児発４１・',baseItem: true,},
  {s:'61D470',v:'1511',c:'児発４１・評価減・',baseItem: true,},
  {s:'61D471',v:'1245',c:'児発４１・開所減１・',baseItem: true,},
  {s:'61D472',v:'1058',c:'児発４１・開所減１・評価減・',baseItem: true,},
  {s:'61D473',v:'1511',c:'児発４１・開所減２・',baseItem: true,},
  {s:'61D474',v:'1284',c:'児発４１・開所減２・評価減・',baseItem: true,},
  {s:'61D475',v:'1245',c:'児発４１・未計画・',baseItem: true,},
  {s:'61D476',v:'1058',c:'児発４１・未計画・評価減・',baseItem: true,},
  {s:'61D477',v:'872',c:'児発４１・未計画・開所減１・',baseItem: true,},
  {s:'61D478',v:'741',c:'児発４１・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D479',v:'1058',c:'児発４１・未計画・開所減２・',baseItem: true,},
  {s:'61D480',v:'899',c:'児発４１・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D481',v:'889',c:'児発４１・未計画２・',baseItem: true,},
  {s:'61D482',v:'756',c:'児発４１・未計画２・評価減・',baseItem: true,},
  {s:'61D483',v:'622',c:'児発４１・未計画２・開所減１・',baseItem: true,},
  {s:'61D484',v:'529',c:'児発４１・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D485',v:'756',c:'児発４１・未計画２・開所減２・',baseItem: true,},
  {s:'61D486',v:'643',c:'児発４１・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D487',v:'1716',c:'児発４１・地公体・',baseItem: true,},
  {s:'61D488',v:'1459',c:'児発４１・地公体・評価減・',baseItem: true,},
  {s:'61D489',v:'1201',c:'児発４１・地公体・開所減１・',baseItem: true,},
  {s:'61D490',v:'1021',c:'児発４１・地公体・開所減１・評価減・',baseItem: true,},
  {s:'61D491',v:'1459',c:'児発４１・地公体・開所減２・',baseItem: true,},
  {s:'61D492',v:'1240',c:'児発４１・地公体・開所減２・評価減・',baseItem: true,},
  {s:'61D493',v:'1201',c:'児発４１・地公体・未計画・',baseItem: true,},
  {s:'61D494',v:'1021',c:'児発４１・地公体・未計画・評価減・',baseItem: true,},
  {s:'61D495',v:'841',c:'児発４１・地公体・未計画・開所減１・',baseItem: true,},
  {s:'61D496',v:'715',c:'児発４１・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D497',v:'1021',c:'児発４１・地公体・未計画・開所減２・',baseItem: true,},
  {s:'61D498',v:'868',c:'児発４１・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D499',v:'858',c:'児発４１・地公体・未計画２・',baseItem: true,},
  {s:'61D500',v:'729',c:'児発４１・地公体・未計画２・評価減・',baseItem: true,},
  {s:'61D501',v:'601',c:'児発４１・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'61D502',v:'511',c:'児発４１・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D503',v:'729',c:'児発４１・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'61D504',v:'620',c:'児発４１・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D505',v:'1753',c:'児発４２・',baseItem: true,},
  {s:'61D506',v:'1490',c:'児発４２・評価減・',baseItem: true,},
  {s:'61D507',v:'1227',c:'児発４２・開所減１・',baseItem: true,},
  {s:'61D508',v:'1043',c:'児発４２・開所減１・評価減・',baseItem: true,},
  {s:'61D509',v:'1490',c:'児発４２・開所減２・',baseItem: true,},
  {s:'61D510',v:'1267',c:'児発４２・開所減２・評価減・',baseItem: true,},
  {s:'61D511',v:'1227',c:'児発４２・未計画・',baseItem: true,},
  {s:'61D512',v:'1043',c:'児発４２・未計画・評価減・',baseItem: true,},
  {s:'61D513',v:'859',c:'児発４２・未計画・開所減１・',baseItem: true,},
  {s:'61D514',v:'730',c:'児発４２・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D515',v:'1043',c:'児発４２・未計画・開所減２・',baseItem: true,},
  {s:'61D516',v:'887',c:'児発４２・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D517',v:'877',c:'児発４２・未計画２・',baseItem: true,},
  {s:'61D518',v:'745',c:'児発４２・未計画２・評価減・',baseItem: true,},
  {s:'61D519',v:'614',c:'児発４２・未計画２・開所減１・',baseItem: true,},
  {s:'61D520',v:'522',c:'児発４２・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D521',v:'745',c:'児発４２・未計画２・開所減２・',baseItem: true,},
  {s:'61D522',v:'633',c:'児発４２・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D523',v:'1692',c:'児発４２・地公体・',baseItem: true,},
  {s:'61D524',v:'1438',c:'児発４２・地公体・評価減・',baseItem: true,},
  {s:'61D525',v:'1184',c:'児発４２・地公体・開所減１・',baseItem: true,},
  {s:'61D526',v:'1006',c:'児発４２・地公体・開所減１・評価減・',baseItem: true,},
  {s:'61D527',v:'1438',c:'児発４２・地公体・開所減２・',baseItem: true,},
  {s:'61D528',v:'1222',c:'児発４２・地公体・開所減２・評価減・',baseItem: true,},
  {s:'61D529',v:'1184',c:'児発４２・地公体・未計画・',baseItem: true,},
  {s:'61D530',v:'1006',c:'児発４２・地公体・未計画・評価減・',baseItem: true,},
  {s:'61D531',v:'829',c:'児発４２・地公体・未計画・開所減１・',baseItem: true,},
  {s:'61D532',v:'705',c:'児発４２・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D533',v:'1006',c:'児発４２・地公体・未計画・開所減２・',baseItem: true,},
  {s:'61D534',v:'855',c:'児発４２・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D535',v:'846',c:'児発４２・地公体・未計画２・',baseItem: true,},
  {s:'61D536',v:'719',c:'児発４２・地公体・未計画２・評価減・',baseItem: true,},
  {s:'61D537',v:'592',c:'児発４２・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'61D538',v:'503',c:'児発４２・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D539',v:'719',c:'児発４２・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'61D540',v:'611',c:'児発４２・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D541',v:'1672',c:'児発４３・',baseItem: true,},
  {s:'61D542',v:'1421',c:'児発４３・評価減・',baseItem: true,},
  {s:'61D543',v:'1170',c:'児発４３・開所減１・',baseItem: true,},
  {s:'61D544',v:'995',c:'児発４３・開所減１・評価減・',baseItem: true,},
  {s:'61D545',v:'1421',c:'児発４３・開所減２・',baseItem: true,},
  {s:'61D546',v:'1208',c:'児発４３・開所減２・評価減・',baseItem: true,},
  {s:'61D547',v:'1170',c:'児発４３・未計画・',baseItem: true,},
  {s:'61D548',v:'995',c:'児発４３・未計画・評価減・',baseItem: true,},
  {s:'61D549',v:'819',c:'児発４３・未計画・開所減１・',baseItem: true,},
  {s:'61D550',v:'696',c:'児発４３・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D551',v:'995',c:'児発４３・未計画・開所減２・',baseItem: true,},
  {s:'61D552',v:'846',c:'児発４３・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D553',v:'836',c:'児発４３・未計画２・',baseItem: true,},
  {s:'61D554',v:'711',c:'児発４３・未計画２・評価減・',baseItem: true,},
  {s:'61D555',v:'585',c:'児発４３・未計画２・開所減１・',baseItem: true,},
  {s:'61D556',v:'497',c:'児発４３・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D557',v:'711',c:'児発４３・未計画２・開所減２・',baseItem: true,},
  {s:'61D558',v:'604',c:'児発４３・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D559',v:'1613',c:'児発４３・地公体・',baseItem: true,},
  {s:'61D560',v:'1371',c:'児発４３・地公体・評価減・',baseItem: true,},
  {s:'61D561',v:'1129',c:'児発４３・地公体・開所減１・',baseItem: true,},
  {s:'61D562',v:'960',c:'児発４３・地公体・開所減１・評価減・',baseItem: true,},
  {s:'61D563',v:'1371',c:'児発４３・地公体・開所減２・',baseItem: true,},
  {s:'61D564',v:'1165',c:'児発４３・地公体・開所減２・評価減・',baseItem: true,},
  {s:'61D565',v:'1129',c:'児発４３・地公体・未計画・',baseItem: true,},
  {s:'61D566',v:'960',c:'児発４３・地公体・未計画・評価減・',baseItem: true,},
  {s:'61D567',v:'790',c:'児発４３・地公体・未計画・開所減１・',baseItem: true,},
  {s:'61D568',v:'672',c:'児発４３・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D569',v:'960',c:'児発４３・地公体・未計画・開所減２・',baseItem: true,},
  {s:'61D570',v:'816',c:'児発４３・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D571',v:'807',c:'児発４３・地公体・未計画２・',baseItem: true,},
  {s:'61D572',v:'686',c:'児発４３・地公体・未計画２・評価減・',baseItem: true,},
  {s:'61D573',v:'565',c:'児発４３・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'61D574',v:'480',c:'児発４３・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D575',v:'686',c:'児発４３・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'61D576',v:'583',c:'児発４３・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D577',v:'1597',c:'児発４４・',baseItem: true,},
  {s:'61D578',v:'1357',c:'児発４４・評価減・',baseItem: true,},
  {s:'61D579',v:'1118',c:'児発４４・開所減１・',baseItem: true,},
  {s:'61D580',v:'950',c:'児発４４・開所減１・評価減・',baseItem: true,},
  {s:'61D581',v:'1357',c:'児発４４・開所減２・',baseItem: true,},
  {s:'61D582',v:'1153',c:'児発４４・開所減２・評価減・',baseItem: true,},
  {s:'61D583',v:'1118',c:'児発４４・未計画・',baseItem: true,},
  {s:'61D584',v:'950',c:'児発４４・未計画・評価減・',baseItem: true,},
  {s:'61D585',v:'783',c:'児発４４・未計画・開所減１・',baseItem: true,},
  {s:'61D586',v:'666',c:'児発４４・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D587',v:'950',c:'児発４４・未計画・開所減２・',baseItem: true,},
  {s:'61D588',v:'808',c:'児発４４・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D589',v:'799',c:'児発４４・未計画２・',baseItem: true,},
  {s:'61D590',v:'679',c:'児発４４・未計画２・評価減・',baseItem: true,},
  {s:'61D591',v:'559',c:'児発４４・未計画２・開所減１・',baseItem: true,},
  {s:'61D592',v:'475',c:'児発４４・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D593',v:'679',c:'児発４４・未計画２・開所減２・',baseItem: true,},
  {s:'61D594',v:'577',c:'児発４４・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D595',v:'1541',c:'児発４４・地公体・',baseItem: true,},
  {s:'61D596',v:'1310',c:'児発４４・地公体・評価減・',baseItem: true,},
  {s:'61D597',v:'1079',c:'児発４４・地公体・開所減１・',baseItem: true,},
  {s:'61D598',v:'917',c:'児発４４・地公体・開所減１・評価減・',baseItem: true,},
  {s:'61D599',v:'1310',c:'児発４４・地公体・開所減２・',baseItem: true,},
  {s:'61D600',v:'1114',c:'児発４４・地公体・開所減２・評価減・',baseItem: true,},
  {s:'61D601',v:'1079',c:'児発４４・地公体・未計画・',baseItem: true,},
  {s:'61D602',v:'917',c:'児発４４・地公体・未計画・評価減・',baseItem: true,},
  {s:'61D603',v:'755',c:'児発４４・地公体・未計画・開所減１・',baseItem: true,},
  {s:'61D604',v:'642',c:'児発４４・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D605',v:'917',c:'児発４４・地公体・未計画・開所減２・',baseItem: true,},
  {s:'61D606',v:'779',c:'児発４４・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D607',v:'771',c:'児発４４・地公体・未計画２・',baseItem: true,},
  {s:'61D608',v:'655',c:'児発４４・地公体・未計画２・評価減・',baseItem: true,},
  {s:'61D609',v:'540',c:'児発４４・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'61D610',v:'459',c:'児発４４・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D611',v:'655',c:'児発４４・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'61D612',v:'557',c:'児発４４・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D613',v:'1526',c:'児発４５・',baseItem: true,},
  {s:'61D614',v:'1297',c:'児発４５・評価減・',baseItem: true,},
  {s:'61D615',v:'1068',c:'児発４５・開所減１・',baseItem: true,},
  {s:'61D616',v:'908',c:'児発４５・開所減１・評価減・',baseItem: true,},
  {s:'61D617',v:'1297',c:'児発４５・開所減２・',baseItem: true,},
  {s:'61D618',v:'1102',c:'児発４５・開所減２・評価減・',baseItem: true,},
  {s:'61D619',v:'1068',c:'児発４５・未計画・',baseItem: true,},
  {s:'61D620',v:'908',c:'児発４５・未計画・評価減・',baseItem: true,},
  {s:'61D621',v:'748',c:'児発４５・未計画・開所減１・',baseItem: true,},
  {s:'61D622',v:'636',c:'児発４５・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D623',v:'908',c:'児発４５・未計画・開所減２・',baseItem: true,},
  {s:'61D624',v:'772',c:'児発４５・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D625',v:'763',c:'児発４５・未計画２・',baseItem: true,},
  {s:'61D626',v:'649',c:'児発４５・未計画２・評価減・',baseItem: true,},
  {s:'61D627',v:'534',c:'児発４５・未計画２・開所減１・',baseItem: true,},
  {s:'61D628',v:'454',c:'児発４５・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D629',v:'649',c:'児発４５・未計画２・開所減２・',baseItem: true,},
  {s:'61D630',v:'552',c:'児発４５・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D631',v:'1473',c:'児発４５・地公体・',baseItem: true,},
  {s:'61D632',v:'1252',c:'児発４５・地公体・評価減・',baseItem: true,},
  {s:'61D633',v:'1031',c:'児発４５・地公体・開所減１・',baseItem: true,},
  {s:'61D634',v:'876',c:'児発４５・地公体・開所減１・評価減・',baseItem: true,},
  {s:'61D635',v:'1252',c:'児発４５・地公体・開所減２・',baseItem: true,},
  {s:'61D636',v:'1064',c:'児発４５・地公体・開所減２・評価減・',baseItem: true,},
  {s:'61D637',v:'1031',c:'児発４５・地公体・未計画・',baseItem: true,},
  {s:'61D638',v:'876',c:'児発４５・地公体・未計画・評価減・',baseItem: true,},
  {s:'61D639',v:'722',c:'児発４５・地公体・未計画・開所減１・',baseItem: true,},
  {s:'61D640',v:'614',c:'児発４５・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D641',v:'876',c:'児発４５・地公体・未計画・開所減２・',baseItem: true,},
  {s:'61D642',v:'745',c:'児発４５・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D643',v:'737',c:'児発４５・地公体・未計画２・',baseItem: true,},
  {s:'61D644',v:'626',c:'児発４５・地公体・未計画２・評価減・',baseItem: true,},
  {s:'61D645',v:'516',c:'児発４５・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'61D646',v:'439',c:'児発４５・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D647',v:'626',c:'児発４５・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'61D648',v:'532',c:'児発４５・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D649',v:'1497',c:'児発４６・',baseItem: true,},
  {s:'61D650',v:'1272',c:'児発４６・評価減・',baseItem: true,},
  {s:'61D651',v:'1048',c:'児発４６・開所減１・',baseItem: true,},
  {s:'61D652',v:'891',c:'児発４６・開所減１・評価減・',baseItem: true,},
  {s:'61D653',v:'1272',c:'児発４６・開所減２・',baseItem: true,},
  {s:'61D654',v:'1081',c:'児発４６・開所減２・評価減・',baseItem: true,},
  {s:'61D655',v:'1048',c:'児発４６・未計画・',baseItem: true,},
  {s:'61D656',v:'891',c:'児発４６・未計画・評価減・',baseItem: true,},
  {s:'61D657',v:'734',c:'児発４６・未計画・開所減１・',baseItem: true,},
  {s:'61D658',v:'624',c:'児発４６・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D659',v:'891',c:'児発４６・未計画・開所減２・',baseItem: true,},
  {s:'61D660',v:'757',c:'児発４６・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D661',v:'749',c:'児発４６・未計画２・',baseItem: true,},
  {s:'61D662',v:'637',c:'児発４６・未計画２・評価減・',baseItem: true,},
  {s:'61D663',v:'524',c:'児発４６・未計画２・開所減１・',baseItem: true,},
  {s:'61D664',v:'445',c:'児発４６・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D665',v:'637',c:'児発４６・未計画２・開所減２・',baseItem: true,},
  {s:'61D666',v:'541',c:'児発４６・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D667',v:'1445',c:'児発４６・地公体・',baseItem: true,},
  {s:'61D668',v:'1228',c:'児発４６・地公体・評価減・',baseItem: true,},
  {s:'61D669',v:'1012',c:'児発４６・地公体・開所減１・',baseItem: true,},
  {s:'61D670',v:'860',c:'児発４６・地公体・開所減１・評価減・',baseItem: true,},
  {s:'61D671',v:'1228',c:'児発４６・地公体・開所減２・',baseItem: true,},
  {s:'61D672',v:'1044',c:'児発４６・地公体・開所減２・評価減・',baseItem: true,},
  {s:'61D673',v:'1012',c:'児発４６・地公体・未計画・',baseItem: true,},
  {s:'61D674',v:'860',c:'児発４６・地公体・未計画・評価減・',baseItem: true,},
  {s:'61D675',v:'708',c:'児発４６・地公体・未計画・開所減１・',baseItem: true,},
  {s:'61D676',v:'602',c:'児発４６・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D677',v:'860',c:'児発４６・地公体・未計画・開所減２・',baseItem: true,},
  {s:'61D678',v:'731',c:'児発４６・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D679',v:'723',c:'児発４６・地公体・未計画２・',baseItem: true,},
  {s:'61D680',v:'615',c:'児発４６・地公体・未計画２・評価減・',baseItem: true,},
  {s:'61D681',v:'506',c:'児発４６・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'61D682',v:'430',c:'児発４６・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D683',v:'615',c:'児発４６・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'61D684',v:'523',c:'児発４６・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D685',v:'1471',c:'児発４７・',baseItem: true,},
  {s:'61D686',v:'1250',c:'児発４７・評価減・',baseItem: true,},
  {s:'61D687',v:'1030',c:'児発４７・開所減１・',baseItem: true,},
  {s:'61D688',v:'876',c:'児発４７・開所減１・評価減・',baseItem: true,},
  {s:'61D689',v:'1250',c:'児発４７・開所減２・',baseItem: true,},
  {s:'61D690',v:'1063',c:'児発４７・開所減２・評価減・',baseItem: true,},
  {s:'61D691',v:'1030',c:'児発４７・未計画・',baseItem: true,},
  {s:'61D692',v:'876',c:'児発４７・未計画・評価減・',baseItem: true,},
  {s:'61D693',v:'721',c:'児発４７・未計画・開所減１・',baseItem: true,},
  {s:'61D694',v:'613',c:'児発４７・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D695',v:'876',c:'児発４７・未計画・開所減２・',baseItem: true,},
  {s:'61D696',v:'745',c:'児発４７・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D697',v:'736',c:'児発４７・未計画２・',baseItem: true,},
  {s:'61D698',v:'626',c:'児発４７・未計画２・評価減・',baseItem: true,},
  {s:'61D699',v:'515',c:'児発４７・未計画２・開所減１・',baseItem: true,},
  {s:'61D700',v:'438',c:'児発４７・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D701',v:'626',c:'児発４７・未計画２・開所減２・',baseItem: true,},
  {s:'61D702',v:'532',c:'児発４７・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D703',v:'1420',c:'児発４７・地公体・',baseItem: true,},
  {s:'61D704',v:'1207',c:'児発４７・地公体・評価減・',baseItem: true,},
  {s:'61D705',v:'994',c:'児発４７・地公体・開所減１・',baseItem: true,},
  {s:'61D706',v:'845',c:'児発４７・地公体・開所減１・評価減・',baseItem: true,},
  {s:'61D707',v:'1207',c:'児発４７・地公体・開所減２・',baseItem: true,},
  {s:'61D708',v:'1026',c:'児発４７・地公体・開所減２・評価減・',baseItem: true,},
  {s:'61D709',v:'994',c:'児発４７・地公体・未計画・',baseItem: true,},
  {s:'61D710',v:'845',c:'児発４７・地公体・未計画・評価減・',baseItem: true,},
  {s:'61D711',v:'696',c:'児発４７・地公体・未計画・開所減１・',baseItem: true,},
  {s:'61D712',v:'592',c:'児発４７・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D713',v:'845',c:'児発４７・地公体・未計画・開所減２・',baseItem: true,},
  {s:'61D714',v:'718',c:'児発４７・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D715',v:'710',c:'児発４７・地公体・未計画２・',baseItem: true,},
  {s:'61D716',v:'604',c:'児発４７・地公体・未計画２・評価減・',baseItem: true,},
  {s:'61D717',v:'497',c:'児発４７・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'61D718',v:'422',c:'児発４７・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D719',v:'604',c:'児発４７・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'61D720',v:'513',c:'児発４７・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D721',v:'1445',c:'児発４８・',baseItem: true,},
  {s:'61D722',v:'1228',c:'児発４８・評価減・',baseItem: true,},
  {s:'61D723',v:'1012',c:'児発４８・開所減１・',baseItem: true,},
  {s:'61D724',v:'860',c:'児発４８・開所減１・評価減・',baseItem: true,},
  {s:'61D725',v:'1228',c:'児発４８・開所減２・',baseItem: true,},
  {s:'61D726',v:'1044',c:'児発４８・開所減２・評価減・',baseItem: true,},
  {s:'61D727',v:'1012',c:'児発４８・未計画・',baseItem: true,},
  {s:'61D728',v:'860',c:'児発４８・未計画・評価減・',baseItem: true,},
  {s:'61D729',v:'708',c:'児発４８・未計画・開所減１・',baseItem: true,},
  {s:'61D730',v:'602',c:'児発４８・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D731',v:'860',c:'児発４８・未計画・開所減２・',baseItem: true,},
  {s:'61D732',v:'731',c:'児発４８・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D733',v:'723',c:'児発４８・未計画２・',baseItem: true,},
  {s:'61D734',v:'615',c:'児発４８・未計画２・評価減・',baseItem: true,},
  {s:'61D735',v:'506',c:'児発４８・未計画２・開所減１・',baseItem: true,},
  {s:'61D736',v:'430',c:'児発４８・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D737',v:'615',c:'児発４８・未計画２・開所減２・',baseItem: true,},
  {s:'61D738',v:'523',c:'児発４８・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D739',v:'1394',c:'児発４８・地公体・',baseItem: true,},
  {s:'61D740',v:'1185',c:'児発４８・地公体・評価減・',baseItem: true,},
  {s:'61D741',v:'976',c:'児発４８・地公体・開所減１・',baseItem: true,},
  {s:'61D742',v:'830',c:'児発４８・地公体・開所減１・評価減・',baseItem: true,},
  {s:'61D743',v:'1185',c:'児発４８・地公体・開所減２・',baseItem: true,},
  {s:'61D744',v:'1007',c:'児発４８・地公体・開所減２・評価減・',baseItem: true,},
  {s:'61D745',v:'976',c:'児発４８・地公体・未計画・',baseItem: true,},
  {s:'61D746',v:'830',c:'児発４８・地公体・未計画・評価減・',baseItem: true,},
  {s:'61D747',v:'683',c:'児発４８・地公体・未計画・開所減１・',baseItem: true,},
  {s:'61D748',v:'581',c:'児発４８・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D749',v:'830',c:'児発４８・地公体・未計画・開所減２・',baseItem: true,},
  {s:'61D750',v:'706',c:'児発４８・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D751',v:'697',c:'児発４８・地公体・未計画２・',baseItem: true,},
  {s:'61D752',v:'592',c:'児発４８・地公体・未計画２・評価減・',baseItem: true,},
  {s:'61D753',v:'488',c:'児発４８・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'61D754',v:'415',c:'児発４８・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D755',v:'592',c:'児発４８・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'61D756',v:'503',c:'児発４８・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611111',v:'1086',c:'児発１・',baseItem: true,},
  {s:'611250',v:'923',c:'児発１・評価減・',baseItem: true,},
  {s:'611113',v:'760',c:'児発１・開所減１・',baseItem: true,},
  {s:'611252',v:'646',c:'児発１・開所減１・評価減・',baseItem: true,},
  {s:'611701',v:'923',c:'児発１・開所減２・',baseItem: true,},
  {s:'611254',v:'785',c:'児発１・開所減２・評価減・',baseItem: true,},
  {s:'611115',v:'760',c:'児発１・未計画・',baseItem: true,},
  {s:'611256',v:'646',c:'児発１・未計画・評価減・',baseItem: true,},
  {s:'611117',v:'532',c:'児発１・未計画・開所減１・',baseItem: true,},
  {s:'611258',v:'452',c:'児発１・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611703',v:'646',c:'児発１・未計画・開所減２・',baseItem: true,},
  {s:'611260',v:'549',c:'児発１・未計画・開所減２・評価減・',baseItem: true,},
  {s:'611262',v:'543',c:'児発１・未計画２・',baseItem: true,},
  {s:'611264',v:'462',c:'児発１・未計画２・評価減・',baseItem: true,},
  {s:'611266',v:'380',c:'児発１・未計画２・開所減１・',baseItem: true,},
  {s:'611268',v:'323',c:'児発１・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'611270',v:'462',c:'児発１・未計画２・開所減２・',baseItem: true,},
  {s:'611272',v:'393',c:'児発１・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611119',v:'1048',c:'児発１・地公体・',baseItem: true,},
  {s:'611274',v:'891',c:'児発１・地公体・評価減・',baseItem: true,},
  {s:'611121',v:'734',c:'児発１・地公体・開所減１・',baseItem: true,},
  {s:'611276',v:'624',c:'児発１・地公体・開所減１・評価減・',baseItem: true,},
  {s:'611705',v:'891',c:'児発１・地公体・開所減２・',baseItem: true,},
  {s:'611278',v:'757',c:'児発１・地公体・開所減２・評価減・',baseItem: true,},
  {s:'611123',v:'734',c:'児発１・地公体・未計画・',baseItem: true,},
  {s:'611280',v:'624',c:'児発１・地公体・未計画・評価減・',baseItem: true,},
  {s:'611125',v:'514',c:'児発１・地公体・未計画・開所減１・',baseItem: true,},
  {s:'611282',v:'437',c:'児発１・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611707',v:'624',c:'児発１・地公体・未計画・開所減２・',baseItem: true,},
  {s:'611284',v:'530',c:'児発１・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'611286',v:'524',c:'児発１・地公体・未計画２・',baseItem: true,},
  {s:'611288',v:'445',c:'児発１・地公体・未計画２・評価減・',baseItem: true,},
  {s:'611290',v:'367',c:'児発１・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'611292',v:'312',c:'児発１・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'611294',v:'445',c:'児発１・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'611296',v:'378',c:'児発１・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611131',v:'1005',c:'児発２・',baseItem: true,},
  {s:'611300',v:'854',c:'児発２・評価減・',baseItem: true,},
  {s:'611133',v:'704',c:'児発２・開所減１・',baseItem: true,},
  {s:'611302',v:'598',c:'児発２・開所減１・評価減・',baseItem: true,},
  {s:'611709',v:'854',c:'児発２・開所減２・',baseItem: true,},
  {s:'611304',v:'726',c:'児発２・開所減２・評価減・',baseItem: true,},
  {s:'611135',v:'704',c:'児発２・未計画・',baseItem: true,},
  {s:'611306',v:'598',c:'児発２・未計画・評価減・',baseItem: true,},
  {s:'611137',v:'493',c:'児発２・未計画・開所減１・',baseItem: true,},
  {s:'611308',v:'419',c:'児発２・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611711',v:'598',c:'児発２・未計画・開所減２・',baseItem: true,},
  {s:'611310',v:'508',c:'児発２・未計画・開所減２・評価減・',baseItem: true,},
  {s:'611351',v:'503',c:'児発２・未計画２・',baseItem: true,},
  {s:'611353',v:'428',c:'児発２・未計画２・評価減・',baseItem: true,},
  {s:'611355',v:'352',c:'児発２・未計画２・開所減１・',baseItem: true,},
  {s:'611357',v:'299',c:'児発２・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'611359',v:'428',c:'児発２・未計画２・開所減２・',baseItem: true,},
  {s:'611361',v:'364',c:'児発２・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611139',v:'970',c:'児発２・地公体・',baseItem: true,},
  {s:'611363',v:'825',c:'児発２・地公体・評価減・',baseItem: true,},
  {s:'611141',v:'679',c:'児発２・地公体・開所減１・',baseItem: true,},
  {s:'611365',v:'577',c:'児発２・地公体・開所減１・評価減・',baseItem: true,},
  {s:'611713',v:'825',c:'児発２・地公体・開所減２・',baseItem: true,},
  {s:'611367',v:'701',c:'児発２・地公体・開所減２・評価減・',baseItem: true,},
  {s:'611143',v:'679',c:'児発２・地公体・未計画・',baseItem: true,},
  {s:'611369',v:'577',c:'児発２・地公体・未計画・評価減・',baseItem: true,},
  {s:'611145',v:'475',c:'児発２・地公体・未計画・開所減１・',baseItem: true,},
  {s:'611371',v:'404',c:'児発２・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611715',v:'577',c:'児発２・地公体・未計画・開所減２・',baseItem: true,},
  {s:'611373',v:'490',c:'児発２・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'611375',v:'485',c:'児発２・地公体・未計画２・',baseItem: true,},
  {s:'611377',v:'412',c:'児発２・地公体・未計画２・評価減・',baseItem: true,},
  {s:'611379',v:'340',c:'児発２・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'611381',v:'289',c:'児発２・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'611383',v:'412',c:'児発２・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'611385',v:'350',c:'児発２・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611151',v:'930',c:'児発３・',baseItem: true,},
  {s:'611390',v:'791',c:'児発３・評価減・',baseItem: true,},
  {s:'611153',v:'651',c:'児発３・開所減１・',baseItem: true,},
  {s:'611392',v:'553',c:'児発３・開所減１・評価減・',baseItem: true,},
  {s:'611717',v:'791',c:'児発３・開所減２・',baseItem: true,},
  {s:'611394',v:'672',c:'児発３・開所減２・評価減・',baseItem: true,},
  {s:'611155',v:'651',c:'児発３・未計画・',baseItem: true,},
  {s:'611396',v:'553',c:'児発３・未計画・評価減・',baseItem: true,},
  {s:'611157',v:'456',c:'児発３・未計画・開所減１・',baseItem: true,},
  {s:'611398',v:'388',c:'児発３・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611719',v:'553',c:'児発３・未計画・開所減２・',baseItem: true,},
  {s:'611400',v:'470',c:'児発３・未計画・開所減２・評価減・',baseItem: true,},
  {s:'611402',v:'465',c:'児発３・未計画２・',baseItem: true,},
  {s:'611404',v:'395',c:'児発３・未計画２・評価減・',baseItem: true,},
  {s:'611406',v:'326',c:'児発３・未計画２・開所減１・',baseItem: true,},
  {s:'611408',v:'277',c:'児発３・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'611410',v:'395',c:'児発３・未計画２・開所減２・',baseItem: true,},
  {s:'611451',v:'336',c:'児発３・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611159',v:'897',c:'児発３・地公体・',baseItem: true,},
  {s:'611453',v:'762',c:'児発３・地公体・評価減・',baseItem: true,},
  {s:'611161',v:'628',c:'児発３・地公体・開所減１・',baseItem: true,},
  {s:'611455',v:'534',c:'児発３・地公体・開所減１・評価減・',baseItem: true,},
  {s:'611721',v:'762',c:'児発３・地公体・開所減２・',baseItem: true,},
  {s:'611457',v:'648',c:'児発３・地公体・開所減２・評価減・',baseItem: true,},
  {s:'611163',v:'628',c:'児発３・地公体・未計画・',baseItem: true,},
  {s:'611459',v:'534',c:'児発３・地公体・未計画・評価減・',baseItem: true,},
  {s:'611165',v:'440',c:'児発３・地公体・未計画・開所減１・',baseItem: true,},
  {s:'611461',v:'374',c:'児発３・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611723',v:'534',c:'児発３・地公体・未計画・開所減２・',baseItem: true,},
  {s:'611463',v:'454',c:'児発３・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'611465',v:'449',c:'児発３・地公体・未計画２・',baseItem: true,},
  {s:'611467',v:'382',c:'児発３・地公体・未計画２・評価減・',baseItem: true,},
  {s:'611469',v:'314',c:'児発３・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'611471',v:'267',c:'児発３・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'611473',v:'382',c:'児発３・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'611475',v:'325',c:'児発３・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611171',v:'859',c:'児発４・',baseItem: true,},
  {s:'611480',v:'730',c:'児発４・評価減・',baseItem: true,},
  {s:'611173',v:'601',c:'児発４・開所減１・',baseItem: true,},
  {s:'611482',v:'511',c:'児発４・開所減１・評価減・',baseItem: true,},
  {s:'611725',v:'730',c:'児発４・開所減２・',baseItem: true,},
  {s:'611484',v:'621',c:'児発４・開所減２・評価減・',baseItem: true,},
  {s:'611175',v:'601',c:'児発４・未計画・',baseItem: true,},
  {s:'611486',v:'511',c:'児発４・未計画・評価減・',baseItem: true,},
  {s:'611177',v:'421',c:'児発４・未計画・開所減１・',baseItem: true,},
  {s:'611488',v:'358',c:'児発４・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611727',v:'511',c:'児発４・未計画・開所減２・',baseItem: true,},
  {s:'611490',v:'434',c:'児発４・未計画・開所減２・評価減・',baseItem: true,},
  {s:'611492',v:'430',c:'児発４・未計画２・',baseItem: true,},
  {s:'611494',v:'366',c:'児発４・未計画２・評価減・',baseItem: true,},
  {s:'611496',v:'301',c:'児発４・未計画２・開所減１・',baseItem: true,},
  {s:'611498',v:'256',c:'児発４・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'611500',v:'366',c:'児発４・未計画２・開所減２・',baseItem: true,},
  {s:'611502',v:'311',c:'児発４・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611179',v:'829',c:'児発４・地公体・',baseItem: true,},
  {s:'611504',v:'705',c:'児発４・地公体・評価減・',baseItem: true,},
  {s:'611181',v:'580',c:'児発４・地公体・開所減１・',baseItem: true,},
  {s:'611506',v:'493',c:'児発４・地公体・開所減１・評価減・',baseItem: true,},
  {s:'611729',v:'705',c:'児発４・地公体・開所減２・',baseItem: true,},
  {s:'611508',v:'599',c:'児発４・地公体・開所減２・評価減・',baseItem: true,},
  {s:'611183',v:'580',c:'児発４・地公体・未計画・',baseItem: true,},
  {s:'611510',v:'493',c:'児発４・地公体・未計画・評価減・',baseItem: true,},
  {s:'611185',v:'406',c:'児発４・地公体・未計画・開所減１・',baseItem: true,},
  {s:'611551',v:'345',c:'児発４・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611731',v:'493',c:'児発４・地公体・未計画・開所減２・',baseItem: true,},
  {s:'611553',v:'419',c:'児発４・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'611555',v:'415',c:'児発４・地公体・未計画２・',baseItem: true,},
  {s:'611557',v:'353',c:'児発４・地公体・未計画２・評価減・',baseItem: true,},
  {s:'611559',v:'291',c:'児発４・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'611561',v:'247',c:'児発４・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'611563',v:'353',c:'児発４・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'611565',v:'300',c:'児発４・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611191',v:'830',c:'児発５・',baseItem: true,},
  {s:'611567',v:'706',c:'児発５・評価減・',baseItem: true,},
  {s:'611193',v:'581',c:'児発５・開所減１・',baseItem: true,},
  {s:'611569',v:'494',c:'児発５・開所減１・評価減・',baseItem: true,},
  {s:'611733',v:'706',c:'児発５・開所減２・',baseItem: true,},
  {s:'611571',v:'600',c:'児発５・開所減２・評価減・',baseItem: true,},
  {s:'611195',v:'581',c:'児発５・未計画・',baseItem: true,},
  {s:'611573',v:'494',c:'児発５・未計画・評価減・',baseItem: true,},
  {s:'611197',v:'407',c:'児発５・未計画・開所減１・',baseItem: true,},
  {s:'611575',v:'346',c:'児発５・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611735',v:'494',c:'児発５・未計画・開所減２・',baseItem: true,},
  {s:'611577',v:'420',c:'児発５・未計画・開所減２・評価減・',baseItem: true,},
  {s:'611579',v:'415',c:'児発５・未計画２・',baseItem: true,},
  {s:'611581',v:'353',c:'児発５・未計画２・評価減・',baseItem: true,},
  {s:'611583',v:'291',c:'児発５・未計画２・開所減１・',baseItem: true,},
  {s:'611585',v:'247',c:'児発５・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'611587',v:'353',c:'児発５・未計画２・開所減２・',baseItem: true,},
  {s:'611589',v:'300',c:'児発５・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611199',v:'801',c:'児発５・地公体・',baseItem: true,},
  {s:'611591',v:'681',c:'児発５・地公体・評価減・',baseItem: true,},
  {s:'611201',v:'561',c:'児発５・地公体・開所減１・',baseItem: true,},
  {s:'611593',v:'477',c:'児発５・地公体・開所減１・評価減・',baseItem: true,},
  {s:'611737',v:'681',c:'児発５・地公体・開所減２・',baseItem: true,},
  {s:'611595',v:'579',c:'児発５・地公体・開所減２・評価減・',baseItem: true,},
  {s:'611203',v:'561',c:'児発５・地公体・未計画・',baseItem: true,},
  {s:'611597',v:'477',c:'児発５・地公体・未計画・評価減・',baseItem: true,},
  {s:'611205',v:'393',c:'児発５・地公体・未計画・開所減１・',baseItem: true,},
  {s:'611599',v:'334',c:'児発５・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611739',v:'477',c:'児発５・地公体・未計画・開所減２・',baseItem: true,},
  {s:'611601',v:'405',c:'児発５・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'611603',v:'401',c:'児発５・地公体・未計画２・',baseItem: true,},
  {s:'611605',v:'341',c:'児発５・地公体・未計画２・評価減・',baseItem: true,},
  {s:'611607',v:'281',c:'児発５・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'611609',v:'239',c:'児発５・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'611680',v:'341',c:'児発５・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'611682',v:'290',c:'児発５・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611211',v:'804',c:'児発６・',baseItem: true,},
  {s:'611684',v:'683',c:'児発６・評価減・',baseItem: true,},
  {s:'611213',v:'563',c:'児発６・開所減１・',baseItem: true,},
  {s:'611686',v:'479',c:'児発６・開所減１・評価減・',baseItem: true,},
  {s:'611741',v:'683',c:'児発６・開所減２・',baseItem: true,},
  {s:'611688',v:'581',c:'児発６・開所減２・評価減・',baseItem: true,},
  {s:'611215',v:'563',c:'児発６・未計画・',baseItem: true,},
  {s:'611690',v:'479',c:'児発６・未計画・評価減・',baseItem: true,},
  {s:'611217',v:'394',c:'児発６・未計画・開所減１・',baseItem: true,},
  {s:'611692',v:'335',c:'児発６・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611743',v:'479',c:'児発６・未計画・開所減２・',baseItem: true,},
  {s:'611694',v:'407',c:'児発６・未計画・開所減２・評価減・',baseItem: true,},
  {s:'611696',v:'402',c:'児発６・未計画２・',baseItem: true,},
  {s:'611698',v:'342',c:'児発６・未計画２・評価減・',baseItem: true,},
  {s:'611700',v:'281',c:'児発６・未計画２・開所減１・',baseItem: true,},
  {s:'611811',v:'239',c:'児発６・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'611813',v:'342',c:'児発６・未計画２・開所減２・',baseItem: true,},
  {s:'611815',v:'291',c:'児発６・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611219',v:'776',c:'児発６・地公体・',baseItem: true,},
  {s:'611817',v:'660',c:'児発６・地公体・評価減・',baseItem: true,},
  {s:'611221',v:'543',c:'児発６・地公体・開所減１・',baseItem: true,},
  {s:'611819',v:'462',c:'児発６・地公体・開所減１・評価減・',baseItem: true,},
  {s:'611745',v:'660',c:'児発６・地公体・開所減２・',baseItem: true,},
  {s:'611821',v:'561',c:'児発６・地公体・開所減２・評価減・',baseItem: true,},
  {s:'611223',v:'543',c:'児発６・地公体・未計画・',baseItem: true,},
  {s:'611823',v:'462',c:'児発６・地公体・未計画・評価減・',baseItem: true,},
  {s:'611225',v:'380',c:'児発６・地公体・未計画・開所減１・',baseItem: true,},
  {s:'611825',v:'323',c:'児発６・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611747',v:'462',c:'児発６・地公体・未計画・開所減２・',baseItem: true,},
  {s:'611827',v:'393',c:'児発６・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'611829',v:'388',c:'児発６・地公体・未計画２・',baseItem: true,},
  {s:'611831',v:'330',c:'児発６・地公体・未計画２・評価減・',baseItem: true,},
  {s:'611833',v:'272',c:'児発６・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'611835',v:'231',c:'児発６・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'611837',v:'330',c:'児発６・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'611839',v:'281',c:'児発６・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611231',v:'778',c:'児発７・',baseItem: true,},
  {s:'611841',v:'661',c:'児発７・評価減・',baseItem: true,},
  {s:'611233',v:'545',c:'児発７・開所減１・',baseItem: true,},
  {s:'611843',v:'463',c:'児発７・開所減１・評価減・',baseItem: true,},
  {s:'611749',v:'661',c:'児発７・開所減２・',baseItem: true,},
  {s:'611845',v:'562',c:'児発７・開所減２・評価減・',baseItem: true,},
  {s:'611235',v:'545',c:'児発７・未計画・',baseItem: true,},
  {s:'611847',v:'463',c:'児発７・未計画・評価減・',baseItem: true,},
  {s:'611237',v:'382',c:'児発７・未計画・開所減１・',baseItem: true,},
  {s:'611849',v:'325',c:'児発７・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611751',v:'463',c:'児発７・未計画・開所減２・',baseItem: true,},
  {s:'611851',v:'394',c:'児発７・未計画・開所減２・評価減・',baseItem: true,},
  {s:'611853',v:'389',c:'児発７・未計画２・',baseItem: true,},
  {s:'611855',v:'331',c:'児発７・未計画２・評価減・',baseItem: true,},
  {s:'611857',v:'272',c:'児発７・未計画２・開所減１・',baseItem: true,},
  {s:'611859',v:'231',c:'児発７・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'611861',v:'331',c:'児発７・未計画２・開所減２・',baseItem: true,},
  {s:'611863',v:'281',c:'児発７・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611239',v:'751',c:'児発７・地公体・',baseItem: true,},
  {s:'611865',v:'638',c:'児発７・地公体・評価減・',baseItem: true,},
  {s:'611241',v:'526',c:'児発７・地公体・開所減１・',baseItem: true,},
  {s:'611867',v:'447',c:'児発７・地公体・開所減１・評価減・',baseItem: true,},
  {s:'611753',v:'638',c:'児発７・地公体・開所減２・',baseItem: true,},
  {s:'611869',v:'542',c:'児発７・地公体・開所減２・評価減・',baseItem: true,},
  {s:'611243',v:'526',c:'児発７・地公体・未計画・',baseItem: true,},
  {s:'611871',v:'447',c:'児発７・地公体・未計画・評価減・',baseItem: true,},
  {s:'611245',v:'368',c:'児発７・地公体・未計画・開所減１・',baseItem: true,},
  {s:'611873',v:'313',c:'児発７・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611755',v:'447',c:'児発７・地公体・未計画・開所減２・',baseItem: true,},
  {s:'611875',v:'380',c:'児発７・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'611877',v:'376',c:'児発７・地公体・未計画２・',baseItem: true,},
  {s:'611879',v:'320',c:'児発７・地公体・未計画２・評価減・',baseItem: true,},
  {s:'611881',v:'263',c:'児発７・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'611883',v:'224',c:'児発７・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'611885',v:'320',c:'児発７・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'611887',v:'272',c:'児発７・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D757',v:'3384',c:'児発４９・',baseItem: true,},
  {s:'61D758',v:'2876',c:'児発４９・評価減・',baseItem: true,},
  {s:'61D759',v:'2369',c:'児発４９・開所減１・',baseItem: true,},
  {s:'61D760',v:'2014',c:'児発４９・開所減１・評価減・',baseItem: true,},
  {s:'61D761',v:'2876',c:'児発４９・開所減２・',baseItem: true,},
  {s:'61D762',v:'2445',c:'児発４９・開所減２・評価減・',baseItem: true,},
  {s:'61D763',v:'2369',c:'児発４９・未計画・',baseItem: true,},
  {s:'61D764',v:'2014',c:'児発４９・未計画・評価減・',baseItem: true,},
  {s:'61D765',v:'1658',c:'児発４９・未計画・開所減１・',baseItem: true,},
  {s:'61D766',v:'1409',c:'児発４９・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D767',v:'2014',c:'児発４９・未計画・開所減２・',baseItem: true,},
  {s:'61D768',v:'1712',c:'児発４９・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D769',v:'1692',c:'児発４９・未計画２・',baseItem: true,},
  {s:'61D770',v:'1438',c:'児発４９・未計画２・評価減・',baseItem: true,},
  {s:'61D771',v:'1184',c:'児発４９・未計画２・開所減１・',baseItem: true,},
  {s:'61D772',v:'1006',c:'児発４９・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D773',v:'1438',c:'児発４９・未計画２・開所減２・',baseItem: true,},
  {s:'61D774',v:'1222',c:'児発４９・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D775',v:'3266',c:'児発４９・地公体・',baseItem: true,},
  {s:'61D776',v:'2776',c:'児発４９・地公体・評価減・',baseItem: true,},
  {s:'61D777',v:'2286',c:'児発４９・地公体・開所減１・',baseItem: true,},
  {s:'61D778',v:'1943',c:'児発４９・地公体・開所減１・評価減・',baseItem: true,},
  {s:'61D779',v:'2776',c:'児発４９・地公体・開所減２・',baseItem: true,},
  {s:'61D780',v:'2360',c:'児発４９・地公体・開所減２・評価減・',baseItem: true,},
  {s:'61D781',v:'2286',c:'児発４９・地公体・未計画・',baseItem: true,},
  {s:'61D782',v:'1943',c:'児発４９・地公体・未計画・評価減・',baseItem: true,},
  {s:'61D783',v:'1600',c:'児発４９・地公体・未計画・開所減１・',baseItem: true,},
  {s:'61D784',v:'1360',c:'児発４９・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D785',v:'1943',c:'児発４９・地公体・未計画・開所減２・',baseItem: true,},
  {s:'61D786',v:'1652',c:'児発４９・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D787',v:'1633',c:'児発４９・地公体・未計画２・',baseItem: true,},
  {s:'61D788',v:'1388',c:'児発４９・地公体・未計画２・評価減・',baseItem: true,},
  {s:'61D789',v:'1143',c:'児発４９・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'61D790',v:'972',c:'児発４９・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D791',v:'1388',c:'児発４９・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'61D792',v:'1180',c:'児発４９・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D793',v:'3191',c:'児発５０・',baseItem: true,},
  {s:'61D794',v:'2712',c:'児発５０・評価減・',baseItem: true,},
  {s:'61D795',v:'2234',c:'児発５０・開所減１・',baseItem: true,},
  {s:'61D796',v:'1899',c:'児発５０・開所減１・評価減・',baseItem: true,},
  {s:'61D797',v:'2712',c:'児発５０・開所減２・',baseItem: true,},
  {s:'61D798',v:'2305',c:'児発５０・開所減２・評価減・',baseItem: true,},
  {s:'61D799',v:'2234',c:'児発５０・未計画・',baseItem: true,},
  {s:'61D800',v:'1899',c:'児発５０・未計画・評価減・',baseItem: true,},
  {s:'61D801',v:'1564',c:'児発５０・未計画・開所減１・',baseItem: true,},
  {s:'61D802',v:'1329',c:'児発５０・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D803',v:'1899',c:'児発５０・未計画・開所減２・',baseItem: true,},
  {s:'61D804',v:'1614',c:'児発５０・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D805',v:'1596',c:'児発５０・未計画２・',baseItem: true,},
  {s:'61D806',v:'1357',c:'児発５０・未計画２・評価減・',baseItem: true,},
  {s:'61D807',v:'1117',c:'児発５０・未計画２・開所減１・',baseItem: true,},
  {s:'61D808',v:'949',c:'児発５０・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D809',v:'1357',c:'児発５０・未計画２・開所減２・',baseItem: true,},
  {s:'61D810',v:'1153',c:'児発５０・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D811',v:'3079',c:'児発５０・地公体・',baseItem: true,},
  {s:'61D812',v:'2617',c:'児発５０・地公体・評価減・',baseItem: true,},
  {s:'61D813',v:'2155',c:'児発５０・地公体・開所減１・',baseItem: true,},
  {s:'61D814',v:'1832',c:'児発５０・地公体・開所減１・評価減・',baseItem: true,},
  {s:'61D815',v:'2617',c:'児発５０・地公体・開所減２・',baseItem: true,},
  {s:'61D816',v:'2224',c:'児発５０・地公体・開所減２・評価減・',baseItem: true,},
  {s:'61D817',v:'2155',c:'児発５０・地公体・未計画・',baseItem: true,},
  {s:'61D818',v:'1832',c:'児発５０・地公体・未計画・評価減・',baseItem: true,},
  {s:'61D819',v:'1509',c:'児発５０・地公体・未計画・開所減１・',baseItem: true,},
  {s:'61D820',v:'1283',c:'児発５０・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D821',v:'1832',c:'児発５０・地公体・未計画・開所減２・',baseItem: true,},
  {s:'61D822',v:'1557',c:'児発５０・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D823',v:'1540',c:'児発５０・地公体・未計画２・',baseItem: true,},
  {s:'61D824',v:'1309',c:'児発５０・地公体・未計画２・評価減・',baseItem: true,},
  {s:'61D825',v:'1078',c:'児発５０・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'61D826',v:'916',c:'児発５０・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D827',v:'1309',c:'児発５０・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'61D828',v:'1113',c:'児発５０・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D829',v:'3075',c:'児発５１・',baseItem: true,},
  {s:'61D830',v:'2614',c:'児発５１・評価減・',baseItem: true,},
  {s:'61D831',v:'2153',c:'児発５１・開所減１・',baseItem: true,},
  {s:'61D832',v:'1830',c:'児発５１・開所減１・評価減・',baseItem: true,},
  {s:'61D833',v:'2614',c:'児発５１・開所減２・',baseItem: true,},
  {s:'61D834',v:'2222',c:'児発５１・開所減２・評価減・',baseItem: true,},
  {s:'61D835',v:'2153',c:'児発５１・未計画・',baseItem: true,},
  {s:'61D836',v:'1830',c:'児発５１・未計画・評価減・',baseItem: true,},
  {s:'61D837',v:'1507',c:'児発５１・未計画・開所減１・',baseItem: true,},
  {s:'61D838',v:'1281',c:'児発５１・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D839',v:'1830',c:'児発５１・未計画・開所減２・',baseItem: true,},
  {s:'61D840',v:'1556',c:'児発５１・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D841',v:'1538',c:'児発５１・未計画２・',baseItem: true,},
  {s:'61D842',v:'1307',c:'児発５１・未計画２・評価減・',baseItem: true,},
  {s:'61D843',v:'1077',c:'児発５１・未計画２・開所減１・',baseItem: true,},
  {s:'61D844',v:'915',c:'児発５１・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D845',v:'1307',c:'児発５１・未計画２・開所減２・',baseItem: true,},
  {s:'61D846',v:'1111',c:'児発５１・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D847',v:'2967',c:'児発５１・地公体・',baseItem: true,},
  {s:'61D848',v:'2522',c:'児発５１・地公体・評価減・',baseItem: true,},
  {s:'61D849',v:'2077',c:'児発５１・地公体・開所減１・',baseItem: true,},
  {s:'61D850',v:'1765',c:'児発５１・地公体・開所減１・評価減・',baseItem: true,},
  {s:'61D851',v:'2522',c:'児発５１・地公体・開所減２・',baseItem: true,},
  {s:'61D852',v:'2144',c:'児発５１・地公体・開所減２・評価減・',baseItem: true,},
  {s:'61D853',v:'2077',c:'児発５１・地公体・未計画・',baseItem: true,},
  {s:'61D854',v:'1765',c:'児発５１・地公体・未計画・評価減・',baseItem: true,},
  {s:'61D855',v:'1454',c:'児発５１・地公体・未計画・開所減１・',baseItem: true,},
  {s:'61D856',v:'1236',c:'児発５１・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D857',v:'1765',c:'児発５１・地公体・未計画・開所減２・',baseItem: true,},
  {s:'61D858',v:'1500',c:'児発５１・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D859',v:'1484',c:'児発５１・地公体・未計画２・',baseItem: true,},
  {s:'61D860',v:'1261',c:'児発５１・地公体・未計画２・評価減・',baseItem: true,},
  {s:'61D861',v:'1039',c:'児発５１・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'61D862',v:'883',c:'児発５１・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D863',v:'1261',c:'児発５１・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'61D864',v:'1072',c:'児発５１・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D865',v:'2975',c:'児発５２・',baseItem: true,},
  {s:'61D866',v:'2529',c:'児発５２・評価減・',baseItem: true,},
  {s:'61D867',v:'2083',c:'児発５２・開所減１・',baseItem: true,},
  {s:'61D868',v:'1771',c:'児発５２・開所減１・評価減・',baseItem: true,},
  {s:'61D869',v:'2529',c:'児発５２・開所減２・',baseItem: true,},
  {s:'61D870',v:'2150',c:'児発５２・開所減２・評価減・',baseItem: true,},
  {s:'61D871',v:'2083',c:'児発５２・未計画・',baseItem: true,},
  {s:'61D872',v:'1771',c:'児発５２・未計画・評価減・',baseItem: true,},
  {s:'61D873',v:'1458',c:'児発５２・未計画・開所減１・',baseItem: true,},
  {s:'61D874',v:'1239',c:'児発５２・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D875',v:'1771',c:'児発５２・未計画・開所減２・',baseItem: true,},
  {s:'61D876',v:'1505',c:'児発５２・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D877',v:'1488',c:'児発５２・未計画２・',baseItem: true,},
  {s:'61D878',v:'1265',c:'児発５２・未計画２・評価減・',baseItem: true,},
  {s:'61D879',v:'1042',c:'児発５２・未計画２・開所減１・',baseItem: true,},
  {s:'61D880',v:'886',c:'児発５２・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D881',v:'1265',c:'児発５２・未計画２・開所減２・',baseItem: true,},
  {s:'61D882',v:'1075',c:'児発５２・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D883',v:'2871',c:'児発５２・地公体・',baseItem: true,},
  {s:'61D884',v:'2440',c:'児発５２・地公体・評価減・',baseItem: true,},
  {s:'61D885',v:'2010',c:'児発５２・地公体・開所減１・',baseItem: true,},
  {s:'61D886',v:'1709',c:'児発５２・地公体・開所減１・評価減・',baseItem: true,},
  {s:'61D887',v:'2440',c:'児発５２・地公体・開所減２・',baseItem: true,},
  {s:'61D888',v:'2074',c:'児発５２・地公体・開所減２・評価減・',baseItem: true,},
  {s:'61D889',v:'2010',c:'児発５２・地公体・未計画・',baseItem: true,},
  {s:'61D890',v:'1709',c:'児発５２・地公体・未計画・評価減・',baseItem: true,},
  {s:'61D891',v:'1407',c:'児発５２・地公体・未計画・開所減１・',baseItem: true,},
  {s:'61D892',v:'1196',c:'児発５２・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D893',v:'1709',c:'児発５２・地公体・未計画・開所減２・',baseItem: true,},
  {s:'61D894',v:'1453',c:'児発５２・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D895',v:'1436',c:'児発５２・地公体・未計画２・',baseItem: true,},
  {s:'61D896',v:'1221',c:'児発５２・地公体・未計画２・評価減・',baseItem: true,},
  {s:'61D897',v:'1005',c:'児発５２・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'61D898',v:'854',c:'児発５２・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D899',v:'1221',c:'児発５２・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'61D900',v:'1038',c:'児発５２・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D901',v:'2384',c:'児発５３・',baseItem: true,},
  {s:'61D902',v:'2026',c:'児発５３・評価減・',baseItem: true,},
  {s:'61D903',v:'1669',c:'児発５３・開所減１・',baseItem: true,},
  {s:'61D904',v:'1419',c:'児発５３・開所減１・評価減・',baseItem: true,},
  {s:'61D905',v:'2026',c:'児発５３・開所減２・',baseItem: true,},
  {s:'61D906',v:'1722',c:'児発５３・開所減２・評価減・',baseItem: true,},
  {s:'61D907',v:'1669',c:'児発５３・未計画・',baseItem: true,},
  {s:'61D908',v:'1419',c:'児発５３・未計画・評価減・',baseItem: true,},
  {s:'61D909',v:'1168',c:'児発５３・未計画・開所減１・',baseItem: true,},
  {s:'61D910',v:'993',c:'児発５３・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D911',v:'1419',c:'児発５３・未計画・開所減２・',baseItem: true,},
  {s:'61D912',v:'1206',c:'児発５３・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D913',v:'1192',c:'児発５３・未計画２・',baseItem: true,},
  {s:'61D914',v:'1013',c:'児発５３・未計画２・評価減・',baseItem: true,},
  {s:'61D915',v:'834',c:'児発５３・未計画２・開所減１・',baseItem: true,},
  {s:'61D916',v:'709',c:'児発５３・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D917',v:'1013',c:'児発５３・未計画２・開所減２・',baseItem: true,},
  {s:'61D918',v:'861',c:'児発５３・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D919',v:'2301',c:'児発５３・地公体・',baseItem: true,},
  {s:'61D920',v:'1956',c:'児発５３・地公体・評価減・',baseItem: true,},
  {s:'61D921',v:'1611',c:'児発５３・地公体・開所減１・',baseItem: true,},
  {s:'61D922',v:'1369',c:'児発５３・地公体・開所減１・評価減・',baseItem: true,},
  {s:'61D923',v:'1956',c:'児発５３・地公体・開所減２・',baseItem: true,},
  {s:'61D924',v:'1663',c:'児発５３・地公体・開所減２・評価減・',baseItem: true,},
  {s:'61D925',v:'1611',c:'児発５３・地公体・未計画・',baseItem: true,},
  {s:'61D926',v:'1369',c:'児発５３・地公体・未計画・評価減・',baseItem: true,},
  {s:'61D927',v:'1128',c:'児発５３・地公体・未計画・開所減１・',baseItem: true,},
  {s:'61D928',v:'959',c:'児発５３・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D929',v:'1369',c:'児発５３・地公体・未計画・開所減２・',baseItem: true,},
  {s:'61D930',v:'1164',c:'児発５３・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D931',v:'1151',c:'児発５３・地公体・未計画２・',baseItem: true,},
  {s:'61D932',v:'978',c:'児発５３・地公体・未計画２・評価減・',baseItem: true,},
  {s:'61D933',v:'806',c:'児発５３・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'61D934',v:'685',c:'児発５３・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D935',v:'978',c:'児発５３・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'61D936',v:'831',c:'児発５３・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D937',v:'2191',c:'児発５４・',baseItem: true,},
  {s:'61D938',v:'1862',c:'児発５４・評価減・',baseItem: true,},
  {s:'61D939',v:'1534',c:'児発５４・開所減１・',baseItem: true,},
  {s:'61D940',v:'1304',c:'児発５４・開所減１・評価減・',baseItem: true,},
  {s:'61D941',v:'1862',c:'児発５４・開所減２・',baseItem: true,},
  {s:'61D942',v:'1583',c:'児発５４・開所減２・評価減・',baseItem: true,},
  {s:'61D943',v:'1534',c:'児発５４・未計画・',baseItem: true,},
  {s:'61D944',v:'1304',c:'児発５４・未計画・評価減・',baseItem: true,},
  {s:'61D945',v:'1074',c:'児発５４・未計画・開所減１・',baseItem: true,},
  {s:'61D946',v:'913',c:'児発５４・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D947',v:'1304',c:'児発５４・未計画・開所減２・',baseItem: true,},
  {s:'61D948',v:'1108',c:'児発５４・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D949',v:'1096',c:'児発５４・未計画２・',baseItem: true,},
  {s:'61D950',v:'932',c:'児発５４・未計画２・評価減・',baseItem: true,},
  {s:'61D951',v:'767',c:'児発５４・未計画２・開所減１・',baseItem: true,},
  {s:'61D952',v:'652',c:'児発５４・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D953',v:'932',c:'児発５４・未計画２・開所減２・',baseItem: true,},
  {s:'61D954',v:'792',c:'児発５４・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D955',v:'2114',c:'児発５４・地公体・',baseItem: true,},
  {s:'61D956',v:'1797',c:'児発５４・地公体・評価減・',baseItem: true,},
  {s:'61D957',v:'1480',c:'児発５４・地公体・開所減１・',baseItem: true,},
  {s:'61D958',v:'1258',c:'児発５４・地公体・開所減１・評価減・',baseItem: true,},
  {s:'61D959',v:'1797',c:'児発５４・地公体・開所減２・',baseItem: true,},
  {s:'61D960',v:'1527',c:'児発５４・地公体・開所減２・評価減・',baseItem: true,},
  {s:'61D961',v:'1480',c:'児発５４・地公体・未計画・',baseItem: true,},
  {s:'61D962',v:'1258',c:'児発５４・地公体・未計画・評価減・',baseItem: true,},
  {s:'61D963',v:'1036',c:'児発５４・地公体・未計画・開所減１・',baseItem: true,},
  {s:'61D964',v:'881',c:'児発５４・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D965',v:'1258',c:'児発５４・地公体・未計画・開所減２・',baseItem: true,},
  {s:'61D966',v:'1069',c:'児発５４・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D967',v:'1057',c:'児発５４・地公体・未計画２・',baseItem: true,},
  {s:'61D968',v:'898',c:'児発５４・地公体・未計画２・評価減・',baseItem: true,},
  {s:'61D969',v:'740',c:'児発５４・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'61D970',v:'629',c:'児発５４・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D971',v:'898',c:'児発５４・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'61D972',v:'763',c:'児発５４・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D973',v:'2075',c:'児発５５・',baseItem: true,},
  {s:'61D974',v:'1764',c:'児発５５・評価減・',baseItem: true,},
  {s:'61D975',v:'1453',c:'児発５５・開所減１・',baseItem: true,},
  {s:'61D976',v:'1235',c:'児発５５・開所減１・評価減・',baseItem: true,},
  {s:'61D977',v:'1764',c:'児発５５・開所減２・',baseItem: true,},
  {s:'61D978',v:'1499',c:'児発５５・開所減２・評価減・',baseItem: true,},
  {s:'61D979',v:'1453',c:'児発５５・未計画・',baseItem: true,},
  {s:'61D980',v:'1235',c:'児発５５・未計画・評価減・',baseItem: true,},
  {s:'61D981',v:'1017',c:'児発５５・未計画・開所減１・',baseItem: true,},
  {s:'61D982',v:'864',c:'児発５５・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61D983',v:'1235',c:'児発５５・未計画・開所減２・',baseItem: true,},
  {s:'61D984',v:'1050',c:'児発５５・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61D985',v:'1038',c:'児発５５・未計画２・',baseItem: true,},
  {s:'61D986',v:'882',c:'児発５５・未計画２・評価減・',baseItem: true,},
  {s:'61D987',v:'727',c:'児発５５・未計画２・開所減１・',baseItem: true,},
  {s:'61D988',v:'618',c:'児発５５・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61D989',v:'882',c:'児発５５・未計画２・開所減２・',baseItem: true,},
  {s:'61D990',v:'750',c:'児発５５・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61D991',v:'2002',c:'児発５５・地公体・',baseItem: true,},
  {s:'61D992',v:'1702',c:'児発５５・地公体・評価減・',baseItem: true,},
  {s:'61D993',v:'1401',c:'児発５５・地公体・開所減１・',baseItem: true,},
  {s:'61D994',v:'1191',c:'児発５５・地公体・開所減１・評価減・',baseItem: true,},
  {s:'61D995',v:'1702',c:'児発５５・地公体・開所減２・',baseItem: true,},
  {s:'61D996',v:'1447',c:'児発５５・地公体・開所減２・評価減・',baseItem: true,},
  {s:'61D997',v:'1401',c:'児発５５・地公体・未計画・',baseItem: true,},
  {s:'61D998',v:'1191',c:'児発５５・地公体・未計画・評価減・',baseItem: true,},
  {s:'61D999',v:'981',c:'児発５５・地公体・未計画・開所減１・',baseItem: true,},
  {s:'61DA00',v:'834',c:'児発５５・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61DA01',v:'1191',c:'児発５５・地公体・未計画・開所減２・',baseItem: true,},
  {s:'61DA02',v:'1012',c:'児発５５・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61DA03',v:'1001',c:'児発５５・地公体・未計画２・',baseItem: true,},
  {s:'61DA04',v:'851',c:'児発５５・地公体・未計画２・評価減・',baseItem: true,},
  {s:'61DA05',v:'701',c:'児発５５・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'61DA06',v:'596',c:'児発５５・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61DA07',v:'851',c:'児発５５・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'61DA08',v:'723',c:'児発５５・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61DA09',v:'1975',c:'児発５６・',baseItem: true,},
  {s:'61DA10',v:'1679',c:'児発５６・評価減・',baseItem: true,},
  {s:'61DA11',v:'1383',c:'児発５６・開所減１・',baseItem: true,},
  {s:'61DA12',v:'1176',c:'児発５６・開所減１・評価減・',baseItem: true,},
  {s:'61DA13',v:'1679',c:'児発５６・開所減２・',baseItem: true,},
  {s:'61DA14',v:'1427',c:'児発５６・開所減２・評価減・',baseItem: true,},
  {s:'61DA15',v:'1383',c:'児発５６・未計画・',baseItem: true,},
  {s:'61DA16',v:'1176',c:'児発５６・未計画・評価減・',baseItem: true,},
  {s:'61DA17',v:'968',c:'児発５６・未計画・開所減１・',baseItem: true,},
  {s:'61DA18',v:'823',c:'児発５６・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61DA19',v:'1176',c:'児発５６・未計画・開所減２・',baseItem: true,},
  {s:'61DA20',v:'1000',c:'児発５６・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61DA21',v:'988',c:'児発５６・未計画２・',baseItem: true,},
  {s:'61DA22',v:'840',c:'児発５６・未計画２・評価減・',baseItem: true,},
  {s:'61DA23',v:'692',c:'児発５６・未計画２・開所減１・',baseItem: true,},
  {s:'61DA24',v:'588',c:'児発５６・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61DA25',v:'840',c:'児発５６・未計画２・開所減２・',baseItem: true,},
  {s:'61DA26',v:'714',c:'児発５６・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61DA27',v:'1906',c:'児発５６・地公体・',baseItem: true,},
  {s:'61DA28',v:'1620',c:'児発５６・地公体・評価減・',baseItem: true,},
  {s:'61DA29',v:'1334',c:'児発５６・地公体・開所減１・',baseItem: true,},
  {s:'61DA30',v:'1134',c:'児発５６・地公体・開所減１・評価減・',baseItem: true,},
  {s:'61DA31',v:'1620',c:'児発５６・地公体・開所減２・',baseItem: true,},
  {s:'61DA32',v:'1377',c:'児発５６・地公体・開所減２・評価減・',baseItem: true,},
  {s:'61DA33',v:'1334',c:'児発５６・地公体・未計画・',baseItem: true,},
  {s:'61DA34',v:'1134',c:'児発５６・地公体・未計画・評価減・',baseItem: true,},
  {s:'61DA35',v:'934',c:'児発５６・地公体・未計画・開所減１・',baseItem: true,},
  {s:'61DA36',v:'794',c:'児発５６・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61DA37',v:'1134',c:'児発５６・地公体・未計画・開所減２・',baseItem: true,},
  {s:'61DA38',v:'964',c:'児発５６・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61DA39',v:'953',c:'児発５６・地公体・未計画２・',baseItem: true,},
  {s:'61DA40',v:'810',c:'児発５６・地公体・未計画２・評価減・',baseItem: true,},
  {s:'61DA41',v:'667',c:'児発５６・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'61DA42',v:'567',c:'児発５６・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61DA43',v:'810',c:'児発５６・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'61DA44',v:'689',c:'児発５６・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61DA45',v:'2051',c:'児発５７・',baseItem: true,},
  {s:'61DA46',v:'1743',c:'児発５７・評価減・',baseItem: true,},
  {s:'61DA47',v:'1436',c:'児発５７・開所減１・',baseItem: true,},
  {s:'61DA48',v:'1221',c:'児発５７・開所減１・評価減・',baseItem: true,},
  {s:'61DA49',v:'1743',c:'児発５７・開所減２・',baseItem: true,},
  {s:'61DA50',v:'1482',c:'児発５７・開所減２・評価減・',baseItem: true,},
  {s:'61DA51',v:'1436',c:'児発５７・未計画・',baseItem: true,},
  {s:'61DA52',v:'1221',c:'児発５７・未計画・評価減・',baseItem: true,},
  {s:'61DA53',v:'1005',c:'児発５７・未計画・開所減１・',baseItem: true,},
  {s:'61DA54',v:'854',c:'児発５７・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61DA55',v:'1221',c:'児発５７・未計画・開所減２・',baseItem: true,},
  {s:'61DA56',v:'1038',c:'児発５７・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61DA57',v:'1026',c:'児発５７・未計画２・',baseItem: true,},
  {s:'61DA58',v:'872',c:'児発５７・未計画２・評価減・',baseItem: true,},
  {s:'61DA59',v:'718',c:'児発５７・未計画２・開所減１・',baseItem: true,},
  {s:'61DA60',v:'610',c:'児発５７・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61DA61',v:'872',c:'児発５７・未計画２・開所減２・',baseItem: true,},
  {s:'61DA62',v:'741',c:'児発５７・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61DA63',v:'1979',c:'児発５７・地公体・',baseItem: true,},
  {s:'61DA64',v:'1682',c:'児発５７・地公体・評価減・',baseItem: true,},
  {s:'61DA65',v:'1385',c:'児発５７・地公体・開所減１・',baseItem: true,},
  {s:'61DA66',v:'1177',c:'児発５７・地公体・開所減１・評価減・',baseItem: true,},
  {s:'61DA67',v:'1682',c:'児発５７・地公体・開所減２・',baseItem: true,},
  {s:'61DA68',v:'1430',c:'児発５７・地公体・開所減２・評価減・',baseItem: true,},
  {s:'61DA69',v:'1385',c:'児発５７・地公体・未計画・',baseItem: true,},
  {s:'61DA70',v:'1177',c:'児発５７・地公体・未計画・評価減・',baseItem: true,},
  {s:'61DA71',v:'970',c:'児発５７・地公体・未計画・開所減１・',baseItem: true,},
  {s:'61DA72',v:'825',c:'児発５７・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61DA73',v:'1177',c:'児発５７・地公体・未計画・開所減２・',baseItem: true,},
  {s:'61DA74',v:'1000',c:'児発５７・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61DA75',v:'990',c:'児発５７・地公体・未計画２・',baseItem: true,},
  {s:'61DA76',v:'842',c:'児発５７・地公体・未計画２・評価減・',baseItem: true,},
  {s:'61DA77',v:'693',c:'児発５７・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'61DA78',v:'589',c:'児発５７・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61DA79',v:'842',c:'児発５７・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'61DA80',v:'716',c:'児発５７・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61DA81',v:'1858',c:'児発５８・',baseItem: true,},
  {s:'61DA82',v:'1579',c:'児発５８・評価減・',baseItem: true,},
  {s:'61DA83',v:'1301',c:'児発５８・開所減１・',baseItem: true,},
  {s:'61DA84',v:'1106',c:'児発５８・開所減１・評価減・',baseItem: true,},
  {s:'61DA85',v:'1579',c:'児発５８・開所減２・',baseItem: true,},
  {s:'61DA86',v:'1342',c:'児発５８・開所減２・評価減・',baseItem: true,},
  {s:'61DA87',v:'1301',c:'児発５８・未計画・',baseItem: true,},
  {s:'61DA88',v:'1106',c:'児発５８・未計画・評価減・',baseItem: true,},
  {s:'61DA89',v:'911',c:'児発５８・未計画・開所減１・',baseItem: true,},
  {s:'61DA90',v:'774',c:'児発５８・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61DA91',v:'1106',c:'児発５８・未計画・開所減２・',baseItem: true,},
  {s:'61DA92',v:'940',c:'児発５８・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61DA93',v:'929',c:'児発５８・未計画２・',baseItem: true,},
  {s:'61DA94',v:'790',c:'児発５８・未計画２・評価減・',baseItem: true,},
  {s:'61DA95',v:'650',c:'児発５８・未計画２・開所減１・',baseItem: true,},
  {s:'61DA96',v:'553',c:'児発５８・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61DA97',v:'790',c:'児発５８・未計画２・開所減２・',baseItem: true,},
  {s:'61DA98',v:'672',c:'児発５８・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61DA99',v:'1793',c:'児発５８・地公体・',baseItem: true,},
  {s:'61DB00',v:'1524',c:'児発５８・地公体・評価減・',baseItem: true,},
  {s:'61DB01',v:'1255',c:'児発５８・地公体・開所減１・',baseItem: true,},
  {s:'61DB02',v:'1067',c:'児発５８・地公体・開所減１・評価減・',baseItem: true,},
  {s:'61DB03',v:'1524',c:'児発５８・地公体・開所減２・',baseItem: true,},
  {s:'61DB04',v:'1295',c:'児発５８・地公体・開所減２・評価減・',baseItem: true,},
  {s:'61DB05',v:'1255',c:'児発５８・地公体・未計画・',baseItem: true,},
  {s:'61DB06',v:'1067',c:'児発５８・地公体・未計画・評価減・',baseItem: true,},
  {s:'61DB07',v:'879',c:'児発５８・地公体・未計画・開所減１・',baseItem: true,},
  {s:'61DB08',v:'747',c:'児発５８・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61DB09',v:'1067',c:'児発５８・地公体・未計画・開所減２・',baseItem: true,},
  {s:'61DB10',v:'907',c:'児発５８・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61DB11',v:'897',c:'児発５８・地公体・未計画２・',baseItem: true,},
  {s:'61DB12',v:'762',c:'児発５８・地公体・未計画２・評価減・',baseItem: true,},
  {s:'61DB13',v:'628',c:'児発５８・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'61DB14',v:'534',c:'児発５８・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61DB15',v:'762',c:'児発５８・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'61DB16',v:'648',c:'児発５８・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61DB17',v:'1742',c:'児発５９・',baseItem: true,},
  {s:'61DB18',v:'1481',c:'児発５９・評価減・',baseItem: true,},
  {s:'61DB19',v:'1219',c:'児発５９・開所減１・',baseItem: true,},
  {s:'61DB20',v:'1036',c:'児発５９・開所減１・評価減・',baseItem: true,},
  {s:'61DB21',v:'1481',c:'児発５９・開所減２・',baseItem: true,},
  {s:'61DB22',v:'1259',c:'児発５９・開所減２・評価減・',baseItem: true,},
  {s:'61DB23',v:'1219',c:'児発５９・未計画・',baseItem: true,},
  {s:'61DB24',v:'1036',c:'児発５９・未計画・評価減・',baseItem: true,},
  {s:'61DB25',v:'853',c:'児発５９・未計画・開所減１・',baseItem: true,},
  {s:'61DB26',v:'725',c:'児発５９・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61DB27',v:'1036',c:'児発５９・未計画・開所減２・',baseItem: true,},
  {s:'61DB28',v:'881',c:'児発５９・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61DB29',v:'871',c:'児発５９・未計画２・',baseItem: true,},
  {s:'61DB30',v:'740',c:'児発５９・未計画２・評価減・',baseItem: true,},
  {s:'61DB31',v:'610',c:'児発５９・未計画２・開所減１・',baseItem: true,},
  {s:'61DB32',v:'519',c:'児発５９・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61DB33',v:'740',c:'児発５９・未計画２・開所減２・',baseItem: true,},
  {s:'61DB34',v:'629',c:'児発５９・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61DB35',v:'1681',c:'児発５９・地公体・',baseItem: true,},
  {s:'61DB36',v:'1429',c:'児発５９・地公体・評価減・',baseItem: true,},
  {s:'61DB37',v:'1177',c:'児発５９・地公体・開所減１・',baseItem: true,},
  {s:'61DB38',v:'1000',c:'児発５９・地公体・開所減１・評価減・',baseItem: true,},
  {s:'61DB39',v:'1429',c:'児発５９・地公体・開所減２・',baseItem: true,},
  {s:'61DB40',v:'1215',c:'児発５９・地公体・開所減２・評価減・',baseItem: true,},
  {s:'61DB41',v:'1177',c:'児発５９・地公体・未計画・',baseItem: true,},
  {s:'61DB42',v:'1000',c:'児発５９・地公体・未計画・評価減・',baseItem: true,},
  {s:'61DB43',v:'824',c:'児発５９・地公体・未計画・開所減１・',baseItem: true,},
  {s:'61DB44',v:'700',c:'児発５９・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61DB45',v:'1000',c:'児発５９・地公体・未計画・開所減２・',baseItem: true,},
  {s:'61DB46',v:'850',c:'児発５９・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61DB47',v:'841',c:'児発５９・地公体・未計画２・',baseItem: true,},
  {s:'61DB48',v:'715',c:'児発５９・地公体・未計画２・評価減・',baseItem: true,},
  {s:'61DB49',v:'589',c:'児発５９・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'61DB50',v:'501',c:'児発５９・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61DB51',v:'715',c:'児発５９・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'61DB52',v:'608',c:'児発５９・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61DB53',v:'1642',c:'児発６０・',baseItem: true,},
  {s:'61DB54',v:'1396',c:'児発６０・評価減・',baseItem: true,},
  {s:'61DB55',v:'1149',c:'児発６０・開所減１・',baseItem: true,},
  {s:'61DB56',v:'977',c:'児発６０・開所減１・評価減・',baseItem: true,},
  {s:'61DB57',v:'1396',c:'児発６０・開所減２・',baseItem: true,},
  {s:'61DB58',v:'1187',c:'児発６０・開所減２・評価減・',baseItem: true,},
  {s:'61DB59',v:'1149',c:'児発６０・未計画・',baseItem: true,},
  {s:'61DB60',v:'977',c:'児発６０・未計画・評価減・',baseItem: true,},
  {s:'61DB61',v:'804',c:'児発６０・未計画・開所減１・',baseItem: true,},
  {s:'61DB62',v:'683',c:'児発６０・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61DB63',v:'977',c:'児発６０・未計画・開所減２・',baseItem: true,},
  {s:'61DB64',v:'830',c:'児発６０・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61DB65',v:'821',c:'児発６０・未計画２・',baseItem: true,},
  {s:'61DB66',v:'698',c:'児発６０・未計画２・評価減・',baseItem: true,},
  {s:'61DB67',v:'575',c:'児発６０・未計画２・開所減１・',baseItem: true,},
  {s:'61DB68',v:'489',c:'児発６０・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61DB69',v:'698',c:'児発６０・未計画２・開所減２・',baseItem: true,},
  {s:'61DB70',v:'593',c:'児発６０・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61DB71',v:'1585',c:'児発６０・地公体・',baseItem: true,},
  {s:'61DB72',v:'1347',c:'児発６０・地公体・評価減・',baseItem: true,},
  {s:'61DB73',v:'1110',c:'児発６０・地公体・開所減１・',baseItem: true,},
  {s:'61DB74',v:'944',c:'児発６０・地公体・開所減１・評価減・',baseItem: true,},
  {s:'61DB75',v:'1347',c:'児発６０・地公体・開所減２・',baseItem: true,},
  {s:'61DB76',v:'1145',c:'児発６０・地公体・開所減２・評価減・',baseItem: true,},
  {s:'61DB77',v:'1110',c:'児発６０・地公体・未計画・',baseItem: true,},
  {s:'61DB78',v:'944',c:'児発６０・地公体・未計画・評価減・',baseItem: true,},
  {s:'61DB79',v:'777',c:'児発６０・地公体・未計画・開所減１・',baseItem: true,},
  {s:'61DB80',v:'660',c:'児発６０・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61DB81',v:'944',c:'児発６０・地公体・未計画・開所減２・',baseItem: true,},
  {s:'61DB82',v:'802',c:'児発６０・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61DB83',v:'793',c:'児発６０・地公体・未計画２・',baseItem: true,},
  {s:'61DB84',v:'674',c:'児発６０・地公体・未計画２・評価減・',baseItem: true,},
  {s:'61DB85',v:'555',c:'児発６０・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'61DB86',v:'472',c:'児発６０・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61DB87',v:'674',c:'児発６０・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'61DB88',v:'573',c:'児発６０・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611311',v:'1384',c:'児発８・',baseItem: true,},
  {s:'611891',v:'1176',c:'児発８・評価減・',baseItem: true,},
  {s:'611312',v:'969',c:'児発８・開所減１・',baseItem: true,},
  {s:'611894',v:'824',c:'児発８・開所減１・評価減・',baseItem: true,},
  {s:'611757',v:'1176',c:'児発８・開所減２・',baseItem: true,},
  {s:'611897',v:'1000',c:'児発８・開所減２・評価減・',baseItem: true,},
  {s:'611313',v:'969',c:'児発８・未計画・',baseItem: true,},
  {s:'611900',v:'824',c:'児発８・未計画・評価減・',baseItem: true,},
  {s:'611314',v:'678',c:'児発８・未計画・開所減１・',baseItem: true,},
  {s:'611903',v:'576',c:'児発８・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611758',v:'824',c:'児発８・未計画・開所減２・',baseItem: true,},
  {s:'611906',v:'700',c:'児発８・未計画・開所減２・評価減・',baseItem: true,},
  {s:'611908',v:'692',c:'児発８・未計画２・',baseItem: true,},
  {s:'611910',v:'588',c:'児発８・未計画２・評価減・',baseItem: true,},
  {s:'611912',v:'484',c:'児発８・未計画２・開所減１・',baseItem: true,},
  {s:'611914',v:'411',c:'児発８・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'611916',v:'588',c:'児発８・未計画２・開所減２・',baseItem: true,},
  {s:'611918',v:'500',c:'児発８・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611315',v:'1336',c:'児発８・地公体・',baseItem: true,},
  {s:'611921',v:'1136',c:'児発８・地公体・評価減・',baseItem: true,},
  {s:'611316',v:'935',c:'児発８・地公体・開所減１・',baseItem: true,},
  {s:'611924',v:'795',c:'児発８・地公体・開所減１・評価減・',baseItem: true,},
  {s:'611759',v:'1136',c:'児発８・地公体・開所減２・',baseItem: true,},
  {s:'611927',v:'966',c:'児発８・地公体・開所減２・評価減・',baseItem: true,},
  {s:'611317',v:'935',c:'児発８・地公体・未計画・',baseItem: true,},
  {s:'611930',v:'795',c:'児発８・地公体・未計画・評価減・',baseItem: true,},
  {s:'611318',v:'655',c:'児発８・地公体・未計画・開所減１・',baseItem: true,},
  {s:'611933',v:'557',c:'児発８・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611760',v:'795',c:'児発８・地公体・未計画・開所減２・',baseItem: true,},
  {s:'611936',v:'676',c:'児発８・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'611938',v:'668',c:'児発８・地公体・未計画２・',baseItem: true,},
  {s:'611940',v:'568',c:'児発８・地公体・未計画２・評価減・',baseItem: true,},
  {s:'611942',v:'468',c:'児発８・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'611944',v:'398',c:'児発８・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'611946',v:'568',c:'児発８・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'611948',v:'483',c:'児発８・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611321',v:'1191',c:'児発９・',baseItem: true,},
  {s:'611951',v:'1012',c:'児発９・評価減・',baseItem: true,},
  {s:'611322',v:'834',c:'児発９・開所減１・',baseItem: true,},
  {s:'611954',v:'709',c:'児発９・開所減１・評価減・',baseItem: true,},
  {s:'611761',v:'1012',c:'児発９・開所減２・',baseItem: true,},
  {s:'611957',v:'860',c:'児発９・開所減２・評価減・',baseItem: true,},
  {s:'611323',v:'834',c:'児発９・未計画・',baseItem: true,},
  {s:'611960',v:'709',c:'児発９・未計画・評価減・',baseItem: true,},
  {s:'611324',v:'584',c:'児発９・未計画・開所減１・',baseItem: true,},
  {s:'611963',v:'496',c:'児発９・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611762',v:'709',c:'児発９・未計画・開所減２・',baseItem: true,},
  {s:'611966',v:'603',c:'児発９・未計画・開所減２・評価減・',baseItem: true,},
  {s:'611968',v:'596',c:'児発９・未計画２・',baseItem: true,},
  {s:'611970',v:'507',c:'児発９・未計画２・評価減・',baseItem: true,},
  {s:'611972',v:'417',c:'児発９・未計画２・開所減１・',baseItem: true,},
  {s:'611974',v:'354',c:'児発９・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'611976',v:'507',c:'児発９・未計画２・開所減２・',baseItem: true,},
  {s:'611978',v:'431',c:'児発９・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611325',v:'1149',c:'児発９・地公体・',baseItem: true,},
  {s:'611981',v:'977',c:'児発９・地公体・評価減・',baseItem: true,},
  {s:'611326',v:'804',c:'児発９・地公体・開所減１・',baseItem: true,},
  {s:'611984',v:'683',c:'児発９・地公体・開所減１・評価減・',baseItem: true,},
  {s:'611763',v:'977',c:'児発９・地公体・開所減２・',baseItem: true,},
  {s:'611987',v:'830',c:'児発９・地公体・開所減２・評価減・',baseItem: true,},
  {s:'611327',v:'804',c:'児発９・地公体・未計画・',baseItem: true,},
  {s:'611990',v:'683',c:'児発９・地公体・未計画・評価減・',baseItem: true,},
  {s:'611328',v:'563',c:'児発９・地公体・未計画・開所減１・',baseItem: true,},
  {s:'611993',v:'479',c:'児発９・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611764',v:'683',c:'児発９・地公体・未計画・開所減２・',baseItem: true,},
  {s:'611996',v:'581',c:'児発９・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'611998',v:'575',c:'児発９・地公体・未計画２・',baseItem: true,},
  {s:'612000',v:'489',c:'児発９・地公体・未計画２・評価減・',baseItem: true,},
  {s:'612002',v:'403',c:'児発９・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'612004',v:'343',c:'児発９・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'612006',v:'489',c:'児発９・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'612008',v:'416',c:'児発９・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611331',v:'1075',c:'児発１０・',baseItem: true,},
  {s:'612011',v:'914',c:'児発１０・評価減・',baseItem: true,},
  {s:'611332',v:'753',c:'児発１０・開所減１・',baseItem: true,},
  {s:'612014',v:'640',c:'児発１０・開所減１・評価減・',baseItem: true,},
  {s:'611765',v:'914',c:'児発１０・開所減２・',baseItem: true,},
  {s:'612017',v:'777',c:'児発１０・開所減２・評価減・',baseItem: true,},
  {s:'611333',v:'753',c:'児発１０・未計画・',baseItem: true,},
  {s:'612020',v:'640',c:'児発１０・未計画・評価減・',baseItem: true,},
  {s:'611334',v:'527',c:'児発１０・未計画・開所減１・',baseItem: true,},
  {s:'612023',v:'448',c:'児発１０・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611766',v:'640',c:'児発１０・未計画・開所減２・',baseItem: true,},
  {s:'612026',v:'544',c:'児発１０・未計画・開所減２・評価減・',baseItem: true,},
  {s:'612028',v:'538',c:'児発１０・未計画２・',baseItem: true,},
  {s:'612030',v:'457',c:'児発１０・未計画２・評価減・',baseItem: true,},
  {s:'612032',v:'377',c:'児発１０・未計画２・開所減１・',baseItem: true,},
  {s:'612034',v:'320',c:'児発１０・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'612036',v:'457',c:'児発１０・未計画２・開所減２・',baseItem: true,},
  {s:'612038',v:'388',c:'児発１０・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611335',v:'1037',c:'児発１０・地公体・',baseItem: true,},
  {s:'612041',v:'881',c:'児発１０・地公体・評価減・',baseItem: true,},
  {s:'611336',v:'726',c:'児発１０・地公体・開所減１・',baseItem: true,},
  {s:'612044',v:'617',c:'児発１０・地公体・開所減１・評価減・',baseItem: true,},
  {s:'611767',v:'881',c:'児発１０・地公体・開所減２・',baseItem: true,},
  {s:'612047',v:'749',c:'児発１０・地公体・開所減２・評価減・',baseItem: true,},
  {s:'611337',v:'726',c:'児発１０・地公体・未計画・',baseItem: true,},
  {s:'612050',v:'617',c:'児発１０・地公体・未計画・評価減・',baseItem: true,},
  {s:'611338',v:'508',c:'児発１０・地公体・未計画・開所減１・',baseItem: true,},
  {s:'612053',v:'432',c:'児発１０・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611768',v:'617',c:'児発１０・地公体・未計画・開所減２・',baseItem: true,},
  {s:'612056',v:'524',c:'児発１０・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'612058',v:'519',c:'児発１０・地公体・未計画２・',baseItem: true,},
  {s:'612060',v:'441',c:'児発１０・地公体・未計画２・評価減・',baseItem: true,},
  {s:'612062',v:'363',c:'児発１０・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'612064',v:'309',c:'児発１０・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'612066',v:'441',c:'児発１０・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'612068',v:'375',c:'児発１０・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611341',v:'975',c:'児発１１・',baseItem: true,},
  {s:'612071',v:'829',c:'児発１１・評価減・',baseItem: true,},
  {s:'611342',v:'683',c:'児発１１・開所減１・',baseItem: true,},
  {s:'612074',v:'581',c:'児発１１・開所減１・評価減・',baseItem: true,},
  {s:'611769',v:'829',c:'児発１１・開所減２・',baseItem: true,},
  {s:'612077',v:'705',c:'児発１１・開所減２・評価減・',baseItem: true,},
  {s:'611343',v:'683',c:'児発１１・未計画・',baseItem: true,},
  {s:'612080',v:'581',c:'児発１１・未計画・評価減・',baseItem: true,},
  {s:'611344',v:'478',c:'児発１１・未計画・開所減１・',baseItem: true,},
  {s:'612083',v:'406',c:'児発１１・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611770',v:'581',c:'児発１１・未計画・開所減２・',baseItem: true,},
  {s:'612086',v:'494',c:'児発１１・未計画・開所減２・評価減・',baseItem: true,},
  {s:'612088',v:'488',c:'児発１１・未計画２・',baseItem: true,},
  {s:'612090',v:'415',c:'児発１１・未計画２・評価減・',baseItem: true,},
  {s:'612092',v:'342',c:'児発１１・未計画２・開所減１・',baseItem: true,},
  {s:'612094',v:'291',c:'児発１１・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'612096',v:'415',c:'児発１１・未計画２・開所減２・',baseItem: true,},
  {s:'612098',v:'353',c:'児発１１・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611345',v:'941',c:'児発１１・地公体・',baseItem: true,},
  {s:'612101',v:'800',c:'児発１１・地公体・評価減・',baseItem: true,},
  {s:'611346',v:'659',c:'児発１１・地公体・開所減１・',baseItem: true,},
  {s:'612104',v:'560',c:'児発１１・地公体・開所減１・評価減・',baseItem: true,},
  {s:'611771',v:'800',c:'児発１１・地公体・開所減２・',baseItem: true,},
  {s:'612107',v:'680',c:'児発１１・地公体・開所減２・評価減・',baseItem: true,},
  {s:'611347',v:'659',c:'児発１１・地公体・未計画・',baseItem: true,},
  {s:'612110',v:'560',c:'児発１１・地公体・未計画・評価減・',baseItem: true,},
  {s:'611348',v:'461',c:'児発１１・地公体・未計画・開所減１・',baseItem: true,},
  {s:'612113',v:'392',c:'児発１１・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611772',v:'560',c:'児発１１・地公体・未計画・開所減２・',baseItem: true,},
  {s:'612116',v:'476',c:'児発１１・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'612118',v:'471',c:'児発１１・地公体・未計画２・',baseItem: true,},
  {s:'612120',v:'400',c:'児発１１・地公体・未計画２・評価減・',baseItem: true,},
  {s:'612122',v:'330',c:'児発１１・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'612124',v:'281',c:'児発１１・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'612126',v:'400',c:'児発１１・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'612128',v:'340',c:'児発１１・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611411',v:'1331',c:'児発１２・',baseItem: true,},
  {s:'612131',v:'1131',c:'児発１２・評価減・',baseItem: true,},
  {s:'611412',v:'932',c:'児発１２・開所減１・',baseItem: true,},
  {s:'612134',v:'792',c:'児発１２・開所減１・評価減・',baseItem: true,},
  {s:'611773',v:'1131',c:'児発１２・開所減２・',baseItem: true,},
  {s:'612137',v:'961',c:'児発１２・開所減２・評価減・',baseItem: true,},
  {s:'611413',v:'932',c:'児発１２・未計画・',baseItem: true,},
  {s:'612140',v:'792',c:'児発１２・未計画・評価減・',baseItem: true,},
  {s:'611414',v:'652',c:'児発１２・未計画・開所減１・',baseItem: true,},
  {s:'612143',v:'554',c:'児発１２・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611774',v:'792',c:'児発１２・未計画・開所減２・',baseItem: true,},
  {s:'612146',v:'673',c:'児発１２・未計画・開所減２・評価減・',baseItem: true,},
  {s:'612148',v:'666',c:'児発１２・未計画２・',baseItem: true,},
  {s:'612150',v:'566',c:'児発１２・未計画２・評価減・',baseItem: true,},
  {s:'612152',v:'466',c:'児発１２・未計画２・開所減１・',baseItem: true,},
  {s:'612154',v:'396',c:'児発１２・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'612156',v:'566',c:'児発１２・未計画２・開所減２・',baseItem: true,},
  {s:'612158',v:'481',c:'児発１２・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611415',v:'1284',c:'児発１２・地公体・',baseItem: true,},
  {s:'612161',v:'1091',c:'児発１２・地公体・評価減・',baseItem: true,},
  {s:'611416',v:'899',c:'児発１２・地公体・開所減１・',baseItem: true,},
  {s:'612164',v:'764',c:'児発１２・地公体・開所減１・評価減・',baseItem: true,},
  {s:'611775',v:'1091',c:'児発１２・地公体・開所減２・',baseItem: true,},
  {s:'612167',v:'927',c:'児発１２・地公体・開所減２・評価減・',baseItem: true,},
  {s:'611417',v:'899',c:'児発１２・地公体・未計画・',baseItem: true,},
  {s:'612170',v:'764',c:'児発１２・地公体・未計画・評価減・',baseItem: true,},
  {s:'611418',v:'629',c:'児発１２・地公体・未計画・開所減１・',baseItem: true,},
  {s:'612173',v:'535',c:'児発１２・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611776',v:'764',c:'児発１２・地公体・未計画・開所減２・',baseItem: true,},
  {s:'612176',v:'649',c:'児発１２・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'612178',v:'642',c:'児発１２・地公体・未計画２・',baseItem: true,},
  {s:'612180',v:'546',c:'児発１２・地公体・未計画２・評価減・',baseItem: true,},
  {s:'612182',v:'449',c:'児発１２・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'612184',v:'382',c:'児発１２・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'612186',v:'546',c:'児発１２・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'612188',v:'464',c:'児発１２・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611421',v:'1040',c:'児発１３・',baseItem: true,},
  {s:'612191',v:'884',c:'児発１３・評価減・',baseItem: true,},
  {s:'611422',v:'728',c:'児発１３・開所減１・',baseItem: true,},
  {s:'612194',v:'619',c:'児発１３・開所減１・評価減・',baseItem: true,},
  {s:'611777',v:'884',c:'児発１３・開所減２・',baseItem: true,},
  {s:'612197',v:'751',c:'児発１３・開所減２・評価減・',baseItem: true,},
  {s:'611423',v:'728',c:'児発１３・未計画・',baseItem: true,},
  {s:'612200',v:'619',c:'児発１３・未計画・評価減・',baseItem: true,},
  {s:'611424',v:'510',c:'児発１３・未計画・開所減１・',baseItem: true,},
  {s:'612203',v:'434',c:'児発１３・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611778',v:'619',c:'児発１３・未計画・開所減２・',baseItem: true,},
  {s:'612206',v:'526',c:'児発１３・未計画・開所減２・評価減・',baseItem: true,},
  {s:'612208',v:'520',c:'児発１３・未計画２・',baseItem: true,},
  {s:'612210',v:'442',c:'児発１３・未計画２・評価減・',baseItem: true,},
  {s:'612212',v:'364',c:'児発１３・未計画２・開所減１・',baseItem: true,},
  {s:'612214',v:'309',c:'児発１３・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'612216',v:'442',c:'児発１３・未計画２・開所減２・',baseItem: true,},
  {s:'612218',v:'376',c:'児発１３・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611425',v:'1004',c:'児発１３・地公体・',baseItem: true,},
  {s:'612221',v:'853',c:'児発１３・地公体・評価減・',baseItem: true,},
  {s:'611426',v:'703',c:'児発１３・地公体・開所減１・',baseItem: true,},
  {s:'612224',v:'598',c:'児発１３・地公体・開所減１・評価減・',baseItem: true,},
  {s:'611779',v:'853',c:'児発１３・地公体・開所減２・',baseItem: true,},
  {s:'612227',v:'725',c:'児発１３・地公体・開所減２・評価減・',baseItem: true,},
  {s:'611427',v:'703',c:'児発１３・地公体・未計画・',baseItem: true,},
  {s:'612230',v:'598',c:'児発１３・地公体・未計画・評価減・',baseItem: true,},
  {s:'611428',v:'492',c:'児発１３・地公体・未計画・開所減１・',baseItem: true,},
  {s:'612233',v:'418',c:'児発１３・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611780',v:'598',c:'児発１３・地公体・未計画・開所減２・',baseItem: true,},
  {s:'612236',v:'508',c:'児発１３・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'612238',v:'502',c:'児発１３・地公体・未計画２・',baseItem: true,},
  {s:'612240',v:'427',c:'児発１３・地公体・未計画２・評価減・',baseItem: true,},
  {s:'612242',v:'351',c:'児発１３・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'612244',v:'298',c:'児発１３・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'612246',v:'427',c:'児発１３・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'612248',v:'363',c:'児発１３・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611431',v:'924',c:'児発１４・',baseItem: true,},
  {s:'612251',v:'785',c:'児発１４・評価減・',baseItem: true,},
  {s:'611432',v:'647',c:'児発１４・開所減１・',baseItem: true,},
  {s:'612254',v:'550',c:'児発１４・開所減１・評価減・',baseItem: true,},
  {s:'611781',v:'785',c:'児発１４・開所減２・',baseItem: true,},
  {s:'612257',v:'667',c:'児発１４・開所減２・評価減・',baseItem: true,},
  {s:'611433',v:'647',c:'児発１４・未計画・',baseItem: true,},
  {s:'612260',v:'550',c:'児発１４・未計画・評価減・',baseItem: true,},
  {s:'611434',v:'453',c:'児発１４・未計画・開所減１・',baseItem: true,},
  {s:'612263',v:'385',c:'児発１４・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611782',v:'550',c:'児発１４・未計画・開所減２・',baseItem: true,},
  {s:'612266',v:'468',c:'児発１４・未計画・開所減２・評価減・',baseItem: true,},
  {s:'612268',v:'462',c:'児発１４・未計画２・',baseItem: true,},
  {s:'612270',v:'393',c:'児発１４・未計画２・評価減・',baseItem: true,},
  {s:'612272',v:'323',c:'児発１４・未計画２・開所減１・',baseItem: true,},
  {s:'612274',v:'275',c:'児発１４・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'612276',v:'393',c:'児発１４・未計画２・開所減２・',baseItem: true,},
  {s:'612278',v:'334',c:'児発１４・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611435',v:'892',c:'児発１４・地公体・',baseItem: true,},
  {s:'612281',v:'758',c:'児発１４・地公体・評価減・',baseItem: true,},
  {s:'611436',v:'624',c:'児発１４・地公体・開所減１・',baseItem: true,},
  {s:'612284',v:'530',c:'児発１４・地公体・開所減１・評価減・',baseItem: true,},
  {s:'611783',v:'758',c:'児発１４・地公体・開所減２・',baseItem: true,},
  {s:'612287',v:'644',c:'児発１４・地公体・開所減２・評価減・',baseItem: true,},
  {s:'611437',v:'624',c:'児発１４・地公体・未計画・',baseItem: true,},
  {s:'612290',v:'530',c:'児発１４・地公体・未計画・評価減・',baseItem: true,},
  {s:'611438',v:'437',c:'児発１４・地公体・未計画・開所減１・',baseItem: true,},
  {s:'612293',v:'371',c:'児発１４・地公体・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611784',v:'530',c:'児発１４・地公体・未計画・開所減２・',baseItem: true,},
  {s:'612296',v:'451',c:'児発１４・地公体・未計画・開所減２・評価減・',baseItem: true,},
  {s:'612298',v:'446',c:'児発１４・地公体・未計画２・',baseItem: true,},
  {s:'612300',v:'379',c:'児発１４・地公体・未計画２・評価減・',baseItem: true,},
  {s:'612302',v:'312',c:'児発１４・地公体・未計画２・開所減１・',baseItem: true,},
  {s:'612304',v:'265',c:'児発１４・地公体・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'612306',v:'379',c:'児発１４・地公体・未計画２・開所減２・',baseItem: true,},
  {s:'612308',v:'322',c:'児発１４・地公体・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61DB89',v:'2885',c:'児発６１・',baseItem: true,},
  {s:'61DB90',v:'2452',c:'児発６１・評価減・',baseItem: true,},
  {s:'61DB91',v:'2020',c:'児発６１・開所減１・',baseItem: true,},
  {s:'61DB92',v:'1717',c:'児発６１・開所減１・評価減・',baseItem: true,},
  {s:'61DB93',v:'2452',c:'児発６１・開所減２・',baseItem: true,},
  {s:'61DB94',v:'2084',c:'児発６１・開所減２・評価減・',baseItem: true,},
  {s:'61DB95',v:'2020',c:'児発６１・未計画・',baseItem: true,},
  {s:'61DB96',v:'1717',c:'児発６１・未計画・評価減・',baseItem: true,},
  {s:'61DB97',v:'1414',c:'児発６１・未計画・開所減１・',baseItem: true,},
  {s:'61DB98',v:'1202',c:'児発６１・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61DB99',v:'1717',c:'児発６１・未計画・開所減２・',baseItem: true,},
  {s:'61DC00',v:'1459',c:'児発６１・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61DC01',v:'1443',c:'児発６１・未計画２・',baseItem: true,},
  {s:'61DC02',v:'1227',c:'児発６１・未計画２・評価減・',baseItem: true,},
  {s:'61DC03',v:'1010',c:'児発６１・未計画２・開所減１・',baseItem: true,},
  {s:'61DC04',v:'859',c:'児発６１・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61DC05',v:'1227',c:'児発６１・未計画２・開所減２・',baseItem: true,},
  {s:'61DC06',v:'1043',c:'児発６１・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61DC07',v:'2613',c:'児発６２・',baseItem: true,},
  {s:'61DC08',v:'2221',c:'児発６２・評価減・',baseItem: true,},
  {s:'61DC09',v:'1829',c:'児発６２・開所減１・',baseItem: true,},
  {s:'61DC10',v:'1555',c:'児発６２・開所減１・評価減・',baseItem: true,},
  {s:'61DC11',v:'2221',c:'児発６２・開所減２・',baseItem: true,},
  {s:'61DC12',v:'1888',c:'児発６２・開所減２・評価減・',baseItem: true,},
  {s:'61DC13',v:'1829',c:'児発６２・未計画・',baseItem: true,},
  {s:'61DC14',v:'1555',c:'児発６２・未計画・評価減・',baseItem: true,},
  {s:'61DC15',v:'1280',c:'児発６２・未計画・開所減１・',baseItem: true,},
  {s:'61DC16',v:'1088',c:'児発６２・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61DC17',v:'1555',c:'児発６２・未計画・開所減２・',baseItem: true,},
  {s:'61DC18',v:'1322',c:'児発６２・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61DC19',v:'1307',c:'児発６２・未計画２・',baseItem: true,},
  {s:'61DC20',v:'1111',c:'児発６２・未計画２・評価減・',baseItem: true,},
  {s:'61DC21',v:'915',c:'児発６２・未計画２・開所減１・',baseItem: true,},
  {s:'61DC22',v:'778',c:'児発６２・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61DC23',v:'1111',c:'児発６２・未計画２・開所減２・',baseItem: true,},
  {s:'61DC24',v:'944',c:'児発６２・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61DC25',v:'2486',c:'児発６３・',baseItem: true,},
  {s:'61DC26',v:'2113',c:'児発６３・評価減・',baseItem: true,},
  {s:'61DC27',v:'1740',c:'児発６３・開所減１・',baseItem: true,},
  {s:'61DC28',v:'1479',c:'児発６３・開所減１・評価減・',baseItem: true,},
  {s:'61DC29',v:'2113',c:'児発６３・開所減２・',baseItem: true,},
  {s:'61DC30',v:'1796',c:'児発６３・開所減２・評価減・',baseItem: true,},
  {s:'61DC31',v:'1740',c:'児発６３・未計画・',baseItem: true,},
  {s:'61DC32',v:'1479',c:'児発６３・未計画・評価減・',baseItem: true,},
  {s:'61DC33',v:'1218',c:'児発６３・未計画・開所減１・',baseItem: true,},
  {s:'61DC34',v:'1035',c:'児発６３・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61DC35',v:'1479',c:'児発６３・未計画・開所減２・',baseItem: true,},
  {s:'61DC36',v:'1257',c:'児発６３・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61DC37',v:'1243',c:'児発６３・未計画２・',baseItem: true,},
  {s:'61DC38',v:'1057',c:'児発６３・未計画２・評価減・',baseItem: true,},
  {s:'61DC39',v:'870',c:'児発６３・未計画２・開所減１・',baseItem: true,},
  {s:'61DC40',v:'740',c:'児発６３・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61DC41',v:'1057',c:'児発６３・未計画２・開所減２・',baseItem: true,},
  {s:'61DC42',v:'898',c:'児発６３・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61DC43',v:'1885',c:'児発６４・',baseItem: true,},
  {s:'61DC44',v:'1602',c:'児発６４・評価減・',baseItem: true,},
  {s:'61DC45',v:'1320',c:'児発６４・開所減１・',baseItem: true,},
  {s:'61DC46',v:'1122',c:'児発６４・開所減１・評価減・',baseItem: true,},
  {s:'61DC47',v:'1602',c:'児発６４・開所減２・',baseItem: true,},
  {s:'61DC48',v:'1362',c:'児発６４・開所減２・評価減・',baseItem: true,},
  {s:'61DC49',v:'1320',c:'児発６４・未計画・',baseItem: true,},
  {s:'61DC50',v:'1122',c:'児発６４・未計画・評価減・',baseItem: true,},
  {s:'61DC51',v:'924',c:'児発６４・未計画・開所減１・',baseItem: true,},
  {s:'61DC52',v:'785',c:'児発６４・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61DC53',v:'1122',c:'児発６４・未計画・開所減２・',baseItem: true,},
  {s:'61DC54',v:'954',c:'児発６４・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61DC55',v:'943',c:'児発６４・未計画２・',baseItem: true,},
  {s:'61DC56',v:'802',c:'児発６４・未計画２・評価減・',baseItem: true,},
  {s:'61DC57',v:'660',c:'児発６４・未計画２・開所減１・',baseItem: true,},
  {s:'61DC58',v:'561',c:'児発６４・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61DC59',v:'802',c:'児発６４・未計画２・開所減２・',baseItem: true,},
  {s:'61DC60',v:'682',c:'児発６４・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61DC61',v:'1613',c:'児発６５・',baseItem: true,},
  {s:'61DC62',v:'1371',c:'児発６５・評価減・',baseItem: true,},
  {s:'61DC63',v:'1129',c:'児発６５・開所減１・',baseItem: true,},
  {s:'61DC64',v:'960',c:'児発６５・開所減１・評価減・',baseItem: true,},
  {s:'61DC65',v:'1371',c:'児発６５・開所減２・',baseItem: true,},
  {s:'61DC66',v:'1165',c:'児発６５・開所減２・評価減・',baseItem: true,},
  {s:'61DC67',v:'1129',c:'児発６５・未計画・',baseItem: true,},
  {s:'61DC68',v:'960',c:'児発６５・未計画・評価減・',baseItem: true,},
  {s:'61DC69',v:'790',c:'児発６５・未計画・開所減１・',baseItem: true,},
  {s:'61DC70',v:'672',c:'児発６５・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61DC71',v:'960',c:'児発６５・未計画・開所減２・',baseItem: true,},
  {s:'61DC72',v:'816',c:'児発６５・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61DC73',v:'807',c:'児発６５・未計画２・',baseItem: true,},
  {s:'61DC74',v:'686',c:'児発６５・未計画２・評価減・',baseItem: true,},
  {s:'61DC75',v:'565',c:'児発６５・未計画２・開所減１・',baseItem: true,},
  {s:'61DC76',v:'480',c:'児発６５・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61DC77',v:'686',c:'児発６５・未計画２・開所減２・',baseItem: true,},
  {s:'61DC78',v:'583',c:'児発６５・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61DC79',v:'1486',c:'児発６６・',baseItem: true,},
  {s:'61DC80',v:'1263',c:'児発６６・評価減・',baseItem: true,},
  {s:'61DC81',v:'1040',c:'児発６６・開所減１・',baseItem: true,},
  {s:'61DC82',v:'884',c:'児発６６・開所減１・評価減・',baseItem: true,},
  {s:'61DC83',v:'1263',c:'児発６６・開所減２・',baseItem: true,},
  {s:'61DC84',v:'1074',c:'児発６６・開所減２・評価減・',baseItem: true,},
  {s:'61DC85',v:'1040',c:'児発６６・未計画・',baseItem: true,},
  {s:'61DC86',v:'884',c:'児発６６・未計画・評価減・',baseItem: true,},
  {s:'61DC87',v:'728',c:'児発６６・未計画・開所減１・',baseItem: true,},
  {s:'61DC88',v:'619',c:'児発６６・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61DC89',v:'884',c:'児発６６・未計画・開所減２・',baseItem: true,},
  {s:'61DC90',v:'751',c:'児発６６・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61DC91',v:'743',c:'児発６６・未計画２・',baseItem: true,},
  {s:'61DC92',v:'632',c:'児発６６・未計画２・評価減・',baseItem: true,},
  {s:'61DC93',v:'520',c:'児発６６・未計画２・開所減１・',baseItem: true,},
  {s:'61DC94',v:'442',c:'児発６６・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61DC95',v:'632',c:'児発６６・未計画２・開所減２・',baseItem: true,},
  {s:'61DC96',v:'537',c:'児発６６・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61DC97',v:'1552',c:'児発６７・',baseItem: true,},
  {s:'61DC98',v:'1319',c:'児発６７・評価減・',baseItem: true,},
  {s:'61DC99',v:'1086',c:'児発６７・開所減１・',baseItem: true,},
  {s:'61DE00',v:'923',c:'児発６７・開所減１・評価減・',baseItem: true,},
  {s:'61DE01',v:'1319',c:'児発６７・開所減２・',baseItem: true,},
  {s:'61DE02',v:'1121',c:'児発６７・開所減２・評価減・',baseItem: true,},
  {s:'61DE03',v:'1086',c:'児発６７・未計画・',baseItem: true,},
  {s:'61DE04',v:'923',c:'児発６７・未計画・評価減・',baseItem: true,},
  {s:'61DE05',v:'760',c:'児発６７・未計画・開所減１・',baseItem: true,},
  {s:'61DE06',v:'646',c:'児発６７・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61DE07',v:'923',c:'児発６７・未計画・開所減２・',baseItem: true,},
  {s:'61DE08',v:'785',c:'児発６７・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61DE09',v:'776',c:'児発６７・未計画２・',baseItem: true,},
  {s:'61DE10',v:'660',c:'児発６７・未計画２・評価減・',baseItem: true,},
  {s:'61DE11',v:'543',c:'児発６７・未計画２・開所減１・',baseItem: true,},
  {s:'61DE12',v:'462',c:'児発６７・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61DE13',v:'660',c:'児発６７・未計画２・開所減２・',baseItem: true,},
  {s:'61DE14',v:'561',c:'児発６７・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61DE15',v:'1280',c:'児発６８・',baseItem: true,},
  {s:'61DE16',v:'1088',c:'児発６８・評価減・',baseItem: true,},
  {s:'61DE17',v:'896',c:'児発６８・開所減１・',baseItem: true,},
  {s:'61DE18',v:'762',c:'児発６８・開所減１・評価減・',baseItem: true,},
  {s:'61DE19',v:'1088',c:'児発６８・開所減２・',baseItem: true,},
  {s:'61DE20',v:'925',c:'児発６８・開所減２・評価減・',baseItem: true,},
  {s:'61DE21',v:'896',c:'児発６８・未計画・',baseItem: true,},
  {s:'61DE22',v:'762',c:'児発６８・未計画・評価減・',baseItem: true,},
  {s:'61DE23',v:'627',c:'児発６８・未計画・開所減１・',baseItem: true,},
  {s:'61DE24',v:'533',c:'児発６８・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61DE25',v:'762',c:'児発６８・未計画・開所減２・',baseItem: true,},
  {s:'61DE26',v:'648',c:'児発６８・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61DE27',v:'640',c:'児発６８・未計画２・',baseItem: true,},
  {s:'61DE28',v:'544',c:'児発６８・未計画２・評価減・',baseItem: true,},
  {s:'61DE29',v:'448',c:'児発６８・未計画２・開所減１・',baseItem: true,},
  {s:'61DE30',v:'381',c:'児発６８・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61DE31',v:'544',c:'児発６８・未計画２・開所減２・',baseItem: true,},
  {s:'61DE32',v:'462',c:'児発６８・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61DE33',v:'1153',c:'児発６９・',baseItem: true,},
  {s:'61DE34',v:'980',c:'児発６９・評価減・',baseItem: true,},
  {s:'61DE35',v:'807',c:'児発６９・開所減１・',baseItem: true,},
  {s:'61DE36',v:'686',c:'児発６９・開所減１・評価減・',baseItem: true,},
  {s:'61DE37',v:'980',c:'児発６９・開所減２・',baseItem: true,},
  {s:'61DE38',v:'833',c:'児発６９・開所減２・評価減・',baseItem: true,},
  {s:'61DE39',v:'807',c:'児発６９・未計画・',baseItem: true,},
  {s:'61DE40',v:'686',c:'児発６９・未計画・評価減・',baseItem: true,},
  {s:'61DE41',v:'565',c:'児発６９・未計画・開所減１・',baseItem: true,},
  {s:'61DE42',v:'480',c:'児発６９・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61DE43',v:'686',c:'児発６９・未計画・開所減２・',baseItem: true,},
  {s:'61DE44',v:'583',c:'児発６９・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61DE45',v:'577',c:'児発６９・未計画２・',baseItem: true,},
  {s:'61DE46',v:'490',c:'児発６９・未計画２・評価減・',baseItem: true,},
  {s:'61DE47',v:'404',c:'児発６９・未計画２・開所減１・',baseItem: true,},
  {s:'61DE48',v:'343',c:'児発６９・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61DE49',v:'490',c:'児発６９・未計画２・開所減２・',baseItem: true,},
  {s:'61DE50',v:'417',c:'児発６９・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611511',v:'885',c:'児発１５・',baseItem: true,},
  {s:'612311',v:'752',c:'児発１５・評価減・',baseItem: true,},
  {s:'611512',v:'620',c:'児発１５・開所減１・',baseItem: true,},
  {s:'612314',v:'527',c:'児発１５・開所減１・評価減・',baseItem: true,},
  {s:'611785',v:'752',c:'児発１５・開所減２・',baseItem: true,},
  {s:'612317',v:'639',c:'児発１５・開所減２・評価減・',baseItem: true,},
  {s:'611513',v:'620',c:'児発１５・未計画・',baseItem: true,},
  {s:'612320',v:'527',c:'児発１５・未計画・評価減・',baseItem: true,},
  {s:'611514',v:'434',c:'児発１５・未計画・開所減１・',baseItem: true,},
  {s:'612323',v:'369',c:'児発１５・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611786',v:'527',c:'児発１５・未計画・開所減２・',baseItem: true,},
  {s:'612326',v:'448',c:'児発１５・未計画・開所減２・評価減・',baseItem: true,},
  {s:'612328',v:'443',c:'児発１５・未計画２・',baseItem: true,},
  {s:'612330',v:'377',c:'児発１５・未計画２・評価減・',baseItem: true,},
  {s:'612332',v:'310',c:'児発１５・未計画２・開所減１・',baseItem: true,},
  {s:'612334',v:'264',c:'児発１５・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'612336',v:'377',c:'児発１５・未計画２・開所減２・',baseItem: true,},
  {s:'612338',v:'320',c:'児発１５・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611521',v:'613',c:'児発１６・',baseItem: true,},
  {s:'612371',v:'521',c:'児発１６・評価減・',baseItem: true,},
  {s:'611522',v:'429',c:'児発１６・開所減１・',baseItem: true,},
  {s:'612374',v:'365',c:'児発１６・開所減１・評価減・',baseItem: true,},
  {s:'611793',v:'521',c:'児発１６・開所減２・',baseItem: true,},
  {s:'612377',v:'443',c:'児発１６・開所減２・評価減・',baseItem: true,},
  {s:'611523',v:'429',c:'児発１６・未計画・',baseItem: true,},
  {s:'612380',v:'365',c:'児発１６・未計画・評価減・',baseItem: true,},
  {s:'611524',v:'300',c:'児発１６・未計画・開所減１・',baseItem: true,},
  {s:'612383',v:'255',c:'児発１６・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611794',v:'365',c:'児発１６・未計画・開所減２・',baseItem: true,},
  {s:'612386',v:'310',c:'児発１６・未計画・開所減２・評価減・',baseItem: true,},
  {s:'612388',v:'307',c:'児発１６・未計画２・',baseItem: true,},
  {s:'612390',v:'261',c:'児発１６・未計画２・評価減・',baseItem: true,},
  {s:'612392',v:'215',c:'児発１６・未計画２・開所減１・',baseItem: true,},
  {s:'612394',v:'183',c:'児発１６・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'612396',v:'261',c:'児発１６・未計画２・開所減２・',baseItem: true,},
  {s:'612398',v:'222',c:'児発１６・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611531',v:'486',c:'児発１７・',baseItem: true,},
  {s:'612431',v:'413',c:'児発１７・評価減・',baseItem: true,},
  {s:'611532',v:'340',c:'児発１７・開所減１・',baseItem: true,},
  {s:'612434',v:'289',c:'児発１７・開所減１・評価減・',baseItem: true,},
  {s:'611801',v:'413',c:'児発１７・開所減２・',baseItem: true,},
  {s:'612437',v:'351',c:'児発１７・開所減２・評価減・',baseItem: true,},
  {s:'611533',v:'340',c:'児発１７・未計画・',baseItem: true,},
  {s:'612440',v:'289',c:'児発１７・未計画・評価減・',baseItem: true,},
  {s:'611534',v:'238',c:'児発１７・未計画・開所減１・',baseItem: true,},
  {s:'612443',v:'202',c:'児発１７・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611802',v:'289',c:'児発１７・未計画・開所減２・',baseItem: true,},
  {s:'612446',v:'246',c:'児発１７・未計画・開所減２・評価減・',baseItem: true,},
  {s:'612448',v:'243',c:'児発１７・未計画２・',baseItem: true,},
  {s:'612450',v:'207',c:'児発１７・未計画２・評価減・',baseItem: true,},
  {s:'612452',v:'170',c:'児発１７・未計画２・開所減１・',baseItem: true,},
  {s:'612454',v:'145',c:'児発１７・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'612456',v:'207',c:'児発１７・未計画２・開所減２・',baseItem: true,},
  {s:'612458',v:'176',c:'児発１７・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61DE51',v:'2754',c:'児発７０・',baseItem: true,},
  {s:'61DE52',v:'2341',c:'児発７０・評価減・',baseItem: true,},
  {s:'61DE53',v:'1928',c:'児発７０・開所減１・',baseItem: true,},
  {s:'61DE54',v:'1639',c:'児発７０・開所減１・評価減・',baseItem: true,},
  {s:'61DE55',v:'2341',c:'児発７０・開所減２・',baseItem: true,},
  {s:'61DE56',v:'1990',c:'児発７０・開所減２・評価減・',baseItem: true,},
  {s:'61DE57',v:'1928',c:'児発７０・未計画・',baseItem: true,},
  {s:'61DE58',v:'1639',c:'児発７０・未計画・評価減・',baseItem: true,},
  {s:'61DE59',v:'1350',c:'児発７０・未計画・開所減１・',baseItem: true,},
  {s:'61DE60',v:'1148',c:'児発７０・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61DE61',v:'1639',c:'児発７０・未計画・開所減２・',baseItem: true,},
  {s:'61DE62',v:'1393',c:'児発７０・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61DE63',v:'1377',c:'児発７０・未計画２・',baseItem: true,},
  {s:'61DE64',v:'1170',c:'児発７０・未計画２・評価減・',baseItem: true,},
  {s:'61DE65',v:'964',c:'児発７０・未計画２・開所減１・',baseItem: true,},
  {s:'61DE66',v:'819',c:'児発７０・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61DE67',v:'1170',c:'児発７０・未計画２・開所減２・',baseItem: true,},
  {s:'61DE68',v:'995',c:'児発７０・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61DE69',v:'2513',c:'児発７１・',baseItem: true,},
  {s:'61DE70',v:'2136',c:'児発７１・評価減・',baseItem: true,},
  {s:'61DE71',v:'1759',c:'児発７１・開所減１・',baseItem: true,},
  {s:'61DE72',v:'1495',c:'児発７１・開所減１・評価減・',baseItem: true,},
  {s:'61DE73',v:'2136',c:'児発７１・開所減２・',baseItem: true,},
  {s:'61DE74',v:'1816',c:'児発７１・開所減２・評価減・',baseItem: true,},
  {s:'61DE75',v:'1759',c:'児発７１・未計画・',baseItem: true,},
  {s:'61DE76',v:'1495',c:'児発７１・未計画・評価減・',baseItem: true,},
  {s:'61DE77',v:'1231',c:'児発７１・未計画・開所減１・',baseItem: true,},
  {s:'61DE78',v:'1046',c:'児発７１・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61DE79',v:'1495',c:'児発７１・未計画・開所減２・',baseItem: true,},
  {s:'61DE80',v:'1271',c:'児発７１・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61DE81',v:'1257',c:'児発７１・未計画２・',baseItem: true,},
  {s:'61DE82',v:'1068',c:'児発７１・未計画２・評価減・',baseItem: true,},
  {s:'61DE83',v:'880',c:'児発７１・未計画２・開所減１・',baseItem: true,},
  {s:'61DE84',v:'748',c:'児発７１・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61DE85',v:'1068',c:'児発７１・未計画２・開所減２・',baseItem: true,},
  {s:'61DE86',v:'908',c:'児発７１・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61DE87',v:'2404',c:'児発７２・',baseItem: true,},
  {s:'61DE88',v:'2043',c:'児発７２・評価減・',baseItem: true,},
  {s:'61DE89',v:'1683',c:'児発７２・開所減１・',baseItem: true,},
  {s:'61DE90',v:'1431',c:'児発７２・開所減１・評価減・',baseItem: true,},
  {s:'61DE91',v:'2043',c:'児発７２・開所減２・',baseItem: true,},
  {s:'61DE92',v:'1737',c:'児発７２・開所減２・評価減・',baseItem: true,},
  {s:'61DE93',v:'1683',c:'児発７２・未計画・',baseItem: true,},
  {s:'61DE94',v:'1431',c:'児発７２・未計画・評価減・',baseItem: true,},
  {s:'61DE95',v:'1178',c:'児発７２・未計画・開所減１・',baseItem: true,},
  {s:'61DE96',v:'1001',c:'児発７２・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61DE97',v:'1431',c:'児発７２・未計画・開所減２・',baseItem: true,},
  {s:'61DE98',v:'1216',c:'児発７２・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61DE99',v:'1202',c:'児発７２・未計画２・',baseItem: true,},
  {s:'61DF00',v:'1022',c:'児発７２・未計画２・評価減・',baseItem: true,},
  {s:'61DF01',v:'841',c:'児発７２・未計画２・開所減１・',baseItem: true,},
  {s:'61DF02',v:'715',c:'児発７２・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61DF03',v:'1022',c:'児発７２・未計画２・開所減２・',baseItem: true,},
  {s:'61DF04',v:'869',c:'児発７２・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61DF05',v:'1754',c:'児発７３・',baseItem: true,},
  {s:'61DF06',v:'1491',c:'児発７３・評価減・',baseItem: true,},
  {s:'61DF07',v:'1228',c:'児発７３・開所減１・',baseItem: true,},
  {s:'61DF08',v:'1044',c:'児発７３・開所減１・評価減・',baseItem: true,},
  {s:'61DF09',v:'1491',c:'児発７３・開所減２・',baseItem: true,},
  {s:'61DF10',v:'1267',c:'児発７３・開所減２・評価減・',baseItem: true,},
  {s:'61DF11',v:'1228',c:'児発７３・未計画・',baseItem: true,},
  {s:'61DF12',v:'1044',c:'児発７３・未計画・評価減・',baseItem: true,},
  {s:'61DF13',v:'860',c:'児発７３・未計画・開所減１・',baseItem: true,},
  {s:'61DF14',v:'731',c:'児発７３・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61DF15',v:'1044',c:'児発７３・未計画・開所減２・',baseItem: true,},
  {s:'61DF16',v:'887',c:'児発７３・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61DF17',v:'877',c:'児発７３・未計画２・',baseItem: true,},
  {s:'61DF18',v:'745',c:'児発７３・未計画２・評価減・',baseItem: true,},
  {s:'61DF19',v:'614',c:'児発７３・未計画２・開所減１・',baseItem: true,},
  {s:'61DF20',v:'522',c:'児発７３・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61DF21',v:'745',c:'児発７３・未計画２・開所減２・',baseItem: true,},
  {s:'61DF22',v:'633',c:'児発７３・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61DF23',v:'1513',c:'児発７４・',baseItem: true,},
  {s:'61DF24',v:'1286',c:'児発７４・評価減・',baseItem: true,},
  {s:'61DF25',v:'1059',c:'児発７４・開所減１・',baseItem: true,},
  {s:'61DF26',v:'900',c:'児発７４・開所減１・評価減・',baseItem: true,},
  {s:'61DF27',v:'1286',c:'児発７４・開所減２・',baseItem: true,},
  {s:'61DF28',v:'1093',c:'児発７４・開所減２・評価減・',baseItem: true,},
  {s:'61DF29',v:'1059',c:'児発７４・未計画・',baseItem: true,},
  {s:'61DF30',v:'900',c:'児発７４・未計画・評価減・',baseItem: true,},
  {s:'61DF31',v:'741',c:'児発７４・未計画・開所減１・',baseItem: true,},
  {s:'61DF32',v:'630',c:'児発７４・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61DF33',v:'900',c:'児発７４・未計画・開所減２・',baseItem: true,},
  {s:'61DF34',v:'765',c:'児発７４・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61DF35',v:'757',c:'児発７４・未計画２・',baseItem: true,},
  {s:'61DF36',v:'643',c:'児発７４・未計画２・評価減・',baseItem: true,},
  {s:'61DF37',v:'530',c:'児発７４・未計画２・開所減１・',baseItem: true,},
  {s:'61DF38',v:'451',c:'児発７４・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61DF39',v:'643',c:'児発７４・未計画２・開所減２・',baseItem: true,},
  {s:'61DF40',v:'547',c:'児発７４・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61DF41',v:'1404',c:'児発７５・',baseItem: true,},
  {s:'61DF42',v:'1193',c:'児発７５・評価減・',baseItem: true,},
  {s:'61DF43',v:'983',c:'児発７５・開所減１・',baseItem: true,},
  {s:'61DF44',v:'836',c:'児発７５・開所減１・評価減・',baseItem: true,},
  {s:'61DF45',v:'1193',c:'児発７５・開所減２・',baseItem: true,},
  {s:'61DF46',v:'1014',c:'児発７５・開所減２・評価減・',baseItem: true,},
  {s:'61DF47',v:'983',c:'児発７５・未計画・',baseItem: true,},
  {s:'61DF48',v:'836',c:'児発７５・未計画・評価減・',baseItem: true,},
  {s:'61DF49',v:'688',c:'児発７５・未計画・開所減１・',baseItem: true,},
  {s:'61DF50',v:'585',c:'児発７５・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61DF51',v:'836',c:'児発７５・未計画・開所減２・',baseItem: true,},
  {s:'61DF52',v:'711',c:'児発７５・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61DF53',v:'702',c:'児発７５・未計画２・',baseItem: true,},
  {s:'61DF54',v:'597',c:'児発７５・未計画２・評価減・',baseItem: true,},
  {s:'61DF55',v:'491',c:'児発７５・未計画２・開所減１・',baseItem: true,},
  {s:'61DF56',v:'417',c:'児発７５・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61DF57',v:'597',c:'児発７５・未計画２・開所減２・',baseItem: true,},
  {s:'61DF58',v:'507',c:'児発７５・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61DF59',v:'1421',c:'児発７６・',baseItem: true,},
  {s:'61DF60',v:'1208',c:'児発７６・評価減・',baseItem: true,},
  {s:'61DF61',v:'995',c:'児発７６・開所減１・',baseItem: true,},
  {s:'61DF62',v:'846',c:'児発７６・開所減１・評価減・',baseItem: true,},
  {s:'61DF63',v:'1208',c:'児発７６・開所減２・',baseItem: true,},
  {s:'61DF64',v:'1027',c:'児発７６・開所減２・評価減・',baseItem: true,},
  {s:'61DF65',v:'995',c:'児発７６・未計画・',baseItem: true,},
  {s:'61DF66',v:'846',c:'児発７６・未計画・評価減・',baseItem: true,},
  {s:'61DF67',v:'697',c:'児発７６・未計画・開所減１・',baseItem: true,},
  {s:'61DF68',v:'592',c:'児発７６・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61DF69',v:'846',c:'児発７６・未計画・開所減２・',baseItem: true,},
  {s:'61DF70',v:'719',c:'児発７６・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61DF71',v:'711',c:'児発７６・未計画２・',baseItem: true,},
  {s:'61DF72',v:'604',c:'児発７６・未計画２・評価減・',baseItem: true,},
  {s:'61DF73',v:'498',c:'児発７６・未計画２・開所減１・',baseItem: true,},
  {s:'61DF74',v:'423',c:'児発７６・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61DF75',v:'604',c:'児発７６・未計画２・開所減２・',baseItem: true,},
  {s:'61DF76',v:'513',c:'児発７６・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61DF77',v:'1180',c:'児発７７・',baseItem: true,},
  {s:'61DF78',v:'1003',c:'児発７７・評価減・',baseItem: true,},
  {s:'61DF79',v:'826',c:'児発７７・開所減１・',baseItem: true,},
  {s:'61DF80',v:'702',c:'児発７７・開所減１・評価減・',baseItem: true,},
  {s:'61DF81',v:'1003',c:'児発７７・開所減２・',baseItem: true,},
  {s:'61DF82',v:'853',c:'児発７７・開所減２・評価減・',baseItem: true,},
  {s:'61DF83',v:'826',c:'児発７７・未計画・',baseItem: true,},
  {s:'61DF84',v:'702',c:'児発７７・未計画・評価減・',baseItem: true,},
  {s:'61DF85',v:'578',c:'児発７７・未計画・開所減１・',baseItem: true,},
  {s:'61DF86',v:'491',c:'児発７７・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61DF87',v:'702',c:'児発７７・未計画・開所減２・',baseItem: true,},
  {s:'61DF88',v:'597',c:'児発７７・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61DF89',v:'590',c:'児発７７・未計画２・',baseItem: true,},
  {s:'61DF90',v:'502',c:'児発７７・未計画２・評価減・',baseItem: true,},
  {s:'61DF91',v:'413',c:'児発７７・未計画２・開所減１・',baseItem: true,},
  {s:'61DF92',v:'351',c:'児発７７・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61DF93',v:'502',c:'児発７７・未計画２・開所減２・',baseItem: true,},
  {s:'61DF94',v:'427',c:'児発７７・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61DF95',v:'1071',c:'児発７８・',baseItem: true,},
  {s:'61DF96',v:'910',c:'児発７８・評価減・',baseItem: true,},
  {s:'61DF97',v:'750',c:'児発７８・開所減１・',baseItem: true,},
  {s:'61DF98',v:'638',c:'児発７８・開所減１・評価減・',baseItem: true,},
  {s:'61DF99',v:'910',c:'児発７８・開所減２・',baseItem: true,},
  {s:'61DG00',v:'774',c:'児発７８・開所減２・評価減・',baseItem: true,},
  {s:'61DG01',v:'750',c:'児発７８・未計画・',baseItem: true,},
  {s:'61DG02',v:'638',c:'児発７８・未計画・評価減・',baseItem: true,},
  {s:'61DG03',v:'525',c:'児発７８・未計画・開所減１・',baseItem: true,},
  {s:'61DG04',v:'446',c:'児発７８・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61DG05',v:'638',c:'児発７８・未計画・開所減２・',baseItem: true,},
  {s:'61DG06',v:'542',c:'児発７８・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61DG07',v:'536',c:'児発７８・未計画２・',baseItem: true,},
  {s:'61DG08',v:'456',c:'児発７８・未計画２・評価減・',baseItem: true,},
  {s:'61DG09',v:'375',c:'児発７８・未計画２・開所減１・',baseItem: true,},
  {s:'61DG10',v:'319',c:'児発７８・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61DG11',v:'456',c:'児発７８・未計画２・開所減２・',baseItem: true,},
  {s:'61DG12',v:'388',c:'児発７８・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'612490',v:'754',c:'児発１８・',baseItem: true,},
  {s:'612492',v:'641',c:'児発１８・評価減・',baseItem: true,},
  {s:'612494',v:'528',c:'児発１８・開所減１・',baseItem: true,},
  {s:'612496',v:'449',c:'児発１８・開所減１・評価減・',baseItem: true,},
  {s:'612498',v:'641',c:'児発１８・開所減２・',baseItem: true,},
  {s:'612500',v:'545',c:'児発１８・開所減２・評価減・',baseItem: true,},
  {s:'612502',v:'528',c:'児発１８・未計画・',baseItem: true,},
  {s:'612504',v:'449',c:'児発１８・未計画・評価減・',baseItem: true,},
  {s:'612506',v:'370',c:'児発１８・未計画・開所減１・',baseItem: true,},
  {s:'612508',v:'315',c:'児発１８・未計画・開所減１・評価減・',baseItem: true,},
  {s:'612510',v:'449',c:'児発１８・未計画・開所減２・',baseItem: true,},
  {s:'612512',v:'382',c:'児発１８・未計画・開所減２・評価減・',baseItem: true,},
  {s:'612514',v:'377',c:'児発１８・未計画２・',baseItem: true,},
  {s:'612516',v:'320',c:'児発１８・未計画２・評価減・',baseItem: true,},
  {s:'612518',v:'264',c:'児発１８・未計画２・開所減１・',baseItem: true,},
  {s:'612520',v:'224',c:'児発１８・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'612522',v:'320',c:'児発１８・未計画２・開所減２・',baseItem: true,},
  {s:'612524',v:'272',c:'児発１８・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'612570',v:'513',c:'児発１９・',baseItem: true,},
  {s:'612572',v:'436',c:'児発１９・評価減・',baseItem: true,},
  {s:'612574',v:'359',c:'児発１９・開所減１・',baseItem: true,},
  {s:'612576',v:'305',c:'児発１９・開所減１・評価減・',baseItem: true,},
  {s:'612578',v:'436',c:'児発１９・開所減２・',baseItem: true,},
  {s:'612580',v:'371',c:'児発１９・開所減２・評価減・',baseItem: true,},
  {s:'612582',v:'359',c:'児発１９・未計画・',baseItem: true,},
  {s:'612584',v:'305',c:'児発１９・未計画・評価減・',baseItem: true,},
  {s:'612586',v:'251',c:'児発１９・未計画・開所減１・',baseItem: true,},
  {s:'612588',v:'213',c:'児発１９・未計画・開所減１・評価減・',baseItem: true,},
  {s:'612590',v:'305',c:'児発１９・未計画・開所減２・',baseItem: true,},
  {s:'612592',v:'259',c:'児発１９・未計画・開所減２・評価減・',baseItem: true,},
  {s:'612594',v:'257',c:'児発１９・未計画２・',baseItem: true,},
  {s:'612596',v:'218',c:'児発１９・未計画２・評価減・',baseItem: true,},
  {s:'612598',v:'180',c:'児発１９・未計画２・開所減１・',baseItem: true,},
  {s:'612600',v:'153',c:'児発１９・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'612602',v:'218',c:'児発１９・未計画２・開所減２・',baseItem: true,},
  {s:'612604',v:'185',c:'児発１９・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'612642',v:'404',c:'児発２０・',baseItem: true,},
  {s:'612644',v:'343',c:'児発２０・評価減・',baseItem: true,},
  {s:'612646',v:'283',c:'児発２０・開所減１・',baseItem: true,},
  {s:'612648',v:'241',c:'児発２０・開所減１・評価減・',baseItem: true,},
  {s:'612650',v:'343',c:'児発２０・開所減２・',baseItem: true,},
  {s:'612652',v:'292',c:'児発２０・開所減２・評価減・',baseItem: true,},
  {s:'612654',v:'283',c:'児発２０・未計画・',baseItem: true,},
  {s:'612656',v:'241',c:'児発２０・未計画・評価減・',baseItem: true,},
  {s:'612658',v:'198',c:'児発２０・未計画・開所減１・',baseItem: true,},
  {s:'612660',v:'168',c:'児発２０・未計画・開所減１・評価減・',baseItem: true,},
  {s:'612662',v:'241',c:'児発２０・未計画・開所減２・',baseItem: true,},
  {s:'612664',v:'205',c:'児発２０・未計画・開所減２・評価減・',baseItem: true,},
  {s:'612666',v:'202',c:'児発２０・未計画２・',baseItem: true,},
  {s:'612668',v:'172',c:'児発２０・未計画２・評価減・',baseItem: true,},
  {s:'612670',v:'141',c:'児発２０・未計画２・開所減１・',baseItem: true,},
  {s:'612672',v:'120',c:'児発２０・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'612674',v:'172',c:'児発２０・未計画２・開所減２・',baseItem: true,},
  {s:'612676',v:'146',c:'児発２０・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611611',v:'2098',c:'児発２１・',baseItem: true,},
  {s:'612715',v:'1783',c:'児発２１・評価減・',baseItem: true,},
  {s:'611612',v:'1469',c:'児発２１・開所減１・',baseItem: true,},
  {s:'612718',v:'1249',c:'児発２１・開所減１・評価減・',baseItem: true,},
  {s:'611615',v:'1783',c:'児発２１・開所減２・',baseItem: true,},
  {s:'612721',v:'1516',c:'児発２１・開所減２・評価減・',baseItem: true,},
  {s:'611613',v:'1469',c:'児発２１・未計画・',baseItem: true,},
  {s:'612724',v:'1249',c:'児発２１・未計画・評価減・',baseItem: true,},
  {s:'611614',v:'1028',c:'児発２１・未計画・開所減１・',baseItem: true,},
  {s:'612727',v:'874',c:'児発２１・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611616',v:'1249',c:'児発２１・未計画・開所減２・',baseItem: true,},
  {s:'612730',v:'1062',c:'児発２１・未計画・開所減２・評価減・',baseItem: true,},
  {s:'612732',v:'1049',c:'児発２１・未計画２・',baseItem: true,},
  {s:'612734',v:'892',c:'児発２１・未計画２・評価減・',baseItem: true,},
  {s:'612736',v:'734',c:'児発２１・未計画２・開所減１・',baseItem: true,},
  {s:'612738',v:'624',c:'児発２１・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'612740',v:'892',c:'児発２１・未計画２・開所減２・',baseItem: true,},
  {s:'612742',v:'758',c:'児発２１・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611641',v:'1757',c:'児発２２・',baseItem: true,},
  {s:'612751',v:'1493',c:'児発２２・評価減・',baseItem: true,},
  {s:'611642',v:'1230',c:'児発２２・開所減１・',baseItem: true,},
  {s:'612754',v:'1046',c:'児発２２・開所減１・評価減・',baseItem: true,},
  {s:'611643',v:'1493',c:'児発２２・開所減２・',baseItem: true,},
  {s:'612757',v:'1269',c:'児発２２・開所減２・評価減・',baseItem: true,},
  {s:'611644',v:'1230',c:'児発２２・未計画・',baseItem: true,},
  {s:'612760',v:'1046',c:'児発２２・未計画・評価減・',baseItem: true,},
  {s:'611645',v:'861',c:'児発２２・未計画・開所減１・',baseItem: true,},
  {s:'612763',v:'732',c:'児発２２・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611646',v:'1046',c:'児発２２・未計画・開所減２・',baseItem: true,},
  {s:'612766',v:'889',c:'児発２２・未計画・開所減２・評価減・',baseItem: true,},
  {s:'612768',v:'879',c:'児発２２・未計画２・',baseItem: true,},
  {s:'612770',v:'747',c:'児発２２・未計画２・評価減・',baseItem: true,},
  {s:'612772',v:'615',c:'児発２２・未計画２・開所減１・',baseItem: true,},
  {s:'612774',v:'523',c:'児発２２・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'612776',v:'747',c:'児発２２・未計画２・開所減２・',baseItem: true,},
  {s:'612778',v:'635',c:'児発２２・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611651',v:'1511',c:'児発２３・',baseItem: true,},
  {s:'612781',v:'1284',c:'児発２３・評価減・',baseItem: true,},
  {s:'611652',v:'1058',c:'児発２３・開所減１・',baseItem: true,},
  {s:'612784',v:'899',c:'児発２３・開所減１・評価減・',baseItem: true,},
  {s:'611653',v:'1284',c:'児発２３・開所減２・',baseItem: true,},
  {s:'612787',v:'1091',c:'児発２３・開所減２・評価減・',baseItem: true,},
  {s:'611654',v:'1058',c:'児発２３・未計画・',baseItem: true,},
  {s:'612790',v:'899',c:'児発２３・未計画・評価減・',baseItem: true,},
  {s:'611655',v:'741',c:'児発２３・未計画・開所減１・',baseItem: true,},
  {s:'612793',v:'630',c:'児発２３・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611656',v:'899',c:'児発２３・未計画・開所減２・',baseItem: true,},
  {s:'612796',v:'764',c:'児発２３・未計画・開所減２・評価減・',baseItem: true,},
  {s:'612798',v:'756',c:'児発２３・未計画２・',baseItem: true,},
  {s:'612800',v:'643',c:'児発２３・未計画２・評価減・',baseItem: true,},
  {s:'612802',v:'529',c:'児発２３・未計画２・開所減１・',baseItem: true,},
  {s:'612804',v:'450',c:'児発２３・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'612806',v:'643',c:'児発２３・未計画２・開所減２・',baseItem: true,},
  {s:'612808',v:'547',c:'児発２３・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611661',v:'1326',c:'児発２４・',baseItem: true,},
  {s:'612811',v:'1127',c:'児発２４・評価減・',baseItem: true,},
  {s:'611662',v:'928',c:'児発２４・開所減１・',baseItem: true,},
  {s:'612814',v:'789',c:'児発２４・開所減１・評価減・',baseItem: true,},
  {s:'611663',v:'1127',c:'児発２４・開所減２・',baseItem: true,},
  {s:'612817',v:'958',c:'児発２４・開所減２・評価減・',baseItem: true,},
  {s:'611664',v:'928',c:'児発２４・未計画・',baseItem: true,},
  {s:'612820',v:'789',c:'児発２４・未計画・評価減・',baseItem: true,},
  {s:'611665',v:'650',c:'児発２４・未計画・開所減１・',baseItem: true,},
  {s:'612823',v:'553',c:'児発２４・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611666',v:'789',c:'児発２４・未計画・開所減２・',baseItem: true,},
  {s:'612826',v:'671',c:'児発２４・未計画・開所減２・評価減・',baseItem: true,},
  {s:'612828',v:'663',c:'児発２４・未計画２・',baseItem: true,},
  {s:'612830',v:'564',c:'児発２４・未計画２・評価減・',baseItem: true,},
  {s:'612832',v:'464',c:'児発２４・未計画２・開所減１・',baseItem: true,},
  {s:'612834',v:'394',c:'児発２４・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'612836',v:'564',c:'児発２４・未計画２・開所減２・',baseItem: true,},
  {s:'612838',v:'479',c:'児発２４・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611671',v:'1184',c:'児発２５・',baseItem: true,},
  {s:'612841',v:'1006',c:'児発２５・評価減・',baseItem: true,},
  {s:'611672',v:'829',c:'児発２５・開所減１・',baseItem: true,},
  {s:'612844',v:'705',c:'児発２５・開所減１・評価減・',baseItem: true,},
  {s:'611673',v:'1006',c:'児発２５・開所減２・',baseItem: true,},
  {s:'612847',v:'855',c:'児発２５・開所減２・評価減・',baseItem: true,},
  {s:'611674',v:'829',c:'児発２５・未計画・',baseItem: true,},
  {s:'612850',v:'705',c:'児発２５・未計画・評価減・',baseItem: true,},
  {s:'611675',v:'580',c:'児発２５・未計画・開所減１・',baseItem: true,},
  {s:'612853',v:'493',c:'児発２５・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611676',v:'705',c:'児発２５・未計画・開所減２・',baseItem: true,},
  {s:'612856',v:'599',c:'児発２５・未計画・開所減２・評価減・',baseItem: true,},
  {s:'612858',v:'592',c:'児発２５・未計画２・',baseItem: true,},
  {s:'612860',v:'503',c:'児発２５・未計画２・評価減・',baseItem: true,},
  {s:'612862',v:'414',c:'児発２５・未計画２・開所減１・',baseItem: true,},
  {s:'612864',v:'352',c:'児発２５・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'612866',v:'503',c:'児発２５・未計画２・開所減２・',baseItem: true,},
  {s:'612868',v:'428',c:'児発２５・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611621',v:'1069',c:'児発２６・',baseItem: true,},
  {s:'612871',v:'909',c:'児発２６・評価減・',baseItem: true,},
  {s:'611622',v:'748',c:'児発２６・開所減１・',baseItem: true,},
  {s:'612874',v:'636',c:'児発２６・開所減１・評価減・',baseItem: true,},
  {s:'611625',v:'909',c:'児発２６・開所減２・',baseItem: true,},
  {s:'612877',v:'773',c:'児発２６・開所減２・評価減・',baseItem: true,},
  {s:'611623',v:'748',c:'児発２６・未計画・',baseItem: true,},
  {s:'612880',v:'636',c:'児発２６・未計画・評価減・',baseItem: true,},
  {s:'611624',v:'524',c:'児発２６・未計画・開所減１・',baseItem: true,},
  {s:'612883',v:'445',c:'児発２６・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611626',v:'636',c:'児発２６・未計画・開所減２・',baseItem: true,},
  {s:'612886',v:'541',c:'児発２６・未計画・開所減２・評価減・',baseItem: true,},
  {s:'612888',v:'535',c:'児発２６・未計画２・',baseItem: true,},
  {s:'612890',v:'455',c:'児発２６・未計画２・評価減・',baseItem: true,},
  {s:'612892',v:'375',c:'児発２６・未計画２・開所減１・',baseItem: true,},
  {s:'612894',v:'319',c:'児発２６・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'612896',v:'455',c:'児発２６・未計画２・開所減２・',baseItem: true,},
  {s:'612898',v:'387',c:'児発２６・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'611631',v:'837',c:'児発２７・',baseItem: true,},
  {s:'612901',v:'711',c:'児発２７・評価減・',baseItem: true,},
  {s:'611632',v:'586',c:'児発２７・開所減１・',baseItem: true,},
  {s:'612904',v:'498',c:'児発２７・開所減１・評価減・',baseItem: true,},
  {s:'611635',v:'711',c:'児発２７・開所減２・',baseItem: true,},
  {s:'612907',v:'604',c:'児発２７・開所減２・評価減・',baseItem: true,},
  {s:'611633',v:'586',c:'児発２７・未計画・',baseItem: true,},
  {s:'612910',v:'498',c:'児発２７・未計画・評価減・',baseItem: true,},
  {s:'611634',v:'410',c:'児発２７・未計画・開所減１・',baseItem: true,},
  {s:'612913',v:'349',c:'児発２７・未計画・開所減１・評価減・',baseItem: true,},
  {s:'611636',v:'498',c:'児発２７・未計画・開所減２・',baseItem: true,},
  {s:'612916',v:'423',c:'児発２７・未計画・開所減２・評価減・',baseItem: true,},
  {s:'612918',v:'419',c:'児発２７・未計画２・',baseItem: true,},
  {s:'612920',v:'356',c:'児発２７・未計画２・評価減・',baseItem: true,},
  {s:'612922',v:'293',c:'児発２７・未計画２・開所減１・',baseItem: true,},
  {s:'612924',v:'249',c:'児発２７・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'612926',v:'356',c:'児発２７・未計画２・開所減２・',baseItem: true,},
  {s:'612928',v:'303',c:'児発２７・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'612930',v:'591',c:'共生型児発・',baseItem: true,},
  {s:'612932',v:'502',c:'共生型児発・評価減・',baseItem: true,},
  {s:'612934',v:'414',c:'共生型児発・開所減１・',baseItem: true,},
  {s:'612936',v:'352',c:'共生型児発・開所減１・評価減・',baseItem: true,},
  {s:'612938',v:'502',c:'共生型児発・開所減２・',baseItem: true,},
  {s:'612940',v:'427',c:'共生型児発・開所減２・評価減・',baseItem: true,},
  {s:'612950',v:'701',c:'基準該当児発Ⅰ・',baseItem: true,},
  {s:'612951',v:'596',c:'基準該当児発Ⅰ・評価減・',baseItem: true,},
  {s:'612952',v:'491',c:'基準該当児発Ⅰ・開所減１・',baseItem: true,},
  {s:'612953',v:'417',c:'基準該当児発Ⅰ・開所減１・評価減・',baseItem: true,},
  {s:'612954',v:'596',c:'基準該当児発Ⅰ・開所減２・',baseItem: true,},
  {s:'612955',v:'507',c:'基準該当児発Ⅰ・開所減２・評価減・',baseItem: true,},
  {s:'612956',v:'491',c:'基準該当児発Ⅰ・未計画・',baseItem: true,},
  {s:'612957',v:'417',c:'基準該当児発Ⅰ・未計画・評価減・',baseItem: true,},
  {s:'612958',v:'344',c:'基準該当児発Ⅰ・未計画・開所減１・',baseItem: true,},
  {s:'612959',v:'292',c:'基準該当児発Ⅰ・未計画・開所減１・評価減・',baseItem: true,},
  {s:'612960',v:'417',c:'基準該当児発Ⅰ・未計画・開所減２・',baseItem: true,},
  {s:'612961',v:'354',c:'基準該当児発Ⅰ・未計画・開所減２・評価減・',baseItem: true,},
  {s:'612962',v:'351',c:'基準該当児発Ⅰ・未計画２・',baseItem: true,},
  {s:'612963',v:'298',c:'基準該当児発Ⅰ・未計画２・評価減・',baseItem: true,},
  {s:'612964',v:'246',c:'基準該当児発Ⅰ・未計画２・開所減１・',baseItem: true,},
  {s:'612965',v:'209',c:'基準該当児発Ⅰ・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'612966',v:'298',c:'基準該当児発Ⅰ・未計画２・開所減２・',baseItem: true,},
  {s:'612967',v:'253',c:'基準該当児発Ⅰ・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'612970',v:'591',c:'基準該当児発Ⅱ・',baseItem: true,},
  {s:'612971',v:'502',c:'基準該当児発Ⅱ・評価減・',baseItem: true,},
  {s:'612972',v:'414',c:'基準該当児発Ⅱ・開所減１・',baseItem: true,},
  {s:'612973',v:'352',c:'基準該当児発Ⅱ・開所減１・評価減・',baseItem: true,},
  {s:'612974',v:'502',c:'基準該当児発Ⅱ・開所減２・',baseItem: true,},
  {s:'612975',v:'427',c:'基準該当児発Ⅱ・開所減２・評価減・',baseItem: true,},
  {s:'61E001',v:'2160',c:'児発２８・定超・',baseItem: true,},
  {s:'61E002',v:'1836',c:'児発２８・定超・評価減・',baseItem: true,},
  {s:'61E003',v:'1512',c:'児発２８・定超・開所減１・',baseItem: true,},
  {s:'61E004',v:'1285',c:'児発２８・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E005',v:'1836',c:'児発２８・定超・開所減２・',baseItem: true,},
  {s:'61E006',v:'1561',c:'児発２８・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E007',v:'1512',c:'児発２８・定超・未計画・',baseItem: true,},
  {s:'61E008',v:'1285',c:'児発２８・定超・未計画・評価減・',baseItem: true,},
  {s:'61E009',v:'1058',c:'児発２８・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E010',v:'899',c:'児発２８・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E011',v:'1285',c:'児発２８・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E012',v:'1092',c:'児発２８・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E013',v:'1080',c:'児発２８・定超・未計画２・',baseItem: true,},
  {s:'61E014',v:'918',c:'児発２８・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E015',v:'756',c:'児発２８・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E016',v:'643',c:'児発２８・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E017',v:'918',c:'児発２８・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E018',v:'780',c:'児発２８・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E019',v:'2085',c:'児発２８・地公体・定超・',baseItem: true,},
  {s:'61E020',v:'1772',c:'児発２８・地公体・定超・評価減・',baseItem: true,},
  {s:'61E021',v:'1460',c:'児発２８・地公体・定超・開所減１・',baseItem: true,},
  {s:'61E022',v:'1241',c:'児発２８・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E023',v:'1772',c:'児発２８・地公体・定超・開所減２・',baseItem: true,},
  {s:'61E024',v:'1506',c:'児発２８・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E025',v:'1460',c:'児発２８・地公体・定超・未計画・',baseItem: true,},
  {s:'61E026',v:'1241',c:'児発２８・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'61E027',v:'1022',c:'児発２８・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E028',v:'869',c:'児発２８・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E029',v:'1241',c:'児発２８・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E030',v:'1055',c:'児発２８・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E031',v:'1043',c:'児発２８・地公体・定超・未計画２・',baseItem: true,},
  {s:'61E032',v:'887',c:'児発２８・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E033',v:'730',c:'児発２８・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E034',v:'621',c:'児発２８・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E035',v:'887',c:'児発２８・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E036',v:'754',c:'児発２８・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E037',v:'2104',c:'児発２９・定超・',baseItem: true,},
  {s:'61E038',v:'1788',c:'児発２９・定超・評価減・',baseItem: true,},
  {s:'61E039',v:'1473',c:'児発２９・定超・開所減１・',baseItem: true,},
  {s:'61E040',v:'1252',c:'児発２９・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E041',v:'1788',c:'児発２９・定超・開所減２・',baseItem: true,},
  {s:'61E042',v:'1520',c:'児発２９・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E043',v:'1473',c:'児発２９・定超・未計画・',baseItem: true,},
  {s:'61E044',v:'1252',c:'児発２９・定超・未計画・評価減・',baseItem: true,},
  {s:'61E045',v:'1031',c:'児発２９・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E046',v:'876',c:'児発２９・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E047',v:'1252',c:'児発２９・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E048',v:'1064',c:'児発２９・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E049',v:'1052',c:'児発２９・定超・未計画２・',baseItem: true,},
  {s:'61E050',v:'894',c:'児発２９・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E051',v:'736',c:'児発２９・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E052',v:'626',c:'児発２９・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E053',v:'894',c:'児発２９・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E054',v:'760',c:'児発２９・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E055',v:'2030',c:'児発２９・地公体・定超・',baseItem: true,},
  {s:'61E056',v:'1726',c:'児発２９・地公体・定超・評価減・',baseItem: true,},
  {s:'61E057',v:'1421',c:'児発２９・地公体・定超・開所減１・',baseItem: true,},
  {s:'61E058',v:'1208',c:'児発２９・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E059',v:'1726',c:'児発２９・地公体・定超・開所減２・',baseItem: true,},
  {s:'61E060',v:'1467',c:'児発２９・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E061',v:'1421',c:'児発２９・地公体・定超・未計画・',baseItem: true,},
  {s:'61E062',v:'1208',c:'児発２９・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'61E063',v:'995',c:'児発２９・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E064',v:'846',c:'児発２９・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E065',v:'1208',c:'児発２９・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E066',v:'1027',c:'児発２９・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E067',v:'1015',c:'児発２９・地公体・定超・未計画２・',baseItem: true,},
  {s:'61E068',v:'863',c:'児発２９・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E069',v:'711',c:'児発２９・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E070',v:'604',c:'児発２９・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E071',v:'863',c:'児発２９・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E072',v:'734',c:'児発２９・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E073',v:'2051',c:'児発３０・定超・',baseItem: true,},
  {s:'61E074',v:'1743',c:'児発３０・定超・評価減・',baseItem: true,},
  {s:'61E075',v:'1436',c:'児発３０・定超・開所減１・',baseItem: true,},
  {s:'61E076',v:'1221',c:'児発３０・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E077',v:'1743',c:'児発３０・定超・開所減２・',baseItem: true,},
  {s:'61E078',v:'1482',c:'児発３０・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E079',v:'1436',c:'児発３０・定超・未計画・',baseItem: true,},
  {s:'61E080',v:'1221',c:'児発３０・定超・未計画・評価減・',baseItem: true,},
  {s:'61E081',v:'1005',c:'児発３０・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E082',v:'854',c:'児発３０・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E083',v:'1221',c:'児発３０・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E084',v:'1038',c:'児発３０・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E085',v:'1026',c:'児発３０・定超・未計画２・',baseItem: true,},
  {s:'61E086',v:'872',c:'児発３０・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E087',v:'718',c:'児発３０・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E088',v:'610',c:'児発３０・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E089',v:'872',c:'児発３０・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E090',v:'741',c:'児発３０・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E091',v:'1979',c:'児発３０・地公体・定超・',baseItem: true,},
  {s:'61E092',v:'1682',c:'児発３０・地公体・定超・評価減・',baseItem: true,},
  {s:'61E093',v:'1385',c:'児発３０・地公体・定超・開所減１・',baseItem: true,},
  {s:'61E094',v:'1177',c:'児発３０・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E095',v:'1682',c:'児発３０・地公体・定超・開所減２・',baseItem: true,},
  {s:'61E096',v:'1430',c:'児発３０・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E097',v:'1385',c:'児発３０・地公体・定超・未計画・',baseItem: true,},
  {s:'61E098',v:'1177',c:'児発３０・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'61E099',v:'970',c:'児発３０・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E100',v:'825',c:'児発３０・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E101',v:'1177',c:'児発３０・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E102',v:'1000',c:'児発３０・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E103',v:'990',c:'児発３０・地公体・定超・未計画２・',baseItem: true,},
  {s:'61E104',v:'842',c:'児発３０・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E105',v:'693',c:'児発３０・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E106',v:'589',c:'児発３０・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E107',v:'842',c:'児発３０・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E108',v:'716',c:'児発３０・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E109',v:'2001',c:'児発３１・定超・',baseItem: true,},
  {s:'61E110',v:'1701',c:'児発３１・定超・評価減・',baseItem: true,},
  {s:'61E111',v:'1401',c:'児発３１・定超・開所減１・',baseItem: true,},
  {s:'61E112',v:'1191',c:'児発３１・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E113',v:'1701',c:'児発３１・定超・開所減２・',baseItem: true,},
  {s:'61E114',v:'1446',c:'児発３１・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E115',v:'1401',c:'児発３１・定超・未計画・',baseItem: true,},
  {s:'61E116',v:'1191',c:'児発３１・定超・未計画・評価減・',baseItem: true,},
  {s:'61E117',v:'981',c:'児発３１・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E118',v:'834',c:'児発３１・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E119',v:'1191',c:'児発３１・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E120',v:'1012',c:'児発３１・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E121',v:'1001',c:'児発３１・定超・未計画２・',baseItem: true,},
  {s:'61E122',v:'851',c:'児発３１・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E123',v:'701',c:'児発３１・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E124',v:'596',c:'児発３１・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E125',v:'851',c:'児発３１・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E126',v:'723',c:'児発３１・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E127',v:'1931',c:'児発３１・地公体・定超・',baseItem: true,},
  {s:'61E128',v:'1641',c:'児発３１・地公体・定超・評価減・',baseItem: true,},
  {s:'61E129',v:'1352',c:'児発３１・地公体・定超・開所減１・',baseItem: true,},
  {s:'61E130',v:'1149',c:'児発３１・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E131',v:'1641',c:'児発３１・地公体・定超・開所減２・',baseItem: true,},
  {s:'61E132',v:'1395',c:'児発３１・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E133',v:'1352',c:'児発３１・地公体・定超・未計画・',baseItem: true,},
  {s:'61E134',v:'1149',c:'児発３１・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'61E135',v:'946',c:'児発３１・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E136',v:'804',c:'児発３１・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E137',v:'1149',c:'児発３１・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E138',v:'977',c:'児発３１・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E139',v:'966',c:'児発３１・地公体・定超・未計画２・',baseItem: true,},
  {s:'61E140',v:'821',c:'児発３１・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E141',v:'676',c:'児発３１・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E142',v:'575',c:'児発３１・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E143',v:'821',c:'児発３１・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E144',v:'698',c:'児発３１・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E145',v:'1981',c:'児発３２・定超・',baseItem: true,},
  {s:'61E146',v:'1684',c:'児発３２・定超・評価減・',baseItem: true,},
  {s:'61E147',v:'1387',c:'児発３２・定超・開所減１・',baseItem: true,},
  {s:'61E148',v:'1179',c:'児発３２・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E149',v:'1684',c:'児発３２・定超・開所減２・',baseItem: true,},
  {s:'61E150',v:'1431',c:'児発３２・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E151',v:'1387',c:'児発３２・定超・未計画・',baseItem: true,},
  {s:'61E152',v:'1179',c:'児発３２・定超・未計画・評価減・',baseItem: true,},
  {s:'61E153',v:'971',c:'児発３２・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E154',v:'825',c:'児発３２・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E155',v:'1179',c:'児発３２・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E156',v:'1002',c:'児発３２・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E157',v:'991',c:'児発３２・定超・未計画２・',baseItem: true,},
  {s:'61E158',v:'842',c:'児発３２・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E159',v:'694',c:'児発３２・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E160',v:'590',c:'児発３２・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E161',v:'842',c:'児発３２・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E162',v:'716',c:'児発３２・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E163',v:'1912',c:'児発３２・地公体・定超・',baseItem: true,},
  {s:'61E164',v:'1625',c:'児発３２・地公体・定超・評価減・',baseItem: true,},
  {s:'61E165',v:'1338',c:'児発３２・地公体・定超・開所減１・',baseItem: true,},
  {s:'61E166',v:'1137',c:'児発３２・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E167',v:'1625',c:'児発３２・地公体・定超・開所減２・',baseItem: true,},
  {s:'61E168',v:'1381',c:'児発３２・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E169',v:'1338',c:'児発３２・地公体・定超・未計画・',baseItem: true,},
  {s:'61E170',v:'1137',c:'児発３２・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'61E171',v:'937',c:'児発３２・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E172',v:'796',c:'児発３２・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E173',v:'1137',c:'児発３２・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E174',v:'966',c:'児発３２・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E175',v:'956',c:'児発３２・地公体・定超・未計画２・',baseItem: true,},
  {s:'61E176',v:'813',c:'児発３２・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E177',v:'669',c:'児発３２・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E178',v:'569',c:'児発３２・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E179',v:'813',c:'児発３２・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E180',v:'691',c:'児発３２・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E181',v:'1963',c:'児発３３・定超・',baseItem: true,},
  {s:'61E182',v:'1669',c:'児発３３・定超・評価減・',baseItem: true,},
  {s:'61E183',v:'1374',c:'児発３３・定超・開所減１・',baseItem: true,},
  {s:'61E184',v:'1168',c:'児発３３・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E185',v:'1669',c:'児発３３・定超・開所減２・',baseItem: true,},
  {s:'61E186',v:'1419',c:'児発３３・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E187',v:'1374',c:'児発３３・定超・未計画・',baseItem: true,},
  {s:'61E188',v:'1168',c:'児発３３・定超・未計画・評価減・',baseItem: true,},
  {s:'61E189',v:'962',c:'児発３３・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E190',v:'818',c:'児発３３・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E191',v:'1168',c:'児発３３・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E192',v:'993',c:'児発３３・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E193',v:'982',c:'児発３３・定超・未計画２・',baseItem: true,},
  {s:'61E194',v:'835',c:'児発３３・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E195',v:'687',c:'児発３３・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E196',v:'584',c:'児発３３・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E197',v:'835',c:'児発３３・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E198',v:'710',c:'児発３３・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E199',v:'1894',c:'児発３３・地公体・定超・',baseItem: true,},
  {s:'61E200',v:'1610',c:'児発３３・地公体・定超・評価減・',baseItem: true,},
  {s:'61E201',v:'1326',c:'児発３３・地公体・定超・開所減１・',baseItem: true,},
  {s:'61E202',v:'1127',c:'児発３３・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E203',v:'1610',c:'児発３３・地公体・定超・開所減２・',baseItem: true,},
  {s:'61E204',v:'1369',c:'児発３３・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E205',v:'1326',c:'児発３３・地公体・定超・未計画・',baseItem: true,},
  {s:'61E206',v:'1127',c:'児発３３・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'61E207',v:'928',c:'児発３３・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E208',v:'789',c:'児発３３・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E209',v:'1127',c:'児発３３・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E210',v:'958',c:'児発３３・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E211',v:'947',c:'児発３３・地公体・定超・未計画２・',baseItem: true,},
  {s:'61E212',v:'805',c:'児発３３・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E213',v:'663',c:'児発３３・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E214',v:'564',c:'児発３３・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E215',v:'805',c:'児発３３・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E216',v:'684',c:'児発３３・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E217',v:'1945',c:'児発３４・定超・',baseItem: true,},
  {s:'61E218',v:'1653',c:'児発３４・定超・評価減・',baseItem: true,},
  {s:'61E219',v:'1362',c:'児発３４・定超・開所減１・',baseItem: true,},
  {s:'61E220',v:'1158',c:'児発３４・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E221',v:'1653',c:'児発３４・定超・開所減２・',baseItem: true,},
  {s:'61E222',v:'1405',c:'児発３４・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E223',v:'1362',c:'児発３４・定超・未計画・',baseItem: true,},
  {s:'61E224',v:'1158',c:'児発３４・定超・未計画・評価減・',baseItem: true,},
  {s:'61E225',v:'953',c:'児発３４・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E226',v:'810',c:'児発３４・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E227',v:'1158',c:'児発３４・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E228',v:'984',c:'児発３４・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E229',v:'973',c:'児発３４・定超・未計画２・',baseItem: true,},
  {s:'61E230',v:'827',c:'児発３４・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E231',v:'681',c:'児発３４・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E232',v:'579',c:'児発３４・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E233',v:'827',c:'児発３４・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E234',v:'703',c:'児発３４・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E235',v:'1877',c:'児発３４・地公体・定超・',baseItem: true,},
  {s:'61E236',v:'1595',c:'児発３４・地公体・定超・評価減・',baseItem: true,},
  {s:'61E237',v:'1314',c:'児発３４・地公体・定超・開所減１・',baseItem: true,},
  {s:'61E238',v:'1117',c:'児発３４・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E239',v:'1595',c:'児発３４・地公体・定超・開所減２・',baseItem: true,},
  {s:'61E240',v:'1356',c:'児発３４・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E241',v:'1314',c:'児発３４・地公体・定超・未計画・',baseItem: true,},
  {s:'61E242',v:'1117',c:'児発３４・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'61E243',v:'920',c:'児発３４・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E244',v:'782',c:'児発３４・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E245',v:'1117',c:'児発３４・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E246',v:'949',c:'児発３４・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E247',v:'939',c:'児発３４・地公体・定超・未計画２・',baseItem: true,},
  {s:'61E248',v:'798',c:'児発３４・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E249',v:'657',c:'児発３４・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E250',v:'558',c:'児発３４・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E251',v:'798',c:'児発３４・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E252',v:'678',c:'児発３４・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E253',v:'1460',c:'児発３５・定超・',baseItem: true,},
  {s:'61E254',v:'1241',c:'児発３５・定超・評価減・',baseItem: true,},
  {s:'61E255',v:'1022',c:'児発３５・定超・開所減１・',baseItem: true,},
  {s:'61E256',v:'869',c:'児発３５・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E257',v:'1241',c:'児発３５・定超・開所減２・',baseItem: true,},
  {s:'61E258',v:'1055',c:'児発３５・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E259',v:'1022',c:'児発３５・定超・未計画・',baseItem: true,},
  {s:'61E260',v:'869',c:'児発３５・定超・未計画・評価減・',baseItem: true,},
  {s:'61E261',v:'715',c:'児発３５・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E262',v:'608',c:'児発３５・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E263',v:'869',c:'児発３５・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E264',v:'739',c:'児発３５・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E265',v:'730',c:'児発３５・定超・未計画２・',baseItem: true,},
  {s:'61E266',v:'621',c:'児発３５・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E267',v:'511',c:'児発３５・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E268',v:'434',c:'児発３５・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E269',v:'621',c:'児発３５・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E270',v:'528',c:'児発３５・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E271',v:'1409',c:'児発３５・地公体・定超・',baseItem: true,},
  {s:'61E272',v:'1198',c:'児発３５・地公体・定超・評価減・',baseItem: true,},
  {s:'61E273',v:'986',c:'児発３５・地公体・定超・開所減１・',baseItem: true,},
  {s:'61E274',v:'838',c:'児発３５・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E275',v:'1198',c:'児発３５・地公体・定超・開所減２・',baseItem: true,},
  {s:'61E276',v:'1018',c:'児発３５・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E277',v:'986',c:'児発３５・地公体・定超・未計画・',baseItem: true,},
  {s:'61E278',v:'838',c:'児発３５・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'61E279',v:'690',c:'児発３５・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E280',v:'587',c:'児発３５・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E281',v:'838',c:'児発３５・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E282',v:'712',c:'児発３５・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E283',v:'705',c:'児発３５・地公体・定超・未計画２・',baseItem: true,},
  {s:'61E284',v:'599',c:'児発３５・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E285',v:'494',c:'児発３５・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E286',v:'420',c:'児発３５・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E287',v:'599',c:'児発３５・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E288',v:'509',c:'児発３５・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E289',v:'1404',c:'児発３６・定超・',baseItem: true,},
  {s:'61E290',v:'1193',c:'児発３６・定超・評価減・',baseItem: true,},
  {s:'61E291',v:'983',c:'児発３６・定超・開所減１・',baseItem: true,},
  {s:'61E292',v:'836',c:'児発３６・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E293',v:'1193',c:'児発３６・定超・開所減２・',baseItem: true,},
  {s:'61E294',v:'1014',c:'児発３６・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E295',v:'983',c:'児発３６・定超・未計画・',baseItem: true,},
  {s:'61E296',v:'836',c:'児発３６・定超・未計画・評価減・',baseItem: true,},
  {s:'61E297',v:'688',c:'児発３６・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E298',v:'585',c:'児発３６・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E299',v:'836',c:'児発３６・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E300',v:'711',c:'児発３６・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E301',v:'702',c:'児発３６・定超・未計画２・',baseItem: true,},
  {s:'61E302',v:'597',c:'児発３６・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E303',v:'491',c:'児発３６・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E304',v:'417',c:'児発３６・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E305',v:'597',c:'児発３６・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E306',v:'507',c:'児発３６・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E307',v:'1355',c:'児発３６・地公体・定超・',baseItem: true,},
  {s:'61E308',v:'1152',c:'児発３６・地公体・定超・評価減・',baseItem: true,},
  {s:'61E309',v:'949',c:'児発３６・地公体・定超・開所減１・',baseItem: true,},
  {s:'61E310',v:'807',c:'児発３６・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E311',v:'1152',c:'児発３６・地公体・定超・開所減２・',baseItem: true,},
  {s:'61E312',v:'979',c:'児発３６・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E313',v:'949',c:'児発３６・地公体・定超・未計画・',baseItem: true,},
  {s:'61E314',v:'807',c:'児発３６・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'61E315',v:'664',c:'児発３６・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E316',v:'564',c:'児発３６・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E317',v:'807',c:'児発３６・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E318',v:'686',c:'児発３６・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E319',v:'678',c:'児発３６・地公体・定超・未計画２・',baseItem: true,},
  {s:'61E320',v:'576',c:'児発３６・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E321',v:'475',c:'児発３６・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E322',v:'404',c:'児発３６・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E323',v:'576',c:'児発３６・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E324',v:'490',c:'児発３６・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E325',v:'1351',c:'児発３７・定超・',baseItem: true,},
  {s:'61E326',v:'1148',c:'児発３７・定超・評価減・',baseItem: true,},
  {s:'61E327',v:'946',c:'児発３７・定超・開所減１・',baseItem: true,},
  {s:'61E328',v:'804',c:'児発３７・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E329',v:'1148',c:'児発３７・定超・開所減２・',baseItem: true,},
  {s:'61E330',v:'976',c:'児発３７・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E331',v:'946',c:'児発３７・定超・未計画・',baseItem: true,},
  {s:'61E332',v:'804',c:'児発３７・定超・未計画・評価減・',baseItem: true,},
  {s:'61E333',v:'662',c:'児発３７・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E334',v:'563',c:'児発３７・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E335',v:'804',c:'児発３７・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E336',v:'683',c:'児発３７・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E337',v:'676',c:'児発３７・定超・未計画２・',baseItem: true,},
  {s:'61E338',v:'575',c:'児発３７・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E339',v:'473',c:'児発３７・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E340',v:'402',c:'児発３７・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E341',v:'575',c:'児発３７・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E342',v:'489',c:'児発３７・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E343',v:'1303',c:'児発３７・地公体・定超・',baseItem: true,},
  {s:'61E344',v:'1108',c:'児発３７・地公体・定超・評価減・',baseItem: true,},
  {s:'61E345',v:'912',c:'児発３７・地公体・定超・開所減１・',baseItem: true,},
  {s:'61E346',v:'775',c:'児発３７・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E347',v:'1108',c:'児発３７・地公体・定超・開所減２・',baseItem: true,},
  {s:'61E348',v:'942',c:'児発３７・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E349',v:'912',c:'児発３７・地公体・定超・未計画・',baseItem: true,},
  {s:'61E350',v:'775',c:'児発３７・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'61E351',v:'638',c:'児発３７・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E352',v:'542',c:'児発３７・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E353',v:'775',c:'児発３７・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E354',v:'659',c:'児発３７・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E355',v:'652',c:'児発３７・地公体・定超・未計画２・',baseItem: true,},
  {s:'61E356',v:'554',c:'児発３７・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E357',v:'456',c:'児発３７・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E358',v:'388',c:'児発３７・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E359',v:'554',c:'児発３７・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E360',v:'471',c:'児発３７・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E361',v:'1301',c:'児発３８・定超・',baseItem: true,},
  {s:'61E362',v:'1106',c:'児発３８・定超・評価減・',baseItem: true,},
  {s:'61E363',v:'911',c:'児発３８・定超・開所減１・',baseItem: true,},
  {s:'61E364',v:'774',c:'児発３８・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E365',v:'1106',c:'児発３８・定超・開所減２・',baseItem: true,},
  {s:'61E366',v:'940',c:'児発３８・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E367',v:'911',c:'児発３８・定超・未計画・',baseItem: true,},
  {s:'61E368',v:'774',c:'児発３８・定超・未計画・評価減・',baseItem: true,},
  {s:'61E369',v:'638',c:'児発３８・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E370',v:'542',c:'児発３８・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E371',v:'774',c:'児発３８・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E372',v:'658',c:'児発３８・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E373',v:'651',c:'児発３８・定超・未計画２・',baseItem: true,},
  {s:'61E374',v:'553',c:'児発３８・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E375',v:'456',c:'児発３８・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E376',v:'388',c:'児発３８・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E377',v:'553',c:'児発３８・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E378',v:'470',c:'児発３８・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E379',v:'1256',c:'児発３８・地公体・定超・',baseItem: true,},
  {s:'61E380',v:'1068',c:'児発３８・地公体・定超・評価減・',baseItem: true,},
  {s:'61E381',v:'879',c:'児発３８・地公体・定超・開所減１・',baseItem: true,},
  {s:'61E382',v:'747',c:'児発３８・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E383',v:'1068',c:'児発３８・地公体・定超・開所減２・',baseItem: true,},
  {s:'61E384',v:'908',c:'児発３８・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E385',v:'879',c:'児発３８・地公体・定超・未計画・',baseItem: true,},
  {s:'61E386',v:'747',c:'児発３８・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'61E387',v:'615',c:'児発３８・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E388',v:'523',c:'児発３８・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E389',v:'747',c:'児発３８・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E390',v:'635',c:'児発３８・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E391',v:'628',c:'児発３８・地公体・定超・未計画２・',baseItem: true,},
  {s:'61E392',v:'534',c:'児発３８・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E393',v:'440',c:'児発３８・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E394',v:'374',c:'児発３８・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E395',v:'534',c:'児発３８・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E396',v:'454',c:'児発３８・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E397',v:'1281',c:'児発３９・定超・',baseItem: true,},
  {s:'61E398',v:'1089',c:'児発３９・定超・評価減・',baseItem: true,},
  {s:'61E399',v:'897',c:'児発３９・定超・開所減１・',baseItem: true,},
  {s:'61E400',v:'762',c:'児発３９・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E401',v:'1089',c:'児発３９・定超・開所減２・',baseItem: true,},
  {s:'61E402',v:'926',c:'児発３９・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E403',v:'897',c:'児発３９・定超・未計画・',baseItem: true,},
  {s:'61E404',v:'762',c:'児発３９・定超・未計画・評価減・',baseItem: true,},
  {s:'61E405',v:'628',c:'児発３９・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E406',v:'534',c:'児発３９・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E407',v:'762',c:'児発３９・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E408',v:'648',c:'児発３９・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E409',v:'641',c:'児発３９・定超・未計画２・',baseItem: true,},
  {s:'61E410',v:'545',c:'児発３９・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E411',v:'449',c:'児発３９・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E412',v:'382',c:'児発３９・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E413',v:'545',c:'児発３９・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E414',v:'463',c:'児発３９・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E415',v:'1236',c:'児発３９・地公体・定超・',baseItem: true,},
  {s:'61E416',v:'1051',c:'児発３９・地公体・定超・評価減・',baseItem: true,},
  {s:'61E417',v:'865',c:'児発３９・地公体・定超・開所減１・',baseItem: true,},
  {s:'61E418',v:'735',c:'児発３９・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E419',v:'1051',c:'児発３９・地公体・定超・開所減２・',baseItem: true,},
  {s:'61E420',v:'893',c:'児発３９・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E421',v:'865',c:'児発３９・地公体・定超・未計画・',baseItem: true,},
  {s:'61E422',v:'735',c:'児発３９・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'61E423',v:'606',c:'児発３９・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E424',v:'515',c:'児発３９・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E425',v:'735',c:'児発３９・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E426',v:'625',c:'児発３９・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E427',v:'618',c:'児発３９・地公体・定超・未計画２・',baseItem: true,},
  {s:'61E428',v:'525',c:'児発３９・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E429',v:'433',c:'児発３９・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E430',v:'368',c:'児発３９・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E431',v:'525',c:'児発３９・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E432',v:'446',c:'児発３９・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E433',v:'1263',c:'児発４０・定超・',baseItem: true,},
  {s:'61E434',v:'1074',c:'児発４０・定超・評価減・',baseItem: true,},
  {s:'61E435',v:'884',c:'児発４０・定超・開所減１・',baseItem: true,},
  {s:'61E436',v:'751',c:'児発４０・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E437',v:'1074',c:'児発４０・定超・開所減２・',baseItem: true,},
  {s:'61E438',v:'913',c:'児発４０・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E439',v:'884',c:'児発４０・定超・未計画・',baseItem: true,},
  {s:'61E440',v:'751',c:'児発４０・定超・未計画・評価減・',baseItem: true,},
  {s:'61E441',v:'619',c:'児発４０・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E442',v:'526',c:'児発４０・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E443',v:'751',c:'児発４０・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E444',v:'638',c:'児発４０・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E445',v:'632',c:'児発４０・定超・未計画２・',baseItem: true,},
  {s:'61E446',v:'537',c:'児発４０・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E447',v:'442',c:'児発４０・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E448',v:'376',c:'児発４０・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E449',v:'537',c:'児発４０・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E450',v:'456',c:'児発４０・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E451',v:'1219',c:'児発４０・地公体・定超・',baseItem: true,},
  {s:'61E452',v:'1036',c:'児発４０・地公体・定超・評価減・',baseItem: true,},
  {s:'61E453',v:'853',c:'児発４０・地公体・定超・開所減１・',baseItem: true,},
  {s:'61E454',v:'725',c:'児発４０・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E455',v:'1036',c:'児発４０・地公体・定超・開所減２・',baseItem: true,},
  {s:'61E456',v:'881',c:'児発４０・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E457',v:'853',c:'児発４０・地公体・定超・未計画・',baseItem: true,},
  {s:'61E458',v:'725',c:'児発４０・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'61E459',v:'597',c:'児発４０・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E460',v:'507',c:'児発４０・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E461',v:'725',c:'児発４０・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E462',v:'616',c:'児発４０・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E463',v:'610',c:'児発４０・地公体・定超・未計画２・',baseItem: true,},
  {s:'61E464',v:'519',c:'児発４０・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E465',v:'427',c:'児発４０・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E466',v:'363',c:'児発４０・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E467',v:'519',c:'児発４０・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E468',v:'441',c:'児発４０・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E469',v:'1245',c:'児発４１・定超・',baseItem: true,},
  {s:'61E470',v:'1058',c:'児発４１・定超・評価減・',baseItem: true,},
  {s:'61E471',v:'872',c:'児発４１・定超・開所減１・',baseItem: true,},
  {s:'61E472',v:'741',c:'児発４１・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E473',v:'1058',c:'児発４１・定超・開所減２・',baseItem: true,},
  {s:'61E474',v:'899',c:'児発４１・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E475',v:'872',c:'児発４１・定超・未計画・',baseItem: true,},
  {s:'61E476',v:'741',c:'児発４１・定超・未計画・評価減・',baseItem: true,},
  {s:'61E477',v:'610',c:'児発４１・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E478',v:'519',c:'児発４１・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E479',v:'741',c:'児発４１・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E480',v:'630',c:'児発４１・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E481',v:'623',c:'児発４１・定超・未計画２・',baseItem: true,},
  {s:'61E482',v:'530',c:'児発４１・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E483',v:'436',c:'児発４１・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E484',v:'371',c:'児発４１・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E485',v:'530',c:'児発４１・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E486',v:'451',c:'児発４１・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E487',v:'1201',c:'児発４１・地公体・定超・',baseItem: true,},
  {s:'61E488',v:'1021',c:'児発４１・地公体・定超・評価減・',baseItem: true,},
  {s:'61E489',v:'841',c:'児発４１・地公体・定超・開所減１・',baseItem: true,},
  {s:'61E490',v:'715',c:'児発４１・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E491',v:'1021',c:'児発４１・地公体・定超・開所減２・',baseItem: true,},
  {s:'61E492',v:'868',c:'児発４１・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E493',v:'841',c:'児発４１・地公体・定超・未計画・',baseItem: true,},
  {s:'61E494',v:'715',c:'児発４１・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'61E495',v:'589',c:'児発４１・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E496',v:'501',c:'児発４１・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E497',v:'715',c:'児発４１・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E498',v:'608',c:'児発４１・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E499',v:'601',c:'児発４１・地公体・定超・未計画２・',baseItem: true,},
  {s:'61E500',v:'511',c:'児発４１・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E501',v:'421',c:'児発４１・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E502',v:'358',c:'児発４１・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E503',v:'511',c:'児発４１・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E504',v:'434',c:'児発４１・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E505',v:'1227',c:'児発４２・定超・',baseItem: true,},
  {s:'61E506',v:'1043',c:'児発４２・定超・評価減・',baseItem: true,},
  {s:'61E507',v:'859',c:'児発４２・定超・開所減１・',baseItem: true,},
  {s:'61E508',v:'730',c:'児発４２・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E509',v:'1043',c:'児発４２・定超・開所減２・',baseItem: true,},
  {s:'61E510',v:'887',c:'児発４２・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E511',v:'859',c:'児発４２・定超・未計画・',baseItem: true,},
  {s:'61E512',v:'730',c:'児発４２・定超・未計画・評価減・',baseItem: true,},
  {s:'61E513',v:'601',c:'児発４２・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E514',v:'511',c:'児発４２・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E515',v:'730',c:'児発４２・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E516',v:'621',c:'児発４２・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E517',v:'614',c:'児発４２・定超・未計画２・',baseItem: true,},
  {s:'61E518',v:'522',c:'児発４２・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E519',v:'430',c:'児発４２・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E520',v:'366',c:'児発４２・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E521',v:'522',c:'児発４２・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E522',v:'444',c:'児発４２・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E523',v:'1184',c:'児発４２・地公体・定超・',baseItem: true,},
  {s:'61E524',v:'1006',c:'児発４２・地公体・定超・評価減・',baseItem: true,},
  {s:'61E525',v:'829',c:'児発４２・地公体・定超・開所減１・',baseItem: true,},
  {s:'61E526',v:'705',c:'児発４２・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E527',v:'1006',c:'児発４２・地公体・定超・開所減２・',baseItem: true,},
  {s:'61E528',v:'855',c:'児発４２・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E529',v:'829',c:'児発４２・地公体・定超・未計画・',baseItem: true,},
  {s:'61E530',v:'705',c:'児発４２・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'61E531',v:'580',c:'児発４２・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E532',v:'493',c:'児発４２・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E533',v:'705',c:'児発４２・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E534',v:'599',c:'児発４２・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E535',v:'592',c:'児発４２・地公体・定超・未計画２・',baseItem: true,},
  {s:'61E536',v:'503',c:'児発４２・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E537',v:'414',c:'児発４２・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E538',v:'352',c:'児発４２・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E539',v:'503',c:'児発４２・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E540',v:'428',c:'児発４２・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E541',v:'1170',c:'児発４３・定超・',baseItem: true,},
  {s:'61E542',v:'995',c:'児発４３・定超・評価減・',baseItem: true,},
  {s:'61E543',v:'819',c:'児発４３・定超・開所減１・',baseItem: true,},
  {s:'61E544',v:'696',c:'児発４３・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E545',v:'995',c:'児発４３・定超・開所減２・',baseItem: true,},
  {s:'61E546',v:'846',c:'児発４３・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E547',v:'819',c:'児発４３・定超・未計画・',baseItem: true,},
  {s:'61E548',v:'696',c:'児発４３・定超・未計画・評価減・',baseItem: true,},
  {s:'61E549',v:'573',c:'児発４３・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E550',v:'487',c:'児発４３・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E551',v:'696',c:'児発４３・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E552',v:'592',c:'児発４３・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E553',v:'585',c:'児発４３・定超・未計画２・',baseItem: true,},
  {s:'61E554',v:'497',c:'児発４３・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E555',v:'410',c:'児発４３・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E556',v:'349',c:'児発４３・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E557',v:'497',c:'児発４３・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E558',v:'422',c:'児発４３・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E559',v:'1129',c:'児発４３・地公体・定超・',baseItem: true,},
  {s:'61E560',v:'960',c:'児発４３・地公体・定超・評価減・',baseItem: true,},
  {s:'61E561',v:'790',c:'児発４３・地公体・定超・開所減１・',baseItem: true,},
  {s:'61E562',v:'672',c:'児発４３・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E563',v:'960',c:'児発４３・地公体・定超・開所減２・',baseItem: true,},
  {s:'61E564',v:'816',c:'児発４３・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E565',v:'790',c:'児発４３・地公体・定超・未計画・',baseItem: true,},
  {s:'61E566',v:'672',c:'児発４３・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'61E567',v:'553',c:'児発４３・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E568',v:'470',c:'児発４３・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E569',v:'672',c:'児発４３・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E570',v:'571',c:'児発４３・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E571',v:'565',c:'児発４３・地公体・定超・未計画２・',baseItem: true,},
  {s:'61E572',v:'480',c:'児発４３・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E573',v:'396',c:'児発４３・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E574',v:'337',c:'児発４３・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E575',v:'480',c:'児発４３・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E576',v:'408',c:'児発４３・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E577',v:'1118',c:'児発４４・定超・',baseItem: true,},
  {s:'61E578',v:'950',c:'児発４４・定超・評価減・',baseItem: true,},
  {s:'61E579',v:'783',c:'児発４４・定超・開所減１・',baseItem: true,},
  {s:'61E580',v:'666',c:'児発４４・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E581',v:'950',c:'児発４４・定超・開所減２・',baseItem: true,},
  {s:'61E582',v:'808',c:'児発４４・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E583',v:'783',c:'児発４４・定超・未計画・',baseItem: true,},
  {s:'61E584',v:'666',c:'児発４４・定超・未計画・評価減・',baseItem: true,},
  {s:'61E585',v:'548',c:'児発４４・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E586',v:'466',c:'児発４４・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E587',v:'666',c:'児発４４・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E588',v:'566',c:'児発４４・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E589',v:'559',c:'児発４４・定超・未計画２・',baseItem: true,},
  {s:'61E590',v:'475',c:'児発４４・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E591',v:'391',c:'児発４４・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E592',v:'332',c:'児発４４・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E593',v:'475',c:'児発４４・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E594',v:'404',c:'児発４４・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E595',v:'1079',c:'児発４４・地公体・定超・',baseItem: true,},
  {s:'61E596',v:'917',c:'児発４４・地公体・定超・評価減・',baseItem: true,},
  {s:'61E597',v:'755',c:'児発４４・地公体・定超・開所減１・',baseItem: true,},
  {s:'61E598',v:'642',c:'児発４４・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E599',v:'917',c:'児発４４・地公体・定超・開所減２・',baseItem: true,},
  {s:'61E600',v:'779',c:'児発４４・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E601',v:'755',c:'児発４４・地公体・定超・未計画・',baseItem: true,},
  {s:'61E602',v:'642',c:'児発４４・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'61E603',v:'529',c:'児発４４・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E604',v:'450',c:'児発４４・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E605',v:'642',c:'児発４４・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E606',v:'546',c:'児発４４・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E607',v:'540',c:'児発４４・地公体・定超・未計画２・',baseItem: true,},
  {s:'61E608',v:'459',c:'児発４４・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E609',v:'378',c:'児発４４・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E610',v:'321',c:'児発４４・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E611',v:'459',c:'児発４４・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E612',v:'390',c:'児発４４・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E613',v:'1068',c:'児発４５・定超・',baseItem: true,},
  {s:'61E614',v:'908',c:'児発４５・定超・評価減・',baseItem: true,},
  {s:'61E615',v:'748',c:'児発４５・定超・開所減１・',baseItem: true,},
  {s:'61E616',v:'636',c:'児発４５・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E617',v:'908',c:'児発４５・定超・開所減２・',baseItem: true,},
  {s:'61E618',v:'772',c:'児発４５・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E619',v:'748',c:'児発４５・定超・未計画・',baseItem: true,},
  {s:'61E620',v:'636',c:'児発４５・定超・未計画・評価減・',baseItem: true,},
  {s:'61E621',v:'524',c:'児発４５・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E622',v:'445',c:'児発４５・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E623',v:'636',c:'児発４５・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E624',v:'541',c:'児発４５・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E625',v:'534',c:'児発４５・定超・未計画２・',baseItem: true,},
  {s:'61E626',v:'454',c:'児発４５・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E627',v:'374',c:'児発４５・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E628',v:'318',c:'児発４５・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E629',v:'454',c:'児発４５・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E630',v:'386',c:'児発４５・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E631',v:'1031',c:'児発４５・地公体・定超・',baseItem: true,},
  {s:'61E632',v:'876',c:'児発４５・地公体・定超・評価減・',baseItem: true,},
  {s:'61E633',v:'722',c:'児発４５・地公体・定超・開所減１・',baseItem: true,},
  {s:'61E634',v:'614',c:'児発４５・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E635',v:'876',c:'児発４５・地公体・定超・開所減２・',baseItem: true,},
  {s:'61E636',v:'745',c:'児発４５・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E637',v:'722',c:'児発４５・地公体・定超・未計画・',baseItem: true,},
  {s:'61E638',v:'614',c:'児発４５・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'61E639',v:'505',c:'児発４５・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E640',v:'429',c:'児発４５・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E641',v:'614',c:'児発４５・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E642',v:'522',c:'児発４５・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E643',v:'516',c:'児発４５・地公体・定超・未計画２・',baseItem: true,},
  {s:'61E644',v:'439',c:'児発４５・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E645',v:'361',c:'児発４５・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E646',v:'307',c:'児発４５・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E647',v:'439',c:'児発４５・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E648',v:'373',c:'児発４５・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E649',v:'1048',c:'児発４６・定超・',baseItem: true,},
  {s:'61E650',v:'891',c:'児発４６・定超・評価減・',baseItem: true,},
  {s:'61E651',v:'734',c:'児発４６・定超・開所減１・',baseItem: true,},
  {s:'61E652',v:'624',c:'児発４６・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E653',v:'891',c:'児発４６・定超・開所減２・',baseItem: true,},
  {s:'61E654',v:'757',c:'児発４６・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E655',v:'734',c:'児発４６・定超・未計画・',baseItem: true,},
  {s:'61E656',v:'624',c:'児発４６・定超・未計画・評価減・',baseItem: true,},
  {s:'61E657',v:'514',c:'児発４６・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E658',v:'437',c:'児発４６・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E659',v:'624',c:'児発４６・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E660',v:'530',c:'児発４６・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E661',v:'524',c:'児発４６・定超・未計画２・',baseItem: true,},
  {s:'61E662',v:'445',c:'児発４６・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E663',v:'367',c:'児発４６・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E664',v:'312',c:'児発４６・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E665',v:'445',c:'児発４６・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E666',v:'378',c:'児発４６・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E667',v:'1012',c:'児発４６・地公体・定超・',baseItem: true,},
  {s:'61E668',v:'860',c:'児発４６・地公体・定超・評価減・',baseItem: true,},
  {s:'61E669',v:'708',c:'児発４６・地公体・定超・開所減１・',baseItem: true,},
  {s:'61E670',v:'602',c:'児発４６・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E671',v:'860',c:'児発４６・地公体・定超・開所減２・',baseItem: true,},
  {s:'61E672',v:'731',c:'児発４６・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E673',v:'708',c:'児発４６・地公体・定超・未計画・',baseItem: true,},
  {s:'61E674',v:'602',c:'児発４６・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'61E675',v:'496',c:'児発４６・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E676',v:'422',c:'児発４６・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E677',v:'602',c:'児発４６・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E678',v:'512',c:'児発４６・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E679',v:'506',c:'児発４６・地公体・定超・未計画２・',baseItem: true,},
  {s:'61E680',v:'430',c:'児発４６・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E681',v:'354',c:'児発４６・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E682',v:'301',c:'児発４６・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E683',v:'430',c:'児発４６・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E684',v:'366',c:'児発４６・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E685',v:'1030',c:'児発４７・定超・',baseItem: true,},
  {s:'61E686',v:'876',c:'児発４７・定超・評価減・',baseItem: true,},
  {s:'61E687',v:'721',c:'児発４７・定超・開所減１・',baseItem: true,},
  {s:'61E688',v:'613',c:'児発４７・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E689',v:'876',c:'児発４７・定超・開所減２・',baseItem: true,},
  {s:'61E690',v:'745',c:'児発４７・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E691',v:'721',c:'児発４７・定超・未計画・',baseItem: true,},
  {s:'61E692',v:'613',c:'児発４７・定超・未計画・評価減・',baseItem: true,},
  {s:'61E693',v:'505',c:'児発４７・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E694',v:'429',c:'児発４７・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E695',v:'613',c:'児発４７・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E696',v:'521',c:'児発４７・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E697',v:'515',c:'児発４７・定超・未計画２・',baseItem: true,},
  {s:'61E698',v:'438',c:'児発４７・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E699',v:'361',c:'児発４７・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E700',v:'307',c:'児発４７・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E701',v:'438',c:'児発４７・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E702',v:'372',c:'児発４７・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E703',v:'994',c:'児発４７・地公体・定超・',baseItem: true,},
  {s:'61E704',v:'845',c:'児発４７・地公体・定超・評価減・',baseItem: true,},
  {s:'61E705',v:'696',c:'児発４７・地公体・定超・開所減１・',baseItem: true,},
  {s:'61E706',v:'592',c:'児発４７・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E707',v:'845',c:'児発４７・地公体・定超・開所減２・',baseItem: true,},
  {s:'61E708',v:'718',c:'児発４７・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E709',v:'696',c:'児発４７・地公体・定超・未計画・',baseItem: true,},
  {s:'61E710',v:'592',c:'児発４７・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'61E711',v:'487',c:'児発４７・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E712',v:'414',c:'児発４７・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E713',v:'592',c:'児発４７・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E714',v:'503',c:'児発４７・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E715',v:'497',c:'児発４７・地公体・定超・未計画２・',baseItem: true,},
  {s:'61E716',v:'422',c:'児発４７・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E717',v:'348',c:'児発４７・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E718',v:'296',c:'児発４７・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E719',v:'422',c:'児発４７・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E720',v:'359',c:'児発４７・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E721',v:'1012',c:'児発４８・定超・',baseItem: true,},
  {s:'61E722',v:'860',c:'児発４８・定超・評価減・',baseItem: true,},
  {s:'61E723',v:'708',c:'児発４８・定超・開所減１・',baseItem: true,},
  {s:'61E724',v:'602',c:'児発４８・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E725',v:'860',c:'児発４８・定超・開所減２・',baseItem: true,},
  {s:'61E726',v:'731',c:'児発４８・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E727',v:'708',c:'児発４８・定超・未計画・',baseItem: true,},
  {s:'61E728',v:'602',c:'児発４８・定超・未計画・評価減・',baseItem: true,},
  {s:'61E729',v:'496',c:'児発４８・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E730',v:'422',c:'児発４８・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E731',v:'602',c:'児発４８・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E732',v:'512',c:'児発４８・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E733',v:'506',c:'児発４８・定超・未計画２・',baseItem: true,},
  {s:'61E734',v:'430',c:'児発４８・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E735',v:'354',c:'児発４８・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E736',v:'301',c:'児発４８・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E737',v:'430',c:'児発４８・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E738',v:'366',c:'児発４８・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E739',v:'976',c:'児発４８・地公体・定超・',baseItem: true,},
  {s:'61E740',v:'830',c:'児発４８・地公体・定超・評価減・',baseItem: true,},
  {s:'61E741',v:'683',c:'児発４８・地公体・定超・開所減１・',baseItem: true,},
  {s:'61E742',v:'581',c:'児発４８・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E743',v:'830',c:'児発４８・地公体・定超・開所減２・',baseItem: true,},
  {s:'61E744',v:'706',c:'児発４８・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E745',v:'683',c:'児発４８・地公体・定超・未計画・',baseItem: true,},
  {s:'61E746',v:'581',c:'児発４８・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'61E747',v:'478',c:'児発４８・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E748',v:'406',c:'児発４８・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E749',v:'581',c:'児発４８・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E750',v:'494',c:'児発４８・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E751',v:'488',c:'児発４８・地公体・定超・未計画２・',baseItem: true,},
  {s:'61E752',v:'415',c:'児発４８・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E753',v:'342',c:'児発４８・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E754',v:'291',c:'児発４８・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E755',v:'415',c:'児発４８・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E756',v:'353',c:'児発４８・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618111',v:'760',c:'児発１・定超・',baseItem: true,},
  {s:'618250',v:'646',c:'児発１・定超・評価減・',baseItem: true,},
  {s:'618113',v:'532',c:'児発１・定超・開所減１・',baseItem: true,},
  {s:'618252',v:'452',c:'児発１・定超・開所減１・評価減・',baseItem: true,},
  {s:'618701',v:'646',c:'児発１・定超・開所減２・',baseItem: true,},
  {s:'618254',v:'549',c:'児発１・定超・開所減２・評価減・',baseItem: true,},
  {s:'618115',v:'532',c:'児発１・定超・未計画・',baseItem: true,},
  {s:'618256',v:'452',c:'児発１・定超・未計画・評価減・',baseItem: true,},
  {s:'618117',v:'372',c:'児発１・定超・未計画・開所減１・',baseItem: true,},
  {s:'618258',v:'316',c:'児発１・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618703',v:'452',c:'児発１・定超・未計画・開所減２・',baseItem: true,},
  {s:'618260',v:'384',c:'児発１・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'618262',v:'380',c:'児発１・定超・未計画２・',baseItem: true,},
  {s:'618264',v:'323',c:'児発１・定超・未計画２・評価減・',baseItem: true,},
  {s:'618266',v:'266',c:'児発１・定超・未計画２・開所減１・',baseItem: true,},
  {s:'618268',v:'226',c:'児発１・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'618270',v:'323',c:'児発１・定超・未計画２・開所減２・',baseItem: true,},
  {s:'618272',v:'275',c:'児発１・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618119',v:'734',c:'児発１・地公体・定超・',baseItem: true,},
  {s:'618274',v:'624',c:'児発１・地公体・定超・評価減・',baseItem: true,},
  {s:'618121',v:'514',c:'児発１・地公体・定超・開所減１・',baseItem: true,},
  {s:'618276',v:'437',c:'児発１・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'618705',v:'624',c:'児発１・地公体・定超・開所減２・',baseItem: true,},
  {s:'618278',v:'530',c:'児発１・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'618123',v:'514',c:'児発１・地公体・定超・未計画・',baseItem: true,},
  {s:'618280',v:'437',c:'児発１・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'618125',v:'360',c:'児発１・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'618282',v:'306',c:'児発１・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618707',v:'437',c:'児発１・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'618284',v:'371',c:'児発１・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'618286',v:'367',c:'児発１・地公体・定超・未計画２・',baseItem: true,},
  {s:'618288',v:'312',c:'児発１・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'618290',v:'257',c:'児発１・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'618292',v:'218',c:'児発１・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'618294',v:'312',c:'児発１・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'618296',v:'265',c:'児発１・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618131',v:'704',c:'児発２・定超・',baseItem: true,},
  {s:'618300',v:'598',c:'児発２・定超・評価減・',baseItem: true,},
  {s:'618133',v:'493',c:'児発２・定超・開所減１・',baseItem: true,},
  {s:'618302',v:'419',c:'児発２・定超・開所減１・評価減・',baseItem: true,},
  {s:'618709',v:'598',c:'児発２・定超・開所減２・',baseItem: true,},
  {s:'618304',v:'508',c:'児発２・定超・開所減２・評価減・',baseItem: true,},
  {s:'618135',v:'493',c:'児発２・定超・未計画・',baseItem: true,},
  {s:'618306',v:'419',c:'児発２・定超・未計画・評価減・',baseItem: true,},
  {s:'618137',v:'345',c:'児発２・定超・未計画・開所減１・',baseItem: true,},
  {s:'618308',v:'293',c:'児発２・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618711',v:'419',c:'児発２・定超・未計画・開所減２・',baseItem: true,},
  {s:'618310',v:'356',c:'児発２・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'618351',v:'352',c:'児発２・定超・未計画２・',baseItem: true,},
  {s:'618353',v:'299',c:'児発２・定超・未計画２・評価減・',baseItem: true,},
  {s:'618355',v:'246',c:'児発２・定超・未計画２・開所減１・',baseItem: true,},
  {s:'618357',v:'209',c:'児発２・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'618359',v:'299',c:'児発２・定超・未計画２・開所減２・',baseItem: true,},
  {s:'618361',v:'254',c:'児発２・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618139',v:'679',c:'児発２・地公体・定超・',baseItem: true,},
  {s:'618363',v:'577',c:'児発２・地公体・定超・評価減・',baseItem: true,},
  {s:'618141',v:'475',c:'児発２・地公体・定超・開所減１・',baseItem: true,},
  {s:'618365',v:'404',c:'児発２・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'618713',v:'577',c:'児発２・地公体・定超・開所減２・',baseItem: true,},
  {s:'618367',v:'490',c:'児発２・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'618143',v:'475',c:'児発２・地公体・定超・未計画・',baseItem: true,},
  {s:'618369',v:'404',c:'児発２・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'618145',v:'333',c:'児発２・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'618371',v:'283',c:'児発２・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618715',v:'404',c:'児発２・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'618373',v:'343',c:'児発２・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'618375',v:'340',c:'児発２・地公体・定超・未計画２・',baseItem: true,},
  {s:'618377',v:'289',c:'児発２・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'618379',v:'238',c:'児発２・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'618381',v:'202',c:'児発２・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'618383',v:'289',c:'児発２・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'618385',v:'246',c:'児発２・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618151',v:'651',c:'児発３・定超・',baseItem: true,},
  {s:'618390',v:'553',c:'児発３・定超・評価減・',baseItem: true,},
  {s:'618153',v:'456',c:'児発３・定超・開所減１・',baseItem: true,},
  {s:'618392',v:'388',c:'児発３・定超・開所減１・評価減・',baseItem: true,},
  {s:'618717',v:'553',c:'児発３・定超・開所減２・',baseItem: true,},
  {s:'618394',v:'470',c:'児発３・定超・開所減２・評価減・',baseItem: true,},
  {s:'618155',v:'456',c:'児発３・定超・未計画・',baseItem: true,},
  {s:'618396',v:'388',c:'児発３・定超・未計画・評価減・',baseItem: true,},
  {s:'618157',v:'319',c:'児発３・定超・未計画・開所減１・',baseItem: true,},
  {s:'618398',v:'271',c:'児発３・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618719',v:'388',c:'児発３・定超・未計画・開所減２・',baseItem: true,},
  {s:'618400',v:'330',c:'児発３・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'618402',v:'326',c:'児発３・定超・未計画２・',baseItem: true,},
  {s:'618404',v:'277',c:'児発３・定超・未計画２・評価減・',baseItem: true,},
  {s:'618406',v:'228',c:'児発３・定超・未計画２・開所減１・',baseItem: true,},
  {s:'618408',v:'194',c:'児発３・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'618410',v:'277',c:'児発３・定超・未計画２・開所減２・',baseItem: true,},
  {s:'618451',v:'235',c:'児発３・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618159',v:'628',c:'児発３・地公体・定超・',baseItem: true,},
  {s:'618453',v:'534',c:'児発３・地公体・定超・評価減・',baseItem: true,},
  {s:'618161',v:'440',c:'児発３・地公体・定超・開所減１・',baseItem: true,},
  {s:'618455',v:'374',c:'児発３・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'618721',v:'534',c:'児発３・地公体・定超・開所減２・',baseItem: true,},
  {s:'618457',v:'454',c:'児発３・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'618163',v:'440',c:'児発３・地公体・定超・未計画・',baseItem: true,},
  {s:'618459',v:'374',c:'児発３・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'618165',v:'308',c:'児発３・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'618461',v:'262',c:'児発３・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618723',v:'374',c:'児発３・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'618463',v:'318',c:'児発３・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'618465',v:'314',c:'児発３・地公体・定超・未計画２・',baseItem: true,},
  {s:'618467',v:'267',c:'児発３・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'618469',v:'220',c:'児発３・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'618471',v:'187',c:'児発３・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'618473',v:'267',c:'児発３・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'618475',v:'227',c:'児発３・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618171',v:'601',c:'児発４・定超・',baseItem: true,},
  {s:'618480',v:'511',c:'児発４・定超・評価減・',baseItem: true,},
  {s:'618173',v:'421',c:'児発４・定超・開所減１・',baseItem: true,},
  {s:'618482',v:'358',c:'児発４・定超・開所減１・評価減・',baseItem: true,},
  {s:'618725',v:'511',c:'児発４・定超・開所減２・',baseItem: true,},
  {s:'618484',v:'434',c:'児発４・定超・開所減２・評価減・',baseItem: true,},
  {s:'618175',v:'421',c:'児発４・定超・未計画・',baseItem: true,},
  {s:'618486',v:'358',c:'児発４・定超・未計画・評価減・',baseItem: true,},
  {s:'618177',v:'295',c:'児発４・定超・未計画・開所減１・',baseItem: true,},
  {s:'618488',v:'251',c:'児発４・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618727',v:'358',c:'児発４・定超・未計画・開所減２・',baseItem: true,},
  {s:'618490',v:'304',c:'児発４・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'618492',v:'301',c:'児発４・定超・未計画２・',baseItem: true,},
  {s:'618494',v:'256',c:'児発４・定超・未計画２・評価減・',baseItem: true,},
  {s:'618496',v:'211',c:'児発４・定超・未計画２・開所減１・',baseItem: true,},
  {s:'618498',v:'179',c:'児発４・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'618500',v:'256',c:'児発４・定超・未計画２・開所減２・',baseItem: true,},
  {s:'618502',v:'218',c:'児発４・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618179',v:'580',c:'児発４・地公体・定超・',baseItem: true,},
  {s:'618504',v:'493',c:'児発４・地公体・定超・評価減・',baseItem: true,},
  {s:'618181',v:'406',c:'児発４・地公体・定超・開所減１・',baseItem: true,},
  {s:'618506',v:'345',c:'児発４・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'618729',v:'493',c:'児発４・地公体・定超・開所減２・',baseItem: true,},
  {s:'618508',v:'419',c:'児発４・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'618183',v:'406',c:'児発４・地公体・定超・未計画・',baseItem: true,},
  {s:'618510',v:'345',c:'児発４・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'618185',v:'284',c:'児発４・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'618551',v:'241',c:'児発４・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618731',v:'345',c:'児発４・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'618553',v:'293',c:'児発４・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'618555',v:'290',c:'児発４・地公体・定超・未計画２・',baseItem: true,},
  {s:'618557',v:'247',c:'児発４・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'618559',v:'203',c:'児発４・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'618561',v:'173',c:'児発４・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'618563',v:'247',c:'児発４・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'618565',v:'210',c:'児発４・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618191',v:'581',c:'児発５・定超・',baseItem: true,},
  {s:'618567',v:'494',c:'児発５・定超・評価減・',baseItem: true,},
  {s:'618193',v:'407',c:'児発５・定超・開所減１・',baseItem: true,},
  {s:'618569',v:'346',c:'児発５・定超・開所減１・評価減・',baseItem: true,},
  {s:'618733',v:'494',c:'児発５・定超・開所減２・',baseItem: true,},
  {s:'618571',v:'420',c:'児発５・定超・開所減２・評価減・',baseItem: true,},
  {s:'618195',v:'407',c:'児発５・定超・未計画・',baseItem: true,},
  {s:'618573',v:'346',c:'児発５・定超・未計画・評価減・',baseItem: true,},
  {s:'618197',v:'285',c:'児発５・定超・未計画・開所減１・',baseItem: true,},
  {s:'618575',v:'242',c:'児発５・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618735',v:'346',c:'児発５・定超・未計画・開所減２・',baseItem: true,},
  {s:'618577',v:'294',c:'児発５・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'618579',v:'291',c:'児発５・定超・未計画２・',baseItem: true,},
  {s:'618581',v:'247',c:'児発５・定超・未計画２・評価減・',baseItem: true,},
  {s:'618583',v:'204',c:'児発５・定超・未計画２・開所減１・',baseItem: true,},
  {s:'618585',v:'173',c:'児発５・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'618587',v:'247',c:'児発５・定超・未計画２・開所減２・',baseItem: true,},
  {s:'618589',v:'210',c:'児発５・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618199',v:'561',c:'児発５・地公体・定超・',baseItem: true,},
  {s:'618591',v:'477',c:'児発５・地公体・定超・評価減・',baseItem: true,},
  {s:'618201',v:'393',c:'児発５・地公体・定超・開所減１・',baseItem: true,},
  {s:'618593',v:'334',c:'児発５・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'618737',v:'477',c:'児発５・地公体・定超・開所減２・',baseItem: true,},
  {s:'618595',v:'405',c:'児発５・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'618203',v:'393',c:'児発５・地公体・定超・未計画・',baseItem: true,},
  {s:'618597',v:'334',c:'児発５・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'618205',v:'275',c:'児発５・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'618599',v:'234',c:'児発５・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618739',v:'334',c:'児発５・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'618601',v:'284',c:'児発５・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'618603',v:'281',c:'児発５・地公体・定超・未計画２・',baseItem: true,},
  {s:'618605',v:'239',c:'児発５・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'618607',v:'197',c:'児発５・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'618609',v:'167',c:'児発５・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'618680',v:'239',c:'児発５・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'618682',v:'203',c:'児発５・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618211',v:'563',c:'児発６・定超・',baseItem: true,},
  {s:'618684',v:'479',c:'児発６・定超・評価減・',baseItem: true,},
  {s:'618213',v:'394',c:'児発６・定超・開所減１・',baseItem: true,},
  {s:'618686',v:'335',c:'児発６・定超・開所減１・評価減・',baseItem: true,},
  {s:'618741',v:'479',c:'児発６・定超・開所減２・',baseItem: true,},
  {s:'618688',v:'407',c:'児発６・定超・開所減２・評価減・',baseItem: true,},
  {s:'618215',v:'394',c:'児発６・定超・未計画・',baseItem: true,},
  {s:'618690',v:'335',c:'児発６・定超・未計画・評価減・',baseItem: true,},
  {s:'618217',v:'276',c:'児発６・定超・未計画・開所減１・',baseItem: true,},
  {s:'618692',v:'235',c:'児発６・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618743',v:'335',c:'児発６・定超・未計画・開所減２・',baseItem: true,},
  {s:'618694',v:'285',c:'児発６・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'618696',v:'282',c:'児発６・定超・未計画２・',baseItem: true,},
  {s:'618698',v:'240',c:'児発６・定超・未計画２・評価減・',baseItem: true,},
  {s:'618700',v:'197',c:'児発６・定超・未計画２・開所減１・',baseItem: true,},
  {s:'618811',v:'167',c:'児発６・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'618813',v:'240',c:'児発６・定超・未計画２・開所減２・',baseItem: true,},
  {s:'618815',v:'204',c:'児発６・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618219',v:'543',c:'児発６・地公体・定超・',baseItem: true,},
  {s:'618817',v:'462',c:'児発６・地公体・定超・評価減・',baseItem: true,},
  {s:'618221',v:'380',c:'児発６・地公体・定超・開所減１・',baseItem: true,},
  {s:'618819',v:'323',c:'児発６・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'618745',v:'462',c:'児発６・地公体・定超・開所減２・',baseItem: true,},
  {s:'618821',v:'393',c:'児発６・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'618223',v:'380',c:'児発６・地公体・定超・未計画・',baseItem: true,},
  {s:'618823',v:'323',c:'児発６・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'618225',v:'266',c:'児発６・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'618825',v:'226',c:'児発６・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618747',v:'323',c:'児発６・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'618827',v:'275',c:'児発６・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'618829',v:'272',c:'児発６・地公体・定超・未計画２・',baseItem: true,},
  {s:'618831',v:'231',c:'児発６・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'618833',v:'190',c:'児発６・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'618835',v:'162',c:'児発６・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'618837',v:'231',c:'児発６・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'618839',v:'196',c:'児発６・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618231',v:'545',c:'児発７・定超・',baseItem: true,},
  {s:'618841',v:'463',c:'児発７・定超・評価減・',baseItem: true,},
  {s:'618233',v:'382',c:'児発７・定超・開所減１・',baseItem: true,},
  {s:'618843',v:'325',c:'児発７・定超・開所減１・評価減・',baseItem: true,},
  {s:'618749',v:'463',c:'児発７・定超・開所減２・',baseItem: true,},
  {s:'618845',v:'394',c:'児発７・定超・開所減２・評価減・',baseItem: true,},
  {s:'618235',v:'382',c:'児発７・定超・未計画・',baseItem: true,},
  {s:'618847',v:'325',c:'児発７・定超・未計画・評価減・',baseItem: true,},
  {s:'618237',v:'267',c:'児発７・定超・未計画・開所減１・',baseItem: true,},
  {s:'618849',v:'227',c:'児発７・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618751',v:'325',c:'児発７・定超・未計画・開所減２・',baseItem: true,},
  {s:'618851',v:'276',c:'児発７・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'618853',v:'273',c:'児発７・定超・未計画２・',baseItem: true,},
  {s:'618855',v:'232',c:'児発７・定超・未計画２・評価減・',baseItem: true,},
  {s:'618857',v:'191',c:'児発７・定超・未計画２・開所減１・',baseItem: true,},
  {s:'618859',v:'162',c:'児発７・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'618861',v:'232',c:'児発７・定超・未計画２・開所減２・',baseItem: true,},
  {s:'618863',v:'197',c:'児発７・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618239',v:'526',c:'児発７・地公体・定超・',baseItem: true,},
  {s:'618865',v:'447',c:'児発７・地公体・定超・評価減・',baseItem: true,},
  {s:'618241',v:'368',c:'児発７・地公体・定超・開所減１・',baseItem: true,},
  {s:'618867',v:'313',c:'児発７・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'618753',v:'447',c:'児発７・地公体・定超・開所減２・',baseItem: true,},
  {s:'618869',v:'380',c:'児発７・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'618243',v:'368',c:'児発７・地公体・定超・未計画・',baseItem: true,},
  {s:'618871',v:'313',c:'児発７・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'618245',v:'258',c:'児発７・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'618873',v:'219',c:'児発７・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618755',v:'313',c:'児発７・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'618875',v:'266',c:'児発７・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'618877',v:'263',c:'児発７・地公体・定超・未計画２・',baseItem: true,},
  {s:'618879',v:'224',c:'児発７・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'618881',v:'184',c:'児発７・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'618883',v:'156',c:'児発７・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'618885',v:'224',c:'児発７・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'618887',v:'190',c:'児発７・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E757',v:'2369',c:'児発４９・定超・',baseItem: true,},
  {s:'61E758',v:'2014',c:'児発４９・定超・評価減・',baseItem: true,},
  {s:'61E759',v:'1658',c:'児発４９・定超・開所減１・',baseItem: true,},
  {s:'61E760',v:'1409',c:'児発４９・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E761',v:'2014',c:'児発４９・定超・開所減２・',baseItem: true,},
  {s:'61E762',v:'1712',c:'児発４９・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E763',v:'1658',c:'児発４９・定超・未計画・',baseItem: true,},
  {s:'61E764',v:'1409',c:'児発４９・定超・未計画・評価減・',baseItem: true,},
  {s:'61E765',v:'1161',c:'児発４９・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E766',v:'987',c:'児発４９・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E767',v:'1409',c:'児発４９・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E768',v:'1198',c:'児発４９・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E769',v:'1185',c:'児発４９・定超・未計画２・',baseItem: true,},
  {s:'61E770',v:'1007',c:'児発４９・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E771',v:'830',c:'児発４９・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E772',v:'706',c:'児発４９・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E773',v:'1007',c:'児発４９・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E774',v:'856',c:'児発４９・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E775',v:'2286',c:'児発４９・地公体・定超・',baseItem: true,},
  {s:'61E776',v:'1943',c:'児発４９・地公体・定超・評価減・',baseItem: true,},
  {s:'61E777',v:'1600',c:'児発４９・地公体・定超・開所減１・',baseItem: true,},
  {s:'61E778',v:'1360',c:'児発４９・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E779',v:'1943',c:'児発４９・地公体・定超・開所減２・',baseItem: true,},
  {s:'61E780',v:'1652',c:'児発４９・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E781',v:'1600',c:'児発４９・地公体・定超・未計画・',baseItem: true,},
  {s:'61E782',v:'1360',c:'児発４９・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'61E783',v:'1120',c:'児発４９・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E784',v:'952',c:'児発４９・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E785',v:'1360',c:'児発４９・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E786',v:'1156',c:'児発４９・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E787',v:'1143',c:'児発４９・地公体・定超・未計画２・',baseItem: true,},
  {s:'61E788',v:'972',c:'児発４９・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E789',v:'800',c:'児発４９・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E790',v:'680',c:'児発４９・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E791',v:'972',c:'児発４９・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E792',v:'826',c:'児発４９・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E793',v:'2234',c:'児発５０・定超・',baseItem: true,},
  {s:'61E794',v:'1899',c:'児発５０・定超・評価減・',baseItem: true,},
  {s:'61E795',v:'1564',c:'児発５０・定超・開所減１・',baseItem: true,},
  {s:'61E796',v:'1329',c:'児発５０・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E797',v:'1899',c:'児発５０・定超・開所減２・',baseItem: true,},
  {s:'61E798',v:'1614',c:'児発５０・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E799',v:'1564',c:'児発５０・定超・未計画・',baseItem: true,},
  {s:'61E800',v:'1329',c:'児発５０・定超・未計画・評価減・',baseItem: true,},
  {s:'61E801',v:'1095',c:'児発５０・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E802',v:'931',c:'児発５０・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E803',v:'1329',c:'児発５０・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E804',v:'1130',c:'児発５０・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E805',v:'1117',c:'児発５０・定超・未計画２・',baseItem: true,},
  {s:'61E806',v:'949',c:'児発５０・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E807',v:'782',c:'児発５０・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E808',v:'665',c:'児発５０・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E809',v:'949',c:'児発５０・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E810',v:'807',c:'児発５０・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E811',v:'2155',c:'児発５０・地公体・定超・',baseItem: true,},
  {s:'61E812',v:'1832',c:'児発５０・地公体・定超・評価減・',baseItem: true,},
  {s:'61E813',v:'1509',c:'児発５０・地公体・定超・開所減１・',baseItem: true,},
  {s:'61E814',v:'1283',c:'児発５０・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E815',v:'1832',c:'児発５０・地公体・定超・開所減２・',baseItem: true,},
  {s:'61E816',v:'1557',c:'児発５０・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E817',v:'1509',c:'児発５０・地公体・定超・未計画・',baseItem: true,},
  {s:'61E818',v:'1283',c:'児発５０・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'61E819',v:'1056',c:'児発５０・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E820',v:'898',c:'児発５０・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E821',v:'1283',c:'児発５０・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E822',v:'1091',c:'児発５０・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E823',v:'1078',c:'児発５０・地公体・定超・未計画２・',baseItem: true,},
  {s:'61E824',v:'916',c:'児発５０・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E825',v:'755',c:'児発５０・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E826',v:'642',c:'児発５０・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E827',v:'916',c:'児発５０・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E828',v:'779',c:'児発５０・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E829',v:'2153',c:'児発５１・定超・',baseItem: true,},
  {s:'61E830',v:'1830',c:'児発５１・定超・評価減・',baseItem: true,},
  {s:'61E831',v:'1507',c:'児発５１・定超・開所減１・',baseItem: true,},
  {s:'61E832',v:'1281',c:'児発５１・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E833',v:'1830',c:'児発５１・定超・開所減２・',baseItem: true,},
  {s:'61E834',v:'1556',c:'児発５１・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E835',v:'1507',c:'児発５１・定超・未計画・',baseItem: true,},
  {s:'61E836',v:'1281',c:'児発５１・定超・未計画・評価減・',baseItem: true,},
  {s:'61E837',v:'1055',c:'児発５１・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E838',v:'897',c:'児発５１・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E839',v:'1281',c:'児発５１・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E840',v:'1089',c:'児発５１・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E841',v:'1077',c:'児発５１・定超・未計画２・',baseItem: true,},
  {s:'61E842',v:'915',c:'児発５１・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E843',v:'754',c:'児発５１・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E844',v:'641',c:'児発５１・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E845',v:'915',c:'児発５１・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E846',v:'778',c:'児発５１・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E847',v:'2077',c:'児発５１・地公体・定超・',baseItem: true,},
  {s:'61E848',v:'1765',c:'児発５１・地公体・定超・評価減・',baseItem: true,},
  {s:'61E849',v:'1454',c:'児発５１・地公体・定超・開所減１・',baseItem: true,},
  {s:'61E850',v:'1236',c:'児発５１・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E851',v:'1765',c:'児発５１・地公体・定超・開所減２・',baseItem: true,},
  {s:'61E852',v:'1500',c:'児発５１・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E853',v:'1454',c:'児発５１・地公体・定超・未計画・',baseItem: true,},
  {s:'61E854',v:'1236',c:'児発５１・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'61E855',v:'1018',c:'児発５１・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E856',v:'865',c:'児発５１・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E857',v:'1236',c:'児発５１・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E858',v:'1051',c:'児発５１・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E859',v:'1039',c:'児発５１・地公体・定超・未計画２・',baseItem: true,},
  {s:'61E860',v:'883',c:'児発５１・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E861',v:'727',c:'児発５１・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E862',v:'618',c:'児発５１・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E863',v:'883',c:'児発５１・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E864',v:'751',c:'児発５１・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E865',v:'2083',c:'児発５２・定超・',baseItem: true,},
  {s:'61E866',v:'1771',c:'児発５２・定超・評価減・',baseItem: true,},
  {s:'61E867',v:'1458',c:'児発５２・定超・開所減１・',baseItem: true,},
  {s:'61E868',v:'1239',c:'児発５２・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E869',v:'1771',c:'児発５２・定超・開所減２・',baseItem: true,},
  {s:'61E870',v:'1505',c:'児発５２・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E871',v:'1458',c:'児発５２・定超・未計画・',baseItem: true,},
  {s:'61E872',v:'1239',c:'児発５２・定超・未計画・評価減・',baseItem: true,},
  {s:'61E873',v:'1021',c:'児発５２・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E874',v:'868',c:'児発５２・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E875',v:'1239',c:'児発５２・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E876',v:'1053',c:'児発５２・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E877',v:'1042',c:'児発５２・定超・未計画２・',baseItem: true,},
  {s:'61E878',v:'886',c:'児発５２・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E879',v:'729',c:'児発５２・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E880',v:'620',c:'児発５２・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E881',v:'886',c:'児発５２・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E882',v:'753',c:'児発５２・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E883',v:'2010',c:'児発５２・地公体・定超・',baseItem: true,},
  {s:'61E884',v:'1709',c:'児発５２・地公体・定超・評価減・',baseItem: true,},
  {s:'61E885',v:'1407',c:'児発５２・地公体・定超・開所減１・',baseItem: true,},
  {s:'61E886',v:'1196',c:'児発５２・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E887',v:'1709',c:'児発５２・地公体・定超・開所減２・',baseItem: true,},
  {s:'61E888',v:'1453',c:'児発５２・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E889',v:'1407',c:'児発５２・地公体・定超・未計画・',baseItem: true,},
  {s:'61E890',v:'1196',c:'児発５２・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'61E891',v:'985',c:'児発５２・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E892',v:'837',c:'児発５２・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E893',v:'1196',c:'児発５２・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E894',v:'1017',c:'児発５２・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E895',v:'1005',c:'児発５２・地公体・定超・未計画２・',baseItem: true,},
  {s:'61E896',v:'854',c:'児発５２・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E897',v:'704',c:'児発５２・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E898',v:'598',c:'児発５２・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E899',v:'854',c:'児発５２・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E900',v:'726',c:'児発５２・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E901',v:'1669',c:'児発５３・定超・',baseItem: true,},
  {s:'61E902',v:'1419',c:'児発５３・定超・評価減・',baseItem: true,},
  {s:'61E903',v:'1168',c:'児発５３・定超・開所減１・',baseItem: true,},
  {s:'61E904',v:'993',c:'児発５３・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E905',v:'1419',c:'児発５３・定超・開所減２・',baseItem: true,},
  {s:'61E906',v:'1206',c:'児発５３・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E907',v:'1168',c:'児発５３・定超・未計画・',baseItem: true,},
  {s:'61E908',v:'993',c:'児発５３・定超・未計画・評価減・',baseItem: true,},
  {s:'61E909',v:'818',c:'児発５３・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E910',v:'695',c:'児発５３・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E911',v:'993',c:'児発５３・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E912',v:'844',c:'児発５３・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E913',v:'835',c:'児発５３・定超・未計画２・',baseItem: true,},
  {s:'61E914',v:'710',c:'児発５３・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E915',v:'585',c:'児発５３・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E916',v:'497',c:'児発５３・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E917',v:'710',c:'児発５３・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E918',v:'604',c:'児発５３・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E919',v:'1611',c:'児発５３・地公体・定超・',baseItem: true,},
  {s:'61E920',v:'1369',c:'児発５３・地公体・定超・評価減・',baseItem: true,},
  {s:'61E921',v:'1128',c:'児発５３・地公体・定超・開所減１・',baseItem: true,},
  {s:'61E922',v:'959',c:'児発５３・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E923',v:'1369',c:'児発５３・地公体・定超・開所減２・',baseItem: true,},
  {s:'61E924',v:'1164',c:'児発５３・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E925',v:'1128',c:'児発５３・地公体・定超・未計画・',baseItem: true,},
  {s:'61E926',v:'959',c:'児発５３・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'61E927',v:'790',c:'児発５３・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E928',v:'672',c:'児発５３・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E929',v:'959',c:'児発５３・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E930',v:'815',c:'児発５３・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E931',v:'806',c:'児発５３・地公体・定超・未計画２・',baseItem: true,},
  {s:'61E932',v:'685',c:'児発５３・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E933',v:'564',c:'児発５３・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E934',v:'479',c:'児発５３・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E935',v:'685',c:'児発５３・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E936',v:'582',c:'児発５３・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E937',v:'1534',c:'児発５４・定超・',baseItem: true,},
  {s:'61E938',v:'1304',c:'児発５４・定超・評価減・',baseItem: true,},
  {s:'61E939',v:'1074',c:'児発５４・定超・開所減１・',baseItem: true,},
  {s:'61E940',v:'913',c:'児発５４・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E941',v:'1304',c:'児発５４・定超・開所減２・',baseItem: true,},
  {s:'61E942',v:'1108',c:'児発５４・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E943',v:'1074',c:'児発５４・定超・未計画・',baseItem: true,},
  {s:'61E944',v:'913',c:'児発５４・定超・未計画・評価減・',baseItem: true,},
  {s:'61E945',v:'752',c:'児発５４・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E946',v:'639',c:'児発５４・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E947',v:'913',c:'児発５４・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E948',v:'776',c:'児発５４・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E949',v:'767',c:'児発５４・定超・未計画２・',baseItem: true,},
  {s:'61E950',v:'652',c:'児発５４・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E951',v:'537',c:'児発５４・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E952',v:'456',c:'児発５４・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E953',v:'652',c:'児発５４・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E954',v:'554',c:'児発５４・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E955',v:'1480',c:'児発５４・地公体・定超・',baseItem: true,},
  {s:'61E956',v:'1258',c:'児発５４・地公体・定超・評価減・',baseItem: true,},
  {s:'61E957',v:'1036',c:'児発５４・地公体・定超・開所減１・',baseItem: true,},
  {s:'61E958',v:'881',c:'児発５４・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E959',v:'1258',c:'児発５４・地公体・定超・開所減２・',baseItem: true,},
  {s:'61E960',v:'1069',c:'児発５４・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E961',v:'1036',c:'児発５４・地公体・定超・未計画・',baseItem: true,},
  {s:'61E962',v:'881',c:'児発５４・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'61E963',v:'725',c:'児発５４・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E964',v:'616',c:'児発５４・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E965',v:'881',c:'児発５４・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E966',v:'749',c:'児発５４・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E967',v:'740',c:'児発５４・地公体・定超・未計画２・',baseItem: true,},
  {s:'61E968',v:'629',c:'児発５４・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E969',v:'518',c:'児発５４・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E970',v:'440',c:'児発５４・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E971',v:'629',c:'児発５４・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E972',v:'535',c:'児発５４・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E973',v:'1453',c:'児発５５・定超・',baseItem: true,},
  {s:'61E974',v:'1235',c:'児発５５・定超・評価減・',baseItem: true,},
  {s:'61E975',v:'1017',c:'児発５５・定超・開所減１・',baseItem: true,},
  {s:'61E976',v:'864',c:'児発５５・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E977',v:'1235',c:'児発５５・定超・開所減２・',baseItem: true,},
  {s:'61E978',v:'1050',c:'児発５５・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E979',v:'1017',c:'児発５５・定超・未計画・',baseItem: true,},
  {s:'61E980',v:'864',c:'児発５５・定超・未計画・評価減・',baseItem: true,},
  {s:'61E981',v:'712',c:'児発５５・定超・未計画・開所減１・',baseItem: true,},
  {s:'61E982',v:'605',c:'児発５５・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61E983',v:'864',c:'児発５５・定超・未計画・開所減２・',baseItem: true,},
  {s:'61E984',v:'734',c:'児発５５・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61E985',v:'727',c:'児発５５・定超・未計画２・',baseItem: true,},
  {s:'61E986',v:'618',c:'児発５５・定超・未計画２・評価減・',baseItem: true,},
  {s:'61E987',v:'509',c:'児発５５・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61E988',v:'433',c:'児発５５・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61E989',v:'618',c:'児発５５・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61E990',v:'525',c:'児発５５・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61E991',v:'1401',c:'児発５５・地公体・定超・',baseItem: true,},
  {s:'61E992',v:'1191',c:'児発５５・地公体・定超・評価減・',baseItem: true,},
  {s:'61E993',v:'981',c:'児発５５・地公体・定超・開所減１・',baseItem: true,},
  {s:'61E994',v:'834',c:'児発５５・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'61E995',v:'1191',c:'児発５５・地公体・定超・開所減２・',baseItem: true,},
  {s:'61E996',v:'1012',c:'児発５５・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'61E997',v:'981',c:'児発５５・地公体・定超・未計画・',baseItem: true,},
  {s:'61E998',v:'834',c:'児発５５・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'61E999',v:'687',c:'児発５５・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'61EA00',v:'584',c:'児発５５・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61EA01',v:'834',c:'児発５５・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'61EA02',v:'709',c:'児発５５・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61EA03',v:'701',c:'児発５５・地公体・定超・未計画２・',baseItem: true,},
  {s:'61EA04',v:'596',c:'児発５５・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'61EA05',v:'491',c:'児発５５・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61EA06',v:'417',c:'児発５５・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61EA07',v:'596',c:'児発５５・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61EA08',v:'507',c:'児発５５・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61EA09',v:'1383',c:'児発５６・定超・',baseItem: true,},
  {s:'61EA10',v:'1176',c:'児発５６・定超・評価減・',baseItem: true,},
  {s:'61EA11',v:'968',c:'児発５６・定超・開所減１・',baseItem: true,},
  {s:'61EA12',v:'823',c:'児発５６・定超・開所減１・評価減・',baseItem: true,},
  {s:'61EA13',v:'1176',c:'児発５６・定超・開所減２・',baseItem: true,},
  {s:'61EA14',v:'1000',c:'児発５６・定超・開所減２・評価減・',baseItem: true,},
  {s:'61EA15',v:'968',c:'児発５６・定超・未計画・',baseItem: true,},
  {s:'61EA16',v:'823',c:'児発５６・定超・未計画・評価減・',baseItem: true,},
  {s:'61EA17',v:'678',c:'児発５６・定超・未計画・開所減１・',baseItem: true,},
  {s:'61EA18',v:'576',c:'児発５６・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61EA19',v:'823',c:'児発５６・定超・未計画・開所減２・',baseItem: true,},
  {s:'61EA20',v:'700',c:'児発５６・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61EA21',v:'692',c:'児発５６・定超・未計画２・',baseItem: true,},
  {s:'61EA22',v:'588',c:'児発５６・定超・未計画２・評価減・',baseItem: true,},
  {s:'61EA23',v:'484',c:'児発５６・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61EA24',v:'411',c:'児発５６・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61EA25',v:'588',c:'児発５６・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61EA26',v:'500',c:'児発５６・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61EA27',v:'1334',c:'児発５６・地公体・定超・',baseItem: true,},
  {s:'61EA28',v:'1134',c:'児発５６・地公体・定超・評価減・',baseItem: true,},
  {s:'61EA29',v:'934',c:'児発５６・地公体・定超・開所減１・',baseItem: true,},
  {s:'61EA30',v:'794',c:'児発５６・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'61EA31',v:'1134',c:'児発５６・地公体・定超・開所減２・',baseItem: true,},
  {s:'61EA32',v:'964',c:'児発５６・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'61EA33',v:'934',c:'児発５６・地公体・定超・未計画・',baseItem: true,},
  {s:'61EA34',v:'794',c:'児発５６・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'61EA35',v:'654',c:'児発５６・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'61EA36',v:'556',c:'児発５６・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61EA37',v:'794',c:'児発５６・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'61EA38',v:'675',c:'児発５６・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61EA39',v:'667',c:'児発５６・地公体・定超・未計画２・',baseItem: true,},
  {s:'61EA40',v:'567',c:'児発５６・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'61EA41',v:'467',c:'児発５６・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61EA42',v:'397',c:'児発５６・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61EA43',v:'567',c:'児発５６・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61EA44',v:'482',c:'児発５６・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61EA45',v:'1436',c:'児発５７・定超・',baseItem: true,},
  {s:'61EA46',v:'1221',c:'児発５７・定超・評価減・',baseItem: true,},
  {s:'61EA47',v:'1005',c:'児発５７・定超・開所減１・',baseItem: true,},
  {s:'61EA48',v:'854',c:'児発５７・定超・開所減１・評価減・',baseItem: true,},
  {s:'61EA49',v:'1221',c:'児発５７・定超・開所減２・',baseItem: true,},
  {s:'61EA50',v:'1038',c:'児発５７・定超・開所減２・評価減・',baseItem: true,},
  {s:'61EA51',v:'1005',c:'児発５７・定超・未計画・',baseItem: true,},
  {s:'61EA52',v:'854',c:'児発５７・定超・未計画・評価減・',baseItem: true,},
  {s:'61EA53',v:'704',c:'児発５７・定超・未計画・開所減１・',baseItem: true,},
  {s:'61EA54',v:'598',c:'児発５７・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61EA55',v:'854',c:'児発５７・定超・未計画・開所減２・',baseItem: true,},
  {s:'61EA56',v:'726',c:'児発５７・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61EA57',v:'718',c:'児発５７・定超・未計画２・',baseItem: true,},
  {s:'61EA58',v:'610',c:'児発５７・定超・未計画２・評価減・',baseItem: true,},
  {s:'61EA59',v:'503',c:'児発５７・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61EA60',v:'428',c:'児発５７・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61EA61',v:'610',c:'児発５７・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61EA62',v:'519',c:'児発５７・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61EA63',v:'1385',c:'児発５７・地公体・定超・',baseItem: true,},
  {s:'61EA64',v:'1177',c:'児発５７・地公体・定超・評価減・',baseItem: true,},
  {s:'61EA65',v:'970',c:'児発５７・地公体・定超・開所減１・',baseItem: true,},
  {s:'61EA66',v:'825',c:'児発５７・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'61EA67',v:'1177',c:'児発５７・地公体・定超・開所減２・',baseItem: true,},
  {s:'61EA68',v:'1000',c:'児発５７・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'61EA69',v:'970',c:'児発５７・地公体・定超・未計画・',baseItem: true,},
  {s:'61EA70',v:'825',c:'児発５７・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'61EA71',v:'679',c:'児発５７・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'61EA72',v:'577',c:'児発５７・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61EA73',v:'825',c:'児発５７・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'61EA74',v:'701',c:'児発５７・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61EA75',v:'693',c:'児発５７・地公体・定超・未計画２・',baseItem: true,},
  {s:'61EA76',v:'589',c:'児発５７・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'61EA77',v:'485',c:'児発５７・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61EA78',v:'412',c:'児発５７・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61EA79',v:'589',c:'児発５７・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61EA80',v:'501',c:'児発５７・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61EA81',v:'1301',c:'児発５８・定超・',baseItem: true,},
  {s:'61EA82',v:'1106',c:'児発５８・定超・評価減・',baseItem: true,},
  {s:'61EA83',v:'911',c:'児発５８・定超・開所減１・',baseItem: true,},
  {s:'61EA84',v:'774',c:'児発５８・定超・開所減１・評価減・',baseItem: true,},
  {s:'61EA85',v:'1106',c:'児発５８・定超・開所減２・',baseItem: true,},
  {s:'61EA86',v:'940',c:'児発５８・定超・開所減２・評価減・',baseItem: true,},
  {s:'61EA87',v:'911',c:'児発５８・定超・未計画・',baseItem: true,},
  {s:'61EA88',v:'774',c:'児発５８・定超・未計画・評価減・',baseItem: true,},
  {s:'61EA89',v:'638',c:'児発５８・定超・未計画・開所減１・',baseItem: true,},
  {s:'61EA90',v:'542',c:'児発５８・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61EA91',v:'774',c:'児発５８・定超・未計画・開所減２・',baseItem: true,},
  {s:'61EA92',v:'658',c:'児発５８・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61EA93',v:'651',c:'児発５８・定超・未計画２・',baseItem: true,},
  {s:'61EA94',v:'553',c:'児発５８・定超・未計画２・評価減・',baseItem: true,},
  {s:'61EA95',v:'456',c:'児発５８・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61EA96',v:'388',c:'児発５８・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61EA97',v:'553',c:'児発５８・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61EA98',v:'470',c:'児発５８・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61EA99',v:'1255',c:'児発５８・地公体・定超・',baseItem: true,},
  {s:'61EB00',v:'1067',c:'児発５８・地公体・定超・評価減・',baseItem: true,},
  {s:'61EB01',v:'879',c:'児発５８・地公体・定超・開所減１・',baseItem: true,},
  {s:'61EB02',v:'747',c:'児発５８・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'61EB03',v:'1067',c:'児発５８・地公体・定超・開所減２・',baseItem: true,},
  {s:'61EB04',v:'907',c:'児発５８・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'61EB05',v:'879',c:'児発５８・地公体・定超・未計画・',baseItem: true,},
  {s:'61EB06',v:'747',c:'児発５８・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'61EB07',v:'615',c:'児発５８・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'61EB08',v:'523',c:'児発５８・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61EB09',v:'747',c:'児発５８・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'61EB10',v:'635',c:'児発５８・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61EB11',v:'628',c:'児発５８・地公体・定超・未計画２・',baseItem: true,},
  {s:'61EB12',v:'534',c:'児発５８・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'61EB13',v:'440',c:'児発５８・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61EB14',v:'374',c:'児発５８・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61EB15',v:'534',c:'児発５８・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61EB16',v:'454',c:'児発５８・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61EB17',v:'1219',c:'児発５９・定超・',baseItem: true,},
  {s:'61EB18',v:'1036',c:'児発５９・定超・評価減・',baseItem: true,},
  {s:'61EB19',v:'853',c:'児発５９・定超・開所減１・',baseItem: true,},
  {s:'61EB20',v:'725',c:'児発５９・定超・開所減１・評価減・',baseItem: true,},
  {s:'61EB21',v:'1036',c:'児発５９・定超・開所減２・',baseItem: true,},
  {s:'61EB22',v:'881',c:'児発５９・定超・開所減２・評価減・',baseItem: true,},
  {s:'61EB23',v:'853',c:'児発５９・定超・未計画・',baseItem: true,},
  {s:'61EB24',v:'725',c:'児発５９・定超・未計画・評価減・',baseItem: true,},
  {s:'61EB25',v:'597',c:'児発５９・定超・未計画・開所減１・',baseItem: true,},
  {s:'61EB26',v:'507',c:'児発５９・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61EB27',v:'725',c:'児発５９・定超・未計画・開所減２・',baseItem: true,},
  {s:'61EB28',v:'616',c:'児発５９・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61EB29',v:'610',c:'児発５９・定超・未計画２・',baseItem: true,},
  {s:'61EB30',v:'519',c:'児発５９・定超・未計画２・評価減・',baseItem: true,},
  {s:'61EB31',v:'427',c:'児発５９・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61EB32',v:'363',c:'児発５９・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61EB33',v:'519',c:'児発５９・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61EB34',v:'441',c:'児発５９・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61EB35',v:'1177',c:'児発５９・地公体・定超・',baseItem: true,},
  {s:'61EB36',v:'1000',c:'児発５９・地公体・定超・評価減・',baseItem: true,},
  {s:'61EB37',v:'824',c:'児発５９・地公体・定超・開所減１・',baseItem: true,},
  {s:'61EB38',v:'700',c:'児発５９・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'61EB39',v:'1000',c:'児発５９・地公体・定超・開所減２・',baseItem: true,},
  {s:'61EB40',v:'850',c:'児発５９・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'61EB41',v:'824',c:'児発５９・地公体・定超・未計画・',baseItem: true,},
  {s:'61EB42',v:'700',c:'児発５９・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'61EB43',v:'577',c:'児発５９・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'61EB44',v:'490',c:'児発５９・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61EB45',v:'700',c:'児発５９・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'61EB46',v:'595',c:'児発５９・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61EB47',v:'589',c:'児発５９・地公体・定超・未計画２・',baseItem: true,},
  {s:'61EB48',v:'501',c:'児発５９・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'61EB49',v:'412',c:'児発５９・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61EB50',v:'350',c:'児発５９・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61EB51',v:'501',c:'児発５９・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61EB52',v:'426',c:'児発５９・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61EB53',v:'1149',c:'児発６０・定超・',baseItem: true,},
  {s:'61EB54',v:'977',c:'児発６０・定超・評価減・',baseItem: true,},
  {s:'61EB55',v:'804',c:'児発６０・定超・開所減１・',baseItem: true,},
  {s:'61EB56',v:'683',c:'児発６０・定超・開所減１・評価減・',baseItem: true,},
  {s:'61EB57',v:'977',c:'児発６０・定超・開所減２・',baseItem: true,},
  {s:'61EB58',v:'830',c:'児発６０・定超・開所減２・評価減・',baseItem: true,},
  {s:'61EB59',v:'804',c:'児発６０・定超・未計画・',baseItem: true,},
  {s:'61EB60',v:'683',c:'児発６０・定超・未計画・評価減・',baseItem: true,},
  {s:'61EB61',v:'563',c:'児発６０・定超・未計画・開所減１・',baseItem: true,},
  {s:'61EB62',v:'479',c:'児発６０・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61EB63',v:'683',c:'児発６０・定超・未計画・開所減２・',baseItem: true,},
  {s:'61EB64',v:'581',c:'児発６０・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61EB65',v:'575',c:'児発６０・定超・未計画２・',baseItem: true,},
  {s:'61EB66',v:'489',c:'児発６０・定超・未計画２・評価減・',baseItem: true,},
  {s:'61EB67',v:'403',c:'児発６０・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61EB68',v:'343',c:'児発６０・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61EB69',v:'489',c:'児発６０・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61EB70',v:'416',c:'児発６０・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61EB71',v:'1110',c:'児発６０・地公体・定超・',baseItem: true,},
  {s:'61EB72',v:'944',c:'児発６０・地公体・定超・評価減・',baseItem: true,},
  {s:'61EB73',v:'777',c:'児発６０・地公体・定超・開所減１・',baseItem: true,},
  {s:'61EB74',v:'660',c:'児発６０・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'61EB75',v:'944',c:'児発６０・地公体・定超・開所減２・',baseItem: true,},
  {s:'61EB76',v:'802',c:'児発６０・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'61EB77',v:'777',c:'児発６０・地公体・定超・未計画・',baseItem: true,},
  {s:'61EB78',v:'660',c:'児発６０・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'61EB79',v:'544',c:'児発６０・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'61EB80',v:'462',c:'児発６０・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61EB81',v:'660',c:'児発６０・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'61EB82',v:'561',c:'児発６０・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61EB83',v:'555',c:'児発６０・地公体・定超・未計画２・',baseItem: true,},
  {s:'61EB84',v:'472',c:'児発６０・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'61EB85',v:'389',c:'児発６０・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61EB86',v:'331',c:'児発６０・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61EB87',v:'472',c:'児発６０・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61EB88',v:'401',c:'児発６０・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618311',v:'969',c:'児発８・定超・',baseItem: true,},
  {s:'618891',v:'824',c:'児発８・定超・評価減・',baseItem: true,},
  {s:'618312',v:'678',c:'児発８・定超・開所減１・',baseItem: true,},
  {s:'618894',v:'576',c:'児発８・定超・開所減１・評価減・',baseItem: true,},
  {s:'618757',v:'824',c:'児発８・定超・開所減２・',baseItem: true,},
  {s:'618897',v:'700',c:'児発８・定超・開所減２・評価減・',baseItem: true,},
  {s:'618313',v:'678',c:'児発８・定超・未計画・',baseItem: true,},
  {s:'618900',v:'576',c:'児発８・定超・未計画・評価減・',baseItem: true,},
  {s:'618314',v:'475',c:'児発８・定超・未計画・開所減１・',baseItem: true,},
  {s:'618903',v:'404',c:'児発８・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618758',v:'576',c:'児発８・定超・未計画・開所減２・',baseItem: true,},
  {s:'618906',v:'490',c:'児発８・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'618908',v:'485',c:'児発８・定超・未計画２・',baseItem: true,},
  {s:'618910',v:'412',c:'児発８・定超・未計画２・評価減・',baseItem: true,},
  {s:'618912',v:'340',c:'児発８・定超・未計画２・開所減１・',baseItem: true,},
  {s:'618914',v:'289',c:'児発８・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'618916',v:'412',c:'児発８・定超・未計画２・開所減２・',baseItem: true,},
  {s:'618918',v:'350',c:'児発８・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618315',v:'935',c:'児発８・地公体・定超・',baseItem: true,},
  {s:'618921',v:'795',c:'児発８・地公体・定超・評価減・',baseItem: true,},
  {s:'618316',v:'655',c:'児発８・地公体・定超・開所減１・',baseItem: true,},
  {s:'618924',v:'557',c:'児発８・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'618759',v:'795',c:'児発８・地公体・定超・開所減２・',baseItem: true,},
  {s:'618927',v:'676',c:'児発８・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'618317',v:'655',c:'児発８・地公体・定超・未計画・',baseItem: true,},
  {s:'618930',v:'557',c:'児発８・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'618318',v:'459',c:'児発８・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'618933',v:'390',c:'児発８・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618760',v:'557',c:'児発８・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'618936',v:'473',c:'児発８・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'618938',v:'468',c:'児発８・地公体・定超・未計画２・',baseItem: true,},
  {s:'618940',v:'398',c:'児発８・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'618942',v:'328',c:'児発８・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'618944',v:'279',c:'児発８・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'618946',v:'398',c:'児発８・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'618948',v:'338',c:'児発８・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618321',v:'834',c:'児発９・定超・',baseItem: true,},
  {s:'618951',v:'709',c:'児発９・定超・評価減・',baseItem: true,},
  {s:'618322',v:'584',c:'児発９・定超・開所減１・',baseItem: true,},
  {s:'618954',v:'496',c:'児発９・定超・開所減１・評価減・',baseItem: true,},
  {s:'618761',v:'709',c:'児発９・定超・開所減２・',baseItem: true,},
  {s:'618957',v:'603',c:'児発９・定超・開所減２・評価減・',baseItem: true,},
  {s:'618323',v:'584',c:'児発９・定超・未計画・',baseItem: true,},
  {s:'618960',v:'496',c:'児発９・定超・未計画・評価減・',baseItem: true,},
  {s:'618324',v:'409',c:'児発９・定超・未計画・開所減１・',baseItem: true,},
  {s:'618963',v:'348',c:'児発９・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618762',v:'496',c:'児発９・定超・未計画・開所減２・',baseItem: true,},
  {s:'618966',v:'422',c:'児発９・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'618968',v:'417',c:'児発９・定超・未計画２・',baseItem: true,},
  {s:'618970',v:'354',c:'児発９・定超・未計画２・評価減・',baseItem: true,},
  {s:'618972',v:'292',c:'児発９・定超・未計画２・開所減１・',baseItem: true,},
  {s:'618974',v:'248',c:'児発９・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'618976',v:'354',c:'児発９・定超・未計画２・開所減２・',baseItem: true,},
  {s:'618978',v:'301',c:'児発９・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618325',v:'804',c:'児発９・地公体・定超・',baseItem: true,},
  {s:'618981',v:'683',c:'児発９・地公体・定超・評価減・',baseItem: true,},
  {s:'618326',v:'563',c:'児発９・地公体・定超・開所減１・',baseItem: true,},
  {s:'618984',v:'479',c:'児発９・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'618763',v:'683',c:'児発９・地公体・定超・開所減２・',baseItem: true,},
  {s:'618987',v:'581',c:'児発９・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'618327',v:'563',c:'児発９・地公体・定超・未計画・',baseItem: true,},
  {s:'618990',v:'479',c:'児発９・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'618328',v:'394',c:'児発９・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'618993',v:'335',c:'児発９・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618764',v:'479',c:'児発９・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'618996',v:'407',c:'児発９・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'618998',v:'402',c:'児発９・地公体・定超・未計画２・',baseItem: true,},
  {s:'617000',v:'342',c:'児発９・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'617002',v:'281',c:'児発９・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'617004',v:'239',c:'児発９・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'617006',v:'342',c:'児発９・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'617008',v:'291',c:'児発９・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618331',v:'753',c:'児発１０・定超・',baseItem: true,},
  {s:'617011',v:'640',c:'児発１０・定超・評価減・',baseItem: true,},
  {s:'618332',v:'527',c:'児発１０・定超・開所減１・',baseItem: true,},
  {s:'617014',v:'448',c:'児発１０・定超・開所減１・評価減・',baseItem: true,},
  {s:'618765',v:'640',c:'児発１０・定超・開所減２・',baseItem: true,},
  {s:'617017',v:'544',c:'児発１０・定超・開所減２・評価減・',baseItem: true,},
  {s:'618333',v:'527',c:'児発１０・定超・未計画・',baseItem: true,},
  {s:'617020',v:'448',c:'児発１０・定超・未計画・評価減・',baseItem: true,},
  {s:'618334',v:'369',c:'児発１０・定超・未計画・開所減１・',baseItem: true,},
  {s:'617023',v:'314',c:'児発１０・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618766',v:'448',c:'児発１０・定超・未計画・開所減２・',baseItem: true,},
  {s:'617026',v:'381',c:'児発１０・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'617028',v:'377',c:'児発１０・定超・未計画２・',baseItem: true,},
  {s:'617030',v:'320',c:'児発１０・定超・未計画２・評価減・',baseItem: true,},
  {s:'617032',v:'264',c:'児発１０・定超・未計画２・開所減１・',baseItem: true,},
  {s:'617034',v:'224',c:'児発１０・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'617036',v:'320',c:'児発１０・定超・未計画２・開所減２・',baseItem: true,},
  {s:'617038',v:'272',c:'児発１０・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618335',v:'726',c:'児発１０・地公体・定超・',baseItem: true,},
  {s:'617041',v:'617',c:'児発１０・地公体・定超・評価減・',baseItem: true,},
  {s:'618336',v:'508',c:'児発１０・地公体・定超・開所減１・',baseItem: true,},
  {s:'617044',v:'432',c:'児発１０・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'618767',v:'617',c:'児発１０・地公体・定超・開所減２・',baseItem: true,},
  {s:'617047',v:'524',c:'児発１０・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'618337',v:'508',c:'児発１０・地公体・定超・未計画・',baseItem: true,},
  {s:'617050',v:'432',c:'児発１０・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'618338',v:'356',c:'児発１０・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'617053',v:'303',c:'児発１０・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618768',v:'432',c:'児発１０・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'617056',v:'367',c:'児発１０・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'617058',v:'363',c:'児発１０・地公体・定超・未計画２・',baseItem: true,},
  {s:'617060',v:'309',c:'児発１０・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'617062',v:'254',c:'児発１０・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'617064',v:'216',c:'児発１０・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'617066',v:'309',c:'児発１０・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'617068',v:'263',c:'児発１０・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618341',v:'683',c:'児発１１・定超・',baseItem: true,},
  {s:'617071',v:'581',c:'児発１１・定超・評価減・',baseItem: true,},
  {s:'618342',v:'478',c:'児発１１・定超・開所減１・',baseItem: true,},
  {s:'617074',v:'406',c:'児発１１・定超・開所減１・評価減・',baseItem: true,},
  {s:'618769',v:'581',c:'児発１１・定超・開所減２・',baseItem: true,},
  {s:'617077',v:'494',c:'児発１１・定超・開所減２・評価減・',baseItem: true,},
  {s:'618343',v:'478',c:'児発１１・定超・未計画・',baseItem: true,},
  {s:'617080',v:'406',c:'児発１１・定超・未計画・評価減・',baseItem: true,},
  {s:'618344',v:'335',c:'児発１１・定超・未計画・開所減１・',baseItem: true,},
  {s:'617083',v:'285',c:'児発１１・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618770',v:'406',c:'児発１１・定超・未計画・開所減２・',baseItem: true,},
  {s:'617086',v:'345',c:'児発１１・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'617088',v:'342',c:'児発１１・定超・未計画２・',baseItem: true,},
  {s:'617090',v:'291',c:'児発１１・定超・未計画２・評価減・',baseItem: true,},
  {s:'617092',v:'239',c:'児発１１・定超・未計画２・開所減１・',baseItem: true,},
  {s:'617094',v:'203',c:'児発１１・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'617096',v:'291',c:'児発１１・定超・未計画２・開所減２・',baseItem: true,},
  {s:'617098',v:'247',c:'児発１１・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618345',v:'659',c:'児発１１・地公体・定超・',baseItem: true,},
  {s:'617101',v:'560',c:'児発１１・地公体・定超・評価減・',baseItem: true,},
  {s:'618346',v:'461',c:'児発１１・地公体・定超・開所減１・',baseItem: true,},
  {s:'617104',v:'392',c:'児発１１・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'618771',v:'560',c:'児発１１・地公体・定超・開所減２・',baseItem: true,},
  {s:'617107',v:'476',c:'児発１１・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'618347',v:'461',c:'児発１１・地公体・定超・未計画・',baseItem: true,},
  {s:'617110',v:'392',c:'児発１１・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'618348',v:'323',c:'児発１１・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'617113',v:'275',c:'児発１１・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618772',v:'392',c:'児発１１・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'617116',v:'333',c:'児発１１・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'617118',v:'330',c:'児発１１・地公体・定超・未計画２・',baseItem: true,},
  {s:'617120',v:'281',c:'児発１１・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'617122',v:'231',c:'児発１１・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'617124',v:'196',c:'児発１１・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'617126',v:'281',c:'児発１１・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'617128',v:'239',c:'児発１１・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618411',v:'932',c:'児発１２・定超・',baseItem: true,},
  {s:'617131',v:'792',c:'児発１２・定超・評価減・',baseItem: true,},
  {s:'618412',v:'652',c:'児発１２・定超・開所減１・',baseItem: true,},
  {s:'617134',v:'554',c:'児発１２・定超・開所減１・評価減・',baseItem: true,},
  {s:'618773',v:'792',c:'児発１２・定超・開所減２・',baseItem: true,},
  {s:'617137',v:'673',c:'児発１２・定超・開所減２・評価減・',baseItem: true,},
  {s:'618413',v:'652',c:'児発１２・定超・未計画・',baseItem: true,},
  {s:'617140',v:'554',c:'児発１２・定超・未計画・評価減・',baseItem: true,},
  {s:'618414',v:'456',c:'児発１２・定超・未計画・開所減１・',baseItem: true,},
  {s:'617143',v:'388',c:'児発１２・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618774',v:'554',c:'児発１２・定超・未計画・開所減２・',baseItem: true,},
  {s:'617146',v:'471',c:'児発１２・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'617148',v:'466',c:'児発１２・定超・未計画２・',baseItem: true,},
  {s:'617150',v:'396',c:'児発１２・定超・未計画２・評価減・',baseItem: true,},
  {s:'617152',v:'326',c:'児発１２・定超・未計画２・開所減１・',baseItem: true,},
  {s:'617154',v:'277',c:'児発１２・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'617156',v:'396',c:'児発１２・定超・未計画２・開所減２・',baseItem: true,},
  {s:'617158',v:'337',c:'児発１２・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618415',v:'899',c:'児発１２・地公体・定超・',baseItem: true,},
  {s:'617161',v:'764',c:'児発１２・地公体・定超・評価減・',baseItem: true,},
  {s:'618416',v:'629',c:'児発１２・地公体・定超・開所減１・',baseItem: true,},
  {s:'617164',v:'535',c:'児発１２・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'618775',v:'764',c:'児発１２・地公体・定超・開所減２・',baseItem: true,},
  {s:'617167',v:'649',c:'児発１２・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'618417',v:'629',c:'児発１２・地公体・定超・未計画・',baseItem: true,},
  {s:'617170',v:'535',c:'児発１２・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'618418',v:'440',c:'児発１２・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'617173',v:'374',c:'児発１２・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618776',v:'535',c:'児発１２・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'617176',v:'455',c:'児発１２・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'617178',v:'450',c:'児発１２・地公体・定超・未計画２・',baseItem: true,},
  {s:'617180',v:'383',c:'児発１２・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'617182',v:'315',c:'児発１２・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'617184',v:'268',c:'児発１２・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'617186',v:'383',c:'児発１２・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'617188',v:'326',c:'児発１２・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618421',v:'728',c:'児発１３・定超・',baseItem: true,},
  {s:'617191',v:'619',c:'児発１３・定超・評価減・',baseItem: true,},
  {s:'618422',v:'510',c:'児発１３・定超・開所減１・',baseItem: true,},
  {s:'617194',v:'434',c:'児発１３・定超・開所減１・評価減・',baseItem: true,},
  {s:'618777',v:'619',c:'児発１３・定超・開所減２・',baseItem: true,},
  {s:'617197',v:'526',c:'児発１３・定超・開所減２・評価減・',baseItem: true,},
  {s:'618423',v:'510',c:'児発１３・定超・未計画・',baseItem: true,},
  {s:'617200',v:'434',c:'児発１３・定超・未計画・評価減・',baseItem: true,},
  {s:'618424',v:'357',c:'児発１３・定超・未計画・開所減１・',baseItem: true,},
  {s:'617203',v:'303',c:'児発１３・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618778',v:'434',c:'児発１３・定超・未計画・開所減２・',baseItem: true,},
  {s:'617206',v:'369',c:'児発１３・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'617208',v:'364',c:'児発１３・定超・未計画２・',baseItem: true,},
  {s:'617210',v:'309',c:'児発１３・定超・未計画２・評価減・',baseItem: true,},
  {s:'617212',v:'255',c:'児発１３・定超・未計画２・開所減１・',baseItem: true,},
  {s:'617214',v:'217',c:'児発１３・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'617216',v:'309',c:'児発１３・定超・未計画２・開所減２・',baseItem: true,},
  {s:'617218',v:'263',c:'児発１３・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618425',v:'703',c:'児発１３・地公体・定超・',baseItem: true,},
  {s:'617221',v:'598',c:'児発１３・地公体・定超・評価減・',baseItem: true,},
  {s:'618426',v:'492',c:'児発１３・地公体・定超・開所減１・',baseItem: true,},
  {s:'617224',v:'418',c:'児発１３・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'618779',v:'598',c:'児発１３・地公体・定超・開所減２・',baseItem: true,},
  {s:'617227',v:'508',c:'児発１３・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'618427',v:'492',c:'児発１３・地公体・定超・未計画・',baseItem: true,},
  {s:'617230',v:'418',c:'児発１３・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'618428',v:'344',c:'児発１３・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'617233',v:'292',c:'児発１３・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618780',v:'418',c:'児発１３・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'617236',v:'355',c:'児発１３・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'617238',v:'352',c:'児発１３・地公体・定超・未計画２・',baseItem: true,},
  {s:'617240',v:'299',c:'児発１３・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'617242',v:'246',c:'児発１３・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'617244',v:'209',c:'児発１３・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'617246',v:'299',c:'児発１３・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'617248',v:'254',c:'児発１３・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618431',v:'647',c:'児発１４・定超・',baseItem: true,},
  {s:'617251',v:'550',c:'児発１４・定超・評価減・',baseItem: true,},
  {s:'618432',v:'453',c:'児発１４・定超・開所減１・',baseItem: true,},
  {s:'617254',v:'385',c:'児発１４・定超・開所減１・評価減・',baseItem: true,},
  {s:'618781',v:'550',c:'児発１４・定超・開所減２・',baseItem: true,},
  {s:'617257',v:'468',c:'児発１４・定超・開所減２・評価減・',baseItem: true,},
  {s:'618433',v:'453',c:'児発１４・定超・未計画・',baseItem: true,},
  {s:'617260',v:'385',c:'児発１４・定超・未計画・評価減・',baseItem: true,},
  {s:'618434',v:'317',c:'児発１４・定超・未計画・開所減１・',baseItem: true,},
  {s:'617263',v:'269',c:'児発１４・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618782',v:'385',c:'児発１４・定超・未計画・開所減２・',baseItem: true,},
  {s:'617266',v:'327',c:'児発１４・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'617268',v:'324',c:'児発１４・定超・未計画２・',baseItem: true,},
  {s:'617270',v:'275',c:'児発１４・定超・未計画２・評価減・',baseItem: true,},
  {s:'617272',v:'227',c:'児発１４・定超・未計画２・開所減１・',baseItem: true,},
  {s:'617274',v:'193',c:'児発１４・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'617276',v:'275',c:'児発１４・定超・未計画２・開所減２・',baseItem: true,},
  {s:'617278',v:'234',c:'児発１４・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618435',v:'624',c:'児発１４・地公体・定超・',baseItem: true,},
  {s:'617281',v:'530',c:'児発１４・地公体・定超・評価減・',baseItem: true,},
  {s:'618436',v:'437',c:'児発１４・地公体・定超・開所減１・',baseItem: true,},
  {s:'617284',v:'371',c:'児発１４・地公体・定超・開所減１・評価減・',baseItem: true,},
  {s:'618783',v:'530',c:'児発１４・地公体・定超・開所減２・',baseItem: true,},
  {s:'617287',v:'451',c:'児発１４・地公体・定超・開所減２・評価減・',baseItem: true,},
  {s:'618437',v:'437',c:'児発１４・地公体・定超・未計画・',baseItem: true,},
  {s:'617290',v:'371',c:'児発１４・地公体・定超・未計画・評価減・',baseItem: true,},
  {s:'618438',v:'306',c:'児発１４・地公体・定超・未計画・開所減１・',baseItem: true,},
  {s:'617293',v:'260',c:'児発１４・地公体・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618784',v:'371',c:'児発１４・地公体・定超・未計画・開所減２・',baseItem: true,},
  {s:'617296',v:'315',c:'児発１４・地公体・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'617298',v:'312',c:'児発１４・地公体・定超・未計画２・',baseItem: true,},
  {s:'617300',v:'265',c:'児発１４・地公体・定超・未計画２・評価減・',baseItem: true,},
  {s:'617302',v:'218',c:'児発１４・地公体・定超・未計画２・開所減１・',baseItem: true,},
  {s:'617304',v:'185',c:'児発１４・地公体・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'617306',v:'265',c:'児発１４・地公体・定超・未計画２・開所減２・',baseItem: true,},
  {s:'617308',v:'225',c:'児発１４・地公体・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61EB89',v:'2020',c:'児発６１・定超・',baseItem: true,},
  {s:'61EB90',v:'1717',c:'児発６１・定超・評価減・',baseItem: true,},
  {s:'61EB91',v:'1414',c:'児発６１・定超・開所減１・',baseItem: true,},
  {s:'61EB92',v:'1202',c:'児発６１・定超・開所減１・評価減・',baseItem: true,},
  {s:'61EB93',v:'1717',c:'児発６１・定超・開所減２・',baseItem: true,},
  {s:'61EB94',v:'1459',c:'児発６１・定超・開所減２・評価減・',baseItem: true,},
  {s:'61EB95',v:'1414',c:'児発６１・定超・未計画・',baseItem: true,},
  {s:'61EB96',v:'1202',c:'児発６１・定超・未計画・評価減・',baseItem: true,},
  {s:'61EB97',v:'990',c:'児発６１・定超・未計画・開所減１・',baseItem: true,},
  {s:'61EB98',v:'842',c:'児発６１・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61EB99',v:'1202',c:'児発６１・定超・未計画・開所減２・',baseItem: true,},
  {s:'61EC00',v:'1022',c:'児発６１・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61EC01',v:'1010',c:'児発６１・定超・未計画２・',baseItem: true,},
  {s:'61EC02',v:'859',c:'児発６１・定超・未計画２・評価減・',baseItem: true,},
  {s:'61EC03',v:'707',c:'児発６１・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61EC04',v:'601',c:'児発６１・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61EC05',v:'859',c:'児発６１・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61EC06',v:'730',c:'児発６１・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61EC07',v:'1829',c:'児発６２・定超・',baseItem: true,},
  {s:'61EC08',v:'1555',c:'児発６２・定超・評価減・',baseItem: true,},
  {s:'61EC09',v:'1280',c:'児発６２・定超・開所減１・',baseItem: true,},
  {s:'61EC10',v:'1088',c:'児発６２・定超・開所減１・評価減・',baseItem: true,},
  {s:'61EC11',v:'1555',c:'児発６２・定超・開所減２・',baseItem: true,},
  {s:'61EC12',v:'1322',c:'児発６２・定超・開所減２・評価減・',baseItem: true,},
  {s:'61EC13',v:'1280',c:'児発６２・定超・未計画・',baseItem: true,},
  {s:'61EC14',v:'1088',c:'児発６２・定超・未計画・評価減・',baseItem: true,},
  {s:'61EC15',v:'896',c:'児発６２・定超・未計画・開所減１・',baseItem: true,},
  {s:'61EC16',v:'762',c:'児発６２・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61EC17',v:'1088',c:'児発６２・定超・未計画・開所減２・',baseItem: true,},
  {s:'61EC18',v:'925',c:'児発６２・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61EC19',v:'915',c:'児発６２・定超・未計画２・',baseItem: true,},
  {s:'61EC20',v:'778',c:'児発６２・定超・未計画２・評価減・',baseItem: true,},
  {s:'61EC21',v:'641',c:'児発６２・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61EC22',v:'545',c:'児発６２・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61EC23',v:'778',c:'児発６２・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61EC24',v:'661',c:'児発６２・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61EC25',v:'1740',c:'児発６３・定超・',baseItem: true,},
  {s:'61EC26',v:'1479',c:'児発６３・定超・評価減・',baseItem: true,},
  {s:'61EC27',v:'1218',c:'児発６３・定超・開所減１・',baseItem: true,},
  {s:'61EC28',v:'1035',c:'児発６３・定超・開所減１・評価減・',baseItem: true,},
  {s:'61EC29',v:'1479',c:'児発６３・定超・開所減２・',baseItem: true,},
  {s:'61EC30',v:'1257',c:'児発６３・定超・開所減２・評価減・',baseItem: true,},
  {s:'61EC31',v:'1218',c:'児発６３・定超・未計画・',baseItem: true,},
  {s:'61EC32',v:'1035',c:'児発６３・定超・未計画・評価減・',baseItem: true,},
  {s:'61EC33',v:'853',c:'児発６３・定超・未計画・開所減１・',baseItem: true,},
  {s:'61EC34',v:'725',c:'児発６３・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61EC35',v:'1035',c:'児発６３・定超・未計画・開所減２・',baseItem: true,},
  {s:'61EC36',v:'880',c:'児発６３・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61EC37',v:'870',c:'児発６３・定超・未計画２・',baseItem: true,},
  {s:'61EC38',v:'740',c:'児発６３・定超・未計画２・評価減・',baseItem: true,},
  {s:'61EC39',v:'609',c:'児発６３・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61EC40',v:'518',c:'児発６３・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61EC41',v:'740',c:'児発６３・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61EC42',v:'629',c:'児発６３・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61EC43',v:'1320',c:'児発６４・定超・',baseItem: true,},
  {s:'61EC44',v:'1122',c:'児発６４・定超・評価減・',baseItem: true,},
  {s:'61EC45',v:'924',c:'児発６４・定超・開所減１・',baseItem: true,},
  {s:'61EC46',v:'785',c:'児発６４・定超・開所減１・評価減・',baseItem: true,},
  {s:'61EC47',v:'1122',c:'児発６４・定超・開所減２・',baseItem: true,},
  {s:'61EC48',v:'954',c:'児発６４・定超・開所減２・評価減・',baseItem: true,},
  {s:'61EC49',v:'924',c:'児発６４・定超・未計画・',baseItem: true,},
  {s:'61EC50',v:'785',c:'児発６４・定超・未計画・評価減・',baseItem: true,},
  {s:'61EC51',v:'647',c:'児発６４・定超・未計画・開所減１・',baseItem: true,},
  {s:'61EC52',v:'550',c:'児発６４・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61EC53',v:'785',c:'児発６４・定超・未計画・開所減２・',baseItem: true,},
  {s:'61EC54',v:'667',c:'児発６４・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61EC55',v:'660',c:'児発６４・定超・未計画２・',baseItem: true,},
  {s:'61EC56',v:'561',c:'児発６４・定超・未計画２・評価減・',baseItem: true,},
  {s:'61EC57',v:'462',c:'児発６４・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61EC58',v:'393',c:'児発６４・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61EC59',v:'561',c:'児発６４・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61EC60',v:'477',c:'児発６４・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61EC61',v:'1129',c:'児発６５・定超・',baseItem: true,},
  {s:'61EC62',v:'960',c:'児発６５・定超・評価減・',baseItem: true,},
  {s:'61EC63',v:'790',c:'児発６５・定超・開所減１・',baseItem: true,},
  {s:'61EC64',v:'672',c:'児発６５・定超・開所減１・評価減・',baseItem: true,},
  {s:'61EC65',v:'960',c:'児発６５・定超・開所減２・',baseItem: true,},
  {s:'61EC66',v:'816',c:'児発６５・定超・開所減２・評価減・',baseItem: true,},
  {s:'61EC67',v:'790',c:'児発６５・定超・未計画・',baseItem: true,},
  {s:'61EC68',v:'672',c:'児発６５・定超・未計画・評価減・',baseItem: true,},
  {s:'61EC69',v:'553',c:'児発６５・定超・未計画・開所減１・',baseItem: true,},
  {s:'61EC70',v:'470',c:'児発６５・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61EC71',v:'672',c:'児発６５・定超・未計画・開所減２・',baseItem: true,},
  {s:'61EC72',v:'571',c:'児発６５・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61EC73',v:'565',c:'児発６５・定超・未計画２・',baseItem: true,},
  {s:'61EC74',v:'480',c:'児発６５・定超・未計画２・評価減・',baseItem: true,},
  {s:'61EC75',v:'396',c:'児発６５・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61EC76',v:'337',c:'児発６５・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61EC77',v:'480',c:'児発６５・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61EC78',v:'408',c:'児発６５・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61EC79',v:'1040',c:'児発６６・定超・',baseItem: true,},
  {s:'61EC80',v:'884',c:'児発６６・定超・評価減・',baseItem: true,},
  {s:'61EC81',v:'728',c:'児発６６・定超・開所減１・',baseItem: true,},
  {s:'61EC82',v:'619',c:'児発６６・定超・開所減１・評価減・',baseItem: true,},
  {s:'61EC83',v:'884',c:'児発６６・定超・開所減２・',baseItem: true,},
  {s:'61EC84',v:'751',c:'児発６６・定超・開所減２・評価減・',baseItem: true,},
  {s:'61EC85',v:'728',c:'児発６６・定超・未計画・',baseItem: true,},
  {s:'61EC86',v:'619',c:'児発６６・定超・未計画・評価減・',baseItem: true,},
  {s:'61EC87',v:'510',c:'児発６６・定超・未計画・開所減１・',baseItem: true,},
  {s:'61EC88',v:'434',c:'児発６６・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61EC89',v:'619',c:'児発６６・定超・未計画・開所減２・',baseItem: true,},
  {s:'61EC90',v:'526',c:'児発６６・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61EC91',v:'520',c:'児発６６・定超・未計画２・',baseItem: true,},
  {s:'61EC92',v:'442',c:'児発６６・定超・未計画２・評価減・',baseItem: true,},
  {s:'61EC93',v:'364',c:'児発６６・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61EC94',v:'309',c:'児発６６・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61EC95',v:'442',c:'児発６６・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61EC96',v:'376',c:'児発６６・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61EC97',v:'1086',c:'児発６７・定超・',baseItem: true,},
  {s:'61EC98',v:'923',c:'児発６７・定超・評価減・',baseItem: true,},
  {s:'61EC99',v:'760',c:'児発６７・定超・開所減１・',baseItem: true,},
  {s:'61EE00',v:'646',c:'児発６７・定超・開所減１・評価減・',baseItem: true,},
  {s:'61EE01',v:'923',c:'児発６７・定超・開所減２・',baseItem: true,},
  {s:'61EE02',v:'785',c:'児発６７・定超・開所減２・評価減・',baseItem: true,},
  {s:'61EE03',v:'760',c:'児発６７・定超・未計画・',baseItem: true,},
  {s:'61EE04',v:'646',c:'児発６７・定超・未計画・評価減・',baseItem: true,},
  {s:'61EE05',v:'532',c:'児発６７・定超・未計画・開所減１・',baseItem: true,},
  {s:'61EE06',v:'452',c:'児発６７・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61EE07',v:'646',c:'児発６７・定超・未計画・開所減２・',baseItem: true,},
  {s:'61EE08',v:'549',c:'児発６７・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61EE09',v:'543',c:'児発６７・定超・未計画２・',baseItem: true,},
  {s:'61EE10',v:'462',c:'児発６７・定超・未計画２・評価減・',baseItem: true,},
  {s:'61EE11',v:'380',c:'児発６７・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61EE12',v:'323',c:'児発６７・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61EE13',v:'462',c:'児発６７・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61EE14',v:'393',c:'児発６７・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61EE15',v:'896',c:'児発６８・定超・',baseItem: true,},
  {s:'61EE16',v:'762',c:'児発６８・定超・評価減・',baseItem: true,},
  {s:'61EE17',v:'627',c:'児発６８・定超・開所減１・',baseItem: true,},
  {s:'61EE18',v:'533',c:'児発６８・定超・開所減１・評価減・',baseItem: true,},
  {s:'61EE19',v:'762',c:'児発６８・定超・開所減２・',baseItem: true,},
  {s:'61EE20',v:'648',c:'児発６８・定超・開所減２・評価減・',baseItem: true,},
  {s:'61EE21',v:'627',c:'児発６８・定超・未計画・',baseItem: true,},
  {s:'61EE22',v:'533',c:'児発６８・定超・未計画・評価減・',baseItem: true,},
  {s:'61EE23',v:'439',c:'児発６８・定超・未計画・開所減１・',baseItem: true,},
  {s:'61EE24',v:'373',c:'児発６８・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61EE25',v:'533',c:'児発６８・定超・未計画・開所減２・',baseItem: true,},
  {s:'61EE26',v:'453',c:'児発６８・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61EE27',v:'448',c:'児発６８・定超・未計画２・',baseItem: true,},
  {s:'61EE28',v:'381',c:'児発６８・定超・未計画２・評価減・',baseItem: true,},
  {s:'61EE29',v:'314',c:'児発６８・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61EE30',v:'267',c:'児発６８・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61EE31',v:'381',c:'児発６８・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61EE32',v:'324',c:'児発６８・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61EE33',v:'807',c:'児発６９・定超・',baseItem: true,},
  {s:'61EE34',v:'686',c:'児発６９・定超・評価減・',baseItem: true,},
  {s:'61EE35',v:'565',c:'児発６９・定超・開所減１・',baseItem: true,},
  {s:'61EE36',v:'480',c:'児発６９・定超・開所減１・評価減・',baseItem: true,},
  {s:'61EE37',v:'686',c:'児発６９・定超・開所減２・',baseItem: true,},
  {s:'61EE38',v:'583',c:'児発６９・定超・開所減２・評価減・',baseItem: true,},
  {s:'61EE39',v:'565',c:'児発６９・定超・未計画・',baseItem: true,},
  {s:'61EE40',v:'480',c:'児発６９・定超・未計画・評価減・',baseItem: true,},
  {s:'61EE41',v:'396',c:'児発６９・定超・未計画・開所減１・',baseItem: true,},
  {s:'61EE42',v:'337',c:'児発６９・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61EE43',v:'480',c:'児発６９・定超・未計画・開所減２・',baseItem: true,},
  {s:'61EE44',v:'408',c:'児発６９・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61EE45',v:'404',c:'児発６９・定超・未計画２・',baseItem: true,},
  {s:'61EE46',v:'343',c:'児発６９・定超・未計画２・評価減・',baseItem: true,},
  {s:'61EE47',v:'283',c:'児発６９・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61EE48',v:'241',c:'児発６９・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61EE49',v:'343',c:'児発６９・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61EE50',v:'292',c:'児発６９・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618511',v:'620',c:'児発１５・定超・',baseItem: true,},
  {s:'617311',v:'527',c:'児発１５・定超・評価減・',baseItem: true,},
  {s:'618512',v:'434',c:'児発１５・定超・開所減１・',baseItem: true,},
  {s:'617314',v:'369',c:'児発１５・定超・開所減１・評価減・',baseItem: true,},
  {s:'618785',v:'527',c:'児発１５・定超・開所減２・',baseItem: true,},
  {s:'617317',v:'448',c:'児発１５・定超・開所減２・評価減・',baseItem: true,},
  {s:'618513',v:'434',c:'児発１５・定超・未計画・',baseItem: true,},
  {s:'617320',v:'369',c:'児発１５・定超・未計画・評価減・',baseItem: true,},
  {s:'618514',v:'304',c:'児発１５・定超・未計画・開所減１・',baseItem: true,},
  {s:'617323',v:'258',c:'児発１５・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618786',v:'369',c:'児発１５・定超・未計画・開所減２・',baseItem: true,},
  {s:'617326',v:'314',c:'児発１５・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'617328',v:'310',c:'児発１５・定超・未計画２・',baseItem: true,},
  {s:'617330',v:'264',c:'児発１５・定超・未計画２・評価減・',baseItem: true,},
  {s:'617332',v:'217',c:'児発１５・定超・未計画２・開所減１・',baseItem: true,},
  {s:'617334',v:'184',c:'児発１５・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'617336',v:'264',c:'児発１５・定超・未計画２・開所減２・',baseItem: true,},
  {s:'617338',v:'224',c:'児発１５・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618521',v:'429',c:'児発１６・定超・',baseItem: true,},
  {s:'617371',v:'365',c:'児発１６・定超・評価減・',baseItem: true,},
  {s:'618522',v:'300',c:'児発１６・定超・開所減１・',baseItem: true,},
  {s:'617374',v:'255',c:'児発１６・定超・開所減１・評価減・',baseItem: true,},
  {s:'618793',v:'365',c:'児発１６・定超・開所減２・',baseItem: true,},
  {s:'617377',v:'310',c:'児発１６・定超・開所減２・評価減・',baseItem: true,},
  {s:'618523',v:'300',c:'児発１６・定超・未計画・',baseItem: true,},
  {s:'617380',v:'255',c:'児発１６・定超・未計画・評価減・',baseItem: true,},
  {s:'618524',v:'210',c:'児発１６・定超・未計画・開所減１・',baseItem: true,},
  {s:'617383',v:'179',c:'児発１６・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618794',v:'255',c:'児発１６・定超・未計画・開所減２・',baseItem: true,},
  {s:'617386',v:'217',c:'児発１６・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'617388',v:'215',c:'児発１６・定超・未計画２・',baseItem: true,},
  {s:'617390',v:'183',c:'児発１６・定超・未計画２・評価減・',baseItem: true,},
  {s:'617392',v:'151',c:'児発１６・定超・未計画２・開所減１・',baseItem: true,},
  {s:'617394',v:'128',c:'児発１６・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'617396',v:'183',c:'児発１６・定超・未計画２・開所減２・',baseItem: true,},
  {s:'617398',v:'156',c:'児発１６・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618531',v:'340',c:'児発１７・定超・',baseItem: true,},
  {s:'617431',v:'289',c:'児発１７・定超・評価減・',baseItem: true,},
  {s:'618532',v:'238',c:'児発１７・定超・開所減１・',baseItem: true,},
  {s:'617434',v:'202',c:'児発１７・定超・開所減１・評価減・',baseItem: true,},
  {s:'618801',v:'289',c:'児発１７・定超・開所減２・',baseItem: true,},
  {s:'617437',v:'246',c:'児発１７・定超・開所減２・評価減・',baseItem: true,},
  {s:'618533',v:'238',c:'児発１７・定超・未計画・',baseItem: true,},
  {s:'617440',v:'202',c:'児発１７・定超・未計画・評価減・',baseItem: true,},
  {s:'618534',v:'167',c:'児発１７・定超・未計画・開所減１・',baseItem: true,},
  {s:'617443',v:'142',c:'児発１７・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618802',v:'202',c:'児発１７・定超・未計画・開所減２・',baseItem: true,},
  {s:'617446',v:'172',c:'児発１７・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'617448',v:'170',c:'児発１７・定超・未計画２・',baseItem: true,},
  {s:'617450',v:'145',c:'児発１７・定超・未計画２・評価減・',baseItem: true,},
  {s:'617452',v:'119',c:'児発１７・定超・未計画２・開所減１・',baseItem: true,},
  {s:'617454',v:'101',c:'児発１７・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'617456',v:'145',c:'児発１７・定超・未計画２・開所減２・',baseItem: true,},
  {s:'617458',v:'123',c:'児発１７・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61EE51',v:'1928',c:'児発７０・定超・',baseItem: true,},
  {s:'61EE52',v:'1639',c:'児発７０・定超・評価減・',baseItem: true,},
  {s:'61EE53',v:'1350',c:'児発７０・定超・開所減１・',baseItem: true,},
  {s:'61EE54',v:'1148',c:'児発７０・定超・開所減１・評価減・',baseItem: true,},
  {s:'61EE55',v:'1639',c:'児発７０・定超・開所減２・',baseItem: true,},
  {s:'61EE56',v:'1393',c:'児発７０・定超・開所減２・評価減・',baseItem: true,},
  {s:'61EE57',v:'1350',c:'児発７０・定超・未計画・',baseItem: true,},
  {s:'61EE58',v:'1148',c:'児発７０・定超・未計画・評価減・',baseItem: true,},
  {s:'61EE59',v:'945',c:'児発７０・定超・未計画・開所減１・',baseItem: true,},
  {s:'61EE60',v:'803',c:'児発７０・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61EE61',v:'1148',c:'児発７０・定超・未計画・開所減２・',baseItem: true,},
  {s:'61EE62',v:'976',c:'児発７０・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61EE63',v:'964',c:'児発７０・定超・未計画２・',baseItem: true,},
  {s:'61EE64',v:'819',c:'児発７０・定超・未計画２・評価減・',baseItem: true,},
  {s:'61EE65',v:'675',c:'児発７０・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61EE66',v:'574',c:'児発７０・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61EE67',v:'819',c:'児発７０・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61EE68',v:'696',c:'児発７０・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61EE69',v:'1759',c:'児発７１・定超・',baseItem: true,},
  {s:'61EE70',v:'1495',c:'児発７１・定超・評価減・',baseItem: true,},
  {s:'61EE71',v:'1231',c:'児発７１・定超・開所減１・',baseItem: true,},
  {s:'61EE72',v:'1046',c:'児発７１・定超・開所減１・評価減・',baseItem: true,},
  {s:'61EE73',v:'1495',c:'児発７１・定超・開所減２・',baseItem: true,},
  {s:'61EE74',v:'1271',c:'児発７１・定超・開所減２・評価減・',baseItem: true,},
  {s:'61EE75',v:'1231',c:'児発７１・定超・未計画・',baseItem: true,},
  {s:'61EE76',v:'1046',c:'児発７１・定超・未計画・評価減・',baseItem: true,},
  {s:'61EE77',v:'862',c:'児発７１・定超・未計画・開所減１・',baseItem: true,},
  {s:'61EE78',v:'733',c:'児発７１・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61EE79',v:'1046',c:'児発７１・定超・未計画・開所減２・',baseItem: true,},
  {s:'61EE80',v:'889',c:'児発７１・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61EE81',v:'880',c:'児発７１・定超・未計画２・',baseItem: true,},
  {s:'61EE82',v:'748',c:'児発７１・定超・未計画２・評価減・',baseItem: true,},
  {s:'61EE83',v:'616',c:'児発７１・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61EE84',v:'524',c:'児発７１・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61EE85',v:'748',c:'児発７１・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61EE86',v:'636',c:'児発７１・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61EE87',v:'1683',c:'児発７２・定超・',baseItem: true,},
  {s:'61EE88',v:'1431',c:'児発７２・定超・評価減・',baseItem: true,},
  {s:'61EE89',v:'1178',c:'児発７２・定超・開所減１・',baseItem: true,},
  {s:'61EE90',v:'1001',c:'児発７２・定超・開所減１・評価減・',baseItem: true,},
  {s:'61EE91',v:'1431',c:'児発７２・定超・開所減２・',baseItem: true,},
  {s:'61EE92',v:'1216',c:'児発７２・定超・開所減２・評価減・',baseItem: true,},
  {s:'61EE93',v:'1178',c:'児発７２・定超・未計画・',baseItem: true,},
  {s:'61EE94',v:'1001',c:'児発７２・定超・未計画・評価減・',baseItem: true,},
  {s:'61EE95',v:'825',c:'児発７２・定超・未計画・開所減１・',baseItem: true,},
  {s:'61EE96',v:'701',c:'児発７２・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61EE97',v:'1001',c:'児発７２・定超・未計画・開所減２・',baseItem: true,},
  {s:'61EE98',v:'851',c:'児発７２・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61EE99',v:'842',c:'児発７２・定超・未計画２・',baseItem: true,},
  {s:'61EF00',v:'716',c:'児発７２・定超・未計画２・評価減・',baseItem: true,},
  {s:'61EF01',v:'589',c:'児発７２・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61EF02',v:'501',c:'児発７２・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61EF03',v:'716',c:'児発７２・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61EF04',v:'609',c:'児発７２・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61EF05',v:'1228',c:'児発７３・定超・',baseItem: true,},
  {s:'61EF06',v:'1044',c:'児発７３・定超・評価減・',baseItem: true,},
  {s:'61EF07',v:'860',c:'児発７３・定超・開所減１・',baseItem: true,},
  {s:'61EF08',v:'731',c:'児発７３・定超・開所減１・評価減・',baseItem: true,},
  {s:'61EF09',v:'1044',c:'児発７３・定超・開所減２・',baseItem: true,},
  {s:'61EF10',v:'887',c:'児発７３・定超・開所減２・評価減・',baseItem: true,},
  {s:'61EF11',v:'860',c:'児発７３・定超・未計画・',baseItem: true,},
  {s:'61EF12',v:'731',c:'児発７３・定超・未計画・評価減・',baseItem: true,},
  {s:'61EF13',v:'602',c:'児発７３・定超・未計画・開所減１・',baseItem: true,},
  {s:'61EF14',v:'512',c:'児発７３・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61EF15',v:'731',c:'児発７３・定超・未計画・開所減２・',baseItem: true,},
  {s:'61EF16',v:'621',c:'児発７３・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61EF17',v:'614',c:'児発７３・定超・未計画２・',baseItem: true,},
  {s:'61EF18',v:'522',c:'児発７３・定超・未計画２・評価減・',baseItem: true,},
  {s:'61EF19',v:'430',c:'児発７３・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61EF20',v:'366',c:'児発７３・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61EF21',v:'522',c:'児発７３・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61EF22',v:'444',c:'児発７３・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61EF23',v:'1059',c:'児発７４・定超・',baseItem: true,},
  {s:'61EF24',v:'900',c:'児発７４・定超・評価減・',baseItem: true,},
  {s:'61EF25',v:'741',c:'児発７４・定超・開所減１・',baseItem: true,},
  {s:'61EF26',v:'630',c:'児発７４・定超・開所減１・評価減・',baseItem: true,},
  {s:'61EF27',v:'900',c:'児発７４・定超・開所減２・',baseItem: true,},
  {s:'61EF28',v:'765',c:'児発７４・定超・開所減２・評価減・',baseItem: true,},
  {s:'61EF29',v:'741',c:'児発７４・定超・未計画・',baseItem: true,},
  {s:'61EF30',v:'630',c:'児発７４・定超・未計画・評価減・',baseItem: true,},
  {s:'61EF31',v:'519',c:'児発７４・定超・未計画・開所減１・',baseItem: true,},
  {s:'61EF32',v:'441',c:'児発７４・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61EF33',v:'630',c:'児発７４・定超・未計画・開所減２・',baseItem: true,},
  {s:'61EF34',v:'536',c:'児発７４・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61EF35',v:'530',c:'児発７４・定超・未計画２・',baseItem: true,},
  {s:'61EF36',v:'451',c:'児発７４・定超・未計画２・評価減・',baseItem: true,},
  {s:'61EF37',v:'371',c:'児発７４・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61EF38',v:'315',c:'児発７４・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61EF39',v:'451',c:'児発７４・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61EF40',v:'383',c:'児発７４・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61EF41',v:'983',c:'児発７５・定超・',baseItem: true,},
  {s:'61EF42',v:'836',c:'児発７５・定超・評価減・',baseItem: true,},
  {s:'61EF43',v:'688',c:'児発７５・定超・開所減１・',baseItem: true,},
  {s:'61EF44',v:'585',c:'児発７５・定超・開所減１・評価減・',baseItem: true,},
  {s:'61EF45',v:'836',c:'児発７５・定超・開所減２・',baseItem: true,},
  {s:'61EF46',v:'711',c:'児発７５・定超・開所減２・評価減・',baseItem: true,},
  {s:'61EF47',v:'688',c:'児発７５・定超・未計画・',baseItem: true,},
  {s:'61EF48',v:'585',c:'児発７５・定超・未計画・評価減・',baseItem: true,},
  {s:'61EF49',v:'482',c:'児発７５・定超・未計画・開所減１・',baseItem: true,},
  {s:'61EF50',v:'410',c:'児発７５・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61EF51',v:'585',c:'児発７５・定超・未計画・開所減２・',baseItem: true,},
  {s:'61EF52',v:'497',c:'児発７５・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61EF53',v:'492',c:'児発７５・定超・未計画２・',baseItem: true,},
  {s:'61EF54',v:'418',c:'児発７５・定超・未計画２・評価減・',baseItem: true,},
  {s:'61EF55',v:'344',c:'児発７５・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61EF56',v:'292',c:'児発７５・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61EF57',v:'418',c:'児発７５・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61EF58',v:'355',c:'児発７５・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61EF59',v:'995',c:'児発７６・定超・',baseItem: true,},
  {s:'61EF60',v:'846',c:'児発７６・定超・評価減・',baseItem: true,},
  {s:'61EF61',v:'697',c:'児発７６・定超・開所減１・',baseItem: true,},
  {s:'61EF62',v:'592',c:'児発７６・定超・開所減１・評価減・',baseItem: true,},
  {s:'61EF63',v:'846',c:'児発７６・定超・開所減２・',baseItem: true,},
  {s:'61EF64',v:'719',c:'児発７６・定超・開所減２・評価減・',baseItem: true,},
  {s:'61EF65',v:'697',c:'児発７６・定超・未計画・',baseItem: true,},
  {s:'61EF66',v:'592',c:'児発７６・定超・未計画・評価減・',baseItem: true,},
  {s:'61EF67',v:'488',c:'児発７６・定超・未計画・開所減１・',baseItem: true,},
  {s:'61EF68',v:'415',c:'児発７６・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61EF69',v:'592',c:'児発７６・定超・未計画・開所減２・',baseItem: true,},
  {s:'61EF70',v:'503',c:'児発７６・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61EF71',v:'498',c:'児発７６・定超・未計画２・',baseItem: true,},
  {s:'61EF72',v:'423',c:'児発７６・定超・未計画２・評価減・',baseItem: true,},
  {s:'61EF73',v:'349',c:'児発７６・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61EF74',v:'297',c:'児発７６・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61EF75',v:'423',c:'児発７６・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61EF76',v:'360',c:'児発７６・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61EF77',v:'826',c:'児発７７・定超・',baseItem: true,},
  {s:'61EF78',v:'702',c:'児発７７・定超・評価減・',baseItem: true,},
  {s:'61EF79',v:'578',c:'児発７７・定超・開所減１・',baseItem: true,},
  {s:'61EF80',v:'491',c:'児発７７・定超・開所減１・評価減・',baseItem: true,},
  {s:'61EF81',v:'702',c:'児発７７・定超・開所減２・',baseItem: true,},
  {s:'61EF82',v:'597',c:'児発７７・定超・開所減２・評価減・',baseItem: true,},
  {s:'61EF83',v:'578',c:'児発７７・定超・未計画・',baseItem: true,},
  {s:'61EF84',v:'491',c:'児発７７・定超・未計画・評価減・',baseItem: true,},
  {s:'61EF85',v:'405',c:'児発７７・定超・未計画・開所減１・',baseItem: true,},
  {s:'61EF86',v:'344',c:'児発７７・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61EF87',v:'491',c:'児発７７・定超・未計画・開所減２・',baseItem: true,},
  {s:'61EF88',v:'417',c:'児発７７・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61EF89',v:'413',c:'児発７７・定超・未計画２・',baseItem: true,},
  {s:'61EF90',v:'351',c:'児発７７・定超・未計画２・評価減・',baseItem: true,},
  {s:'61EF91',v:'289',c:'児発７７・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61EF92',v:'246',c:'児発７７・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61EF93',v:'351',c:'児発７７・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61EF94',v:'298',c:'児発７７・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61EF95',v:'750',c:'児発７８・定超・',baseItem: true,},
  {s:'61EF96',v:'638',c:'児発７８・定超・評価減・',baseItem: true,},
  {s:'61EF97',v:'525',c:'児発７８・定超・開所減１・',baseItem: true,},
  {s:'61EF98',v:'446',c:'児発７８・定超・開所減１・評価減・',baseItem: true,},
  {s:'61EF99',v:'638',c:'児発７８・定超・開所減２・',baseItem: true,},
  {s:'61EG00',v:'542',c:'児発７８・定超・開所減２・評価減・',baseItem: true,},
  {s:'61EG01',v:'525',c:'児発７８・定超・未計画・',baseItem: true,},
  {s:'61EG02',v:'446',c:'児発７８・定超・未計画・評価減・',baseItem: true,},
  {s:'61EG03',v:'368',c:'児発７８・定超・未計画・開所減１・',baseItem: true,},
  {s:'61EG04',v:'313',c:'児発７８・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61EG05',v:'446',c:'児発７８・定超・未計画・開所減２・',baseItem: true,},
  {s:'61EG06',v:'379',c:'児発７８・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61EG07',v:'375',c:'児発７８・定超・未計画２・',baseItem: true,},
  {s:'61EG08',v:'319',c:'児発７８・定超・未計画２・評価減・',baseItem: true,},
  {s:'61EG09',v:'263',c:'児発７８・定超・未計画２・開所減１・',baseItem: true,},
  {s:'61EG10',v:'224',c:'児発７８・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61EG11',v:'319',c:'児発７８・定超・未計画２・開所減２・',baseItem: true,},
  {s:'61EG12',v:'271',c:'児発７８・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'617490',v:'528',c:'児発１８・定超・',baseItem: true,},
  {s:'617492',v:'449',c:'児発１８・定超・評価減・',baseItem: true,},
  {s:'617494',v:'370',c:'児発１８・定超・開所減１・',baseItem: true,},
  {s:'617496',v:'315',c:'児発１８・定超・開所減１・評価減・',baseItem: true,},
  {s:'617498',v:'449',c:'児発１８・定超・開所減２・',baseItem: true,},
  {s:'617500',v:'382',c:'児発１８・定超・開所減２・評価減・',baseItem: true,},
  {s:'617502',v:'370',c:'児発１８・定超・未計画・',baseItem: true,},
  {s:'617504',v:'315',c:'児発１８・定超・未計画・評価減・',baseItem: true,},
  {s:'617506',v:'259',c:'児発１８・定超・未計画・開所減１・',baseItem: true,},
  {s:'617508',v:'220',c:'児発１８・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'617510',v:'315',c:'児発１８・定超・未計画・開所減２・',baseItem: true,},
  {s:'617512',v:'268',c:'児発１８・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'617514',v:'264',c:'児発１８・定超・未計画２・',baseItem: true,},
  {s:'617516',v:'224',c:'児発１８・定超・未計画２・評価減・',baseItem: true,},
  {s:'617518',v:'185',c:'児発１８・定超・未計画２・開所減１・',baseItem: true,},
  {s:'617520',v:'157',c:'児発１８・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'617522',v:'224',c:'児発１８・定超・未計画２・開所減２・',baseItem: true,},
  {s:'617524',v:'190',c:'児発１８・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'617570',v:'359',c:'児発１９・定超・',baseItem: true,},
  {s:'617572',v:'305',c:'児発１９・定超・評価減・',baseItem: true,},
  {s:'617574',v:'251',c:'児発１９・定超・開所減１・',baseItem: true,},
  {s:'617576',v:'213',c:'児発１９・定超・開所減１・評価減・',baseItem: true,},
  {s:'617578',v:'305',c:'児発１９・定超・開所減２・',baseItem: true,},
  {s:'617580',v:'259',c:'児発１９・定超・開所減２・評価減・',baseItem: true,},
  {s:'617582',v:'251',c:'児発１９・定超・未計画・',baseItem: true,},
  {s:'617584',v:'213',c:'児発１９・定超・未計画・評価減・',baseItem: true,},
  {s:'617586',v:'176',c:'児発１９・定超・未計画・開所減１・',baseItem: true,},
  {s:'617588',v:'150',c:'児発１９・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'617590',v:'213',c:'児発１９・定超・未計画・開所減２・',baseItem: true,},
  {s:'617592',v:'181',c:'児発１９・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'617594',v:'180',c:'児発１９・定超・未計画２・',baseItem: true,},
  {s:'617596',v:'153',c:'児発１９・定超・未計画２・評価減・',baseItem: true,},
  {s:'617598',v:'126',c:'児発１９・定超・未計画２・開所減１・',baseItem: true,},
  {s:'617600',v:'107',c:'児発１９・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'617602',v:'153',c:'児発１９・定超・未計画２・開所減２・',baseItem: true,},
  {s:'617604',v:'130',c:'児発１９・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'617642',v:'283',c:'児発２０・定超・',baseItem: true,},
  {s:'617644',v:'241',c:'児発２０・定超・評価減・',baseItem: true,},
  {s:'617646',v:'198',c:'児発２０・定超・開所減１・',baseItem: true,},
  {s:'617648',v:'168',c:'児発２０・定超・開所減１・評価減・',baseItem: true,},
  {s:'617650',v:'241',c:'児発２０・定超・開所減２・',baseItem: true,},
  {s:'617652',v:'205',c:'児発２０・定超・開所減２・評価減・',baseItem: true,},
  {s:'617654',v:'198',c:'児発２０・定超・未計画・',baseItem: true,},
  {s:'617656',v:'168',c:'児発２０・定超・未計画・評価減・',baseItem: true,},
  {s:'617658',v:'139',c:'児発２０・定超・未計画・開所減１・',baseItem: true,},
  {s:'617660',v:'118',c:'児発２０・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'617662',v:'168',c:'児発２０・定超・未計画・開所減２・',baseItem: true,},
  {s:'617664',v:'143',c:'児発２０・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'617666',v:'142',c:'児発２０・定超・未計画２・',baseItem: true,},
  {s:'617668',v:'121',c:'児発２０・定超・未計画２・評価減・',baseItem: true,},
  {s:'617670',v:'99',c:'児発２０・定超・未計画２・開所減１・',baseItem: true,},
  {s:'617672',v:'84',c:'児発２０・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'617674',v:'121',c:'児発２０・定超・未計画２・開所減２・',baseItem: true,},
  {s:'617676',v:'103',c:'児発２０・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618611',v:'1469',c:'児発２１・定超・',baseItem: true,},
  {s:'617715',v:'1249',c:'児発２１・定超・評価減・',baseItem: true,},
  {s:'618612',v:'1028',c:'児発２１・定超・開所減１・',baseItem: true,},
  {s:'617718',v:'874',c:'児発２１・定超・開所減１・評価減・',baseItem: true,},
  {s:'618615',v:'1249',c:'児発２１・定超・開所減２・',baseItem: true,},
  {s:'617721',v:'1062',c:'児発２１・定超・開所減２・評価減・',baseItem: true,},
  {s:'618613',v:'1028',c:'児発２１・定超・未計画・',baseItem: true,},
  {s:'617724',v:'874',c:'児発２１・定超・未計画・評価減・',baseItem: true,},
  {s:'618614',v:'720',c:'児発２１・定超・未計画・開所減１・',baseItem: true,},
  {s:'617727',v:'612',c:'児発２１・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618616',v:'874',c:'児発２１・定超・未計画・開所減２・',baseItem: true,},
  {s:'617730',v:'743',c:'児発２１・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'617732',v:'735',c:'児発２１・定超・未計画２・',baseItem: true,},
  {s:'617734',v:'625',c:'児発２１・定超・未計画２・評価減・',baseItem: true,},
  {s:'617736',v:'515',c:'児発２１・定超・未計画２・開所減１・',baseItem: true,},
  {s:'617738',v:'438',c:'児発２１・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'617740',v:'625',c:'児発２１・定超・未計画２・開所減２・',baseItem: true,},
  {s:'617742',v:'531',c:'児発２１・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618651',v:'1058',c:'児発２３・定超・',baseItem: true,},
  {s:'617781',v:'899',c:'児発２３・定超・評価減・',baseItem: true,},
  {s:'618652',v:'741',c:'児発２３・定超・開所減１・',baseItem: true,},
  {s:'617784',v:'630',c:'児発２３・定超・開所減１・評価減・',baseItem: true,},
  {s:'618653',v:'899',c:'児発２３・定超・開所減２・',baseItem: true,},
  {s:'617787',v:'764',c:'児発２３・定超・開所減２・評価減・',baseItem: true,},
  {s:'618654',v:'741',c:'児発２３・定超・未計画・',baseItem: true,},
  {s:'617790',v:'630',c:'児発２３・定超・未計画・評価減・',baseItem: true,},
  {s:'618655',v:'519',c:'児発２３・定超・未計画・開所減１・',baseItem: true,},
  {s:'617793',v:'441',c:'児発２３・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618656',v:'630',c:'児発２３・定超・未計画・開所減２・',baseItem: true,},
  {s:'617796',v:'536',c:'児発２３・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'617798',v:'529',c:'児発２３・定超・未計画２・',baseItem: true,},
  {s:'617800',v:'450',c:'児発２３・定超・未計画２・評価減・',baseItem: true,},
  {s:'617802',v:'370',c:'児発２３・定超・未計画２・開所減１・',baseItem: true,},
  {s:'617804',v:'315',c:'児発２３・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'617806',v:'450',c:'児発２３・定超・未計画２・開所減２・',baseItem: true,},
  {s:'617808',v:'383',c:'児発２３・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618661',v:'928',c:'児発２４・定超・',baseItem: true,},
  {s:'617811',v:'789',c:'児発２４・定超・評価減・',baseItem: true,},
  {s:'618662',v:'650',c:'児発２４・定超・開所減１・',baseItem: true,},
  {s:'617814',v:'553',c:'児発２４・定超・開所減１・評価減・',baseItem: true,},
  {s:'618663',v:'789',c:'児発２４・定超・開所減２・',baseItem: true,},
  {s:'617817',v:'671',c:'児発２４・定超・開所減２・評価減・',baseItem: true,},
  {s:'618664',v:'650',c:'児発２４・定超・未計画・',baseItem: true,},
  {s:'617820',v:'553',c:'児発２４・定超・未計画・評価減・',baseItem: true,},
  {s:'618665',v:'455',c:'児発２４・定超・未計画・開所減１・',baseItem: true,},
  {s:'617823',v:'387',c:'児発２４・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618666',v:'553',c:'児発２４・定超・未計画・開所減２・',baseItem: true,},
  {s:'617826',v:'470',c:'児発２４・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'617828',v:'464',c:'児発２４・定超・未計画２・',baseItem: true,},
  {s:'617830',v:'394',c:'児発２４・定超・未計画２・評価減・',baseItem: true,},
  {s:'617832',v:'325',c:'児発２４・定超・未計画２・開所減１・',baseItem: true,},
  {s:'617834',v:'276',c:'児発２４・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'617836',v:'394',c:'児発２４・定超・未計画２・開所減２・',baseItem: true,},
  {s:'617838',v:'335',c:'児発２４・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618671',v:'829',c:'児発２５・定超・',baseItem: true,},
  {s:'617841',v:'705',c:'児発２５・定超・評価減・',baseItem: true,},
  {s:'618672',v:'580',c:'児発２５・定超・開所減１・',baseItem: true,},
  {s:'617844',v:'493',c:'児発２５・定超・開所減１・評価減・',baseItem: true,},
  {s:'618673',v:'705',c:'児発２５・定超・開所減２・',baseItem: true,},
  {s:'617847',v:'599',c:'児発２５・定超・開所減２・評価減・',baseItem: true,},
  {s:'618674',v:'580',c:'児発２５・定超・未計画・',baseItem: true,},
  {s:'617850',v:'493',c:'児発２５・定超・未計画・評価減・',baseItem: true,},
  {s:'618675',v:'406',c:'児発２５・定超・未計画・開所減１・',baseItem: true,},
  {s:'617853',v:'345',c:'児発２５・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618676',v:'493',c:'児発２５・定超・未計画・開所減２・',baseItem: true,},
  {s:'617856',v:'419',c:'児発２５・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'617858',v:'415',c:'児発２５・定超・未計画２・',baseItem: true,},
  {s:'617860',v:'353',c:'児発２５・定超・未計画２・評価減・',baseItem: true,},
  {s:'617862',v:'291',c:'児発２５・定超・未計画２・開所減１・',baseItem: true,},
  {s:'617864',v:'247',c:'児発２５・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'617866',v:'353',c:'児発２５・定超・未計画２・開所減２・',baseItem: true,},
  {s:'617868',v:'300',c:'児発２５・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618621',v:'748',c:'児発２６・定超・',baseItem: true,},
  {s:'617871',v:'636',c:'児発２６・定超・評価減・',baseItem: true,},
  {s:'618622',v:'524',c:'児発２６・定超・開所減１・',baseItem: true,},
  {s:'617874',v:'445',c:'児発２６・定超・開所減１・評価減・',baseItem: true,},
  {s:'618625',v:'636',c:'児発２６・定超・開所減２・',baseItem: true,},
  {s:'617877',v:'541',c:'児発２６・定超・開所減２・評価減・',baseItem: true,},
  {s:'618623',v:'524',c:'児発２６・定超・未計画・',baseItem: true,},
  {s:'617880',v:'445',c:'児発２６・定超・未計画・評価減・',baseItem: true,},
  {s:'618624',v:'367',c:'児発２６・定超・未計画・開所減１・',baseItem: true,},
  {s:'617883',v:'312',c:'児発２６・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618626',v:'445',c:'児発２６・定超・未計画・開所減２・',baseItem: true,},
  {s:'617886',v:'378',c:'児発２６・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'617888',v:'374',c:'児発２６・定超・未計画２・',baseItem: true,},
  {s:'617890',v:'318',c:'児発２６・定超・未計画２・評価減・',baseItem: true,},
  {s:'617892',v:'262',c:'児発２６・定超・未計画２・開所減１・',baseItem: true,},
  {s:'617894',v:'223',c:'児発２６・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'617896',v:'318',c:'児発２６・定超・未計画２・開所減２・',baseItem: true,},
  {s:'617898',v:'270',c:'児発２６・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'618631',v:'586',c:'児発２７・定超・',baseItem: true,},
  {s:'617901',v:'498',c:'児発２７・定超・評価減・',baseItem: true,},
  {s:'618632',v:'410',c:'児発２７・定超・開所減１・',baseItem: true,},
  {s:'617904',v:'349',c:'児発２７・定超・開所減１・評価減・',baseItem: true,},
  {s:'618635',v:'498',c:'児発２７・定超・開所減２・',baseItem: true,},
  {s:'617907',v:'423',c:'児発２７・定超・開所減２・評価減・',baseItem: true,},
  {s:'618633',v:'410',c:'児発２７・定超・未計画・',baseItem: true,},
  {s:'617910',v:'349',c:'児発２７・定超・未計画・評価減・',baseItem: true,},
  {s:'618634',v:'287',c:'児発２７・定超・未計画・開所減１・',baseItem: true,},
  {s:'617913',v:'244',c:'児発２７・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'618636',v:'349',c:'児発２７・定超・未計画・開所減２・',baseItem: true,},
  {s:'617916',v:'297',c:'児発２７・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'617918',v:'293',c:'児発２７・定超・未計画２・',baseItem: true,},
  {s:'617920',v:'249',c:'児発２７・定超・未計画２・評価減・',baseItem: true,},
  {s:'617922',v:'205',c:'児発２７・定超・未計画２・開所減１・',baseItem: true,},
  {s:'617924',v:'174',c:'児発２７・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'617926',v:'249',c:'児発２７・定超・未計画２・開所減２・',baseItem: true,},
  {s:'617928',v:'212',c:'児発２７・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'617930',v:'414',c:'共生型児発・定超・',baseItem: true,},
  {s:'617932',v:'352',c:'共生型児発・定超・評価減・',baseItem: true,},
  {s:'617934',v:'290',c:'共生型児発・定超・開所減１・',baseItem: true,},
  {s:'617936',v:'247',c:'共生型児発・定超・開所減１・評価減・',baseItem: true,},
  {s:'617938',v:'352',c:'共生型児発・定超・開所減２・',baseItem: true,},
  {s:'617940',v:'299',c:'共生型児発・定超・開所減２・評価減・',baseItem: true,},
  {s:'617950',v:'491',c:'基準該当児発Ⅰ・定超・',baseItem: true,},
  {s:'617951',v:'417',c:'基準該当児発Ⅰ・定超・評価減・',baseItem: true,},
  {s:'617952',v:'344',c:'基準該当児発Ⅰ・定超・開所減１・',baseItem: true,},
  {s:'617953',v:'292',c:'基準該当児発Ⅰ・定超・開所減１・評価減・',baseItem: true,},
  {s:'617954',v:'417',c:'基準該当児発Ⅰ・定超・開所減２・',baseItem: true,},
  {s:'617955',v:'354',c:'基準該当児発Ⅰ・定超・開所減２・評価減・',baseItem: true,},
  {s:'617956',v:'344',c:'基準該当児発Ⅰ・定超・未計画・',baseItem: true,},
  {s:'617957',v:'292',c:'基準該当児発Ⅰ・定超・未計画・評価減・',baseItem: true,},
  {s:'617958',v:'241',c:'基準該当児発Ⅰ・定超・未計画・開所減１・',baseItem: true,},
  {s:'617959',v:'205',c:'基準該当児発Ⅰ・定超・未計画・開所減１・評価減・',baseItem: true,},
  {s:'617960',v:'292',c:'基準該当児発Ⅰ・定超・未計画・開所減２・',baseItem: true,},
  {s:'617961',v:'248',c:'基準該当児発Ⅰ・定超・未計画・開所減２・評価減・',baseItem: true,},
  {s:'617962',v:'246',c:'基準該当児発Ⅰ・定超・未計画２・',baseItem: true,},
  {s:'617963',v:'209',c:'基準該当児発Ⅰ・定超・未計画２・評価減・',baseItem: true,},
  {s:'617964',v:'172',c:'基準該当児発Ⅰ・定超・未計画２・開所減１・',baseItem: true,},
  {s:'617965',v:'146',c:'基準該当児発Ⅰ・定超・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'617966',v:'209',c:'基準該当児発Ⅰ・定超・未計画２・開所減２・',baseItem: true,},
  {s:'617967',v:'178',c:'基準該当児発Ⅰ・定超・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'617970',v:'414',c:'基準該当児発Ⅱ・定超・',baseItem: true,},
  {s:'617971',v:'352',c:'基準該当児発Ⅱ・定超・評価減・',baseItem: true,},
  {s:'617972',v:'290',c:'基準該当児発Ⅱ・定超・開所減１・',baseItem: true,},
  {s:'617973',v:'247',c:'基準該当児発Ⅱ・定超・開所減１・評価減・',baseItem: true,},
  {s:'617974',v:'352',c:'基準該当児発Ⅱ・定超・開所減２・',baseItem: true,},
  {s:'617975',v:'299',c:'基準該当児発Ⅱ・定超・開所減２・評価減・',baseItem: true,},
  {s:'61F001',v:'2020',c:'児発６１・人欠・',baseItem: true,},
  {s:'61F002',v:'1717',c:'児発６１・人欠・評価減・',baseItem: true,},
  {s:'61F003',v:'1414',c:'児発６１・人欠・開所減１・',baseItem: true,},
  {s:'61F004',v:'1202',c:'児発６１・人欠・開所減１・評価減・',baseItem: true,},
  {s:'61F005',v:'1717',c:'児発６１・人欠・開所減２・',baseItem: true,},
  {s:'61F006',v:'1459',c:'児発６１・人欠・開所減２・評価減・',baseItem: true,},
  {s:'61F007',v:'1414',c:'児発６１・人欠・未計画・',baseItem: true,},
  {s:'61F008',v:'1202',c:'児発６１・人欠・未計画・評価減・',baseItem: true,},
  {s:'61F009',v:'990',c:'児発６１・人欠・未計画・開所減１・',baseItem: true,},
  {s:'61F010',v:'842',c:'児発６１・人欠・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61F011',v:'1202',c:'児発６１・人欠・未計画・開所減２・',baseItem: true,},
  {s:'61F012',v:'1022',c:'児発６１・人欠・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61F013',v:'1010',c:'児発６１・人欠・未計画２・',baseItem: true,},
  {s:'61F014',v:'859',c:'児発６１・人欠・未計画２・評価減・',baseItem: true,},
  {s:'61F015',v:'707',c:'児発６１・人欠・未計画２・開所減１・',baseItem: true,},
  {s:'61F016',v:'601',c:'児発６１・人欠・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61F017',v:'859',c:'児発６１・人欠・未計画２・開所減２・',baseItem: true,},
  {s:'61F018',v:'730',c:'児発６１・人欠・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61F019',v:'1443',c:'児発６１・人欠２・',baseItem: true,},
  {s:'61F020',v:'1227',c:'児発６１・人欠２・評価減・',baseItem: true,},
  {s:'61F021',v:'1010',c:'児発６１・人欠２・開所減１・',baseItem: true,},
  {s:'61F022',v:'859',c:'児発６１・人欠２・開所減１・評価減・',baseItem: true,},
  {s:'61F023',v:'1227',c:'児発６１・人欠２・開所減２・',baseItem: true,},
  {s:'61F024',v:'1043',c:'児発６１・人欠２・開所減２・評価減・',baseItem: true,},
  {s:'61F025',v:'1010',c:'児発６１・人欠２・未計画・',baseItem: true,},
  {s:'61F026',v:'859',c:'児発６１・人欠２・未計画・評価減・',baseItem: true,},
  {s:'61F027',v:'707',c:'児発６１・人欠２・未計画・開所減１・',baseItem: true,},
  {s:'61F028',v:'601',c:'児発６１・人欠２・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61F029',v:'859',c:'児発６１・人欠２・未計画・開所減２・',baseItem: true,},
  {s:'61F030',v:'730',c:'児発６１・人欠２・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61F031',v:'722',c:'児発６１・人欠２・未計画２・',baseItem: true,},
  {s:'61F032',v:'614',c:'児発６１・人欠２・未計画２・評価減・',baseItem: true,},
  {s:'61F033',v:'505',c:'児発６１・人欠２・未計画２・開所減１・',baseItem: true,},
  {s:'61F034',v:'429',c:'児発６１・人欠２・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61F035',v:'614',c:'児発６１・人欠２・未計画２・開所減２・',baseItem: true,},
  {s:'61F036',v:'522',c:'児発６１・人欠２・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61F037',v:'1829',c:'児発６２・人欠・',baseItem: true,},
  {s:'61F038',v:'1555',c:'児発６２・人欠・評価減・',baseItem: true,},
  {s:'61F039',v:'1280',c:'児発６２・人欠・開所減１・',baseItem: true,},
  {s:'61F040',v:'1088',c:'児発６２・人欠・開所減１・評価減・',baseItem: true,},
  {s:'61F041',v:'1555',c:'児発６２・人欠・開所減２・',baseItem: true,},
  {s:'61F042',v:'1322',c:'児発６２・人欠・開所減２・評価減・',baseItem: true,},
  {s:'61F043',v:'1280',c:'児発６２・人欠・未計画・',baseItem: true,},
  {s:'61F044',v:'1088',c:'児発６２・人欠・未計画・評価減・',baseItem: true,},
  {s:'61F045',v:'896',c:'児発６２・人欠・未計画・開所減１・',baseItem: true,},
  {s:'61F046',v:'762',c:'児発６２・人欠・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61F047',v:'1088',c:'児発６２・人欠・未計画・開所減２・',baseItem: true,},
  {s:'61F048',v:'925',c:'児発６２・人欠・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61F049',v:'915',c:'児発６２・人欠・未計画２・',baseItem: true,},
  {s:'61F050',v:'778',c:'児発６２・人欠・未計画２・評価減・',baseItem: true,},
  {s:'61F051',v:'641',c:'児発６２・人欠・未計画２・開所減１・',baseItem: true,},
  {s:'61F052',v:'545',c:'児発６２・人欠・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61F053',v:'778',c:'児発６２・人欠・未計画２・開所減２・',baseItem: true,},
  {s:'61F054',v:'661',c:'児発６２・人欠・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61F055',v:'1307',c:'児発６２・人欠２・',baseItem: true,},
  {s:'61F056',v:'1111',c:'児発６２・人欠２・評価減・',baseItem: true,},
  {s:'61F057',v:'915',c:'児発６２・人欠２・開所減１・',baseItem: true,},
  {s:'61F058',v:'778',c:'児発６２・人欠２・開所減１・評価減・',baseItem: true,},
  {s:'61F059',v:'1111',c:'児発６２・人欠２・開所減２・',baseItem: true,},
  {s:'61F060',v:'944',c:'児発６２・人欠２・開所減２・評価減・',baseItem: true,},
  {s:'61F061',v:'915',c:'児発６２・人欠２・未計画・',baseItem: true,},
  {s:'61F062',v:'778',c:'児発６２・人欠２・未計画・評価減・',baseItem: true,},
  {s:'61F063',v:'641',c:'児発６２・人欠２・未計画・開所減１・',baseItem: true,},
  {s:'61F064',v:'545',c:'児発６２・人欠２・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61F065',v:'778',c:'児発６２・人欠２・未計画・開所減２・',baseItem: true,},
  {s:'61F066',v:'661',c:'児発６２・人欠２・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61F067',v:'654',c:'児発６２・人欠２・未計画２・',baseItem: true,},
  {s:'61F068',v:'556',c:'児発６２・人欠２・未計画２・評価減・',baseItem: true,},
  {s:'61F069',v:'458',c:'児発６２・人欠２・未計画２・開所減１・',baseItem: true,},
  {s:'61F070',v:'389',c:'児発６２・人欠２・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61F071',v:'556',c:'児発６２・人欠２・未計画２・開所減２・',baseItem: true,},
  {s:'61F072',v:'473',c:'児発６２・人欠２・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61F073',v:'1740',c:'児発６３・人欠・',baseItem: true,},
  {s:'61F074',v:'1479',c:'児発６３・人欠・評価減・',baseItem: true,},
  {s:'61F075',v:'1218',c:'児発６３・人欠・開所減１・',baseItem: true,},
  {s:'61F076',v:'1035',c:'児発６３・人欠・開所減１・評価減・',baseItem: true,},
  {s:'61F077',v:'1479',c:'児発６３・人欠・開所減２・',baseItem: true,},
  {s:'61F078',v:'1257',c:'児発６３・人欠・開所減２・評価減・',baseItem: true,},
  {s:'61F079',v:'1218',c:'児発６３・人欠・未計画・',baseItem: true,},
  {s:'61F080',v:'1035',c:'児発６３・人欠・未計画・評価減・',baseItem: true,},
  {s:'61F081',v:'853',c:'児発６３・人欠・未計画・開所減１・',baseItem: true,},
  {s:'61F082',v:'725',c:'児発６３・人欠・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61F083',v:'1035',c:'児発６３・人欠・未計画・開所減２・',baseItem: true,},
  {s:'61F084',v:'880',c:'児発６３・人欠・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61F085',v:'870',c:'児発６３・人欠・未計画２・',baseItem: true,},
  {s:'61F086',v:'740',c:'児発６３・人欠・未計画２・評価減・',baseItem: true,},
  {s:'61F087',v:'609',c:'児発６３・人欠・未計画２・開所減１・',baseItem: true,},
  {s:'61F088',v:'518',c:'児発６３・人欠・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61F089',v:'740',c:'児発６３・人欠・未計画２・開所減２・',baseItem: true,},
  {s:'61F090',v:'629',c:'児発６３・人欠・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61F091',v:'1243',c:'児発６３・人欠２・',baseItem: true,},
  {s:'61F092',v:'1057',c:'児発６３・人欠２・評価減・',baseItem: true,},
  {s:'61F093',v:'870',c:'児発６３・人欠２・開所減１・',baseItem: true,},
  {s:'61F094',v:'740',c:'児発６３・人欠２・開所減１・評価減・',baseItem: true,},
  {s:'61F095',v:'1057',c:'児発６３・人欠２・開所減２・',baseItem: true,},
  {s:'61F096',v:'898',c:'児発６３・人欠２・開所減２・評価減・',baseItem: true,},
  {s:'61F097',v:'870',c:'児発６３・人欠２・未計画・',baseItem: true,},
  {s:'61F098',v:'740',c:'児発６３・人欠２・未計画・評価減・',baseItem: true,},
  {s:'61F099',v:'609',c:'児発６３・人欠２・未計画・開所減１・',baseItem: true,},
  {s:'61F100',v:'518',c:'児発６３・人欠２・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61F101',v:'740',c:'児発６３・人欠２・未計画・開所減２・',baseItem: true,},
  {s:'61F102',v:'629',c:'児発６３・人欠２・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61F103',v:'622',c:'児発６３・人欠２・未計画２・',baseItem: true,},
  {s:'61F104',v:'529',c:'児発６３・人欠２・未計画２・評価減・',baseItem: true,},
  {s:'61F105',v:'435',c:'児発６３・人欠２・未計画２・開所減１・',baseItem: true,},
  {s:'61F106',v:'370',c:'児発６３・人欠２・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61F107',v:'529',c:'児発６３・人欠２・未計画２・開所減２・',baseItem: true,},
  {s:'61F108',v:'450',c:'児発６３・人欠２・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61F109',v:'1320',c:'児発６４・人欠・',baseItem: true,},
  {s:'61F110',v:'1122',c:'児発６４・人欠・評価減・',baseItem: true,},
  {s:'61F111',v:'924',c:'児発６４・人欠・開所減１・',baseItem: true,},
  {s:'61F112',v:'785',c:'児発６４・人欠・開所減１・評価減・',baseItem: true,},
  {s:'61F113',v:'1122',c:'児発６４・人欠・開所減２・',baseItem: true,},
  {s:'61F114',v:'954',c:'児発６４・人欠・開所減２・評価減・',baseItem: true,},
  {s:'61F115',v:'924',c:'児発６４・人欠・未計画・',baseItem: true,},
  {s:'61F116',v:'785',c:'児発６４・人欠・未計画・評価減・',baseItem: true,},
  {s:'61F117',v:'647',c:'児発６４・人欠・未計画・開所減１・',baseItem: true,},
  {s:'61F118',v:'550',c:'児発６４・人欠・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61F119',v:'785',c:'児発６４・人欠・未計画・開所減２・',baseItem: true,},
  {s:'61F120',v:'667',c:'児発６４・人欠・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61F121',v:'660',c:'児発６４・人欠・未計画２・',baseItem: true,},
  {s:'61F122',v:'561',c:'児発６４・人欠・未計画２・評価減・',baseItem: true,},
  {s:'61F123',v:'462',c:'児発６４・人欠・未計画２・開所減１・',baseItem: true,},
  {s:'61F124',v:'393',c:'児発６４・人欠・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61F125',v:'561',c:'児発６４・人欠・未計画２・開所減２・',baseItem: true,},
  {s:'61F126',v:'477',c:'児発６４・人欠・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61F127',v:'943',c:'児発６４・人欠２・',baseItem: true,},
  {s:'61F128',v:'802',c:'児発６４・人欠２・評価減・',baseItem: true,},
  {s:'61F129',v:'660',c:'児発６４・人欠２・開所減１・',baseItem: true,},
  {s:'61F130',v:'561',c:'児発６４・人欠２・開所減１・評価減・',baseItem: true,},
  {s:'61F131',v:'802',c:'児発６４・人欠２・開所減２・',baseItem: true,},
  {s:'61F132',v:'682',c:'児発６４・人欠２・開所減２・評価減・',baseItem: true,},
  {s:'61F133',v:'660',c:'児発６４・人欠２・未計画・',baseItem: true,},
  {s:'61F134',v:'561',c:'児発６４・人欠２・未計画・評価減・',baseItem: true,},
  {s:'61F135',v:'462',c:'児発６４・人欠２・未計画・開所減１・',baseItem: true,},
  {s:'61F136',v:'393',c:'児発６４・人欠２・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61F137',v:'561',c:'児発６４・人欠２・未計画・開所減２・',baseItem: true,},
  {s:'61F138',v:'477',c:'児発６４・人欠２・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61F139',v:'472',c:'児発６４・人欠２・未計画２・',baseItem: true,},
  {s:'61F140',v:'401',c:'児発６４・人欠２・未計画２・評価減・',baseItem: true,},
  {s:'61F141',v:'330',c:'児発６４・人欠２・未計画２・開所減１・',baseItem: true,},
  {s:'61F142',v:'281',c:'児発６４・人欠２・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61F143',v:'401',c:'児発６４・人欠２・未計画２・開所減２・',baseItem: true,},
  {s:'61F144',v:'341',c:'児発６４・人欠２・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61F145',v:'1129',c:'児発６５・人欠・',baseItem: true,},
  {s:'61F146',v:'960',c:'児発６５・人欠・評価減・',baseItem: true,},
  {s:'61F147',v:'790',c:'児発６５・人欠・開所減１・',baseItem: true,},
  {s:'61F148',v:'672',c:'児発６５・人欠・開所減１・評価減・',baseItem: true,},
  {s:'61F149',v:'960',c:'児発６５・人欠・開所減２・',baseItem: true,},
  {s:'61F150',v:'816',c:'児発６５・人欠・開所減２・評価減・',baseItem: true,},
  {s:'61F151',v:'790',c:'児発６５・人欠・未計画・',baseItem: true,},
  {s:'61F152',v:'672',c:'児発６５・人欠・未計画・評価減・',baseItem: true,},
  {s:'61F153',v:'553',c:'児発６５・人欠・未計画・開所減１・',baseItem: true,},
  {s:'61F154',v:'470',c:'児発６５・人欠・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61F155',v:'672',c:'児発６５・人欠・未計画・開所減２・',baseItem: true,},
  {s:'61F156',v:'571',c:'児発６５・人欠・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61F157',v:'565',c:'児発６５・人欠・未計画２・',baseItem: true,},
  {s:'61F158',v:'480',c:'児発６５・人欠・未計画２・評価減・',baseItem: true,},
  {s:'61F159',v:'396',c:'児発６５・人欠・未計画２・開所減１・',baseItem: true,},
  {s:'61F160',v:'337',c:'児発６５・人欠・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61F161',v:'480',c:'児発６５・人欠・未計画２・開所減２・',baseItem: true,},
  {s:'61F162',v:'408',c:'児発６５・人欠・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61F163',v:'807',c:'児発６５・人欠２・',baseItem: true,},
  {s:'61F164',v:'686',c:'児発６５・人欠２・評価減・',baseItem: true,},
  {s:'61F165',v:'565',c:'児発６５・人欠２・開所減１・',baseItem: true,},
  {s:'61F166',v:'480',c:'児発６５・人欠２・開所減１・評価減・',baseItem: true,},
  {s:'61F167',v:'686',c:'児発６５・人欠２・開所減２・',baseItem: true,},
  {s:'61F168',v:'583',c:'児発６５・人欠２・開所減２・評価減・',baseItem: true,},
  {s:'61F169',v:'565',c:'児発６５・人欠２・未計画・',baseItem: true,},
  {s:'61F170',v:'480',c:'児発６５・人欠２・未計画・評価減・',baseItem: true,},
  {s:'61F171',v:'396',c:'児発６５・人欠２・未計画・開所減１・',baseItem: true,},
  {s:'61F172',v:'337',c:'児発６５・人欠２・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61F173',v:'480',c:'児発６５・人欠２・未計画・開所減２・',baseItem: true,},
  {s:'61F174',v:'408',c:'児発６５・人欠２・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61F175',v:'404',c:'児発６５・人欠２・未計画２・',baseItem: true,},
  {s:'61F176',v:'343',c:'児発６５・人欠２・未計画２・評価減・',baseItem: true,},
  {s:'61F177',v:'283',c:'児発６５・人欠２・未計画２・開所減１・',baseItem: true,},
  {s:'61F178',v:'241',c:'児発６５・人欠２・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61F179',v:'343',c:'児発６５・人欠２・未計画２・開所減２・',baseItem: true,},
  {s:'61F180',v:'292',c:'児発６５・人欠２・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61F181',v:'1040',c:'児発６６・人欠・',baseItem: true,},
  {s:'61F182',v:'884',c:'児発６６・人欠・評価減・',baseItem: true,},
  {s:'61F183',v:'728',c:'児発６６・人欠・開所減１・',baseItem: true,},
  {s:'61F184',v:'619',c:'児発６６・人欠・開所減１・評価減・',baseItem: true,},
  {s:'61F185',v:'884',c:'児発６６・人欠・開所減２・',baseItem: true,},
  {s:'61F186',v:'751',c:'児発６６・人欠・開所減２・評価減・',baseItem: true,},
  {s:'61F187',v:'728',c:'児発６６・人欠・未計画・',baseItem: true,},
  {s:'61F188',v:'619',c:'児発６６・人欠・未計画・評価減・',baseItem: true,},
  {s:'61F189',v:'510',c:'児発６６・人欠・未計画・開所減１・',baseItem: true,},
  {s:'61F190',v:'434',c:'児発６６・人欠・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61F191',v:'619',c:'児発６６・人欠・未計画・開所減２・',baseItem: true,},
  {s:'61F192',v:'526',c:'児発６６・人欠・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61F193',v:'520',c:'児発６６・人欠・未計画２・',baseItem: true,},
  {s:'61F194',v:'442',c:'児発６６・人欠・未計画２・評価減・',baseItem: true,},
  {s:'61F195',v:'364',c:'児発６６・人欠・未計画２・開所減１・',baseItem: true,},
  {s:'61F196',v:'309',c:'児発６６・人欠・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61F197',v:'442',c:'児発６６・人欠・未計画２・開所減２・',baseItem: true,},
  {s:'61F198',v:'376',c:'児発６６・人欠・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61F199',v:'743',c:'児発６６・人欠２・',baseItem: true,},
  {s:'61F200',v:'632',c:'児発６６・人欠２・評価減・',baseItem: true,},
  {s:'61F201',v:'520',c:'児発６６・人欠２・開所減１・',baseItem: true,},
  {s:'61F202',v:'442',c:'児発６６・人欠２・開所減１・評価減・',baseItem: true,},
  {s:'61F203',v:'632',c:'児発６６・人欠２・開所減２・',baseItem: true,},
  {s:'61F204',v:'537',c:'児発６６・人欠２・開所減２・評価減・',baseItem: true,},
  {s:'61F205',v:'520',c:'児発６６・人欠２・未計画・',baseItem: true,},
  {s:'61F206',v:'442',c:'児発６６・人欠２・未計画・評価減・',baseItem: true,},
  {s:'61F207',v:'364',c:'児発６６・人欠２・未計画・開所減１・',baseItem: true,},
  {s:'61F208',v:'309',c:'児発６６・人欠２・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61F209',v:'442',c:'児発６６・人欠２・未計画・開所減２・',baseItem: true,},
  {s:'61F210',v:'376',c:'児発６６・人欠２・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61F211',v:'372',c:'児発６６・人欠２・未計画２・',baseItem: true,},
  {s:'61F212',v:'316',c:'児発６６・人欠２・未計画２・評価減・',baseItem: true,},
  {s:'61F213',v:'260',c:'児発６６・人欠２・未計画２・開所減１・',baseItem: true,},
  {s:'61F214',v:'221',c:'児発６６・人欠２・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61F215',v:'316',c:'児発６６・人欠２・未計画２・開所減２・',baseItem: true,},
  {s:'61F216',v:'269',c:'児発６６・人欠２・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61F217',v:'1086',c:'児発６７・人欠・',baseItem: true,},
  {s:'61F218',v:'923',c:'児発６７・人欠・評価減・',baseItem: true,},
  {s:'61F219',v:'760',c:'児発６７・人欠・開所減１・',baseItem: true,},
  {s:'61F220',v:'646',c:'児発６７・人欠・開所減１・評価減・',baseItem: true,},
  {s:'61F221',v:'923',c:'児発６７・人欠・開所減２・',baseItem: true,},
  {s:'61F222',v:'785',c:'児発６７・人欠・開所減２・評価減・',baseItem: true,},
  {s:'61F223',v:'760',c:'児発６７・人欠・未計画・',baseItem: true,},
  {s:'61F224',v:'646',c:'児発６７・人欠・未計画・評価減・',baseItem: true,},
  {s:'61F225',v:'532',c:'児発６７・人欠・未計画・開所減１・',baseItem: true,},
  {s:'61F226',v:'452',c:'児発６７・人欠・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61F227',v:'646',c:'児発６７・人欠・未計画・開所減２・',baseItem: true,},
  {s:'61F228',v:'549',c:'児発６７・人欠・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61F229',v:'543',c:'児発６７・人欠・未計画２・',baseItem: true,},
  {s:'61F230',v:'462',c:'児発６７・人欠・未計画２・評価減・',baseItem: true,},
  {s:'61F231',v:'380',c:'児発６７・人欠・未計画２・開所減１・',baseItem: true,},
  {s:'61F232',v:'323',c:'児発６７・人欠・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61F233',v:'462',c:'児発６７・人欠・未計画２・開所減２・',baseItem: true,},
  {s:'61F234',v:'393',c:'児発６７・人欠・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61F235',v:'776',c:'児発６７・人欠２・',baseItem: true,},
  {s:'61F236',v:'660',c:'児発６７・人欠２・評価減・',baseItem: true,},
  {s:'61F237',v:'543',c:'児発６７・人欠２・開所減１・',baseItem: true,},
  {s:'61F238',v:'462',c:'児発６７・人欠２・開所減１・評価減・',baseItem: true,},
  {s:'61F239',v:'660',c:'児発６７・人欠２・開所減２・',baseItem: true,},
  {s:'61F240',v:'561',c:'児発６７・人欠２・開所減２・評価減・',baseItem: true,},
  {s:'61F241',v:'543',c:'児発６７・人欠２・未計画・',baseItem: true,},
  {s:'61F242',v:'462',c:'児発６７・人欠２・未計画・評価減・',baseItem: true,},
  {s:'61F243',v:'380',c:'児発６７・人欠２・未計画・開所減１・',baseItem: true,},
  {s:'61F244',v:'323',c:'児発６７・人欠２・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61F245',v:'462',c:'児発６７・人欠２・未計画・開所減２・',baseItem: true,},
  {s:'61F246',v:'393',c:'児発６７・人欠２・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61F247',v:'388',c:'児発６７・人欠２・未計画２・',baseItem: true,},
  {s:'61F248',v:'330',c:'児発６７・人欠２・未計画２・評価減・',baseItem: true,},
  {s:'61F249',v:'272',c:'児発６７・人欠２・未計画２・開所減１・',baseItem: true,},
  {s:'61F250',v:'231',c:'児発６７・人欠２・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61F251',v:'330',c:'児発６７・人欠２・未計画２・開所減２・',baseItem: true,},
  {s:'61F252',v:'281',c:'児発６７・人欠２・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61F253',v:'896',c:'児発６８・人欠・',baseItem: true,},
  {s:'61F254',v:'762',c:'児発６８・人欠・評価減・',baseItem: true,},
  {s:'61F255',v:'627',c:'児発６８・人欠・開所減１・',baseItem: true,},
  {s:'61F256',v:'533',c:'児発６８・人欠・開所減１・評価減・',baseItem: true,},
  {s:'61F257',v:'762',c:'児発６８・人欠・開所減２・',baseItem: true,},
  {s:'61F258',v:'648',c:'児発６８・人欠・開所減２・評価減・',baseItem: true,},
  {s:'61F259',v:'627',c:'児発６８・人欠・未計画・',baseItem: true,},
  {s:'61F260',v:'533',c:'児発６８・人欠・未計画・評価減・',baseItem: true,},
  {s:'61F261',v:'439',c:'児発６８・人欠・未計画・開所減１・',baseItem: true,},
  {s:'61F262',v:'373',c:'児発６８・人欠・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61F263',v:'533',c:'児発６８・人欠・未計画・開所減２・',baseItem: true,},
  {s:'61F264',v:'453',c:'児発６８・人欠・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61F265',v:'448',c:'児発６８・人欠・未計画２・',baseItem: true,},
  {s:'61F266',v:'381',c:'児発６８・人欠・未計画２・評価減・',baseItem: true,},
  {s:'61F267',v:'314',c:'児発６８・人欠・未計画２・開所減１・',baseItem: true,},
  {s:'61F268',v:'267',c:'児発６８・人欠・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61F269',v:'381',c:'児発６８・人欠・未計画２・開所減２・',baseItem: true,},
  {s:'61F270',v:'324',c:'児発６８・人欠・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61F271',v:'640',c:'児発６８・人欠２・',baseItem: true,},
  {s:'61F272',v:'544',c:'児発６８・人欠２・評価減・',baseItem: true,},
  {s:'61F273',v:'448',c:'児発６８・人欠２・開所減１・',baseItem: true,},
  {s:'61F274',v:'381',c:'児発６８・人欠２・開所減１・評価減・',baseItem: true,},
  {s:'61F275',v:'544',c:'児発６８・人欠２・開所減２・',baseItem: true,},
  {s:'61F276',v:'462',c:'児発６８・人欠２・開所減２・評価減・',baseItem: true,},
  {s:'61F277',v:'448',c:'児発６８・人欠２・未計画・',baseItem: true,},
  {s:'61F278',v:'381',c:'児発６８・人欠２・未計画・評価減・',baseItem: true,},
  {s:'61F279',v:'314',c:'児発６８・人欠２・未計画・開所減１・',baseItem: true,},
  {s:'61F280',v:'267',c:'児発６８・人欠２・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61F281',v:'381',c:'児発６８・人欠２・未計画・開所減２・',baseItem: true,},
  {s:'61F282',v:'324',c:'児発６８・人欠２・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61F283',v:'320',c:'児発６８・人欠２・未計画２・',baseItem: true,},
  {s:'61F284',v:'272',c:'児発６８・人欠２・未計画２・評価減・',baseItem: true,},
  {s:'61F285',v:'224',c:'児発６８・人欠２・未計画２・開所減１・',baseItem: true,},
  {s:'61F286',v:'190',c:'児発６８・人欠２・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61F287',v:'272',c:'児発６８・人欠２・未計画２・開所減２・',baseItem: true,},
  {s:'61F288',v:'231',c:'児発６８・人欠２・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61F289',v:'807',c:'児発６９・人欠・',baseItem: true,},
  {s:'61F290',v:'686',c:'児発６９・人欠・評価減・',baseItem: true,},
  {s:'61F291',v:'565',c:'児発６９・人欠・開所減１・',baseItem: true,},
  {s:'61F292',v:'480',c:'児発６９・人欠・開所減１・評価減・',baseItem: true,},
  {s:'61F293',v:'686',c:'児発６９・人欠・開所減２・',baseItem: true,},
  {s:'61F294',v:'583',c:'児発６９・人欠・開所減２・評価減・',baseItem: true,},
  {s:'61F295',v:'565',c:'児発６９・人欠・未計画・',baseItem: true,},
  {s:'61F296',v:'480',c:'児発６９・人欠・未計画・評価減・',baseItem: true,},
  {s:'61F297',v:'396',c:'児発６９・人欠・未計画・開所減１・',baseItem: true,},
  {s:'61F298',v:'337',c:'児発６９・人欠・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61F299',v:'480',c:'児発６９・人欠・未計画・開所減２・',baseItem: true,},
  {s:'61F300',v:'408',c:'児発６９・人欠・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61F301',v:'404',c:'児発６９・人欠・未計画２・',baseItem: true,},
  {s:'61F302',v:'343',c:'児発６９・人欠・未計画２・評価減・',baseItem: true,},
  {s:'61F303',v:'283',c:'児発６９・人欠・未計画２・開所減１・',baseItem: true,},
  {s:'61F304',v:'241',c:'児発６９・人欠・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61F305',v:'343',c:'児発６９・人欠・未計画２・開所減２・',baseItem: true,},
  {s:'61F306',v:'292',c:'児発６９・人欠・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61F307',v:'577',c:'児発６９・人欠２・',baseItem: true,},
  {s:'61F308',v:'490',c:'児発６９・人欠２・評価減・',baseItem: true,},
  {s:'61F309',v:'404',c:'児発６９・人欠２・開所減１・',baseItem: true,},
  {s:'61F310',v:'343',c:'児発６９・人欠２・開所減１・評価減・',baseItem: true,},
  {s:'61F311',v:'490',c:'児発６９・人欠２・開所減２・',baseItem: true,},
  {s:'61F312',v:'417',c:'児発６９・人欠２・開所減２・評価減・',baseItem: true,},
  {s:'61F313',v:'404',c:'児発６９・人欠２・未計画・',baseItem: true,},
  {s:'61F314',v:'343',c:'児発６９・人欠２・未計画・評価減・',baseItem: true,},
  {s:'61F315',v:'283',c:'児発６９・人欠２・未計画・開所減１・',baseItem: true,},
  {s:'61F316',v:'241',c:'児発６９・人欠２・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61F317',v:'343',c:'児発６９・人欠２・未計画・開所減２・',baseItem: true,},
  {s:'61F318',v:'292',c:'児発６９・人欠２・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61F319',v:'289',c:'児発６９・人欠２・未計画２・',baseItem: true,},
  {s:'61F320',v:'246',c:'児発６９・人欠２・未計画２・評価減・',baseItem: true,},
  {s:'61F321',v:'202',c:'児発６９・人欠２・未計画２・開所減１・',baseItem: true,},
  {s:'61F322',v:'172',c:'児発６９・人欠２・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61F323',v:'246',c:'児発６９・人欠２・未計画２・開所減２・',baseItem: true,},
  {s:'61F324',v:'209',c:'児発６９・人欠２・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'619111',v:'620',c:'児発１・人欠・',baseItem: true,},
  {s:'619002',v:'527',c:'児発１・人欠・評価減・',baseItem: true,},
  {s:'619112',v:'434',c:'児発１・人欠・開所減１・',baseItem: true,},
  {s:'619005',v:'369',c:'児発１・人欠・開所減１・評価減・',baseItem: true,},
  {s:'619701',v:'527',c:'児発１・人欠・開所減２・',baseItem: true,},
  {s:'619008',v:'448',c:'児発１・人欠・開所減２・評価減・',baseItem: true,},
  {s:'619113',v:'434',c:'児発１・人欠・未計画・',baseItem: true,},
  {s:'619011',v:'369',c:'児発１・人欠・未計画・評価減・',baseItem: true,},
  {s:'619114',v:'304',c:'児発１・人欠・未計画・開所減１・',baseItem: true,},
  {s:'619014',v:'258',c:'児発１・人欠・未計画・開所減１・評価減・',baseItem: true,},
  {s:'619702',v:'369',c:'児発１・人欠・未計画・開所減２・',baseItem: true,},
  {s:'619017',v:'314',c:'児発１・人欠・未計画・開所減２・評価減・',baseItem: true,},
  {s:'619019',v:'310',c:'児発１・人欠・未計画２・',baseItem: true,},
  {s:'619021',v:'264',c:'児発１・人欠・未計画２・評価減・',baseItem: true,},
  {s:'619023',v:'217',c:'児発１・人欠・未計画２・開所減１・',baseItem: true,},
  {s:'619025',v:'184',c:'児発１・人欠・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'619027',v:'264',c:'児発１・人欠・未計画２・開所減２・',baseItem: true,},
  {s:'619029',v:'224',c:'児発１・人欠・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'619031',v:'443',c:'児発１・人欠２・',baseItem: true,},
  {s:'619033',v:'377',c:'児発１・人欠２・評価減・',baseItem: true,},
  {s:'619035',v:'310',c:'児発１・人欠２・開所減１・',baseItem: true,},
  {s:'619037',v:'264',c:'児発１・人欠２・開所減１・評価減・',baseItem: true,},
  {s:'619039',v:'377',c:'児発１・人欠２・開所減２・',baseItem: true,},
  {s:'619041',v:'320',c:'児発１・人欠２・開所減２・評価減・',baseItem: true,},
  {s:'619043',v:'310',c:'児発１・人欠２・未計画・',baseItem: true,},
  {s:'619045',v:'264',c:'児発１・人欠２・未計画・評価減・',baseItem: true,},
  {s:'619047',v:'217',c:'児発１・人欠２・未計画・開所減１・',baseItem: true,},
  {s:'619049',v:'184',c:'児発１・人欠２・未計画・開所減１・評価減・',baseItem: true,},
  {s:'619051',v:'264',c:'児発１・人欠２・未計画・開所減２・',baseItem: true,},
  {s:'619053',v:'224',c:'児発１・人欠２・未計画・開所減２・評価減・',baseItem: true,},
  {s:'619055',v:'222',c:'児発１・人欠２・未計画２・',baseItem: true,},
  {s:'619057',v:'189',c:'児発１・人欠２・未計画２・評価減・',baseItem: true,},
  {s:'619059',v:'155',c:'児発１・人欠２・未計画２・開所減１・',baseItem: true,},
  {s:'619061',v:'132',c:'児発１・人欠２・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'619063',v:'189',c:'児発１・人欠２・未計画２・開所減２・',baseItem: true,},
  {s:'619065',v:'161',c:'児発１・人欠２・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'619121',v:'429',c:'児発２・人欠・',baseItem: true,},
  {s:'619172',v:'365',c:'児発２・人欠・評価減・',baseItem: true,},
  {s:'619122',v:'300',c:'児発２・人欠・開所減１・',baseItem: true,},
  {s:'619175',v:'255',c:'児発２・人欠・開所減１・評価減・',baseItem: true,},
  {s:'619709',v:'365',c:'児発２・人欠・開所減２・',baseItem: true,},
  {s:'619178',v:'310',c:'児発２・人欠・開所減２・評価減・',baseItem: true,},
  {s:'619123',v:'300',c:'児発２・人欠・未計画・',baseItem: true,},
  {s:'619181',v:'255',c:'児発２・人欠・未計画・評価減・',baseItem: true,},
  {s:'619124',v:'210',c:'児発２・人欠・未計画・開所減１・',baseItem: true,},
  {s:'619184',v:'179',c:'児発２・人欠・未計画・開所減１・評価減・',baseItem: true,},
  {s:'619710',v:'255',c:'児発２・人欠・未計画・開所減２・',baseItem: true,},
  {s:'619187',v:'217',c:'児発２・人欠・未計画・開所減２・評価減・',baseItem: true,},
  {s:'619189',v:'215',c:'児発２・人欠・未計画２・',baseItem: true,},
  {s:'619191',v:'183',c:'児発２・人欠・未計画２・評価減・',baseItem: true,},
  {s:'619193',v:'151',c:'児発２・人欠・未計画２・開所減１・',baseItem: true,},
  {s:'619195',v:'128',c:'児発２・人欠・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'619197',v:'183',c:'児発２・人欠・未計画２・開所減２・',baseItem: true,},
  {s:'619199',v:'156',c:'児発２・人欠・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'619201',v:'307',c:'児発２・人欠２・',baseItem: true,},
  {s:'619203',v:'261',c:'児発２・人欠２・評価減・',baseItem: true,},
  {s:'619205',v:'215',c:'児発２・人欠２・開所減１・',baseItem: true,},
  {s:'619207',v:'183',c:'児発２・人欠２・開所減１・評価減・',baseItem: true,},
  {s:'619209',v:'261',c:'児発２・人欠２・開所減２・',baseItem: true,},
  {s:'619211',v:'222',c:'児発２・人欠２・開所減２・評価減・',baseItem: true,},
  {s:'619213',v:'215',c:'児発２・人欠２・未計画・',baseItem: true,},
  {s:'619215',v:'183',c:'児発２・人欠２・未計画・評価減・',baseItem: true,},
  {s:'619217',v:'151',c:'児発２・人欠２・未計画・開所減１・',baseItem: true,},
  {s:'619219',v:'128',c:'児発２・人欠２・未計画・開所減１・評価減・',baseItem: true,},
  {s:'619221',v:'183',c:'児発２・人欠２・未計画・開所減２・',baseItem: true,},
  {s:'619223',v:'156',c:'児発２・人欠２・未計画・開所減２・評価減・',baseItem: true,},
  {s:'619225',v:'154',c:'児発２・人欠２・未計画２・',baseItem: true,},
  {s:'619227',v:'131',c:'児発２・人欠２・未計画２・評価減・',baseItem: true,},
  {s:'619229',v:'108',c:'児発２・人欠２・未計画２・開所減１・',baseItem: true,},
  {s:'619231',v:'92',c:'児発２・人欠２・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'619233',v:'131',c:'児発２・人欠２・未計画２・開所減２・',baseItem: true,},
  {s:'619235',v:'111',c:'児発２・人欠２・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'619131',v:'340',c:'児発３・人欠・',baseItem: true,},
  {s:'619312',v:'289',c:'児発３・人欠・評価減・',baseItem: true,},
  {s:'619132',v:'238',c:'児発３・人欠・開所減１・',baseItem: true,},
  {s:'619315',v:'202',c:'児発３・人欠・開所減１・評価減・',baseItem: true,},
  {s:'619717',v:'289',c:'児発３・人欠・開所減２・',baseItem: true,},
  {s:'619318',v:'246',c:'児発３・人欠・開所減２・評価減・',baseItem: true,},
  {s:'619133',v:'238',c:'児発３・人欠・未計画・',baseItem: true,},
  {s:'619321',v:'202',c:'児発３・人欠・未計画・評価減・',baseItem: true,},
  {s:'619134',v:'167',c:'児発３・人欠・未計画・開所減１・',baseItem: true,},
  {s:'619324',v:'142',c:'児発３・人欠・未計画・開所減１・評価減・',baseItem: true,},
  {s:'619718',v:'202',c:'児発３・人欠・未計画・開所減２・',baseItem: true,},
  {s:'619327',v:'172',c:'児発３・人欠・未計画・開所減２・評価減・',baseItem: true,},
  {s:'619329',v:'170',c:'児発３・人欠・未計画２・',baseItem: true,},
  {s:'619331',v:'145',c:'児発３・人欠・未計画２・評価減・',baseItem: true,},
  {s:'619333',v:'119',c:'児発３・人欠・未計画２・開所減１・',baseItem: true,},
  {s:'619335',v:'101',c:'児発３・人欠・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'619337',v:'145',c:'児発３・人欠・未計画２・開所減２・',baseItem: true,},
  {s:'619339',v:'123',c:'児発３・人欠・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'619341',v:'243',c:'児発３・人欠２・',baseItem: true,},
  {s:'619343',v:'207',c:'児発３・人欠２・評価減・',baseItem: true,},
  {s:'619345',v:'170',c:'児発３・人欠２・開所減１・',baseItem: true,},
  {s:'619347',v:'145',c:'児発３・人欠２・開所減１・評価減・',baseItem: true,},
  {s:'619349',v:'207',c:'児発３・人欠２・開所減２・',baseItem: true,},
  {s:'619351',v:'176',c:'児発３・人欠２・開所減２・評価減・',baseItem: true,},
  {s:'619353',v:'170',c:'児発３・人欠２・未計画・',baseItem: true,},
  {s:'619355',v:'145',c:'児発３・人欠２・未計画・評価減・',baseItem: true,},
  {s:'619357',v:'119',c:'児発３・人欠２・未計画・開所減１・',baseItem: true,},
  {s:'619359',v:'101',c:'児発３・人欠２・未計画・開所減１・評価減・',baseItem: true,},
  {s:'619361',v:'145',c:'児発３・人欠２・未計画・開所減２・',baseItem: true,},
  {s:'619363',v:'123',c:'児発３・人欠２・未計画・開所減２・評価減・',baseItem: true,},
  {s:'619365',v:'122',c:'児発３・人欠２・未計画２・',baseItem: true,},
  {s:'619367',v:'104',c:'児発３・人欠２・未計画２・評価減・',baseItem: true,},
  {s:'619369',v:'85',c:'児発３・人欠２・未計画２・開所減１・',baseItem: true,},
  {s:'619371',v:'72',c:'児発３・人欠２・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'619373',v:'104',c:'児発３・人欠２・未計画２・開所減２・',baseItem: true,},
  {s:'619375',v:'88',c:'児発３・人欠２・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61F325',v:'1928',c:'児発７０・人欠・',baseItem: true,},
  {s:'61F326',v:'1639',c:'児発７０・人欠・評価減・',baseItem: true,},
  {s:'61F327',v:'1350',c:'児発７０・人欠・開所減１・',baseItem: true,},
  {s:'61F328',v:'1148',c:'児発７０・人欠・開所減１・評価減・',baseItem: true,},
  {s:'61F329',v:'1639',c:'児発７０・人欠・開所減２・',baseItem: true,},
  {s:'61F330',v:'1393',c:'児発７０・人欠・開所減２・評価減・',baseItem: true,},
  {s:'61F331',v:'1350',c:'児発７０・人欠・未計画・',baseItem: true,},
  {s:'61F332',v:'1148',c:'児発７０・人欠・未計画・評価減・',baseItem: true,},
  {s:'61F333',v:'945',c:'児発７０・人欠・未計画・開所減１・',baseItem: true,},
  {s:'61F334',v:'803',c:'児発７０・人欠・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61F335',v:'1148',c:'児発７０・人欠・未計画・開所減２・',baseItem: true,},
  {s:'61F336',v:'976',c:'児発７０・人欠・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61F337',v:'964',c:'児発７０・人欠・未計画２・',baseItem: true,},
  {s:'61F338',v:'819',c:'児発７０・人欠・未計画２・評価減・',baseItem: true,},
  {s:'61F339',v:'675',c:'児発７０・人欠・未計画２・開所減１・',baseItem: true,},
  {s:'61F340',v:'574',c:'児発７０・人欠・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61F341',v:'819',c:'児発７０・人欠・未計画２・開所減２・',baseItem: true,},
  {s:'61F342',v:'696',c:'児発７０・人欠・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61F343',v:'1377',c:'児発７０・人欠２・',baseItem: true,},
  {s:'61F344',v:'1170',c:'児発７０・人欠２・評価減・',baseItem: true,},
  {s:'61F345',v:'964',c:'児発７０・人欠２・開所減１・',baseItem: true,},
  {s:'61F346',v:'819',c:'児発７０・人欠２・開所減１・評価減・',baseItem: true,},
  {s:'61F347',v:'1170',c:'児発７０・人欠２・開所減２・',baseItem: true,},
  {s:'61F348',v:'995',c:'児発７０・人欠２・開所減２・評価減・',baseItem: true,},
  {s:'61F349',v:'964',c:'児発７０・人欠２・未計画・',baseItem: true,},
  {s:'61F350',v:'819',c:'児発７０・人欠２・未計画・評価減・',baseItem: true,},
  {s:'61F351',v:'675',c:'児発７０・人欠２・未計画・開所減１・',baseItem: true,},
  {s:'61F352',v:'574',c:'児発７０・人欠２・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61F353',v:'819',c:'児発７０・人欠２・未計画・開所減２・',baseItem: true,},
  {s:'61F354',v:'696',c:'児発７０・人欠２・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61F355',v:'689',c:'児発７０・人欠２・未計画２・',baseItem: true,},
  {s:'61F356',v:'586',c:'児発７０・人欠２・未計画２・評価減・',baseItem: true,},
  {s:'61F357',v:'482',c:'児発７０・人欠２・未計画２・開所減１・',baseItem: true,},
  {s:'61F358',v:'410',c:'児発７０・人欠２・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61F359',v:'586',c:'児発７０・人欠２・未計画２・開所減２・',baseItem: true,},
  {s:'61F360',v:'498',c:'児発７０・人欠２・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61F361',v:'1759',c:'児発７１・人欠・',baseItem: true,},
  {s:'61F362',v:'1495',c:'児発７１・人欠・評価減・',baseItem: true,},
  {s:'61F363',v:'1231',c:'児発７１・人欠・開所減１・',baseItem: true,},
  {s:'61F364',v:'1046',c:'児発７１・人欠・開所減１・評価減・',baseItem: true,},
  {s:'61F365',v:'1495',c:'児発７１・人欠・開所減２・',baseItem: true,},
  {s:'61F366',v:'1271',c:'児発７１・人欠・開所減２・評価減・',baseItem: true,},
  {s:'61F367',v:'1231',c:'児発７１・人欠・未計画・',baseItem: true,},
  {s:'61F368',v:'1046',c:'児発７１・人欠・未計画・評価減・',baseItem: true,},
  {s:'61F369',v:'862',c:'児発７１・人欠・未計画・開所減１・',baseItem: true,},
  {s:'61F370',v:'733',c:'児発７１・人欠・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61F371',v:'1046',c:'児発７１・人欠・未計画・開所減２・',baseItem: true,},
  {s:'61F372',v:'889',c:'児発７１・人欠・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61F373',v:'880',c:'児発７１・人欠・未計画２・',baseItem: true,},
  {s:'61F374',v:'748',c:'児発７１・人欠・未計画２・評価減・',baseItem: true,},
  {s:'61F375',v:'616',c:'児発７１・人欠・未計画２・開所減１・',baseItem: true,},
  {s:'61F376',v:'524',c:'児発７１・人欠・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61F377',v:'748',c:'児発７１・人欠・未計画２・開所減２・',baseItem: true,},
  {s:'61F378',v:'636',c:'児発７１・人欠・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61F379',v:'1257',c:'児発７１・人欠２・',baseItem: true,},
  {s:'61F380',v:'1068',c:'児発７１・人欠２・評価減・',baseItem: true,},
  {s:'61F381',v:'880',c:'児発７１・人欠２・開所減１・',baseItem: true,},
  {s:'61F382',v:'748',c:'児発７１・人欠２・開所減１・評価減・',baseItem: true,},
  {s:'61F383',v:'1068',c:'児発７１・人欠２・開所減２・',baseItem: true,},
  {s:'61F384',v:'908',c:'児発７１・人欠２・開所減２・評価減・',baseItem: true,},
  {s:'61F385',v:'880',c:'児発７１・人欠２・未計画・',baseItem: true,},
  {s:'61F386',v:'748',c:'児発７１・人欠２・未計画・評価減・',baseItem: true,},
  {s:'61F387',v:'616',c:'児発７１・人欠２・未計画・開所減１・',baseItem: true,},
  {s:'61F388',v:'524',c:'児発７１・人欠２・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61F389',v:'748',c:'児発７１・人欠２・未計画・開所減２・',baseItem: true,},
  {s:'61F390',v:'636',c:'児発７１・人欠２・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61F391',v:'629',c:'児発７１・人欠２・未計画２・',baseItem: true,},
  {s:'61F392',v:'535',c:'児発７１・人欠２・未計画２・評価減・',baseItem: true,},
  {s:'61F393',v:'440',c:'児発７１・人欠２・未計画２・開所減１・',baseItem: true,},
  {s:'61F394',v:'374',c:'児発７１・人欠２・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61F395',v:'535',c:'児発７１・人欠２・未計画２・開所減２・',baseItem: true,},
  {s:'61F396',v:'455',c:'児発７１・人欠２・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61F397',v:'1683',c:'児発７２・人欠・',baseItem: true,},
  {s:'61F398',v:'1431',c:'児発７２・人欠・評価減・',baseItem: true,},
  {s:'61F399',v:'1178',c:'児発７２・人欠・開所減１・',baseItem: true,},
  {s:'61F400',v:'1001',c:'児発７２・人欠・開所減１・評価減・',baseItem: true,},
  {s:'61F401',v:'1431',c:'児発７２・人欠・開所減２・',baseItem: true,},
  {s:'61F402',v:'1216',c:'児発７２・人欠・開所減２・評価減・',baseItem: true,},
  {s:'61F403',v:'1178',c:'児発７２・人欠・未計画・',baseItem: true,},
  {s:'61F404',v:'1001',c:'児発７２・人欠・未計画・評価減・',baseItem: true,},
  {s:'61F405',v:'825',c:'児発７２・人欠・未計画・開所減１・',baseItem: true,},
  {s:'61F406',v:'701',c:'児発７２・人欠・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61F407',v:'1001',c:'児発７２・人欠・未計画・開所減２・',baseItem: true,},
  {s:'61F408',v:'851',c:'児発７２・人欠・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61F409',v:'842',c:'児発７２・人欠・未計画２・',baseItem: true,},
  {s:'61F410',v:'716',c:'児発７２・人欠・未計画２・評価減・',baseItem: true,},
  {s:'61F411',v:'589',c:'児発７２・人欠・未計画２・開所減１・',baseItem: true,},
  {s:'61F412',v:'501',c:'児発７２・人欠・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61F413',v:'716',c:'児発７２・人欠・未計画２・開所減２・',baseItem: true,},
  {s:'61F414',v:'609',c:'児発７２・人欠・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61F415',v:'1202',c:'児発７２・人欠２・',baseItem: true,},
  {s:'61F416',v:'1022',c:'児発７２・人欠２・評価減・',baseItem: true,},
  {s:'61F417',v:'841',c:'児発７２・人欠２・開所減１・',baseItem: true,},
  {s:'61F418',v:'715',c:'児発７２・人欠２・開所減１・評価減・',baseItem: true,},
  {s:'61F419',v:'1022',c:'児発７２・人欠２・開所減２・',baseItem: true,},
  {s:'61F420',v:'869',c:'児発７２・人欠２・開所減２・評価減・',baseItem: true,},
  {s:'61F421',v:'841',c:'児発７２・人欠２・未計画・',baseItem: true,},
  {s:'61F422',v:'715',c:'児発７２・人欠２・未計画・評価減・',baseItem: true,},
  {s:'61F423',v:'589',c:'児発７２・人欠２・未計画・開所減１・',baseItem: true,},
  {s:'61F424',v:'501',c:'児発７２・人欠２・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61F425',v:'715',c:'児発７２・人欠２・未計画・開所減２・',baseItem: true,},
  {s:'61F426',v:'608',c:'児発７２・人欠２・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61F427',v:'601',c:'児発７２・人欠２・未計画２・',baseItem: true,},
  {s:'61F428',v:'511',c:'児発７２・人欠２・未計画２・評価減・',baseItem: true,},
  {s:'61F429',v:'421',c:'児発７２・人欠２・未計画２・開所減１・',baseItem: true,},
  {s:'61F430',v:'358',c:'児発７２・人欠２・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61F431',v:'511',c:'児発７２・人欠２・未計画２・開所減２・',baseItem: true,},
  {s:'61F432',v:'434',c:'児発７２・人欠２・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61F433',v:'1228',c:'児発７３・人欠・',baseItem: true,},
  {s:'61F434',v:'1044',c:'児発７３・人欠・評価減・',baseItem: true,},
  {s:'61F435',v:'860',c:'児発７３・人欠・開所減１・',baseItem: true,},
  {s:'61F436',v:'731',c:'児発７３・人欠・開所減１・評価減・',baseItem: true,},
  {s:'61F437',v:'1044',c:'児発７３・人欠・開所減２・',baseItem: true,},
  {s:'61F438',v:'887',c:'児発７３・人欠・開所減２・評価減・',baseItem: true,},
  {s:'61F439',v:'860',c:'児発７３・人欠・未計画・',baseItem: true,},
  {s:'61F440',v:'731',c:'児発７３・人欠・未計画・評価減・',baseItem: true,},
  {s:'61F441',v:'602',c:'児発７３・人欠・未計画・開所減１・',baseItem: true,},
  {s:'61F442',v:'512',c:'児発７３・人欠・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61F443',v:'731',c:'児発７３・人欠・未計画・開所減２・',baseItem: true,},
  {s:'61F444',v:'621',c:'児発７３・人欠・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61F445',v:'614',c:'児発７３・人欠・未計画２・',baseItem: true,},
  {s:'61F446',v:'522',c:'児発７３・人欠・未計画２・評価減・',baseItem: true,},
  {s:'61F447',v:'430',c:'児発７３・人欠・未計画２・開所減１・',baseItem: true,},
  {s:'61F448',v:'366',c:'児発７３・人欠・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61F449',v:'522',c:'児発７３・人欠・未計画２・開所減２・',baseItem: true,},
  {s:'61F450',v:'444',c:'児発７３・人欠・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61F451',v:'877',c:'児発７３・人欠２・',baseItem: true,},
  {s:'61F452',v:'745',c:'児発７３・人欠２・評価減・',baseItem: true,},
  {s:'61F453',v:'614',c:'児発７３・人欠２・開所減１・',baseItem: true,},
  {s:'61F454',v:'522',c:'児発７３・人欠２・開所減１・評価減・',baseItem: true,},
  {s:'61F455',v:'745',c:'児発７３・人欠２・開所減２・',baseItem: true,},
  {s:'61F456',v:'633',c:'児発７３・人欠２・開所減２・評価減・',baseItem: true,},
  {s:'61F457',v:'614',c:'児発７３・人欠２・未計画・',baseItem: true,},
  {s:'61F458',v:'522',c:'児発７３・人欠２・未計画・評価減・',baseItem: true,},
  {s:'61F459',v:'430',c:'児発７３・人欠２・未計画・開所減１・',baseItem: true,},
  {s:'61F460',v:'366',c:'児発７３・人欠２・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61F461',v:'522',c:'児発７３・人欠２・未計画・開所減２・',baseItem: true,},
  {s:'61F462',v:'444',c:'児発７３・人欠２・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61F463',v:'439',c:'児発７３・人欠２・未計画２・',baseItem: true,},
  {s:'61F464',v:'373',c:'児発７３・人欠２・未計画２・評価減・',baseItem: true,},
  {s:'61F465',v:'307',c:'児発７３・人欠２・未計画２・開所減１・',baseItem: true,},
  {s:'61F466',v:'261',c:'児発７３・人欠２・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61F467',v:'373',c:'児発７３・人欠２・未計画２・開所減２・',baseItem: true,},
  {s:'61F468',v:'317',c:'児発７３・人欠２・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61F469',v:'1059',c:'児発７４・人欠・',baseItem: true,},
  {s:'61F470',v:'900',c:'児発７４・人欠・評価減・',baseItem: true,},
  {s:'61F471',v:'741',c:'児発７４・人欠・開所減１・',baseItem: true,},
  {s:'61F472',v:'630',c:'児発７４・人欠・開所減１・評価減・',baseItem: true,},
  {s:'61F473',v:'900',c:'児発７４・人欠・開所減２・',baseItem: true,},
  {s:'61F474',v:'765',c:'児発７４・人欠・開所減２・評価減・',baseItem: true,},
  {s:'61F475',v:'741',c:'児発７４・人欠・未計画・',baseItem: true,},
  {s:'61F476',v:'630',c:'児発７４・人欠・未計画・評価減・',baseItem: true,},
  {s:'61F477',v:'519',c:'児発７４・人欠・未計画・開所減１・',baseItem: true,},
  {s:'61F478',v:'441',c:'児発７４・人欠・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61F479',v:'630',c:'児発７４・人欠・未計画・開所減２・',baseItem: true,},
  {s:'61F480',v:'536',c:'児発７４・人欠・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61F481',v:'530',c:'児発７４・人欠・未計画２・',baseItem: true,},
  {s:'61F482',v:'451',c:'児発７４・人欠・未計画２・評価減・',baseItem: true,},
  {s:'61F483',v:'371',c:'児発７４・人欠・未計画２・開所減１・',baseItem: true,},
  {s:'61F484',v:'315',c:'児発７４・人欠・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61F485',v:'451',c:'児発７４・人欠・未計画２・開所減２・',baseItem: true,},
  {s:'61F486',v:'383',c:'児発７４・人欠・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61F487',v:'757',c:'児発７４・人欠２・',baseItem: true,},
  {s:'61F488',v:'643',c:'児発７４・人欠２・評価減・',baseItem: true,},
  {s:'61F489',v:'530',c:'児発７４・人欠２・開所減１・',baseItem: true,},
  {s:'61F490',v:'451',c:'児発７４・人欠２・開所減１・評価減・',baseItem: true,},
  {s:'61F491',v:'643',c:'児発７４・人欠２・開所減２・',baseItem: true,},
  {s:'61F492',v:'547',c:'児発７４・人欠２・開所減２・評価減・',baseItem: true,},
  {s:'61F493',v:'530',c:'児発７４・人欠２・未計画・',baseItem: true,},
  {s:'61F494',v:'451',c:'児発７４・人欠２・未計画・評価減・',baseItem: true,},
  {s:'61F495',v:'371',c:'児発７４・人欠２・未計画・開所減１・',baseItem: true,},
  {s:'61F496',v:'315',c:'児発７４・人欠２・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61F497',v:'451',c:'児発７４・人欠２・未計画・開所減２・',baseItem: true,},
  {s:'61F498',v:'383',c:'児発７４・人欠２・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61F499',v:'379',c:'児発７４・人欠２・未計画２・',baseItem: true,},
  {s:'61F500',v:'322',c:'児発７４・人欠２・未計画２・評価減・',baseItem: true,},
  {s:'61F501',v:'265',c:'児発７４・人欠２・未計画２・開所減１・',baseItem: true,},
  {s:'61F502',v:'225',c:'児発７４・人欠２・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61F503',v:'322',c:'児発７４・人欠２・未計画２・開所減２・',baseItem: true,},
  {s:'61F504',v:'274',c:'児発７４・人欠２・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61F505',v:'983',c:'児発７５・人欠・',baseItem: true,},
  {s:'61F506',v:'836',c:'児発７５・人欠・評価減・',baseItem: true,},
  {s:'61F507',v:'688',c:'児発７５・人欠・開所減１・',baseItem: true,},
  {s:'61F508',v:'585',c:'児発７５・人欠・開所減１・評価減・',baseItem: true,},
  {s:'61F509',v:'836',c:'児発７５・人欠・開所減２・',baseItem: true,},
  {s:'61F510',v:'711',c:'児発７５・人欠・開所減２・評価減・',baseItem: true,},
  {s:'61F511',v:'688',c:'児発７５・人欠・未計画・',baseItem: true,},
  {s:'61F512',v:'585',c:'児発７５・人欠・未計画・評価減・',baseItem: true,},
  {s:'61F513',v:'482',c:'児発７５・人欠・未計画・開所減１・',baseItem: true,},
  {s:'61F514',v:'410',c:'児発７５・人欠・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61F515',v:'585',c:'児発７５・人欠・未計画・開所減２・',baseItem: true,},
  {s:'61F516',v:'497',c:'児発７５・人欠・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61F517',v:'492',c:'児発７５・人欠・未計画２・',baseItem: true,},
  {s:'61F518',v:'418',c:'児発７５・人欠・未計画２・評価減・',baseItem: true,},
  {s:'61F519',v:'344',c:'児発７５・人欠・未計画２・開所減１・',baseItem: true,},
  {s:'61F520',v:'292',c:'児発７５・人欠・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61F521',v:'418',c:'児発７５・人欠・未計画２・開所減２・',baseItem: true,},
  {s:'61F522',v:'355',c:'児発７５・人欠・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61F523',v:'702',c:'児発７５・人欠２・',baseItem: true,},
  {s:'61F524',v:'597',c:'児発７５・人欠２・評価減・',baseItem: true,},
  {s:'61F525',v:'491',c:'児発７５・人欠２・開所減１・',baseItem: true,},
  {s:'61F526',v:'417',c:'児発７５・人欠２・開所減１・評価減・',baseItem: true,},
  {s:'61F527',v:'597',c:'児発７５・人欠２・開所減２・',baseItem: true,},
  {s:'61F528',v:'507',c:'児発７５・人欠２・開所減２・評価減・',baseItem: true,},
  {s:'61F529',v:'491',c:'児発７５・人欠２・未計画・',baseItem: true,},
  {s:'61F530',v:'417',c:'児発７５・人欠２・未計画・評価減・',baseItem: true,},
  {s:'61F531',v:'344',c:'児発７５・人欠２・未計画・開所減１・',baseItem: true,},
  {s:'61F532',v:'292',c:'児発７５・人欠２・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61F533',v:'417',c:'児発７５・人欠２・未計画・開所減２・',baseItem: true,},
  {s:'61F534',v:'354',c:'児発７５・人欠２・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61F535',v:'351',c:'児発７５・人欠２・未計画２・',baseItem: true,},
  {s:'61F536',v:'298',c:'児発７５・人欠２・未計画２・評価減・',baseItem: true,},
  {s:'61F537',v:'246',c:'児発７５・人欠２・未計画２・開所減１・',baseItem: true,},
  {s:'61F538',v:'209',c:'児発７５・人欠２・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61F539',v:'298',c:'児発７５・人欠２・未計画２・開所減２・',baseItem: true,},
  {s:'61F540',v:'253',c:'児発７５・人欠２・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61F541',v:'995',c:'児発７６・人欠・',baseItem: true,},
  {s:'61F542',v:'846',c:'児発７６・人欠・評価減・',baseItem: true,},
  {s:'61F543',v:'697',c:'児発７６・人欠・開所減１・',baseItem: true,},
  {s:'61F544',v:'592',c:'児発７６・人欠・開所減１・評価減・',baseItem: true,},
  {s:'61F545',v:'846',c:'児発７６・人欠・開所減２・',baseItem: true,},
  {s:'61F546',v:'719',c:'児発７６・人欠・開所減２・評価減・',baseItem: true,},
  {s:'61F547',v:'697',c:'児発７６・人欠・未計画・',baseItem: true,},
  {s:'61F548',v:'592',c:'児発７６・人欠・未計画・評価減・',baseItem: true,},
  {s:'61F549',v:'488',c:'児発７６・人欠・未計画・開所減１・',baseItem: true,},
  {s:'61F550',v:'415',c:'児発７６・人欠・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61F551',v:'592',c:'児発７６・人欠・未計画・開所減２・',baseItem: true,},
  {s:'61F552',v:'503',c:'児発７６・人欠・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61F553',v:'498',c:'児発７６・人欠・未計画２・',baseItem: true,},
  {s:'61F554',v:'423',c:'児発７６・人欠・未計画２・評価減・',baseItem: true,},
  {s:'61F555',v:'349',c:'児発７６・人欠・未計画２・開所減１・',baseItem: true,},
  {s:'61F556',v:'297',c:'児発７６・人欠・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61F557',v:'423',c:'児発７６・人欠・未計画２・開所減２・',baseItem: true,},
  {s:'61F558',v:'360',c:'児発７６・人欠・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61F559',v:'711',c:'児発７６・人欠２・',baseItem: true,},
  {s:'61F560',v:'604',c:'児発７６・人欠２・評価減・',baseItem: true,},
  {s:'61F561',v:'498',c:'児発７６・人欠２・開所減１・',baseItem: true,},
  {s:'61F562',v:'423',c:'児発７６・人欠２・開所減１・評価減・',baseItem: true,},
  {s:'61F563',v:'604',c:'児発７６・人欠２・開所減２・',baseItem: true,},
  {s:'61F564',v:'513',c:'児発７６・人欠２・開所減２・評価減・',baseItem: true,},
  {s:'61F565',v:'498',c:'児発７６・人欠２・未計画・',baseItem: true,},
  {s:'61F566',v:'423',c:'児発７６・人欠２・未計画・評価減・',baseItem: true,},
  {s:'61F567',v:'349',c:'児発７６・人欠２・未計画・開所減１・',baseItem: true,},
  {s:'61F568',v:'297',c:'児発７６・人欠２・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61F569',v:'423',c:'児発７６・人欠２・未計画・開所減２・',baseItem: true,},
  {s:'61F570',v:'360',c:'児発７６・人欠２・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61F571',v:'356',c:'児発７６・人欠２・未計画２・',baseItem: true,},
  {s:'61F572',v:'303',c:'児発７６・人欠２・未計画２・評価減・',baseItem: true,},
  {s:'61F573',v:'249',c:'児発７６・人欠２・未計画２・開所減１・',baseItem: true,},
  {s:'61F574',v:'212',c:'児発７６・人欠２・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61F575',v:'303',c:'児発７６・人欠２・未計画２・開所減２・',baseItem: true,},
  {s:'61F576',v:'258',c:'児発７６・人欠２・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61F577',v:'826',c:'児発７７・人欠・',baseItem: true,},
  {s:'61F578',v:'702',c:'児発７７・人欠・評価減・',baseItem: true,},
  {s:'61F579',v:'578',c:'児発７７・人欠・開所減１・',baseItem: true,},
  {s:'61F580',v:'491',c:'児発７７・人欠・開所減１・評価減・',baseItem: true,},
  {s:'61F581',v:'702',c:'児発７７・人欠・開所減２・',baseItem: true,},
  {s:'61F582',v:'597',c:'児発７７・人欠・開所減２・評価減・',baseItem: true,},
  {s:'61F583',v:'578',c:'児発７７・人欠・未計画・',baseItem: true,},
  {s:'61F584',v:'491',c:'児発７７・人欠・未計画・評価減・',baseItem: true,},
  {s:'61F585',v:'405',c:'児発７７・人欠・未計画・開所減１・',baseItem: true,},
  {s:'61F586',v:'344',c:'児発７７・人欠・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61F587',v:'491',c:'児発７７・人欠・未計画・開所減２・',baseItem: true,},
  {s:'61F588',v:'417',c:'児発７７・人欠・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61F589',v:'413',c:'児発７７・人欠・未計画２・',baseItem: true,},
  {s:'61F590',v:'351',c:'児発７７・人欠・未計画２・評価減・',baseItem: true,},
  {s:'61F591',v:'289',c:'児発７７・人欠・未計画２・開所減１・',baseItem: true,},
  {s:'61F592',v:'246',c:'児発７７・人欠・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61F593',v:'351',c:'児発７７・人欠・未計画２・開所減２・',baseItem: true,},
  {s:'61F594',v:'298',c:'児発７７・人欠・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61F595',v:'590',c:'児発７７・人欠２・',baseItem: true,},
  {s:'61F596',v:'502',c:'児発７７・人欠２・評価減・',baseItem: true,},
  {s:'61F597',v:'413',c:'児発７７・人欠２・開所減１・',baseItem: true,},
  {s:'61F598',v:'351',c:'児発７７・人欠２・開所減１・評価減・',baseItem: true,},
  {s:'61F599',v:'502',c:'児発７７・人欠２・開所減２・',baseItem: true,},
  {s:'61F600',v:'427',c:'児発７７・人欠２・開所減２・評価減・',baseItem: true,},
  {s:'61F601',v:'413',c:'児発７７・人欠２・未計画・',baseItem: true,},
  {s:'61F602',v:'351',c:'児発７７・人欠２・未計画・評価減・',baseItem: true,},
  {s:'61F603',v:'289',c:'児発７７・人欠２・未計画・開所減１・',baseItem: true,},
  {s:'61F604',v:'246',c:'児発７７・人欠２・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61F605',v:'351',c:'児発７７・人欠２・未計画・開所減２・',baseItem: true,},
  {s:'61F606',v:'298',c:'児発７７・人欠２・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61F607',v:'295',c:'児発７７・人欠２・未計画２・',baseItem: true,},
  {s:'61F608',v:'251',c:'児発７７・人欠２・未計画２・評価減・',baseItem: true,},
  {s:'61F609',v:'207',c:'児発７７・人欠２・未計画２・開所減１・',baseItem: true,},
  {s:'61F610',v:'176',c:'児発７７・人欠２・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61F611',v:'251',c:'児発７７・人欠２・未計画２・開所減２・',baseItem: true,},
  {s:'61F612',v:'213',c:'児発７７・人欠２・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61F613',v:'750',c:'児発７８・人欠・',baseItem: true,},
  {s:'61F614',v:'638',c:'児発７８・人欠・評価減・',baseItem: true,},
  {s:'61F615',v:'525',c:'児発７８・人欠・開所減１・',baseItem: true,},
  {s:'61F616',v:'446',c:'児発７８・人欠・開所減１・評価減・',baseItem: true,},
  {s:'61F617',v:'638',c:'児発７８・人欠・開所減２・',baseItem: true,},
  {s:'61F618',v:'542',c:'児発７８・人欠・開所減２・評価減・',baseItem: true,},
  {s:'61F619',v:'525',c:'児発７８・人欠・未計画・',baseItem: true,},
  {s:'61F620',v:'446',c:'児発７８・人欠・未計画・評価減・',baseItem: true,},
  {s:'61F621',v:'368',c:'児発７８・人欠・未計画・開所減１・',baseItem: true,},
  {s:'61F622',v:'313',c:'児発７８・人欠・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61F623',v:'446',c:'児発７８・人欠・未計画・開所減２・',baseItem: true,},
  {s:'61F624',v:'379',c:'児発７８・人欠・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61F625',v:'375',c:'児発７８・人欠・未計画２・',baseItem: true,},
  {s:'61F626',v:'319',c:'児発７８・人欠・未計画２・評価減・',baseItem: true,},
  {s:'61F627',v:'263',c:'児発７８・人欠・未計画２・開所減１・',baseItem: true,},
  {s:'61F628',v:'224',c:'児発７８・人欠・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61F629',v:'319',c:'児発７８・人欠・未計画２・開所減２・',baseItem: true,},
  {s:'61F630',v:'271',c:'児発７８・人欠・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61F631',v:'536',c:'児発７８・人欠２・',baseItem: true,},
  {s:'61F632',v:'456',c:'児発７８・人欠２・評価減・',baseItem: true,},
  {s:'61F633',v:'375',c:'児発７８・人欠２・開所減１・',baseItem: true,},
  {s:'61F634',v:'319',c:'児発７８・人欠２・開所減１・評価減・',baseItem: true,},
  {s:'61F635',v:'456',c:'児発７８・人欠２・開所減２・',baseItem: true,},
  {s:'61F636',v:'388',c:'児発７８・人欠２・開所減２・評価減・',baseItem: true,},
  {s:'61F637',v:'375',c:'児発７８・人欠２・未計画・',baseItem: true,},
  {s:'61F638',v:'319',c:'児発７８・人欠２・未計画・評価減・',baseItem: true,},
  {s:'61F639',v:'263',c:'児発７８・人欠２・未計画・開所減１・',baseItem: true,},
  {s:'61F640',v:'224',c:'児発７８・人欠２・未計画・開所減１・評価減・',baseItem: true,},
  {s:'61F641',v:'319',c:'児発７８・人欠２・未計画・開所減２・',baseItem: true,},
  {s:'61F642',v:'271',c:'児発７８・人欠２・未計画・開所減２・評価減・',baseItem: true,},
  {s:'61F643',v:'268',c:'児発７８・人欠２・未計画２・',baseItem: true,},
  {s:'61F644',v:'228',c:'児発７８・人欠２・未計画２・評価減・',baseItem: true,},
  {s:'61F645',v:'188',c:'児発７８・人欠２・未計画２・開所減１・',baseItem: true,},
  {s:'61F646',v:'160',c:'児発７８・人欠２・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'61F647',v:'228',c:'児発７８・人欠２・未計画２・開所減２・',baseItem: true,},
  {s:'61F648',v:'194',c:'児発７８・人欠２・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'619451',v:'528',c:'児発４・人欠・',baseItem: true,},
  {s:'619453',v:'449',c:'児発４・人欠・評価減・',baseItem: true,},
  {s:'619455',v:'370',c:'児発４・人欠・開所減１・',baseItem: true,},
  {s:'619457',v:'315',c:'児発４・人欠・開所減１・評価減・',baseItem: true,},
  {s:'619459',v:'449',c:'児発４・人欠・開所減２・',baseItem: true,},
  {s:'619461',v:'382',c:'児発４・人欠・開所減２・評価減・',baseItem: true,},
  {s:'619463',v:'370',c:'児発４・人欠・未計画・',baseItem: true,},
  {s:'619465',v:'315',c:'児発４・人欠・未計画・評価減・',baseItem: true,},
  {s:'619467',v:'259',c:'児発４・人欠・未計画・開所減１・',baseItem: true,},
  {s:'619469',v:'220',c:'児発４・人欠・未計画・開所減１・評価減・',baseItem: true,},
  {s:'619471',v:'315',c:'児発４・人欠・未計画・開所減２・',baseItem: true,},
  {s:'619473',v:'268',c:'児発４・人欠・未計画・開所減２・評価減・',baseItem: true,},
  {s:'619475',v:'264',c:'児発４・人欠・未計画２・',baseItem: true,},
  {s:'619477',v:'224',c:'児発４・人欠・未計画２・評価減・',baseItem: true,},
  {s:'619479',v:'185',c:'児発４・人欠・未計画２・開所減１・',baseItem: true,},
  {s:'619481',v:'157',c:'児発４・人欠・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'619483',v:'224',c:'児発４・人欠・未計画２・開所減２・',baseItem: true,},
  {s:'619485',v:'190',c:'児発４・人欠・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'619487',v:'377',c:'児発４・人欠２・',baseItem: true,},
  {s:'619489',v:'320',c:'児発４・人欠２・評価減・',baseItem: true,},
  {s:'619491',v:'264',c:'児発４・人欠２・開所減１・',baseItem: true,},
  {s:'619493',v:'224',c:'児発４・人欠２・開所減１・評価減・',baseItem: true,},
  {s:'619495',v:'320',c:'児発４・人欠２・開所減２・',baseItem: true,},
  {s:'619497',v:'272',c:'児発４・人欠２・開所減２・評価減・',baseItem: true,},
  {s:'619499',v:'264',c:'児発４・人欠２・未計画・',baseItem: true,},
  {s:'619501',v:'224',c:'児発４・人欠２・未計画・評価減・',baseItem: true,},
  {s:'619503',v:'185',c:'児発４・人欠２・未計画・開所減１・',baseItem: true,},
  {s:'619505',v:'157',c:'児発４・人欠２・未計画・開所減１・評価減・',baseItem: true,},
  {s:'619507',v:'224',c:'児発４・人欠２・未計画・開所減２・',baseItem: true,},
  {s:'619509',v:'190',c:'児発４・人欠２・未計画・開所減２・評価減・',baseItem: true,},
  {s:'619511',v:'189',c:'児発４・人欠２・未計画２・',baseItem: true,},
  {s:'619513',v:'161',c:'児発４・人欠２・未計画２・評価減・',baseItem: true,},
  {s:'619515',v:'132',c:'児発４・人欠２・未計画２・開所減１・',baseItem: true,},
  {s:'619517',v:'112',c:'児発４・人欠２・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'619519',v:'161',c:'児発４・人欠２・未計画２・開所減２・',baseItem: true,},
  {s:'619521',v:'137',c:'児発４・人欠２・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'619601',v:'359',c:'児発５・人欠・',baseItem: true,},
  {s:'619603',v:'305',c:'児発５・人欠・評価減・',baseItem: true,},
  {s:'619605',v:'251',c:'児発５・人欠・開所減１・',baseItem: true,},
  {s:'619607',v:'213',c:'児発５・人欠・開所減１・評価減・',baseItem: true,},
  {s:'619609',v:'305',c:'児発５・人欠・開所減２・',baseItem: true,},
  {s:'619611',v:'259',c:'児発５・人欠・開所減２・評価減・',baseItem: true,},
  {s:'619613',v:'251',c:'児発５・人欠・未計画・',baseItem: true,},
  {s:'619615',v:'213',c:'児発５・人欠・未計画・評価減・',baseItem: true,},
  {s:'619617',v:'176',c:'児発５・人欠・未計画・開所減１・',baseItem: true,},
  {s:'619619',v:'150',c:'児発５・人欠・未計画・開所減１・評価減・',baseItem: true,},
  {s:'619621',v:'213',c:'児発５・人欠・未計画・開所減２・',baseItem: true,},
  {s:'619623',v:'181',c:'児発５・人欠・未計画・開所減２・評価減・',baseItem: true,},
  {s:'619625',v:'180',c:'児発５・人欠・未計画２・',baseItem: true,},
  {s:'619627',v:'153',c:'児発５・人欠・未計画２・評価減・',baseItem: true,},
  {s:'619629',v:'126',c:'児発５・人欠・未計画２・開所減１・',baseItem: true,},
  {s:'619631',v:'107',c:'児発５・人欠・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'619633',v:'153',c:'児発５・人欠・未計画２・開所減２・',baseItem: true,},
  {s:'619635',v:'130',c:'児発５・人欠・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'619637',v:'257',c:'児発５・人欠２・',baseItem: true,},
  {s:'619639',v:'218',c:'児発５・人欠２・評価減・',baseItem: true,},
  {s:'619641',v:'180',c:'児発５・人欠２・開所減１・',baseItem: true,},
  {s:'619643',v:'153',c:'児発５・人欠２・開所減１・評価減・',baseItem: true,},
  {s:'619645',v:'218',c:'児発５・人欠２・開所減２・',baseItem: true,},
  {s:'619647',v:'185',c:'児発５・人欠２・開所減２・評価減・',baseItem: true,},
  {s:'619649',v:'180',c:'児発５・人欠２・未計画・',baseItem: true,},
  {s:'619651',v:'153',c:'児発５・人欠２・未計画・評価減・',baseItem: true,},
  {s:'619653',v:'126',c:'児発５・人欠２・未計画・開所減１・',baseItem: true,},
  {s:'619655',v:'107',c:'児発５・人欠２・未計画・開所減１・評価減・',baseItem: true,},
  {s:'619657',v:'153',c:'児発５・人欠２・未計画・開所減２・',baseItem: true,},
  {s:'619659',v:'130',c:'児発５・人欠２・未計画・開所減２・評価減・',baseItem: true,},
  {s:'619661',v:'129',c:'児発５・人欠２・未計画２・',baseItem: true,},
  {s:'619663',v:'110',c:'児発５・人欠２・未計画２・評価減・',baseItem: true,},
  {s:'619665',v:'90',c:'児発５・人欠２・未計画２・開所減１・',baseItem: true,},
  {s:'619667',v:'77',c:'児発５・人欠２・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'619669',v:'110',c:'児発５・人欠２・未計画２・開所減２・',baseItem: true,},
  {s:'619671',v:'94',c:'児発５・人欠２・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'619775',v:'283',c:'児発６・人欠・',baseItem: true,},
  {s:'619777',v:'241',c:'児発６・人欠・評価減・',baseItem: true,},
  {s:'619779',v:'198',c:'児発６・人欠・開所減１・',baseItem: true,},
  {s:'619781',v:'168',c:'児発６・人欠・開所減１・評価減・',baseItem: true,},
  {s:'619783',v:'241',c:'児発６・人欠・開所減２・',baseItem: true,},
  {s:'619785',v:'205',c:'児発６・人欠・開所減２・評価減・',baseItem: true,},
  {s:'619787',v:'198',c:'児発６・人欠・未計画・',baseItem: true,},
  {s:'619789',v:'168',c:'児発６・人欠・未計画・評価減・',baseItem: true,},
  {s:'619791',v:'139',c:'児発６・人欠・未計画・開所減１・',baseItem: true,},
  {s:'619793',v:'118',c:'児発６・人欠・未計画・開所減１・評価減・',baseItem: true,},
  {s:'619795',v:'168',c:'児発６・人欠・未計画・開所減２・',baseItem: true,},
  {s:'619797',v:'143',c:'児発６・人欠・未計画・開所減２・評価減・',baseItem: true,},
  {s:'619799',v:'142',c:'児発６・人欠・未計画２・',baseItem: true,},
  {s:'619801',v:'121',c:'児発６・人欠・未計画２・評価減・',baseItem: true,},
  {s:'619803',v:'99',c:'児発６・人欠・未計画２・開所減１・',baseItem: true,},
  {s:'619805',v:'84',c:'児発６・人欠・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'619807',v:'121',c:'児発６・人欠・未計画２・開所減２・',baseItem: true,},
  {s:'619809',v:'103',c:'児発６・人欠・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'619811',v:'202',c:'児発６・人欠２・',baseItem: true,},
  {s:'619813',v:'172',c:'児発６・人欠２・評価減・',baseItem: true,},
  {s:'619815',v:'141',c:'児発６・人欠２・開所減１・',baseItem: true,},
  {s:'619817',v:'120',c:'児発６・人欠２・開所減１・評価減・',baseItem: true,},
  {s:'619819',v:'172',c:'児発６・人欠２・開所減２・',baseItem: true,},
  {s:'619821',v:'146',c:'児発６・人欠２・開所減２・評価減・',baseItem: true,},
  {s:'619823',v:'141',c:'児発６・人欠２・未計画・',baseItem: true,},
  {s:'619825',v:'120',c:'児発６・人欠２・未計画・評価減・',baseItem: true,},
  {s:'619827',v:'99',c:'児発６・人欠２・未計画・開所減１・',baseItem: true,},
  {s:'619829',v:'84',c:'児発６・人欠２・未計画・開所減１・評価減・',baseItem: true,},
  {s:'619831',v:'120',c:'児発６・人欠２・未計画・開所減２・',baseItem: true,},
  {s:'619833',v:'102',c:'児発６・人欠２・未計画・開所減２・評価減・',baseItem: true,},
  {s:'619835',v:'101',c:'児発６・人欠２・未計画２・',baseItem: true,},
  {s:'619837',v:'86',c:'児発６・人欠２・未計画２・評価減・',baseItem: true,},
  {s:'619839',v:'71',c:'児発６・人欠２・未計画２・開所減１・',baseItem: true,},
  {s:'619841',v:'60',c:'児発６・人欠２・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'619843',v:'86',c:'児発６・人欠２・未計画２・開所減２・',baseItem: true,},
  {s:'619845',v:'73',c:'児発６・人欠２・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'619919',v:'491',c:'基準該当児発Ⅰ・人欠・',baseItem: true,},
  {s:'619920',v:'417',c:'基準該当児発Ⅰ・人欠・評価減・',baseItem: true,},
  {s:'619921',v:'344',c:'基準該当児発Ⅰ・人欠・開所減１・',baseItem: true,},
  {s:'619922',v:'292',c:'基準該当児発Ⅰ・人欠・開所減１・評価減・',baseItem: true,},
  {s:'619923',v:'417',c:'基準該当児発Ⅰ・人欠・開所減２・',baseItem: true,},
  {s:'619924',v:'354',c:'基準該当児発Ⅰ・人欠・開所減２・評価減・',baseItem: true,},
  {s:'619925',v:'344',c:'基準該当児発Ⅰ・人欠・未計画・',baseItem: true,},
  {s:'619926',v:'292',c:'基準該当児発Ⅰ・人欠・未計画・評価減・',baseItem: true,},
  {s:'619927',v:'241',c:'基準該当児発Ⅰ・人欠・未計画・開所減１・',baseItem: true,},
  {s:'619928',v:'205',c:'基準該当児発Ⅰ・人欠・未計画・開所減１・評価減・',baseItem: true,},
  {s:'619929',v:'292',c:'基準該当児発Ⅰ・人欠・未計画・開所減２・',baseItem: true,},
  {s:'619930',v:'248',c:'基準該当児発Ⅰ・人欠・未計画・開所減２・評価減・',baseItem: true,},
  {s:'619931',v:'246',c:'基準該当児発Ⅰ・人欠・未計画２・',baseItem: true,},
  {s:'619932',v:'209',c:'基準該当児発Ⅰ・人欠・未計画２・評価減・',baseItem: true,},
  {s:'619933',v:'172',c:'基準該当児発Ⅰ・人欠・未計画２・開所減１・',baseItem: true,},
  {s:'619934',v:'146',c:'基準該当児発Ⅰ・人欠・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'619935',v:'209',c:'基準該当児発Ⅰ・人欠・未計画２・開所減２・',baseItem: true,},
  {s:'619936',v:'178',c:'基準該当児発Ⅰ・人欠・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'619937',v:'351',c:'基準該当児発Ⅰ・人欠２・',baseItem: true,},
  {s:'619938',v:'298',c:'基準該当児発Ⅰ・人欠２・評価減・',baseItem: true,},
  {s:'619939',v:'246',c:'基準該当児発Ⅰ・人欠２・開所減１・',baseItem: true,},
  {s:'619940',v:'209',c:'基準該当児発Ⅰ・人欠２・開所減１・評価減・',baseItem: true,},
  {s:'619941',v:'298',c:'基準該当児発Ⅰ・人欠２・開所減２・',baseItem: true,},
  {s:'619942',v:'253',c:'基準該当児発Ⅰ・人欠２・開所減２・評価減・',baseItem: true,},
  {s:'619943',v:'246',c:'基準該当児発Ⅰ・人欠２・未計画・',baseItem: true,},
  {s:'619944',v:'209',c:'基準該当児発Ⅰ・人欠２・未計画・評価減・',baseItem: true,},
  {s:'619945',v:'172',c:'基準該当児発Ⅰ・人欠２・未計画・開所減１・',baseItem: true,},
  {s:'619946',v:'146',c:'基準該当児発Ⅰ・人欠２・未計画・開所減１・評価減・',baseItem: true,},
  {s:'619947',v:'209',c:'基準該当児発Ⅰ・人欠２・未計画・開所減２・',baseItem: true,},
  {s:'619948',v:'178',c:'基準該当児発Ⅰ・人欠２・未計画・開所減２・評価減・',baseItem: true,},
  {s:'619949',v:'176',c:'基準該当児発Ⅰ・人欠２・未計画２・',baseItem: true,},
  {s:'619950',v:'150',c:'基準該当児発Ⅰ・人欠２・未計画２・評価減・',baseItem: true,},
  {s:'619951',v:'123',c:'基準該当児発Ⅰ・人欠２・未計画２・開所減１・',baseItem: true,},
  {s:'619952',v:'105',c:'基準該当児発Ⅰ・人欠２・未計画２・開所減１・評価減・',baseItem: true,},
  {s:'619953',v:'150',c:'基準該当児発Ⅰ・人欠２・未計画２・開所減２・',baseItem: true,},
  {s:'619954',v:'128',c:'基準該当児発Ⅰ・人欠２・未計画２・開所減２・評価減・',baseItem: true,},
  {s:'61CA00',v:'2020',c:'児発６１・責欠・',baseItem: true,},
  {s:'61CA01',v:'1717',c:'児発６１・責欠・評価減・',baseItem: true,},
  {s:'61CA02',v:'1414',c:'児発６１・責欠・開所減１・',baseItem: true,},
  {s:'61CA03',v:'1202',c:'児発６１・責欠・開所減１・評価減・',baseItem: true,},
  {s:'61CA04',v:'1717',c:'児発６１・責欠・開所減２・',baseItem: true,},
  {s:'61CA05',v:'1459',c:'児発６１・責欠・開所減２・評価減・',baseItem: true,},
  {s:'61CA06',v:'1443',c:'児発６１・責欠２・',baseItem: true,},
  {s:'61CA07',v:'1227',c:'児発６１・責欠２・評価減・',baseItem: true,},
  {s:'61CA08',v:'1010',c:'児発６１・責欠２・開所減１・',baseItem: true,},
  {s:'61CA09',v:'859',c:'児発６１・責欠２・開所減１・評価減・',baseItem: true,},
  {s:'61CA10',v:'1227',c:'児発６１・責欠２・開所減２・',baseItem: true,},
  {s:'61CA11',v:'1043',c:'児発６１・責欠２・開所減２・評価減・',baseItem: true,},
  {s:'61CA12',v:'1829',c:'児発６２・責欠・',baseItem: true,},
  {s:'61CA13',v:'1555',c:'児発６２・責欠・評価減・',baseItem: true,},
  {s:'61CA14',v:'1280',c:'児発６２・責欠・開所減１・',baseItem: true,},
  {s:'61CA15',v:'1088',c:'児発６２・責欠・開所減１・評価減・',baseItem: true,},
  {s:'61CA16',v:'1555',c:'児発６２・責欠・開所減２・',baseItem: true,},
  {s:'61CA17',v:'1322',c:'児発６２・責欠・開所減２・評価減・',baseItem: true,},
  {s:'61CA18',v:'1307',c:'児発６２・責欠２・',baseItem: true,},
  {s:'61CA19',v:'1111',c:'児発６２・責欠２・評価減・',baseItem: true,},
  {s:'61CA20',v:'915',c:'児発６２・責欠２・開所減１・',baseItem: true,},
  {s:'61CA21',v:'778',c:'児発６２・責欠２・開所減１・評価減・',baseItem: true,},
  {s:'61CA22',v:'1111',c:'児発６２・責欠２・開所減２・',baseItem: true,},
  {s:'61CA23',v:'944',c:'児発６２・責欠２・開所減２・評価減・',baseItem: true,},
  {s:'61CA24',v:'1740',c:'児発６３・責欠・',baseItem: true,},
  {s:'61CA25',v:'1479',c:'児発６３・責欠・評価減・',baseItem: true,},
  {s:'61CA26',v:'1218',c:'児発６３・責欠・開所減１・',baseItem: true,},
  {s:'61CA27',v:'1035',c:'児発６３・責欠・開所減１・評価減・',baseItem: true,},
  {s:'61CA28',v:'1479',c:'児発６３・責欠・開所減２・',baseItem: true,},
  {s:'61CA29',v:'1257',c:'児発６３・責欠・開所減２・評価減・',baseItem: true,},
  {s:'61CA30',v:'1243',c:'児発６３・責欠２・',baseItem: true,},
  {s:'61CA31',v:'1057',c:'児発６３・責欠２・評価減・',baseItem: true,},
  {s:'61CA32',v:'870',c:'児発６３・責欠２・開所減１・',baseItem: true,},
  {s:'61CA33',v:'740',c:'児発６３・責欠２・開所減１・評価減・',baseItem: true,},
  {s:'61CA34',v:'1057',c:'児発６３・責欠２・開所減２・',baseItem: true,},
  {s:'61CA35',v:'898',c:'児発６３・責欠２・開所減２・評価減・',baseItem: true,},
  {s:'61CA36',v:'1320',c:'児発６４・責欠・',baseItem: true,},
  {s:'61CA37',v:'1122',c:'児発６４・責欠・評価減・',baseItem: true,},
  {s:'61CA38',v:'924',c:'児発６４・責欠・開所減１・',baseItem: true,},
  {s:'61CA39',v:'785',c:'児発６４・責欠・開所減１・評価減・',baseItem: true,},
  {s:'61CA40',v:'1122',c:'児発６４・責欠・開所減２・',baseItem: true,},
  {s:'61CA41',v:'954',c:'児発６４・責欠・開所減２・評価減・',baseItem: true,},
  {s:'61CA42',v:'943',c:'児発６４・責欠２・',baseItem: true,},
  {s:'61CA43',v:'802',c:'児発６４・責欠２・評価減・',baseItem: true,},
  {s:'61CA44',v:'660',c:'児発６４・責欠２・開所減１・',baseItem: true,},
  {s:'61CA45',v:'561',c:'児発６４・責欠２・開所減１・評価減・',baseItem: true,},
  {s:'61CA46',v:'802',c:'児発６４・責欠２・開所減２・',baseItem: true,},
  {s:'61CA47',v:'682',c:'児発６４・責欠２・開所減２・評価減・',baseItem: true,},
  {s:'61CA48',v:'1129',c:'児発６５・責欠・',baseItem: true,},
  {s:'61CA49',v:'960',c:'児発６５・責欠・評価減・',baseItem: true,},
  {s:'61CA50',v:'790',c:'児発６５・責欠・開所減１・',baseItem: true,},
  {s:'61CA51',v:'672',c:'児発６５・責欠・開所減１・評価減・',baseItem: true,},
  {s:'61CA52',v:'960',c:'児発６５・責欠・開所減２・',baseItem: true,},
  {s:'61CA53',v:'816',c:'児発６５・責欠・開所減２・評価減・',baseItem: true,},
  {s:'61CA54',v:'807',c:'児発６５・責欠２・',baseItem: true,},
  {s:'61CA55',v:'686',c:'児発６５・責欠２・評価減・',baseItem: true,},
  {s:'61CA56',v:'565',c:'児発６５・責欠２・開所減１・',baseItem: true,},
  {s:'61CA57',v:'480',c:'児発６５・責欠２・開所減１・評価減・',baseItem: true,},
  {s:'61CA58',v:'686',c:'児発６５・責欠２・開所減２・',baseItem: true,},
  {s:'61CA59',v:'583',c:'児発６５・責欠２・開所減２・評価減・',baseItem: true,},
  {s:'61CA60',v:'1040',c:'児発６６・責欠・',baseItem: true,},
  {s:'61CA61',v:'884',c:'児発６６・責欠・評価減・',baseItem: true,},
  {s:'61CA62',v:'728',c:'児発６６・責欠・開所減１・',baseItem: true,},
  {s:'61CA63',v:'619',c:'児発６６・責欠・開所減１・評価減・',baseItem: true,},
  {s:'61CA64',v:'884',c:'児発６６・責欠・開所減２・',baseItem: true,},
  {s:'61CA65',v:'751',c:'児発６６・責欠・開所減２・評価減・',baseItem: true,},
  {s:'61CA66',v:'743',c:'児発６６・責欠２・',baseItem: true,},
  {s:'61CA67',v:'632',c:'児発６６・責欠２・評価減・',baseItem: true,},
  {s:'61CA68',v:'520',c:'児発６６・責欠２・開所減１・',baseItem: true,},
  {s:'61CA69',v:'442',c:'児発６６・責欠２・開所減１・評価減・',baseItem: true,},
  {s:'61CA70',v:'632',c:'児発６６・責欠２・開所減２・',baseItem: true,},
  {s:'61CA71',v:'537',c:'児発６６・責欠２・開所減２・評価減・',baseItem: true,},
  {s:'61CA72',v:'1086',c:'児発６７・責欠・',baseItem: true,},
  {s:'61CA73',v:'923',c:'児発６７・責欠・評価減・',baseItem: true,},
  {s:'61CA74',v:'760',c:'児発６７・責欠・開所減１・',baseItem: true,},
  {s:'61CA75',v:'646',c:'児発６７・責欠・開所減１・評価減・',baseItem: true,},
  {s:'61CA76',v:'923',c:'児発６７・責欠・開所減２・',baseItem: true,},
  {s:'61CA77',v:'785',c:'児発６７・責欠・開所減２・評価減・',baseItem: true,},
  {s:'61CA78',v:'776',c:'児発６７・責欠２・',baseItem: true,},
  {s:'61CA79',v:'660',c:'児発６７・責欠２・評価減・',baseItem: true,},
  {s:'61CA80',v:'543',c:'児発６７・責欠２・開所減１・',baseItem: true,},
  {s:'61CA81',v:'462',c:'児発６７・責欠２・開所減１・評価減・',baseItem: true,},
  {s:'61CA82',v:'660',c:'児発６７・責欠２・開所減２・',baseItem: true,},
  {s:'61CA83',v:'561',c:'児発６７・責欠２・開所減２・評価減・',baseItem: true,},
  {s:'61CA84',v:'896',c:'児発６８・責欠・',baseItem: true,},
  {s:'61CA85',v:'762',c:'児発６８・責欠・評価減・',baseItem: true,},
  {s:'61CA86',v:'627',c:'児発６８・責欠・開所減１・',baseItem: true,},
  {s:'61CA87',v:'533',c:'児発６８・責欠・開所減１・評価減・',baseItem: true,},
  {s:'61CA88',v:'762',c:'児発６８・責欠・開所減２・',baseItem: true,},
  {s:'61CA89',v:'648',c:'児発６８・責欠・開所減２・評価減・',baseItem: true,},
  {s:'61CA90',v:'640',c:'児発６８・責欠２・',baseItem: true,},
  {s:'61CA91',v:'544',c:'児発６８・責欠２・評価減・',baseItem: true,},
  {s:'61CA92',v:'448',c:'児発６８・責欠２・開所減１・',baseItem: true,},
  {s:'61CA93',v:'381',c:'児発６８・責欠２・開所減１・評価減・',baseItem: true,},
  {s:'61CA94',v:'544',c:'児発６８・責欠２・開所減２・',baseItem: true,},
  {s:'61CA95',v:'462',c:'児発６８・責欠２・開所減２・評価減・',baseItem: true,},
  {s:'61CA96',v:'807',c:'児発６９・責欠・',baseItem: true,},
  {s:'61CA97',v:'686',c:'児発６９・責欠・評価減・',baseItem: true,},
  {s:'61CA98',v:'565',c:'児発６９・責欠・開所減１・',baseItem: true,},
  {s:'61CA99',v:'480',c:'児発６９・責欠・開所減１・評価減・',baseItem: true,},
  {s:'61CB00',v:'686',c:'児発６９・責欠・開所減２・',baseItem: true,},
  {s:'61CB01',v:'583',c:'児発６９・責欠・開所減２・評価減・',baseItem: true,},
  {s:'61CB02',v:'577',c:'児発６９・責欠２・',baseItem: true,},
  {s:'61CB03',v:'490',c:'児発６９・責欠２・評価減・',baseItem: true,},
  {s:'61CB04',v:'404',c:'児発６９・責欠２・開所減１・',baseItem: true,},
  {s:'61CB05',v:'343',c:'児発６９・責欠２・開所減１・評価減・',baseItem: true,},
  {s:'61CB06',v:'490',c:'児発６９・責欠２・開所減２・',baseItem: true,},
  {s:'61CB07',v:'417',c:'児発６９・責欠２・開所減２・評価減・',baseItem: true,},
  {s:'61C001',v:'620',c:'児発１・責欠・',baseItem: true,},
  {s:'61C003',v:'527',c:'児発１・責欠・評価減・',baseItem: true,},
  {s:'61C005',v:'434',c:'児発１・責欠・開所減１・',baseItem: true,},
  {s:'61C007',v:'369',c:'児発１・責欠・開所減１・評価減・',baseItem: true,},
  {s:'61C009',v:'527',c:'児発１・責欠・開所減２・',baseItem: true,},
  {s:'61C011',v:'448',c:'児発１・責欠・開所減２・評価減・',baseItem: true,},
  {s:'61C037',v:'443',c:'児発１・責欠２・',baseItem: true,},
  {s:'61C039',v:'377',c:'児発１・責欠２・評価減・',baseItem: true,},
  {s:'61C041',v:'310',c:'児発１・責欠２・開所減１・',baseItem: true,},
  {s:'61C043',v:'264',c:'児発１・責欠２・開所減１・評価減・',baseItem: true,},
  {s:'61C045',v:'377',c:'児発１・責欠２・開所減２・',baseItem: true,},
  {s:'61C047',v:'320',c:'児発１・責欠２・開所減２・評価減・',baseItem: true,},
  {s:'61C151',v:'429',c:'児発２・責欠・',baseItem: true,},
  {s:'61C153',v:'365',c:'児発２・責欠・評価減・',baseItem: true,},
  {s:'61C155',v:'300',c:'児発２・責欠・開所減１・',baseItem: true,},
  {s:'61C157',v:'255',c:'児発２・責欠・開所減１・評価減・',baseItem: true,},
  {s:'61C159',v:'365',c:'児発２・責欠・開所減２・',baseItem: true,},
  {s:'61C161',v:'310',c:'児発２・責欠・開所減２・評価減・',baseItem: true,},
  {s:'61C187',v:'307',c:'児発２・責欠２・',baseItem: true,},
  {s:'61C189',v:'261',c:'児発２・責欠２・評価減・',baseItem: true,},
  {s:'61C191',v:'215',c:'児発２・責欠２・開所減１・',baseItem: true,},
  {s:'61C193',v:'183',c:'児発２・責欠２・開所減１・評価減・',baseItem: true,},
  {s:'61C195',v:'261',c:'児発２・責欠２・開所減２・',baseItem: true,},
  {s:'61C197',v:'222',c:'児発２・責欠２・開所減２・評価減・',baseItem: true,},
  {s:'61C301',v:'340',c:'児発３・責欠・',baseItem: true,},
  {s:'61C303',v:'289',c:'児発３・責欠・評価減・',baseItem: true,},
  {s:'61C305',v:'238',c:'児発３・責欠・開所減１・',baseItem: true,},
  {s:'61C307',v:'202',c:'児発３・責欠・開所減１・評価減・',baseItem: true,},
  {s:'61C309',v:'289',c:'児発３・責欠・開所減２・',baseItem: true,},
  {s:'61C311',v:'246',c:'児発３・責欠・開所減２・評価減・',baseItem: true,},
  {s:'61C337',v:'243',c:'児発３・責欠２・',baseItem: true,},
  {s:'61C339',v:'207',c:'児発３・責欠２・評価減・',baseItem: true,},
  {s:'61C341',v:'170',c:'児発３・責欠２・開所減１・',baseItem: true,},
  {s:'61C343',v:'145',c:'児発３・責欠２・開所減１・評価減・',baseItem: true,},
  {s:'61C345',v:'207',c:'児発３・責欠２・開所減２・',baseItem: true,},
  {s:'61C347',v:'176',c:'児発３・責欠２・開所減２・評価減・',baseItem: true,},
  {s:'61CB08',v:'1928',c:'児発７０・責欠・',baseItem: true,},
  {s:'61CB09',v:'1639',c:'児発７０・責欠・評価減・',baseItem: true,},
  {s:'61CB10',v:'1350',c:'児発７０・責欠・開所減１・',baseItem: true,},
  {s:'61CB11',v:'1148',c:'児発７０・責欠・開所減１・評価減・',baseItem: true,},
  {s:'61CB12',v:'1639',c:'児発７０・責欠・開所減２・',baseItem: true,},
  {s:'61CB13',v:'1393',c:'児発７０・責欠・開所減２・評価減・',baseItem: true,},
  {s:'61CB14',v:'1377',c:'児発７０・責欠２・',baseItem: true,},
  {s:'61CB15',v:'1170',c:'児発７０・責欠２・評価減・',baseItem: true,},
  {s:'61CB16',v:'964',c:'児発７０・責欠２・開所減１・',baseItem: true,},
  {s:'61CB17',v:'819',c:'児発７０・責欠２・開所減１・評価減・',baseItem: true,},
  {s:'61CB18',v:'1170',c:'児発７０・責欠２・開所減２・',baseItem: true,},
  {s:'61CB19',v:'995',c:'児発７０・責欠２・開所減２・評価減・',baseItem: true,},
  {s:'61CB20',v:'1759',c:'児発７１・責欠・',baseItem: true,},
  {s:'61CB21',v:'1495',c:'児発７１・責欠・評価減・',baseItem: true,},
  {s:'61CB22',v:'1231',c:'児発７１・責欠・開所減１・',baseItem: true,},
  {s:'61CB23',v:'1046',c:'児発７１・責欠・開所減１・評価減・',baseItem: true,},
  {s:'61CB24',v:'1495',c:'児発７１・責欠・開所減２・',baseItem: true,},
  {s:'61CB25',v:'1271',c:'児発７１・責欠・開所減２・評価減・',baseItem: true,},
  {s:'61CB26',v:'1257',c:'児発７１・責欠２・',baseItem: true,},
  {s:'61CB27',v:'1068',c:'児発７１・責欠２・評価減・',baseItem: true,},
  {s:'61CB28',v:'880',c:'児発７１・責欠２・開所減１・',baseItem: true,},
  {s:'61CB29',v:'748',c:'児発７１・責欠２・開所減１・評価減・',baseItem: true,},
  {s:'61CB30',v:'1068',c:'児発７１・責欠２・開所減２・',baseItem: true,},
  {s:'61CB31',v:'908',c:'児発７１・責欠２・開所減２・評価減・',baseItem: true,},
  {s:'61CB32',v:'1683',c:'児発７２・責欠・',baseItem: true,},
  {s:'61CB33',v:'1431',c:'児発７２・責欠・評価減・',baseItem: true,},
  {s:'61CB34',v:'1178',c:'児発７２・責欠・開所減１・',baseItem: true,},
  {s:'61CB35',v:'1001',c:'児発７２・責欠・開所減１・評価減・',baseItem: true,},
  {s:'61CB36',v:'1431',c:'児発７２・責欠・開所減２・',baseItem: true,},
  {s:'61CB37',v:'1216',c:'児発７２・責欠・開所減２・評価減・',baseItem: true,},
  {s:'61CB38',v:'1202',c:'児発７２・責欠２・',baseItem: true,},
  {s:'61CB39',v:'1022',c:'児発７２・責欠２・評価減・',baseItem: true,},
  {s:'61CB40',v:'841',c:'児発７２・責欠２・開所減１・',baseItem: true,},
  {s:'61CB41',v:'715',c:'児発７２・責欠２・開所減１・評価減・',baseItem: true,},
  {s:'61CB42',v:'1022',c:'児発７２・責欠２・開所減２・',baseItem: true,},
  {s:'61CB43',v:'869',c:'児発７２・責欠２・開所減２・評価減・',baseItem: true,},
  {s:'61CB44',v:'1228',c:'児発７３・責欠・',baseItem: true,},
  {s:'61CB45',v:'1044',c:'児発７３・責欠・評価減・',baseItem: true,},
  {s:'61CB46',v:'860',c:'児発７３・責欠・開所減１・',baseItem: true,},
  {s:'61CB47',v:'731',c:'児発７３・責欠・開所減１・評価減・',baseItem: true,},
  {s:'61CB48',v:'1044',c:'児発７３・責欠・開所減２・',baseItem: true,},
  {s:'61CB49',v:'887',c:'児発７３・責欠・開所減２・評価減・',baseItem: true,},
  {s:'61CB50',v:'877',c:'児発７３・責欠２・',baseItem: true,},
  {s:'61CB51',v:'745',c:'児発７３・責欠２・評価減・',baseItem: true,},
  {s:'61CB52',v:'614',c:'児発７３・責欠２・開所減１・',baseItem: true,},
  {s:'61CB53',v:'522',c:'児発７３・責欠２・開所減１・評価減・',baseItem: true,},
  {s:'61CB54',v:'745',c:'児発７３・責欠２・開所減２・',baseItem: true,},
  {s:'61CB55',v:'633',c:'児発７３・責欠２・開所減２・評価減・',baseItem: true,},
  {s:'61CB56',v:'1059',c:'児発７４・責欠・',baseItem: true,},
  {s:'61CB57',v:'900',c:'児発７４・責欠・評価減・',baseItem: true,},
  {s:'61CB58',v:'741',c:'児発７４・責欠・開所減１・',baseItem: true,},
  {s:'61CB59',v:'630',c:'児発７４・責欠・開所減１・評価減・',baseItem: true,},
  {s:'61CB60',v:'900',c:'児発７４・責欠・開所減２・',baseItem: true,},
  {s:'61CB61',v:'765',c:'児発７４・責欠・開所減２・評価減・',baseItem: true,},
  {s:'61CB62',v:'757',c:'児発７４・責欠２・',baseItem: true,},
  {s:'61CB63',v:'643',c:'児発７４・責欠２・評価減・',baseItem: true,},
  {s:'61CB64',v:'530',c:'児発７４・責欠２・開所減１・',baseItem: true,},
  {s:'61CB65',v:'451',c:'児発７４・責欠２・開所減１・評価減・',baseItem: true,},
  {s:'61CB66',v:'643',c:'児発７４・責欠２・開所減２・',baseItem: true,},
  {s:'61CB67',v:'547',c:'児発７４・責欠２・開所減２・評価減・',baseItem: true,},
  {s:'61CB68',v:'983',c:'児発７５・責欠・',baseItem: true,},
  {s:'61CB69',v:'836',c:'児発７５・責欠・評価減・',baseItem: true,},
  {s:'61CB70',v:'688',c:'児発７５・責欠・開所減１・',baseItem: true,},
  {s:'61CB71',v:'585',c:'児発７５・責欠・開所減１・評価減・',baseItem: true,},
  {s:'61CB72',v:'836',c:'児発７５・責欠・開所減２・',baseItem: true,},
  {s:'61CB73',v:'711',c:'児発７５・責欠・開所減２・評価減・',baseItem: true,},
  {s:'61CB74',v:'702',c:'児発７５・責欠２・',baseItem: true,},
  {s:'61CB75',v:'597',c:'児発７５・責欠２・評価減・',baseItem: true,},
  {s:'61CB76',v:'491',c:'児発７５・責欠２・開所減１・',baseItem: true,},
  {s:'61CB77',v:'417',c:'児発７５・責欠２・開所減１・評価減・',baseItem: true,},
  {s:'61CB78',v:'597',c:'児発７５・責欠２・開所減２・',baseItem: true,},
  {s:'61CB79',v:'507',c:'児発７５・責欠２・開所減２・評価減・',baseItem: true,},
  {s:'61CB80',v:'995',c:'児発７６・責欠・',baseItem: true,},
  {s:'61CB81',v:'846',c:'児発７６・責欠・評価減・',baseItem: true,},
  {s:'61CB82',v:'697',c:'児発７６・責欠・開所減１・',baseItem: true,},
  {s:'61CB83',v:'592',c:'児発７６・責欠・開所減１・評価減・',baseItem: true,},
  {s:'61CB84',v:'846',c:'児発７６・責欠・開所減２・',baseItem: true,},
  {s:'61CB85',v:'719',c:'児発７６・責欠・開所減２・評価減・',baseItem: true,},
  {s:'61CB86',v:'711',c:'児発７６・責欠２・',baseItem: true,},
  {s:'61CB87',v:'604',c:'児発７６・責欠２・評価減・',baseItem: true,},
  {s:'61CB88',v:'498',c:'児発７６・責欠２・開所減１・',baseItem: true,},
  {s:'61CB89',v:'423',c:'児発７６・責欠２・開所減１・評価減・',baseItem: true,},
  {s:'61CB90',v:'604',c:'児発７６・責欠２・開所減２・',baseItem: true,},
  {s:'61CB91',v:'513',c:'児発７６・責欠２・開所減２・評価減・',baseItem: true,},
  {s:'61CB92',v:'826',c:'児発７７・責欠・',baseItem: true,},
  {s:'61CB93',v:'702',c:'児発７７・責欠・評価減・',baseItem: true,},
  {s:'61CB94',v:'578',c:'児発７７・責欠・開所減１・',baseItem: true,},
  {s:'61CB95',v:'491',c:'児発７７・責欠・開所減１・評価減・',baseItem: true,},
  {s:'61CB96',v:'702',c:'児発７７・責欠・開所減２・',baseItem: true,},
  {s:'61CB97',v:'597',c:'児発７７・責欠・開所減２・評価減・',baseItem: true,},
  {s:'61CB98',v:'590',c:'児発７７・責欠２・',baseItem: true,},
  {s:'61CB99',v:'502',c:'児発７７・責欠２・評価減・',baseItem: true,},
  {s:'61CC00',v:'413',c:'児発７７・責欠２・開所減１・',baseItem: true,},
  {s:'61CC01',v:'351',c:'児発７７・責欠２・開所減１・評価減・',baseItem: true,},
  {s:'61CC02',v:'502',c:'児発７７・責欠２・開所減２・',baseItem: true,},
  {s:'61CC03',v:'427',c:'児発７７・責欠２・開所減２・評価減・',baseItem: true,},
  {s:'61CC04',v:'750',c:'児発７８・責欠・',baseItem: true,},
  {s:'61CC05',v:'638',c:'児発７８・責欠・評価減・',baseItem: true,},
  {s:'61CC06',v:'525',c:'児発７８・責欠・開所減１・',baseItem: true,},
  {s:'61CC07',v:'446',c:'児発７８・責欠・開所減１・評価減・',baseItem: true,},
  {s:'61CC08',v:'638',c:'児発７８・責欠・開所減２・',baseItem: true,},
  {s:'61CC09',v:'542',c:'児発７８・責欠・開所減２・評価減・',baseItem: true,},
  {s:'61CC10',v:'536',c:'児発７８・責欠２・',baseItem: true,},
  {s:'61CC11',v:'456',c:'児発７８・責欠２・評価減・',baseItem: true,},
  {s:'61CC12',v:'375',c:'児発７８・責欠２・開所減１・',baseItem: true,},
  {s:'61CC13',v:'319',c:'児発７８・責欠２・開所減１・評価減・',baseItem: true,},
  {s:'61CC14',v:'456',c:'児発７８・責欠２・開所減２・',baseItem: true,},
  {s:'61CC15',v:'388',c:'児発７８・責欠２・開所減２・評価減・',baseItem: true,},
  {s:'61C451',v:'528',c:'児発４・責欠・',baseItem: true,},
  {s:'61C453',v:'449',c:'児発４・責欠・評価減・',baseItem: true,},
  {s:'61C455',v:'370',c:'児発４・責欠・開所減１・',baseItem: true,},
  {s:'61C457',v:'315',c:'児発４・責欠・開所減１・評価減・',baseItem: true,},
  {s:'61C459',v:'449',c:'児発４・責欠・開所減２・',baseItem: true,},
  {s:'61C461',v:'382',c:'児発４・責欠・開所減２・評価減・',baseItem: true,},
  {s:'61C487',v:'377',c:'児発４・責欠２・',baseItem: true,},
  {s:'61C489',v:'320',c:'児発４・責欠２・評価減・',baseItem: true,},
  {s:'61C491',v:'264',c:'児発４・責欠２・開所減１・',baseItem: true,},
  {s:'61C493',v:'224',c:'児発４・責欠２・開所減１・評価減・',baseItem: true,},
  {s:'61C495',v:'320',c:'児発４・責欠２・開所減２・',baseItem: true,},
  {s:'61C497',v:'272',c:'児発４・責欠２・開所減２・評価減・',baseItem: true,},
  {s:'61C601',v:'359',c:'児発５・責欠・',baseItem: true,},
  {s:'61C603',v:'305',c:'児発５・責欠・評価減・',baseItem: true,},
  {s:'61C605',v:'251',c:'児発５・責欠・開所減１・',baseItem: true,},
  {s:'61C607',v:'213',c:'児発５・責欠・開所減１・評価減・',baseItem: true,},
  {s:'61C609',v:'305',c:'児発５・責欠・開所減２・',baseItem: true,},
  {s:'61C611',v:'259',c:'児発５・責欠・開所減２・評価減・',baseItem: true,},
  {s:'61C637',v:'257',c:'児発５・責欠２・',baseItem: true,},
  {s:'61C639',v:'218',c:'児発５・責欠２・評価減・',baseItem: true,},
  {s:'61C641',v:'180',c:'児発５・責欠２・開所減１・',baseItem: true,},
  {s:'61C643',v:'153',c:'児発５・責欠２・開所減１・評価減・',baseItem: true,},
  {s:'61C645',v:'218',c:'児発５・責欠２・開所減２・',baseItem: true,},
  {s:'61C647',v:'185',c:'児発５・責欠２・開所減２・評価減・',baseItem: true,},
  {s:'61C751',v:'283',c:'児発６・責欠・',baseItem: true,},
  {s:'61C753',v:'241',c:'児発６・責欠・評価減・',baseItem: true,},
  {s:'61C755',v:'198',c:'児発６・責欠・開所減１・',baseItem: true,},
  {s:'61C757',v:'168',c:'児発６・責欠・開所減１・評価減・',baseItem: true,},
  {s:'61C759',v:'241',c:'児発６・責欠・開所減２・',baseItem: true,},
  {s:'61C761',v:'205',c:'児発６・責欠・開所減２・評価減・',baseItem: true,},
  {s:'61C787',v:'202',c:'児発６・責欠２・',baseItem: true,},
  {s:'61C789',v:'172',c:'児発６・責欠２・評価減・',baseItem: true,},
  {s:'61C791',v:'141',c:'児発６・責欠２・開所減１・',baseItem: true,},
  {s:'61C793',v:'120',c:'児発６・責欠２・開所減１・評価減・',baseItem: true,},
  {s:'61C795',v:'172',c:'児発６・責欠２・開所減２・',baseItem: true,},
  {s:'61C797',v:'146',c:'児発６・責欠２・開所減２・評価減・',baseItem: true,},
  {s:'61C901',v:'491',c:'基準該当児発Ⅰ・責欠・',baseItem: true,},
  {s:'61C902',v:'417',c:'基準該当児発Ⅰ・責欠・評価減・',baseItem: true,},
  {s:'61C903',v:'344',c:'基準該当児発Ⅰ・責欠・開所減１・',baseItem: true,},
  {s:'61C904',v:'292',c:'基準該当児発Ⅰ・責欠・開所減１・評価減・',baseItem: true,},
  {s:'61C905',v:'417',c:'基準該当児発Ⅰ・責欠・開所減２・',baseItem: true,},
  {s:'61C906',v:'354',c:'基準該当児発Ⅰ・責欠・開所減２・評価減・',baseItem: true,},
  {s:'61C919',v:'351',c:'基準該当児発Ⅰ・責欠２・',baseItem: true,},
  {s:'61C920',v:'298',c:'基準該当児発Ⅰ・責欠２・評価減・',baseItem: true,},
  {s:'61C921',v:'246',c:'基準該当児発Ⅰ・責欠２・開所減１・',baseItem: true,},
  {s:'61C922',v:'209',c:'基準該当児発Ⅰ・責欠２・開所減１・評価減・',baseItem: true,},
  {s:'61C923',v:'298',c:'基準該当児発Ⅰ・責欠２・開所減２・',baseItem: true,},
  {s:'61C924',v:'253',c:'基準該当児発Ⅰ・責欠２・開所減２・評価減・',baseItem: true,},
];

export const jihatsuKasan = [
  {s:'61ZZ01',v:0.1,c:'令和３年９月３０日までの上乗せ分（児発）・',name:'新型コロナウイルス感染症への対応',value:'1',opt:'',limit:'',syoguu:'',tokubetsu:'1' },
  {s:'61Z001',v:'-5',c:'児発身体拘束廃止未実施減算・',name:'身体拘束廃止未実施減算',value:'1',opt:'',limit:'',syoguu:'',},
  {s:'615500',v:'603',c:'児発人工内耳装用児支援加算１・',name:'人工内耳装用児支援加算',value:'1',opt:'n20',limit:'',syoguu:'',},
  {s:'615501',v:'531',c:'児発人工内耳装用児支援加算２・',name:'人工内耳装用児支援加算',value:'1',opt:'n30',limit:'',syoguu:'',},
  {s:'615502',v:'488',c:'児発人工内耳装用児支援加算３・',name:'人工内耳装用児支援加算',value:'1',opt:'n40',limit:'',syoguu:'',},
  {s:'615503',v:'445',c:'児発人工内耳装用児支援加算４・',name:'人工内耳装用児支援加算',value:'1',opt:'n200',limit:'',syoguu:'',},
  {s:'614000',v:'62',c:'児発児童指導員等加配加算Ⅰ１・１・',name:'児童指導員等加配加算（Ⅰ）',value:'理学療法士等',opt:'n30,センター,障害児',limit:'',syoguu:'',},
  {s:'614001',v:'53',c:'児発児童指導員等加配加算Ⅰ１・２・',name:'児童指導員等加配加算（Ⅰ）',value:'理学療法士等',opt:'n40,センター,障害児',limit:'',syoguu:'',},
  {s:'614002',v:'42',c:'児発児童指導員等加配加算Ⅰ１・３・',name:'児童指導員等加配加算（Ⅰ）',value:'理学療法士等',opt:'n50,センター,障害児',limit:'',syoguu:'',},
  {s:'614003',v:'34',c:'児発児童指導員等加配加算Ⅰ１・４・',name:'児童指導員等加配加算（Ⅰ）',value:'理学療法士等',opt:'n60,センター,障害児',limit:'',syoguu:'',},
  {s:'614004',v:'29',c:'児発児童指導員等加配加算Ⅰ１・５・',name:'児童指導員等加配加算（Ⅰ）',value:'理学療法士等',opt:'n70,センター,障害児',limit:'',syoguu:'',},
  {s:'614005',v:'25',c:'児発児童指導員等加配加算Ⅰ１・６・',name:'児童指導員等加配加算（Ⅰ）',value:'理学療法士等',opt:'n80,センター,障害児',limit:'',syoguu:'',},
  {s:'614006',v:'22',c:'児発児童指導員等加配加算Ⅰ１・７・',name:'児童指導員等加配加算（Ⅰ）',value:'理学療法士等',opt:'n200,センター,障害児',limit:'',syoguu:'',},
  {s:'614007',v:'93',c:'児発児童指導員等加配加算Ⅰ１・８・',name:'児童指導員等加配加算（Ⅰ）',value:'理学療法士等',opt:'n20,センター,難聴児',limit:'',syoguu:'',},
  {s:'614008',v:'75',c:'児発児童指導員等加配加算Ⅰ１・９・',name:'児童指導員等加配加算（Ⅰ）',value:'理学療法士等',opt:'n30,センター,難聴児',limit:'',syoguu:'',},
  {s:'614009',v:'53',c:'児発児童指導員等加配加算Ⅰ１・１０・',name:'児童指導員等加配加算（Ⅰ）',value:'理学療法士等',opt:'n40,センター,難聴児',limit:'',syoguu:'',},
  {s:'614010',v:'42',c:'児発児童指導員等加配加算Ⅰ１・１１・',name:'児童指導員等加配加算（Ⅰ）',value:'理学療法士等',opt:'n200,センター,難聴児',limit:'',syoguu:'',},
  {s:'614011',v:'93',c:'児発児童指導員等加配加算Ⅰ１・１２・',name:'児童指導員等加配加算（Ⅰ）',value:'理学療法士等',opt:'n15,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'614012',v:'93',c:'児発児童指導員等加配加算Ⅰ１・１３・',name:'児童指導員等加配加算（Ⅰ）',value:'理学療法士等',opt:'n20,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'614013',v:'75',c:'児発児童指導員等加配加算Ⅰ１・１４・',name:'児童指導員等加配加算（Ⅰ）',value:'理学療法士等',opt:'n200,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'614014',v:'187',c:'児発児童指導員等加配加算Ⅰ１・１５・',name:'児童指導員等加配加算（Ⅰ）',value:'理学療法士等',opt:'n10,事業所,障害児,主に未就学児',limit:'',syoguu:'',},
  {s:'614015',v:'125',c:'児発児童指導員等加配加算Ⅰ１・１６・',name:'児童指導員等加配加算（Ⅰ）',value:'理学療法士等',opt:'n20,事業所,障害児,主に未就学児',limit:'',syoguu:'',},
  {s:'614016',v:'75',c:'児発児童指導員等加配加算Ⅰ１・１７・',name:'児童指導員等加配加算（Ⅰ）',value:'理学療法士等',opt:'n200,事業所,障害児,主に未就学児',limit:'',syoguu:'',},
  {s:'614017',v:'187',c:'児発児童指導員等加配加算Ⅰ１・１８・',name:'児童指導員等加配加算（Ⅰ）',value:'理学療法士等',opt:'n10,事業所,障害児,主に未就学児以外',limit:'',syoguu:'',},
  {s:'614018',v:'125',c:'児発児童指導員等加配加算Ⅰ１・１９・',name:'児童指導員等加配加算（Ⅰ）',value:'理学療法士等',opt:'n20,事業所,障害児,主に未就学児以外',limit:'',syoguu:'',},
  {s:'614019',v:'75',c:'児発児童指導員等加配加算Ⅰ１・２０・',name:'児童指導員等加配加算（Ⅰ）',value:'理学療法士等',opt:'n200,事業所,障害児,主に未就学児以外',limit:'',syoguu:'',},
  {s:'614020',v:'374',c:'児発児童指導員等加配加算Ⅰ１・２１・',name:'児童指導員等加配加算（Ⅰ）',value:'理学療法士等',opt:'n5,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'614021',v:'312',c:'児発児童指導員等加配加算Ⅰ１・２２・',name:'児童指導員等加配加算（Ⅰ）',value:'理学療法士等',opt:'n6,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'614022',v:'267',c:'児発児童指導員等加配加算Ⅰ１・２３・',name:'児童指導員等加配加算（Ⅰ）',value:'理学療法士等',opt:'n7,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'614023',v:'234',c:'児発児童指導員等加配加算Ⅰ１・２４・',name:'児童指導員等加配加算（Ⅰ）',value:'理学療法士等',opt:'n8,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'614024',v:'208',c:'児発児童指導員等加配加算Ⅰ１・２５・',name:'児童指導員等加配加算（Ⅰ）',value:'理学療法士等',opt:'n9,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'614025',v:'187',c:'児発児童指導員等加配加算Ⅰ１・２６・',name:'児童指導員等加配加算（Ⅰ）',value:'理学療法士等',opt:'n10,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'614026',v:'125',c:'児発児童指導員等加配加算Ⅰ１・２７・',name:'児童指導員等加配加算（Ⅰ）',value:'理学療法士等',opt:'n200,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'614030',v:'41',c:'児発児童指導員等加配加算Ⅰ２・１・',name:'児童指導員等加配加算（Ⅰ）',value:'児童指導員',opt:'n30,センター,障害児',limit:'',syoguu:'',},
  {s:'614031',v:'35',c:'児発児童指導員等加配加算Ⅰ２・２・',name:'児童指導員等加配加算（Ⅰ）',value:'児童指導員',opt:'n40,センター,障害児',limit:'',syoguu:'',},
  {s:'614032',v:'27',c:'児発児童指導員等加配加算Ⅰ２・３・',name:'児童指導員等加配加算（Ⅰ）',value:'児童指導員',opt:'n50,センター,障害児',limit:'',syoguu:'',},
  {s:'614033',v:'22',c:'児発児童指導員等加配加算Ⅰ２・４・',name:'児童指導員等加配加算（Ⅰ）',value:'児童指導員',opt:'n60,センター,障害児',limit:'',syoguu:'',},
  {s:'614034',v:'19',c:'児発児童指導員等加配加算Ⅰ２・５・',name:'児童指導員等加配加算（Ⅰ）',value:'児童指導員',opt:'n70,センター,障害児',limit:'',syoguu:'',},
  {s:'614035',v:'16',c:'児発児童指導員等加配加算Ⅰ２・６・',name:'児童指導員等加配加算（Ⅰ）',value:'児童指導員',opt:'n80,センター,障害児',limit:'',syoguu:'',},
  {s:'614036',v:'15',c:'児発児童指導員等加配加算Ⅰ２・７・',name:'児童指導員等加配加算（Ⅰ）',value:'児童指導員',opt:'n200,センター,障害児',limit:'',syoguu:'',},
  {s:'614037',v:'62',c:'児発児童指導員等加配加算Ⅰ２・８・',name:'児童指導員等加配加算（Ⅰ）',value:'児童指導員',opt:'n20,センター,難聴児',limit:'',syoguu:'',},
  {s:'614038',v:'49',c:'児発児童指導員等加配加算Ⅰ２・９・',name:'児童指導員等加配加算（Ⅰ）',value:'児童指導員',opt:'n30,センター,難聴児',limit:'',syoguu:'',},
  {s:'614039',v:'35',c:'児発児童指導員等加配加算Ⅰ２・１０・',name:'児童指導員等加配加算（Ⅰ）',value:'児童指導員',opt:'n40,センター,難聴児',limit:'',syoguu:'',},
  {s:'614040',v:'27',c:'児発児童指導員等加配加算Ⅰ２・１１・',name:'児童指導員等加配加算（Ⅰ）',value:'児童指導員',opt:'n200,センター,難聴児',limit:'',syoguu:'',},
  {s:'614041',v:'62',c:'児発児童指導員等加配加算Ⅰ２・１２・',name:'児童指導員等加配加算（Ⅰ）',value:'児童指導員',opt:'n15,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'614042',v:'62',c:'児発児童指導員等加配加算Ⅰ２・１３・',name:'児童指導員等加配加算（Ⅰ）',value:'児童指導員',opt:'n20,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'614043',v:'49',c:'児発児童指導員等加配加算Ⅰ２・１４・',name:'児童指導員等加配加算（Ⅰ）',value:'児童指導員',opt:'n200,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'614044',v:'123',c:'児発児童指導員等加配加算Ⅰ２・１５・',name:'児童指導員等加配加算（Ⅰ）',value:'児童指導員',opt:'n10,事業所,障害児,主に未就学児',limit:'',syoguu:'',},
  {s:'614045',v:'82',c:'児発児童指導員等加配加算Ⅰ２・１６・',name:'児童指導員等加配加算（Ⅰ）',value:'児童指導員',opt:'n20,事業所,障害児,主に未就学児',limit:'',syoguu:'',},
  {s:'614046',v:'49',c:'児発児童指導員等加配加算Ⅰ２・１７・',name:'児童指導員等加配加算（Ⅰ）',value:'児童指導員',opt:'n200,事業所,障害児,主に未就学児',limit:'',syoguu:'',},
  {s:'614047',v:'123',c:'児発児童指導員等加配加算Ⅰ２・１８・',name:'児童指導員等加配加算（Ⅰ）',value:'児童指導員',opt:'n10,事業所,障害児,主に未就学児以外',limit:'',syoguu:'',},
  {s:'614048',v:'82',c:'児発児童指導員等加配加算Ⅰ２・１９・',name:'児童指導員等加配加算（Ⅰ）',value:'児童指導員',opt:'n20,事業所,障害児,主に未就学児以外',limit:'',syoguu:'',},
  {s:'614049',v:'49',c:'児発児童指導員等加配加算Ⅰ２・２０・',name:'児童指導員等加配加算（Ⅰ）',value:'児童指導員',opt:'n200,事業所,障害児,主に未就学児以外',limit:'',syoguu:'',},
  {s:'614050',v:'247',c:'児発児童指導員等加配加算Ⅰ２・２１・',name:'児童指導員等加配加算（Ⅰ）',value:'児童指導員',opt:'n5,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'614051',v:'206',c:'児発児童指導員等加配加算Ⅰ２・２２・',name:'児童指導員等加配加算（Ⅰ）',value:'児童指導員',opt:'n6,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'614052',v:'176',c:'児発児童指導員等加配加算Ⅰ２・２３・',name:'児童指導員等加配加算（Ⅰ）',value:'児童指導員',opt:'n7,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'614053',v:'154',c:'児発児童指導員等加配加算Ⅰ２・２４・',name:'児童指導員等加配加算（Ⅰ）',value:'児童指導員',opt:'n8,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'614054',v:'137',c:'児発児童指導員等加配加算Ⅰ２・２５・',name:'児童指導員等加配加算（Ⅰ）',value:'児童指導員',opt:'n9,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'614055',v:'123',c:'児発児童指導員等加配加算Ⅰ２・２６・',name:'児童指導員等加配加算（Ⅰ）',value:'児童指導員',opt:'n10,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'614056',v:'82',c:'児発児童指導員等加配加算Ⅰ２・２７・',name:'児童指導員等加配加算（Ⅰ）',value:'児童指導員',opt:'n200,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'614060',v:'30',c:'児発児童指導員等加配加算Ⅰ３・１・',name:'児童指導員等加配加算（Ⅰ）',value:'その他',opt:'n30,センター,障害児',limit:'',syoguu:'',},
  {s:'614061',v:'26',c:'児発児童指導員等加配加算Ⅰ３・２・',name:'児童指導員等加配加算（Ⅰ）',value:'その他',opt:'n40,センター,障害児',limit:'',syoguu:'',},
  {s:'614062',v:'20',c:'児発児童指導員等加配加算Ⅰ３・３・',name:'児童指導員等加配加算（Ⅰ）',value:'その他',opt:'n50,センター,障害児',limit:'',syoguu:'',},
  {s:'614063',v:'16',c:'児発児童指導員等加配加算Ⅰ３・４・',name:'児童指導員等加配加算（Ⅰ）',value:'その他',opt:'n60,センター,障害児',limit:'',syoguu:'',},
  {s:'614064',v:'14',c:'児発児童指導員等加配加算Ⅰ３・５・',name:'児童指導員等加配加算（Ⅰ）',value:'その他',opt:'n70,センター,障害児',limit:'',syoguu:'',},
  {s:'614065',v:'12',c:'児発児童指導員等加配加算Ⅰ３・６・',name:'児童指導員等加配加算（Ⅰ）',value:'その他',opt:'n80,センター,障害児',limit:'',syoguu:'',},
  {s:'614066',v:'11',c:'児発児童指導員等加配加算Ⅰ３・７・',name:'児童指導員等加配加算（Ⅰ）',value:'その他',opt:'n200,センター,障害児',limit:'',syoguu:'',},
  {s:'614067',v:'45',c:'児発児童指導員等加配加算Ⅰ３・８・',name:'児童指導員等加配加算（Ⅰ）',value:'その他',opt:'n20,センター,難聴児',limit:'',syoguu:'',},
  {s:'614068',v:'36',c:'児発児童指導員等加配加算Ⅰ３・９・',name:'児童指導員等加配加算（Ⅰ）',value:'その他',opt:'n30,センター,難聴児',limit:'',syoguu:'',},
  {s:'614069',v:'26',c:'児発児童指導員等加配加算Ⅰ３・１０・',name:'児童指導員等加配加算（Ⅰ）',value:'その他',opt:'n40,センター,難聴児',limit:'',syoguu:'',},
  {s:'614070',v:'20',c:'児発児童指導員等加配加算Ⅰ３・１１・',name:'児童指導員等加配加算（Ⅰ）',value:'その他',opt:'n200,センター,難聴児',limit:'',syoguu:'',},
  {s:'614071',v:'45',c:'児発児童指導員等加配加算Ⅰ３・１２・',name:'児童指導員等加配加算（Ⅰ）',value:'その他',opt:'n15,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'614072',v:'45',c:'児発児童指導員等加配加算Ⅰ３・１３・',name:'児童指導員等加配加算（Ⅰ）',value:'その他',opt:'n20,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'614073',v:'36',c:'児発児童指導員等加配加算Ⅰ３・１４・',name:'児童指導員等加配加算（Ⅰ）',value:'その他',opt:'n200,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'614074',v:'90',c:'児発児童指導員等加配加算Ⅰ３・１５・',name:'児童指導員等加配加算（Ⅰ）',value:'その他',opt:'n10,事業所,障害児,主に未就学児',limit:'',syoguu:'',},
  {s:'614075',v:'60',c:'児発児童指導員等加配加算Ⅰ３・１６・',name:'児童指導員等加配加算（Ⅰ）',value:'その他',opt:'n20,事業所,障害児,主に未就学児',limit:'',syoguu:'',},
  {s:'614076',v:'36',c:'児発児童指導員等加配加算Ⅰ３・１７・',name:'児童指導員等加配加算（Ⅰ）',value:'その他',opt:'n200,事業所,障害児,主に未就学児',limit:'',syoguu:'',},
  {s:'614077',v:'90',c:'児発児童指導員等加配加算Ⅰ３・１８・',name:'児童指導員等加配加算（Ⅰ）',value:'その他',opt:'n10,事業所,障害児,主に未就学児以外',limit:'',syoguu:'',},
  {s:'614078',v:'60',c:'児発児童指導員等加配加算Ⅰ３・１９・',name:'児童指導員等加配加算（Ⅰ）',value:'その他',opt:'n20,事業所,障害児,主に未就学児以外',limit:'',syoguu:'',},
  {s:'614079',v:'36',c:'児発児童指導員等加配加算Ⅰ３・２０・',name:'児童指導員等加配加算（Ⅰ）',value:'その他',opt:'n200,事業所,障害児,主に未就学児以外',limit:'',syoguu:'',},
  {s:'614080',v:'180',c:'児発児童指導員等加配加算Ⅰ３・２１・',name:'児童指導員等加配加算（Ⅰ）',value:'その他',opt:'n5,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'614081',v:'150',c:'児発児童指導員等加配加算Ⅰ３・２２・',name:'児童指導員等加配加算（Ⅰ）',value:'その他',opt:'n6,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'614082',v:'129',c:'児発児童指導員等加配加算Ⅰ３・２３・',name:'児童指導員等加配加算（Ⅰ）',value:'その他',opt:'n7,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'614083',v:'113',c:'児発児童指導員等加配加算Ⅰ３・２４・',name:'児童指導員等加配加算（Ⅰ）',value:'その他',opt:'n8,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'614084',v:'100',c:'児発児童指導員等加配加算Ⅰ３・２５・',name:'児童指導員等加配加算（Ⅰ）',value:'その他',opt:'n9,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'614085',v:'90',c:'児発児童指導員等加配加算Ⅰ３・２６・',name:'児童指導員等加配加算（Ⅰ）',value:'その他',opt:'n10,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'614086',v:'60',c:'児発児童指導員等加配加算Ⅰ３・２７・',name:'児童指導員等加配加算（Ⅰ）',value:'その他',opt:'n200,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'616806',v:'62',c:'児発専門的支援加算（理学療法士）１・',name:'専門的支援加算',value:'理学療法士等',opt:'n30,センター,障害児',limit:'',syoguu:'',},
  {s:'616807',v:'53',c:'児発専門的支援加算（理学療法士）２・',name:'専門的支援加算',value:'理学療法士等',opt:'n40,センター,障害児',limit:'',syoguu:'',},
  {s:'616808',v:'42',c:'児発専門的支援加算（理学療法士）３・',name:'専門的支援加算',value:'理学療法士等',opt:'n50,センター,障害児',limit:'',syoguu:'',},
  {s:'616809',v:'34',c:'児発専門的支援加算（理学療法士）４・',name:'専門的支援加算',value:'理学療法士等',opt:'n60,センター,障害児',limit:'',syoguu:'',},
  {s:'616810',v:'29',c:'児発専門的支援加算（理学療法士）５・',name:'専門的支援加算',value:'理学療法士等',opt:'n70,センター,障害児',limit:'',syoguu:'',},
  {s:'616811',v:'25',c:'児発専門的支援加算（理学療法士）６・',name:'専門的支援加算',value:'理学療法士等',opt:'n80,センター,障害児',limit:'',syoguu:'',},
  {s:'616812',v:'22',c:'児発専門的支援加算（理学療法士）７・',name:'専門的支援加算',value:'理学療法士等',opt:'n200,センター,障害児',limit:'',syoguu:'',},
  {s:'616813',v:'93',c:'児発専門的支援加算（理学療法士）８・',name:'専門的支援加算',value:'理学療法士等',opt:'n20,センター,障害児',limit:'',syoguu:'',},
  {s:'616814',v:'75',c:'児発専門的支援加算（理学療法士）９・',name:'専門的支援加算',value:'理学療法士等',opt:'n30,センター,障害児',limit:'',syoguu:'',},
  {s:'616815',v:'53',c:'児発専門的支援加算（理学療法士）１０・',name:'専門的支援加算',value:'理学療法士等',opt:'n40,センター,障害児',limit:'',syoguu:'',},
  {s:'616816',v:'42',c:'児発専門的支援加算（理学療法士）１１・',name:'専門的支援加算',value:'理学療法士等',opt:'n200,センター,障害児',limit:'',syoguu:'',},
  {s:'616817',v:'93',c:'児発専門的支援加算（理学療法士）１２・',name:'専門的支援加算',value:'理学療法士等',opt:'n15,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'616818',v:'93',c:'児発専門的支援加算（理学療法士）１３・',name:'専門的支援加算',value:'理学療法士等',opt:'n20,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'616819',v:'75',c:'児発専門的支援加算（理学療法士）１４・',name:'専門的支援加算',value:'理学療法士等',opt:'n200,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'616820',v:'187',c:'児発専門的支援加算（理学療法士）１５・',name:'専門的支援加算',value:'理学療法士等',opt:'n10,事業所,障害児,主に未就学児',limit:'',syoguu:'',},
  {s:'616821',v:'125',c:'児発専門的支援加算（理学療法士）１６・',name:'専門的支援加算',value:'理学療法士等',opt:'n20,事業所,障害児,主に未就学児',limit:'',syoguu:'',},
  {s:'616822',v:'75',c:'児発専門的支援加算（理学療法士）１７・',name:'専門的支援加算',value:'理学療法士等',opt:'n200,事業所,障害児,主に未就学児',limit:'',syoguu:'',},
  {s:'616823',v:'187',c:'児発専門的支援加算（理学療法士）１８・',name:'専門的支援加算',value:'理学療法士等',opt:'n10,事業所,障害児,主に未就学児以外',limit:'',syoguu:'',},
  {s:'616824',v:'125',c:'児発専門的支援加算（理学療法士）１９・',name:'専門的支援加算',value:'理学療法士等',opt:'n20,事業所,障害児,主に未就学児以外',limit:'',syoguu:'',},
  {s:'616825',v:'75',c:'児発専門的支援加算（理学療法士）２０・',name:'専門的支援加算',value:'理学療法士等',opt:'n200,事業所,障害児,主に未就学児以外',limit:'',syoguu:'',},
  {s:'616826',v:'374',c:'児発専門的支援加算（理学療法士）２１・',name:'専門的支援加算',value:'理学療法士等',opt:'n5,事業所,重症心身障害児',limit:'',syoguu:'',},
  {s:'616827',v:'312',c:'児発専門的支援加算（理学療法士）２２・',name:'専門的支援加算',value:'理学療法士等',opt:'n6,事業所,重症心身障害児',limit:'',syoguu:'',},
  {s:'616828',v:'267',c:'児発専門的支援加算（理学療法士）２３・',name:'専門的支援加算',value:'理学療法士等',opt:'n7,事業所,重症心身障害児',limit:'',syoguu:'',},
  {s:'616829',v:'234',c:'児発専門的支援加算（理学療法士）２４・',name:'専門的支援加算',value:'理学療法士等',opt:'n8,事業所,重症心身障害児',limit:'',syoguu:'',},
  {s:'616830',v:'208',c:'児発専門的支援加算（理学療法士）２５・',name:'専門的支援加算',value:'理学療法士等',opt:'n9,事業所,重症心身障害児',limit:'',syoguu:'',},
  {s:'616831',v:'187',c:'児発専門的支援加算（理学療法士）２６・',name:'専門的支援加算',value:'理学療法士等',opt:'n190,事業所,重症心身障害児',limit:'',syoguu:'',},
  {s:'616832',v:'125',c:'児発専門的支援加算（理学療法士）２７・',name:'専門的支援加算',value:'理学療法士等',opt:'n200,事業所,重症心身障害児',limit:'',syoguu:'',},
  {s:'616833',v:'41',c:'児発専門的支援加算（児童指導員）１・',name:'専門的支援加算',value:'児童指導員',opt:'n30,センター,障害児',limit:'',syoguu:'',},
  {s:'616834',v:'35',c:'児発専門的支援加算（児童指導員）２・',name:'専門的支援加算',value:'児童指導員',opt:'n40,センター,障害児',limit:'',syoguu:'',},
  {s:'616835',v:'27',c:'児発専門的支援加算（児童指導員）３・',name:'専門的支援加算',value:'児童指導員',opt:'n50,センター,障害児',limit:'',syoguu:'',},
  {s:'616836',v:'22',c:'児発専門的支援加算（児童指導員）４・',name:'専門的支援加算',value:'児童指導員',opt:'n60,センター,障害児',limit:'',syoguu:'',},
  {s:'616837',v:'19',c:'児発専門的支援加算（児童指導員）５・',name:'専門的支援加算',value:'児童指導員',opt:'n70,センター,障害児',limit:'',syoguu:'',},
  {s:'616838',v:'16',c:'児発専門的支援加算（児童指導員）６・',name:'専門的支援加算',value:'児童指導員',opt:'n80,センター,障害児',limit:'',syoguu:'',},
  {s:'616839',v:'15',c:'児発専門的支援加算（児童指導員）７・',name:'専門的支援加算',value:'児童指導員',opt:'n200,センター,障害児',limit:'',syoguu:'',},
  {s:'616840',v:'62',c:'児発専門的支援加算（児童指導員）８・',name:'専門的支援加算',value:'児童指導員',opt:'n20,センター,障害児',limit:'',syoguu:'',},
  {s:'616841',v:'49',c:'児発専門的支援加算（児童指導員）９・',name:'専門的支援加算',value:'児童指導員',opt:'n30,センター,障害児',limit:'',syoguu:'',},
  {s:'616842',v:'35',c:'児発専門的支援加算（児童指導員）１０・',name:'専門的支援加算',value:'児童指導員',opt:'n40,センター,障害児',limit:'',syoguu:'',},
  {s:'616843',v:'27',c:'児発専門的支援加算（児童指導員）１１・',name:'専門的支援加算',value:'児童指導員',opt:'n200,センター,障害児',limit:'',syoguu:'',},
  {s:'616844',v:'62',c:'児発専門的支援加算（児童指導員）１２・',name:'専門的支援加算',value:'児童指導員',opt:'n15,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'616845',v:'62',c:'児発専門的支援加算（児童指導員）１３・',name:'専門的支援加算',value:'児童指導員',opt:'n20,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'616846',v:'49',c:'児発専門的支援加算（児童指導員）１４・',name:'専門的支援加算',value:'児童指導員',opt:'n200,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'616847',v:'123',c:'児発専門的支援加算（児童指導員）１５・',name:'専門的支援加算',value:'児童指導員',opt:'n10,事業所,障害児,主に未就学児',limit:'',syoguu:'',},
  {s:'616848',v:'82',c:'児発専門的支援加算（児童指導員）１６・',name:'専門的支援加算',value:'児童指導員',opt:'n20,事業所,障害児,主に未就学児',limit:'',syoguu:'',},
  {s:'616849',v:'49',c:'児発専門的支援加算（児童指導員）１７・',name:'専門的支援加算',value:'児童指導員',opt:'n200,事業所,障害児,主に未就学児',limit:'',syoguu:'',},
  {s:'616850',v:'123',c:'児発専門的支援加算（児童指導員）１８・',name:'専門的支援加算',value:'児童指導員',opt:'n10,事業所,障害児,主に未就学児以外',limit:'',syoguu:'',},
  {s:'616851',v:'82',c:'児発専門的支援加算（児童指導員）１９・',name:'専門的支援加算',value:'児童指導員',opt:'n20,事業所,障害児,主に未就学児以外',limit:'',syoguu:'',},
  {s:'616852',v:'49',c:'児発専門的支援加算（児童指導員）２０・',name:'専門的支援加算',value:'児童指導員',opt:'n200,事業所,障害児,主に未就学児以外',limit:'',syoguu:'',},
  {s:'616853',v:'247',c:'児発専門的支援加算（児童指導員）２１・',name:'専門的支援加算',value:'児童指導員',opt:'n5,事業所,重症心身障害児',limit:'',syoguu:'',},
  {s:'616854',v:'206',c:'児発専門的支援加算（児童指導員）２２・',name:'専門的支援加算',value:'児童指導員',opt:'n6,事業所,重症心身障害児',limit:'',syoguu:'',},
  {s:'616855',v:'176',c:'児発専門的支援加算（児童指導員）２３・',name:'専門的支援加算',value:'児童指導員',opt:'n7,事業所,重症心身障害児',limit:'',syoguu:'',},
  {s:'616856',v:'154',c:'児発専門的支援加算（児童指導員）２４・',name:'専門的支援加算',value:'児童指導員',opt:'n8,事業所,重症心身障害児',limit:'',syoguu:'',},
  {s:'616857',v:'137',c:'児発専門的支援加算（児童指導員）２５・',name:'専門的支援加算',value:'児童指導員',opt:'n9,事業所,重症心身障害児',limit:'',syoguu:'',},
  {s:'616858',v:'123',c:'児発専門的支援加算（児童指導員）２６・',name:'専門的支援加算',value:'児童指導員',opt:'n10,事業所,重症心身障害児',limit:'',syoguu:'',},
  {s:'616859',v:'82',c:'児発専門的支援加算（児童指導員）２７・',name:'専門的支援加算',value:'児童指導員',opt:'n200,事業所,重症心身障害児',limit:'',syoguu:'',},
  {s:'614111',v:'100',c:'児発看護職員加配加算１・１２・',name:'看護職員加配加算',value:'看護職員加配加算Ⅰ',opt:'n15,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'614112',v:'100',c:'児発看護職員加配加算１・１３・',name:'看護職員加配加算',value:'看護職員加配加算Ⅰ',opt:'n20,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'614113',v:'80',c:'児発看護職員加配加算１・１４・',name:'看護職員加配加算',value:'看護職員加配加算Ⅰ',opt:'n200,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'614120',v:'400',c:'児発看護職員加配加算１・２１・',name:'看護職員加配加算',value:'看護職員加配加算Ⅰ',opt:'n5,事業所,重症心身障害児',limit:'',syoguu:'',},
  {s:'614121',v:'333',c:'児発看護職員加配加算１・２２・',name:'看護職員加配加算',value:'看護職員加配加算Ⅰ',opt:'n6,事業所,重症心身障害児',limit:'',syoguu:'',},
  {s:'614122',v:'286',c:'児発看護職員加配加算１・２３・',name:'看護職員加配加算',value:'看護職員加配加算Ⅰ',opt:'n7,事業所,重症心身障害児',limit:'',syoguu:'',},
  {s:'614123',v:'250',c:'児発看護職員加配加算１・２４・',name:'看護職員加配加算',value:'看護職員加配加算Ⅰ',opt:'n8,事業所,重症心身障害児',limit:'',syoguu:'',},
  {s:'614124',v:'222',c:'児発看護職員加配加算１・２５・',name:'看護職員加配加算',value:'看護職員加配加算Ⅰ',opt:'n9,事業所,重症心身障害児',limit:'',syoguu:'',},
  {s:'614125',v:'200',c:'児発看護職員加配加算１・２６・',name:'看護職員加配加算',value:'看護職員加配加算Ⅰ',opt:'n10,事業所,重症心身障害児',limit:'',syoguu:'',},
  {s:'614126',v:'133',c:'児発看護職員加配加算１・２７・',name:'看護職員加配加算',value:'看護職員加配加算Ⅰ',opt:'n200,事業所,重症心身障害児',limit:'',syoguu:'',},
  {s:'614141',v:'200',c:'児発看護職員加配加算２・１２・',name:'看護職員加配加算',value:'看護職員加配加算Ⅰ',opt:'n15,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'614142',v:'200',c:'児発看護職員加配加算２・１３・',name:'看護職員加配加算',value:'看護職員加配加算Ⅰ',opt:'n20,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'614143',v:'160',c:'児発看護職員加配加算２・１４・',name:'看護職員加配加算',value:'看護職員加配加算Ⅰ',opt:'n200,センター,重症心身障害児',limit:'',syoguu:'',},
  {s:'614150',v:'800',c:'児発看護職員加配加算２・２１・',name:'看護職員加配加算',value:'看護職員加配加算Ⅰ',opt:'n5,事業所,重症心身障害児',limit:'',syoguu:'',},
  {s:'614151',v:'666',c:'児発看護職員加配加算２・２２・',name:'看護職員加配加算',value:'看護職員加配加算Ⅰ',opt:'n6,事業所,重症心身障害児',limit:'',syoguu:'',},
  {s:'614152',v:'572',c:'児発看護職員加配加算２・２３・',name:'看護職員加配加算',value:'看護職員加配加算Ⅰ',opt:'n7,事業所,重症心身障害児',limit:'',syoguu:'',},
  {s:'614153',v:'500',c:'児発看護職員加配加算２・２４・',name:'看護職員加配加算',value:'看護職員加配加算Ⅰ',opt:'n8,事業所,重症心身障害児',limit:'',syoguu:'',},
  {s:'614154',v:'444',c:'児発看護職員加配加算２・２５・',name:'看護職員加配加算',value:'看護職員加配加算Ⅰ',opt:'n9,事業所,重症心身障害児',limit:'',syoguu:'',},
  {s:'614155',v:'400',c:'児発看護職員加配加算２・２６・',name:'看護職員加配加算',value:'看護職員加配加算Ⅰ',opt:'n10,事業所,重症心身障害児',limit:'',syoguu:'',},
  {s:'614156',v:'266',c:'児発看護職員加配加算２・２７・',name:'看護職員加配加算',value:'看護職員加配加算Ⅰ',opt:'n200,事業所,重症心身障害児',limit:'',syoguu:'',},
  {s:'614180',v:'181',c:'児発共生型サービス体制強化加算１・',name:'共生型サービス体制強化加算',value:'児発管かつ保育士又は児童指導員',opt:'',limit:'',syoguu:'',},
  {s:'614181',v:'103',c:'児発共生型サービス体制強化加算２・',name:'共生型サービス体制強化加算',value:'児発管の場合',opt:'',limit:'',syoguu:'',},
  {s:'614182',v:'78',c:'児発共生型サービス体制強化加算３・',name:'共生型サービス体制強化加算',value:'保育士又は児童指導員の場合',opt:'',limit:'',syoguu:'',},
  {s:'615350',v:'187',c:'児発家庭連携加算１・',name:'家庭連携加算',value:0,opt:'1時間未満',limit:'4',syoguu:'',},
  {s:'615351',v:'280',c:'児発家庭連携加算２・',name:'家庭連携加算',value:60,opt:'1時間以上',limit:'4',syoguu:'',},
  {s:'615493',v:'100',c:'児発事業所内相談支援加算Ⅰ・',name:'事業所内相談支援加算',value:'事業所内相談支援加算Ⅰ',opt:'事業所内相談支援加算Ⅰ',limit:'1',syoguu:'',},
  {s:'615494',v:'80',c:'児発事業所内相談支援加算Ⅱ・',name:'事業所内相談支援加算',value:'事業所内相談支援加算Ⅱ',opt:'事業所内相談支援加算Ⅱ',limit:'1',syoguu:'',},
  {s:'615310',v:'30',c:'児発食事提供加算Ⅰ・',name:'食事提供加算',value:'児発食事提供加算Ⅰ',opt:'',limit:'',syoguu:'',},
  {s:'615311',v:'40',c:'児発食事提供加算Ⅱ・',name:'食事提供加算',value:'児発食事提供加算Ⅱ',opt:'',limit:'',syoguu:'',},
  {s:'615370',v:'150',c:'児発上限額管理加算・',name:'利用者負担上限額管理加算',value:'1',opt:'',limit:'',syoguu:'',},
  {s:'615492',v:'15',c:'児発福祉専門職員配置等加算Ⅰ・',name:'福祉専門職員配置等加算',value:'福祉専門職員配置等加算Ⅰ',opt:'',limit:'',syoguu:'',},
  {s:'615490',v:'10',c:'児発福祉専門職員配置等加算Ⅱ・',name:'福祉専門職員配置等加算',value:'福祉専門職員配置等加算Ⅱ',opt:'',limit:'',syoguu:'',},
  {s:'615491',v:'6',c:'児発福祉専門職員配置等加算Ⅲ・',name:'福祉専門職員配置等加算',value:'福祉専門職員配置等加算Ⅲ',opt:'',limit:'',syoguu:'',},
  {s:'615130',v:'37',c:'児発栄養士配置加算Ⅰ１・',name:'栄養士配置加算',value:'栄養士配置加算Ⅰ',opt:'n40',limit:'',syoguu:'',},
  {s:'615131',v:'30',c:'児発栄養士配置加算Ⅰ２・',name:'栄養士配置加算',value:'栄養士配置加算Ⅰ',opt:'n50',limit:'',syoguu:'',},
  {s:'615132',v:'25',c:'児発栄養士配置加算Ⅰ３・',name:'栄養士配置加算',value:'栄養士配置加算Ⅰ',opt:'n60',limit:'',syoguu:'',},
  {s:'615133',v:'21',c:'児発栄養士配置加算Ⅰ４・',name:'栄養士配置加算',value:'栄養士配置加算Ⅰ',opt:'n70',limit:'',syoguu:'',},
  {s:'615134',v:'19',c:'児発栄養士配置加算Ⅰ５・',name:'栄養士配置加算',value:'栄養士配置加算Ⅰ',opt:'n80',limit:'',syoguu:'',},
  {s:'615135',v:'16',c:'児発栄養士配置加算Ⅰ６・',name:'栄養士配置加算',value:'栄養士配置加算Ⅰ',opt:'n200',limit:'',syoguu:'',},
  {s:'615200',v:'20',c:'児発栄養士配置加算Ⅱ１・',name:'栄養士配置加算',value:'栄養士配置加算Ⅱ',opt:'n40',limit:'',syoguu:'',},
  {s:'615201',v:'16',c:'児発栄養士配置加算Ⅱ２・',name:'栄養士配置加算',value:'栄養士配置加算Ⅱ',opt:'n50',limit:'',syoguu:'',},
  {s:'615202',v:'13',c:'児発栄養士配置加算Ⅱ３・',name:'栄養士配置加算',value:'栄養士配置加算Ⅱ',opt:'n60',limit:'',syoguu:'',},
  {s:'615203',v:'11',c:'児発栄養士配置加算Ⅱ４・',name:'栄養士配置加算',value:'栄養士配置加算Ⅱ',opt:'n70',limit:'',syoguu:'',},
  {s:'615204',v:'10',c:'児発栄養士配置加算Ⅱ５・',name:'栄養士配置加算',value:'栄養士配置加算Ⅱ',opt:'n80',limit:'',syoguu:'',},
  {s:'615205',v:'9',c:'児発栄養士配置加算Ⅱ６・',name:'栄養士配置加算',value:'栄養士配置加算Ⅱ',opt:'n200',limit:'',syoguu:'',},
  {s:'615495',v:'94',c:'児発欠席時対応加算・',name:'欠席時対応加算',value:'1',opt:'',limit:'4',syoguu:'',},
  {s:'615496',v:'94',c:'児発欠席時対応加算（重心）・',name:'欠席時対応加算',value:'1',opt:'重心',limit:'8',syoguu:'',},
  {s:'616220',v:'54',c:'児発特別支援加算・',name:'特別支援加算',value:'1',opt:'',limit:'',syoguu:'',},
  {s:'616225',v:'155',c:'児発強度行動障害児支援加算・',name:'強度行動障害児支援加算',value:'1',opt:'',limit:'',syoguu:'',},
  {s:'616762',v:'100',c:'児発個別サポート加算Ⅰ・',name:'個別サポート加算',value:'個別サポート加算Ⅰ',opt:'',limit:'',syoguu:'',},
  {s:'616763',v:'125',c:'児発個別サポート加算Ⅱ・',name:'個別サポート加算',value:'個別サポート加算Ⅱ',opt:'',limit:'',syoguu:'',},
  {s:'616762',v:'100',c:'児発個別サポート加算Ⅰ・',name:'個別サポート加算１',value:'1',opt:'1',limit:'',syoguu:'',},
  {s:'616763',v:'125',c:'児発個別サポート加算Ⅱ・',name:'個別サポート加算２',value:'1',opt:'1',limit:'',syoguu:'',},

  {s:'616230',v:'32',c:'児発医療連携体制加算Ⅰ・',name:'医療連携体制加算',value:'医療連携体制加算Ⅰ',opt:'',limit:'',syoguu:'',iryouRenkeiNum: 1},
  {s:'616231',v:'63',c:'児発医療連携体制加算Ⅱ・',name:'医療連携体制加算',value:'医療連携体制加算Ⅱ',opt:'',limit:'',syoguu:'',iryouRenkeiNum: 2},
  {s:'616232',v:'125',c:'児発医療連携体制加算Ⅲ・',name:'医療連携体制加算',value:'医療連携体制加算Ⅲ',opt:'',limit:'',syoguu:'',iryouRenkeiNum: 3},
  {s:'616280',v:'800',c:'児発医療連携体制加算Ⅳ１・',name:'医療連携体制加算',value:'医療連携体制加算Ⅳ１',opt:'',limit:'',syoguu:'',iryouRenkeiNum: 4},
  {s:'616281',v:'500',c:'児発医療連携体制加算Ⅳ２・',name:'医療連携体制加算',value:'医療連携体制加算Ⅳ２',opt:'',limit:'',syoguu:'',iryouRenkeiNum: 4},
  {s:'616282',v:'400',c:'児発医療連携体制加算Ⅳ３・',name:'医療連携体制加算',value:'医療連携体制加算Ⅳ３',opt:'',limit:'',syoguu:'',iryouRenkeiNum: 4},
  {s:'616283',v:'1600',c:'児発医療連携体制加算Ⅴ１・',name:'医療連携体制加算',value:'医療連携体制加算Ⅴ１',opt:'',limit:'',syoguu:'',iryouRenkeiNum: 5},
  {s:'616284',v:'960',c:'児発医療連携体制加算Ⅴ２・',name:'医療連携体制加算',value:'医療連携体制加算Ⅴ２',opt:'',limit:'',syoguu:'',iryouRenkeiNum: 5},
  {s:'616285',v:'800',c:'児発医療連携体制加算Ⅴ３・',name:'医療連携体制加算',value:'医療連携体制加算Ⅴ３',opt:'',limit:'',syoguu:'',iryouRenkeiNum: 5},
  
  
  {s:'616240',v:'54',c:'児発送迎加算Ⅰ・',name:'送迎加算',value:'送迎加算Ⅰ',opt:'',limit:'',syoguu:'',},
  {s:'616423',v:'38',c:'児発送迎加算Ⅰ（同一敷地）・',name:'送迎加算',value:'送迎加算Ⅰ同一敷地内',opt:'',limit:'',syoguu:'',},
  {s:'616241',v:'37',c:'児発送迎加算Ⅱ・',name:'送迎加算',value:'送迎加算Ⅱ',opt:'',limit:'',syoguu:'',},
  {s:'616424',v:'26',c:'児発送迎加算Ⅱ（同一敷地）・',name:'送迎加算',value:'送迎加算Ⅱ同一敷地内',opt:'',limit:'',syoguu:'',},
  {s:'616422',v:'37',c:'児発送迎加算Ⅰ（一定条件）・',name:'送迎加算',value:'送迎加算Ⅰ一定条件',opt:'',limit:'',syoguu:'',},
  {s:'616250',v:'61',c:'児発延長支援加算１・',name:'延長支援加算',value:'1時間未満',opt:'',limit:'',syoguu:'',},
  {s:'616251',v:'92',c:'児発延長支援加算２・',name:'延長支援加算',value:'1時間以上2時間未満',opt:'',limit:'',syoguu:'',},
  {s:'616252',v:'123',c:'児発延長支援加算３・',name:'延長支援加算',value:'2時間以上',opt:'',limit:'',syoguu:'',},
  {s:'616253',v:'128',c:'児発延長支援加算４・',name:'延長支援加算',value:'1時間未満',opt:'重心',limit:'',syoguu:'',},
  {s:'616254',v:'192',c:'児発延長支援加算５・',name:'延長支援加算',value:'1時間以上2時間未満',opt:'重心',limit:'',syoguu:'',},
  {s:'616255',v:'256',c:'児発延長支援加算６・',name:'延長支援加算',value:'2時間以上',opt:'重心',limit:'',syoguu:'',},
  {s:'616760',v:'200',c:'児発関係機関連携加算Ⅰ・',name:'関係機関連携加算',value:'関係機関連携加算Ⅰ',opt:'',limit:'',syoguu:'',},
  {s:'616761',v:'200',c:'児発関係機関連携加算Ⅱ・',name:'関係機関連携加算',value:'関係機関連携加算Ⅱ',opt:'',limit:'',syoguu:'',},
  {s:'616765',v:'500',c:'児発保育教育等移行支援加算・',name:'保育・教育等移行支援加算',value:'1',opt:'',limit:'1',syoguu:'',},
  {s:'616621',v:'8.1',c:'児発処遇改善加算Ⅰ・',name:'福祉・介護職員処遇改善加算',value:'福祉・介護職員処遇改善加算Ⅰ',opt:'',limit:'',syoguu:'1',},
  {s:'616616',v:'5.9',c:'児発処遇改善加算Ⅱ・',name:'福祉・介護職員処遇改善加算',value:'福祉・介護職員処遇改善加算Ⅱ',opt:'',limit:'',syoguu:'1',},
  {s:'616596',v:'3.3',c:'児発処遇改善加算Ⅲ・',name:'福祉・介護職員処遇改善加算',value:'福祉・介護職員処遇改善加算Ⅲ',opt:'',limit:'',syoguu:'1',},
  {s:'616601',v:'3.3*.9',c:'児発処遇改善加算Ⅳ・',name:'福祉・介護職員処遇改善加算',value:'福祉・介護職員処遇改善加算Ⅳ',opt:'',limit:'',syoguu:'1',},
  {s:'616606',v:'3.3*.8',c:'児発処遇改善加算Ⅴ・',name:'福祉・介護職員処遇改善加算',value:'福祉・介護職員処遇改善加算Ⅴ',opt:'',limit:'',syoguu:'1',},
  {s:'616611',v:'1',c:'児発処遇改善特別加算・',name:'福祉・介護職員処遇改善特別加算',value:'1',opt:'',limit:'',syoguu:'1',},
  {s:'616772',v:'1.3',c:'児発特定処遇改善加算Ⅰ・',name:'特定処遇改善加算',value:'特定処遇改善加算Ⅰ',opt:'',limit:'',syoguu:'1',},
  {s:'616773',v:'1',c:'児発特定処遇改善加算Ⅱ・',name:'特定処遇改善加算',value:'特定処遇改善加算Ⅱ',opt:'',limit:'',syoguu:'1',},
];