import React, { useEffect, useState } from 'react';
import * as mui from '../common/materialUi'
import * as Actions from '../../Actions';
import * as comMod from '../../commonModule';
import { useDispatch, useSelector, } from 'react-redux';
import { common } from '@material-ui/core/colors';
import { LoadingSpinner, LoadErr, PermissionDenied, StdErrorDisplay } from '../common/commonParts';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { 
  houdySirvice, houdayKasan, chiikiKubun, unitPrice,
  serviceSyubetu, ketteiScodeOld,
} from './BlCalcData';
import { keys } from '@material-ui/core/styles/createBreakpoints';
import { FormatAlignJustifyOutlined } from '@material-ui/icons';
import { 
  setBillInfoToSch, makeBiling, makeJugenkanri, makeTeikyouJisseki
} from './blMakeData';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import { Link, useLocation } from 'react-router-dom';
import CheckProgress from '../common/CheckProgress';
import {ProseedUpperLimit, UpperLimitInner} from './Proseed';
import axios from 'axios';
import red from '@material-ui/core/colors/red';
import { UserSelectDialog} from '../common/commonParts';
import GroupIcon from '@material-ui/icons/Group';
import { faChalkboardTeacher } from '@fortawesome/free-solid-svg-icons';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { selectStyle } from '../common/FormPartsCommon';
// import { endPoint } from '../../Rev';
import { endPoint } from '../../albCommonModule';
import ServiceCountNotice from './ServiceCountNotice';
import UserBilling from './UserBilling';



const CSVSURL = "https://rbatosdata.com/";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  linktabRoot: {
    marginTop: 47,
    '& > .MuiButton-text': {
      // margin: theme.spacing(1),
      padding: 0,
    },
  },
  dlBtn: {
    '& .MuiButton-root':{
      marginInlineStart:8,
      marginInlineEnd:8,
    },
  },
  dialogOpenButtonRoot:{
    position: 'fixed',
    top: 90,
    right: 16,
    '& .buttonText':{
      display: 'flex',
    },
    '& .buttonText soan':{
      display: 'block',
    },
    '& .buttonText span:nth-of-type(1)' :{
      fontSize: '.6rem',
      margin: '.7rem 2px 0',
      marginLeft: '.6rem',
    },
    '& .buttonText span:nth-of-type(2)': {
      fontSize: '1.2rem',
      margin: '0 2px 0'
    },
    '& .buttonText span:nth-of-type(3)': {
      fontSize: '.6rem',
      margin: '.7rem 2px 0'
    },
    '& .scheduleCount' : {
      padding: 6,
      textAlign: 'center',
      '& span' :{
        color:'#00695c',
        fontWeight: 'bold',
      }
    },
  },
  // createCsv:{
  //   '& .MuiButtonBase-root' :{
  //     margin: 0,
  //     paddingRight: 16,
  //     paddingLeft: 16,
  //   },
  // },
}));

const Links = () => {
  const classes = useStyles();
  // react-routerからロケーション取得
  const ref = useLocation().pathname;
  const prms = [
    { link: "/billing", label: "請求送信" },
    { link: "/billing/upperlimit", label: "上限管理" },
    { link: "/billing/userbilling", label: "口座振替・ファクタリング" },
  ];
  const linkList = prms.map((e, i) => {
    let cls = (ref === e.link) ? 'current' : '';
    return (
      <Button key={i} >
        <Link className={cls} to={e.link}>{e.label}</Link>
      </Button>
    )
  });
  return (<>
    <div className={'linksTab ' + classes.linktabRoot}>
      {linkList}
    </div>
  </>);
}

export const BilUpperLimit = (props) =>{
  const allstate = useSelector(state=>state);
  const loadingStatus = comMod.getLodingStatus(allstate);

  const BilUpperLimitMain = () => (
    <>
      <Links/>
      <UpperLimitInner style={{paddingTop: 0}} />
      {/* <div className="AppPage account">hoge</div> */}
    </>
  )

  if (loadingStatus.loaded){
    return (<BilUpperLimitMain />)
  }
  else if (loadingStatus.error){
    return (
      <LoadErr loadStatus={loadingStatus} errorId={'E4934'} />
    )
  }
  else{
    return <LoadingSpinner/>
  }

}

export const BilUserBilling = (props) =>{
  const allstate = useSelector(state=>state);
  const loadingStatus = comMod.getLodingStatus(allstate);

  const BilUserBillingMain = () => (
    <>
      <Links/>
      <UserBilling/>
      {/* <div className="AppPage account">hoge</div> */}
    </>
  )

  if (loadingStatus.loaded){
    return (<BilUserBillingMain />)
  }
  else if (loadingStatus.error){
    return (
      <LoadErr loadStatus={loadingStatus} errorId={'E4934'} />
    )
  }
  else{
    return <LoadingSpinner/>
  }

}



// 請求処理を行うモジュール。
// 処理はmakeCsvで一括して行う。
// makeCsvからは
// 明細情報（請求）k121(122)
// サービス提供実績 k611
// 上限管理(k411)
// を作成するモジュールそれぞれコールする予定。
// 個別の作成モジュールはcsvに該当する配列を返す。
// 配列はjsonにしてdbに投げるようにする
// 親モジュールAccountからはuseStateで作成したステイトとset関数を
// 明細作成用モジュールに渡してそれぞれ状態管理を行うようにする。
// サーバーサイドの状態管理を行うためにfetch関連のディスパッチを作成して
// そちらもstateで状態管理を行うようにする。

// 送信の状態を監視するタイマーインターバル
const fetchTransferInterval = 1000 * 8;
const dummyProgressInterval = 800;
// 配列置換用文字列定義
const REC_CNT = 'REC_CNT';
const TOTAL_RECS = 'TOTAL_RECS';
const JINO = 'JINO';
const MONTH_USE = 'MONTH_USE';
const MONTH_APPLY = 'MONTH_APPLY';
const CITY = 'CITY';
const HNO = 'HNO';
// カラム位置
const CP_TR_SOUGEI_CNT = 36; // 送迎回数 35 カラム
// 以下はトータルレコードに挿入するべき値とカラム位置 今はまだ対応していない
// const KATEI_CNT = 'KATEI_CNT'; // 家庭連携加算 37
// const KATEI_CNT1 = 'KATEI_CNT1'; // 家庭連携加算算定回数 38
// const JIKATSU_CNT = 'JIKATSU_CNT';// 自活支援回数 53
// const HOUMON_CNT = 'HOUMON_CNT';// 訪問回数 54
// const KOUDOU_CNT = 'KOUDOU_CNT';// 行動障害回数 119
// const JUUDO_CNT = 'JUUDO_CNT';// 重度回数 123

// 配列から特定の文字列を検索して置換する
const elmRep = (a, str, value) =>{
  const ndx = a.indexOf(str);
  if (ndx === -1) return false;
  a[ndx] = value;
  return true;
}

// // scheduleから該当サービスのあったuidを取得する
// const getUIDS = (schedule, users, service)=>{
//   const t = Object.keys(schedule).map(e=>{
//     if ((e.indexOf('UID') === 0) && comMod.getUser(e.users).service === service){
//       return e;
//     };
//   });
//   // undefindが帰ってくるのでフィルタする
//   const uids = t.filter(e=>e);

//   return uids;
// }


// 利用明細レコードを作成する トータルレコードに記載するための値も返す
// const getDetailUseResult = (detailRec, schedule, uid, cityNo, jino, hno) =>{
//   // detailRecの形式
//   // detailRec = [2, REC_CNT, 'K611', 2, MONTH_USE, CITY, JINO, HNO, '0501'];
//   // カラムポジションの定義
//   const CP_DATE = 11;
//   const CP_START = 16;
//   const CP_END = 17;
//   const CP_PICKUP = 23;
//   const CP_SEND = 24;
//   const CP_BIKOU = 36;  // 備考だがサンプルデータは平日=1 休日=2が入力されている

//   const detail = []; // リターン用の配列
//   const thisSch = schedule[uid];  // 処理を行うユーザーのスケジュール
//   // did (d20200816)キーで格納されている値でmap
//   let transferCount = 0; // 送迎カウント
//   Object.keys(thisSch).forEach(did=>{
//     const e = thisSch[did];
//     // did形式以外のキーは弾く
//     if (!did.match(/^D[0-9]+/)) return true;
//     // 利用実績フラグのチェック
//     if (!e.useResult) return true;
//     const ary = detailRec.concat();
//     elmRep(ary, MONTH_USE, did.substr(1, 4));// 提供月
//     elmRep(ary, CITY, cityNo);// 市区町村番号
//     elmRep(ary, JINO, jino);// 事業所番号
//     elmRep(ary, HNO, hno);// 被保険者番号
//     ary[CP_DATE] = parseInt(did.substr(7, 2));// 日付
//     ary[CP_START] = e.start.replace(':', ''); //開始時間
//     ary[CP_END] = e.end.replace(':', ''); //終了時間
//     ary[CP_PICKUP] = (e.transfer[0]) ? 1 : 0; // 迎え
//     ary[CP_SEND] = (e.transfer[1]) ? 1 : 0; // 送り
//     transferCount += (e.transfer[0]) ? 1 : 0;
//     transferCount += (e.transfer[1]) ? 1 : 0;
//     ary[CP_BIKOU] = parseInt(e.offSchool) + 1; // 平日0休日1を+1して変換
//     detail.push(ary);

//   });
//   return {detail, transferCount};
// }
// 配列がlengthになるまでfillerを挿入する
// const addFill = (array, filler, length) =>{
// 　while(array.length < length)  array.push(filler);
//   return true;
// }

// 利用実績ファイルに対応した配列を作成
// const makeUseResult = (prms)=>{
//   const {stdDate, schedule, users, com, service} = prms;

//   const monthUse = stdDate.replace(/\-/g, '').substr(0, 6);
//   // stdDateを日付型に変換
//   const date = new Date(stdDate.split('-')[0], stdDate.split('-')[1],1);
//   date.setMonth(date.getMonth() + 1);
//   const monthApply = comMod.formatDate(date, 'YYYYMM');
//   // コントロールレコード。後から置換するフィールドに文字列を入れておく
//   const cntRec = [1,REC_CNT,0,TOTAL_RECS,'K61',0,JINO,0,1,MONTH_APPLY,''];
//   // 基本レコード 合計情報などが記載されている
//   const totalRec = [2, REC_CNT, 'K611', 1, MONTH_USE, CITY, JINO, HNO, '0501'];
//   addFill(totalRec, '', 148);
//   // 先頭の自明な項目を設定する monthは提供月 hnoは受給者番号
//   // 0501は要識別種別番号 固定にしてあるがあとで変更するかも
//   // 明細レコード
//   const detailRec = [2,REC_CNT,'K611',2,MONTH_USE,CITY,JINO, HNO,'0501'];
//   addFill(detailRec, '', 92);
//   const endRec = [3, REC_CNT];
  
//   // リターンするためのレコード
//   const rt = [];
//   // コントロールレコードの作成
//   elmRep(cntRec, JINO, com.jino); // 事業所番号
//   elmRep(cntRec, MONTH_APPLY, monthApply); // 申請月
//   rt.push(cntRec);
//   // 対象のUIDを取得
//   const uids = getUIDS(schedule, users, service);
//   // ユーザーごとの処理 totalレコードと明細レコードの作成
//   const jino = com.jino;
//   uids.forEach(uid=>{
//     const hno = comMod.getUser(uid, users).hno;
//     const cityNo = comMod.getUser(uid, users).scity_no;
//     const tr = totalRec.concat(); //rtに挿入用のレコード
//     elmRep(tr, MONTH_USE, monthUse); // 利用月
//     elmRep(tr, CITY, cityNo); // 請求市町村
//     elmRep(tr, JINO, jino); // 事業所番号
//     elmRep(tr, HNO, hno); // 被保険者番号
//     // detailRecordを取得する トータルレコードに取得する値と合わせて
//     // オブジェクトを返す
//     const detail = getDetailUseResult(
//       detailRec, schedule, uid, cityNo, jino, hno
//     );
//     tr[CP_TR_SOUGEI_CNT] = detail.transferCount; // 送迎回数をトータルレコードに
//     rt.push(tr);
//     // getDetailUseResultからの戻り配列を全て追加
//     detail.detail.forEach(e=>{
//       rt.push(e);
//     });
//   });
//   rt.push(endRec);  // 終端レコード
//   rt.forEach((e, i)=>{
//     elmRep(e, REC_CNT, i +1);
//   });
//   elmRep(rt[0], TOTAL_RECS, rt.length - 2); //総レコードカウントの書き込み
//   return rt;
// }

// const makeDummy = (num)=>{
//   const rt = [];
//   for (let i = 0; i < num; i++){
//     rt.push(['this is dummy', i + 1]);
//   }
//   return rt;
// };

// それぞれのcsv作成モジュールのローダー
const makeAndSend = (prms) =>{
  const {
    stdDate, schedule, users, com, service, serviceItems, dispatch
  } = prms;
  // const useResult = makeUseResult(prms);
  const { billingDt, masterRec , result, errDetail} = setBillInfoToSch(prms);
  console.log('billingDt', billingDt);
  console.log('masterRec', masterRec);
  const billing = makeBiling(billingDt, masterRec, schedule, users);
  const upperLimit = makeJugenkanri(billingDt, masterRec, 1, users, schedule);
  const useResult = makeTeikyouJisseki(billingDt, masterRec);
  console.log('upperLimit', upperLimit);
  console.log('useResult', useResult);
  const jino = com.jino;
  const bid = com.bid;
  const hid = com.hid;
  const transferData = { useResult, upperLimit, billing, jino, bid, hid};
  console.log('transferData', transferData);

  const smpHno = ['0200383255', '0200324515', '0200259745', '0122584063'];
  const sampleBilingCsvImg = billing.filter(e=>(
    smpHno.indexOf(e[7]) > -1
  ));
  console.log('sampleBilingCsvImg', sampleBilingCsvImg)
  const sampleBiling = billingDt.filter(e => (
    smpHno.indexOf(e.hno) > -1
  ));
  console.log('sampleBiling', sampleBiling)
  // 請求データ作成した結果を受けてstorにセット
  // if (!result){
  //   dispatch(Actions.setSnackMsg(errDetail.description, 'error', 'B236710'));
  // }
  // dispatch(Actions.setStore({ billingDt, masterRec }));
  dispatch(Actions.sendTransfer({
    hid: com.hid,
    bid: com.bid,
    date: stdDate,
    dt: JSON.stringify(transferData),
    a: 'sendTransferData',
  }));
}

const sendAndMakeCsvApiCall = async (prms, transferData, setRes) => {
  let resSend, resCsv
  try{
    // dbに送信データをセット
    prms.a = 'sendTransferData';
    prms.dt = JSON.stringify(transferData);
    prms.date = prms.stdDate;
    resSend = await axios.post(endPoint(), comMod.uPrms(prms));
    if (resSend.status !== 200)  throw resSend;
    if (!resSend.data) throw resSend;
    delete prms.dt; // 無駄な送信を防ぐために削除
    // csvの作成
    prms.a = 'csvgen';
    prms.rnddir = comMod.randomStr(20, 0);
    prms.prefix = (prms.prefix)? prms.prefix.substr(0, 3): 'AAA';
    resCsv = await axios.post(endPoint(), comMod.uPrms(prms));
    if (resCsv.status !== 200)  throw resCsv;
    if (!resCsv.data) throw resCsv;
    // 終了フラグのセット
    resSend.done = true; resCsv.done = true;
    setRes({resSend, resCsv});
  }
  catch (e) {
    setRes({resSend, resCsv, e, error: true});

  }
}

// csv作成用
// データ送信とダウンロードリンクの提供を行う
// makeAndSendとかもaction使わない方向で書き換える予定なのでこっちのモジュールは
// async awaitをコールするようにする
const sendAndMakeCsv = (prms, userList, jougenKubun) => {
  const {stdDate, schedule, users, com, service, dispatch, setRes} = prms;
  let { billingDt, masterRec, result, errDetail } = setBillInfoToSch(prms, userList);
  // 利用なしのデータをここで削除
  billingDt = billingDt.filter(e=>e.tanniTotal);
  const jino = com.jino;
  const bid = com.bid;
  const hid = com.hid;
  const prefix = com.fprefix;
  const billing = makeBiling(billingDt, masterRec, schedule, users);
  const upperLimit = makeJugenkanri(
    billingDt, masterRec, jougenKubun, users, schedule
  );
  const useResult = makeTeikyouJisseki(billingDt, masterRec);
  const transferData = { useResult, upperLimit, billing, jino, bid, hid};
  const apiPrms = {hid, bid, stdDate,  prefix};
  // if (!result){
  //   dispatch(Actions.setSnackMsg(errDetail.description, 'error', 'B236711'));
  // }
  // dispatch(Actions.setStore({ billingDt, masterRec }));
  sendAndMakeCsvApiCall(apiPrms, transferData, setRes);
  
}

// フックを使う必要があるのでトップレベルファンクションにする
const TransferDone = () => {
  const dispatch = useDispatch();
  const fetchStatus = useSelector(state => state.fetchTransferStatus);

  const [count, setCount] = React.useState(0);
  const [sent, setSent] = React.useState(false);
  useEffect(()=> {
    const tid = setInterval(()=>{
      setCount(count+1);
      // 国保連送信を監視する
      if (fetchStatus.data.dt.length) {
        const transferd = fetchStatus.data.dt[0].sent; // 国保連伝送日付
        // 日付が入っていたら送信済み
        if (transferd.indexOf('0000-00') !== 0) { 
          setSent(true);
        }
      }
      // 一定時間過ぎたら経過表示を停止する
      if (count > 6){
        dispatch(Actions.resetTransfer());
      }
    }, dummyProgressInterval);
    return () => clearInterval(tid);
  });
  const checkIconStyle = {
    verticalAlign: 'sub',
  }

  return (
    <div className="AppPage account">
      <div className="billingPanel">
        <div className="sendProgress">
          <span className='icon'>
            <CheckIcon style={checkIconStyle} color='primary'/>
          </span>
          <span className='text'>送信準備完了しました。</span>
        </div>
        {(count > 0) &&
          <div className="sendProgress">
            <span className='icon'>
              <CheckIcon style={checkIconStyle} color='primary' />
            </span>
            <span className='text'>請求データ送信完了</span>
          </div>
        }
        {(count > 1) &&
          <div className="sendProgress">
            <span className='icon'>
              <CheckIcon style={checkIconStyle} color='primary' />
            </span>
            <span className='text'>利用実績データ送信完了</span>
          </div>
        }
        {(count > 2) &&
          <div className="sendProgress">
            <span className='icon'>
              <CheckIcon style={checkIconStyle} color='primary' />
            </span>
            <span className='text'>上限管理データ送信完了</span>
          </div>
        }
        {(count > 2 && !sent) &&
          <div className="sendProgress">
            送信完了までしばらくお待ち下さい。
          </div>
        }
        {(count > 2 && sent) &&
          <div className="sendProgress">
            <span className='icon'>
              <CheckCircleIcon style={checkIconStyle} color='secondary' />
            </span>
            <span className='text'>国保連伝送完了</span>
          </div>
        }
      </div>
    </div>
  )

}
// 兄弟設定がされているかどうかのアラートを表示する。
// 現状、されているかどうかだけの表示
// props.brosはusersで兄弟設定がされている利用者の配列
const BrosInfo = (props) => {
  const {bros, ...othes} = props;
  const st = {
    width:'100%', textAlign:'center',padding:8,fontWeight:600,
    color: red[900],fontSize: '.8rem',
  }
  if (bros.length){
    return(
      <div style={st}>
        兄弟設定が行われている利用者は国保連に送付するデータの作成は行われません。
      </div>
    )
  }
  else{
    return null;
  }
}

const UserSelectButton = (props) => {
  const classes = useStyles();
  const {dialogOpen, setDialogOpen, userList, setUserList} = props;
  const [userListlength, SetUserListlength] = useState(0);
  const [scheduleCount, SetScheduleCount] = useState(0);
  const schedule = useSelector(state=>state.schedule);
  // ユーザーリストのカウント
  useEffect(()=>{
    SetUserListlength(userList.filter(e=>e.checked === true).length);
    let cnt = 0;
    // チェックされたユーザーのスケジュール件数をカウント
    userList.filter(e => e.checked === true).map(f=>{
      const thisSch = schedule['UID' + f.uid];
      if (!thisSch)  return false;
      cnt += Object.keys(thisSch).filter(g=>g.indexOf('D2') === 0).length
    });
    SetScheduleCount(cnt);
  }, [userList])

  return (
    <div className={classes.dialogOpenButtonRoot}>
      <Button
        onClick={() => setDialogOpen(true)}
        color='secondary'
        variant='contained'
      >
        <GroupIcon fontSize='large' />
        <div className='buttonText'>
          <span>設定済み</span>
          <span>{userListlength}</span>
          <span>人</span>
        </div>

      </Button>
      <div className='scheduleCount'>
          対象件数 <span>{scheduleCount}</span> 件
      </div>
    </div>

  )
}

const Billing = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const stdDate = useSelector(state => state.stdDate);
  const schedule = useSelector(state => state.schedule);
  const users = useSelector(state => state.users);
  const com = useSelector(state => state.com); // 会社と事業所の情報
  const hid = useSelector(state => state.hid);
  const bid = useSelector(state => state.bid);
  const serviceItems = useSelector(state => state.serviceItems);
  const service = useSelector(state => state.service);
  const sendStatus = useSelector(state => state.sendTransferStatus);
  const fetchStatus = useSelector(state => state.fetchTransferStatus);
  const allState = useSelector(state=>state);
  const sBillingDt = useSelector(state=>state.billingDt);
  const account = useSelector(state=>state.account);
  const permission = comMod.parsePermission(account)[0][0];
  const [dialogOpen, setDialogOpen] = useState('');
  const [userList, setUserList] = useState(
    users.map(e => ({ uid: e.uid, checked: true }))
  );
  const loadingStatus = comMod.getLodingStatus(allState);
  const tService = service? service: serviceItems[0];
  const comAdic = comMod.findDeepPath(com, ['addiction', tService]);  

  // csv送信用のレスポンス
  const [res, setRes] = useState({resSend:{},resCsv:{}});
  const [csvUrl, setCsvUrl] = useState({});
  // 上限管理区分設定用
  const [jougenKubun, setJougenKubun] = useState(1);

  // 送信データの状態取得 useEffectを使うよ！！
  useEffect(()=>{
    const params = {
      hid, bid, 
      date: stdDate,
      reg: 1,
      displayLoading : false,
      a: 'listSent',
    }
    if (!fetchStatus.done && !fetchStatus.err){
      dispatch(Actions.fetchTransfer(params));
    }

    // const tid = setInterval(()=>{
    //   // 送信状態のポーリング
    //   dispatch(Actions.fetchTransfer(params));
    // }, fetchTransferInterval);
    // return () => clearInterval(tid);
  }, [fetchStatus]);

  const thisMonth = stdDate.split('-')[0] + '年' + stdDate.split('-')[1] + '月';
  
  const str = thisMonth + 'のデータ伝送をリクエストします。よろしいですか？'
  const prms = {
    stdDate, schedule, users, com, 
    service: '放課後等デイサービス', 
    serviceItems,
    dispatch, setRes,
  }
  
  const clickHandler = ()=>{
    console.log('clicked');
    makeAndSend(prms);
  }
  // csv作成のレスポンス監視
  useEffect(()=>{
    if (res.resCsv.done && res.resCsv.status === 200){
      try{
        const csvDt = res.resCsv.data;
        const zipAddr = csvDt.zip.replace(csvDt.root, '');
        const upperLimitAddr = csvDt.upperLimit.replace(csvDt.root, '');
        const useResultAddr = csvDt.useResult.replace(csvDt.root, '');
        const billingAddr = csvDt.biling.replace(csvDt.root, '');
        setCsvUrl({
          zip: CSVSURL + zipAddr,
          upperLimit: CSVSURL + upperLimitAddr,
          useResult: CSVSURL + useResultAddr,
          billing: CSVSURL + billingAddr,
          useResultLen: csvDt.useResultLen,
          upperLimitLen: csvDt.upperLimitLen,
          bilingLen: csvDt.bilingLen,
          result: true,
        });
        // 30秒でリンク消失させる
        setTimeout(() => {
          setCsvUrl({});
        }, 30 * 1000);
      }
      catch(e){
        setCsvUrl({result: false,})
      }
    }
  },[res]);
  // userlistを監視。変更されたらcsvurlを破棄する
  useEffect(()=>{
    setCsvUrl({});
  },[userList]);

  // 最初だけ bilingDtをdispatchする。確認のため
  useEffect(()=>{
    if (!sBillingDt && loadingStatus.loaded && comAdic){
      const { billingDt, masterRec, result, errDetail } = setBillInfoToSch(prms);
      if (!result){
        dispatch(Actions.setSnackMsg(errDetail.description, 'error', 'B236709'));
      }
      dispatch(Actions.setStore({billingDt: "", masterRec: ""}));
      dispatch(Actions.setStore({ billingDt, masterRec }));
    }
  }, []);

  // 基本設定項目の確認
  if (loadingStatus.loaded && !comAdic){
    return(
      <StdErrorDisplay 
        errorText = '請求設定項目が未設定です。'
        errorSubText = {`請求開始に必要な基本設定項目がありません。設定メニューの
        「請求・加算」から定員や地域区分などを設定して下さい。`}
        errorId = 'E49423'
      />
    )
  }
  

  // csv作成
  const callCsv = () => { 
    sendAndMakeCsv(prms, userList, jougenKubun);
  }
  const jougenOptionsVal = [
    {name: '新規', value: 1},
    {name: '修正', value: 2},
    {name: '削除', value: 3},
  ];
  const jougenOptions = jougenOptionsVal.map(e=>{
    return(
      <option key={e.value} value={e.value}>{e.name}</option>
    );
  });
  const handleChange = (ev) => {
    const val = ev.currentTarget.value;
    setJougenKubun(val);
  };

  // 送付処理を行うかどうか。送付済みのときはボタン自体が表示されないはず。
  const TransferButtons = () => {
    const bros = users.filter(e=>e.brosIndex);
    let str1;
    let transferToKKH = false;  // 国保連送付済み
    // db登録済みデータが存在するとき
    if (fetchStatus.data.dt.length) {
      const gen = fetchStatus.data.dt[0].gen; //送信済み日付
      const transferd = fetchStatus.data.dt[0].sent; // 国保連伝送日付
      // 日付が入っていない場合は未伝送
      if (transferd.indexOf('0000-00') === 0) { 
        str1 = thisMonth + 'のデータは' + gen + 
        'に伝送リクエスト済みです。' + '再度リクエストを行いますか？'
      }
      else {
        str1 = thisMonth + 'のデータは' + 
          transferd + 'に伝送が完了しました。'
        transferToKKH = true;
      }
    }
    const msg = (str1) ? str1 : str;
    console.log(csvUrl, 'csvUrl');
    return(<>
      <Links />
      <CheckProgress inline />
      <BrosInfo bros={bros} />
      <div className="AppPage account">
        {/* <div className='billingPanel'>
          <div className="massage">{msg}</div>
          {!transferToKKH &&
            <>
              <mui.ButtonGP
                color='primary'
                label='はい'
                addictionclass='accountButtonGP'
                onClick={clickHandler}
              />
              <mui.ButtonGP
                color='secondary'
                label='いいえ'
                addictionclass='accountButtonGP'
              />
            </>
          }

        </div> */}
        <div className={'billingPanel short ' + classes.createCsv}>
          {Object.keys(csvUrl).length === 0 &&
            <mui.ButtonGP
              color='primary'
              label='CSV作成'
              onClick={callCsv}
            />
          }
          {(Object.keys(csvUrl).length > 0 && csvUrl.result) && <>
            <a href={csvUrl.zip} className={classes.dlBtn}>
              <mui.ButtonGP
                color='secondary'
                label='一括ダウンロード'
              />
            </a>
            <a href={csvUrl.billing} className={classes.dlBtn}>
              <mui.ButtonGP
                // color='secondary'
                label='請求'
              />
            </a>
            <a href={csvUrl.upperLimit} className={classes.dlBtn}>
              {(csvUrl.upperLimitLen) > 0 &&
                <mui.ButtonGP
                  // color='secondary'
                  label='上限管理'
                />
              }
            </a>

            <a href={csvUrl.useResult} className={classes.dlBtn}>
              <mui.ButtonGP
                // color='secondary'
                label='提供実績'
              />
            </a>
          </>}
          {(Object.keys(csvUrl).length > 0 && !csvUrl.result) &&
            <div>通信エラーが発生しています。通信状況を見直しして再度実行して下さい。
              何度も発生するようならサポートに連絡をお願いします。
            </div>
          }
        </div>
        {/* <SalesAccordion /> */}
        <div style={{textAlign:'center'}}>
          <FormControl style={{margin: 8, minWidth: 150}}>
            <InputLabel >上限管理作成区分</InputLabel>
            <Select
              native
              value={jougenKubun}
              name={'jougenKubun'}
              onChange={(e) => handleChange(e)}
            >
              {jougenOptions}
            </Select>
          </FormControl>
        </div>
        <ServiceCountNotice />
      </div>

    </>)
  }
  const FetchLoading = () =>(
    <LoadingSpinner/>
  )
  const TransferLoading = () =>(
    <div className="AppPage account">
      <div className="billingPanel">送信中です。</div>
    </div>
  )
  const TransferErr = () => (
    <div className="AppPage account">
      <div className="billingPanel">送信エラーが発生しています。</div>
    </div>
  )
  const usProps = {userList, setUserList, dialogOpen, setDialogOpen,}
  if (permission < 90) return <PermissionDenied marginTop='120' />
  
  if (!loadingStatus.loaded)  return <LoadingSpinner/>
  else if (!fetchStatus.done) return <FetchLoading/>
  else if (!sendStatus.done) return (<>
    <UserSelectDialog 
      open={dialogOpen} setOpen={setDialogOpen}
      userList={userList} setUserList={setUserList}
    />
    <UserSelectButton {...usProps} />
    <TransferButtons />
  </>)
  else if (sendStatus.loading) return <TransferLoading />
  else if (sendStatus.done && !sendStatus.err) return (<>
    <UserSelectDialog 
      open={dialogOpen} setOpen={setDialogOpen}
      userList={userList} setUserList={setUserList}
    />
    <UserSelectButton {...usProps} />
    <TransferDone />
  </>)
  else if (sendStatus.done && sendStatus.err) return <TransferErr />
}

export default Billing;