import React, { useEffect, useState } from 'react';
import * as Actions from '../../Actions';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Snackbar } from '@material-ui/core';
import * as comMod from '../../commonModule';
import * as albCM from '../../albCommonModule';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
// import { useStyles } from './FormPartsCommon';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Link, useLocation } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import teal from '@material-ui/core/colors/teal';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import { grey } from '@material-ui/core/colors';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator, faSleigh } from "@fortawesome/free-solid-svg-icons";
import { setBillInfoToSch, } from '../Billing/blMakeData';
import PrintIcon from '@material-ui/icons/Print';
import SnackMsg from './SnackMsg';
import { faLess } from '@fortawesome/free-brands-svg-icons';
import CallMergeIcon from '@material-ui/icons/CallMerge';

const iconStyle = { padding: 0, fontSize: 18, marginRight: 8 };
const useStyles = makeStyles({
  cheeckBoxRoot: {
    padding: 8,
    paddingLeft: 16,
    maxHeight: '75vh',
    '& .MuiCheckbox-root': {
      padding: 4,
    },
  },
  userSelectDialog:{
    padding: 4,
    borderRadius: 2,
  },
  closeButton: {
    // position:'relative',
    // top: 0,
    // right: 0,
  },
  dialogTitle:{
    padding: 0,
    paddingLeft: 26,
  },
  scrollBody: {
    padding: '8px 16px 16px',
  },
  linktabRoot: {
    position:'fixed',
    top: 47,
    width: '100vw',
    zIndex: 998,
    '& > .MuiButton-text': {
      // margin: theme.spacing(1),
      padding: 0,
    },
  },
  errDisplay:{
    marginTop: '20vh', color: teal[500],
    '& p': {padding: 8},
    '& .link' : {
      color: blue[500],
      '& a' : {color : red[500]},
    },
  },
  serviceSnack: {
    '& .MuiSnackbarContent-root':{
      marginTop: '35vh', textAlign: 'center', color: '#fff',
      background: teal[500],
      '& .MuiSnackbarContent-message': {margin:'0 auto'},
    },
  },
  serviceSnackHD: {
    '& .MuiSnackbarContent-root':{
      marginTop: '35vh', textAlign: 'center', color: '#fff',
      background: blue[500],
      '& .MuiSnackbarContent-message': {margin:'0 auto'},
    },
  },
  LoadErrRoot: {
    width: '80%', maxWidth: 600, margin: '60px auto', 
    '& .detail': {
      wordBreak: 'break-all', fontSize: '.8rem', color: grey[500],
      padding: '8px 0', lineHeight: 1.6,
    },
    '& .errId': {paddingTop: 24, color:teal[700]},
    '& .imgWrap': {textAlign: 'center', padding: 24,},
    '& .mainMsg': {
      color: red[700], fontSize: '1.2rem', padding: '16px 0',
    },
    '& .subMsg': {
      '& > p': {
        padding: '8px 0',lineHeight: 1.4, 
        '& > a': {fontSize: '1.4rem', color: teal[600], fontWeight: 600,},
      }
    }

  },
  stdErrDisp: {
    width: '80%', maxWidth: 600, margin: '60px auto', 
    '& .detail': {
      wordBreak: 'break-all', color:grey[800], fontSize:'.9rem',
      padding: '4px 0', lineHeight: 1.6,
    },
    '& .detailHead': {
      padding: '16px 0 4px', fontSize:'.8rem',
      borderBottom: '1px solid ' + grey[200], color: grey[600],
    },
    '& .errId': {paddingTop: 24, color:teal[700]},
    '& .imgWrap': {textAlign: 'center', padding: 24,},
    '& .mainMsg': {
      color: red[700], fontSize: '1.2rem', padding: '16px 0',
    },
    '& .subMsg': {
      '& > p': {
        padding: '8px 0',lineHeight: 1.4, 
        '& > a': {fontSize: '1.4rem', color: teal[600], fontWeight: 600,},
      }
    }

  },
  printCntRoot: {
    width: '80%',
    textAlign: 'center',
    margin: '0 auto',
    backgroundColor: '#ffffff66',
    padding: 8,
    top: 60,
    left: '10%',
    // transform: 'translate(-50%, 0)',
    '& .MuiButtonBase-root': {
      width: 200,
      margin: '0 8px',
      '& .MuiSvgIcon-root': {
        marginInlineEnd: '8px',
      },
    },
    '& .subButtons': {
      padding: 8,
      '& .MuiButtonBase-root': {
        width: 'auto',
      },
    },
  },
  excahngeTotalizeButton:{
    '& .MuiSvgIcon-root': {
      transform: 'scale(1,-1)',
    },
  },

});
// サービスが変更されたときにスナック表示を行う
// serviceItemsが一つのときは表示されない
export const ServiceNotice = () => {
  const service = useSelector(state=>state.service);
  const serviceItems = useSelector(state=>state.serviceItems);
  const [snack, setSnack] = useState({open: false, msg: ''});
  const classes = useStyles();
  useEffect(()=>{
    setSnack({open: true, msg: service});
  }, [service]);
  const handleClose = () =>{
    setSnack({...snack, open: false});
  }
  const key = new Date().getTime();
  if (serviceItems.length === 1)  return null;
  const snackClass = (service==='放課後等デイサービス')
  ? classes.serviceSnack: classes.serviceSnackHD;
  return(
    <Snackbar
      className={snackClass}
      anchorOrigin={{ vertical: 'top', horizontal:'center'}}
      autoHideDuration={2000}
      open={snack.open}
      onClose={handleClose}
      message={snack.msg}
      key={key}
    />
  )
}

export const LoadingSpinner =()=>{
  return(
    <div className='loading'>
      <img src="./img/loading3dRing.png" />
      {/* <img src="./img/loading3dSq.png"/> */}
    </div>
  )
}


// 日毎の加算の数をドットで表現
// maxdotの値で何ドットまでドット数で表示するか。
// maxdot以上ならドットxで表示する
// maxdot -1ならフォントサイズを小さくする
export const DAddictionContent = (props) => {
  const { did, maxdot, ...other } = props;
  const maxdot_ = (maxdot === undefined) ? 4 : maxdot;
  const schedule = useSelector(state => state.schedule);
  const service = useSelector(state => state.service);
  // 日毎の加算項目をカウント
  const countAddiction = () => {
    if (!schedule)  return 0;
    if (!schedule[service]) return 0;
    if (!schedule[service][did]) return 0;
    return (Object.keys(schedule[service][did]).length);
  }
  const c = countAddiction();
  if (c >= maxdot_) return (
    <div className="dAddiction">
      <FiberManualRecordIcon fontSize='inherit' />{c}
    </div>
  );
  const a = new Array(c).fill(1);
  const b = a.map((e, i) => {
    return <FiberManualRecordIcon key={i} fontSize='inherit' />
  });
  let styleObj = {};
  // styleObj.fontSize = (c === maxdot_ - 2) ? '.5rem' : 'inherit';
  // styleObj.fontSize = (c === maxdot_ - 1) ? '.4rem' : 'inherit';
  if (c === maxdot_ - 2) styleObj = { fontSize: '.5rem' };
  if (c === maxdot_ - 1) styleObj = { fontSize: '.4rem' };

  return (
    <div className="dAddiction" style={styleObj}>
      {b}
    </div>
  )
}
// ドキュメント印刷などのときにユーザーの選択を行う
// ユーザーのリストはチェックボックスやラジオボタンに利用する
// boolenの値を持ったstateを受け取る
// [{uid:xxx, checked:true/false}, ...]
// ダイアログ自体を提供する
// open closeは親コンポーネントのstateで制御される
// type = 0 : チェックボックス 1 : ラジオボタン
// ラジオボタンはまだ作ってない
export const UserSelectDialog = (props) => {
  const classes = useStyles();
  const { 
    open, setOpen, userList, setUserList, type, ...other 
  } = props;
  const nodeType = (type)? type : 0;
  // checkAllの初期値を決める userListに一個でもfalseがあったらfalse
  let chk = true;
  userList.map(e=>{if (!e.checked) chk = false;});
  const [checkAll, setCheckAll] = useState(chk);
  const users = useSelector(state=>state.users);
  const makeUserLabel = (uid) => {
    const r = comMod.getUser(uid, users);
    return (r.name + ' ' + r.ageStr);
  }
  const handleChange = (ev) => {
    const euid=ev.currentTarget.getAttribute('name');
    console.log('euid', euid);
    const i = userList.findIndex(_=>_.uid === euid);
    const t = [...userList];
    const c = t[i].checked ? false : true;
    t[i] = {...t[i], checked:c};
    setUserList(t);
  }
  const handleChangeAll = (ev) => {
    const c = checkAll ? false: true;
    setCheckAll(c);
    const t = [...userList];
    t.map(e=>{
      e.checked = c;
    });
    setUserList(t);
  }
  const nodesChekBox = userList.map((e, i)=>{
    return(
      <FormControlLabel key={i}
        control={
          <Checkbox checked={e.checked} 
          onChange={(e)=>handleChange(e)} name={e.uid} />
        }
        label={makeUserLabel(e.uid)}
      />
    )
  });
  return (<>
    <Dialog className={classes.userSelectDialog}
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle className={classes.dialogTitle}>
        <FormControlLabel
          control={
            <Checkbox checked={checkAll}
              onChange={(e) => handleChangeAll(e)} name='all' />
          }
          label='すべて選択'
        />
        <IconButton 
          color="primary" className={classes.closeButton}
          onClick={()=>setOpen(false)}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={true} className={classes.scrollBody}>
        <div className={classes.cheeckBoxRoot}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup>
              {nodesChekBox}
            </FormGroup>
          </FormControl>
        </div>
      </DialogContent>
    </Dialog>
  </>)
}
/// 画面上部に表示するサブメニュー
// propsにはメニュー表示用の配列
// 配列のlinkはrouterで指定されている必要がある
export const LinksTab = (props) => {
  const classes = useStyles();
  // react-routerからロケーション取得
  const ref = useLocation().pathname;
  // const menu = [
  //   { link: "/billing", label: "請求送信" },
  //   { link: "/billing/upperlimit", label: "上限管理" },
  // ];
  const menu = props.menu;
  const linkList = menu.map((e, i) => {
    let cls = (ref === e.link) ? 'current' : '';
    return (
      <Button key={i} >
        <Link className={cls} to={e.link}>{e.label}</Link>
      </Button>
    )
  });
  return (<>
    <div className={'linksTab ' + classes.linktabRoot}>
      {linkList}
    </div>
  </>);
}
// エラー表示用
export const ErrorBoundaryDisplay = () => {
  // const classes = useStyles();
  return (
    <div className='errDisplay'>
      <p className='msg'>申し訳ありません。何か問題が発生しているようです。</p>
      <p className='link'><a href='/'>こちらを</a>クリックして同じ操作で同じエラーが発生する場合、管理者またはサポートに連絡して下さい。</p>
    </div>
  )
}

// ユーザーごとの加算をドットで表現
// 結局、オブジェクトのキーの長さをドットで返すだけのComponent
export const Uaddiction = (props)=>{
  if (!props) return null;
  const l = Object.keys(props).length;
  if (l > 6){
    return (
      <FiberManualRecordIcon fontSize='inherit' /> + l
    )
  }
  const c = Object.keys(props).map((e, i)=>{
    return(<FiberManualRecordIcon fontSize='inherit' key={i} />);
  });
  return c;
};

export const LoadErr = (props) => {
  const {loadStatus, errorId} = props; // lsはローディングステイタス
  const classes = useStyles();
  const stdDate = useSelector(state => state.stdDate);
  const hid = useSelector(state => state.hid);
  const bid = useSelector(state => state.bid);
  const weekDayDefaultSet = 
    useSelector(state => state.config.weekDayDefaultSet);
  const dispatch = useDispatch();
  const handleClick = () =>{
    const prms = {set: 0, stdDate, hid, bid, weekDayDefaultSet, dispatch, };
    albCM.setMonth(prms);

  }
  return(
    <div className={classes.LoadErrRoot}>
      <div className='imgWrap'>
        <img src={`${window.location.origin}/img/errlogoRed.svg`} 
          width="120px" alt="logo" 
        />
      </div>

      <div className='mainMsg'>読み込みエラーが発生しました。</div>
      <div className='subMsg'>
        <p>インターネット接続が不安定なのかも知れません。
          接続を確認してから<a href='/'>こちら</a>をクリックして下さい。</p>
        <p>
          インターネット接続が問題ないにも関わらず
          この画面が何度も表示されるときはサポートにご連絡をお願いします。
        </p>
        <p>
          利用開始前の月を表示しようとするとこのエラーが発生します。<br></br>
          <a onClick={handleClick}>こちら</a>をクリックして当月表示に切り替えて下さい。
        </p>
      </div>
      <div className='errId'>{errorId}</div>
      <div className='detail'>{JSON.stringify(loadStatus)}</div>
    </div>
  )
}
// 普通のエラー表示
export const StdErrorDisplay = (props) => {
  const {errorText, errorSubText, errorId, errorDetail} = props;
  const classes = useStyles();
  return (
    <div className={classes.stdErrDisp}>
      <div className='imgWrap'>
        <img src={`${window.location.origin}/img/errlogoRed.svg`} 
          width="120px" alt="logo" 
        />
      </div>
      <div className='mainMsg'>{errorText}</div>
      <div className='subMsg'>
        <p>
          インターネット接続が不安定なのかも知れません。
          接続を確認してから<a href='/'>こちら</a>をクリックして下さい。</p>
        <p>
          {errorSubText}
        </p>
      </div>
      <div className='errId'>{errorId}</div>
      <div className='detailHead'>エラー内容詳細</div>
      <div className='detail'>{errorDetail}</div>

    </div>
  )
}

// 再計算ボタンと印刷ボタンを提供
// 印刷ボタンは考え直すかも
export const RecalcButton = (props) =>{
  const classes = useStyles();
  const dispatch = useDispatch();
  const stdDate = useSelector(state => state.stdDate);
  const schedule = useSelector(state => state.schedule);
  const users = useSelector(state => state.users);
  const com = useSelector(state => state.com); // 会社と事業所の情報
  const serviceSt = useSelector(state => state.service);
  const serviceItems = useSelector(state => state.serviceItems);
  const {hidePrint} = props;
  // サービス未設定のときの設定を行う
  const service = (serviceSt) ? serviceSt : serviceItems[0];
  const billingDt = useSelector(state => state.billingDt);
  const clickHandler = () => {
    const prms = { 
      stdDate, schedule, users, com, service, serviceItems, dispatch 
    };
    const { billingDt:bdt, masterRec, result, errDetail } = setBillInfoToSch(prms);
    if (!result){
      dispatch(Actions.setSnackMsg(errDetail.description, 'error', 'B236709'));
    }
    dispatch(Actions.setStore({ billingDt: bdt, masterRec }));
    // setproseedDatas(makeData(prms));
  }
  return(<>
    <div className={classes.printCntRoot + ' noprint'} >
      <Button
        onClick={clickHandler}
        variant='contained'
      >
        <FontAwesomeIcon icon={faCalculator} style={iconStyle} />
          売上計算する
        </Button>
    </div>
    {(billingDt && !hidePrint) &&
      <div className={classes.printCntRoot + ' noprint'}>
        <Button
          onClick={() => { window.print() }}
          variant='contained'
        >
          <PrintIcon />
              印刷する
          </Button>
      </div>
    }

  </>)
}
// ブラウザチェックを行う
// chrome以外の場合は警告する
export const BrowserCheck = () => {
  const ua = window.navigator.userAgent.toLowerCase();
  let browser, match, error = false, detect = true;;
  if(ua.indexOf('msie') != -1 || ua.indexOf('trident') != -1) {
    browser = 'Internet Explorer';
    match = false;
    error = true;
  }else if(ua.indexOf('edge') != -1) {
    //旧Edge向けの記述
    browser = '古いバージョンのEdge';
    match = false;
    error = true;
  }else if(ua.indexOf('chrome') != -1) {
    browser = 'Google Chrome';
    match = true;
  }else if(ua.indexOf('safari') != -1) {
    browser = 'safari';
    match = false;
  }else if(ua.indexOf('firefox') != -1) {
    browser = 'firefox';
    match = false;
  }else{
    detect = false;
    match = false;
  }
  const fontSize = {fontSize: '1.4rem'}
  const DispError = () => (
    <div style={{padding:8, color:red[900], ...fontSize}}>
      {browser}は非対応なのでご利用いただけません。
    </div>
  )
  const DispWarning = () => (
    <div style={{padding:8, color:blue[900], ...fontSize}}>
      お使いのブラウザは{browser}です。Google Chromeのご利用を推奨します。
    </div>
  )
  const NotDetect = () => (
    <div style={{padding:8, color:red[900], ...fontSize}}>
      お使いのブラウザを検出できませんでした。Google Chromeのご利用を推奨します。
    </div>
  )
  if (error){
    return (<DispError />)
  }
  else if (!match){
    return(<DispWarning />)
  }
  else if (!detect){
    return(<NotDetect/>)
  }
  else return null;

}

export const PermissionDenied = (props) => {
  const {marginTop} = props;
  const style = {marginTop: parseInt(marginTop)};
  style.textAlign = 'center';
  return (
    <div style={style}>
      このページを表示する権限がありません。
    </div>
  )
}

// 汎用テーブルであるsomestateに売上情報を送信
// propsでdisplayButtonが指定されていたら
// ボタンを表示する
export const SendBillingToSomeState = (props) => {
  const {
    displayButton,  // 送信ボタンを表示するか。表示しないときは自動送信
    sendAnyTime,    // 自動送信時毎回送信するかに,Cookieを見て一日一回送信に限定するか
  } = props;

  const allState = useSelector(state=>state);
  const [snack, setSnack] = useState({msg: '', severity: ''});
  // const {
  //   stdDate, schedule, users, com, serviceItems, account,
  //   hid, bid,
  // } = allState;

  const stdDate = useSelector(state=>state.stdDate);
  const schedule = useSelector(state=>state.schedule);
  const users = useSelector(state=>state.users);
  const com = useSelector(state=>state.com);
  const serviceItems = useSelector(state=>state.serviceItems);
  const account = useSelector(state=>state.account);
  const hid = useSelector(state=>state.hid);
  const bid = useSelector(state=>state.bid);

  const ls = comMod.getLodingStatus(allState);
  const service = ''; // サービスは未指定で
  const sbid = bid.slice(-4) + stdDate.slice(5, 7); // bid下4桁と月を示す二桁
  const cookieName = 'sentBdt';
  const thisCookie = comMod.getCookeis(cookieName)
  ?comMod.getCookeis(cookieName): '00000000';
  const cookieDate = thisCookie.slice(0, 8); // 日付部分
  const thisDate = comMod.formatDate(new Date(), 'YYYYMMDD');
  // 事業所番号の下四桁を探して送信済みかどうか 最初は日付が入っている。0以上で探す。
  const sentBid = thisCookie.indexOf(sbid) > 1;
  // Cookieの日付的に自動送信するべきか
  const judgeCookieDate = thisDate > cookieDate;
  // propsとcookieの値で自動送信するべきか
  const dataToBeSend = sendAnyTime || (judgeCookieDate || !sentBid);

  const sendIt = async () => {
    // 売上計算用パラメータ
    const prms = {stdDate, schedule, users, com, service, serviceItems};
    let billingDt = [], masterRec ={};
    // サービスアイテムに対応した事業所加算情報があるか
    // stateの更新が間に合わないことがあるっぽい
    let existComAddic = true;
    serviceItems.forEach(e=>{
      if (!com.addiction[e])  existComAddic = false;
    })
    if (ls.loaded && !ls.error && existComAddic){
      const r = setBillInfoToSch(prms);
      billingDt = r.billingDt? [...r.billingDt]: [];
      masterRec = r.masterRec? {...r.masterRec}: {};
    }
    // 請求データなし
    if (!billingDt.length) return false;
    // billingDtを間引きする did以下を削除
    billingDt.forEach(e=>{
      // didの値を配列化
      const t = Object.keys(e).filter(f=>f.match(/^D2[0-9]{7}/));
      t.forEach(f=>{
        delete e[f];
      })
    });
    // someStateに保管するパラメータ
    const somState = {billingDt, masterRec, com, };
    const sendPrms = {
      date: stdDate, jino: account.jino, keep: 60, hid, bid,
      item: 'billingDt', state:JSON.stringify(somState),
    }
    if (!stdDate) return false; // stddateが設定されていないことがあるっぽい
    await albCM.sendSomeState(
      sendPrms, '', setSnack, '売上情報を更新しました。'
    );
    const dateFormated = comMod.formatDate(new Date(), 'YYYYMMDD');
    let bidStr = thisCookie.slice(8); // Cookieのbids連結部分
    bidStr = bidStr + sbid + '';
    // 4事業所以上は保存しない 
    if (bidStr.length > (sbid.length * 4))  bidStr = bidStr.slice(sbid.length);
    comMod.setCookeis(cookieName, dateFormated + bidStr);
  }
  // メモリリークのワーニング
  // useEffect(()=>{
  //   const ls = comMod.getLodingStatus(allState);
  //   return (()=>{
  //     const f = async () => {
  //       await sendIt();
  //     }
  //     if (ls.loaded && !ls.error && !displayButton && dataToBeSend){
  //       f();
  //     }
  //   });
  // }, [allState])

  // メモリリーク対策。こんな簡単でいいの？
  useEffect(()=>{
    const ls = comMod.getLodingStatus(allState);
    let isMounted = true;
    const f = async () => {
      await sendIt();
    }
    if (ls.loaded && !ls.error && !displayButton && dataToBeSend && isMounted){
      f();
    }
    return (()=>{
      isMounted = false;
    });
  }, [serviceItems])


  if (!ls.loaded) return null;
  if (ls.error) return null;
  return (
    <div>
      {displayButton === true &&
        <Button
          onClick={sendIt}
          color='primary' variant='contained'
        >
          売上データの送信
        </Button>
      }
      <SnackMsg {...snack}/>
    </div>
  )
}

// わんぱく会CSV取り込み用の
// g formを起動する
export const WanpakuImportButton = () => {
  const allState = useSelector(state=>state);
  const [snack, setSnack] = useState({msg: '', severity: ''});
  const ls = comMod.getLodingStatus(allState);
  const {account, stdDate, hid, bid} = allState;
  const sendPrms = {
    date: stdDate, jino: account.jino,
    item: 'wanpakuImport', state:JSON.stringify(account)
  }
  const handleClick = async () => {
    await albCM.sendSomeState(
      sendPrms, '', setSnack, '集計用パラメータを送信しました。'
    );
  }
  if (!ls.loaded) return false;
  if (ls.error) return false;
  const url 
    = 'https://docs.google.com/forms/d/e/'
    + '1FAIpQLSeNXLVwZHxWLgco-DvcboCPhIBac1nsumxZVNZh74PAIwMI9Q/'
    + 'viewform?usp=pp_url&'
    + `entry.790061844=${account.jino}&entry.477714010=${stdDate}`;
    
  return (
    <div>
      <a href={url} target='_blank'>
        <Button
          onClick={handleClick}
          color='primary' variant='contained'
        >
          データ取り込み
        </Button>
      </a>
    </div>
  )
}


// 口座振替ファイルの集計を行うgoogleフォームで使用する情報の送信を行い
// g formを起動する
export const ExcahngeTotalizeButton = () => {
  const allState = useSelector(state=>state);
  const classes = useStyles();
  const [snack, setSnack] = useState({msg: '', severity: ''});
  const ls = comMod.getLodingStatus(allState);
  const {account, stdDate, hid, bid} = allState;
  const sendPrms = {
    date: stdDate, jino: account.jino,
    item: 'excangeTotalize', state:JSON.stringify(account)
  }
  const handleClick = async () => {
    await albCM.sendSomeState(
      sendPrms, '', setSnack, '集計用パラメータを送信しました。'
    );
  }
  if (!ls.loaded) return false;
  if (ls.error) return false;
  const url 
    = 'https://docs.google.com/forms/d/e/'
    + '1FAIpQLSeOEq9bgjn1fBhZv2xJXJI3JttmL7QkqTqK_kMKLKESl3RalA/'
    + 'viewform?usp=pp_url&'
    + `entry.1271751265=${account.jino}&entry.824786232=${stdDate}`;
  
  return (
    <div>
      <a href={url} target='_blank'>
        <Button
          className={classes.excahngeTotalizeButton}
          onClick={handleClick}
          color='primary' variant='contained'
          startIcon={<CallMergeIcon/>}
        >
          口座振替集計
        </Button>
        <div style={{fontSize: '0.7rem', color: teal[800], paddingTop: 8}}>
          外部ツールが起動します。
        </div>
      </a>
    </div>
  )
}
