import React from 'react';
const rev = 2622.1;
export const Rev = (props)=>{
  const {short} = props;
  const l = short? 'r.': 'rev';
  return (<span>{l + rev}</span>)
}
export default Rev;

// 2622.1
// わんぱくフォーム起動パラメータ追加

// 2621
// 提供実績管理票のタイトルが放デイ固定だった。児発のときは児発に変更
// fsconでエクセルファイルの不正キャラクター対策。
// fsconで.xlsファイルアップロードできないように対策強化
// fscon注意書きを変更
// 他事業所売上取得用の情報送信を開始

// 2620
// 通所給付明細が出力できないバグフィックス。

// 2619
// 利用者別月次日程設定 休業日に予定を入れない処理を行っていたが廃止 -> 復活
// 日曜日で稼働日の色を変更した。

// 2618
// 振替口座集計 テスト実装

// 2617
// 利用者別月次日程設定 休業日に予定を入れない処理を行っていたが廃止

// 2616
// 振替口座集計 テスト実装

// 2615
// 帳票関係のバグフィックスさらに。
// 多子軽減措置の追加修正

// 2614
// 帳票関係のバグフィックス

// 2613
// 通所給付明細東京形式しか出力できないのを標準形式のみにする。とりあえず。

// 2612
// 多子軽減対応

// 2611
// 帳票の権限見直し

// 2610
// 兄弟ともに管理事業所の場合、上限管理結果票が出力されてしまうのを抑制

// 2609
// 児発しかない場合地域区分コードを出力しないバグを修正


// 2608
// 提供実績サービス種別の誤記訂正

// 2607
// 上限管理引当方法変更
// 2022-06以降に計算方法を変更を適用。それ以前は従来どおり

// 2606
// 代理受領通知更新

// 2605
// 横浜再調整

// 2603
// 横浜上限菜調整

// 2602
// 横浜上限出力調整
// 横浜上限のせいで上限管理が壊れていたのを訂正

// 2601
// 横浜上限弟のみの判定基準追加
// 管理結果が未設定、横浜市、管理タイプ協力事業所

// 2600
// 横浜市動作確認済み

// 2599
// 横浜市だけ変更を入れるバージョン

// 2598
// 自己負担引当優先変更暫定バージョン

// 2597
// 自分自身に兄弟設定されていたら兄弟判定するバグをfix
// getBrothersを修正した

// 2596
// k122_1の月額上限調整額の出力で兄弟上限の判定をisKyoudaiJougenを使って厳密に行う
// ようにした

// 2595
// 上限管理自動設定が反映されなくなるのを直した

// 2594
// 兄弟上限ダイアログが協力事業所等のデータを破壊するバグをfix
// 上限管理手動設定が反映されないことがあるbug fix

// 2593
// 兄弟上限バグフィックス
//

// 2592
// 提供実績出力時に行数指定と白紙出力の追加

// 2591
// 兄弟上限の兄弟の弟の上限管理結果を手動で変更できるようにした

// 2590
// 兄弟上限の判定モジュールを作成し兄弟上限ＣＳＶ出力時の判断基準にする

// 2589;
// 放デイに児発無償化が引き継がれるバグフィックス

// 2587
// 上限管理画面に兄弟のみを検索するボタンを追加

// 2586
// 週間予定のバグ？fix 月初に実行するとフリーズする

// 2585
// 売上管理画面で金額のズレが生じることがあるのを修正

// 2584
// 上限管理区分手動設定など

// 2583
// 兄弟組されているが弟の利用がない場合、兄弟上限を中止にする

// 2582 0522
// アカウントリセットと削除を追加

// 2581 0519
// 上限管理入力で管理事業所に不正なデータが入っているときにエラーで落ちるのを抑制
// アカウントのパスワードリセットなどのモジュールを実装中

// 2580 0515
// スケジュールの削除を実装。スケジュールは月のスケジュール表示で自動作成されるので事実上の
// リセット
// Cookieの削除を実装
// SetUiCookies を移動。今のところ「文字入力時の自動選択」
// 兄弟上限のときにデフォルトで上限管理加算が付与される問題を修正
// 他事業所の修正で、他事業所の情報削除を追加

// 2579
// com.templateにclassroomが書き込まれている場合がある
// その場合、classroomが間違えて付与される
// template.classroomをスケジュール追加時に無視するように変更

// 2578
// 部分請求を実施したときに集計データなどがおかしくなる問題を解決

// 2577 
// 兄弟上限のときの上限月額調整額で差異が出る問題を対策
// 兄弟上限で一人だけのデータなどを出すときに関連情報がないため上限管理事業所等に欠落が出る
// 問題対応

// 2576 20220505
// 実費項目入力時に既存の項目を入力不可とした

// 2575
// 兄弟区分未入力のときに0を自動入力

// 2574 0504
// ユーザーがいないときの請求設定・スケジュール関連設定画面の変更
// ユーザーがいなければエラー表示にした
// getDbnameの通信トリガ見直し。何度も問い合わせを行っているのでwaitを入れて.5秒遅延させた
// db名をストアにデdispatchするようにした
// ユーザー削除の見直し 当月以前は削除しない
// 地域区分、級地、定員などが未設定の場合、請求や予定作成などでエラーを出力するようにした
// ユーザー1名はユーザー削除不可。ユーザー０は設定できないようにしたがその場合、スケジュール設定
// 請求設定などが設定できないようにした

// 2573 0504
// 2572, 2571 の問題を対策済み

// 2572 0504
// 前のバージョンで余分な請求データが出力されているのを抑制。
// 事実上、前前バージョンに戻しているので対策が必要

// 2571
// schedule初期化後にスケジュール追加せずに請求データ作成処理のみ行うと
// フリーズする問題を解決
// 兄弟上限作成時に兄に上限管理加算を付与できるよう変更

// 2570
// 請求書等で実費追加項目に対し項目名でソートを実施

// 2569
// 兄弟上限管理票で長兄がトップに来ないことがあるので調整

// 2568 0429
// 売上概要に利用回数情報を追加
// 稼働率表示の利用回数計算をgetScheduleInfoに統一

// 2567 0425
// ログイン周りにバグあり。訂正。

// 2566 0425
// 協力事業所から非該当に変更になった場合、協力事業所番号がCSVに出力されてしまうのを抑制

// 2565 0425
// 利用者別日別設定実績ボタンの追加

// 2564 0423
// 利用者情報、事業所情報の有効範囲を表示

// 2563 0418
// アカウント切替時の月が強制的に飛ばされるのを制御。飛ぶ前にデータが存在するかどうか確認して
// 出来るだけ現在の月を保持するようにする
// 利用者別予定、習慣予定で稼働率がリアルタイム反映されないのをフィックス
// 多機能事業所でサービス別にコマ数を表示。


// 2562 0415
// 利用者別一覧入力通信エラー発生することがあるバグフィックス

// 2561 0415
// 実費追加項目の対応

// 2560
// 兄弟間調整の調整方法を調整

// 2559
// userbilling 日付上限値。現在日付より+95日で設定

// 2558
// 兄弟上限再調整

// 2557
// 兄弟上限、児発放デイ跨ったパターンを一応、対応 


// 2556 0411
// 利用予定詳細設定、時刻のチェック強化
// shift+enter送信時の入力チェック追加。

// 2555
// レビジョンをヘッダに表示
// レビジョンクリックでアップデート
// 18歳以上の年齢表示がおかしかったのを訂正
// 請求->口座振替のリンクにファクタリングを追加

// 2554 04/08
// 山陰信販ファクタリングを追加

// 2553
// 口座振替をapp router組み込み

// 2552
// 口座振替 ファイル作成CGIにFTP転送を仕込んでそれの対応

// 2551
// offschoolにundefinedが存在するため提供形態の値が間違えることがあるのを対策

// 2550
// 提供実績管理票 上の部分が切れるのを訂正

// 2549
// 上限管理事業所がだぶるのを抑止 再訂正

// 2548
// 上限管理事業所がだぶるのを抑止

// 2547 0404
// 協力事業所の入力範囲を６に拡張

// 2546 0331
// 月間予定ユーザーソート問題再調整

// 2545 0328
// マネージャーモードで事業所更新すると事業所番号が空白になる！！！-> fix
// ユーザーダイアログで 自動種別（障害児・重身など）とサービス種別（放デイ児発）で
// 未選択を不可とした
// 学齢の計算方法を変更。今日付基準ではなくstdDate基準

// 2544 0328
// 週間予定で週の変更時にstore stateのscheduleが更新されないバグをfix
// 週間予定で当月の場合は当日の週をカレントにするようにした

// 2543 0325
// メールアドレス確認時に空白文字チェック
// 複数単位事業所 利用者並び順設定変更。ただしMTUの位置は厳密に決められない。
// 帳票、月間予定で欠席時の表示が正しくないbug fix

// 2542 0325
// 利用者別予定に対して利用数表示を追加
// 複数単位事業所で複数事業所を同時に利用する場合の利用回数表示など改善

// 2541 0323
// MTU 対応強化

// 2540 0323
// 兄弟上限修正

// 2539
// ServiceCountNotice baseItemに問題がありますと表示れるのを訂正
// 多機能施設に対し機能ごとに単位を持つか、別々に持つかを指定可能にした
// 上記、サービスごとの単位により月間予定表示の利用数とOccupancyRateの表示を変更した
// ServiceCountNotice サービスごと単位 or not に対応
// ユーザーの画面、修正ボタンの状態、書き込み後、保持できていなかったのをstore state
// を使って保持。
// 帳票の変更。利用者請求計を記載追加。

// 2538 0317
// 請求書控え、受領書控えが印刷されない不具合修正
// HolidayOrNot 平日利用、休日利用がセレクトに反映されない不具合修正

// 2537 0316
// 浮動小数点の罠回避
// unitPrice使うときの計算方法変更
// 11340*11.20 -> 127007.99999999999
// 11340*(11.20*100)/100 -> 127008

// 2536 0316
// パスワードリセット 自動パスワードの登録

// 2535 03/16
// 提供実績ファイルの変更。医療連携加算データなどを追記。請求ファイルの利用回数を再調整

// 2534 03/14
// ユーザーごとの加算項目を追加。単位ごとに加配加算が変わることがあるので

// 2533 03/11
// ユーザーごとの加算項目を追加。単位ごとに加配加算が変わることがあるので

// 2532 2022/03/08
// 上限月額調整の計算式を変更したがもとに戻した。国保連ヘルプデスクでは
// 使っていない項目とのこと。なにそれ。
// 売上台帳 総合計欄を追加
// 月間利用予定 欠席対応加算表示

// 2531 2022/03/07
// ユーザー表示拡張
// 口座情報入力対応
// ログイン状態維持選択

// dbとcgi本番用切り替え機能

// 2528
// fscon調整 本番デプロイ済み

// 2527
// fscon調整 setMonth バグ発生fix

// 2525
// 再訂正
// FsCon 終了後のAPI受け取り

// 2524
// 再訂正

// 2523
// 家庭連携加算 請求データ作成時のバグfix again

// 2522 2022/02/18
// 家庭連携加算 請求データ作成時のバグfix

// 2521 2022/02/18
// 月間予定ユーザー加算項目が引き継がれていないバグ発見。
// 訂正して謎データ送信と謎ディスパッチが発生するのを抑制
// 上限管理無いときにcsv出力のボタンが表示されないように変更 zipの中身も削除するようにした
// 原則の日数に対応
// 18歳以上のご利用者対応強化
// 家庭連携加算 請求データの適正化

// 2520
// 提供実績管理票、欠席対応時の実費表示改善

// 2519
// 利用なし上限管理ありの請求データ差異調整

// 2516 2022/02/10
// 帳票 利用者負担額自治体助成がある場合の請求書
// 帳票 18歳以上のご利用者の帳票、請求対応
// 家庭内連携加算の入力方法変更対応中

// 2515 2022/02/08
// 帳票 月間利用予定のMTU対応
// 月間予定無駄Dispatchをなくして速度向上 changeService
// 請求CSV変更
// 上限管理加算のみの場合など。利用回数のカウント方法を変更 k122_2 k122_4
// 利用回数がない場合など k611_1出力抑制

// 2514 2022/02/07
// 18歳以上の請求データ、帳票の変更対応（受給者が保護者から本人になる
// 利用がない上限管理加算対応方法検討。自動で上限加算が付与されるようにした。

// 2513 2022/02/07
// 画面表示の改善 MTU対応強化
// 欠席利用しか無い利用者に対する処遇改善加算などが反映されていなかったのを訂正

// 2512 2022/02/02
// 利用者負担額一覧、帳票の改善
// 利用者別売上一覧。帳票の改善

// 2511
// 利用者別予定データ消失対策

// 2510
// 帳票月間利用、ユーザー指定すると表示が乱れるのを修正
// fscon関連 パラメータの追加など

// 2509
// fscon実装中 デベロッパーのみ

// 2508
// MTU対応多々
// 帳票でstateのbillingDtを参照するのをやめた
// 帳票画面のデザイン変更。日付設定対応拡大。

// 2507
// MTU 単単位で不具合

// 2506
// MTU 対応途中
// ユーザー別予定が機能していなかったのを修正

// 2505
// fscon err 実費の処理

// 2504
// FSCon読み込み時のエラー対策

// 2503
// MTU (複数単位利用者 multi tanni user) 対応途中

// 2502
// 上限管理Componentの変更
// 管理事業所以外は表示しない

// 2501
// 利用者データ履歴管理

// 2409
// Excel帳票の複数単位対応
// 利用確認表の追加
// 利用者一覧の追加
// Excel帳票等を通常の帳票で見分けがつきやすいようにアイコンを追加した。
// Excel帳票で通信エラーが発生することがあるのでエラー処理を強化した.
// 帳票の画面に売り上げ計算のボタンを追加。最新の売り上げ情報が反映されないことがあるので。
// 児童発達支援の請求項目等の精査、改善

// 2408
// パーミッション改善
// 児発個別サポート加算バグフィックス
// ユーザー一覧レポート追加
// レポート作成タイムアウトエラー検出

// 2407
// レポートと上限管理で単位の反映を強化
// 上限管理インターフェースの一部変更
// 上限管理の精度向上
// パーミッション改善 テスト不十分

// 2406
// パーミッションでclassroom制限をかけているアカウントログインエラー
// classroom が数字のみだとステイトに格納されている文字列と
// 一致しない
// スケジュールデータの初期化強化
// schedule[service][uid].addictionが存在しないときにエラー発生

// 2405
// パーミッションの設定にサービスと単位を追加した。
// パーミッションとCookieとの整合性の確保
// ログインを変更した時のパーミッションCookieの扱いを管理
// 上限管理変更時の送信の見直し。Snackの表示等
// カレンダー設定画面に送信ボタンを付与 遅延送信しないため

// 2404
// 上限管理更新時にスケジュールを送信していなかったのをフィックス。
// sendPartOfScheduleの結果を見ていないのでsnack等での監視を追加する。

// 2403
// エクセルファイルアップローダをテスト実装

// 2402
// 多単位同時書き込み対応
// 上限管理結果票の内容を訂正。調整額が１割相当額になっていた。

// 2401t
// 単位別同時書き込みにトライ

// 2302
// 本番環境のみserviceCountNoticeの出力がおかしい？
// -> billingDtをdispatchすると値がおかしくなる？？？
// dispatchした値を使わずにその都度演算

// 2301
// 本番環境のみserviceCountNoticeの出力がおかしい？
// local hostでは発生しないため再度デプロイ

// 2300 2021/11/04
// エラー対策強化
// （テスト実装）児童発達支援対応・児童発達支援無償化対応
// 機能していないメニュー項目の整理等
// 請求内容のチェック強化
// 上限管理更新時、即時画面反映に変更（再計算ボタン押下不要）
// 自治体助成金対応
// 売上計算の他単位、多機能計算対応。単位別、放デイ、児発それぞれで売上計算表示
// 請求書などの帳票が金額ゼロのものも出力されるのを修正
// 兄弟上限管理の対応
// その他、画面の調整、細かいバグの訂正等
// 帳票をファイル保存した時のファイル名をわかりやすく設定

// 22121
// 児初対応CSVバグフィックス

// 2211
// 児初対応CSVバグフィックス
// 上限管理で他事業所入力時、事業所番号未入力でも入力できてしまうバグ訂正
// 児初無償化でも上限管理してしまうバグ訂正

// 2210
// 児初対応CSVバグフィックス

// 2209
// 児初無償化、自治体補助に対応

// 2208
// 放デイ加算コード特定時、定員が正しく設定できていなかったのを訂正

// 2207
// 放デイの事業所全体の加算が取得できなくなっていたので訂正

// 2206
// 請求関係バグフィックス

// 2205 2021/10/18
// 児発の送迎回数カウントミス修正

// 2204
// 児発専用だと定員を取得できていないのを訂正

// 2203
// serviceItem更新時にservice stateの切り替えを追加 アカウント切替時に発現

// 2202
// 児発しかない場合、serviceItemが強制リセットされる不具合を訂正

// 2201 10/12
// 児発請求機能実装

// 2125 2021/09/25
// 上限管理機能強化。利用がなくても上限管理を付与できるようにした。
// 請求データに利用なしのデータを含めるために大幅な変更を行った。
// エラー関連の表示を強化

// 2124 2021/09/05
// 縦型利用者別予定のサポート
// 月間予定のカラムの色付けを変更。月水金をグレイに。

// 2123 2021/09/02
// 上限管理の計算方法。福祉ソフトを真似したところが悪さしていた。
// 欠席時対応加算を確認
// const kAddic = comMod.findDeepPath(sch[key], 'dAddiction.欠席時対応加算');
// if (kAddic) c[UID][i] = 1;
// 欠席時対応加算の日毎カウントを修正したが一旦棚上げ

// 2122
// 帳票出力画面でパーミッションが正しく解釈されていないのを修正した。
// 稼働率表示の微調整

// 2121
// 権限の導入

// 2120
// 児発Componentの組み込み
// 「教室」分けの実装見直し。周辺バグフィックス
// 稼働率の表示方法変更
// 児発コンポーネントの追加


// 2119
// 放デイ専門的支援加算 -> 専門的支援加算 加算計算用の内部データ変更。
// 自発との共用化のため
// その他、Component作成済み
// 利用者別日別設定でのデータ破損リスクに対応
// (サーバー側）Cookieログインが不能だったことを修正
// 教室ごとの稼働率計算に対応
// 放デイ・児発切り替え機能の変更

// 2107
// スケジュールデータの日付の整合性を確認してから送信するようにした
// 日付が不整合の場合は送信を行わない。ユーザはリロードするいがい手段は無いけどしょうがない
// 遅延書き込みのためのアプリケーションバーのボタンに対してスケジュールの
// 変更がなくても送信ができるように変更した。
// 兄弟設定を入力、表示可能にした。
// 重度身体障害児の指定を一時的に無効にした。認定を受けていない事業所では重症心身障害児の
// 請求を発生させることができないと言うことがわかったため

// 2106
// 事業所全体の設定項目を月別に変更。APIをいくつか変更

// 2105
// 本番用のAPIと開発用を切り替え

// 2104
// 上限管理が実施されているかどうかの判定を見直し。

// 2103
// 月間予定で読み込みが不安定レンダリングが何回も行われると言う不具合があった
// 毎回出るわけではなく時々出る現象
// 表示行のコンポーネントが破棄されるときのディスパッチ方法を変更している
// これが原因かどうかはっきりしないが上星川のデータで8月のデータが6月のデータで上書きされて
// しまうと言うトラブルがあった。レンダリングが何回も行われているためディスパッチも何回も
// 行われている。
// そのため月の更新とデータの送信のタイミングが合わなかった可能性がある。しばらく様子見。


// 2102
// 月刊予定で利用回数の表示改善

// 2101
// sch2.jsでローディングの完了を確認する前にステートの操作とデータベースの操作を行っているため
// スケジュールのデータを1ヵ月分消去する可能性があることがわかった
// ステートの操作とデータベースの操作はコンポーネントを作成するときにuseEffect
// で行っている。ローディングの完了がすんでからコンポーネントの作成に入るようにロジックの変更した
// 月間利用での利用回数の計算方法を変更。欠席時対応加算など実際に利用がない加算項目は別枠にして
// 表示するようにした。
// ヘルプでGoogleフォトのスライドショーを利用することを試している.


// 2100
// 利用者別日別設定のページを新設
// 日別の加算項目やユーザ別の加算項目を設定する
// 従来の月間予定で設定できていたユーザ別の加算項目の設定は廃止した
// またユーザ別の加算項目は月ごとに管理される
// 各fromにおける加算項目などを初期値は今までusersより取得していたがscheduleの項目の中から
// 初期値の取得に変更した

// 2026.1
// sch2 バグフィックス ダイアログが開かなかった

// 2026
// 提供実績管理表に実費の表示を追加
// 提供実績記録表でデータがないときに異常終了するバグをフィックス
// 暫定実装してあった月間予定を本実装にしました。旧版の月間予定は廃止しました。
// 従来、月間予定の日付クリックで表示されていた「日次メニュー」は別のページに移動しました。

// 2025
// 日時項目を仮実装
// 協力事業所で保存済み上限管理結果がフォームに反映されていないのを修正
// それと関係あるかどうかわからないがbillingDtのkanrikakkagakuがnanになっていることがある
// 請求データに不正が発生する
// 不正が発生したときはコンソールにアラートを出し当面監視を続ける

// 2024
// 返戻送信暫定対応。
// 提供実績管理表バグフィックス
// スケジュールデータ書き込み要素見直しでトラフィックを削減 weekday object削除

// 2023
// 日付の移動で戻るボタンが機能していなかったのを修正
// billingのページにユーザー選択ボタンを追加。反映はまだ。

// 2022
// 月間予定高速化機能強化
// 月間予定高速化において日程詳細ダイアログで日付の移動を可能にした。

// 2021
// 管理事業所でも協力事業所でもない利用者の負担額が間違っていたのを修正
// 月間予定新バージョン 暫定実装

// 2020
// 兄弟間の上限管理に一応対応
// 上限管理結果表、利用者負担一覧表に氏名の伏せ字を設定する。
// 今までも一部設定されていたが範囲を拡大。また、設定によって伏せ字をしないことも可能

// 2019
// 予定設定ダイアログで送迎の初期値が取得できていなかったのを修正
// 管理事業者に設定して協力事業者を何も設定しないと自己負担額がゼロになるのを是正
// 兄弟管理に対応中

// 2018
// 送迎入力タイトル表示のバグフィックス

// 2017
// 利用者別一覧入力を作成。利用者別メニューから選択できます。
// 一部の設定値が保存できないことがあるのを修正 users.etcが保存されていなかった！


// 2016
// 上限管理値、帳票、csv出力値を一部変更
// 協力事業所の並び順を統一

// 2015
// csv作成 欠席対応時のサービス提供回数カウント方法変更

// 2014
// 上限管理を調整
// 利用者負担上限管理結果票の利用者負担額
// 自社のみ管理結果額、他は1割相当額
// 他事業所最大の場合の問題が残りそう

// 2012
// csv出力時の「今月」をstdDateから今日の日付に変更
// 利用実績欠席対応を修正

// 2011 0601
// 兄弟絡みの上限管理作成中。他の上限管理が潰れたのをとりま直した

// 2010
// ダイアログ日付表示バグ訂正

// 2009 0530 
// 処遇改善加算 0930加算を含む形で修正

// 2008 0527 
// 欠席時対応加算が取得できないのをバグフィクス
// その他、加算項目設定のパラメータ調整
// 医療的ケアのデータパラメータの変更。非該当は０の値をもたせていたが他の加算項目と同様に
// '' に変更 重心などが入ってきたときにどうなるか要確認
// SchEditDetailDialogのサイズを変更。タイトルの文字列大きさ変更

// 2007 0527 利用実績表などを修正

// 2006 0525
// 上限管理結果票が表示されないバグフィックス

// 2005 0520
// エクセルドキュメントのダウンロード先をrbatosdata.comに変更
// 非SSLブロックをChromeが行っているためその対応

// 2004 20210519
// 方対応改正は継続中
// テキストボックスがフォーカスしたときに全体選択状態になるかどうかを
// 設定可能にした
// <sfp.SetUiCookies />
// クッキーによる環境設定追加可能なComponentにしてある

// 2001 20210519
// 法改正対応

// β.1049 2021/05/07
// 学齢計算バグフィクス
// 供給量31日入力可能へ

// β.1048 2021/05/06
// アカウト管理。帳票の追加など

// α1047 2021/03/08
// 利用者追加時の挙動バグフィックス

// α1046 2021/03/04
// 送迎予定表など

// α1045 2021/03/03
// 色々変わってる

// α1045 2020/01/20
// 学齢変更時にstateの更新漏れが合ったので訂正
// appバー作り直し現時点で完了

// α1044 2020/01/20
// 学齢計算バグフィックス

// α1043 2020/01/20
// 予定関連ページ内ナビゲーション作り直し

// α1042 2020/01/20
// appバーの作り変え中
// 予定一覧の管理事業所、協力事業所の表示方法変更

// α1041 2020/01/18
// 設定>表示にユーザーの並び替えの順番を追加。細かい順番の指定は数字で設定する。
// ユーザー表示設定に伴う他画面の調整。ユーザーを追加すると常に最終に追加されるなど。

// 1026 2020/10/26 予定入力用リリース
// 1027 2020/10/26 
// 加算項目数カウント不具合訂正。送迎数カウント不具合訂正。実費項目が修正時にすべてonになってしまうのを修正
// 1028 月間予定にユーザー別加算項目、日毎加算項目を示唆するドットを表示。
// 1029 文字化け対応
// 1030 最初の読み込みのときにScheduleデータをロードしない不具合を解消。
// 1031 2020/11/02 
// 上限管理入力を追加。自動セーブとのコンフリクトがあり。セーブ実行時にダイアログが閉じられてしまう。
// 1032 2020/11/02
// 加算フォームパーツのバグフィックス。一部、チェックボックスから転用したフォームパーツの値がvalueではなくcheckedに紐付いていた。
// 1033 2020/11/17
// 契約書記入番号追加。協力事業所の上限管理区分を入力追加。欠席管理追加。
// 自動セーブ一旦保留。CSV作成機能絶賛実装中
// 1034 2020/11/19
// 固定ツールバーに仮対応。協力事業所入力項目が書き込まれない（フォーム
// 初期値として読み込めない）バグフィックス
// 1035 2020/12/04
// 固定ツールバー方法変更
// 1036 2020/12/14
// 自動セーブ追加。スナックバー表示方法変更
// 売上管理を追加
// 1037 2020/12/14
// 開始終了時間一括入力並び順を設定
// スケジュール修正ダイアログ、日付まで表示
// 1038 2020/12/17
// 事業所情報入力を追加
// 1038 2020/12/25
// api.php エラーレポーティング設定。get通信をデバック時以外は禁止にする。
// 事業所基本情報設定
// スケジュールテンプレート設定
// スケジュール入力、合計値の表示訂正 稼働率の表示
// フォーム送信キャンセルボタンイベント見直し まだ課題あり。パチクリする
// ロゴ、マークのsvg化
// メニュードロワの追加
// 1039 2020/12/28
// csv出力のバグフィックス
// メニューバー作り変え
// 1040 2020/01/14
// 帳票作成対応。ユーザー別売上管理を追加



// const locationPrams = () => {
//   const href = window.location.href;
//   const body = href.split('?')[0];
//   const prms = (href.split('?')[1])? href.split('?')[1]: null;
//   const detail = {};
//   const ary = (!prms)? []:
//     (prms.split('&'))? prms.split('&'): [];
//   ary.map(e=>{
//     detail[e.split('=')[0]] = (e.split('=')[1])? e.split('=')[1]: '';
//   });
//   return {href, body, prms, detail};
// }

// const ep = () => {
//   const v = locationPrams();
//   const ep = v.detail.endpoint;
//   if (ep === 'apidev'){
//     return 'https://houday.rbatos.com/api/apidev.php';
//   }
//   else if (ep === 'apixfg'){
//     return 'https://houday.rbatos.com/api/apixfg.php';
//   }
//   else{
//     // return 'https://houday.rbatos.com/api/apidev.php';
//     return 'https://houday.rbatos.com/api/api.php';
//   }
// }
// export const endPoint = ep();
