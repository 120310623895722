import React, { useEffect, useState } from 'react';
import { IconButton, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
// import orange from '@material-ui/core/colors/orange';
// import amber from '@material-ui/core/colors/amber';
import red from '@material-ui/core/colors/red';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Delete from '@material-ui/icons/Delete';
import SortIcon from '@material-ui/icons/Sort';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import * as Actions from '../../Actions';
import * as comMod from '../../commonModule';
import { endPoint } from '../../albCommonModule';
import * as afp from '../common/AddictionFormParts';
import { CheckBrunchUpdate } from '../common/CheckProgress';
import * as mui from '../common/materialUi';
import SnackMsg from '../common/SnackMsg';
import * as sfp from '../common/StdFormParts';
import {PermissionDenied, SendBillingToSomeState, ServiceNotice, StdErrorDisplay} from '../common/commonParts';
import RegistedParams from './RegistedParams';
import teal from '@material-ui/core/colors/teal';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';
import { faLess } from '@fortawesome/free-brands-svg-icons';
import { faBullseye } from '@fortawesome/free-solid-svg-icons';
import EcoOutlinedIcon from '@material-ui/icons/EcoOutlined';

const useStyles = makeStyles((theme) => ({
  linktabRoot: {
    marginTop: 47,
    '& > .MuiButton-text': {
      // margin: theme.spacing(1),
      padding: 0,
    },
  },
  userIndexTfRoot:{
    '& .MuiInputBase-input': {
      padding: 0,
      textAlign: 'right',
    },
  },
  actualCostListRoot:{
    display: 'flex',
    '& .MuiFormControl-root': {
      width: 150,
      marginTop: 8,
    },
    '& .MuiIconButton-root': {
      padding: 8,
      marginRight: 16,
      // color: '#ef5350',
      color: red[200],
    },
  },
  actualCostAddRoot:{
    display: 'flex',
    paddingTop: 8,
    '& .MuiFormControl-root': {
      width: 150,
      marginRight: 16,
      marginTop: 8,
    },
    '& .MuiButtonBase-root': {
      padding: 8,
      marginTop: 16,
    },
  },
  serviceTitle:{
    textAlign:'center', fontSize:'1.4rem', color:teal[800],
    margin:12,
  },
  serviceTitleHD:{
    textAlign:'center', fontSize:'1.4rem', color:blue[800],
    margin:12,
  },
  innerTitle0 :{
    background: teal[50], borderBottom: "1px solid" + teal[800],
    color: teal[600],
    fontSize: '.8rem', padding:8,
  },
  innerTitle1 :{
    background: blue[50], borderBottom: "1px solid" + blue[800],
    color: blue[600],
    fontSize: '.8rem', padding:8,
  },
  innerTitle2 :{
    background: grey[50], borderBottom: "1px solid" + grey[800],
    color: grey[600],
    fontSize: '.8rem', padding:8,
  },
  innerContent:{padding: 8,},
  bankInfoTitle: {
    padding: 8, paddingBottom:2, borderBottom: teal[200] + '90 1px solid',
    color: teal[600], fontSize: '.8rem',
    cursor: 'pointer',
    '& .delete': {color: red[800], fontSize: '1.0rem'}
  },
  spacer:{height: 16},
  bancInfoDelete: {
    textAlign: 'right',
    '& .MuiButton-root': {color: red[600]},
    '& .MuiButton-startIcon': {color: red[200]},
  },
  leafs:{
    textAlign: 'center', padding: 16,
    '& .MuiSvgIcon-root': {
      marginInlineStart: 16, marginInlineEnd: 16, fontSize: 16,
      color: teal[300],
    }
  },
}));

const Links = ()=>{
  const classes = useStyles();
  // react-routerからロケーション取得
  const ref = useLocation().pathname;
  const prms = [
    { link: "/setting", label: "基本" },
    { link: "/setting/schedule", label: "スケジュール関連" },
    { link: "/setting/view", label: "表示" },
    // { link: "/setting/others", label: "その他" },
    { link: "/setting/reg", label: "他事業所・市区町村" },
    { link: "/setting/addiction", label: "請求・加算" },
    { link: "/setting/others", label: "その他" },
  ];
  const linkList = prms.map((e, i)=>{
    let cls =  (ref === e.link) ? 'current':'';
    return (
      <Button key={i} >
        <Link className={cls} to={e.link}>{e.label}</Link>
      </Button>
    )
  });
  return(<>
    <div className={'linksTab ' + classes.linktabRoot}>
      {linkList}
    </div>
  </>);
}

// Storeのstate usersを更新する
const updateToSotedUsers = (prms) => {
  const {
    susers, users, dispatch, 
  } = prms;
  // ソート済みユーザーリストからインデックスを取得して
  // ユーザーにセット
  const newUsers = users.map((e, i) => {
    const ndx = susers.findIndex(_ => e.uid === _.uid);
    e.sindex = susers[ndx].sindex;
    return e;
  });
  newUsers.sort((a, b) => (parseInt(a.sindex) - parseInt(b.sindex)));
  dispatch(Actions.updateUsersAll(newUsers));
}

// ユーザーインデックスを更新する
async function requestUserIndexUpdate (prms){
  // susers ソート済みのユーザー
  // users Storeのユーザー
  const {
    susers, hid, bid, setres
  } = prms;
  
  // dbに送信するための配列作成
  const indexset = susers.map(e=>{
    return [e.uid, e.sindex];
  });
  const jindexset = JSON.stringify(indexset);
  // dbのアップデート
  let res;
  const urlPrms = { 
    hid, bid, indexset: jindexset, a: 'sendUsersIndex'
  };
  try {
    res = await axios.post(endPoint(), comMod.uPrms(urlPrms));
    if (!res.data.resulttrue > 0 || res.data.resultfalse) {
      throw new Error(res);
    }
    setres(res);
  }
  catch {
    setres(res);
  }
}

const SetUserIndex = (props) => {
  const classes = useStyles();
  // const {tests, settests, susers, setsusers} = props;
  const {susers, setsusers} = props;
  const classroomSet = new Set();
  susers.filter(e=>e.classroom).map(e=>{classroomSet.add(e)});
  const classroomCnt = Array.from(classroomSet).length;
  const handleChange = (ev) => {
    const name = ev.currentTarget.getAttribute('name');
    const tuid = parseInt(name.replace(/[^0-9]/g, ''));
    const i = susers.findIndex(e=>parseInt(e.uid) === tuid);
    const val = ev.currentTarget.value 
    // settests(val);
    // setsusers([...tmpa]);
    setsusers(_=>{
      const tmpa = [...susers];
      tmpa[i] = { ...tmpa[i], sindex: val};
      return tmpa;
    });
  }
  const a = [...susers];
  const userList = a.map((e, i)=>{
    // console.log(tests)
    return(
      <div className='userForIndex flxRow' key={i}>
        <div className='username w20'>
          {!i && <span className='title'>氏名</span>}
          {e.name}
        </div>
        <div className='choolAge w07'>
          {!i && <span className='title'>学齢</span>}
          {e.ageStr}
        </div>
        {classroomCnt > 0 &&
          <div className='choolAge w07'>
            {!i && <span className='title'>教室</span>}
            {e.classroom}
          </div>
        }
        <div className='belongs1 w20'>
          {!i && <span className='title'>所属</span>}
          {e.belongs1}
        </div>
        <div className='startDate w15'>
          {!i && <span className='title'>開始日</span>}
          {e.startDate}
        </div>
        <div className={'w10 ' + classes.userIndexTfRoot}>
          {!i && <span className='title'>順番</span>}
          <Input 
            name={'UIDNDX' + e.uid} value={e.sindex} 
            onChange={e=>handleChange(e)}
          />
        </div>
      </div>
    )
  });
  return (
    <div className='usersListForIndex'>
      {userList}
    </div>
  )
}

const userLstForSort = (users, order = 0, svcOrder = 0) => {
  // { value: 0, label: '学齢順に並べる', class: '' },
  // { value: 1, label: '利用開始順に並べる', class: '' },
  // { value: 2, label: '所属・学校順に並べる', class: '' },
  // { value: 3, label: '50音順に並べる', class: '' },
  // 5 を追加 教室順
  if (!Array.isArray(users))  return [];
  const tmp = [...users];
  const sOder = parseInt(svcOrder);
  if (parseInt(order) === 0){
    tmp.sort((a, b) => {
      if (a.service > b.service && sOder === 1)  return 1
      if (a.service < b.service && sOder === 1)  return -1
      if (a.service < b.service && sOder === 2)  return 1
      if (a.service > b.service && sOder === 2)  return -1
    });
  }
  if (parseInt(order) === 1){
    tmp.sort((a, b) => {
      if (a.service > b.service && sOder === 1)  return 1
      if (a.service < b.service && sOder === 1)  return -1
      if (a.service < b.service && sOder === 2)  return 1
      if (a.service > b.service && sOder === 2)  return -1
      if (a.ageNdx > b.ageNdx) return 1;  
      if (a.ageNdx < b.ageNdx) return -1;  
      if (a.sindex > b.sindex) return 1;
      if (a.sindex < b.sindex) return -1;
    });
  }
  else if (parseInt(order) === 2){
    tmp.sort((a, b) => {
      if (a.service > b.service && sOder === 1)  return 1
      if (a.service < b.service && sOder === 1)  return -1
      if (a.service < b.service && sOder === 2)  return 1
      if (a.service > b.service && sOder === 2)  return -1
      if (a.startDate > b.startDate) return 1
      if (a.startDate < b.startDate) return -1
      if (a.sindex > b.sindex) return 1;
      if (a.sindex < b.sindex) return -1;
    });
  }
  else if (parseInt(order) === 3){
    tmp.sort((a, b) => {
      if (a.service > b.service && sOder === 1)  return 1
      if (a.service < b.service && sOder === 1)  return -1
      if (a.service < b.service && sOder === 2)  return 1
      if (a.service > b.service && sOder === 2)  return -1
      if (a.belongs1 > b.belongs1) return 1
      if (a.belongs1 < b.belongs1) return -1
      if (a.sindex > b.sindex) return 1;
      if (a.sindex < b.sindex) return -1;
    });
  }
    // tmp.sort((a, b) => ( (a.belongs1 > b.belongs1) ? 1 : -1 ));
  else if (parseInt(order) === 4){
    tmp.sort((a, b) => {
      if (a.service > b.service && sOder === 1)  return 1
      if (a.service < b.service && sOder === 1)  return -1
      if (a.service < b.service && sOder === 2)  return 1
      if (a.service > b.service && sOder === 2)  return -1
      if (a.kana > b.kana) return 1
      if (a.kana < b.kana) return -1
      if (a.sindex > b.sindex) return 1;
      if (a.sindex < b.sindex) return -1;
    });
  }
  else if (parseInt(order) === 5){
    tmp.sort((a, b) => {
      if (a.service > b.service && sOder === 1)  return 1
      if (a.service < b.service && sOder === 1)  return -1
      if (a.service < b.service && sOder === 2)  return 1
      if (a.service > b.service && sOder === 2)  return -1
      if (a.classroom > b.classroom) return 1
      if (a.classroom < b.classroom) return -1
      if (a.sindex > b.sindex) return 1;
      if (a.sindex < b.sindex) return -1;
    });
  }
  // tmp.sort((a, b) => ((a.kana > b.kana) ? 1 : -1));
  return tmp.map((e, i)=>{
    e.sindex = i * 10 + 100;
    return {
      sindex: e.sindex,
      ageStr: e.ageStr,
      ageNdx: e.ageNdx,
      belongs1: e.belongs1,
      classroom: e.classroom,
      name: e.name,
      kana: e.kana,
      service: e.service,
      uid: e.uid,
      startDate: e.startDate,
    };
  });
}

export const ViewSettings = () => {
  const defaultOrder = 0;
  const dispatch = useDispatch();
  const classes = useStyles();
  const users = useSelector(state => state.users);
  const hid = useSelector(state => state.hid);
  const bid = useSelector(state => state.bid);
  
  // ユーザーリストを一旦、ステイトにセット
  const [susers, setsusers] = useState([]);
  // 並び順の設定 SetUsersSort にわたす
  const [sortOrder, setsortOrder] = useState(defaultOrder);
  const [sortSvcOrder, setsortSvcOrder] = useState(defaultOrder);
  // dbapi レスポンス格納用
  const [res, setres] = useState({});
  // スナックバー用
  const [msg, setmsg] = useState('');
  const [severity, setseverity] = useState('');

  // ソートの変更
  useEffect(()=>{
    setsusers(userLstForSort(users, sortOrder, sortSvcOrder))
  }, [sortOrder, sortSvcOrder]);

  // 送信後の状態監視
  useEffect(()=>{
    console.log('res', res);
    if (Object.keys(res).length){
      if (res.data.resulttrue && !res.data.resultfalse) {
        setseverity('');
        setmsg('利用者の順番を登録しました。');
        updateToSotedUsers({ susers, users, hid, bid, dispatch, setres });
      }
      else {
        setseverity('error');
        setmsg('利用者の登録に問題が発生しました。');
      }
    }
  }, [res]);
  const keyHandler = (e) => {
    if (e.which === 13) handleSubmit(e);
  }
  const handleSubmit = (e) => {
    // susers, users, hid, bid, dispatch, setres
    requestUserIndexUpdate({susers, users, hid, bid, dispatch, setres});
    console.log('res',res)
  }
  const cancelSubmit = (e) => {
    console.log('hoge');
    dispatch(Actions.resetStore());
  }
  
  // ソートの実行。ユーザーリストを表示するステイトをソートして更新する
  const sortLocal = (e) => {
    const tmpa = [...susers];
    tmpa.sort((a, b) => (a.sindex - b.sindex));
    setsusers(tmpa);
    // setsortOrder(0);
  }

  return(<>
    <Links />
    <div className="AppPage setting">
      <form id='f37fht' onKeyPress={(e) => keyHandler(e)}>
        <sfp.SetUsersSort 
          size='large'
          sortOrder={sortOrder} setsortOrder={setsortOrder}  
        />
        <sfp.SetUsersSvcSort 
          size='large'
          sortSvcOrder={sortSvcOrder} setsortSvcOrder={setsortSvcOrder}  
        />
        <SetUserIndex 
          susers={susers} 
          setsusers={(v)=>setsusers(v)} 
          // tests={tests}
          // settests={(v)=>settests(v)}
        />
      </form>
      <div className='buttonWrapper fixed'>
        {/* <mui.ButtonGP
          label='整列'
          onClick={sortLocal}
        /> */}
        <mui.ButtonGP
          color='secondary'
          label='キャンセル'
          onClick={cancelSubmit}
          
        />
        <Button
          variant="contained"
          className='sortButton'
          startIcon={<SortIcon />}
          onClick={sortLocal}
        >
          整列
        </Button>
        <mui.ButtonGP
          color='primary'
          label='書き込み'
          type="submit"
          onClick={handleSubmit}
        />
      </div>
    </div>
    <SnackMsg msg={msg} severity={severity} />
  </>)
}


export const AddictionSettings =() => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const hid  = useSelector(state => state.hid);
  const bid = useSelector(state => state.bid);
  const com = useSelector(state=>state.com);
  const stdDate = useSelector(state=>state.stdDate);
  const users = useSelector(state=>state.users);
  const service = useSelector(state=>state.service);
  const serviceItems = useSelector(state=>state.serviceItems);
  const dispService = (serviceItems.length > 1)? service: '';

  // const serviceItems = useSelector(state => state.serviceItems);
  // const s = useSelector(state => state.service);
  // const service = (s === '')?serviceItems[0]:s;
  // サービスは放デイ固定
  // const service = '放課後等デイサービス';
  // 事業所加算項目
  const handleSubmit = (e)=>{
    e.preventDefault();
    // 値が必要なエレメント
    const inputs = document.querySelectorAll('#f37fht input');
    const selects = document.querySelectorAll('#f37fht select');

    const outPutObj = {...com.addiction}
    outPutObj[service] = comMod.getFormDatas([inputs, selects]);
    dispatch(Actions.setAddictionSettingCom(outPutObj));
    // const addiction = JSON.stringify(outPutObj);
    dispatch(Actions.sendBrunch(
      { ...com, hid, bid, date:stdDate, addiction: outPutObj }
    ));
  }

  const keyHandler = (e) =>{
    if (e.which === 13 && e.shiftKey) handleSubmit(e);
  }

  const cancelSubmit = (e)=>{
    dispatch(Actions.resetStore());
  }
  const serviceTitleClass = (service === '放課後等デイサービス')
  ? classes.serviceTitle: classes.serviceTitleHD;
  if (!users.length){
    const errPrms = {
      errorText: `ユーザーが一人も登録されていません。
                  こちらの設定は一人以上のユーザーを登録してから実施して下さい。`, 
      errorSubText: '', errorId: 'E55611', errorDetail:''
    }
    return (
      <div className="AppPage setting">
        <StdErrorDisplay {...errPrms} />
      </div>
      
    )
  }

  return (<>
    <Links />
    <div className="AppPage setting">
      <CheckBrunchUpdate inline />
      {dispService !== '' &&
        <div className={serviceTitleClass}>{dispService}</div>
      }
      <form id='f37fht' onKeyPress={(e) => keyHandler(e)}>
        {/* <afp.IryouCareJi dLayer={0} dispHide size='large'/> */}
        <afp.TeikyoujikanKubun dLayer={0} dispHide size='large'/>
        <afp.ChiikiKubun dLayer={0} dispHide size='large'/>
        <afp.Teiin dLayer={0} dispHide size='large' />
        {/* <afp.JidouShidouHaichi dLayer={0} dispHide size='large' /> */}
        <afp.FukushiSenmonHaichi dLayer={0} dispHide size='large' />
        {/* 児発専用 */}
        <afp.ShisetsuKubun dLayer={0} dispHide size='large' />
        <afp.Chikoutai dLayer={0} dispHide size='large' />
        <afp.SyuugakuKubun dLayer={0} dispHide size='large' />
        <afp.Musyouka dLayer={0} dispHide size='large' />
        {/* 児発専用ここまで */}
        <afp.JiShidouKaHai1 dLayer={0} dispHide size='large' />
        {/* <afp.JiShidouKaHai2 dLayer={0} dispHide size='large' /> */}
        <afp.KangoKahai dLayer={0} dispHide size='large' />
        <afp.SenmonShien dLayer={0} dispHide size='large' />
        {/* <afp.KyouseiService dLayer={0} dispHide size='large' /> */}
        {/* <afp.KyouseiKyouka dLayer={0} dispHide size='large' /> */}
        <afp.ShoguuKaizen dLayer={0} dispHide size='large' />
        <afp.ShoguuTokubetsu dLayer={0} dispHide size='large' />
        <afp.TokuteiSyoguu dLayer={0} dispHide size='large' />
        <afp.Juushingata dLayer={0} dispHide size='large' />
        <afp.ServiceAsTanni dLayer={0} dispHide size='large' />
        <afp.ShokuinKetujo dLayer={0} dispHide size='large' />
        <afp.JihatsuKetsujo dLayer={0} dispHide size='large' />
        <afp.KeikakuMisakusei dLayer={0} dispHide size='large' />
        <afp.Jikohyouka dLayer={0} dispHide size='large' />
        <afp.KaisyoGensan dLayer={0} dispHide size='large' />
        <afp.ShinTaikousoku dLayer={0} dispHide size='large' />
        <afp.TeiinChouka dLayer={0} dispHide size='large' />
        <div className="formTextWrapper">
          <div className="text">
            以下の項目は全体の事業所全体の請求設定ではありません。
            ここでは利用者ごと、日付ごとのなどの加算減算設定で表示非表示を切り替えるために設定します。
          </div>
          <div className="text">
            普段利用しない項目は非表示設定にすることにより入力作業などがしやすくなります。
          </div>
          <div className="text">
            非表示設定にすることにより加算を見逃す可能性もあるので十分にご注意ください。
          </div>

        </div>
        {/* 児発専用 */}
        <afp.ShokujiTeikyou dLayer={0} noOpt dispHide size='large' />
        <afp.EiyoushiHaichi dLayer={0} noOpt dispHide size='large' />
        {/* 児発専用ここまで */}

        <afp.KobetsuSuport dLayer={0} noOpt dispHide size='large' />
        <afp.IryouRenkei dLayer={0} noOpt dispHide size='large' />
        <afp.EnchouShien dLayer={0} noOpt dispHide size='large' />
        <afp.TokubetsuShien dLayer={0} noOpt dispHide size='large' />
        <afp.KateiRenkei dLayer={0} noOpt dispHide size='large' />
        <afp.KankeiRenkei dLayer={0} noOpt dispHide size='large' />
        <afp.HoikuKyouiku dLayer={0} noOpt dispHide size='large' />
        {/* <afp.JougenKanri dLayer={0} dispHide size='large' /> */}
        {/* <afp.HoumonShien dLayer={0} noOpt dispHide size='large' /> */}
        <afp.KessekiTaiou dLayer={0} noOpt dispHide size='large' />

      </form>
      <div className='buttonWrapper fixed'>
        <mui.ButtonGP
          color='secondary'
          label='キャンセル'
          onClick={cancelSubmit}
        />
        <mui.ButtonGP
          color='primary'
          label={comMod.shortWord(dispService) + '書き込み'}
          type="submit"
          onClick={handleSubmit}
        />
      </div>
      <ServiceNotice/>
    </div>
  </>)
}

export const ExstraSettings = () => {
  const classes = useStyles();
  const sendBillingWrapStyle = {display: 'flex', padding: '8px 0'};
  const sendBillingTextStyle = {lineHeight: 1.5, paddingRight: 16, width: '60%'};
  
  const Leafs = () => {
    return (
      <div className={classes.leafs}>
        <EcoOutlinedIcon/><EcoOutlinedIcon/><EcoOutlinedIcon/>
      </div>
    )
  }
  return (<>
    <Links />
    <div className="AppPage setting">
      <sfp.SetUiCookies />
      <Leafs/>
      <sfp.RemoveCookieAll />
      <Leafs/>
      <sfp.DeleteScheduleButton />
      <Leafs/>
      <div style={sendBillingWrapStyle}>
        <div style={sendBillingTextStyle}>
          売上情報を共有するための情報を送信します。
          送信は通常、自動で行われますが最新の情報を共有したいときはこのボタンを
          クリックしてください。
        </div>
        <SendBillingToSomeState displayButton={true} />
      </div>

    </div>
  </>)
}

export const StandardSettings = ()=>{
  const dispatch = useDispatch();
  const classes = useStyles();
  const com = useSelector(state=>state.com);
  const bid = useSelector(state => state.bid);
  const hid = useSelector(state => state.hid);
  const stdDate = useSelector(state => state.stdDate);
  const account = useSelector(state=>state.account);
  const permission = comMod.parsePermission(account)[0][0];

  // スナックバー用
  const [snackMsg, setSnackMsg] = useState('');
  const [severity, setSeverity] = useState('');
  // 設定済み口座情報用
  // nullが帰ってきたら初期値を設定
  let bi = comMod.fdp(com, 'etc.bank_info', {});
  // 収納口座情報入力欄表示
  // 口座情報に２個以上の有効な値があったら口座情報を開く
  const [displayBankInfo, setDisplayBankInfo] = useState(()=>{
    // return Object.keys(bi).length > 1 ? true: false;
    // const cnt = Object.keys(bi).reduce((v, e)=>(v + bi[e]? 1: 0), 0);
    let cnt = 0;
    Object.keys(bi).forEach(e=>{
      if (bi[e])  cnt++;
    });
    return (cnt > 1)? true: false;
  });

  // 銀行口座項目を配列にしておく
  const bankInfoNames = [
    '口座名義人', '口座番号', '口振初回', '店舗番号', 
    '金融機関番号', '預金種目', '委託者番号',
  ]
  // 銀行口座項目ステイトに書き込む初期値
  const biIni = {};
  bankInfoNames.forEach(e=>{biIni[e] = ''});
  const bankInfo = (!Object.keys(bi).length || !bi)? biIni: bi;

  const handleSubmit = (e) => {
    e.preventDefault();
    const formId = '#f38fht '
    const inputs = document.querySelectorAll(formId + 'input');
    const selects = document.querySelectorAll(formId + 'select');
    const formDt = comMod.getFormDatas([inputs, selects], true, true);
    formDt.bid = bid;
    formDt.hid = hid;
    formDt.kanri = formDt.kanriL + ' ' + formDt.kanriF;
    console.log('formDt', formDt);
    // エラーがないか helperテキストエラーのセレクタを定義
    const errOccured = document.querySelectorAll(
      formId + '.MuiFormHelperText-root.Mui-error'
    );
    if (errOccured.length){
      console.log(errOccured);
      // dispatch(Actions.setSnackMsg(
      //   'エラーが発生している入力項目があります。', 'error'
      // ));
      setSnackMsg('入力内容を確認してください。');
      setSeverity('warning');
      return false;
    }
    // bankinfo を作成
    const bank_info = {};
    // // 銀行口座項目の必須項目にすべて値が入っているか全く入っていないかをチェック
    const bank_infoChk = [];
    bankInfoNames.forEach(e=>{
      bank_info[e] = formDt[e];
      delete formDt[e];
      if (bank_info[e]) bank_infoChk.push(1);
    });
    const biLength = bank_infoChk.length;
    if (biLength !== 0 && biLength !== 1 && biLength !== 6){
      setSnackMsg('口座情報が不完全です。');
      setSeverity('warning');
      return false;
    }
    // etcに口座情報を追加
    const comEtc = com.etc? com.etc: {};
    comEtc.bank_info = bank_info;

    // 送信パラメータ
    // hid, bid, bname, sbname, jino, kanri, postal, city, address, tel, fax
    // etc, addiction, date,
    const sendPrms = {
      ...formDt, hid, bid, date: stdDate,
      etc: comEtc, 
      addiction: com.addiction,
    };
    dispatch(Actions.sendBrunch(sendPrms));
  }
  const keyHandler = (e) =>{
    if (e.which === 13 && e.shiftKey) handleSubmit(e);
  }
  const cancelSubmit = (e) => {
    console.log('canceled.');
    dispatch(Actions.resetStore());
  }
  const kNameProps = {
    nameLname: 'kanriL',
    nameFname: 'kanriF',
    labelLname: '管理者 姓',
    labelFname: '管理者 名前',
    kana: false,
    required: true,
    def: com.kanri,
  }
  // const { defPostal, defAddr1, defAddr2, required } = props;
  const defPostal = isNaN(com.postal) 
    ? com.postal : com.postal.substr(0, 3) + '-' + com.postal.substr(3, 4);
  const paProps = {
    defPostal: defPostal,
    defAddr1: com.city,
    defAddr2: com.address,
    required: true,
  }
  const telProps = { 
    name: 'tel',
    label: '電話番号',
    required: true,
    def: com.tel,
  };
  const faxProps = {
    name: 'fax',
    label: 'FAX番号',
    required: false,
    def: com.fax,
  };
  if (permission < 90) return <PermissionDenied marginTop='120' />
  return (<>
    <Links />
    <div className="AppPage setting">
      <CheckBrunchUpdate inline />
      <form id='f38fht' onKeyPress={(e) => keyHandler(e)}>
        <sfp.JiNumber def={com.jino} />
        <sfp.Bname def={com.bname} />
        <sfp.Sbname def={com.sbname} />
        {/* <div className='cntRow'>
          <sfp.ServiceItems setMsg={setSnackMsg} setSeverity={setSeverity}/>
        </div> */}
        <div className='cntRow'>
          <sfp.NameInput {...kNameProps} />
        </div>
        <sfp.PostalAndAddress {...paProps} />
        <div className='cntRow'>
          <sfp.PhoneInput {...telProps} />
          <sfp.PhoneInput {...faxProps} />
        </div>
        <div className={classes.bankInfoTitle}
          onClick={()=>{
            setDisplayBankInfo(true);
          }}
        >
          {displayBankInfo === true &&
            <span>
              {'収納口座情報'}
            </span>
          }
          {displayBankInfo === false &&
            <span>
              {'収納口座情報を入力する'}
            </span>
          }

        </div>
        {displayBankInfo === true && <>
          <div className='cntRow'>
            <sfp.BankInfoFormsParts bankInfo={bankInfo} thisUser={null} />
          </div>
          <div className={classes.bancInfoDelete}>
            <Button 
              startIcon={<Delete/>}
              onClick={()=>{setDisplayBankInfo(false)}}
              tabIndex='-1'
            >
              口座情報を削除する
            </Button>
          </div>
        </>}
        <div className={classes.spacer}></div>
        <div className='cntRow'>
          <sfp.FilenamePreFix />
        </div>
      </form>

      <div className='buttonWrapper'>
        <mui.ButtonGP
          color='secondary'
          label='キャンセル'
          onClick={cancelSubmit}
        />
        <mui.ButtonGP
          color='primary'
          label='書き込み'
          type="submit"
          onClick={handleSubmit}
        />
      </div>
      {/* <sfp.SetUiCookies />
      <sfp.RemoveCookieAll /> */}
      <div style={{height: 24}}></div>
    </div>
    <SnackMsg msg={snackMsg} severity={severity} setmsg={setSnackMsg}/>

  </>);
}

export const OthesSettings = () =>{
  return(<>
    <Links />
    <div className="AppPage setting">
      hoge
    </div>
  </>)
}

export const RegParamsSettings = () =>{
  return(<>
    <Links />
    <div className="AppPage setting">
      <RegistedParams />
    </div>
  </>)
}


const ActtualCostFormParts = (props) =>{
  const classses = useStyles();
  // const acList = useSelector(state => state.config.actualCostList);
  // const { acListS, setAclistS, newItem, setNewItem } = props;
  const { 
    acListS, setAclistS, acListS_Err, setAclistS_Err,
    acListS_Text, setAclistS_Text, setSnack
  } = props;
  // // 実費設定入力用ステイト
  // const [acListS, setAclistS] = useState(acList);
  // // 新規要素用のステイト
  const [newItem, setNewItem] = useState({ name: '', value: '' })
  const [newItemErr, setNewItemErr] = useState({ name: false, value: false })
  const [newItemText, setNewItemText] = useState({ name: '', value: '' })
  // チェンジイベントハンドラ
  const handleChange = (ev) => {
    const name = ev.currentTarget.getAttribute('name');
    let val = ev.currentTarget.value;
    const t = { ...acListS };
    t[name] = val;
    setAclistS(t);
  }
  // ブラーイベントと入力確認
  const handleBlur = (ev) => {
    const name = ev.currentTarget.getAttribute('name');
    let val = ev.currentTarget.value;
    val = comMod.convHankaku(val);
    setAclistS({...acListS, [name]: val});
    if (isNaN(val)){
      setAclistS_Err({ ...acListS_Err, [name]: true })
      setAclistS_Text({ ...acListS_Text, [name]: '数値を入力してください。'})
    }
    else{
      setAclistS_Err({ ...acListS_Err, [name]: false })
      setAclistS_Text({ ...acListS_Text, [name]: '' })
    }
  }
  // クリックイベントハンドラ
  const handleClick = (ev) => {
    let name = ev.currentTarget.getAttribute('name');
    name = name.split('-')[1];
    const t = {...acListS}
    if (Object.keys(t).length === 1){
      setSnack({msg: '最後の一つは削除できません。', severity: 'warning'});
      return faLess;
    }
    delete t[name];
    setAclistS(t)
  }
  // 追加アイテムチェンジハンドラ
  const handleNewItemChange = (ev) =>{
    const key = ev.currentTarget.getAttribute('name');
    const v = ev.currentTarget.value;
    const t = {...newItem, [key]: v};
    setNewItem(t);

  }
  const handleNewItemBlur = (ev) => {
    const key = ev.currentTarget.getAttribute('name');
    let v = ev.currentTarget.value;
    if (key === 'value'){
      v = comMod.convHankaku(v);
      if (isNaN(v)){
        setNewItemErr({...newItemErr, [key]: true});
        setNewItemText({...newItemText, [key]: '数値を入力してください。'} )
      }
      else{
        setNewItemErr({...newItemErr, [key]: false});
        setNewItemText({...newItemText, [key]: ''} );
        setNewItem({...newItem, [key]: v});
      }
    }
  }
  // 新規実費項目の追加
  // 実費項目選択用のステイトを追加して新規追加用のステイトを初期化する
  const handleAddClick = (ev) => {
    if (!newItem.name){
      setNewItemErr({ ...newItemErr, name: true });
      setNewItemText({ ...newItemText, name: '名前を入力してください' });
      return false;
    }
    else{
      setNewItemErr({ ...newItemErr, name: false });
      setNewItemText({ ...newItemText, name: '' });
    }
    if (!newItem.value) {
      setNewItemErr({ ...newItemErr, value: true });
      setNewItemText({ ...newItemText, value: '金額を入力してください' });
      return false;
    }
    else {
      setNewItemErr({ ...newItemErr, name: false });
      setNewItemText({ ...newItemText, name: '' });
    }
    setAclistS({...acListS, [newItem.name]: newItem.value});
    setNewItem({name:'', value:''});
  }
  
  const acctualCostListFP = Object.keys(acListS).map((e, i) => {
    return (
      <div className={classses.actualCostListRoot} key={i}>
        <TextField key={i}
          label={e}
          name={e}
          value={acListS[e]}
          onChange={(e) => handleChange(e)}
          onBlur={(e)=>handleBlur(e)}
          error={acListS_Err[e]}
          helperText={acListS_Text[e]}
        />
        <IconButton 
          name={'delete-' + e}
          onClick={(e)=>handleClick(e)}
          color={'primary'}
        >
          <Delete />
        </IconButton>
      </div>
    )
  });
  // これを関数コンポーネントにすると変更するたびに再レンダリングが発生する
  // 単純にノードを返す変数にすればok
  // イベントもステイトも外部に保有しているからだと思われる
  const acctualCostAddFP = (
    <div className={classses.actualCostAddRoot}>
      <TextField key='name'
        label='名前'
        name='name'
        value={newItem.name}
        onChange={(e) => handleNewItemChange(e)}
        onBlur={(e)=>handleNewItemBlur(e)}
        error={newItemErr.name}
        helperText={newItemText.name}
      />
      <TextField key='value'
        label='金額'
        name='value'
        value={newItem.value}
        onChange={(e) => handleNewItemChange(e)}
        onBlur={(e) => handleNewItemBlur(e)}
        error={newItemErr.value}
        helperText={newItemText.value}
      />
      <IconButton
        name='add'
        onClick={(e) => handleAddClick(e)}
        color='primary'
      >
        <AddCircleIcon />
      </IconButton>

    </div>
  )
  return (<>
    <div className='acctualCosts'>
      {acctualCostListFP}
    </div>
    <div className='acctualCosts'>
      {acctualCostAddFP}
    </div>
  </>)
}

export const ScheduleSettings = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const service = useSelector(state => state.service);
  const template = useSelector(state => state.scheduleTemplate);
  const serviceItems = useSelector(state => state.serviceItems)
  const thisServeice = (service) ? service : serviceItems[0];
  const acList = useSelector(state => state.config.actualCostList);
  const config = useSelector(state => state.config);
  const com = useSelector(state => state.com);
  const stdDate = useSelector(state => state.stdDate);
  const users = useSelector(state => state.users);
  const [snack, setSnack] = useState({msg: '', severity: ''})

  // テンプレートの初期値読み込み
  const wd = comMod.findDeepPath(
    template, [thisServeice, 'weekday']
  );
  const so = comMod.findDeepPath(
    template, [thisServeice, 'schoolOff']
  );
  
  // 実費設定入力用ステイト
  const [acListS, setAclistS] = useState(acList);
  // エラーステイト
  let tmp = {};
  Object.keys(tmp).map(_ =>{
    tmp._ = false;
  });
  // テキストステイト
  const [acListS_Err, setAclistS_Err] = useState(tmp);
  Object.keys(tmp).map(_ => {
    tmp._ = '';
  });
  const [acListS_Text, setAclistS_Text] = useState(tmp);

  // 入力用のステイト
  const [wdTemplate, setWdTemplate] = useState(wd);
  const [soTemplate, setSoTemplate] = useState(so);
  
  // 送迎先用のstate
  const [destList, setDestList] = useState(config.transferList);

  const handleSubmit = (e) =>{
    e.preventDefault();
    // 平日のデータと休日のデータをそれぞれ取得
    const inputsWd = document.querySelectorAll('#uio908 .schTmpWd input');
    const inputsSo = document.querySelectorAll('#uio908 .schTmpSo input');
    const selectsWd = document.querySelectorAll('#uio908 .schTmpWd select');
    const selectsSo = document.querySelectorAll('#uio908 .schTmpSo select');
    const fdWd = comMod.getFormDatas([inputsWd, selectsWd], false, true);
    const fdSo = comMod.getFormDatas([inputsSo, selectsSo], false, true);
    // 実費項目
    [fdWd, fdSo].map(formsVal=>{
      Object.keys(formsVal.actualCost).map(e => {
        if (formsVal.actualCost[e]) {
          formsVal.actualCost[e] = acListS[e];
        }
        else {
          delete formsVal.actualCost[e];
        }
      });
    });
    // 送迎が配列になっているので処理を追加
    [fdWd, fdSo].map(formsVal => {
      formsVal.transfer = [];
      formsVal.transfer[0] =
        (formsVal.pickup !== undefined) ? formsVal.pickup : '';
      formsVal.transfer[1] =
        (formsVal.send !== undefined) ? formsVal.send : '';
      delete formsVal.pickup; delete formsVal.send;
    });
    // 足りない項目を追加
    [fdWd, fdSo].map(formsVal => {
      formsVal.service = thisServeice;
    });
    fdWd.offSchool = 0;
    fdSo.offSchool = 1;
    const tmp = {...template}
    console.log('dist', { scheduleTemplate: { ...tmp } });
    tmp[thisServeice] = { ...tmp[thisServeice], weekday: { ...fdWd } };
    tmp[thisServeice] = { ...tmp[thisServeice], schoolOff: { ...fdSo } };
    const tmp1 = { scheduleTemplate: { ...tmp } }
    // テンプレートの更新
    dispatch(Actions.setStore({...tmp1}));
    // com.etcに記述する内容
    const etcSelects = document.querySelectorAll('#uio908 .comEtc select');
    const comEtc = comMod.getFormDatas([etcSelects], false, true);
    // comの更新とconfigの更新
    const tmp2 = { 
      ...com, 
      etc: { 
        ...tmp1, ...comEtc, 
        actualCostList: acListS, 
        transferList: destList 
      }
    };
    // stateに追加されていない送迎場所を取得する
    const newDest = document.querySelector('input[name=dest]').value;
    const tmp3 = {...tmp2}; // 参照渡しでstringfyされてしまうので一旦退避
    const tmp4 = {
      ...config,
      actualCostList: acListS,
      transferList: [...destList]
    }
    // 追加ボタンが押されていない新しい送迎先の処理
    if (newDest){
      tmp4.transferList.push(newDest);
      tmp3.etc.transferList.push(newDest);
    }
   
    dispatch(Actions.setStore({ com: tmp2 , config: tmp4}));
    // dbに送信
    const tmp5 = {...tmp3, date: stdDate}
    dispatch(Actions.sendBrunch(tmp5));
  }
  const cancelSubmit = (e) =>{
    e.preventDefault();
    console.log('canceled');
    dispatch(Actions.resetStore());
  }
  const serviceTitle = (thisServeice === '放課後等デイサービス')?
  classes.innerTitle0: classes.innerTitle1;
  if (!users.length){
    const errPrms = {
      errorText: `ユーザーが一人も登録されていません。
                  こちらの設定は一人以上のユーザーを登録してから実施して下さい。`, 
      errorSubText: '', errorId: 'E55609', errorDetail:''
    }
    return (
      <div className="AppPage setting">
        <StdErrorDisplay {...errPrms} />
      </div>
      
    )
  }
  return (<>
    <Links />
    <div className="AppPage setting">
      <form id="uio908">
        <div className='templateWrappOuter'>
          <div className={serviceTitle}>
            スケジュール雛形・平日・{thisServeice}
          </div>
          <div className='templatewrappInnner'>
            <div className='cntRow schTmpWd'>
              <sfp.TimeInput
                name='start' label='開始'
                value={wdTemplate}
                required size='middle'
              />
              <sfp.TimeInput
                name='end' label='終了'
                value={wdTemplate}
                required size='middle'
              />
              <sfp.Transfer
                name='pickup' label='迎え'
                value={wdTemplate}
                required size='middle'
              />
              <sfp.Transfer
                name='send' label='送り'
                value={wdTemplate}
                required size='middle'
              />
            </div>
            <div className='cntRow schTmpWd'>
              <sfp.ActualCostCheckBox
                key={0}
                value={wdTemplate}
                actualCostList={acListS}
                required size='middle'
              />
            </div>

          </div>

          <div className={serviceTitle}>
            休校日
          </div>
          <div className='templatewrappInnner'>
            <div className='cntRow schTmpSo'>
              <sfp.TimeInput
                name='start' label='開始'
                value={soTemplate}
                required size='middle'
              />
              <sfp.TimeInput
                name='end' label='終了'
                value={soTemplate}
                required size='middle'
              />
              <sfp.Transfer
                name='pickup' label='迎え'
                value={soTemplate}
                required size='middle'
              />
              <sfp.Transfer
                name='send' label='送り'
                value={soTemplate}
                required size='middle'
              />
            </div>
            <div className='cntRow schTmpSo'>
              <sfp.ActualCostCheckBox
                key={1}
                value={soTemplate}
                actualCostList={acListS}
                required size='middle'
              />
            </div>
          </div>
        </div>
        <div className={classes.innerTitle0}>
          計算方法
        </div>
        <div className={classes.innerContent}>
          <sfp.SetOccupancyCalc />
        </div>
        <div className={classes.innerTitle0}>
          実費項目
        </div>
        <div className={classes.innerContent}>

          <ActtualCostFormParts 
            acListS={acListS} setAclistS={setAclistS}
            acListS_Err={acListS_Err} setAclistS_Err={setAclistS_Err}
            acListS_Text={acListS_Text} setAclistS_Text={setAclistS_Text}
            setSnack={setSnack}
            // newItem={newItem} setNewItem={setNewItem}
          />
          <div style={{height:16}}></div>
        </div>
        <div className={classes.innerTitle0}>
          送迎先
        </div>
        <div className={classes.innerContent}>
          <sfp.TransferListGlobal 
            destList={destList} setDestList={setDestList} 
          />
        </div>
      </form>
      <div className='buttonWrapper'>
        <mui.ButtonGP
          color='secondary'
          label='キャンセル'
          onClick={cancelSubmit}
        />
        <mui.ButtonGP
          color='primary'
          label='書き込み'
          type="submit"
          onClick={handleSubmit}
        />
      </div>
      <ServiceNotice />
    </div>
    <SnackMsg {...snack}/>

  </>);
}

const Setting = () => {
  return (
    <>
    <StandardSettings />
    </>

  );
}
export default Setting;