import React,{useState, useEffect} from 'react';
// import store from './store';
import * as Actions from '../../Actions';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as comMod from '../../commonModule';
import * as albCM from '../../albCommonModule';
import { LoadingSpinner, LoadErr} from '../common/commonParts';
import * as mui from '../common/materialUi'
import * as sfp from '../common/StdFormParts'
import UserDialog from './UserDialog';
import UserSortDialog from './UserSortDialog';
import SnackMsg from '../common/SnackMsg';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import IconButton from '@material-ui/core/IconButton';
import { LinksTab } from '../common/commonParts';
import { useParams, } from 'react-router-dom';
import { faLess } from '@fortawesome/free-brands-svg-icons';
import { TrendingUp, TurnedIn } from '@material-ui/icons';
import { faSleigh } from '@fortawesome/free-solid-svg-icons';


const UserListTitle =(props)=> {
  const {classroomCnt, brosCnt, uprms} = props;
  // 教室未設定の場合は項目表示しない
  const Std = () => {
    return(
      <div className = "flxTitle oddColor" >
        <div className="wmin lower"><div>No</div></div>
        <div className="w07 lower"><div>年齢<br />学齢</div></div>
        <div className="w20 lower">
          <div>種別/サービス<br />受給者証番号</div>
        </div>
        <div className="wzen4 lower"><div>提供量</div></div>
        {classroomCnt > 0 &&
         <div className="w07 lower"><div>単位</div></div>
        }
        <div className="w10 lower"><div>上限額</div></div>
        <div className="wmin lower"><div>管</div></div>
        <div className="w20 lower"><div>氏名</div></div>
        {brosCnt > 0 &&
         <div className="w07 lower"><div>兄弟</div></div>
        }
        <div className="w20 lower"><div>保護者</div></div>
        <div className="w30 lower"><div>連絡先</div></div>
      </div >
    )
  }
  const Belongs = () => {
    return (
      <div className = "flxTitle oddColor" >
        <div className="wmin lower"><div>No</div></div>
        <div className="w07 lower"><div>年齢<br />学齢</div></div>
        <div className="w20 lower">
          <div>種別/サービス<br />受給者証番号</div>
        </div>
        <div className="w20 lower"><div>氏名</div></div>
        <div className="w20 lower"><div>保護者</div></div>
        <div className="w20 lower"><div>市区町村</div></div>
        <div className="w25 lower"><div>所属１</div></div>
        <div className="w25 lower"><div>所属２</div></div>
      </div >
    )
  }
  const BankInfo = () => {
    return (
      <div className = "flxTitle oddColor" >
        <div className="wmin lower"><div>No</div></div>
        <div className="w15 lower"><div>保護者<br></br>児童名</div></div>
        <div className="w10 lower"><div>金融機関</div></div>
        <div className="w07 lower"><div>支店</div></div>
        <div className="w07 lower"><div>種別</div></div>
        <div className="w15 lower"><div>口座番号</div></div>
        <div className="w15 lower"><div>口座名義</div></div>
        <div className="w15 lower"><div>顧客コード</div></div>
      </div >
    )
  }
  if (uprms === 'belongs')        return <Belongs/>
  else if (uprms === 'bankinfo')  return <BankInfo/>
  else return <Std/>
}

const usersMenu = [
  { link: "/", label: "基本" },
  { link: "/users/belongs", label: "所属" },
  { link: "/users/bankinfo", label: "口座情報" },
]

// 表示を行わないコンポーネント。ローディングが終わったらuserとcomの次月以降の
// データを取得しdispatchする
export const GetNextHist = () => {
  const dispatch = useDispatch();
  const allState = useSelector(state=>state);
  const loadingStatus = comMod.getLodingStatus(allState);
  const {hid, bid, stdDate, nextUsers, nextCom} = allState;
  useEffect(()=>{
    const dispatchItems = (r) => {
      if (!r.result) return false;
      const rtnNextCom = r.fetchNextComInfo.data.dt.length
      ? r.fetchNextComInfo.data.dt[0].next: '';
      const rtnNextUsers = r.fetchNextUserInfo.data.dt.length
      ? r.fetchNextUserInfo.data.dt: [];
      dispatch(Actions.setStore({nextUsers: rtnNextUsers, nextCom: rtnNextCom}));
    }
    const {loaded, error} = loadingStatus;
    if (loaded && !error && (!nextUsers && nextCom === undefined)){
      albCM.getNextHist({hid, bid, date: stdDate})
      .then(res=>{dispatchItems(res)})
    }
  }, [loadingStatus])
  return null;
}


const UserlistElms = (props)=>{
  const dispatch = useDispatch();
  let listElms;
  const service = useSelector(state => state.service);
  const classroom = useSelector(state =>state.classroom);
  const allstate = useSelector(state => state);
  const loadingStatus = comMod.getLodingStatus(allstate);
  const {uprms, editOn} = props;
  
  const dispClassroom = (e) => {
    const v = e.classroom;
    if (Array.isArray(v) && v.length > 1) return '複数';
    else if (v.indexOf(',') > 0)  return '複数';
    else if (Array.isArray(v) && v.length === 1) return v[0];
    else return v;
  }

  const clickHandler = (e)=>{
    // 修正モードでないときは何もしない
    if (!editOn) return false;
    const uid = e.currentTarget.getAttribute('uid');
    props.setuids(uid);
    props.setopen(true);
  }
  const editonRowClass = (props.editOn) ? 'editOn ' : ' '
  const swaponRowClass = (props.swapOn)? 'swapOn ': ' '
  const OneUser = (props) => {
    const {e, i, classroomCnt, brosCnt} = props;
    const t = comMod.findDeepPath(e, 'etc.bank_info');
    const binfo = t ? t: {};
    const kariNoClass = (e.hno.length < 10) ? 'kariHno' : '';
    // 行の中の共通項目
    const Common = () => {
      return (<>
          <div className='wmin center'>{i + 1}</div>
          <div className='w07'>{e.ageStr}</div>
          <div className='w20'>
            <div>
              {
                comMod.shortWord(e.type) +
                ' / ' +
                comMod.shortWord(e.service)
              }
            </div>
            <div className={'small ' + kariNoClass}>{e.hno}</div>
          </div>
      </>)
    }
    const BankInfo = () => {
      return (<>
        <div 
          key={e.uid} uid={e.uid} 
          className={'userRow flxRow oddColor ' + editonRowClass + swaponRowClass}
          onClick={e=>clickHandler(e)}
        >
          <div className='wmin center'>{i + 1}</div>
          <div className='w15'>
            <div>{e.pname}</div>
            <div className='small'>{e.name}</div>
          </div>
          <div className='w10'>{binfo.金融機関番号}</div>
          <div className='w07'>{binfo.店舗番号}</div>
          <div className='w07'>{binfo.預金種目}</div>
          <div className='w15'>{binfo.口座番号}</div>
          <div className='w15'>{binfo.口座名義人}</div>
          <div className='w15'>{binfo.顧客コード}</div>
        </div>
      </>)
    }
    const Belongs = () => {
      return (
        <div 
          key={e.uid} uid={e.uid} 
          className={'userRow flxRow oddColor ' + editonRowClass + swaponRowClass}
          onClick={e=>clickHandler(e)}
        >
          <Common/>
          <div className='w20'>
            <div>{e.name}</div>
            <div className='small'>{e.kana}</div>
          </div>
          <div className='w20'>
            <div>{e.pname}</div>
            <div className='small'>{e.pkana}</div>
          </div>
          <div className='w20'>
            <div>{e.scity}</div>
            <div className='small'>{e.scity_no}</div>
          </div>
          <div className='w25'>
            <div>{e.belongs1}</div>
          </div>
          <div className='w25'>
            <div>{e.belongs2}</div>
          </div>
        </div>

      )
    }
    // 長すぎると表示が乱れるので
    const mailText = e.pmail.length > 25? e.pmail.slice(0,25) + '..': e.pmail;
    const Std = () =>{
      return (
        <div 
          key={e.uid} uid={e.uid} 
          className={'userRow flxRow oddColor ' + editonRowClass + swaponRowClass}
          onClick={e=>clickHandler(e)}
        >
          <Common/>
          <div className='wzen4 right'>{e.volume}</div>
          {/* 教室未設定の場合は項目表示しない */}
          {/* 複数指定の場合ストアステイトに一致しているものだけ表示する */}
          {classroomCnt > 0 &&
            // <div className='w07'>{dispClassroom(e)}</div>
            <div className='w07'>{dispClassroom(e)}</div>
          }
          <div className='w10 right'>
            {comMod.formatNum(e.priceLimit, 1)}
          </div>
          <div className="wmin">{e.kanri_type.substr(0, 1)}</div>
          <div className='w20'>
            <div>{e.name}</div>
            <div className='small'>{e.kana}</div>
          </div>
          {brosCnt > 0 &&
            <div className='w07 right'>{e.brosIndex}</div>
          }
          <div className='w20'>
            <div>{e.pname}</div>
            <div className='small'>{e.pkana}</div>
          </div>
          <div className='w30'>
            <div>{e.pphone}</div>
            <div className='small'>{e.pphone1}</div>
            <div className='small'>
              <a href={'mailto:'+e.pmail}>{mailText}</a>
            </div>
          </div>
        </div>
      )
    }
    if (uprms === 'belongs'){
      return <Belongs />
    }
    else if (uprms === 'bankinfo'){
      return <BankInfo />
    }
    else {
      return <Std/>
    }
  }
  if (loadingStatus.loaded && !loadingStatus.error) {
    let users = props.users.filter(e => {
      if (
        (service === '' || service === e.service) &&
        (albCM.isClassroom(e, classroom))
      ) {
        return (e);
      }
    });
    listElms = users.map((e, i) => {
      if (e.delete)  return null;
      // 仮保険番号であることを示すクラス設定
      const kariNoClass = (e.hno.length < 10) ? 'kariHno' : '';
      return (
        <OneUser 
          e={e} i={i} key={i}
          classroomCnt={props.classroomCnt} brosCnt={props.brosCnt} 
        />
      );
    });
  }
  else if (loadingStatus.error) {
    listElms = (
      <LoadErr loadStatus={loadingStatus} errorId={'E4931'} />

    )
  }
  else{
    listElms = (<LoadingSpinner />);
  }
  return (
    // <div className='tabelBodyWrapper' onScroll={handleScroll}>
    <div className='tabelBodyWrapper' >
      {listElms}
    </div>
  );
}
// ユーザーリストを表示するエレメントを作成
// storeからユーザーリストの配列を取得して表示
// 放デイ、自発のサービスごとにするか全部出すかを判断する
// stateのserviceが空白なら全表示
// usersからrenderを独立させた
export const Users = ()=>{
  const dispatch = useDispatch();
  // 編集ボタンの状態を保持するため
  const cntMode = useSelector(state=>state.controleMode);
  // 編集用ダイアログの制御
  const [open, setopen] = useState(false);
  const [userSortOpen, setUserSortOpen] = useState(false);
  const [userSortRes, setUserSortRes] = useState({});
  const [uids, setuids] = useState('');
  const [editOn, seteditOn] = useState(()=>{
    if (!cntMode.usersFabEdit){
      return false;
    }
    else return true;
  });
  // const [swapOn, setswapOn] = useState(false);
  const users = useSelector(state => state.users);
  const stdDate = useSelector(state=>state.stdDate);
  const uprms = useParams().p; // url パラメータ取得
  

  // 設定済み教室のカウント 0ならば一覧に教室を表示しない
  const classroomCnt = users.filter(e=>e.classroom).length;
  // 設定済みの兄弟のカウント 0ならば一覧に表示しない
  const brosCnt = users.filter(e=>parseInt(e.brosIndex) > 0).length;
  const fabClickHandler = (e)=>{
    const name = e.currentTarget.name;
    if (name === 'edit' && editOn){
      seteditOn(false);
      dispatch(Actions.setStore({controleMode:{usersFabEdit: false}}))
    };
    if (name === 'edit' && !editOn) {
      seteditOn(true);
      setUserSortOpen(false);
      dispatch(Actions.setStore({controleMode:{usersFabEdit: true}}))
    };
    // if (name === 'swap' && swapOn) {
    //   setUserSortOpen(false);
    // };
    if (name === 'swap') {
      setUserSortOpen(true);
      seteditOn(false);
    };
    if (name === 'add') {
      setopen(true); 
      seteditOn(false);
      setuids('');
    }
  }
  useEffect(() => {
    // console.log('res', userSortRes);
    if (Object.keys(userSortRes).length) {
      if (userSortRes.data.resulttrue && !userSortRes.data.resultfalse) {
        dispatch(Actions.setSnackMsg('利用者の順番を登録しました。', ''));
      }
      else {
        dispatch(Actions.setSnackMsg(
          '利用者の順番を登録できませんでした。', 'error'
        ));
      }
    }
  }, [userSortRes]);

  return (<>
    <LinksTab menu={usersMenu} />
    <div className='AppPage userLst fixed'>
      <UserListTitle 
        classroomCnt={classroomCnt} brosCnt={brosCnt} 
        uprms={uprms}
      />
    </div>
    <div className='AppPage userLst scroll'>
      <UserlistElms 
        users={users} stdDate={stdDate}
        editOn={editOn}
        open={open} setopen={setopen}
        uids={uids} setuids={setuids}
        uprms={uprms}
        classroomCnt={classroomCnt} brosCnt={brosCnt}
      />
      <mui.FabAddEdit 
        clickHandler={e => fabClickHandler(e)} 
        editOn={editOn}
        // swapOn={swapOn}
      />
      <UserDialog 
        open={open} setopen={setopen} 
        uids={uids} setuids={setuids} 
        editOn={editOn}
      />
      <UserSortDialog
        open={userSortOpen} setopen={setUserSortOpen}
        res={userSortRes} setres={setUserSortRes}
        uids={uids} setuids={setuids}
      />
    </div>
    <GetNextHist />
  </>)
}

export default Users;
