import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSomeState } from '../../albCommonModule';
import { getLodingStatus, parsePermission, fdp, null2Zero, formatNum, typeOf } from '../../commonModule';
import { LoadErr, LoadingSpinner, PermissionDenied } from '../common/commonParts';

// オブジェクトを配列化する
const toArray = (v) => {
  if (Array.isArray(v)) return v;
  const r = [];
  Object.keys(v).forEach(e=>{
    if ((typeof v[e]) !== 'object') return null;
    if (Array.isArray(v[e])) return null;
    if (!Object.keys(v[e]).length)  return null;
    r.push(v[e]);
  });
  return r;
}

const MainProseedOtherOfficeis = () => {
  const allstate = useSelector(state=>state);
  // const {stdDate, accountLst} = allstate;
  const stdDate = useSelector(state=>state.stdDate);
  const accountLst = useSelector(state=>state.accountLst);
  const [fetchStatus, setFetchStatus] = useState(
    {count:accountLst.length ,loaded: 0, nodata: 0, error: 0}
  );
  const [res, setRes] = useState({});
  const [officeis, setOfficeis] = useState(()=>{
    return accountLst.map(e=>e);
  });
  
  // 月が変わったときに他事業所売上情報正気化
  useEffect(()=>{
    return () => {
      const t = officeis;
      t.forEach(e=>{
        e.userSanteiTotal = 0;
      });
      setOfficeis(t);
    }
    
  }, [stdDate])

  useEffect(()=>{
    if (Object.keys(res).length){
      if (!res.data.result){
        const t = {...fetchStatus};
        t.error++;
        setFetchStatus(t);
      }
      else if (!res.data.dt.length){
        const t = {...fetchStatus};
        t.nodata++;
        setFetchStatus(t);
      }
      else{
        const t = {...fetchStatus};
        t.loaded++;
        setFetchStatus(t);
        // 他事業所の売上データ取得
        const b = fdp(res.data.dt[0], 'state.billingDt', []);
        const bdt = toArray(b); // fdpを通しているのでオブジェクトで戻ってくる！
        const thisBid = res.data.dt[0].bid;
        const thisHid = res.data.dt[0].hid;
        const userSanteiTotal = bdt.reduce((v, e)=>(v + e.userSanteiTotal), 0);
        const countOfUse = bdt.reduce((v, e)=>(v + e.countOfUse), 0);
        const tanniTotal = bdt.reduce((v, e)=>(v + e.tanniTotal), 0);
        const ketteigaku = bdt.reduce((v, e)=>(v + e.ketteigaku), 0);
        const jichiJosei = bdt.reduce((v, e)=>(v + e.jichiJosei), 0);
        const actualCost = bdt.reduce((v, e)=>(v + e.actualCost), 0);
        const p = officeis.findIndex(e=>(e.bid === thisBid && e.hid === thisHid));
        const u = [...officeis];
        u[p].userSanteiTotal = userSanteiTotal;
        u[p].countOfUse = countOfUse;
        u[p].tanniTotal = tanniTotal;
        u[p].ketteigaku = ketteigaku;
        u[p].jichiJosei = jichiJosei;
        u[p].actualCost = actualCost;
        setOfficeis(u);
      }
    }
  }, [res]);
  const fmtNum = (v) => (formatNum(null2Zero(v), 1));
  const officeisProseeds = officeis.map((e, i)=>{
    if (!e.userSanteiTotal) return null;
    const kokuho = e.userSanteiTotal - e.ketteigaku - null2Zero(e.jichiJosei);
    return (
      <div className='flxRow' key={i} >
        <div className='wmin right'>{i + 1}</div>
        <div className='w20'>{e.sbname}</div>
        <div className='w10 right'>{fmtNum(e.tanniTotal)}</div>
        <div className='w10 right'>{fmtNum(e.userSanteiTotal)}</div>
        <div className='w10 right'>{fmtNum(e.ketteigaku)}</div>
        <div className='w10 right'>{fmtNum(e.actualCost)}</div>
        <div className='w10 right'>{fmtNum(kokuho)}</div>
        <div className='w10 right'>{fmtNum(e.countOfUse)}</div>
      </div>
    )
  });
  const handleClick = () => {
    const f = async (e) => {
      const prms = {
        hid: e.hid, bid: e.bid, date: stdDate,
        item: 'billingDt',
      };
      const r = await fetchSomeState(prms);
      setRes(r);
    }
    accountLst.forEach(e=>{
      f(e);
    });
  }

  const FetchButton = () => {
    return (
      <div>
        <Button
          variant='contained'
          onClick={handleClick}
        >
          売上情報取得
        </Button>
      </div>
    )
  }

  return (
    <div>
      <FetchButton />
      {officeisProseeds}
    </div>
  )

}

const ProseedOtherOfficeis = ()=>{
  const allstate = useSelector(state=>state);
  const loadingStatus = getLodingStatus(allstate);
  const account = useSelector(state => state.account);
  const permission = parsePermission(account)[0][0];

  if (loadingStatus.loaded && permission >= 90){
    return(<>
      <MainProseedOtherOfficeis />
    </>)
  }
  else if (loadingStatus.error){
    return (<>
      <LoadErr loadStatus={loadingStatus} errorId={'E5966'} />
    </>)
  }
  else if (permission < 90) return <PermissionDenied marginTop='90'/>
  else{
    return(<>
     <LoadingSpinner/>
    </>)
  }
}
export default ProseedOtherOfficeis;