import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBillInfoToSch } from './blMakeData';
import { DAddictionContent, ExcahngeTotalizeButton, LoadingSpinner, PermissionDenied } from '../common/commonParts';
import { LoadErr } from '../common/commonParts';
import { 
  callDisptchForSendSchedule, 
  convHankaku, 
  findDeepPath, formatDate, formatNum, getDateEx, getLodingStatus, 
  parsePermission, randomStr,
  spfill,
  zen2han,
  zp,
} from '../../commonModule';
import { AddAlarm, RepeatRounded, TramRounded, TrendingUp } from '@material-ui/icons';
import { SelectGp, } from '../common/FormPartsCommon';
import { Button, Checkbox, makeStyles } from '@material-ui/core';
import { genFKdatas, sendSomeState } from '../../albCommonModule';
import SnackMsg from '../common/SnackMsg';
import { blue, teal } from '@material-ui/core/colors';
import { DateInput } from '../common/StdFormParts';
import { faSleigh } from '@fortawesome/free-solid-svg-icons';
import { faLess } from '@fortawesome/free-brands-svg-icons';
import { KyoudoKoudou } from '../common/AddictionFormParts';
const useStyles = makeStyles({
  cntRow: {display: 'flex', flexWrap:'wrap', minHeight: 88},
  buttonFKroot: {
    padding: 8, paddingTop: 16,
    '& .MuiButton-root': {minWidth: 140}
  },
  loading:{
    display: 'inline-flex', marginTop: 16,
    width: 140, height: 24, justifyContent: 'center', alignItems: 'center', 
    backGround: blue[100], color: blue[800],
  },
  checkboxRoot: {
    '& .MuiCheckbox-root': {padding:' 4px 12px'}
  },
  comAccountInfo: {
    display: 'flex', flexWrap: 'wrap', alignItems: 'end', padding: 8,
    marginBottom: 18,
    '& .label': {padding: 4, margin: '0, 4px 0 8px', fontSize: '.8rem'},
    '& .cont': {
      padding: 4, margin: '0, 8px 0 4px', fontSize: '1.1rem', color: teal[900]
    },
  },

});

const linkErase = 15; // リンク消失秒数

const fkItems = [
  {value: 'hamagin', label: '浜銀ファイナンス'},
  {value: 'sanin', label: '標準フォーマット'},
  // {value: 'mbs', label: 'MBS口振くん'},
  // {value: 'ufj', label: '三菱UFJニコス,共立コンピュータ'},
  // {value: 'yuucho', label: 'インターネット伝送ゆうちょ'},
  {value: 'fctSanin', label: '山陰信販ファクタリング'},
]

const downloadUrlRoot = 'https://houday.rbatos.com/'

const makeFactDt = (prms) => {
  const {masterRec, billingDt, allState} = prms;
  const {com} = allState;
  const {jino} = com;
  console.log(masterRec);
  const totalBilling = masterRec.totalized.reduce(
    (v, e) => (v + (e.userSanteiTotal - e.kanrikekkagaku)), 0
  )
  const r = [jino, masterRec.curMonth, masterRec.thisMonth, totalBilling];
  console.log(r, 'makeFactDt');
  return [r];
  
}

// ターゲットに応じたデータの作成をする
const makeData = (prms) => {
  const {allState, dt, target} = prms;
  const {com } = allState;
  // finddeeppath 
  const itakuCode = findDeepPath(com, 'etc.bank_info.委託者番号', '');
  const itakuName = findDeepPath(com, 'etc.bank_info.口座名義人', '');
  const bankCode = findDeepPath(com, 'etc.bank_info.金融機関番号', '');
  const brunchCode = findDeepPath(com, 'etc.bank_info.店舗番号', '');
  const syumokuName = findDeepPath(com, 'etc.bank_info.預金種目', '');
  const kouzaBango = findDeepPath(com, 'etc.bank_info.口座番号', '');
  if (target === 'fctSanin'){
    return (makeFactDt(prms));
  }
  
  // インプット要素より日付を取得 2022-04-01 -> 0401
  const FKdate = document.querySelector('#userbillingFKdate [name="振替日"')
  .value.replace(/\-/g, '').slice(-4);
  // ヘッダレコードのコード区分。何故か種類がある
  const headCodeKubun = {mbs: '2', hamagin: '2', sanin: '1'};
  const syumoku = {普通: '1', 当座: '2', 貯蓄: '4',}
  // const head0 = '191'; // 固定
  // const head1 = headCodeKubun[target]? headCodeKubun[target]: '1';
  // const head2Itaku = zp(itakuCode, 10); // 委託者番号
  // const head3Iname = spfill(convHankaku(itakuName), 40); // 委託者コード
  // const head5Date = spfill(FKdate, 4); // 引き落とし日
  // const head6bankCode = spfill(bankCode, 4); // 金融機関コード
  // const head7bankName = spfill('', 15); // 銀行名
  // const head8brunchCode = spfill(brunchCode, 15); // 銀行名
  const zenginHead = () => {
    const a = [
      '191', // 固定
      headCodeKubun[target]? headCodeKubun[target]: '1',
      zp(itakuCode, 10), // 委託者番号
      spfill(zen2han(itakuName), 40), // 委託者コード
      spfill(FKdate, 4), // 引き落とし日
      spfill(bankCode, 4), // 金融機関コード
      spfill('', 15), // 銀行名
      spfill(brunchCode, 3), // 支店コード
      spfill('', 15), // 支店名
      syumoku[syumokuName]? syumoku[syumokuName]: 1, // 預金種目 未入力は1
      zp(kouzaBango, 7),
    ];
    let s = '';
    a.forEach(e=>{s += e});
    return s;
  }
  const zenginDetail = dt.filter(e=>e.check).map(e=>{
    let kokyakuCode;
    if (target === 'hamagin'){
      // 浜銀は８桁
      kokyakuCode = itakuCode? itakuCode.slice(-8) : zp(0, 8);
      kokyakuCode += zp(e.顧客コード.trim()? e.顧客コード: '0', 10);
    }
    else if (target === 'mbs'){
      kokyakuCode = itakuCode? itakuCode.slice(-8) : zp(0, 8);
      kokyakuCode += ('  ' + e.hno);
    }
    else{
      kokyakuCode = zp(e.顧客コード.trim()? e.顧客コード: '0', 20)
    }
    kokyakuCode = spfill(kokyakuCode, 20);
    const a = [
      '2', 
      zp(e.金融機関番号.trim()? e.金融機関番号: 0 , 4),
      spfill('', 15),
      zp(e.店舗番号.trim()? e.店舗番号: 0 , 3),
      spfill('', 15),
      spfill('', 4),
      syumoku[e.預金種目]? syumoku[e.預金種目]: 1, // 預金種目無入力の場合は1
      zp(e.口座番号.trim()?e.口座番号: 0, 7),
      spfill(zen2han(e.口座名義人), 30),      
      zp(e.actualCost + e.ketteigaku, 10),
      e.shinki? '1': '0',
      kokyakuCode,
      '0', // 振替結果コード ここでは0で固定
      spfill('', 8),
    ];
    let s = '';
    a.forEach(e=>{s += e});
    return s;
  });
  const zenginTraler = () => {
    const total = dt.filter(e=>e.check)
    .reduce((v, e) => (v + (e.ketteigaku + e.actualCost)), 0);
    const length = dt.filter(e=>e.check).length
    const a = [
      '8', // 8＝トレーラレコード
      zp(length, 6), // データレコードの合計件数（右詰、残り前ゼロ）
      zp(total, 12),// データレコードの合計金額（右詰、残り前ゼロ）
      zp(0, 6),// 全て「0」またはスペース　※振替結果連絡時：件数を記載
      zp(0, 12),// 全て「0」またはスペース　※振替結果連絡時：金額を記載
      zp(0, 6),// 全て「0」またはスペース　※振替結果連絡時：件数を記載
      zp(0, 12),// 全て「0」またはスペース　※振替結果連絡時：金額を記載
      spfill('', 65),
    ]
    let s = '';
    a.forEach(e=>{s += e});
    return s;
  }
  const zenginEnd = () => ('9' + spfill('', 119));
  // 全銀フォーマット
  if (['hamagin', 'mbs', 'sanin'].indexOf(target)> -1){
    const rt = [];
    if (target !== 'mbs') rt.push(zenginHead());
    rt.push(...zenginDetail);
    if (target !== 'mbs') rt.push(zenginTraler());
    if (target !== 'mbs') rt.push(zenginEnd());
    return rt;
  }

}

const sendAndMake = async (prms) => {
  const {
    allState, dt, target, setTarget, 
    res, setRes, setSnack, masterRec, billingDt
  } = prms;
  // {hid, bid, date, jino, item, state, } 
  const {hid, bid, com, stdDate} = allState;
  const jino = com.jino;
  const date = stdDate;
  const item = 'KF';
  const sendDt = makeData(prms);
  console.log(sendDt, 'sendDt');
  // namesは入れ子の配列要素なので削除
  // const sendDt = dt.filter(e=>(e.actualCost + e.ketteigaku > 0)).map(e=>{
  //   delete e.names;
  //   return e;
  // })
  
  // データのチェック
  const fmDateT = document.querySelector('#userbillingFKdate [name="振替日"').value;
  const thisDay = formatDate(new Date(), 'YYYY-MM-DD');
  if (fmDateT < thisDay){
    setSnack({msg: '日付を確認してください。', severity: 'warning'})
    return false;
  }
  if (((new Date(fmDateT) - new Date()) / 86400000) > 95){
    setSnack({msg: '日付を確認してください。', severity: 'warning'})
    return false;
  }
  const kouzaBango = findDeepPath(com, 'etc.bank_info.口座番号', '');
  if (target !== 'fctSanin' && !kouzaBango){
    setSnack({msg: '口座情報が未入力です。', severity: 'warning'})
    return false;
  }



  const t = {...res, loading: true};
  setRes({...t});
  try{
    const sendPrms = {
      hid, bid, jino, date, item, target, state: JSON.stringify(sendDt)
    };
    // ここではsetresは送信しない
    let r = await sendSomeState(
      sendPrms, '', setSnack, '内部データを送信しました。'
    );
    t.sendSomeState = r;
    setRes({...t});

    if (r.status !== 200)  throw r;
    if (!r.data) throw r;
    const rnddir = randomStr(20, 0);
    const prefix = com.fprefix;
    let format, encode;
    if (target === 'fctSanin'){
      format = 'csv';
      encode = 'utf-8';
    }
    else{
      format = 'fixed';
      encode = 'shift_jis';
    }
  
    const genPrms = {
      jino, date, item, encode, rnddir, prefix, format, target
    }
    r = await genFKdatas(
      genPrms, '', setSnack, 'ファイルを取得しました。'
    )
    // genFKdatasの結果を同盟のオブジェクトに格納する
    t.genFKdatas = r;
    setRes({...t});

    if (r.status !== 200)  throw r;
    if (!r.data) throw r;
    t.loading = false; t.result = true;
    // 戻り地に含まれるサーバー上のアドレスをhttpアクセスできる形に変換
    const url = r.data.fname.replace(r.data.root, downloadUrlRoot);
    t.url = url;
    setRes({...t});
    setTimeout(()=>{
      // リンク消失させる ステイトの初期化  
      setTarget('');
      setRes({done: false, loading: false, result: false})
    }, linkErase * 1000)
  }
  catch(e){
    console.log(e, 'sendMakeErr');
    setRes({...res, done: true, loading:false, result:false});
  }
}

const ItemSelect = (props) => {
  const {item, setItem} = props;
  const handleChange = (e) => {
    setItem(e.currentTarget.value)
  }
  return (
    <SelectGp
      onChange={e => handleChange(e)}
      name={'itemSelect'}
      label={'データ種別'}
      value={item}
      size={'large'}
      opts={fkItems}
    />
  )
}
const ButtonFK = (props) => {
  const {dt, target, setTarget, res, setRes, setSnack, masterRec, billingDt} = props;
  const allState = useSelector(state=>state);
  const prms = {
    dt, target, setTarget, allState, masterRec, billingDt, res, setRes, setSnack
  }
  const classes = useStyles();
  const clickHandler = () => {
    sendAndMake(prms);
  }
  const disabled = !target? true: false
  const GenButton = () =>(
    <div className={classes.buttonFKroot}>
      <Button 
        variant="contained" color="primary" component="span"
        onClick={clickHandler} disabled={disabled}
      >
        データの作成
      </Button>
    </div>

  )
  const Loading = () => (
    <span className={classes.loading}>作成中</span>
  )

  const DownloadButton = (props) =>{
    const {url} = props;
    return(
      <div className={classes.buttonFKroot} >
        <a href= {url} download>
          <Button variant="contained" color="secondary" component="span">
            ダウンロード
          </Button>
        </a>
      </div>
    )
  }
  if (!res.result && !res.loading){
    return (<GenButton />)
  }
  else if (res.loading) {
    return (<Loading />)
  }
  else if (res.result && !res.loading){
    return (<DownloadButton url={res.url}/>)
  }
}
// 法人口座情報を表示
const ComAccountInfo = (props) => {
  const {allState, } = props;
  const {com } = allState;
  const classes = useStyles()
  // finddeeppath 
  const itakuCode = findDeepPath(com, 'etc.bank_info.委託者番号', '');
  const itakuName = findDeepPath(com, 'etc.bank_info.口座名義人', '');
  const bankCode = findDeepPath(com, 'etc.bank_info.金融機関番号', '');
  const brunchCode = findDeepPath(com, 'etc.bank_info.店舗番号', '');
  const syumokuName = findDeepPath(com, 'etc.bank_info.預金種目', '');
  const kouzaBango = findDeepPath(com, 'etc.bank_info.口座番号', '');
  return (
    <div className={classes.comAccountInfo}>
      <div className='label'>金融機関番号</div>
      <div className='cont'>{bankCode}</div>
      <div className='label'>店舗番号</div>
      <div className='cont'>{brunchCode}</div>
      <div className='label'>預金種目</div>
      <div className='cont'>{syumokuName}</div>
      <div className='label'>口座番号</div>
      <div className='cont'>{kouzaBango}</div>
      <div className='label'>口座名義人</div>
      <div className='cont'>{itakuName}</div>
      <div className='label'>委託者番号</div>
      <div className='cont'>{itakuCode}</div>
    </div>
  )
}

const MainUserBilling = () => {
  const classes = useStyles();
  const allState = useSelector(state=>state);
  const {users, schedule, com, service, serviceItems, stdDate} = allState;
  const prms = { stdDate, schedule, users, com, service, serviceItems };
  const { billingDt, masterRec, bdtResult: result } = setBillInfoToSch(prms);
  const [item, setItem] = useState();
  const [res, setRes] = useState({done:false, loading: false, result:false});
  const [snack, setSnack] = useState({msg: '', severity: ''});

  // 保護者の配列作成
  const pa = Array.from(new Set(
    billingDt.map(e=>(e.pname + ',' + e.pphone))
  ));
  const tpArray = pa.map(e=>({
    pname: e.split(',')[0], pphone: e.split(',')[1]
  }));

  // 保護者配列に実費と利用者負担額付与 口座情報付与
  tpArray.forEach(e=>{
    const t = billingDt.filter(f=>f.pname === e.pname && f.pphone === e.pphone);
    const ketteigaku = t.reduce((v, f)=>(v + f.ketteigaku), 0);
    const actualCost = t.reduce((v, f)=>(v + f.actualCost), 0);
    e.actualCost = actualCost;
    e.ketteigaku = ketteigaku;
    e.names = [];
    t.forEach(f=>{
      e.hno = f.hno; // 受給者証番号は一個だけ取得する。
      const bi = f.bank_info;
      e.口座名義人 = bi && bi.口座名義人 ? f.bank_info.口座名義人: ' ';
      e.口座番号 = bi && bi.口座番号 ? f.bank_info.口座番号: ' ';
      e.店舗番号 = bi && bi.店舗番号 ? f.bank_info.店舗番号: ' ';
      e.預金種目 = bi && bi.預金種目 ? f.bank_info.預金種目: ' ';
      e.顧客コード = bi && bi.顧客コード ? f.bank_info.顧客コード: ' ';
      e.金融機関番号 = bi && bi.金融機関番号 ? f.bank_info.金融機関番号: ' ';
      e.names.push(f.name);
    });
    e.check = e.口座番号.trim() ? true: false;
    e.shinki = false;

  });
  const [pArray, setPArray] = useState(
    tpArray.filter(e=>e.actualCost + e.ketteigaku > 0)
  );
  const handleChange = (e, i) => {
    const target = e.currentTarget;
    // const row = parseInt(target.getAttribute('row'));
    const name = target.name;
    const value = target.checked;
    const t = [...pArray];
    t[i][name] = value;
    setPArray(t);
  }
  console.log(pArray);
  const Title = () => (
    <div className='flxTitle'>
      <div className='wmin lower'>No</div>
      <div className='w13 lower'>保護者名</div>
      <div className='w13 lower'>利用者名</div>
      <div className='w07'><div>金融機関</div><div>店舗番号</div></div>
      <div className='w08'><div>預金種目</div><div>口座番号</div></div>
      <div className='w15'><div>口座名義人</div><div>顧客コード</div></div>
      <div className='w07 lower'>実費</div>
      <div className='w07'><div>利用者</div><div>負担</div></div>
      <div className='w07 lower'>請求計</div>
      <div className='wzen4 lower'>送信<br></br>実施</div>
      <div className='wzen4 lower'>新規</div>
    </div>
  )
  
  const detail = pArray.map((e, i)=>{
    const names = e.names.map((f, j)=>{
      return (<div key={j}>{f}</div>)
    })
    return(
      <div className='flxRow' key={i}>
        <div className='wmin right'>{i + 1}</div>
        <div className='w13'>{e.pname}</div>
        <div className='w13'>{names}</div>
        <div className='w07'>
          <div>{e.金融機関番号}</div><div>{e.店舗番号}</div>
        </div>
        <div className='w08'>
          <div>{e.預金種目}</div><div>{e.口座番号}</div>
        </div>
        <div className='w15'>
          <div>{e.口座名義人}</div><div>{e.顧客コード}</div>
        </div>
        <div className='w07 right'>{formatNum(e.actualCost, 1)}</div>
        <div className='w07 right'>{formatNum(e.ketteigaku, 1)}</div>
        <div className='w07 right'>
          {formatNum(e.actualCost + e.ketteigaku, 1)}
        </div>
        <div className={classes.checkboxRoot + ' wzen4'}>
          <Checkbox 
            checked={e.check} name='check'
            onChange={e=>handleChange(e, i)}
          />
        </div>
        <div className={classes.checkboxRoot + ' wzen4'}>
          <Checkbox 
            checked={e.shinki} name='shinki'
            onChange={e=>handleChange(e, i)}
          />
        </div>
      </div>
    )
  });
  const Total = () => {
    // 請求計
    const actualcost = pArray.reduce((v, e) => (v + e.actualCost), 0);
    const ketteigaku = pArray.reduce((v, e) => (v + e.ketteigaku), 0);
    const total = actualcost + ketteigaku;
    const count = pArray.length;
    // 振替計
    const checked = pArray.filter(e=>e.check);
    const actualcostFk = checked.reduce((v, e) => (v + e.actualCost), 0);
    const ketteigakuFk = checked.reduce((v, e) => (v + e.ketteigaku), 0);
    const totalFk = actualcostFk + ketteigakuFk;
    const countFk = checked.length;
    return (<>
      <div className='flxRow' style={{marginTop: 8}}>
        <div className='wmin'></div>
        <div className='w13'>請求計</div>
        <div className='w13'></div>
        <div className='w07'></div>
        <div className='w08'></div>
        <div className='w15 right'>{count}件</div>
        <div className='w07 right'>{formatNum(actualcost, 1)}</div>
        <div className='w07 right'>{formatNum(ketteigaku, 1)}</div>
        <div className='w07 right'>{formatNum(total, 1)}</div>
        <div className='wzen4'></div>
        <div className='wzen4'></div>
      </div>
      <div className='flxRow' style={{marginTop: 8}}>
        <div className='wmin'></div>
        <div className='w13'>振替計</div>
        <div className='w13'></div>
        <div className='w07'></div>
        <div className='w08'></div>
        <div className='w15 right'>{countFk}件</div>
        <div className='w07 right'>{formatNum(actualcostFk, 1)}</div>
        <div className='w07 right'>{formatNum(ketteigakuFk, 1)}</div>
        <div className='w07 right'>{formatNum(totalFk, 1)}</div>
        <div className='wzen4'></div>
        <div className='wzen4'></div>
      </div>
    </>)
  }
  const appPageStyle={paddingTop: 40, marginBottom: 40};
  let today = new Date();
  today.setDate(today.getDate() + 1)
  return(
    <div className='AppPage' style={appPageStyle}>
      <div className={classes.cntRow}>
        <ItemSelect item={item} setItem={setItem} />
        <DateInput 
          name='振替日' def={formatDate(today, 'YYYY-MM-DD')}
          id='userbillingFKdate' label='振替日'
        />
        <ButtonFK 
          dt={pArray} target={item} setTarget={setItem} res={res} 
          billingDt={billingDt} masterRec={masterRec}
          setRes={setRes} setSnack={setSnack} 
        />
        <div style={{padding: '16px 0 0 40px'}}>
          <ExcahngeTotalizeButton />
        </div>
      </div>
      <ComAccountInfo allState={allState} />
      <Title />
      {detail}
      <Total />
      <SnackMsg {...snack} />

    </div>
  )
}

const UserBilling = ()=>{
  const allstate = useSelector(state=>state);
  const loadingStatus = getLodingStatus(allstate);
  const account = useSelector(state => state.account);
  const permission = parsePermission(account)[0][0];

  if (loadingStatus.loaded && permission >= 90){
    return(<>
      <MainUserBilling />
    </>)
  }
  else if (loadingStatus.error){
    return (<>
      <LoadErr loadStatus={loadingStatus} errorId={'E5958'} />
    </>)
  }
  else if (permission < 90) return <PermissionDenied marginTop='90'/>
  else{
    return(<>
     <LoadingSpinner/>
    </>)
  }
}
export default UserBilling;