// 汎用関数にするのをまとめてみる
import { DevicesOther, Phone } from '@material-ui/icons';
import Cookies from 'js-cookie';
import * as Actions from './Actions';
import axios from 'axios';
// import {endPoint} from './Rev';  
import {endPoint} from './albCommonModule';  
import { faLeaf, faSleigh } from '@fortawesome/free-solid-svg-icons';
import { classroomCount, isClassroom } from './albCommonModule';


let saveTimer;  // タイマーイベント
const SAVE_LATER = 3 * 1000;  // 変更後何秒で保存するか
const passWdPtn = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!-/:-@[-`{-~])[!-~]*$/;

export const PERMISSION_NAMES = [
  {value: 100, name: 'デベロッパー', comment:'開発用のアカウントです。',},
  {
    value: 95, name: 'トップマネージャー', 
    comment:'すべての操作、アカウント管理が出来ます。',
  },
  {value: 90, name: 'マネージャー', comment:'すべての操作が可能です。'},
  {value: 80, name: 'スタッフ', comment:'予定の編集などが行なえます。'},
];

export const setCookeis = (name, value, expires=30)=>{
  Cookies.set(name, value, {expires});
}

export const getCookeis = (name)=>{
  return (Cookies.get(name));
}

export const removeCookieAll = () => {
  const c = Cookies.get();
  Object.keys(c).forEach(e=>{
    Cookies.remove(e)
  });
  return c;
}

export const shortWord = (wd, size='s')=>{
  const lst = [
    {l:'放課後等デイサービス',s: '放デイ',},
    {l:'児童発達支援',s: '児発',},
    {l:'サービス種別',s: 'サービス',},
    {l:'重症心身障害児',s: '重障害児',},
    {l:'福祉専門職員配置等加算',s: '福祉専門配置',},
    {l:'福祉専門職員配置等加算Ⅰ',s: '福祉配置Ⅰ',},
    {l:'福祉専門職員配置等加算Ⅱ',s: '福祉配置Ⅱ',},
    {l:'福祉専門職員配置等加算Ⅲ',s: '福祉配置Ⅲ',},
    {l:'医療連携体制加算',s: '医療連携',},
    {l:'医療連携体制加算Ⅰ',s: '医療連携Ⅰ',},
    {l:'医療連携体制加算Ⅱ',s: '医療連携Ⅱ',},
    {l:'医療連携体制加算Ⅲ',s: '医療連携Ⅲ',},
    {l:'医療連携体制加算Ⅳ',s: '医療連携Ⅳ',},
    {l:'医療連携体制加算Ⅳ１',s: '医療連携Ⅳ１',},
    {l:'医療連携体制加算Ⅳ２',s: '医療連携Ⅳ２',},
    {l:'医療連携体制加算Ⅳ３',s: '医療連携Ⅳ３',},
    {l:'医療連携体制加算Ⅴ',s: '医療連携Ⅴ',},
    {l:'医療連携体制加算Ⅴ１',s: '医療連携Ⅴ１',},
    {l:'医療連携体制加算Ⅴ２',s: '医療連携Ⅴ２',},
    {l:'医療連携体制加算Ⅴ３',s: '医療連携Ⅴ３',},
    {l:'医療連携体制加算Ⅵ',s: '医療連携Ⅵ',},
    {l:'関係機関連携加算',s: '関係機関連携',},
    {l: '関係機関連携加算Ⅰ', s: '関係機関連携Ⅰ',},
    {l: '関係機関連携加算Ⅱ', s: '関係機関連携Ⅱ',},
    {l:'児童指導員配置加算',s: '児童指導員配置',},
    {l:'児童指導員等加配加算（Ⅰ）',s: '指導員等加配Ⅰ',},
    {l:'児童指導員等加配加算（Ⅱ）',s: '指導員等加配Ⅱ',},
    {l:'その他の従業者',s: 'その他',},
    {l:'身体拘束廃止未実施減算',s: '拘束未実施',},
    {l:'共生型サービス',s: '共生型',},
    {l:'障害児状態等区分',s: '状態区分',},
    {l:'看護職員加配加算',s: '看護職員加配',},
    {l:'看護職員加配加算Ⅰ',s: '看護配置Ⅰ',},
    {l:'看護職員加配加算Ⅱ',s: '看護配置Ⅱ',},
    {l:'看護職員加配加算Ⅲ',s: '看護配置Ⅲ',},
    {l:'4時間以上6時間未満',s: '6時間未満',},
    {
      l:'児童発達支援管理責任者かつ保育士又は児童指導員',
      s:'自発責で保育/指導員'
    },
    {l:'児童発達支援管理責任者の場合',s: '自発責',},
    {l:'保育士又は児童指導員の場合',s: '保育/指導員',},
    {l:'福祉・介護職員処遇改善加算',s: '処遇改善',},
    {l:'福祉・介護職員処遇改善加算Ⅰ',s: '処遇改善Ⅰ',},
    {l:'福祉・介護職員処遇改善加算Ⅱ',s: '処遇改善Ⅱ',},
    {l:'福祉・介護職員処遇改善加算Ⅲ',s: '処遇改善Ⅲ',},
    {l:'福祉・介護職員処遇改善加算Ⅳ',s: '処遇改善Ⅳ',},
    {l:'福祉・介護職員処遇改善加算Ⅴ',s: '処遇改善Ⅴ',},
    {l:'福祉・介護職員処遇改善加算Ⅵ',s: '処遇改善Ⅵ',},
    { l: '利用者負担上限額管理加算', s: '上限管理加算', },
    { l: '定員超過利用減算', s: '定員超過減算', },
    {l: '訪問支援特別加算', s: '訪問支援' },
    {l: '事業所内相談支援加算', s: '事業所内相談' },
    { l: '強度行動障害児支援加算', s: '強行支援' },
    { l: '保育・教育等移行支援加算', s: '保育教育移行' },
    { l: '保育・教育等移行支援加算', s: '保育教育移行' },
    { l: '医療ケア児基本報酬区分', s: '医療的ケア児' },
    { l: '医療的ケア児32点以上', s: '32点以上' },
    { l: '医療的ケア児16点以上', s: '16点以上' },
    { l: '医療的ケア児3点以上', s: '3点以上' },
    { l: '放デイ身体拘束廃止未実施減算', s: '拘束廃止未実施' },
    {l: '専門的支援加算', s:'専門的支援加算'},
    {l: '個別サポート加算', s:'個別サポート'},
    {l: '個別サポート加算Ⅰ', s:'個別サポⅠ'},
    {l: '個別サポート加算Ⅱ', s:'個別サポⅡ'},
    {l: '個別サポート加算１', s:'個別サポート１'},
    {l: '個別サポート加算２', s:'個別サポート２'},
    {l: '欠席時対応加算１', s:'欠席対応１'},
    {l: '欠席時対応加算２', s:'欠席対応２'},
    {l: '人工内耳装用児支援加算', s:'人工内耳支援'},
    {l: '児童発達支援無償化', s:'児発無償化'},
    {l: '管理事業所', s:'管理'},
    {l: '協力事業所', s:'協力'},

  ];

  const r = lst.filter(e=>e.l === wd);
  if (!r.length)  return wd;
  return (size === 's') ? r[0].s : r[0].m;
}

// 誕生日より学齢や年齢を計算する
export const getAge = (birthday, nDate = new Date()) =>{
  const ymd = birthday.split('-');
  if (typeof nDate === 'string'){
    nDate = toDateApiDateStr(nDate);
  }
  const bDate = new Date(ymd[0], ymd[1] - 1, ymd[2]);
  // const nDate = new Date();
  let age = nDate.getFullYear() - bDate.getFullYear() - 1;
  if (nDate.getMonth() > bDate.getMonth()){
    age++;
  }
  if (nDate.getMonth() === bDate.getMonth() && nDate.getDate() >= bDate.getDate()) {
    age++;
  }
  // let schoolAgeCnt = age - 6;
  // 誕生日と今日の日付を4文字の文字列化
  // const bDateStr = birthday.replace(/-/g, '').slice(-4);
  // const nDateStr = 
  //   ('0' + (nDate.getMonth() + 1)).slice(-2) + ('0' + nDate.getDate()).slice(-2);

  // 誕生日と今日の日付を4文字の文字列化
  const bDateStr = birthday.replace(/-/g, '').slice(-4);
  const nDateStr = 
    ('0' + (nDate.getMonth() + 1)).slice(-2) + ('0' + nDate.getDate()).slice(-2);
  // 誕生日を0401で丸める
  const bDateA1 = (bDateStr <= '0401')? 
    new Date(bDate.getFullYear(), 3, 1) : 
    new Date(bDate.getFullYear() + 1, 3, 1) 
  // console.log(nDateStr, 'nDateStr', bDateStr, 'bDateStr', bDateA1, 'bDateA1');
  let schoolAgeCnt = nDate.getFullYear() - bDateA1.getFullYear() - 6;
  if (nDateStr < '0401') schoolAgeCnt--;

  let schoolAge;
  if (schoolAgeCnt >= 0 && schoolAgeCnt <= 12){
    schoolAge = [
      '小1', '小2', '小3', '小4', '小5', '小6', 
      '中1', '中2', '中3', '高1', '高2', '高3',
    ][schoolAgeCnt];
  }
  else{
    schoolAge = '';
  }
  const ageStr = age + '歳';
  const flx = (!schoolAge) ? ageStr : schoolAge;
  const schoolAgeNdx = schoolAgeCnt + 7;
  return { age, ageStr, schoolAge, schoolAgeNdx, flx};
}
/// --------------- const zp = (n, l)
/// 2019-08-15 yoshi
/// ゼロ埋めを行う
/// zp(10,3)
/// 010
export const zp = (n, l) => {
  n = n? n: '0';
  let s = '0'.repeat(l);
  n = (s + n).slice(-l);
  return (n)
}

/// --------------- const spfill = (n, l)
/// 2022-04-02
/// スペースで埋めて文字列長さを揃える
/// spfill("aaa",20)
/// 'aaa                 '
export const spfill = (str, l) => {
  str = str? str: '';
  let s = ' '.repeat(l);
  return (str + s).slice(0, l);
}

/// --------------- formatNum = (n, c = 0, zp = 0, dp = 0)
/// 2019-08-14 yoshi
/// 数値のフォーマットを行う
/// n : 対象数値、c : 0 = カンマ削除、1 = カンマ付与
/// zp : ゼロ埋め桁数。桁にはカンマも含む
/// dp : 小数点以下
/// 戻り値 文字列
/// formatNum('1000', 1)
/// "1,000"
/// formatNum('1,000', 0, 0, 2)
/// "1000.00"
/// nandisp falseでNaNを表示しない

export const formatNum = (n, c = 0, zp = 0, dp = 0, nanDisp = false) => {
  n = String(n).replace(/,/g, ''); //あらかじめカンマを削除
  n = parseFloat(n);
  if (isNaN(n) && !nanDisp) return '';
  let fp = n - Math.floor(n); // 小数点以下を保持
  // 小数点以下がない場合はここで四捨五入
  if (dp === 0) {
    n = Math.round(n);
  }
  else {
    n = Math.floor(n);
  }
  // カンマ付与
  if (c === 1) {
    n = String(n).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  }
  // ゼロ埋め
  if (zp > 0) {
    n = zp(n, zp);
  }
  // 小数点以下の処理
  if (dp > 0) {
    n += fp.toFixed(dp).slice(1);
  }
  return (n);
}

/// ---------------- getWd = (d)
/// 2019-08-15 yoshi
/// 対応した曜日を返す
/// 戻り値 obj
/// getWd(0)
/// {s:'Sun',l:'Sunday',jp:'日',jpm:'(日)',jpl:'日曜日'}
export const getWd = (d) => {
  let a = [
    { s: 'Sun', l: 'Sunday', jp: '日', jpm: '(日)', jpl: '日曜日' },
    { s: 'Mon', l: 'Monday', jp: '月', jpm: '(月)', jpl: '月曜日' },
    { s: 'Tue', l: 'Tuesday', jp: '火', jpm: '(火)', jpl: '火曜日' },
    { s: 'Wed', l: 'Wednesday', jp: '水', jpm: '(水)', jpl: '水曜日' },
    { s: 'Thu', l: 'Thursday', jp: '木', jpm: '(木)', jpl: '木曜日' },
    { s: 'Fri', l: 'Friday', jp: '金', jpm: '(金)', jpl: '金曜日' },
    { s: 'Sut', l: 'Saturday', jp: '土', jpm: '(土)', jpl: '土曜日' },
  ];
  return (a[d]);
}

/**
 * 日付をフォーマットする
 * @param  {Date}   date     日付
 * @param  {String} [fmt] フォーマット
 * @return {String} フォーマット済み日付
 */
export const formatDate = (date, fmt) =>{
  if (typeof date !== "object") return "";
  if (isNaN(date.getDate())) return "";
  if (!fmt) fmt = 'YYYY-MM-DD hh:mm:ss.SSS';
  fmt = fmt.replace(/YYYY/g, date.getFullYear());
  fmt = fmt.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
  fmt = fmt.replace(/DD/g, ('0' + date.getDate()).slice(-2));
  fmt = fmt.replace(/hh/g, ('0' + date.getHours()).slice(-2));
  fmt = fmt.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
  fmt = fmt.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
  fmt = fmt.replace(/AAA/, getWd(date.getDay()).jpl);
  fmt = fmt.replace(/AA/, getWd(date.getDay()).jpm);
  fmt = fmt.replace(/A/, getWd(date.getDay()).jp);

  if (fmt.match(/S/g)) {
    var milliSeconds = ('00' + date.getMilliseconds()).slice(-3);
    var length = fmt.match(/S/g).length;
    for (var i = 0; i < length; i++) fmt = fmt.replace(/S/, milliSeconds.substring(i, i + 1));
  }
  return fmt;
};

// dが日付オブジェクトだったらdidフォーマット
// DYYYYMMDDにして返す
// ストリングだったら日付オブジェクトにすることをトライ
export const convDid = (did) =>{
  if (typeof did === "object"){
    try{
      return "D" + formatDate(did, "YYYYMMDD");
    }
    catch{
      return false;
    }
  }
  else if (typeof did === "string"){
    try{
      return new Date(
        parseInt(did.substr(1, 4)),
        parseInt(did.substr(5, 2)) - 1,
        parseInt(did.substr(7, 2))
      );
    }
    catch{
      return false;
    }
  }
}

// nullやundefinedを0にして返す
export const null2Zero = (v) => {
  if (isNaN(v))  return 0;
  if (v === undefined)  return 0;
  if (v === null)  return 0;
  return v;
}

// uidよりusersのオブジェクトを返す
// usersのリストを受け取る
export const getUser = (UID, users, nextUsers='')=>{
  if (!UID) return {};  // 空のオブジェクト
  if (!Array.isArray(users)) return {};
  const uid = UID.replace(/[^0-9]/g, '');
  const user = users.filter(e=>{
    return e.uid === uid;
  });
  const next = Array.isArray(nextUsers)
  ? nextUsers.find(e=>e.uid === uid): null;
  // 非該当だとundefind
  // その場合空のオブジェクトを返す
  const ret = (!user.length) ? {} : user[0];
  // 次の情報がある場合は戻り値に加える
  if (next) ret.next = next.next;
  return (!user.length) ? {} : user[0];

}

/// --------------- getDateEx = (y, m, d)
/// 該当日を返す。0は月末を返す。曜日も書式別の文字を返す
/// 月の指定はjsの指定より+1する
/// 戻り値 obj
/// 戻り値のwdはgetWdの値を返す
/// wrは年号を返す
/// getDateEx(2019,12,0)
/// {y:2019,m:12,d:31,wd:{}, wr, dt:Tue Dec 31 2019 00:00:00 GMT+0900}
/// getDateEx(2020,-1,1)
/// {y:2019,m:11,d:1,wd:{}, wr, dt:Fri Nov 01 2019 00:00:00 GMT+0900}
/// getDateEx(2019,5,1)
/// {"y":2019,"m":5,"d":1,
///  "wd":{"s":"Wed","l":"Wednesday","jp":"水","jpm":"(水)","jpl":"水曜日"},
///  "wr":{"s":"R.","l":"令和","y":1,"full":"令和元年"},
///  "dt":"2019-04-30T15:00:00.000Z"}"
export const getDateEx = (y, m, d) => {
  let tmpD = new Date();
  if (isNaN(y)) {
    y = tmpD.getFullYear();
  }
  if (isNaN(m)) {
    m = tmpD.getMonth() + 1;
  }
  if (isNaN(d)) {
    d = tmpD.getDate();
  }
  if (d === 0) {
    m++;
  }
  let date = new Date(y, m - 1, d);
  let meiji = new Date(1868, 9 - 1, 8);
  let taisyo = new Date(1912, 7 - 1, 30);
  let syouwa = new Date(1926, 12 - 1, 25);
  let heisei = new Date(1989, 1 - 1, 8);
  let reiwa = new Date(2019, 5 - 1, 1);
  let full, wr;
  let wry; // 和暦による年
  if (date >= reiwa) {
    wry = date.getFullYear() - reiwa.getFullYear() + 1;
    if (wry === 1) full = "令和元年";
    else full = "令和" + wry + "年";
    wr = { s: 'R.', l: '令和', y: wry, full: full };
  }
  else if (date >= heisei) {
    wry = date.getFullYear() - heisei.getFullYear() + 1;
    if (wry === 1) full = "平成元年";
    else full = "平成" + wry + "年";
    wr = { s: 'H.', l: '平成', y: wry, full: full };
  }
  else if (date >= syouwa) {
    wry = date.getFullYear() - syouwa.getFullYear() + 1;
    if (wry === 1) full = "昭和元年";
    else full = "昭和" + wry + "年";
    wr = { s: 'S.', l: '昭和', y: wry, full: full };
  }
  else if (date >= taisyo) {
    wry = date.getFullYear() - taisyo.getFullYear() + 1;
    if (wry === 1) full = "大正元年";
    else full = "大正" + wry + "年";
    wr = { s: 'T.', l: '大正', y: wry, full: full };
  }
  else if (date >= meiji) {
    wry = date.getFullYear() - meiji.getFullYear() + 1;
    if (wry === 1) full = "明治元年";
    else full = "明治" + wry + "年";
    wr = { s: 'M.', l: '明治', y: wry, full: full };
  }
  else {
    wr = { s: 'unknown', l: 'unknown', y: 'unknown', full: 'unknown' };
  }
  y = date.getFullYear();
  d = date.getDate();
  m = date.getMonth() + 1;
  let wd = getWd(date.getDay());
  return ({ y: y, m: m, d: d, wd: wd, wr: wr, dt: date });
}

// 文字列を日付に変換する
// 2021/08/08 年が省かれていても入力可能にした
export const parseDate = (str)=>{
  str = convHankaku(str);
  const thisYear = new Date().getFullYear() + '';
  let [yearOrg, month, day]  = str.split(/[\.\-\/\s]/);
  // 日付が解釈できない場合は年が未指定と解釈
  if (!day){
    day = month;
    month = yearOrg;
    yearOrg = thisYear;
  }
  // 分割できない場合 固定長で分割
  if (!(yearOrg && month && day)){
    // 数値のみ取り出す
    str = str.replace(/[^0-9]/g, '');
    if (str.length === 4) str = thisYear + str; // 年が省かれていることを想定 
    yearOrg = str.substr(0, 4);
    month = str.substr(4, 2);
    day = str.substr(6, 2);
  }
  // それでも分割できない場合
  if (!(yearOrg && month && day)) return { result: false };

  const year = yearOrg.toUpperCase();
  let yearN = year.replace(/[^0-9]/g, ''); //数値だけ取り出す
  // 数値としてパースできない
  if (isNaN(yearN) || isNaN(month) || isNaN(day)) return { result: false };
  const yearL = year.substr(0, 1);  // 元号があったら取り出す
  if (yearL === 'S') yearN = parseInt(yearN) + 1925;  // 昭和
  if (yearL === 'H') yearN = parseInt(yearN) + 1988;  // 平成
  if (yearL === 'R') yearN = parseInt(yearN) + 2018;  // 令和
  const dateResult = getDateEx(yearN, month, day);
  return {result:true, date:dateResult};
}

// 一ヶ月の日付オブジェクト配列を返す
// mはjsのmonthではなく普通に月で示す
// getDatesArrayOfMont(2020, 4)
// [2001/04/01,2001/04/02...2001/04/30]
export const getDatesArrayOfMonth = (year, month) =>{
  const ret = [];
  let dd = getDateEx(year, month, 1).dt;
  const ed = getDateEx(year, month, 0).dt;
  let y = dd.getFullYear();let m = dd.getMonth();let d=dd.getDate();
  do {
    let a = new Date(y, m, d++);
    ret.push(a);
    if (a >= ed)  break;
  } while (true);
  return(ret);
}

// {おやつ: 100, 教材費: 200, レク費: 300}
// =>
// [おやつ,100],[教材費,200],[レク費,300],
export const objToArray = (obj) => {
  const rt = [];
  if (obj === undefined) return rt;
  for (let k of Object.keys(obj)) {
    rt.push([k, obj[k]]);
  }
  return rt;
}
// startとendはhh:mmのフォーマットで
// stepは分数
// start 07:00 end 08:00 step 30=>07:00,07:30,08:30
export const timePickerList = (start, end, step)=>{
  let hh = parseInt(start.split(':')[0]);
  let mm = parseInt(start.split(':')[1]);
  const endH = parseInt(end.split(':')[0]);
  const endM = parseInt(end.split(':')[1]);
  const ret = [{h:hh, m: mm, str:start}];
  let base60calc = (h, m, s)=>{
    m += s;
    if (m >= 60)  h++;
    m = m % 60;
    const str = zp(h, 2) + ":" + zp(m, 2);
    return ({h, m, str});
  }
  while (hh < endH || (hh == endH && mm < endM)){
    const r = base60calc(hh, mm, step);
    ret.push(r);
    hh = r.h; mm = r.m;
  }
  return(ret);
}

// axios post送信用にパラメータをurl引数オブジェクトに
// 変換する
export const makeUrlSearchParams = (params)=>{
  let rt = new URLSearchParams('');
  Object.keys(params).forEach(key=>{
    rt.append(key, params[key]);
  });
  return rt;
}

// makeUrlSearchParamsのエイリアス
export const uPrms = (params) => makeUrlSearchParams(params);

// apiでフェッチした日付が文字列のままなので変換する
// "2020-06-30T15:00:00.000Z"
export const toDateApiDateStr = (str) =>{
  if (str.indexOf('T') > -1) str = str.split('T')[0];
  return (new Date(
    parseInt(str.split('-')[0]),
    parseInt(str.split('-')[1]) - 1,
    parseInt(str.split('-')[2]),
  ));
}

// obj = { a: 0, b: { a: 1, b: 2 } };
// path = ['b', 'a'] // 1
// path = ['b', 'b'] // 2
// path = ['b', 'b', 'c'] // null
// オブジェクトからパスの深いところを探す
// objは'a.b.c'のような文字列でも可とする
// 戻り値が配列でも添字をキーにしたオブジェクトで返す->しょうがないかな？
export const findDeepPath = (obj, path, notFound = null) => {
  if (typeof path === 'string'){
    path = path.split('.');
  }

  let a = Object.assign({}, obj);
  let b;
  let rtn;
  for (let i = 0; i < path.length; i++) {
    let e = path[i];
    if (e in a) {
      if (typeof a[e] === 'object')
        b = Object.assign({}, a[e]);
      else{
        rtn = a[e];
        break;
      }
      if (!Object.keys(b).length) {
        rtn = (i === path.length - 1) ? a[e] : null
        break;
      }
      a = Object.assign({}, b);
      rtn = Object.assign({}, b);
    }
    else {
      rtn = null;
      break;
    }
  };
  const r = rtn ? rtn: notFound;
  return r;
}
// エイリアス
export const fdp = (obj, path, notFound = null) => {
  const v = findDeepPath(obj, path, notFound);
  return v;
}

// 全角から半角に変換
export const zen2han = (src) =>{
  if (!src) return src;
  const zen = [
    'ア', 'イ', 'ウ', 'エ', 'オ', 'カ', 'キ', 'ク', 'ケ', 'コ',
    'サ', 'シ', 'ス', 'セ', 'ソ', 'タ', 'チ', 'ツ', 'テ', 'ト',
    'ナ', 'ニ', 'ヌ', 'ネ', 'ノ', 'ハ', 'ヒ', 'フ', 'ヘ', 'ホ',
    'マ', 'ミ', 'ム', 'メ', 'モ', 'ヤ', 'ヰ', 'ユ', 'ヱ', 'ヨ',
    'ラ', 'リ', 'ル', 'レ', 'ロ', 'ワ', 'ヲ', 'ン',
    'ガ', 'ギ', 'グ', 'ゲ', 'ゴ', 'ザ', 'ジ', 'ズ', 'ゼ', 'ゾ',
    'ダ', 'ヂ', 'ヅ', 'デ', 'ド', 'バ', 'ビ', 'ブ', 'ベ', 'ボ',
    'パ', 'ピ', 'プ', 'ペ', 'ポ',
    'ァ', 'ィ', 'ゥ', 'ェ', 'ォ', 'ャ', 'ュ', 'ョ', 'ッ',
    '゛', '°', '、', '。', '「', '」', 'ー', '・','　',
  ];
  const han = [
    'ｱ', 'ｲ', 'ｳ', 'ｴ', 'ｵ', 'ｶ', 'ｷ', 'ｸ', 'ｹ', 'ｺ',
    'ｻ', 'ｼ', 'ｽ', 'ｾ', 'ｿ', 'ﾀ', 'ﾁ', 'ﾂ', 'ﾃ', 'ﾄ',
    'ﾅ', 'ﾆ', 'ﾇ', 'ﾈ', 'ﾉ', 'ﾊ', 'ﾋ', 'ﾌ', 'ﾍ', 'ﾎ',
    'ﾏ', 'ﾐ', 'ﾑ', 'ﾒ', 'ﾓ', 'ﾔ', 'ｲ', 'ﾕ', 'ｴ', 'ﾖ',
    'ﾗ', 'ﾘ', 'ﾙ', 'ﾚ', 'ﾛ', 'ﾜ', 'ｦ', 'ﾝ',
    'ｶﾞ', 'ｷﾞ', 'ｸﾞ', 'ｹﾞ', 'ｺﾞ', 'ｻﾞ', 'ｼﾞ', 'ｽﾞ', 'ｾﾞ', 'ｿﾞ',
    'ﾀﾞ', 'ﾁﾞ', 'ﾂﾞ', 'ﾃﾞ', 'ﾄﾞ', 'ﾊﾞ', 'ﾋﾞ', 'ﾌﾞ', 'ﾍﾞ', 'ﾎﾞ',
    'ﾊﾟ', 'ﾋﾟ', 'ﾌﾟ', 'ﾍﾟ', 'ﾎﾟ',
    'ｧ', 'ｨ', 'ｩ', 'ｪ', 'ｫ', 'ｬ', 'ｭ', 'ｮ', 'ｯ',
    'ﾞ', 'ﾟ', '､', '｡', '｢', '｣', 'ｰ', '･', ' ',
  ];

  // const dst = src.map(e=>{
  //   const ndx = zen.indexOf(e);
  //   if (ndx === -1) return false;
  //   else retu
  // });
  let dst = '';
  for (let i = 0; i < src.length; i++){
    const e = src[i];
    const ndx = zen.indexOf(e);
    if (ndx > -1){
      dst += han[ndx];
    }
    else {
      dst += e;
    }    
  }
  return dst;
}

// 一ヶ月の日付リストを受け取り7曜式の配列にして返す
export const makeDaysGrid = (dateList) => {
  const monthGrid = [];
  const week = [];
  // stateが整う前に呼び出されたらからの配列を返す
  if (!dateList.length) return(monthGrid);
  dateList.map(e => {
    week.push(e); // これで大丈夫？？？
    if (e.date.getDay() == 6) {
      monthGrid.push(week.concat());
      week.length = 0; // 配列を空にする
    }
  });
  if (week.length) monthGrid.push(week.concat()); // 最後の一週
  // 先頭の一行、配列の長さが7になるまで空白を挿入
  while (monthGrid[0].length < 7) monthGrid[0].unshift('');
  // 最後の一行、配列の長さを7に揃える
  const lastWeek = monthGrid[monthGrid.length - 1];
  while (lastWeek.length < 7) lastWeek.push('');
  return (monthGrid);
}

/// ----------------- randomStr(length, item=0)
/// 2019-09-24 yoshi
/// ランダムな文字列を作成する。
/// item = 0で英数字のみ。ファイル名に使用する
/// item = 1 でパスワード用。見間違いやすい文字を除外して記号を入れる。
/// item = 2 数値オンリー
/// item = 3 パスワード 少し大文字 数字多め
/// 記号が連続するとか分かりづらいので改善するかも。
export const randomStr = (length, item = 0) => {
  // 生成する文字列に含める文字セット
  let filename = "abcdefghijklmnopqrstuvwxyz0123456789";
  let passwd = "abcdefghjkmprstuvyz123456789!#&-";
  let numOnly = "0123456789";
  let passwd2 = "abcdefghjkmprstuvyz123456789123456789123456789!#&-ADWQPRZ";
  let c;
  if (item == 0){
    c = filename;
  }
  else if (item === 1){
    c = passwd;
  }
  else if (item === 2){
    c = numOnly;
  }
  else if (item === 3){
    c = passwd2;
  }
  let cl = c.length;
  let r = "";
  for(let i = 0; i < length; i++){
    r += c[Math.floor(Math.random()*cl)];
  }
  return r;
}

export const makePassWd = (length, ptn=passWdPtn) => {
  let c = "abcdefghjkmprstuvyz123456789123456789123456789!#&-ADWQPRZ";
  let matched = false;
  let cl = c.length;
  let r;
  while (matched === false){
    r = "";
    for(let i = 0; i < length; i++){
      r += c[Math.floor(Math.random()*cl)];
    }
    matched = ptn.test(r);
  }
  return r;
}

//// ------------------------------const convHankaku = (strVal)
/// 半角全角変換
export const convHankaku = (strVal, numMode = false) => {
  if (strVal === undefined) return ('');
  if (numMode)  strVal = strVal.replace(/^−/, '-');  
  strVal = strVal.trim();
  // 半角変換
  var halfVal = strVal.replace(/[！-～]/g,
    function (tmpStr) {
      // 文字コードをシフト
      return String.fromCharCode(tmpStr.charCodeAt(0) - 0xFEE0);
    }
  );

  // 文字コードシフトで対応できない文字の変換
  return halfVal.replace(/”/g, "\"")
    .replace(/’/g, "'")
    .replace(/‘/g, "`")
    .replace(/￥/g, "\\")
    // 数字に続く音引きはハイフンに変換
    // convHankaku('なんとかタワー１２３ー４５')
    // "なんとかタワー123-45"
    .replace(/([0-9]+)ー/g, "$1-")
    .replace(/　/g, " ")
    .replace(/〜/g, "~");
}



export const formatUserList = (res, date, sort=0) =>{
  console.log(date);
  const year = date.split('-')[0];
  const month = date.split('-')[1];
  const days = new Date(year, month, 0).getDate();

  // ユーザーデータに年齢、学齢などを付加する
  const newDt = res.data.dt.map(e => {
    const ages = getAge(e.birthday, date);
    return {
      ...e,
      age: ages.age,
      ageStr: ages.flx,
      ageNdx: ages.schoolAgeNdx,
    }
  });
  newDt.forEach(e=>{
    if (e.volume === '0') e.volume = days - 8;
  })
  // 学齢でソートする
  if (sort === 0){
    newDt.sort((a, b) => {
      if (a.ageNdx < b.ageNdx) return -1;
      if (a.ageNdx > b.ageNdx) return 1;
    });
  }
  // 契約日でソートする
  else if (sort === 1){
    newDt.sort((a, b) => {
      if (a.startDate < b.startDate) return -1;
      if (a.startDate > b.startDate) return 1;
    });
  }
  // indexでソート
  else if (sort === 2){
    newDt.sort((a, b) => (parseInt(a.sindex) - parseInt(b.sindex)))
  }
  return ({ ...res, data: { ...res.data, dt: newDt } });
}

// オブジェクトから特定の値を持つ要素を削除する
export const removeFromObj = (obj, value) => {
  let rt = Object.assign({}, obj);
  const toRemove = Object.entries(obj).map(e => {
    if (e[1] === value) return e[0];
  });
  toRemove.map(e => {
    delete rt[e];
  });
  return rt;
}


// フォームの中のノードの値を取得
// selecters はノードリストの配列
// フォームパーツの名前をキーにしたオブジェクトを返す
// disabledは空白文字 '' を返す
// disabledをtrueにするとdisabledされたフォームパーツも
// 値を返す
// 値が空白の場合はキーを含めて削除する -> spを追加 trueで値空白も残す
export const getFormDatas = (selecters, disabled=false, sp=false) => {
  const rt = {};
  selecters.map(slct => {
    Array.from(slct).map(e => {
      const type = e.getAttribute('type');
      const name = e.getAttribute('name');
      // textareaの場合、name属性がないノードが混ざってくる場合あり
      if (!name)  return false;
      const nodeDisabled = e.getAttribute('disabled');
      let value = (type === 'checkbox') ? e.checked : e.value;
      value = (nodeDisabled !== null && !disabled) ? '' : value;
      // valueのあるチェックボックス処理
      if (type === 'checkbox' && e.value) {
        if (rt[e.value] === undefined)  rt[e.value] = {};
        rt[e.value][e.name] = value;
      }
      else{
        rt[name] = value;
      }
    });
  });
  // spオプションを見て空白を削除してリターン
  return (sp ? rt : removeFromObj(rt, ""));
}
// フォームの中のrequireされているノードで値を持たないノードがないか確認
// 未入力のノードが存在したらその名前の配列を返す
export const checkRequireFilled = (selecters) =>{
  const rt = [];
  selecters.map(slct => {
    Array.from(slct).map(e => {
      const name = e.name;
      const nodeRequired = e.required;
      // 値が入っていないノード名を配列に格納
      if (nodeRequired && !e.value) rt.push(name);
    });
  });
  return (rt);
}


/// const kanaToHira = (str) =>
/// カタカナ→ひらがな
export const convKanaToHira = (str) => {
  if (str === undefined) return ('');
  return str.replace(/[\u30a1-\u30f6]/g, function (match) {
    var chr = match.charCodeAt(0) - 0x60;
    return String.fromCharCode(chr);
  });
}

export const convHiraToKataAndChk = (str) => {
  str = convHiraToKata(str);
  const result = /^[ァ-ヶー　]+$/.test(str);
  return ({result, str});
}

export const convHiraToKata = (str) =>{
  return str.replace(/[\u3041-\u3096]/g, ch =>
    String.fromCharCode(ch.charCodeAt(0) + 0x60)
  );
}

export const convKanaToHiraAndChk = (str) => {
  str = convKanaToHira(str);
  let result;
  if (str.match(/^[ぁ-んー　]*$/)) {
    result = true;
  }
  else {
    result = false;
  }
  return ({ result, str });
}

/// メールアドレスの確認を行う
/// https://kantaro-cgi.com/blog/javascript/javascript_mailaddress_check.html
export const isMailAddress = (mail) => {
  if (!mail)  return true;
  if (/\s/.test(mail))  return false; // 空白文字チェック
  var mail_regex1 = new RegExp('(?:[-!#-\'*+/-9=?A-Z^-~]+\.?(?:\.[-!#-\'*+/-9=?A-Z^-~]+)*|"(?:[!#-\[\]-~]|\\\\[\x09 -~])*")@[-!#-\'*+/-9=?A-Z^-~]+(?:\.[-!#-\'*+/-9=?A-Z^-~]+)*');
  var mail_regex2 = new RegExp('^[^\@]+\@[^\@]+$');
  if (mail.match(mail_regex1) && mail.match(mail_regex2)) {
    // 全角チェック
    if (mail.match(/[^a-zA-Z0-9\!\"\#\$\%\&\'\(\)\=\~\|\-\^\\\@\[\;\:\]\,\.\/\\\<\>\?\_\`\{\+\*\} ]/)) { return false; }
    // 末尾TLDチェック（〜.co,jpなどの末尾ミスチェック用）
    if (!mail.match(/\.[a-z]+$/)) { return false; }
    return true;
  } else {
    return false;
  }
}

/// ------------------------------- const formatTelNum = (telnum) =>{
/// 電話番号のフォーマットを行う
/// 携帯番号、ip電話、フリーダイヤルなどの先頭桁を確認してマッチしたら
/// その桁数に合わせてハイフンを入れる
/// 市外局番は総当たりで確認を行い桁数に合わせたフォーマットを行う
/// {'result':true, 'format':'xxx-xxxx-xxxxx', 'num':'xxxxxxxxxxx'}
/// 市外局番リスト
/// http://www.soumu.go.jp/main_sosiki/joho_tsusin/top/tel_number/shigai_list.html
export const formatTelNum = (telnum) => {
  // 入力されていなかったら何もしない
  if (!telnum) return ({ 'result': true, 'format': '', 'num': '' });
  // ハイフンが記述されていたらそのまま
  if (telnum.indexOf('-') > -1) 
    return ({ 'result': true, 'format': telnum, 'num':telnum });
  // 区切り位置の配列を渡して文字列を区切る
  const splitByPos = (s, pos, sep = '-') => {
    let p = 0;
    let r = '';
    pos.map(e => {
      r += s.substr(p, e);
      r += sep;
      p += e;
    });
    return r.substr(0, r.length - 1);
  }
  // 半角に変換しておく
  telnum = convHankaku(telnum);
  const mp = ['090', '080', '070', '060', '020',];
  const ip = ['050',];
  const fd = ['0120', '0570'];  //フリーダイヤルやナビダイヤルなど
  const shigai = [
    '01267', '01372', '01374', '01377', '01392', '01397', '01398', '01456',
    '01457', '01466', '01547', '01558', '01564', '01586', '01587', '01632',
    '01634', '01635', '01648', '01654', '01655', '01656', '01658', '04992',
    '04994', '04996', '04998', '05769', '05979', '07468', '08387', '08388',
    '08396', '08477', '08512', '08514', '09496', '09802', '09912', '09913',
    '09969', 

    '0123', '0124', '0125', '0126', '0133', '0134', '0135', '0136',
    '0137', '0138', '0139', '0142', '0143', '0144', '0145', '0146', '0152',
    '0153', '0154', '0155', '0156', '0157', '0158', '0162', '0163', '0164',
    '0165', '0166', '0167', '0172', '0173', '0174', '0175', '0176', '0178',
    '0179', '0182', '0183', '0184', '0185', '0186', '0187', '0191', '0192',
    '0193', '0194', '0195', '0197', '0198', '0220', '0223', '0224', '0225',
    '0226', '0228', '0229', '0233', '0234', '0235', '0237', '0238', '0240',
    '0241', '0242', '0243', '0244', '0246', '0247', '0248', '0250', '0254',
    '0255', '0256', '0257', '0258', '0259', '0260', '0261', '0263', '0264',
    '0265', '0266', '0267', '0268', '0269', '0270', '0274', '0276', '0277',
    '0278', '0279', '0280', '0282', '0283', '0284', '0285', '0287', '0288',
    '0289', '0291', '0293', '0294', '0295', '0296', '0297', '0299', '0422',
    '0428', '0436', '0438', '0439', '0460', '0463', '0465', '0466', '0467',
    '0470', '0475', '0476', '0478', '0479', '0480', '0493', '0494', '0495',
    '0531', '0532', '0533', '0536', '0537', '0538', '0539', '0544', '0545',
    '0547', '0548', '0550', '0551', '0553', '0554', '0555', '0556', '0557',
    '0558', '0561', '0562', '0563', '0564', '0565', '0566', '0567', '0568',
    '0569', '0572', '0573', '0574', '0575', '0576', '0577', '0578', '0581',
    '0584', '0585', '0586', '0587', '0594', '0595', '0596', '0597', '0598',
    '0599', '0721', '0725', '0735', '0736', '0737', '0738', '0739', '0740',
    '0742', '0743', '0744', '0745', '0746', '0747', '0748', '0749', '0761',
    '0763', '0765', '0766', '0767', '0768', '0770', '0771', '0772', '0773',
    '0774', '0776', '0778', '0779', '0790', '0791', '0794', '0795', '0796',
    '0797', '0798', '0799', '0820', '0823', '0824', '0826', '0827', '0829',
    '0833', '0834', '0835', '0836', '0837', '0838', '0845', '0846', '0847',
    '0848', '0852', '0853', '0854', '0855', '0856', '0857', '0858', '0859',
    '0863', '0865', '0866', '0867', '0868', '0869', '0875', '0877', '0879',
    '0880', '0883', '0884', '0885', '0887', '0889', '0892', '0893', '0894',
    '0895', '0896', '0897', '0898', '0920', '0930', '0940', '0942', '0943',
    '0944', '0946', '0947', '0948', '0949', '0950', '0952', '0954', '0955',
    '0956', '0957', '0959', '0964', '0965', '0966', '0967', '0968', '0969',
    '0972', '0973', '0974', '0977', '0978', '0979', '0980', '0982', '0983',
    '0984', '0985', '0986', '0987', '0993', '0994', '0995', '0996', '0997',

    '011', '015', '017', '018', '019', '022', '023', '024', '025', '026',
    '027', '028', '029', '042', '043', '044', '045', '046', '047', '048',
    '049', '052', '053', '054', '055', '058', '059', '072', '073', '075',
    '076', '077', '078', '079', '082', '083', '084', '086', '087', '088',
    '089', '092', '093', '095', '096', '097', '098', '099', 
    '03', '04', '06',
  ];

  // ハイフンとカッコを削除
  let num = telnum.replace(/[\(\)\-\s]/g, '');

  // 数値以外が入っていたら弾く
  if (isNaN(num)) {
    return ({ result: false, format: '', num: '', err: 1 });
  }

  // 桁数確認 11桁と10桁以外は弾く
  let len = num.length
  if (len < 10 || len > 11) {
    return ({ result: false, format: '', num: num, err: 2 });
  }

  // 先頭0以外は弾く
  if (num.substr(0, 1) != '0') {
    return ({ result: false, format: '', num: num, err: 3 });

  }
  let match = false;
  // すでにハイフンが2つはいっていたら整形済みと解釈
  let hCnt = (telnum.match(/\-/g) || []).length;
  if (hCnt == 2) {
    return ({ result: true, format: telnum, num: num, match: match });
  }

  // 携帯番号とのマッチを確認
  let frm = '';
  let r = false;
  if (mp.indexOf(num.substr(0, 3)) > -1) match = true;
  if (match) {
    // frm = num.substr(0, 3) + '-' + num.substr(3, 4) + '-' + num.substr(7, 4);
    frm = splitByPos(num, [3, 4, 4]);
    if (num.length == 11) r = true;
    return ({ result: r, format: frm, num: num, match: match });
  }
  // ip電話とのマッチを確認
  if (ip.indexOf(num.substr(0, 3)) > -1) match = true;
  if (match) {
    if (len == 10) {
      frm = splitByPos(num, [3, 3, 4]);
    }
    else {
      frm = splitByPos(num, [3, 4, 4]);
    }
    return ({ result: true, format: frm, num: num, match: match });
  }
  // フリーダイヤルなど
  if (fd.indexOf(num.substr(0, 3)) > 1) match = true;
  if (match) {
    frm = splitByPos(num, [4, 3, 3]);
    if (num.length == 10) r = true;
    return ({ result: r, format: frm, num: num, match: match });
  }
  // 市外局番とのマッチを確認
  const shigaiNum = shigai.find(e=>num.indexOf(e) === 0);
  let slen;
  if (shigaiNum){
    match = true;
    slen = shigaiNum.length;
  }
  if (match) {
    frm = splitByPos(num, [slen, 6 - slen, 4]);
    if (num.length == 10) r = true;
    return ({ result: r, format: frm, num: num, match: match });
  }
  // 見つからない場合 3桁市外局番として解釈
  if (num.length == 10) r = true;
  return ({ result: r, format: frm, num: num, match: match });
}

// uriを解釈する
// href 全体 
// body パラメータ部分を含まない 
// prms パラメータ部部のみ 
// detail パラメータオブジェクト化
export const locationPrams = () => {
  const href = window.location.href;
  const body = href.split('?')[0];
  const prms = (href.split('?')[1])? href.split('?')[1]: null;
  const detail = {};
  const ary = (!prms)? []:
    (prms.split('&'))? prms.split('&'): [];
  ary.map(e=>{
    detail[e.split('=')[0]] = (e.split('=')[1])? e.split('=')[1]: '';
  });
  return {href, body, prms, detail};
}
// 配列の比較を行う 差分の配列を返す。
// 比較対象の配列の順序は問わない
export const compareArrays = (a1, a2) => {
  const diff1 = a1.filter(e=>a2.indexOf(e) == -1);
  const diff2 = a2.filter(e=>a1.indexOf(e) == -1);
  return [...diff1, ...diff2];
  
}
// イベントから諸々取得する
export const getInputInfo = (e) => {
  const target = e.currentTarget;
  const name = target.name;
  const value = target.value;
  const checked = target.checked;
  return {target, name, value, checked};
}

// オブジェクトの型を返す
// undefined	-> "undefined" ,null	-> "null"
// true / false	-> "boolean", ['array']	-> "array"
// {key: 'value'}	-> "object" ,NaN	-> "NaN" ,1234	-> "number"
// new Number()	-> "number" ,'abcd'	-> "string"
// new String()	-> "string",function () {}	-> "function"
// () => {}	-> "function",function* () {}	-> "generatorfunction"
// async function () {}	-> "asyncfunction",new Promise(() => {})	-> "promise"
// Symbol()	-> "symbol",new Map()	-> "map"
// https://qiita.com/amamamaou/items/ef0b797156b324bb4ef3
export const typeOf = (v) => {
  const toString = Object.prototype.toString;
  let rtn = toString.call(v).slice(8, -1).toLowerCase();
  rtn = (rtn === 'number' && isNaN(v))? 'NaN': rtn;
  return rtn
}
// パスワードチェック
// 判定結果とメッセージを返す
export const chkPasword = (passWd, minLen=8, maxLen=20) => {
  if (passWd.length <= minLen || passWd.length >= maxLen){
    return {result: false, msg: `${minLen}文字以上${maxLen}文字以内でお願いします。`}
  }
  if (!passWd.match(/^[\x20-\x7E]+$/)){
    return {result: false, msg: '漢字、ひらがな、カタカナなどは使えません。'}
  }
  if (passWd.match(/\s/)){
    return {result: false, msg: 'スペースなどの空白文字を含むことは出来ません。'}
  }
  // if (!passWd.match(/^(?=.*?[a-z])(?=.*?\d)(?=.*?[!-\/:-@[-`{-~])[!-~]*$/i){
  //   return {result: false, msg: 'パスワードにはアルファベットと数字と記号を含む必要があります。'}
  // }
  // https://qiita.com/mpyw/items/886218e7b418dfed254b
  
  if (!passWd.match(passWdPtn)){
    return {result: false, msg: 'アルファベット大文字小文字と数字と記号を含む必要があります。'}
  }
  // https://qiita.com/tmasu/items/bf47383dec9c30e6cc74
  return {result:true, msg:'安全なパスワードです。'};

}



// stateのコントロールモードにSchedule最終更新時刻をセットする
// 更新時刻はgetTime()で記録する
// useDispatchを受け取る ボツ予定
export const setScheduleLUPDATE = (dispatch)=>{
  const p = {scheduleLUPDATE: new Date().getTime()};
  dispatch(Actions.setControleMode(p));
}
// こっちは最終保存 ボツ予定
export const setScheduleLSAVED = (dispatch) => {
  const p = { scheduleLSAVED: new Date().getTime() };
  dispatch(Actions.setControleMode(p));
}
// Schedule保存予約
// 予約を行ったときのpathも記録する
export const setSchedleLastUpdate = (dispatch, path) =>{
  const p = { 
    scheduleLastUpdate: Math.floor(new Date().getTime() / 1000) ,
    scheduleLastUpdatePath: path,
    saved : false,
  };
  dispatch(Actions.setControleMode(p));
}
// 保存済みフラグを立てる
export const setScheduleSaved = (dispatch) =>{
  const p = {
    saved: true,
  };
  dispatch(Actions.setControleMode(p));
}

// SchEditDetailDialogのオープン
export const setOpenSchEditDetailDialog = (dispatch, prms) =>{
  // const {open, uid, did, ...other} = prms;
  const p = { openSchEditDetailDialog: prms }
  dispatch(Actions.setControleMode(p));
}

// uidの処理を行う
// str形式で来てもnum形式で来てもstrとnumを返す
// 例 
// 8 => {str:'UID8', num: 8}
// UID8 => {str:'UID8', num: 8}
// HOGE8 => {str:null, num: null}
// UID => {str:null, num: null}
export const convUID = (v) => {
  let num;
  let err = false;
  if (!v) return { str: null, num: null };
  if (isNaN(v) && v.indexOf('UID') == 0){
    num = v.replace(/[^0-9]/g, '');
    if (num)  num = parseInt(num);
    else err = true;
  }
  else{
    if (!isNaN(v)) num = parseInt(v);
    else err = true;
  }
  if (!err) return { str: 'UID' + num, num };
  else return { str: null, num:null };
}

// オブジェクトを配列にする キーは無視される
export const objToArrayIgnoreKey = (obj) => {
  if (!null || !undefined) return [];
  const r = Object.keys(obj).map(e => obj[e]);
  return r;
}
// オブジェクトを配列にする キーはオブジェクトに組み込まれる
export const objToArrayWithKey = (obj) => {
  if (!null || !undefined) return [];
  const r = Object.keys(obj).map(e => ({...obj[e], key:e}));
  return r;
}
// スケジュールを送信するための準備を行い送信を実行する
// 送信実行をおこなったフラグセットを行う
// 送信実行後、スナックバーを表示する->dispatchのcallbackで実行される
// 編集用のダイアログなどが開かれている状態では送信をキャンセルする
// スケジュールデータの日付の整合性をここで確認する
// actionの方で確認の実施をしようとしたがあまり機能を追加するのは好ましくない
// アクションによるディスパッチはここでしか呼び出して いないはずなので
// ここでチェックを実施することにする
// 外から呼び出しを行っている有効なコードはないか確認する必要がある
export const  callDisptchForSendSchedule = (params) =>{
  const {dateList, stdDate, schedule, hid, bid, dispatch} = params;
  let dateChk = true;
  // dDateからDYYYYMMの文字列を作成
  const strStdDate = 'D' + stdDate.split('-')[0] + stdDate.split('-')[1];
  // チェックの実施
  Object.keys(schedule).filter(e=>e.indexOf('U') === 0).map(e=>{
    Object.keys(schedule[e]).filter(f=>f.indexOf('D2') === 0).map(f=>{
      if (f.indexOf(strStdDate) !== 0)  dateChk = false;
    });
  });
  if (!dateChk){
    dispatch(Actions.setSnackMsg(
      '日付エラーが発生しました。保存をキャンセルします。','error'
    ));
    setScheduleSaved(dispatch);
    return false;
  }
  
  // 日付オブジェクトをjsonにすると時差で日付がずれる！
  // ので一旦文字列に変換
  const newList = dateList.map(e => {
    return ({
      date: formatDate(e.date, 'YYYY-MM-DD'),
      holiday: e.holiday,
    });
  });
  let prms = {
    hid, bid, date: stdDate,
    dateList: JSON.stringify(newList),
    a: 'sendCalender',
  };
  dispatch(Actions.sendCalender(prms));
  prms = {
    hid, bid, date: stdDate,
    schedule: JSON.stringify(schedule),
    a: 'sendSchedule',
  }
  dispatch(Actions.sendSchedule(prms));
  setScheduleSaved(dispatch);
}

// 定員から日毎の利用上限を求める
export const upperLimitOfUseByDay = (teiin) =>{
  if (teiin <= 50){
    return Math.floor(teiin * 1.5);
  }
  else{
    return Math.floor(teiin + ((teiin - 50) * .25) + 25);
  }
}

// サービス名の末尾の中黒を削除する
// サービス名とかでついている末尾の中黒を想定
export const deleteLast = (s, l) => {
  if (s.slice(-1) === l) {
    return s.slice(0, -1);
  }
  else {
    return s;
  }
}

// 全てのstateを受け取ってローディング状態を返す
export const getLodingStatus = (allstate) => {
  const sessionDone = allstate.sessionStatus.done;
  const scheduleDone = allstate.fetchSchedule.done;
  const calenderDone = allstate.fetchCalenderStatus.done;
  const userDone = allstate.userFtc.done;
  const comDone = allstate.comFtc.done;
  const sessionErr = allstate.sessionStatus.err;
  const scheduleErr = allstate.fetchSchedule.err;
  const calenderErr = allstate.fetchCalenderStatus.err;
  const userErr = allstate.userFtc.err;
  const comErr = allstate.comFtc.err;
  const serviceItemsInit = allstate.serviceItemsInit;
  // const comErr = true; // エラーワザと出す用
  const loaded = (
    sessionDone && scheduleDone && calenderDone && userDone && comDone &&
    serviceItemsInit
  );
  const error = (
    sessionErr || scheduleErr || calenderErr || userErr || comErr
  );
  return { loaded, error, detail:{
      sessionDone, scheduleDone, calenderDone, userDone, comDone,
      sessionErr, scheduleErr, calenderErr, userErr, comErr,
      serviceItemsInit,
    }
  };

}
// スケジュールを受け取ってuidとdidのセットを返す
export const setOfUidDid = (schedule, UID='') => {
  if (UID)  UID = convUID(UID).str;
  const didptn = /^D2[0-9]+/; // D20xxmmddを検索するためのパターン
  const uidptn = /^UID[0-9]/;

  // uidが指定されたときは単純にdidの配列を返す
  if (UID)
    return Object.keys(schedule[UID]).filter(e=>e.match(didptn));
  // uidが指定されていないときは[[uid, did]...]の配列を返す
  else{
    let rtn = []
    Object.keys(schedule).filter(e=>e.match(uidptn)).map(e=>{
      if (!schedule[e])  return false;
      Object.keys(schedule[e]).filter(f=>f.match(didptn)).map(f=>{
        rtn.push([e, f])
      });
    });
    return (rtn);
  }
}
// スナックバー表示やオートセーブを制御するために
// フォームなどが開いていないかどうか確認する
export const isEditElementOpen = () =>{
  const formOpen = document.querySelectorAll('form.dialogForm').length;
  let dialogOpen =
    document.querySelectorAll('.MuiDialog-container .dialogTitle').length;
  dialogOpen +=
    document.querySelectorAll('.MuiDialog-container .MuiDialogTitle-root').length;
  const drowerOpne =
    document.querySelectorAll('.MuiDrawer-root .drowerMenu').length;
  const printPreview = 
    Array.from(document.querySelectorAll('.AppPage.reports .printPreview'))
    .filter(e=>e.style.display !== 'none').length;
  
  return (formOpen + dialogOpen + drowerOpne + printPreview);
}

// users etcに複数のデータ書き込みを行う
// 本来はアクションで行うべき処理だが実験的にここに置いてみる
// $hid = PRMS('hid');
// $bid = PRMS('bid');
// $json = PRMS('etcs');
// etcsは[{uid:xxx,etc:{...}}]の配列
// 引数でdispatchを受け取る。dispatchが関数であるかどうか判定し関数なら実施
// 関数でない場合は実行しない
// dispathcではコントロールモードに記録を残す
// またDispatch型のスナックバーを表示する
// dispatch型のMSGが固定になっとる…
export const sendUserEtcMulti = async (params, setResponse, dispatch = null)=>{
  let response;
  const dispathValue = (v) => ({controleMode:{registedParamsOfficesSend: v}})
  try{
    params.a = 'sendUserEtcMulti';
    // 配列の中身をJSON化してみる
    const encoded = params.etcs.map(e=>{
      return({uid:e.uid, etc:JSON.stringify(e.etc)});
    })
    params.etcs = JSON.stringify(encoded); // 配列なので文字列化 二重エンコード！
    response = await axios.post(endPoint(), uPrms(params));
    if (response.status !== 200)  throw response;
    if (!response.data) throw response;
    if (typeof setResponse === 'function'){
      setResponse(response);
    }
    if ((typeof dispatch) === 'function'){
      dispatch(Actions.setStore(dispathValue('done')));
      dispatch(Actions.setSnackMsg('他事業所の登録情報を書き込みました。', ''));
    }
  }
  catch(e){
    console.log(e);
    response.data = false;
    if (typeof setResponse === 'function'){
      setResponse(response);
    }
    if ((typeof dispatch) === 'function'){
      dispatch(Actions.setStore(dispathValue('error')))
      dispatch(
        Actions.setSnackMsg('他事業所の登録情報の書き込みエラーです。', 'error')
      );
    }
  }
}
export const sendUsersCity = async (params, setResponse, dispatch) => {
  let response;
  // $hid = PRMS('hid');
  // $bid = PRMS('bid');
  // $scity = PRMS('scity');
  // $scity_no = PRMS('scity_no');

  const controleDispath = (v) => ({controleMode:{sendUsersCity: v}})
  try {
    params.a = 'replaceUsersCity';
    response = await axios.post(endPoint(), uPrms(params))
    if (response.status !== 200)  throw response;
    if (!response.data) throw response;
    setResponse(response);
    if ((typeof dispatch) === 'function'){
      dispatch(Actions.setStore(controleDispath('done')));
      dispatch(Actions.setSnackMsg('市区町村の情報を書き込みました。', ''));
    }
  }
  catch(e){
    console.log(e);
    setResponse(response);
    if ((typeof dispatch) === 'function'){
      dispatch(Actions.setStore(controleDispath({params, response})));
      dispatch(Actions.setSnackMsg('市区町村情報の書き込みエラーです。', 'error'));
    }
  }
}

// 吉村 幸博 -> ●村 ●博
export const getHiddenName = (name, mask = true) => {
  if (!name) return name;
  const name1 = name.split(' ')[0];
  const name2 = name.split(' ')[1];
  if (!name2) return name; // 分割できないときはそのまま返す
  const c = '●';
  let masked1 = c.repeat(name1.length - 1) + name1.slice(-1);
  let masked2 = c.repeat(name2.length - 1) + name2.slice(-1);
  masked1 = (masked1.length === 1)? c: masked1;
  masked2 = (masked2.length === 1)? c: masked2;
  if (mask) return masked1 + ' ' + masked2;
  // 引数maskがfalseならなにもしない
  else return name;
}

// 兄弟を取得する
// 指定されたuid以外のユーザーを配列にして返す
// self はコールしたユーザー自身を含めるか。trueで含める
export const getBrothers = (uid, users, self=false) => {
  const thisUser = getUser(uid, users);
  if (parseInt(thisUser.brosIndex) === 0) return []; // 兄弟設定されていない
  if (!thisUser)  return [];
  if (!Object.keys(thisUser))  return [];
  const pphone = thisUser.pphone;
  const pname = thisUser.pname;
  const brosIndex = thisUser.brosIndex;
  const bros = users.filter(e=>
    e.pphone === pphone &&
    e.pname === pname &&
    parseInt(e.brosIndex) > 0 &&
    (e.brosIndex !== brosIndex || self)
  );
  return bros;
}
// 長兄を取得する
// 自分自身が長兄なら自分のUIDを返す
export const getFirstBros = (uid, users) => {
  const thisUser = getUser(uid, users);
  if (!thisUser)  return false;
  if (parseInt(thisUser.brosIndex) === 0) return false;
  if (parseInt(thisUser.brosIndex) === 1) return uid;
  const bros = getBrothers(uid, users);
  const firstBros = bros.find(
    e=>parseInt(getUser(e.uid, users).brosIndex) === 1
  );
  if (firstBros) return 'UID' + firstBros.uid;
  else return false;

}

// スケジュールオブジェクトを受け取って色々返す
// serviceを受け取ってそのサービスだけの情報も返せるようにする
export const getScheduleInfo = (sch, svc, users, classroom='') => {
  // 利用されているuidの配列
  const tmpUids = Object.keys(sch).filter(e=>e.indexOf('UID') === 0);
  const uids = tmpUids.map(e=>{
    const uSvc = getUser(e, users).service;
    const svcChk = (svc === uSvc || !svc); // サービスを無視したカウント 全サービス用
    // MTU対応
    const thisUser = getUser(e, users);
    const classroomChk = isClassroom(thisUser, classroom);
    if (svcChk && classroomChk)  return e;
  }).filter(e=>e);
  // 利用されているdidの配列
  const didSet = new Set();
  uids.map(e=>{
    if (!sch[e])  return false;
    Object.keys(sch[e]).filter(f=>f.indexOf('D2' === 0)).map(f=>{
      didSet.add(f);
    });    
  });
  const dids = Array.from(didSet);
  // uidごとのカウント
  const uidCounts = {};
  uids.map(e=>{
    if (!sch[e])  return false;
    const count = Object.keys(sch[e]).filter(f=>f.indexOf('D2' === 0)).length;
    let absenceCnt = 0;
    let weekDayCnt = 0;
    let schoolOffCnt = 0;
    let useResultCnt = 0;
    let kessekiAdicCnt = 0;
    Object.keys(sch[e]).filter(f=>f.indexOf('D2' === 0)).map(f=>{
      const absence = sch[e][f].absence;
      const o = sch[e][f];
      if (classroom && o.classroom && o.classroom !== classroom) return false;
      if (absence){
        absenceCnt++;
        // return false;
      }
      if (parseInt(o.offSchool) === 0 && !absence)  weekDayCnt++; 
      if (parseInt(o.offSchool) === 1 && !absence)  schoolOffCnt++;
      if (o.useResult === true && !absence)  useResultCnt++;
      if (findDeepPath(o, 'dAddiction.欠席時対応加算')){
        kessekiAdicCnt++;
      }
    });
    uidCounts[e] = {
      absenceCnt, weekDayCnt, schoolOffCnt, count: (weekDayCnt + schoolOffCnt),
      kessekiAdicCnt, useResultCnt,
    };
  });
  // didごとのカウント
  const didCounts = {}
  dids.map(e=>(
    didCounts[e]= {
      absenceCnt: 0, weekDayCnt: 0, schoolOffCnt: 0, useResultCnt: 0,
      kessekiAdicCnt: 0,
    }
  ));
  uids.map(e=>{
    if (!sch[e])  return false;
    Object.keys(sch[e]).filter(f=>f.indexOf('D2' === 0)).map(f=>{
      const absence = sch[e][f].absence;
      const o = sch[e][f];
      if (classroom && o.classroom && o.classroom !== classroom) return false;
      if (svc && o.service !== svc) return false;
      if (absence) didCounts[f].absenceCnt++;
      if (o.offSchool === 0  && !absence)  didCounts[f].weekDayCnt++; 
      if (o.offSchool === 1 && !absence)  didCounts[f].schoolOffCnt++; 
      if (o.useResult === true && !absence)  didCounts[f].useResultCnt++;
      if (findDeepPath(o, 'dAddiction.欠席時対応加算')){
        didCounts[f].kessekiAdicCnt++;
      }
    });
  });
  return {uids, dids, uidCounts, didCounts};
}
// ユーザーごとのScheduleオブジェクトを受け取り利用数を返す
export const getRiyouCountOfUser = (sch) => {
  const rt = {riyou:0, kessekiKasan:0, kesseki:0};
  if (!sch) return rt;
  Object.keys(sch).filter(e=>e.indexOf('D2') === 0).map(e=>{
    let kessekiKasa = false;
    if (sch[e].dAddiction){
      Object.keys(sch[e].dAddiction).map(f=>{
        if (f.indexOf('欠席時') > -1) kessekiKasa = true;
      });  
    }

    if      (sch[e].absence && kessekiKasa)   rt.kessekiKasan++;
    else if (sch[e].absence && !kessekiKasa)  rt.kesseki++;
    else                                      rt.riyou++;

  });
  return rt;
};

// usersに記載されている加算情報をScheduleに転機する。
// Schedule側にすでに情報がある場合は処理を行わない。
// 送信予約は行わないこととする -> 行う？
export const addUsersAddictionToSch = (prms) => {
  const {schedule, users, dispatch } = prms;
  const sch = {...schedule};
  let dispatchFlg = false;
  const tusers = [...users];
  tusers.map(e=>{
    const UID = 'UID' + e.uid;
    let src = findDeepPath(e, 'etc.addiction');
    const dsc = findDeepPath(sch, [e.service, UID, 'addiction']);
    src = !src? {}: src;
    // scheduleに記載が無い場合
    if (!dsc){
      dispatchFlg = true;
      if (!sch[e.service])        sch[e.service] = {};
      if (!sch[e.service][UID])   sch[e.service][UID] = {};
      sch[e.service][UID].addiction = src;
    }
  });
  if (dispatchFlg){
    dispatch(Actions.setStore({schedule: sch}));
    // setSchedleLastUpdate(dispatch, '');
    prms.schedule = sch; // 追加。送信するスケジュールに更新が反映されていなかった。
    callDisptchForSendSchedule(prms);
  }
}
// パーミッションは 80,0,0-0,0のような文字列を想定。二次元配列に変換する
// 1000以下の数値は数値にする
// 現在の項目仕様 999-サービス,単位
export const parsePermission = (account) => {
  if (!account) return [[0],[0]];
  const t = account.permission.split('-');
  const u = t.map(e=>(e.split(',')));
  u.map((e, i)=>{
    e.map((f, j)=>{
      if (!isNaN(f)){
        if (parseInt(f) < 1000) u[i][j] = parseInt(f);
      }
    });
  });
  if (u.length < 2) u.push([0]);
  return u;
}

// パーミッションの名前を取得する
export const getPermissionName = (account) => {
  if (!account) return '【権限未設定】';
  const permission = parsePermission(account)[0][0];
  const pmsItem = PERMISSION_NAMES.find(e=>e.value <= permission);
  if (pmsItem)  return pmsItem.name;
  else return '【権限未設定】';
}

export const asyncSleep = async (s) => {
  const f = () => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(true);
      }, 1000 * s);
    });
  }
  return await f();
}

export const toClipboard = (text, setSnack, msg='') => {
  navigator.clipboard.writeText(text)
  .then(function() {
    console.log('Async: Copying to clipboard was successful!');
    if ((typeof setSnack) === 'function'){
      if (!msg){
        setSnack({msg:'コピーしました。', severity: ''});
      }
      else{
        setSnack({msg, severity: ''});

      }
    }
  }, function(err) {
    console.error('Async: Could not copy text: ', err);
  });
}