import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import {setBillInfoToSch} from '../Billing/blMakeData';
import * as comMod from '../../commonModule';
import * as Actions from '../../Actions';

import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export const convStrToZenspace = (str, triger=false) => {
    let result = "";
    if(triger){
        for(let i=0; i<str.length; i++){
            result += "　";
        }
    }else{
        result = str;
    }

    return result;
}


export const InvoiceOpt = () => {
    const dispatch = useDispatch();
    const allstate = useSelector(state=>state);
    const {com, hid, bid, stdDate} = allstate;

    const comEtc = com.etc ?com.etc :{};
    const display_date = comMod.findDeepPath(
      comEtc, 'configReports.invoice.displayDate', 
    )
    const display_notice = comMod.findDeepPath(
      comEtc, 'configReports.invoice.displayNotice', 
    )
  
    const [displayDate, setDisplayDate] = useState(display_date!==undefined ?display_date :true);
    const [displayNotice, setDisolayNotice] = useState(display_notice!==undefined ?display_notice :true);
    // const [displayDate, setDisplayDate] = useState(display_date!==null ?display_date :true);
    // const [displayNotice, setDisolayNotice] = useState(display_notice!==null ?display_notice :true);

    const DisplayDate = () => {
        const handleChange = (ev) => {
            setDisplayDate(ev.target.checked);
        }
    
        return(
            <FormControlLabel
                control={
                    <Checkbox
                    checked={(displayDate)}
                    onChange={(ev)=>handleChange(ev)}
                    name='displayDate'
                    color="primary"
                    />
                }
                label='日付表示'
            />
        )
    }
    
    const DisplayNotice = () => {
        const handleChange = (ev) => {
            setDisolayNotice(ev.target.checked);
        }
    
        return(
            <FormControlLabel
                control={
                    <Checkbox
                    checked={(displayNotice)}
                    onChange={(ev)=>handleChange(ev)}
                    name='displayNotice'
                    color="primary"
                    />
                }
                label='備考表示'
            />
        )
    }
    
    const DisplayOptButton = () => { 
        const comEtc = com.etc ?com.etc :{};
        if(!comEtc.configReports) comEtc["configReports"] = {};
        if(!comEtc.configReports.invoice) comEtc.configReports["invoice"] = {};
        const handleClick = () => {
            comEtc.configReports.invoice["displayDate"] = displayDate;
            comEtc.configReports.invoice["displayNotice"] = displayNotice;
            const sendPrms = {
                ...com, hid, bid, date: stdDate,
                addiction: com.addiction,
                etc: comEtc,
            };
            dispatch(Actions.sendBrunch(sendPrms));
        }
    
        return(
            <Button
                variant='contained'
                name='帳票オプション書き込み'
                onClick={()=>handleClick()}
            >
                登録
            </Button>
        )
    }

    return(
        <div className='reportCntRow'  style={{"justify-content": "end", "padding-top": "0px", "margin-bottom": "10px"}}>
            <div style={{"padding": "0px 8px 8px 8px"}}>
                <DisplayDate />
                <DisplayNotice />
                <DisplayOptButton />
            </div>
        </div>
    )
}