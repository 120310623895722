import React, {useState, useEffect,useRef, } from 'react';
import * as Actions from '../../Actions';
import { useSelector, useDispatch } from 'react-redux';
// import SchHeadNav from './SchHeadNav';
import SchEditDetailDialog from './SchEditDetailDialog';
// import SchTableHead from './SchTableHead';
import * as comMod from '../../commonModule';
import * as albcm from '../../albCommonModule'
import * as mui from '../common/materialUi';
import {LoadingSpinner, LoadErr} from '../common/commonParts';
// import SimpleModal from '../common/modal.sample';
// import SchDailyDialog from './SchDailyDialog';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
// import { common } from '@material-ui/core/colors';
// import AccessTimeIcon from '@material-ui/icons/AccessTime';
// import DriveEtaIcon from '@material-ui/icons/DriveEta';
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
// import AddCircleIcon from '@material-ui/icons/AddCircle';
import EachScheduleContent from './SchEachScheduleContent';
// import SchSaveLater from './SchSaveLater';
import { menu } from './Sch2';
import { LinksTab } from '../common/commonParts';
import { useLocation, useParams, } from 'react-router-dom';
import SnackMsg from '../common/SnackMsg';
import { OccupancyRate } from './SchHeadNav';
import {SetUsersSchViewCookies} from '../common/StdFormParts'
import { grey, orange, teal, blue, red } from '@material-ui/core/colors';
import { faLess } from '@fortawesome/free-brands-svg-icons';
import { faSleigh } from '@fortawesome/free-solid-svg-icons';
import { Satellite } from '@material-ui/icons';

const useStyles = makeStyles({
  userInfo:{
    display:'flex', justifyContent:'center',
    paddingTop: 24, paddingBottom: 15,
    '& >div': {margin: '0 8px',}
  },
  occuWrap:{marginTop: 20,},
  vDayWrap: {
    cursor:'pointer',
    // '&:nth-of-type(odd) .dateContent':{background: grey[100]},
    '& .mwfClass .dateContent': {background: grey[100]},
    '& .schoolOff .dateContent': {background: '#fff2e0'},
    '& .schoolOff.mwfClass .dateContent': {background: '#ffeaca'},
    '& .off .dateContent': {background: grey[300]},
    '&:hover .dateContent': {background: teal[50]},
    '&:hover .schoolOff.mwfClass .dateContent': {background: teal[50]},
  },
  countRoot: {
    display:'flex', alignItems: 'end', position: 'fixed',
    top: 108, right: 8, background: '#FFFFFF90', zIndex: 999,
    padding: 8,
    '& > div': {paddingLeft: 4, paddingRight: 4},
    '& .count': {fontSize: '1.1rem', color: teal[900], fontWeight: 600},
    '& .absence': {color: blue[800]},
    '& .s': {fontSize: '.7rem', paddingBottom: 1},
    '& .over': {color: red[800]},
  },

});

// const UserSlect2 = (props)=>{
//   const {puid, suid, setSuid} = props;
//   const usersOrg = useSelector(state => state.users);
//   const service = useSelector(state => state.service);
//   const classroom = useSelector(state => state.classroom);

//   // パラメータuidが指定されていたらnullを返す
//   if (puid) return null;

//   // サービス内容でユーザーリストを絞り込み
//   const users = usersOrg.filter(e=>(
//     e.service === service &&
//     (classroom === '' || e.classroom === classroom)
//   ));
//   // suidが未指定なら先頭ユーザーに設定
//   if (!suid){
//     setSuid(users[0].uid);
//   };

//   // セレクトのオプションを作成
//   const Options = ()=>{
//     const opt = users.map((e, i)=>{
//       return(
//         <option key={i} value={e.uid}>{e.name + ' ' + e.ageStr}</option>
//       )
//     });
//     return opt;
//   }
//   const selectClass = {
//     // margin: theme.spacing(1),
//     minWidth: 130,
//     marginTop: -46,
//   }
//   const handleChange = (e) =>{
//     const val = e.currentTarget.value; 
//     setSuid(val);
//   }

//   return(<>
//     <FormControl style={selectClass}>
//       <InputLabel >{props.name}</InputLabel>
//       <Select
//         native
//         value={suid}
//         name={'ご利用者選択'}
//         onChange={(e) => handleChange(e)}
//       >
//         <Options />
//       </Select>
//     </FormControl>
//   </>)
// }


// ローカルのステイトでコントロールしていたがstoreにuidを書き込むようにする
// やっぱりLOCALstateへ
const UserSlect = (props)=>{
  const {puid, suid, setSuid} = props;
  const usersOrg = useSelector(state => state.users);
  const service = useSelector(state => state.service);
  const classroom = useSelector(state => state.classroom);

  // パラメータuidが指定されていたらnullを返す
  if (puid) return null;

  // サービス内容でユーザーリストを絞り込み
  const users = usersOrg.filter(e=>(
    e.service === service &&
    (albcm.isClassroom(e, classroom))
  ));
  // suidが未指定なら先頭ユーザーに設定
  if (!suid){
    setSuid(users[0].uid);
  };

  // セレクトのオプションを作成
  const Options = ()=>{
    const opt = users.map((e, i)=>{
      return(
        <option key={i} value={e.uid}>{e.name + ' ' + e.ageStr}</option>
      )
    });
    return opt;
  }
  const selectClass = {
    minWidth: 120,
    marginTop: -46,
  }
  const handleChange = (e) =>{
    const val = e.currentTarget.value; 
    setSuid(val);
  }

  return(<>
    <FormControl style={selectClass}>
      <InputLabel >{props.name}</InputLabel>
      <Select
        native
        value={suid}
        name={'ご利用者選択'}
        onChange={(e) => handleChange(e)}
      >
        <Options />
      </Select>
    </FormControl>
  </>)
}

// 2021/09/03 縦型表示を追加してみる
const SevenDaysGrid = (props)=>{
  const dispatch = useDispatch()
  const dateList = useSelector(state=>state.dateList);
  const {
    suid, sch, setSch, dialogOpen, setDialogOpen, setSnack, localFabSch,
    virtical,
  } = props;
  const path = useLocation().pathname;
  const template = useSelector(state => state.scheduleTemplate);
  const users = useSelector(state => state.users);
  const service = useSelector(state=>state.service);
  const classroom = useSelector(state=>state.classroom);
  const schedule = useSelector(state=>state.schedule);

  // 7曜グリッド作成
  const daysGrid = comMod.makeDaysGrid(dateList);
  // クリックハンドラ
  const clickHandler = (e)=>{
    e.stopPropagation();
    e.preventDefault();

    const did = e.currentTarget.getAttribute('did');
    const UID = 'UID' + suid;
    const date = comMod.convDid(did); // did形式を日付オブジェクトに
    // 引数で受け取ったdatalist全体から該当日付の休日モードを取得
    const holiday = dateList.filter(
      f => f.date.getTime() === date.getTime()
    )[0].holiday;
    const holidayStr = ['weekday', 'schoolOff', 'schoolOff'][holiday];
    // 同じようにserviceを取得
    const thisService = users.filter(f => f.uid === suid)[0].service;
    // 該当スケジュールの取得。見つからなければnull
    let thisSchedule = sch[did];
    
    const thisUser = comMod.getUser(suid, users);
    // MTUの規制
    if (!classroom && albcm.classroomCount(thisUser) > 1){
      const id = new Date().getTime();
      setSnack({
        msg: 'この利用者は複数単位があるので編集できません。', 
        severity: 'warning', id
      });
      return false;
    }
    // なにもしない
    if (localFabSch === 0)  return false;
    
    // スケジュールロックを検出
    const locked = albcm.schLocked(
      schedule, users, thisUser, did, service, classroom
    )
    if (locked){
      const id = new Date().getTime();
      setSnack({msg: '予定はロックされています。', severity: 'warning', id});
      return false;
    }

    // 別単位のスケジュールロック
    if (
      thisSchedule &&
      thisSchedule.classroom && classroom && 
      classroom !== thisSchedule.classroom &&
      localFabSch > 0
    ){
      const id = new Date().getTime();
      setSnack({
        msg: '別単位の予定なので編集できません。', 
        severity: 'warning', id
      });
      return false;
    }
    else{
      setSnack({msg: '', severity: '', id: new Date().getTime()})
    }

    // 追加削除、追加削除モードでスケジュールが存在しない->追加
    if (localFabSch > 0 && !thisSchedule) {
      // テンプレートからディープコピー
      thisSchedule = {...template[thisService][holidayStr]};
      thisSchedule.classroom = classroom;
      const t = {...sch};
      setSch({...t, [did]:thisSchedule})
    }
    // 追加削除モードでスケジュールが存在する->削除
    else if (localFabSch === 1 && thisSchedule) {
      const t = {...sch};
      delete t[did];
      setSch(t);
    }
    // 追加修正モードでスケジュールが存在する
    else if (localFabSch === 2 && thisSchedule) {
      const t = {open: true, did, uid: UID, usch: sch};
      setDialogOpen(t);
    }
  }
  // ダイアログオープンstateを監視してLOCALstateを更新
  useEffect(()=>{
    const existUscch = Object.keys(dialogOpen.usch).length;
    if (existUscch){
      setSch(dialogOpen.usch);
    }
  }, [dialogOpen]);

  // ゆーざーがみつからないばあいはnullを返す
  const susers = users.filter(e=>e.service === service);
  const thisUser = (suid, susers);
  if (!Object.keys(thisUser).length)  return null;

  const OneWeek = (props)=>{
    const week = props.week.map((e, i)=>{
      const cls = ['', 'schoolOff', 'off'];// 学校休日休業日を示すクラスリスト
      const wdClass = (e !== '')? cls[e.holiday]:'';
      // 日付オブジェクトをdid形式に変換
      const did = (e !== '') ? comMod.convDid(e.date):''
      // この日のスケジュール
      let thisSchedule;
      // そもそもデータがない
      if (!sch)  thisSchedule = undefined;
      // 該当日のデータがない
      else if (Object.keys(sch).indexOf(did) === -1) 
        thisSchedule = undefined;
      else thisSchedule = sch[did];
      const otherClassroomStyle = 
      (
        thisSchedule &&
        thisSchedule && classroom && 
        thisSchedule.classroom && 
        thisSchedule.classroom !== classroom
      )?{opacity: .3}: {};
    
      return(
        <div className={'day ' } key={i} 
          did = {did}
          onClick = {(e)=>clickHandler(e)}
        >
          {(e !== '') &&
            <div className={'dayLabel ' + wdClass}>
              {e.date.getDate()}
            </div>
          }
          
          <div className='content' style={otherClassroomStyle}>
            <EachScheduleContent thisSchedule={thisSchedule}/>
          </div>
        </div>
      );
    });
    return (<div className='week'>{week}</div>);
  }
  // 縦型表示
  const VrtDisp = (props) => {
    const classes = useStyles();
    const days = dateList.map((e,i)=>{
      const cls = ['', 'schoolOff', 'off'];// 学校休日休業日を示すクラスリスト
      const wdClass = (e !== '')? ' ' + cls[e.holiday]:'';
      // 日付オブジェクトをdid形式に変換
      const did = (e !== '') ? comMod.convDid(e.date):'';
      // mon wed fri 月水金で有効になるクラス名
      const mwfClass = [1, 3, 5].indexOf(e.date.getDay()) >= 0 
      ? ' mwfClass' : '';
      // この日のスケジュール
      let thisSchedule;
      // そもそもデータがない
      if (!sch)  thisSchedule = undefined;
      // 該当日のデータがない
      else if (Object.keys(sch).indexOf(did) === -1) 
        thisSchedule = undefined;
      else thisSchedule = sch[did];
      return (
        <div className={classes.vDayWrap} 
          key={i} did = {did}
          onClick = {(e)=>clickHandler(e)}
        >
          {/* <div className={'dayLabel ' + wdClass}>
            {e.date.getDate()}
          </div> */}
          <div className={'content ' + wdClass + mwfClass}>
            <EachScheduleContent 
              thisSchedule={thisSchedule} virtical d={e}
            />
          </div>

        </div>
      );
    });
    return days;
  };
  const weeks = daysGrid.map((e, i)=>{
    return (
      <OneWeek week={e} key={i} />
    );
  });
  const GridDisp = () => (
    <div className='monthWrapper'>
      <div className="month">
        <div className='week'>
          <div className='day weekLabel'>日</div>
          <div className='day weekLabel'>月</div>
          <div className='day weekLabel'>火</div>
          <div className='day weekLabel'>水</div>
          <div className='day weekLabel'>木</div>
          <div className='day weekLabel'>金</div>
          <div className='day weekLabel'>土</div>
        </div>
        {weeks}
      </div>
    </div>
  );
  return (<>
    {virtical === false && <GridDisp />}
    {virtical === true && <VrtDisp />}
  </>);
}

const CountDisp = (props) => {
  const classes = useStyles();
  const {sch, suid} = props;
  let count = 0;
  let absence = 0;
  const users = useSelector(state=>state.users);
  const thisUser = comMod.getUser(suid, users);
  const volume = parseInt(thisUser.volume);
  Object.keys(sch).filter(e=>/^D2[0-9]{7}/.test(e)).forEach(e=>{
    if (!sch[e].absence) count++;
    else absence++;
  });
  console.log(count, absence, volume, 'countDisp');
  const overClass = count > volume? 'over': '';
  return (
    <div className={classes.countRoot}>
      <div className='s'>利用</div>
      <div className={'count ' + overClass}>{count}</div>
      <div className='s'>欠席</div>
      <div className='absence'>{absence}</div>
      <div className='s'>/</div>
      <div className='n'>{volume}</div>
    </div>
  )
}

const MainSchByUsers = (props)=>{
  const classes = useStyles();
  const users = useSelector(state => state.users);
  const service = useSelector(state => state.service);
  const classroom = useSelector(state => state.classroom);
  const schedule = useSelector(state => state.schedule);
  const serviceItems = useSelector(state => state.serviceItems);
  const stdDate = useSelector(state => state.stdDate);
  // const {suid, setSuid, } = props;
  const hid = useSelector(state => state.hid);
  const bid = useSelector(state => state.bid);
  const dispatch = useDispatch();
  // ダイアログ制御用
  const [dialogOpen, setDialogOpen] = useState({
    open: false, uid: '', did: '' , usch: {}
  });

  // サービスが未設定なら設定する
  if (!service) {
    dispatch(Actions.changeService(serviceItems[0]));
  }
  // 対象となるユーザーのリスト
  const fusers = albcm.getFilteredUsers(users, service, classroom);
  // パラメータからuidを取得トライ
  const puid = useParams().p;
  // uid をlocal state化
  const [suid, setSuid] = useState(puid? puid: '');
  // ユーザーごとのスケジュール定義 初期状態であることを確認するためのフラグ格納
  const [sch, setSch] = useState({init: true});
  const [snack, setSnack] = useState({msg: '', severity: '', id: 0});
  const [res, setRes] = useState(null);
  const [localFabSch, setLocalFabSch] = useState(0);
  const [userInfo, setUserInfo] = useState({});
  // 縦型表示にするかどうか SetUsersSchViewCookiesで設定される
  const [virtical, setVirtical] = useState(false);
  const favSchProps = {localFabSch, setLocalFabSch};
  const nodeId = 'nodeX765' + service + classroom;
  const [allSch, setAllSch] = useState(()=>{
    const t = {};
    Object.keys(schedule).forEach(e=>{
      if (!comMod.getUser(e, fusers)) return false;
      t[e] = schedule[e];
    });
    return t;
  });
  // suidを保持するよ
  const controleMode = useSelector(state=>state.controleMode);
  // 最初だけallSch作成
  // useEffect(()=>{
  //   Object.keys(schedule).forEach(e=>{
  //     if (!comMod.getUser(e, fusers)) return false;
  //     allSch[e] = schedule[e];
  //   });
  // }, [])
  // useRef使ってみるよ
  const firstRender = useRef(false);
  // ユーザーごとのスケジュールを設定
  useEffect(()=>{
    if (suid && firstRender){
      setSch(allSch['UID' + suid]);
      setUserInfo(comMod.getUser(suid, users));
      // スケジュールデータが最初に読み込まれたフラグ
      firstRender.current = true;
    }
  }, [suid]);


  // -------------------------------
  // 作り直すよ
  // -------------------------------
  useEffect(()=>{
    const uiLen = Object.keys(userInfo).length;
    const schLen = (sch)? Object.keys(sch).length: 0;
    const sendItem = async (v) =>{
      const sendPrms = {
        uid: 'UID' + suid, hid, bid, date: stdDate, schedule: sch 
      }
      await albcm.sendUsersSchedule(sendPrms, setRes, setSnack, userInfo.name);
    }
    const dispatchItem = async (v) =>{
      const t = {...schedule};
      const s = {...allSch};
      Object.keys(s).forEach(e=>{
        t[e] = s[e]
      });
      t['UID' + suid] = sch;
      if (sch !== undefined){
        dispatch(Actions.setStore({schedule: t}));  
      }
    }
    if (!firstRender.current && schLen > 1){
      sendItem();
    }
    if (firstRender.current && uiLen){
      firstRender.current = false;
    }
    const s = {...allSch};
    s['UID' + suid] = sch;
    setAllSch(s);
    return () => {
      console.log('unmounted');
      setTimeout(()=>{
        const closed = !document.querySelector('#' + nodeId);
        if (Object.keys(sch).length > 1 && closed){
          dispatchItem();
        }
      }, 100)
    }
  }, [sch, ])

  const susers = users.filter(e=>e.service === service);
  const pUser = comMod.getUser(puid, susers); // パラメータで指定されたユーザー
  const pUserExist = Object.keys(pUser).length;
  const Uinfo = () => {
    const noUserstyle = {paddingTop: 60, textAlign:'center'};
    if (!puid) return null;
    return(<>
      {pUserExist > 1 && <>
        <div className={classes.userInfo}>
          <div>{pUser.name} 様</div>
          <div>{pUser.ageStr}</div>
          <div>{pUser.belongs1}</div>
        </div>
      </>}
      
      {pUserExist === 0 && <>
        <div style={noUserstyle}>
          存在しないユーザーが指定されました。
        </div>
      </>}
    </>)
  }
  const sdPrms = {
    suid, sch, setSch, dialogOpen, setDialogOpen, setSnack, localFabSch,
    virtical,
  };
  return(<>
    <LinksTab menu={menu} />
    <div className="AppPage schByUsers">
      <div className={classes.occuWrap}>
        <OccupancyRate displayMode='wide' localSch={{['UID' + suid]:sch}} />
      </div>
      <UserSlect puid={puid} suid={suid} setSuid={setSuid} />
      <Uinfo />
      {((!puid) || pUserExist > 1) && <>
        <CountDisp {...sdPrms} />
        <SevenDaysGrid {...sdPrms} />
        <mui.FabSchedule {...favSchProps} />
        <SchEditDetailDialog 
          stateOpen={dialogOpen} setStateOpen={setDialogOpen}
        />
        <SnackMsg {...snack} />
        <div style={{marginTop: 16, textAlign: 'center'}}>
          <SetUsersSchViewCookies setVirtical={setVirtical}/>
        </div>
      </>}

    </div>
    <div id={nodeId}></div>
  </>)
}

// const ErrSchByUsers = ()=>{
//   return(<div>error occured.</div>)
// }

const SchByUsers2 = ()=>{
  // const userFtc = useSelector(state => state.userFtc);
  // const fetchCalenderStatus = useSelector(state => state.fetchCalenderStatus);
  // // fetch状態の取得
  // const done = (
  //   userFtc.done && fetchCalenderStatus.done
  // );
  // const errorOccured = (
  //   userFtc.err || fetchCalenderStatus.err
  // );
  // const loading = (
  //   userFtc.loading || fetchCalenderStatus.loading
  // );
  const dispatch = useDispatch();
  const allstate = useSelector(state=>state);
  const loadingStatus = comMod.getLodingStatus(allstate);
  // ------ 下位モジュールより
  // パラメータからuidを取得トライ
  const puid = useParams().p;
  // uid をlocal state化
  // const [suid, setSuid] = useState(puid? puid: '');
  // 全体のスケジュールをローカルstate化
  // const [allSch, setAllSch] = useState(allstate.schedule);
  // const props = {suid, setSuid, allSch, setAllSch, puid};
  const normalId = 'nomal-schbyuser2';
  const notNormalId = 'not-nomal-schbyuser2';

  if (loadingStatus.loaded){
    return(<>
      <MainSchByUsers />
      <div id={normalId}></div>
    </>)
  }
  else if (loadingStatus.error){
    return (<>
      <LoadErr loadStatus={loadingStatus} errorId={'E4941'} />
      <div id={notNormalId}></div>
    </>)
  }
  else{
    return(<>
     <LoadingSpinner/>
     <div id={notNormalId}></div>
    </>)
  }
  // if (done) return (<MainSchByUsers />);
  // else if (loading) return (<LoadingSpinner />);
  // else if (errorOccured) return (<ErrSchByUsers />);
  // else return null;
}
export default SchByUsers2;