import React, {useState, useEffect, } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector, ReactReduxContext } from 'react-redux';
import * as Actions from '../../Actions';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import teal from '@material-ui/core/colors/teal';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import lime from '@material-ui/core/colors/lime';
import deepOrange from '@material-ui/core/colors/deepOrange';
import Dialog from '@material-ui/core/Dialog';
import MouseIcon from '@material-ui/icons/Mouse';
import InfoIcon from '@material-ui/icons/Info';
import { setBillInfoToSch } from './blMakeData';
import * as comMod from '../../commonModule';

const useStyles = makeStyles((theme) => ({
  close: {
    padding: theme.spacing(0.5),
  },
  snackNormal: {
    ' & .MuiSnackbar-root .MuiPaper-root': {
      backgroundColor:teal[900]
    }
  },
  snackWarning: {
    ' & .MuiSnackbar-root .MuiPaper-root': {
      backgroundColor: lime[900]
    }
  },
  snackSuccess: {
    ' & .MuiSnackbar-root .MuiPaper-root': {
      backgroundColor: blue[900]
    }
  },
  snackError: {
    ' & .MuiSnackbar-root .MuiPaper-root': {
      backgroundColor: red[800]
    }
  },
  snackClass:{
    '& .MuiSnackbar-root .MuiPaper-root': {
      backgroundColor: red[50],
    },
    '& .MuiSnackbarContent-message':{color:red[900]},
    '& .MuiSvgIcon-root': {color:red[900]},
    '& .detailLabl>span':{
      display:'inline-block', padding: 9, color:red[900],
      cursor:'pointer',
    },
    '& .detailLabl .MuiSvgIcon-root':{marginTop: -1}

  },
  dialogRoot: {
    '& .MuiDialog-paperWidthSm':{width: 800, maxWidth: '90%'}
  },
  errDialog: {
    padding: 16, 
    lineHeight: 1.4, 
    // fontWeight:600,
    '& .inner': {paddingTop: 4, paddingBottom: 4},
    '& .buttonWrap': {textAlign: 'center', paddingTop: 8, paddingBottom: 4},
    '& .imgWrap': {textAlign: 'center'},
    '& .errId': {
      color: teal[100], fontSize:'1.2rem', fontWeight: 600, //padding: '0 4px',
    },
    '& .detail': {fontSize: '.9rem',color:deepOrange[900]}
  },
  divInSnack:{
    background:red[50],color:red[900],
  },
}));
// 請求データの不整合を検出する。
// 当初はdispatchされたbillingDtを使う設定だったがdispatch後の値に問題が発生しているため
// 都度、計算を実施する。
// 今後、dispatchしない方向で進めるようにする必要あるかも。
export const ServiceCountNotice = (props) => {
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [msg, setmsg] = useState('');
  const [severity, setseverity] = useState('');
  const [errorId, setErrorId] = useState('');
  const [msgDetail, setMsgDetail] = useState([]);
  const [bdtObj, setBtdObj] = useState({}); // setBillingToSchの戻り地を格納
  // const billingDt = useSelector(state=>state.billingDt);
  const stdDate = useSelector(state=>state.stdDate);
  const schedule = useSelector(state=>state.schedule);
  const users = useSelector(state=>state.users);
  const com = useSelector(state=>state.com);
  const service = useSelector(state=>state.service);
  const serviceItems = useSelector(state=>state.serviceItems);
  const allstate = useSelector(state=>state);
  const loadingStatus = comMod.getLodingStatus(allstate);

  const dispatch = useDispatch();
  const prms = { 
    stdDate, schedule, users, com, service, serviceItems, dispatch 
  };
  // classroomのユニーク配列を作成
  const classroos = Array.from(new Set(users.map(e=>
    (e.classroom)? e.classroom: 'nc'
  )));

  const handleClose = (event, reason) => {
    if (event) event.preventDefault();
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  }
  // billingDtを取得
  useEffect(()=>{
    if (loadingStatus.loaded){
      setBtdObj(setBillInfoToSch(prms));
    }
  }, []);
  // billingDtを監視
  useEffect(()=>{
    if (Array.isArray(bdtObj.billingDt)){
      const t = []; // 利用者ごとの通知用
      const s = []; // 利用日ごとの通知用
      const billingDt = bdtObj.billingDt;
      // 日毎の利用数を記述するオブジェクト
      const dayofUse = {};
      serviceItems.map(e=>dayofUse[e] = {});
      Object.keys(dayofUse).map(e=>{
        classroos.map(f=>{
          dayofUse[e][f] = {};  
        })
      })
      billingDt.map(e=>{
        Object.keys(e).filter(f=>f.indexOf('D2') === 0).map(f=>{
          const o = e[f];
          if (o.absence)  return false;
          const s = o.service;
          const c = (e.classroom)? e.classroom: 'nc';
          if (dayofUse[s][c][f] === undefined){
            dayofUse[s][c][f] = 1;
          }
          else{
            dayofUse[s][c][f]++;
          }
          // サービスごと単位でない場合のカウント
          if (dayofUse[c] === undefined)  dayofUse[c] = {};
          if (dayofUse[c][f] === undefined){
            dayofUse[c][f] = 1;
          }
          else{
            dayofUse[c][f]++;
          }
        });
      });

      // 利用回数のチェック
      Object.keys(dayofUse).map(e=>{
        let teiin = comMod.findDeepPath(com, ['addiction', e, '定員']);
        teiin = parseInt(comMod.null2Zero(teiin));
        const limit = comMod.upperLimitOfUseByDay(teiin);
        // サービスごとの単位かどうか
        const SasT = comMod.findDeepPath(
          com, ['addiction', e, 'サービスごと単位']
        );
        if (!SasT)  return false; // サービスごと単位でない場合はここでは行わない
        // サービス名ではないキーはここでは扱わない
        if (serviceItems.indexOf(e) === -1) return false;
        Object.keys(dayofUse[e]).map(f=>{
          Object.keys(dayofUse[e][f]).map(g=>{
            if (dayofUse[e][f][g] > limit){
              const d = comMod.convDid(g);
              const fd = comMod.formatDate(d, 'MM月DD日');
              s.push({
                item:'dayOfUse', date:fd, limit, count:dayofUse[e][f][g],
                classroom: (f === 'nc')? '': f
              });
            }
          })
        })
      });
      // 利用回数チェック サービスごと単位でない場合
      // 定員は任意のサービスから取得する
      const defSvc = serviceItems[0]; // 任意のサービス 定員取得用
      let teiin = comMod.findDeepPath(com, ['addiction', defSvc, '定員']);
      teiin = parseInt(comMod.null2Zero(teiin));
      const limit = comMod.upperLimitOfUseByDay(teiin);
      // サービスごとの単位かどうか
      const SasT = comMod.findDeepPath(
        com, ['addiction', defSvc, 'サービスごと単位']
      );
      if (!SasT){
        Object.keys(dayofUse).forEach(e=>{
          if (SasT)  return false; // サービスごと単位の場合はここでは行わない
          // サービス名がキーの場合はスキップ
          if (serviceItems.indexOf(e) > -1) return false;
  
          Object.keys(dayofUse[e]).map(f=>{
            if (dayofUse[e][f] > limit){
              const d = comMod.convDid(f);
              const fd = comMod.formatDate(d, 'MM月DD日');
              s.push({
                item:'dayOfUse', date:fd, limit, count:dayofUse[e][f],
                classroom: (e === 'nc')? '': f
              });
            }
          })
        });
      }
      billingDt.map(e=>{
        // 回数集計された配列を舐めて利用数オーバーを検出
        // 検出された内容をmsgDetaiにセット
        e.itemTotal.map(f=>{
          if (f.limit === '') return false;
          if (f.count > parseInt(f.limit)){
            t.push({
              name: e.name, item: f.name, 
              limit: parseInt(f.limit), count: f.count
            })
          }
        });
        // ベースアイテム
        const baseCnt = e.itemTotal.filter(f=>f.baseItem)
        .reduce((v, f)=>(v + f.count), 0);
        if (baseCnt > e.volume){
          t.push({
            name: e.name, item: 'baseItem', 
            limit: parseInt(e.volume), count: baseCnt
          });
        }
        // 括って回数を出すパターン
        // --- 上限がありかつ上限内
        const inLimit = e.itemTotal
        .filter(f=>f.limit && f.count <= parseInt(f.limit));
        const soudan = inLimit.filter(f=>f.name==='事業所内相談支援加算');
        const kaRen = inLimit.filter(f=>f.name==='家庭連携加算');
        // --- 合計の回数が上限を超えているレコードをdetailに追加
        const soudanCnt = soudan.reduce((v, f)=>(v + f.count), 0);
        const soudanLimit = (soudan.length)? parseInt(soudan[0].limit): 200;
        const kaRenCnt = kaRen.reduce((v, f)=>(v + f.count), 0);
        const kaRenLimit = (kaRen.length)? parseInt(kaRen[0].limit): 200;
        if (soudanCnt > soudanLimit){
          t.push({
            name: e.name, item: '事業所内相談支援加算', 
            limit: soudanLimit, count: soudanCnt
          });
        }
        if (kaRenCnt > kaRenLimit){
          t.push({
            name: e.name, item: '家庭連携加算', 
            limit: kaRenLimit, count: kaRenCnt
          });
        }
      });
      // 回数オーバーのメッセージ件数を検出したらセッターを起動
      t.push(...s); // 利用者ごとのアラートと日付ごとのアラートをマージ
      if (t.length) setMsgDetail(t);
    }
  }, [bdtObj]);
  // msgDetailを監視
  useEffect(()=>{
    // 通知が一件の場合
    if (msgDetail.length === 1){
      const o = msgDetail[0];
      let t;
      if (o.item === 'baseItem'){
        t = `${o.name} さんの 利用回数に問題があります。`;
      }
      else if (o.item === 'dayOfUse'){
        t = `${o.date} の利用回数が制限を超えています。`;
        if (o.classroom) t = t + `単位 : ${o.classroom}`
      }
      else{
        t = `${o.name} さんの ${o.item} の設定件数に問題があります。`;
      }
      setmsg(t /* + '...詳細'*/);
      setOpen(true);
      // setseverity('warning')
    }
    // 通知が複数件の場合
    else if (msgDetail.length > 1){
      const o = msgDetail[0];
      const l = msgDetail.length;
      const dispName = o.item === 'baseItem' ? '利用回数': o.item;
      const t = (o.item === 'dayOfUse')
      ? `${o.date} の利用回数など  ${l} 件の問題があります。`
      : `${o.name} さんの ${dispName} など ${l} 件の問題があります。`;
      setmsg(t /* + '...詳細'*/);
      setOpen(true);
      // setseverity('warning')
    }
  }, [msgDetail]);
  const classes = useStyles();
  let snackClass;
  const handleClick = (ev) => {
    setOpen(false);
    setDialogOpen(true);
    ev.stopPropagation();
  }
  const SnackDisp = () => (
    <div className={classes.snackClass}>
      {/* <Button onClick={handleClick}>Open simple snackbar</Button> */}
      <Snackbar
        // anchorOrigin={{vertical: 'bottom',horizontal: 'left',}}
        anchorOrigin={{vertical: 'bottom', horizontal:'right' }}
        open={open}
        autoHideDuration={12000}
        onClose={(ev)=>handleClose(ev)}
        // onClick={(ev)=>handleClick(ev)}
        message={msg}
        action={
          <>
            <div className='detailLabl' onClick={(ev)=>handleClick(ev)}>
              <span>詳細</span>
              <IconButton 
                size="small" aria-label="close" 
                onClick={(ev)=>handleClose(ev)}
              >
                <InfoIcon fontSize="small" />
              </IconButton>
            </div>

            <IconButton 
              size="small" aria-label="close" 
              onClick={(ev)=>handleClose(ev)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      >
        
      </Snackbar>
    </div>
  );
  const dialogDetail = msgDetail.map((e, i)=>{
    let str;
    if (e.item === 'baseItem'){
      str = `${e.name} さんの利用回数が上限を超えています。
             ${e.count + ' / ' + e.limit}`
    }
    else if (e.item === 'dayOfUse'){
      str = `${e.date} 利用回数が上限を超えています。
             ${e.count + ' / ' + e.limit}`
      if (e.classroom !== 'nc'){
        str = `${e.classroom} ` + str;
      }
    }
    else{
      str = `${e.name} さんの ${e.item} の利用回数が上限を超えています。
             ${e.count + ' / ' + e.limit}`
    }
    return(
      <div className='detail' key={i}>
        {str}
      </div>
    )
  });
  const handleCloseClick = () => {
    setDialogOpen(false);
  }
  const DialogDisp = () => (
    <Dialog 
      onClose={handleDialogClose} open={dialogOpen} 
      className={classes.dialogRoot}
    >
      <div className={classes.errDialog}>
        <div className='inner'>
          {dialogDetail}
        </div>
        <div className='buttonWrap'>
          <Button variant="contained" onClick={handleCloseClick}>OK</Button>
        </div>
      </div>
    </Dialog>
  )
  return(<>
    <DialogDisp />
    <SnackDisp />
  </>) 
};
export default ServiceCountNotice;
