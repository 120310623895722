import React, {useEffect, useState, } from 'react';
import * as Actions from '../../Actions';
import { useSelector, useDispatch } from 'react-redux';
// import SchHeadNav from './SchHeadNav';
import SchEditDetailDialog from './SchEditDetailDialog';
// import SchTableHead from './SchTableHead';
import * as comMod from '../../commonModule';
import * as mui from '../common/materialUi';
import {LoadingSpinner, LoadErr} from '../common/commonParts';
// import SimpleModal from '../common/modal.sample';
import SchDailyDialog, {SchDailyDialogPropsOpen} from './SchDailyDialog';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
// import { common } from '@material-ui/core/colors';
// import AccessTimeIcon from '@material-ui/icons/AccessTime';
// import DriveEtaIcon from '@material-ui/icons/DriveEta';
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
// import AddCircleIcon from '@material-ui/icons/AddCircle';
import EachScheduleContent from './SchEachScheduleContent';
// import SchSaveLater from './SchSaveLater';
import { menu } from './Sch2';
import { LinksTab } from '../common/commonParts';
import { useLocation, useParams, } from 'react-router-dom';
import { CallMissedSharp } from '@material-ui/icons';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import teal from '@material-ui/core/colors/teal';
import blue from '@material-ui/core/colors/blue';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import CheckIcon from '@material-ui/icons/Check';
import SchUserSetting from './SchUserSetting';
import {OccupancyRate} from './SchHeadNav';
import { GetNextHist } from '../Users/Users';
import { Button } from '@material-ui/core';
import { isClassroom, sendPartOfSchedule } from '../../albCommonModule';
import ClearIcon from '@material-ui/icons/Clear';
import SnackMsg from '../common/SnackMsg';

const useStyles = makeStyles({
  userInfo:{
    display:'flex',
    justifyContent:'center',
    paddingTop: 48,
    paddingBottom: 24,
    '& >div': {
      margin: '0 8px',
    }
  },
  eachAddiction: {
    '& .eachItem': {
      paddingTop: 4, paddingBottom: 4,
    },
    '& .name':{
      paddingTop: 2, paddingBottom: 2,
      '& .MuiSvgIcon-root':{
        fontSize: '.6rem', color:teal[600],
      },
    },
    '& .val':{
      paddingTop: 2, paddingBottom: 2,
      '& .MuiSvgIcon-root':{
        fontSize: '.6rem',
      },
    },
  },
  monthWrap: {
    marginTop: 80, flex: 1,
    '& .day': {
      cursor:'pointer',
      position: 'relative',
      '& .content': {
        minHeight: 80,
        position: 'relative',
        '& .eachItem': {
          paddingTop: 4, paddingBottom: 4,
        },
        '& .name':{
          paddingTop: 2, paddingBottom: 2,
          '& .MuiSvgIcon-root':{
            fontSize: '.6rem', color:teal[600],
          },
        },
        '& .val':{
          paddingTop: 2, paddingBottom: 2,
          '& .MuiSvgIcon-root':{
            fontSize: '.6rem',
          },
        },
    
      },
      '& .checkIcon':{
        color: teal[600], position:'absolute',
        right: '1rem', top: 4,
        '& .MuiSvgIcon-root': {fontSize:'1.0rem'}
      },
    },
  
    '& .counts, .usage': {
      paddingTop: 4, paddingBottom: 4,
      fontSize: '.7rem', textAlign: 'center',
      '& span': {fontSize: '1.0rem'},
      '& .wd': {color: teal[800]},
      '& .os': {color: orange[900]},
      '& .ab': {color: red[800]},
      '& .ur': {color: blue[800]},
    },
  },
  userSettingWrap:{position:'relative'},
  occuRateWrap:{paddingTop: 30, width: '100%'},
  useResultButtons:{
    position: 'absolute', top:98, right:8, width: 128,
    '& a': {display: 'block', paddingBottom: 8},
    '& .MuiButton-root': {width: 128},
    '& .dateLabel': {
      fontSize: '.9rem', textAlign: 'center', paddingBottom: 4, color: teal[800]
    },
  },
});
const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#009688',
      main: '#00695c',
      dark: '#004d40',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});


// ローカルのステイトでコントロールしていたがstoreにuidを書き込むようにする
const UserSlect = (props)=>{
  const dispatch = useDispatch();
  const usersOrg = useSelector(state => state.users);
  let service = useSelector(state => state.service);
  const classroom = useSelector(state => state.classroom);
  const serviceItems = useSelector(state => state.serviceItems);
  const contMode = useSelector(state => state.controleMode);
  const cntUid = contMode.uid;
  const [thisUid, setthisUid] = useState(cntUid);
  // サービス内容でユーザーリストを絞り込み
  const users = usersOrg.filter(e=>(
    e.service === service &&
    (classroom === '' || e.classroom === classroom)
  ));
  // パラメータで設定されたuidをstoreにセット。
  // 見つからなかったらメッセージを作成する
  // let uidFound = false;
  // if (puid){
  //   if (users.find(e => parseInt(e.uid) === parseInt(puid))){
  //     dispatch(Actions.setControleMode({ ...contMode, uid: parseInt(puid) }));
  //     uidFound = true;
  //   }
  // }
  // else{
    // storeのuidが現在のユーザーリストになければユーザーリストの先頭をセット
    // パラメータでuidが指定されているときはこの処理はしない    
  if (!users.find(e => parseInt(e.uid) === comMod.convUID(thisUid).num)) {
    setthisUid(users[0].uid);
    dispatch(Actions.setControleMode({ ...contMode, uid: users[0].uid }));
  }
  // }

  // セレクトのオプションを作成
  const Options = ()=>{
    const opt = users.map((e, i)=>{
      return(
        <option key={i} value={e.uid}>{e.name + ' ' + e.ageStr}</option>
      )
    });
    return opt;
  }
  const selectClass = {
    margin: theme.spacing(1),
    minWidth: 120,
  }
  const handleChange = (e) =>{
    const val = e.currentTarget.value; 
    setthisUid(val);
    dispatch(Actions.setControleMode({...contMode, uid: val}));
  }
  return(<>
    <FormControl style={selectClass}>
      <InputLabel >{props.name}</InputLabel>
      <Select
        native
        value={thisUid}
        name={'ご利用者選択'}
        onChange={(e) => handleChange(e)}
      >
        <Options />
      </Select>
    </FormControl>
  </>)
}

export const EachAddiction = (props) => {
  const classes = useStyles();
  const {thisAdc, } = props;
  const addiction = thisAdc? thisAdc: {};
  const eachAdic = Object.keys(addiction).map((e, i)=>{
    // console.log(e, addiction[e]);
    return(<div className='eachItem' key={i}>
      <div className='name'>
        <FiberManualRecordIcon  />
        {comMod.shortWord(e)}
      </div>
      <div className='val'>
        <ArrowForwardIosIcon />
        {parseInt(comMod.shortWord(addiction[e])) === 1 ?
         '選択': comMod.shortWord(addiction[e])}
      </div>
    </div>);
  });
  return(
    <div className={classes.eachAddiction}>
      {eachAdic}
    </div>
  )
}

// 一日の情報を表示する。加算項目と利用回数。
const DaySettingContent = (props) => {
  const {did, schCounts, ...others} = props;
  const allState = useSelector(state=>state);
  const schedule = allState.schedule;
  const service = allState.service;
  // この日の加算項目
  const thisAdc = comMod.findDeepPath(schedule,[service, did])?
  comMod.findDeepPath(schedule,[service, did]):[];
  const dCount = schCounts.didCounts[did];
  // この日の加算項目を列挙
  const eachAdic = Object.keys(thisAdc).map((e, i)=>{
    // console.log(e, thisAdc[e]);
    return(<div className='eachItem' key={i}>
      <div className='name'>
        <FiberManualRecordIcon  />
        {comMod.shortWord(e)}
      </div>
      <div className='val'>
        <ArrowForwardIosIcon />
        {parseInt(comMod.shortWord(thisAdc[e])) === 1 ?
         '選択': comMod.shortWord(thisAdc[e])}
      </div>
    </div>);
  });
  return (<>
    {dCount !== undefined &&
      <div className='counts'>
        <span className='wd'>{dCount.weekDayCnt}</span>{' / '}
        <span className='os'>{dCount.schoolOffCnt}</span>{' / '}
        <span className='ab'>{dCount.absenceCnt}</span>{' / '}
        <span className='ur'>{dCount.useResultCnt}</span>
      </div>
    }
    <div className='daySetteingInner'>
      {/* {eachAdic} */}
      <EachAddiction thisAdc={thisAdc} />
    </div>
  </>);
}

const SevenDaysGrid = (props)=>{
  const classes = useStyles();
  const dispatch = useDispatch()
  const dateList = useSelector(state=>state.dateList);
  const path = useLocation().pathname;
  const {setDialogOpen, setDialogDate} = props;
  const schedule = useSelector(state=>state.schedule);
  const service = useSelector(state=>state.service);
  const classroom = useSelector(state=>state.classroom);
  

  // 該当ユーザーのみのスケジュールを取得
  // const schedule = useSelector(state => state.schedule['UID' + thisUser]);
  const users = useSelector(state => state.users);

  // フローティングアクションボタンの値取得
  // 0 何もしない 1 追加削除 2 追加修正
  // let cntMode = useSelector(state => state.controleMode.fabSchedule);
  // cntMode = (cntMode === undefined) ? 0 : parseInt(cntMode);

  const template = useSelector(state => state.scheduleTemplate);
  // 7曜グリッド作成
  const daysGrid = comMod.makeDaysGrid(dateList);
  // クリックハンドラ
  const clickHandler = (e)=>{
    const targetDid = e.currentTarget.getAttribute('did');
    if (!targetDid) return false; // didがなければ何もしない。
    const targetDate = comMod.convDid(targetDid);
    setDialogOpen(true);
    setDialogDate(targetDate);
  }
  const schCounts = comMod.getScheduleInfo(schedule, service, users, classroom);
  const didCounts = schCounts.didCounts;
  const OneWeek = (props)=>{
    const week = props.week.map((e, i)=>{
      const cls = ['', 'schoolOff', 'off'];// 学校休日休業日を示すクラスリスト
      const wdClass = (e !== '')? cls[e.holiday]:'';
      // 日付オブジェクトをdid形式に変換
      const did = (e !== '') ? comMod.convDid(e.date):''
      // 利用実績を示すチェックを表示
      const ResultOfUse = () => {
        const c = didCounts[did];
        const n = c? c.schoolOffCnt + c.weekDayCnt: null;
        const r = c? c.useResultCnt: null;
        // const s = {color: teal[600], '& .MuiSvgIcon-root': {fontSize:'.7rem'}}
        if (r && r === n){
          return (<div className='checkIcon'><CheckIcon/></div>);
          // return (<div style={s}><CheckIcon/></div>);
        }
        else{
          return null;
        }
      }
      return(
        <div className={'day '} key={i} 
          did = {did}
          onClick = {(e)=>clickHandler(e)}
        >
          {(e !== '') &&
            <div className={'dayLabel ' + wdClass}>
              {e.date.getDate()}
              <ResultOfUse />
            </div>
          }
          <div className='content'>
            <DaySettingContent did={did} schCounts={schCounts}/>
          </div>
        </div>
      );
    });
    return (<div className='week'>{week}</div>);
  }
  const weeks = daysGrid.map((e, i)=>{
    return (
      <OneWeek week={e} key={i} />
    );
  });
  return (
    <div className={'monthWrapper ' + classes.monthWrap}>
      <div className="month">
        <div className='week'>
          <div className='day weekLabel'>日</div>
          <div className='day weekLabel'>月</div>
          <div className='day weekLabel'>火</div>
          <div className='day weekLabel'>水</div>
          <div className='day weekLabel'>木</div>
          <div className='day weekLabel'>金</div>
          <div className='day weekLabel'>土</div>
        </div>
        {weeks}
      </div>
      <div className='usage'>
        <span className='wd'>平日利用</span>{' / '}
        <span className='hd'>休日利用</span>{' / '}
        <span className='ab'>欠席</span>{' / '}
        <span className='ur'>利用実績</span>
      </div>
    </div>
  );
}

const SetUseResult = (props) => {
  const classes = useStyles();
  const allState = useSelector(state=>state);
  const {hid, bid, stdDate, classroom, users, service} = allState;
  const {schedule: sch, setSchedule: setSch} = props;
  const [snack, setSnack] = useState({msg:'', severity:''})
  // 実績セット、リセットする日付をdid形式で
  const st = 'D' + stdDate.replace(/\-/g, ''); // 2022-04-01 -> D20220401;
  let ed;
  const edStdDate = comMod.getDateEx( // 当月の月末
    stdDate.split('-')[0], stdDate.split('-')[1], 0
  ).dt;
  if (edStdDate < new Date()) ed = comMod.convDid(edStdDate);
  else{
    let t = new Date();
    t.setHours(t.getHours() - 18);
    ed = comMod.convDid(t);
  }
  const clickHandler = (value) => {
    const d = value? ed: st; // 設定日付。実績にするときは
      // 対象となるユーザーUIDxxx形式の配列
    const tUsers = users
    .filter(e=>e.service===service)
    .filter(e=>isClassroom(e, classroom))
    .map(e=>'UID' + e.uid);

    const targetList = [];
    Object.keys(sch).forEach(e =>{
      // scheduleのキーでuidを探す
      if (e.indexOf('UID') !== 0){
        return false;
      }
      
      // classroomとserviceで切り分けされた配列で該当ユーザーかどうかをチェック
      if (tUsers.indexOf(e) < 0)  return false;
      // schedule.uidから更に掘って該当するスケジュールオブジェクトを特定する
      // 一括変更のオプションも読んでそのとおりに変更リストを追加する
      Object.keys(sch[e]).filter(f=>f.match(/^D[0-9]+/)).forEach(f=>{
        const o = sch[e][f];
        if (o.classroom && classroom && classroom !== o.classroom) return false;
        // if (f === d)  targetList.push({UID:e, did: f});
        if (f <= d && value) targetList.push({ UID: e, did: f });
        if (f >= d && !value) targetList.push({ UID: e, did: f });
      });
    });

    // 部分送信
    const partOfSch = {};
    tUsers.map(e=>{partOfSch[e] = {...sch[e]}});
    targetList.map(e=>{
      partOfSch[e.UID][e.did].useResult = value;
    });
    const sendPrms = {hid, bid, date:stdDate, partOfSch}
    sendPartOfSchedule(sendPrms, 'setRes', setSnack);
    // ローカルのスケジュールに値セット
    setSch({...sch, ...partOfSch});
  }
  const edStr = parseInt(ed.slice(5,7)) + '月' + parseInt(ed.slice(7,9)) + '日';
  const todayStr = comMod.formatDate(new Date(), 'YYYY-MM-DD');
  if (todayStr < stdDate) return null;
  return (
    <div className={classes.useResultButtons}>
      <a>
        <div className='dateLabel'>{edStr}まで</div>
        <Button
          variant='contained'
          color='primary'
          onClick={()=>clickHandler(true)}
          startIcon={<CheckIcon/>}
        >
          {'実績にする'}
        </Button>
      </a>
      <a>
        <Button
          variant='contained'
          color='secondary'
          onClick={()=>clickHandler(false)}
          startIcon={<ClearIcon/>}
        >
          実績を解除
        </Button>
      </a>
      <SnackMsg {...snack}/>
    </div>
  )
}

const MainSchDaySetting = ()=>{
  const classes = useStyles();
  const users = useSelector(state => state.users);
  const service = useSelector(state => state.service);
  const serviceItems = useSelector(state => state.serviceItems);
  const contMode = useSelector(state => state.controleMode);
  const sSchedule = useSelector(state => state.schedule);
  const dateList = useSelector(state => state.dateList);
  const stdDate = useSelector(state => state.stdDate);
  const hid = useSelector(state => state.hid);
  const bid = useSelector(state => state.bid);

  const dispatch = useDispatch();
  // storeのスケジュールとは別にローカルステイとのスケジュールを設定
  const [schedule, setSchedule] = useState(sSchedule);
  // サービスが未設定なら設定する
  // こういうディスパッチやセットステイとはuseeffectでラッピングするほうが安全らしい
  // useEffect(()=>{
  //   if (!service) {
  //     dispatch(Actions.changeService(serviceItems[0]));
  //   }
  // }, [service]);
  // ダイアログ用のstate
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogDate, setDialogDate] = useState(null);
  // ユーザー別加算項目をユーザーのstateから取得
  // useEffect(()=>{
  //   const p = {dateList, stdDate, users, schedule, hid, bid, dispatch};
  //   comMod.addUsersAddictionToSch(p);
  // }, []);
  useEffect(()=>{
    if (service && !schedule[service]){
      const p = {dateList, stdDate, schedule, hid, bid, users, dispatch }
      comMod.addUsersAddictionToSch(p);
    }
  }, [schedule, service]);

  const dialogPrms = {
    dialogDate, setDialogDate, dialogOpen, setDialogOpen,
    schedule, setSchedule,
  };
  const gridPrms = {setDialogOpen, setDialogDate}
  return(<>
    <LinksTab menu={menu} />
    <div className="AppPage schByUsers flex">
      <div className={classes.occuRateWrap}>
        <OccupancyRate displayMode='wide' />
      </div>
      <div className={classes.userSettingWrap}>
        <SchUserSetting />
      </div>
      <SevenDaysGrid {...gridPrms} />
      <SchDailyDialogPropsOpen {...dialogPrms} />
    </div>
    <SetUseResult schedule={schedule} setSchedule={setSchedule} />
    <GetNextHist />
  </>)
}


const SchDaySetting = ()=>{

  const allstate = useSelector(state=>state);
  const loadingStatus = comMod.getLodingStatus(allstate);
  if (loadingStatus.loaded){
    return(
      <MainSchDaySetting />
    )
  }
  else if (loadingStatus.error){
    return (
      <LoadErr loadStatus={loadingStatus} errorId={'E4943'} />

    )
  }
  else{
    return <LoadingSpinner/>
  }

}
export default SchDaySetting;