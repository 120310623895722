// 計算用のFunctionを提供する
import React, { useEffect, useRef, useState } from 'react';
import * as Actions from '../../Actions';
import * as comMod from '../../commonModule';
import { useDispatch, useSelector, } from 'react-redux';
import { common } from '@material-ui/core/colors';
import { LoadingSpinner, LoadErr } from '../common/commonParts';
import {
  houdySirvice, houdayKasan, chiikiKubun, unitPrice,
  serviceSyubetu, ketteiScode, 
  SOUGEY_SVC_CODE,
  KATEI_SVC_CODE,
  // HOUMON_SVC_CODE, // 2021廃止っぽい
  KESSEKI_SVC_CODE,
  IREN_SVC_CODE,
  SOUDANSIEN_SVC_CODE,
  SYOKUJI_SVC_CODE,
  jihatsuKasan,
  serviceNameBase,serviceNameBaseHD, jihatsuService,
} from './BlCalcData';
import { Phone, TrendingUp } from '@material-ui/icons';
import { jssPreset } from '@material-ui/styles';
import { Juushingata, KateiRenkei, SoudanShien } from '../common/AddictionFormParts';
import { faLess } from '@fortawesome/free-brands-svg-icons';
import * as amdcm from '../../albCommonModule';
import { faBullseye, faSleigh } from '@fortawesome/free-solid-svg-icons';

const ptn = /^D[0-9]+[0-9]+/; // D20xxmmddを検索するためのパターン
const tokubetuItemSc = '63ZZ01'; // 令和３年９月３０日までの上乗せ分（放デイ）
const tokubetuItemScJH = '61ZZ01'; // 令和３年９月３０日までの上乗せ分（放デイ）
// サービスの基本アイテム
// 共生型サービスや条件デイなどはあとから追加していく
// const serviceNameBase2018 = [
//   { wd: '放デイ１', ku: '区分１の１', min: 0, max: 10, hd: '放デイ４' },
//   { wd: '放デイ２', ku: '区分１の１', min: 11, max: 20, hd: '放デイ５' },
//   { wd: '放デイ３', ku: '区分１の１', min: 21, max: 999, hd: '放デイ６' },
//   { wd: '放デイ２１', ku: '区分１の２', min: 0, max: 10, hd: '放デイ４' },
//   { wd: '放デイ２２', ku: '区分１の２', min: 11, max: 20, hd: '放デイ５' },
//   { wd: '放デイ２３', ku: '区分１の２', min: 21, max: 999, hd: '放デイ６' },
//   { wd: '放デイ２４', ku: '区分２の１', min: 0, max: 10, hd: '放デイ３０' },
//   { wd: '放デイ２５', ku: '区分２の１', min: 11, max: 20, hd: '放デイ３１' },
//   { wd: '放デイ２６', ku: '区分２の１', min: 21, max: 999, hd: '放デイ３２' },
//   { wd: '放デイ２７', ku: '区分２の２', min: 0, max: 10, hd: '放デイ３０' },
//   { wd: '放デイ２８', ku: '区分２の２', min: 11, max: 20, hd: '放デイ３１' },
//   { wd: '放デイ２９', ku: '区分２の２', min: 21, max: 999, hd: '放デイ３２' },
// ];


// 上限管理のオブジェクト。予め定義しておく
const JOUGEN_KANRI_HD = houdayKasan.filter(e => e.s === '635370')[0];
const JOUGEN_KANRI_JH = jihatsuKasan.filter(e => e.s === '615370')[0];
export const JOUGEN_KANRI = {
  '放課後等デイサービス': JOUGEN_KANRI_HD,
  '児童発達支援':JOUGEN_KANRI_JH,
}
// 医療連携体制加算絡みのサービスコードを返す
const iryouRenkei = () => {
  const r = [
    ...houdayKasan.filter(e=>e.name==='医療連携体制加算'),
    ...jihatsuKasan.filter(e=>e.name==='医療連携体制加算')
  ];
  const s = r.map(e=>e.s);
  return s;
}

const yokohama5Digit = [
  '14100','14101','14102','14103','14104','14105','14106','14107',
  '14108','14109','14110','14111','14112','14113','14114','14115',
  '14116','14117','14118',
]

// 横浜市かどうか
export const isYokohama = (scode) => {
  const v = yokohama5Digit.find(e=>e === scode.slice(0, 5));
  return v !== undefined;
}

// csv作成用 定義する文字列や置換用シンボル
const REC_NO = 'REC_NO';
const REC_CNT = 'REC_CNT';
const JI_NO = 'JI_NO';
const H_NO = 'H_NO'; // 被保険者番号
const JOSEIJICHITAI = 'HOSEIJICHITAI'; // 助成自治体番号
const TOTAL_AMOUNT = 'TOTAL_AMOUNT';  // 費用合計
const TOTAL_COUNT = 'TOTAL_COUNT'     // 件数合計
const TOTAL_BILLED = 'TOTAL_BILLED';  // 国保連請求
const TOTAL_BILLED1 = 'TOTAL_BILLED1';  // 総費用額 自治体請求を含む
const TOTAL_USER_BILLED = 'TOTAL_USER_BILLED';   // 利用者請求
const TOKUBETSU_TAISAKU = 0;   // K112_1 特別対策費
const TOTAL_TANNI = 'TOTAL_TANNI' // 単位数合計
const SRVC_AMOUNT = 'SRVC_AMOUNT';  // サービス毎費用合計
const SRVC_COUNT_TOTAL = 'SRVC_COUNT_TOTAL'     // サービス毎件数合計
const SRVC_BILLED = 'SRVC_BILLED';  // サービス毎国保連請求
const SRVC_USER_BILLED = 'SRVC_USER_BILLED';   // サービス毎利用者請求
const SRVC_TANNI_TOTAL = 'SRVC_TANNI_TOTAL' // サービス毎単位数合計
const SCITY_NO = 'SCITY_NO' // 市区町村番号
const START_DATE = 'START_DATE' // サービス開始日
const END_DATE = 'END_DATE' // サービス終了日
const S_SYUBETSU = 'S_SYUBETSU'; // サービス種別 放デイ63 自発61
const CNT_USED = 'CNT_USED' // ユーザーごとの利用回数
const SRVC_CODE = 'SRVC_CODE'; // サービスコード
const SRVC_TANNI = 'SRVC_TANNI'; // サービス毎の単位数
const SRVC_COUNT = 'SRVC_COUNT'; // サービス提供回数
const SRVC_SANTEI = 'SRVC_SANTEI'; // サービス算定額
// 地域コード
// const CHIKI_CODE = chiikiKubun[com.addiction[service].地域区分];
const CHIKI_CODE = 'CHIKI_CODE';
const JOUGEN = 'JOUGEN' // 上限額
const JOUGEN_JI = 'JOUGEN_JI' // 上限管理事業所
const JOUGEN_RES = 'JOUGEN_RES' // 上限管理結果額
const JOUGEN_GETSU_TYOUSEI = 'JOUGEN_GETSU_TYOUSEI'; // 上限管理結果額
const JOUGEN_KETTEI = 'JOUGEN_KETTEI'; // 上限決定額
const JOUGEN_TYOUSEIGO = 'JOUGEN_TYOUSEIGO'; // 上限調整後金額
const JOUGEN_KEKKA = 'JOUGEN_KEKKA' // 上限管理結果のフラグ
const USER_TANNI = 'USER_TANNI' // ユーザーごとの単位数
const NAME = 'NAME' // 利用者の名前半角カナ
const PNAME = 'PNAME' // 保護者の名前半角カナ
const SYUUKEI_BUNRUI = 1 // 集計分類番号 基本1だが重心で2になることも
const GETSUGAKU_TYOUSEI = 'GETSU_TY'; // 上限月額調整額 一割と上限を比較
 // 調整後利用者負担。福祉ソフトでは未設定 
const TYOUSEIGO_USER_BILLED = 'TYOUSEIGO_USER_BILLED';
const JOUGEN_USER_BILLED = 'J_USER_BIL'; // 上限額管理後利用者負担額を設定
const KETTEI_USER_BILLED = 'K_USER_BILLED'// 決定利用者負担額
const KETTEI_TOTAL_BILLED = 'K_TOTAL_BILLED' // 決定給付請求額
const KOUGAKU_KYUUFU = 'KOUGAKU_KYUUFU' // 高額障害児給付費
const TOKUBETSU_TAISAKU_K122 = 'TOKUBETSU_TAISAKU' // 特別対策費
const JICHITAI_JOSEI_SEIKYUU = 'JICHITAI_JOSEI_SEIKYUU' // 自治体助成請求額
const JICHITAI_JOSEI = 'JICHITAI_JOSEI' // 自治体助成分請求
const KETTEI_SRVC_CODE = 'KTTEI_SRVC_CODE' // 決定サービスコード
const KEIYAKU_VOL = 'KEIYAKU_VOL' // 契約量*100
const KEIYAKU_DATE = 'KEIYAKU_DATE' // 契約日
const KEIYAKU_END = 'KEIYAKU_END' // 契約終了日
const KINYUU_BANGOU = 'KINYUU_BANGOU' // 事業者記入欄番号 
const ICHIWARI1 = 'ICHIWARI1' // 一割相当額 よくわからない
const ICHIWARI2 = 'ICHIWARI2' // 一割相当額 よくわからない
const KYUUFU_TANKA = 'KYUUFU_TANKA' // 給付単価。サービス種別と級地で決まる 
const THIS_MONTH = 'THIS_MONTH';
const HEAD_SYUBETU = 'HEAD_SYUBETU'; // ヘッダレコードのレコード種別
// 上限管理ファイルで他事業所の請求額を含めて合算したもの
const ALL_AMOUNT = 'ALL_AMOUNT';
// 上限管理ファイルで他事業所の調整額=上限値と一割で金額が低い方を合計
const ALL_TYOUSEI = 'ALL_TYOUSEI';
// 上限管理ファイルで他事業所の利用者請求額を合算したもの
const ALL_JOUGEN = 'ALL_JOUGEN';
const SAKUSEI_KU = 'SAKUSEI_KU'; // 作成区分
const LINE_NO = 'LINE_NO'; // 項番
const KYO_JI = 'KYO_JI';

// 事業所内相談支援
// const SOUDAN_SVC_CODE = ['636805',];


// レコードのテンプレート
const headRec = [ // ヘッダレコード
  1, REC_NO, 0, REC_CNT, HEAD_SYUBETU, 0, JI_NO, 0, 1, THIS_MONTH, 0
];

const jgKihonK411_1 = [ // 上限管理基本
  2, REC_NO, 'K411', '01', THIS_MONTH, SAKUSEI_KU, SCITY_NO, 
  JI_NO, H_NO, PNAME, NAME,
  JOUGEN, JOUGEN_KEKKA, ALL_AMOUNT, ALL_TYOUSEI, ALL_JOUGEN,
]
const jgMeisaiK411_2 = [ // 上限管理明細
  2, REC_NO, 'K411', '02', THIS_MONTH, SCITY_NO, JI_NO, H_NO, LINE_NO,
  KYO_JI, TOTAL_AMOUNT, JOUGEN_TYOUSEIGO, JOUGEN_KETTEI
]

const kihonK112_1 = [ // K112基本 市区町村ごと合計行
  2, REC_NO, 'K112', '01', THIS_MONTH, SCITY_NO, JI_NO, 
  TOTAL_BILLED1, TOTAL_COUNT, TOTAL_TANNI, 
  TOTAL_AMOUNT, TOTAL_BILLED, TOKUBETSU_TAISAKU, TOTAL_USER_BILLED, 
  JICHITAI_JOSEI, 0, 0, 0,
  TOTAL_COUNT, TOTAL_TANNI, TOTAL_AMOUNT, TOTAL_BILLED, TOKUBETSU_TAISAKU,
  TOTAL_USER_BILLED, JICHITAI_JOSEI,
];
const meisaiK112_2 = [ // K112明細 サービス種別毎の明細。自発だと別レコード
  2, REC_NO, 'K112', '02', THIS_MONTH, SCITY_NO, JI_NO, 1, S_SYUBETSU,
  SRVC_COUNT_TOTAL, SRVC_TANNI_TOTAL, SRVC_AMOUNT, SRVC_BILLED, 0,
  SRVC_USER_BILLED, JICHITAI_JOSEI,
];
const kihonK122_1 = [ // k122基本レコード
  2, REC_NO, 'K122', '01', THIS_MONTH, SCITY_NO, JI_NO, H_NO, 
  JOSEIJICHITAI, // 助成自治体番号
  PNAME, NAME,
  CHIKI_CODE, '', JOUGEN, '', '', JOUGEN_JI, JOUGEN_KEKKA, JOUGEN_RES,
  '', '', USER_TANNI, TOTAL_AMOUNT, 
  JOUGEN_GETSU_TYOUSEI, // 上限月額調整額
  '', '', '', 
  JOUGEN_TYOUSEIGO, // 上限調整後金額 
  JOUGEN_KETTEI, // 上限決定後
  TOTAL_BILLED, // 給付費
  KOUGAKU_KYUUFU, 
  TOKUBETSU_TAISAKU_K122,
  JICHITAI_JOSEI_SEIKYUU,
  '','','','',
]
const nissuuK122_2 = [ // k122日数情報レコード
  2, REC_NO, 'K122', '02', THIS_MONTH, SCITY_NO, JI_NO, H_NO, S_SYUBETSU,
  START_DATE, END_DATE, CNT_USED, '', '', 
];

const serviceMeisaiK122_3 = [ // k122 明細レコード サービスコードを記載
  2, REC_NO, 'K122', '03', THIS_MONTH, SCITY_NO, JI_NO, H_NO, SRVC_CODE,
  SRVC_TANNI, SRVC_COUNT, SRVC_SANTEI, '', 
]

const syuukeiK122_4 = [
  2, REC_NO, 'K122', '04', THIS_MONTH, SCITY_NO, JI_NO, H_NO, S_SYUBETSU,
  SYUUKEI_BUNRUI,
  SRVC_COUNT, // サービス回数 
  USER_TANNI, // 単位数
  KYUUFU_TANKA, // 給付単価。サービス種別と級地で決まる 
  0, // 給付率 0固定
  TOTAL_BILLED, // 単位数✕給付単価
  ICHIWARI1, // 一割相当額
  ICHIWARI2, // 一割相当額、都道府県が絡んだり自発で多子だと変わる？
  GETSUGAKU_TYOUSEI, // 上限月額調整額 一割と上限を比較
  '', '', // 未設定項目＊２
  TYOUSEIGO_USER_BILLED, // 調整後利用者負担。
  JOUGEN_USER_BILLED, // 上限額管理後利用者負担額を設定
  KETTEI_USER_BILLED,// 決定利用者負担額
  KETTEI_TOTAL_BILLED, // 決定給付請求額
  KOUGAKU_KYUUFU,// 高額給付費
  TOKUBETSU_TAISAKU_K122, // 特別対策費
  JICHITAI_JOSEI, // 自治体助成
  '', '', '', '', '', '', '', '', 
]

const keiyakuK122_5 = [ // 契約情報レコード
  2, REC_NO, 'K122', '05', THIS_MONTH, SCITY_NO, JI_NO, H_NO,
  KETTEI_SRVC_CODE, // 決定サービスコード
  KEIYAKU_VOL, // 契約量*100
  KEIYAKU_DATE, // 契約日
  KEIYAKU_END, // 契約終了日
  KINYUU_BANGOU, // 事業者記入欄番号 
];
const endRecord = [
  3, REC_NO
]
const zero2blank = (v) => (v === 0)? '': v;
const fdp = (obj, path) => comMod.findDeepPath(obj, path);

// 決定サービスコードの取得
// 医療ケアと障害種別を考慮する。
const getKetteiSeriviceCode = (prms) => {
  const {uid, users, schedule} = prms;
  const userType = comMod.getUser(uid, users).type;
  const uService = comMod.getUser(uid, users).service;
  const usersIcare = comMod.null2Zero(fdp(
    schedule, [uService, uid, 'addiction', '医療ケア児基本報酬区分']
  ));
  let selected = ketteiScode.filter(e=>e.service === uService);
  selected = selected.filter(e=>e.iCare === parseInt(usersIcare));
  selected = selected.filter(e=>e.type === userType);
  if (!selected.length){
    return ('000000')
  }
  else{
    return (selected[0].kettei);
  }
}
// 処遇改善と特別加算のサービスコードを取得する
const getSvcCdSyoguuTokubetu = () => {
  const a = houdayKasan.filter(e=>e.syoguu).map(e=>e.s);
  const b = jihatsuKasan.filter(e=>e.syoguu).map(e=>e.s);
  const c = houdayKasan.filter(e=>e.tokubetsu).map(e=>e.s);
  const d = jihatsuKasan.filter(e=>e.tokubetsu).map(e=>e.s);
  return [...a, ...b, ...c, ...d];
}

// 加算などのオブジェクトからサービスコードのキーになる文字列を配列にして返す
// 配列に格納される文字
// 放デイ
// 開減１,開減２,拘減,人欠,人欠２,責欠,責欠２,定超,評減,未計画,未計画２,有資格X
// 児発
// 評価減,開所減１,開所減２,未計画,未計画２,地公体,定超,人欠,人欠２,責欠,責欠２,
// 放デイと児発で微妙に表現が違う。
// 放デイの表現で統一して児発は後から内容を変更する
// こいつらが違う-> 評価減,開所減１,開所減２
const getKeyStrOfServiceItems = (adc, offSchool) => {
  const rt = [];
  const targetKey = [
    '開所時間減算',
    '身体拘束廃止未実施減算',
    'サービス提供職員欠如減算',
    '児童発達支援管理責任者欠如減算',
    '定員超過利用減算',
    '自己評価結果等未公表減算',
    '通所支援計画未作成減算',
    '児童指導員配置加算',
    '地方公共団体',
  ];
  const pi = (v) => {
    if (isNaN(v)) return "";
    else return parseInt(v);
  }
  Object.keys(adc).map(e => {
    if (targetKey.indexOf(e) === -1) return false;
    if (e === '開所時間減算' && adc[e] === '4時間未満' && offSchool)
      rt.push('開減１・');
    // 開所時間減算は休日利用だけ該当
    if (e === '開所時間減算' && adc[e] === '4時間以上6時間未満' && offSchool)
      rt.push('開減２・');
    // 2021削除
    // if (e === '身体拘束廃止未実施減算' && pi(adc[e]) === 1)
    //   rt.push('拘減・');
    if (e === 'サービス提供職員欠如減算' && adc[e] === '二ヶ月まで')
      rt.push('人欠・');
    if (e === 'サービス提供職員欠如減算' && adc[e] === '三ヶ月以上')
      rt.push('人欠２・');
    if (e === '児童発達支援管理責任者欠如減算' && adc[e] === '五ヶ月未満')
      rt.push('責欠・');
    if (e === '児童発達支援管理責任者欠如減算' && adc[e] === '五ヶ月以上')
      rt.push('責欠２・');
    if (e === '定員超過利用減算' && pi(adc[e]) === 1)
      rt.push('定超・');
    if (e === '自己評価結果等未公表減算' && pi(adc[e]) === 1)
      rt.push('評減・');
    if (e === '通所支援計画未作成減算' && adc[e] === '3ヶ月未満')
      rt.push('未計画・');
    if (e === '通所支援計画未作成減算' && adc[e] === '3ヶ月以上')
      rt.push('未計画２・');
    if (e === '地方公共団体' && pi(adc[e]) === 1)
      rt.push('地公体・');
    // 2021削除
    // if (e === '児童指導員配置加算' && pi(adc[e]) === 1)
    //   rt.push('有資格');
  });
  return rt;
}

// 配列から特定の文字列を検索して置換する
// const elmRep = (a, str, value) => {
//   const ndx = a.indexOf(str);
//   if (ndx === -1) return false;
//   a[ndx] = value;
//   return true;
// }
// 要素が複数あったときにも対応
const elmRep = (a, str, value) => {
  while (true){
    let ndx = a.indexOf(str);
    if (ndx === -1) return false;
    a[ndx] = value;
  }
}

// 配列の最後の配列から特定の文字を検索して置換する
const erl = (a, str, value) =>{
  elmRep(a[a.length - 1], str, value);
}

// 18歳以上の処理を行う
const convNameOver18 = (schTmp, users) => {
  Object.keys(schTmp).forEach(e=>{
    const u = comMod.getUser(e, users);
    if (u.age >= 18){
      const name = schTmp[e].name;
      const kana = schTmp[e].kana;
      schTmp[e].pkana = kana;
      schTmp[e].pname = name;
      schTmp[e].name = '';
      schTmp[e].kana = '';
    }
  });
}


// 加算アイテムを特定するためのローダー
// pTypeはもともとのユーザーのタイプが与えられることを想定している。
// 施設のパラメータにより重心や難聴児を受け入れるか判断を行う
// kessekiSvcに定義されているオブジェクトは欠席時でも処理を行う
const getKasanItem = (
  addiction, offSchool, teiin, kubun, absence, service, type
) => {
  const kasanNames = getKasanUniqName();
  const rt = [];
  const kessekiSvc = [
    '欠席時対応加算', '事業所内相談支援加算', '家庭連携加算',
    '特定処遇改善加算', '福祉・介護職員処遇改善加算', '福祉・介護職員処遇改善特別加算'
  ]
  Object.keys(addiction).map(e => {
    // 欠席指定がある場合、欠席対応加算以外は無視する
    if (absence && kessekiSvc.indexOf(e) === -1)  return false;
    // 対象外の値は読み飛ばし
    if (kasanNames.indexOf(e) === -1) return false;
    let thisItem;
    if (service === '放課後等デイサービス'){
      thisItem = getKasanOneItem(e, addiction, offSchool, teiin, type, kubun);
    }
    else if (service === '児童発達支援'){
      thisItem = getKasanOneItemJH(e, addiction, teiin, type);
    }
    if (thisItem.err) console.log(thisItem, 'errItem');
    rt.push(thisItem);
  });
  return rt;
}


// 加算の内容とサービス名のベース、休日かどうかなどの情報から
// サービスコードを含むオブジェクトを取得して返す
// 欠席ならnullを返す
// 放課後等デイサービス用専用モジュールに変更
const getBaseItemHD = (nameBase, addiction, offSchool, absence) => {
  if (absence)  return null // 欠席の処理
  // 休日か平日かで検索用サービス名称ベースを変える
  const nbStr = (!offSchool) ? nameBase.wd : nameBase.hd + '・';
  // まずはベースになるサービス名で絞り込み
  let thisServece = houdySirvice.filter(e => e.c.indexOf(nbStr) === 0);
  // 加算オブジェクトに対応した文字配列を取得する
  const keyStrs = getKeyStrOfServiceItems(addiction, offSchool);
  // 全てのキーオブジェクトに対して絞り込みを行う
  keyStrs.map(e => {
    thisServece = thisServece.filter(f => f.c.indexOf(e) > -1);
  });
  // 絞り込みを行った結果、thisServece[x].cの中のデリミタ"・"が
  //　一番少ないやつがビンゴ
  let pos = 0; let minLen = 100;
  thisServece.map((e, i) => {
    const cnt = (e.c.match(/・/g) || []).length;
    pos = (minLen > cnt) ? i : pos;
    minLen = (minLen > cnt) ? cnt : minLen;
  });
  return thisServece[pos];
}
const getBaseItemJH = (nameBase, addiction, absence) => {
  if (absence)  return null;
  const nbStr = nameBase.name;
  let keyStrs = getKeyStrOfServiceItems(addiction, false);
  // 放デイと児発で表現が違うので訂正する
  const jhConv = [
    ['評減・', '評価減・'],
    ['開減１・', '開所減１・'],
    ['開減２・', '開所減２・'],
  ];
  keyStrs = keyStrs.map(e=>{
    jhConv.map(f=>{
      if (e === f[0]) return f[1];
      else return e;
    });
  });
  // ベース名で絞り込み
  let thisServece = jihatsuService.filter(e=>e.c.indexOf(nbStr) === 0);
  // ベースに対するオプションで絞り込み
  keyStrs.map(e=>{
    thisServece = thisServece.filter(f => f.c.indexOf(e) > -1);
  });
  // 絞り込みを行った結果、thisServece[x].cの中のデリミタ"・"が
  //　一番少ないやつがビンゴ
  let pos = 0; let minLen = 100;
  thisServece.map((e, i) => {
    const cnt = (e.c.match(/・/g) || []).length;
    pos = (minLen > cnt) ? i : pos;
    minLen = (minLen > cnt) ? cnt : minLen;
  });
  return thisServece[pos];
}

// ベースアイテム所得用のローダ
// 放デイと児発で処理を切り替える
const getBaseItem = (nameBase, addiction, offSchool, absence, service) => {
  if (service === '放課後等デイサービス'){
    return(
      getBaseItemHD(nameBase, addiction, offSchool, absence)
    );
  }
  else if (service === '児童発達支援'){
    return(
      getBaseItemJH(nameBase, addiction, absence)
    );
  }
}
// 送迎を求める。児発
// dObj = 日付ごとのschTmpのオブジェクト
const getTrasferItemJH = (dObj, UID, users) => {
  const thisUser = comMod.getUser(UID, users);
  const type = thisUser.type; // 重症心身障害児、難聴など
  const absence = dObj.absence;
  const transfer = dObj.transfer;
  const juushinGata = dObj.dAddiction.重症心身型;
  const rt = [];
  // 欠席や送迎なし
  if (!transfer) return rt;
  if (!transfer.length) return rt;
  if (absence) return rt;
  if (type === '重症心身障害児' && juushinGata){
    if (transfer[0]){
      rt.push(jihatsuKasan.find(e=>e.s==="616241"));
    }
    if (transfer[0]){
      rt.push(jihatsuKasan.find(e=>e.s==="616241"));
    }
  }
  else{
    if (transfer[0]){
      rt.push(jihatsuKasan.find(e=>e.s==="616240"));
    }
    if (transfer[1]){
      rt.push(jihatsuKasan.find(e=>e.s==="616240"));
    }
  }
  return rt;
}
// 加算項目の送迎を求める
const getTrasferItem = (transfer, absence, type) => {
  const rt = [];
  // オブジェクトがなかったり配列が空だったりしたら空白の配列を返す
  if (!transfer) return rt;
  if (!transfer.length) return rt;
  // 欠席のときも空配列
  if (absence) return rt;
  const transferItemN = houdayKasan.find(e => e.s === '636240');
  const transferItemJ = houdayKasan.find(e => e.s === '636241');
  // transferは配列で中身があれば送迎有りと判断。両方送迎なら二回アイテムを
  // プッシュする
  if (type !== "重症心身障害児") {
    if (transfer[0]) rt.push(transferItemN);
    if (transfer[1]) rt.push(transferItemN);
  }
  else {
    if (transfer[0]) rt.push(transferItemJ);
    if (transfer[1]) rt.push(transferItemJ);
  }
  return rt;
}

// 明細作成用のScheduleオブジェクトを市区町村順でソートして配列化
const schTmpToArray = (src, userlist) => {
  const scitySet = new Set(); // Uniqueな市区町村番号を作成する
  Object.keys(src).map(e => {
    if (!checkUidFromList(userlist, e)){
      return false;
    }
    scitySet.add(src[e].scityNo);
  });
  // セットを配列化してソート
  const scityArray = Array.from(scitySet);
  scityArray.sort((a, b) => (a - b));
  // ソートされた配列の順に新しいオブジェクトに値をCOPY
  const rt = [];
  scityArray.map(e => {
    Object.keys(src).map(f => {
      if (!checkUidFromList(userlist, f)){
        return false;
      }

      if (src[f].scityNo === e) {
        // 利用件数ゼロは削除する ---> 削除しないようにする 2021/09/06
        const count = src[f].countOfUse + src[f].countOfKesseki;
        // if (!count) return false;
        src[f]['UID'] = f; // キーだったuidをオブジェクトの中に入れる
        rt.push(src[f]);
      }
    })
  });
  return rt;
}

// 加算項目を特定するために加算オブジェクトのUniqueなキー値を得る
// houdayKasanから得られるnameを一致させてある
// そのUnique値を取得する
// 児発の名前も加えておく。
const getKasanUniqName = () => {
  const kasanSet = new Set();
  houdayKasan.map(e => {
    kasanSet.add(e.name);
  });
  jihatsuKasan.map(e=>{
    kasanSet.add(e.name);
  });
  return Array.from(kasanSet);
};

// 児発用加算アイテム取得
const getKasanOneItemJH = (key, addiction, teiin, type) => {
  const value = addiction[key];
  const errObj = { err: 'item found err.', key, value };
  let kasan = jihatsuKasan.filter(e => (e.name === key && e.value === value));
  // これだけで特定できたらそのままリターン
  if (kasan.length === 1) return kasan[0];
  // この時点で見つからなければエラー
  if (!kasan.length) return errObj;
  // センターかどうかで絞り込み
  // センターの場合、事業所コードをオミット
  if (addiction.児童発達支援センター === '1'){
    kasan = kasan.filter(e=>{
      const opts = e.opt.split(',');
      if (opts.indexOf('事業所') === -1) return true;
    })
  }
  // センターではない場合、センターのコードをオミット
  else{
    kasan = kasan.filter(e=>{
      const opts = e.opt.split(',');
      if (opts.indexOf('センター') === -1) return true;
    })
  }
  if (kasan.length === 1) return kasan[0];
  if (!kasan.length) return errObj;
  // 就学区分が設定されている場合、それ以外をオミット
  if (addiction.就学区分 === '1'){
    kasan = kasan.filter(e=>{
      const opts = e.opt.split(',');
      if (opts.indexOf('主に未就学児以外') === -1) return true;
    })
  }
  // 未設定なら…
  else{
    kasan = kasan.filter(e=>{
      const opts = e.opt.split(',');
      if (opts.indexOf('主に未就学児') === -1) return true;
    })
  }
  if (kasan.length === 1) return kasan[0];
  if (!kasan.length) return errObj;
  // 障害タイプによる絞り込み
  // ロジックが美しくない(T_T)
  kasan = kasan.filter(e=>{
    const opts = e.opt.split(',');
    if (type === '難聴児'){
      if (opts.indexOf('重症心身障害児') > -1)  return false;
      else if (opts.indexOf('障害児') > -1)  return false;
      else return true;
    }
    else if (type === '障害児'){
      if (opts.indexOf('重症心身障害児') > -1)  return false;
      else if (opts.indexOf('難聴児') > -1)  return false;
      else return true;
    }
    else if (type === '重症心身障害児'){
      if (opts.indexOf('障害児') > -1)  return false;
      else if (opts.indexOf('難聴児') > -1)  return false;
      else return true;
    }
    else return true;
  });
  if (kasan.length === 1) return kasan[0];
  if (!kasan.length) return errObj;
  // オプションから定員を取得 定員がない場合は200(上限値)を設定
  kasan.map(e=>{
    const opts = e.opt.split(',');
    const teiinFld = opts.find(f=>/n[0-9]+/.test(f)); // 定員が記述されている要素
    if (teiinFld){
      const teiin = parseInt(teiinFld.replace(/[^0-9]/g, ''));
      e.teiin = teiin;
    }
    else e.teiin = 200;
  });
  // 定員による絞り込み
  kasan.filter(e=>e.teiin>=teiin);
  if (kasan.length === 1) return kasan[0];
  if (!kasan.length) return errObj;
  // 定員順にソート
  kasan.sort((a, b)=>(a.teiin - b.teiin));
  // 最初の要素がビンゴ
  return kasan[0];
}

const getKasanOneItem = (key, addiction, offSchool, teiin, type, kubun) => {
  // if (key.indexOf('欠席') > -1){
  //   console.log(key);
  // }
  const value = addiction[key];
  const errObj = { err: 'item found err.', key, value };
  // 家庭連携加算だけ別に処理
  if (key === '家庭連携加算'){
    // valueに分数が入っているのでそれでフィルタ
    const t = houdayKasan.filter(e=>(
      e.name === key && parseInt(e.value) <= parseInt(value)
    ));
    // 複数要素が格納されていることがあるのでソート
    t.sort((a, b)=>(a.value < b.value? 0 : -1));
    return t[0];
  }
  // まずはキーとバリューで絞り込み
  let kasan = houdayKasan.filter(e => (e.name === key && e.value === value));
  // これだけで特定できたらそのままリターン
  if (kasan.length === 1) return kasan[0];
  // この時点で見つからなければエラー
  if (!kasan.length) return errObj;
  // 平日は0なのでこの式で検索文字列を得る
  const schoolOffStr = (offSchool) ? '休日・' : '平日・';
  // 休日なら区分strは無効にする
  const kubunStr = (offSchool) ? '・' : kubun
  let teiinStr;
  if (type !== "重症心身障害児") {
    if (teiin <= 10) teiinStr = "10人以下・";
    else if (teiin <= 20) teiinStr = "11から20・";
    else teiinStr = '21人以上・';
  }
  else {
    if (teiin > 10) teiinStr = "11以上・";
    else teiinStr = teiin + '・';
  }
  // 絞り込まれた配列の中で一番長いオプションを調べる
  let longestOpt = 0;
  kasan.map(e => {
    longestOpt = (longestOpt < (e.opt.match(/・/g) || []).length)
      ? (e.opt.match(/・/g) || []).length : longestOpt;
  });

  // 欠席時対応加算の例外処理
  if (key === '欠席時対応加算'){
    let kesseki;
    if (kubun === '重心'){
      kesseki = kasan.filter(e=>e.opt.indexOf('重心') > -1);
      if (kesseki.length === 1) return kesseki[0];
    }
    else{
      kesseki = kasan.filter(e=>e.opt.indexOf('重心') === -1);
      if (kesseki.length === 1) return kesseki[0];
    }
  }

  // 最長オプションが3以下の場合は一点ずつ調べて件数が一件になったらリターン
  // それでも2件以上が見つかる場合は今のところエラーを返す
  // オプションが2件の時がある場合は再度、このルーチンを見直す
  if (longestOpt < 3) {
    let shortOpt;
    shortOpt = kasan.filter(e => e.opt.indexOf(schoolOffStr) > 1);
    if (shortOpt.length === 1) return shortOpt[0];
    else if (shortOpt.length > 1) return errObj;
    shortOpt = kasan.filter(e => e.opt.indexOf(kubunStr) > 1);
    if (shortOpt.length === 1) return shortOpt[0];
    else if (shortOpt.length > 1) return errObj;
    shortOpt = kasan.filter(e => e.opt.indexOf(teiinStr) > 1);
    if (shortOpt.length === 1) return shortOpt[0];
    else if (shortOpt.length > 1) return errObj;
    // それでも見つからないときはoptが空白のものを返す
    shortOpt = kasan.filter(e => !e.opt);
    if (shortOpt.length === 1) return shortOpt[0];
    else if (shortOpt.length > 1) return errObj;
  }
  // オプションで絞り込み
  kasan = kasan.filter(e => (
    e.opt.indexOf(schoolOffStr) > -1 &&
    e.opt.indexOf(kubunStr) > -1 &&
    e.opt.indexOf(teiinStr) > -1
  ));
  // 一件以外の検索結果はエラーとする
  kasan = (kasan.length !== 1) ? errObj : kasan[0];
  return (kasan);
}

// 上限加算サービスアイテムを取得するかどうか判断する
// スケジュールオブジェクトの上限加算を判定して強制だったら真
// 未設定なら偽、
// 自動ならautojougenを返す
// 
// UID は UIDxxx形式
// const isJougen = (schedule, UID, autoJougen, service) => {
//   const fdp = (obj, path) => comMod.findDeepPath(obj, path);
//   const ku = 0; // 0: auto 1:force 2:not
//   const thisJougen = fdp(
//     schedule, [service, UID, 'addiction', '利用者負担上限額管理加算']
//   );
//   // 上限額管理加算が見つかったらその内容のとおりに処理
//   if (thisJougen === '手動'){
//     return true;
//   }
//   else if (thisJougen === 'off'){
//     return false;
//   }
//   else return autoJougen;

// } 
// // 上限管理を行った上でその結果を見て上限管理加算を設定するかどうかの判断を行う
// const addJougenkanriKasan = (schTmp, schedule) => {
//   Object.keys(schTmp).map(e=>{
//     const o = schTmp[e];
//     let jougenItem;
//     if (o.service === '放課後等デイサービス') jougenItem = JOUGEN_KANRI_HD;
//     else if (o.service === '児童発達支援')  jougenItem = JOUGEN_KANRI_JH;
//     jougenItem.count = 1;
//       // 自動設定時の上限管理判定 kanriOkで管理結果が1または3
//     const autoJougen = (o.kanriOk && ([1,3].indexOf(o.kanriKekka) > -1));
//     if (isJougen(schedule, e, autoJougen, o.service)){
//       o.itemTotal.push(jougenItem);
//     }
//   });
// }

// 上限管理加算を判断して付与するべきなら上限管理加算アイテムを返す
// 手動の場合は無条件付与
const judgeJougenkanriKasan = (users, uid, schedule) => {
  const fdp = (o, k) => comMod.findDeepPath(o, k);
  const thisUser = comMod.getUser(uid, users);
  const service = thisUser.service;
  const kanriType = thisUser.kanri_type;
  const brosIndex = parseInt(thisUser.brosIndex);
  // if (kanriType !== '管理事業所' && brosIndex !== 1) return false;
  if (kanriType !== '管理事業所' && parseInt(brosIndex) !== 1) return false;
  // スケジュールオブジェクトから指定された上限額管理を取得する。
  // 長兄は手動による設定を見る
  const jSpecified = fdp(
    schedule, [service, uid, 'addiction', '利用者負担上限額管理加算']
  );
  // 手動でのoff
  if (jSpecified === 'off'){
    return false;
  }
  // 手動での強制設定
  else if (jSpecified === '手動'){
    return JOUGEN_KANRI[service];
  }
  // 管理事業所以外はここで終了
  if (kanriType !== '管理事業所') return false;
  // 以降は自動設定
  // 利用があるか確認
  const uSch = schedule[uid];
  let cnt = 0;
  if (uSch){
    Object.keys(uSch).map(e=>{
      if (e.indexOf('D2') !== 0)  return false;
      if (e.absence && !fdp(e, 'dAddiction.欠席時対応加算'))  return false;
      cnt++;
    });

  }
  // 協力事業所の利用実績があるか確認
  let amount = 0;
  if (uSch){
    if (Array.isArray(uSch.協力事業所)){
      uSch.協力事業所.forEach(e=>{
        amount += (e.amount)? parseInt(e.amount): 0;
      });
    }
  }
  if (cnt || amount)  return JOUGEN_KANRI[service];
}

// 計算用オブジェクトのitemsをユーザーごとに集計する
// クラスルーム別の売上も求められるようにする
const totalizeItems = (tmpSch, masterRec, users, schedule, classroom = '')=>{
  // 欠席でも有効なサービスコード
  
  const kessekiSvc = [
    ...KESSEKI_SVC_CODE, ...SOUDANSIEN_SVC_CODE, ...KATEI_SVC_CODE
  ];
  // 処遇と特別のサービスコード
  const syoguuTkbt = getSvcCdSyoguuTokubetu();
  kessekiSvc.push(...syoguuTkbt);

  Object.keys(tmpSch).map(e=>{
    const eachItem = [];  // itemを全部格納する配列
    const scodeSet = new Set(); // サービスコードのユニークな値を取得する
    // 上限管理加算を判断して加算
    const jougen = judgeJougenkanriKasan(users, e, schedule);
    if (jougen){
      eachItem.push(jougen);
      scodeSet.add(jougen.s);
    }
    Object.keys(tmpSch[e]).map(f=>{
      const thisUser = comMod.getUser(e, users);
      if (!f.match(ptn))  return false; //日付オブジェクトではない
      const o = tmpSch[e][f];
      const thisCls = (o.classroom)? o.classroom: thisUser.classroom;
      if (classroom && classroom !== thisCls) return false;
      o.items.map(g=>{
        // 単位判定 特定単位以外はスキップ
        // MTUはスケジュールオブジェクト内に該当単位を記述するルール。
        // これで特定できるはず
        // キャンセルかどうか確認
        // キャンセルだったら特定のコード意外はスキップ
        if (o.absence && kessekiSvc.indexOf(g.s) === -1)  return false;
        scodeSet.add(g.s);  // サービスコードを追加
        eachItem.push(g);
      });
    });
    // 配列化してソート
    const scodeArray = Array.from(scodeSet).sort((a, b) => (a - b));
    const itemTotal = scodeArray.map(f=>{
      const thisItem = eachItem.find(g=>g.s === f); // 該当アイテムを一個取得
      // 処遇改善、一回のみ場合は回数１で配列に追加
      if (thisItem.syoguu || thisItem.limit === 'once'){ 
        return ({...thisItem, count: 1});
      }
      // 0930特別加算
      else if (thisItem.tokubetsu){
        return ({...thisItem, count: 1});
      }
      else{
        // それ以外はitemは数を数える
        const count = eachItem.filter(g => g.s === f).length;
        return ({...thisItem, count})
      }
    });
    // baseItemのみの合計単位数
    let baseItemTotal = 0;
    itemTotal.filter(f=>f.baseItem)
      .map(f=>{baseItemTotal += f.count * f.v});
    // 記載されている特別加算のアイテムを取得
    let tokubetuNdx = itemTotal.findIndex(f=>f.s === tokubetuItemSc);
    // 放デイのアイテムが見つからない場合、児発のアイテムを取得をトライ
    tokubetuNdx = (tokubetuNdx === -1)?
    itemTotal.findIndex(f=>f.s === tokubetuItemScJH): tokubetuNdx;
    // 特別加算アイテムがあればベースアイテムのみで掛け率に応じて値設定
    if (tokubetuNdx > -1){
      itemTotal[tokubetuNdx].tanniNum = 
        Math.round(itemTotal[tokubetuNdx].v * 0.01 * baseItemTotal);
      itemTotal[tokubetuNdx].v = itemTotal[tokubetuNdx].tanniNum;
    }
    // ---------------------------------------- 処遇改善加算
    // 0930までの加算を含める
    // サービスごとの単位数と合計を求める
    let tanniTotal = 0;
    itemTotal.map(f=>{
      if (!f.syoguu){
        f.tanniNum = f.v * f.count;
        tanniTotal += f.v * f.count;
      };
    });
    // ユーザーごとの単位数の合計を算定し処遇改善加算を求める
    itemTotal.map(f => {
      if (f.syoguu) {
        // 処遇改善加算の値が数値だったらそのまま乗算
        if (!isNaN(f.v)){
          f.tanniNum = Math.round(tanniTotal * (f.v / 100));
        }
        // 値が文字列なら乗数を二段階に分ける
        // 放デイ処遇改善加算4, 5
        else {
          const [v1, v2] = f.v.split('*');
          let t1 = Math.round(tanniTotal * (parseFloat(v1) / 100));
          f.tanniNum = Math.round(t1 * parseFloat(v2));
        }
        f.vbk = f.v;  // f.vを退避
        f.v = f.tanniNum; // 単位数をここに代入 縦計算整えるため
      };
    });


    // 単位合計を再計算
    // 処遇改善加算が複数あるため別ループにする
    tanniTotal = 0;
    itemTotal.map(f =>{
      tanniTotal += f.tanniNum;
    })
    // 単位数に単価を乗算して四捨五入。サービスごと利用者ごとの請求額を求める
    let userSanteiTotal = 0;
    // console.log(e.UID);
    itemTotal.map(f =>{
      // f.santei = Math.round(masterRec.unitPrice * f.tanniNum);
      // userSanteiTotal += f.santei;
      // console.log(
      //   f.c + ',' + f.v + '*' + f.count + '*' + masterRec.unitPrice
      // );
    });
    // ソートを追加
    itemTotal.sort((a, b)=>{
      if (a.baseItem && !b.baseItem) return -1;
      if (!a.baseItem && b.baseItem) return 1;
      if (a.s > b.s) return 1;
      if (a.s < b.s) return -1;
    });
  
    // 通常処理
    if (!classroom){
      tmpSch[e].itemTotal = itemTotal;
      tmpSch[e].tanniTotal = tanniTotal;
      tmpSch[e].userSanteiTotal = 
      Math.floor((masterRec.unitPrice*100) * tanniTotal/100);
    }
    // 単位別売上
    else{
      if (!tmpSch[e].clsItemTotal) tmpSch[e].clsItemTotal = {};
      if (!tmpSch[e].clsTanniTotal) tmpSch[e].clsTanniTotal = {};
      if (!tmpSch[e].clsUserSanteiTotal) tmpSch[e].clsUserSanteiTotal = {};
      tmpSch[e].clsItemTotal[classroom] = itemTotal;
      tmpSch[e].clsTanniTotal[classroom] = tanniTotal;
      tmpSch[e].clsUserSanteiTotal[classroom] = 
      Math.floor((masterRec.unitPrice*100) * tanniTotal/100);
    }
  });
}
// 上限管理するよ！！！
const makeUpperLimit = (dt, stdDate) =>{
  // 管理事業所の場合の調整！
  const manegeThis = (udt) =>{
    if (!udt.協力事業所 && !udt.brosIndex)        return {result: false};
    if (!udt.協力事業所.length && !udt.brosIndex) return {result: false};
    let result = true;
    const haibun = udt.協力事業所[0].haibun;
    const jAry = udt.協力事業所;
    // 2022-06以前と以降で処理を変える
    if (haibun === '最多利用最大' && stdDate < '2022-06-01'){
      jAry.sort((a, b) => {
        return (parseInt(b.amount) - parseInt(a.amount))
      });
      // 先頭に自社を入れる。事業所番号に０を入れてソート後先頭になるようにする
      // 協力事業所を利用学が大きい順にソート
      jAry.unshift({
        name: 'thisOffice', amount: udt.userSanteiTotal, no: '0'
      });
    }
    else if (stdDate < '2022-06-01'){ // それ以外の場合
      // 協力事業所を利用額が大きい順にソート
      // 優先順位があったらそれを優先
      jAry.sort((a, b) => {
        if (!a.priority && b.priority) return 1;
        if (a.priority && !b.priority) return -1;
        return (parseInt(b.amount) - parseInt(a.amount))
      });
      // 先頭に自社を入れる。
      jAry.unshift({ name: 'thisOffice', amount: udt.userSanteiTotal, no: '0'});
    }
    else {
      // 2022-06 以降はソートを行わない
      // 先頭に自社を入れる。
      jAry.unshift({ name: 'thisOffice', amount: udt.userSanteiTotal, no: '0'});

    }
    const priceLimit = udt.priceLimitBk? udt.priceLimitBk: udt.priceLimit;
    let lessPrice = parseInt(priceLimit);
    // 兄弟管理がある場合、割付自己負担額を兄弟管理結果学とする
    lessPrice = (udt.kdLessPrice !== undefined)
    ? udt.kdLessPrice: lessPrice;
    const kdTyousei = (udt.kdTyousei)? udt.kdTyousei: 0;
    // 先頭から自己負担額を割り振る
    jAry.map(e=>{
      e.amount = parseInt(e.amount);
      // 一件でも数値が入っていなかったら管理結果をfalseにする
      if (isNaN(e.amount))  result = false;
      let ichiwari = Math.floor(e.amount * 0.1);
      // 多子軽減対応
      ichiwari = (dt.tashikeigen === 2)? Math.floor(e.amount * 0.05): ichiwari;
      ichiwari = (dt.tashikeigen === 3)? 0: ichiwari;
      let tyouseiGaku = (parseInt(priceLimit) < ichiwari)
        ? parseInt(priceLimit) : ichiwari;
      
      // 管理事業者以外は1割相当額を調整額に入れる？？ 福祉ソフトがそうしてる
      // if (e.name !== 'thisOffice'){
      //   tyouseiGaku = ichiwari;
      // }
      // 兄弟調整済みでthisofficeはここでは調整しない
      if (kdTyousei && e.name === 'thisOffice'){
        e.kettei = udt.ketteigaku;
      }
      else{
        e.kettei = (ichiwari > lessPrice) ? lessPrice : ichiwari;
        lessPrice -= e.kettei;
        if (udt.kdLessPrice)  udt.kdLessPrice -= e.kettei;
      }
      e.ichiwari = ichiwari;
      e.tyouseiGaku = tyouseiGaku;
    // lessPrice -= (e.kettei - kdTyousei); // 長男は二重に引かれるので調整
    });
    // 自己負担額が割り振られた事業所の数をカウントする
    const cnt = jAry.filter(e=>e.kettei).length;
    // ここに被保険者番号と利用者名が欲しくなったという…帳票作成時に使う
    jAry.map(e=>{e.hno = udt.hno; e.userName = udt.name});
    // 上限値配分配列をソート。jAryはデッドコピーされていないので！反映されるはず
    // こういう危ないコーディングはやめよう -> 別にいいんじゃね？
    // 202206以降はソートしない
    if (stdDate < '2022-06-01'){
      jAry.sort((a, b) => (a.no > b.no)? 1: -1);
    }

    
    // 上限範囲内
    const inLimit = lessPrice > 0;
    // 配分した結果を取得
    const kanrikekkagaku = jAry.find(e => e.name === "thisOffice").kettei;
    // 利用者負担額が割り振られた（負担額がある事業所が2以上）なら
    // 上限管理が発生したとしてtrueを返す
    const rt = {
      shared: (cnt > 1), // 配分されたかどうか
      inLimit, // 上限範囲内だったかどうか
      kanrikekkagaku,
      result,
    }
    return rt;
    // scheduleにdispatch要検討
  }
  
  // それぞれの利用者を処理
  Object.keys(dt).map(e=>{
    addjustKdLessPrice(dt);
    const o = dt[e];
    // 利用実績がない利用者は処理しない
    // 欠席利用のみは処理続行するようにした。20210731
    // if (!(o.countOfUse + o.countOfKesseki))  return false;
    // if (!o.kanriType) return false; // 管理事業所協力事業所が設定されていない。
    let ichiwari = Math.floor(o.userSanteiTotal * .1); // 一割。ここは切捨
    ichiwari = o.tashikeigen === 2 ? Math.floor(o.userSanteiTotal * 0.05): ichiwari;
    ichiwari = o.tashikeigen === 3 ? 0: ichiwari;
    let upperlimit = parseInt(o.priceLimitBk? o.priceLimitBk: o.priceLimit);
    if (o.musyouka) upperlimit = 0;
    let tyouseigaku = Math.min(ichiwari, upperlimit); // 上限月額調整額
    // 兄弟調整の結果を反映
    if (o.kdTyousei !== undefined){
      tyouseigaku = Math.min(tyouseigaku, parseInt(o.kdTyousei));
    }
    let kanrikekkagaku = tyouseigaku; // 上限管理結果額 暫定
    let kanriKekka;
    // 兄弟調整があるときは管理結果を上書きしない
    if (o.kdTyousei !== undefined){
      kanriKekka = o.kanriKekka
    }
    else{
      kanriKekka = 0;
    }
    let kanriOk = false; // 上限管理がされているかどうかのフラグ
    // “1”管理事業所で利用者負担額を充当したため、他事業所では発生しない。
    // “2”利用者負担額の合計額が、負担上限月額以下のため、調整事務は行わない。
    // “3”利用者負担額の合計額が、負担上限月額を超過するため、調整した。 
    // 利用者負担上限額管理を行った場合のみ設定する。利用者負担上限額管理
    // が必要ない場合（例えば、利用者負担上限月額が 0 円の場合）は
    // 設定しない。 
    if (o.kanriType === '協力事業所'){
      if (o.管理事業所 === undefined || !o.管理事業所.length){
        // const kettei = o.管理事業所[0].kettei;
        // adjust2 = (tyouseigaku > adjust2)? adjust2 : tyouseigaku;
        // console.log(o.name + 'さんの上限管理情報がありません。');
        kanriOk = false;
      }
      else{
        kanriKekka = parseInt(o.管理事業所[0].kanriKekka);
        kanrikekkagaku = parseInt(o.管理事業所[0].kettei);
        if (kanriKekka >= 0 && kanrikekkagaku >= 0){
          kanriOk = true;
        }
      }
    };
    // 協力事業所がない
    const noKyJi = (o.協力事業所 === undefined || !o.協力事業所.length);
    // 無償化の場合、基本何もしない
    if (o.musyouka){
      kanriOk = true;
    }
    else if (o.kanriType === '管理事業所' && noKyJi){
        // console.log(o.name + 'さんの上限管理情報がありません。');
        kanriOk = false;
    }
    else if (o.kanriType === '管理事業所' && !noKyJi) {
      kanriKekka = (ichiwari > upperlimit) ? 1 : 2;
      // この場合、結果は暫定。他事業所で調整があれば3に変更
      const manageRt = manegeThis(o);
      const {shared, inLimit, result ,kanrikekkagaku, ...others} = manageRt;
      // kanriKekka = (manageRt.shared) ? 3 : kanriKekka; // 配分されたなら3
      if (!shared && !inLimit)  kanriKekka = 1; // 管理事業所充当
      else if (shared && !inLimit)  kanriKekka = 3; // 調整を行った
      else kanriKekka = 2; // 上限範囲内 
      // kanrikekkagaku = manageRt.kanrikekkagaku;
      // 協力事業所情報がないとfalseが帰ってくる
      // また他事業所の数値が入っていな場合もfalseが帰ってくる
      // その場合、管理結果をfalseにする
      if (!manageRt.result){
        kanriKekka = 0;
        kanriOk = false;
      }
      else{
        kanriOk = true;
      }
    }
    o.kanriOk = kanriOk;
    o.kanriKekka = kanriKekka;
    o.tyouseigaku = tyouseigaku;
    o.kanrikekkagaku = kanrikekkagaku;
    o.ketteigaku = Math.min(kanrikekkagaku, tyouseigaku)
  });
}

// 市区町村、サービスごとに集計データを作成する
// k112の項目を埋めるために使用する
const totlizeCityAndService = (dt, masterRec, userlist) =>{
  // まずはUniqueな配列から
  const cityServiceSet = new Set();
  Object.keys(dt).map(e=>{
    if (!checkUidFromList(userlist, e)){
      return false;
    }
    const o = dt[e];
    if (o.tanniTotal){
      cityServiceSet.add(o.scityNo + ',' + o.serviceSyubetu);
    }
  });
  // 配列化ソート。これで文字列のソートが出来るっぽい
  const cityService = Array.from(cityServiceSet)
  .sort((a, b)=>((a < b)? 1: -1));
  // 集計して配列に加える
  const totalized = [];
  cityService.map(e=>{
    let adjust1 = 0;
    let adjust2 = 0;
    let kanrikekkagaku = 0;
    let countOfUse = 0;
    let tanniTotal = 0;
    let userSanteiTotal = 0;
    let countOfUsers = 0;
    let jichiJosei = 0;
    const scityNo = e.split(',')[0]; // カンマ区切りで格納されているので展開
    const serviceSyubetu = parseInt(e.split(',')[1]);
    Object.keys(dt).map(key=>{
      if (!checkUidFromList(userlist, key)){
        return false;
      }
      const f = dt[key];
      if (!f.tanniTotal)  return false;
      if (f.scityNo !== scityNo || f.serviceSyubetu !== serviceSyubetu) {
        return false;
      }
      // 利用実績がなければスキップ
      // ここを変更。単位トータルでスキップしているので2021/09/22追加
      // if (!f.countOfUse)  return false;
      countOfUse += f.countOfUse;
      tanniTotal += f.tanniTotal;
      userSanteiTotal += f.userSanteiTotal;
      kanrikekkagaku += f.kanrikekkagaku;
      jichiJosei += (f.jichiJosei)? f.jichiJosei: 0;
      if (isNaN(f.kanrikekkagaku)){
        console.log(f.kana + 'さんのに問題があります。')
      }
      countOfUsers++;
    })
    totalized.push({
      scityNo, serviceSyubetu, adjust1, adjust2, countOfUse,
      tanniTotal, userSanteiTotal, kanrikekkagaku, countOfUsers,
      jichiJosei
    });
  });
  // ソート実施 文字列連結してマルチソートっぽく（文字数があってないと機能しないよ）
  totalized.sort((a, b)=>(
    a.scityNo+a.serviceSyubetu > a.scityNo+a.serviceSyubetu? 1: -1
  ));
  // マスターレコードに追加！
  masterRec.totalized = totalized;
}

// 2021法改正対応
// 従来はサービス名のベース（放デイ２４等）を事業所情報のみから取得できていたが
// 医療的ケア児が出てきたためユーザーごとにサービス名ベースを設定する必要が
// 出てきた。この関数でユーザーごとのサービス名ベースを設定する
// 医療的ケア児はユーザーごとの加算項目からのみ取得する！
// 事業所の項目は無視
// 放課後等デイサービスではない場合、falseを返す
const getServiceNameBase = (comAdic, schedule, users, UID, type) => {
  let ku = comAdic.サービス提供時間区分;
  const service = '放課後等デイサービス';
  const teiin = parseInt(comAdic.定員);
  const thisUser = comMod.getUser(UID, users);
  if (thisUser.service !== '放課後等デイサービス')  return false;
  // ユーザーからではなくスケジュールから医療ケアの情報を取得
  const pathStr = service + '.' + UID + '.addiction.医療ケア児基本報酬区分';
  const usersIcare = comMod.findDeepPath(schedule, pathStr);
  
  // iryoucareはundefinedではなくnullにする->0にする->''にする
  let iryoucare = (usersIcare)? usersIcare: '';
  // ここは要変更。type重心だけではこの適用にはならない。->事業所設定追加2021/10/01
  // 重度障害児の場合は区分を空白にする
  ku = (type === '  ')? '': ku;
  // 医療ケアも同様 type重心だけではこの適用にはならない。->事業所設定追加2021/10/01
  iryoucare = (type === '重症心身障害児')? '': iryoucare;
  // 区分と障害者・重心で絞って定員で特定
  let nameBase = serviceNameBase.filter(e => e.ku === ku);
  nameBase = nameBase.filter(e=>e.min <= teiin && e.max >= teiin);
  nameBase = nameBase.filter(
    e=>e.iryoucare === parseInt(iryoucare) || e.iryoucare === iryoucare
  );
  nameBase = nameBase.filter(e=>e.target === type);
  nameBase = nameBase[0];
  return nameBase;
}
// 児発用のサービスベース名取得
const getServiceNameBaseJH = (comAdicHD, schedule, users, UID, type) => {
  const teiinHd = (comAdicHD)? parseInt(comAdicHD.定員): 0;
  const thisUser = comMod.getUser(UID, users);

  const fcl = () => {
    const v = (comAdicHD)? parseInt(comAdicHD.児童発達支援センター): false;
    if (v)  return 'センター';
    else return '事業所';
  }
  // フィルタで使用する就学区分を求める
  const syuugaku = () => {
    const v = (comAdicHD)? parseInt(comAdicHD.就学区分): '';
    if (fcl() === 'センター') return '';
    else if (v) return '主に未就学児';
    else return '主に未就学児以外';
  };
  const pathStr = '児童発達支援.' + UID + '.addiction.医療ケア児基本報酬区分';
  // フィルタで使用する医療ケアを求める
  // 重心は医療ケアがない
  const usersIcare = () => {
    let v = comMod.findDeepPath(schedule, pathStr);
    if (isNaN(v)) v = 0;
    v = parseInt(v);
    if (!v) return 0;
    else if (type !== '重症心身障害児') return v;
    else return 0;
  }

  console.log(
    'syuugaku', syuugaku(), 'usersIcare', usersIcare(), 'fcl', fcl()
  );
  // 各条件に従って絞り込みを実施
  let target = serviceNameBaseHD.filter(e=>(e.teiin >= teiinHd));
  target = target.filter(e=>(e.syuugaku === syuugaku()));
  target = target.filter(e=>(e.type === type));
  target = target.filter(e=>(e.iCare === usersIcare()));
  target = target.filter(e=>(e.fcl === fcl()));
  // 絞り込まれたアイテムのうち定員が一番小さいのがビンゴ
  target.sort((a, b)=>(a.teiin > b.teiin ? 1: -1));
  if (!target.length){
    console.log('getServiceNameBaseJH----', thisUser.name, target);
  }
  return target[0];

}

// 兄弟調整の残高を常に兄弟間で一定にするためのモジュール
const addjustKdLessPrice = (dt) => {
  const firstUsers = Object.keys(dt)
  .filter(e=>parseInt(dt[e].brosIndex) === 1);
  firstUsers.map(e=>{
    const pname = dt[e].pname;
    const pphone = dt[e].pphone;
    const brosers = Object.keys(dt)
    .filter(
      f=>dt[f].pname === pname && dt[f].pphone === pphone
      && parseInt(dt[f].brosIndex) > 0
    );
    let less = 999999;
    brosers.forEach(f=>{
      const priceLimit = parseInt(
        dt[f].priceLimitBk? dt[f].priceLimitBk: dt[f].priceLimit
      )
      if (dt[f].kdLessPrice < less){
        less = dt[f].kdLessPrice;
      }
      if (less > priceLimit) less = priceLimit;
      dt[f].kdLessPrice = less;
    })
  })

}

// schTmp を受けて兄弟調整を行う。
const makeBrosTyousei = (dt, jino, ) => {
  // ------ 兄弟調整を行う
  // 長男長女のキー
  const firstUsers = Object.keys(dt)
  .filter(e=>parseInt(dt[e].brosIndex) === 1);
  // 長男長女から同じ親を持つ児童を探し兄弟調整額を設定する
  firstUsers.map(e=>{
    const pname = dt[e].pname;
    const pphone = dt[e].pphone;
    // 長男長女から上限値を取得
    let lessPrice = parseInt(
      dt[e].priceLimitBk? dt[e].priceLimitBk: dt[e].priceLimit
    );
    // 無償化の場合は強制的に0にする
    if (dt[e].musyouka) lessPrice = 0;
    // 多子軽減の3も0にする
    if (dt[e].tashikeigen === 3) lessPrice = 0;
    // 兄弟のキー配列を取得
    // brosindex 0 は無視する 2022/04/13
    const brosers = Object.keys(dt)
    .filter(
      f=>dt[f].pname === pname && dt[f].pphone === pphone 
      && parseInt(dt[f].brosIndex) > 0
      // && dt[f].userSanteiTotal > 0 // 算定がなくても兄弟として登録 2022/06/06
    );
    // 利用金額などで兄弟なしになる場合あり
    if (brosers.length < 2) return false;
    
    // 兄弟のキー配列をインデックス順にソート
    brosers.sort((a, b) => ((dt[a].brosIndex > dt[b].brosIndex) ? 1: -1));
    let kanriKekka;
    brosers.map((f, i)=>{
      const udt = dt[f];
      const amount = parseInt(udt.userSanteiTotal);
      let ichiwari = Math.floor(amount * 0.1);
      // 多子軽減対応
      ichiwari = udt.tashikeigen === 2 ? Math.floor(amount * 0.05): ichiwari;
      const kdTyousei = (lessPrice < ichiwari)
        ? lessPrice : ichiwari;
      // 配列トップで上限使う場合は1
      if (i === 0 && lessPrice < ichiwari)  kanriKekka = 1;
      // 配列最後で上限残額が残るようなら2
      if (i === brosers.length - 1 && lessPrice > ichiwari)   kanriKekka = 2;
      // 兄弟間で調整する場合は１にする？（福祉ソフトがそうしてる）
      if (i === brosers.length - 1 && lessPrice <= ichiwari)  kanriKekka = 1;
      // const kanriKekka = (lessPrice < ichiwari)? 1: 0;
      udt.kdTyousei = kdTyousei;
      if (kdTyousei === 0){
        udt.kanrikekkagaku = 0;
        udt.kanriOk = true;
      }
      lessPrice -= kdTyousei;
      dt[f].kanriKekka = kanriKekka;
      // 管理結果額決定額はここでは暫定。後ほど、他事業所との調整で上書きされる予定。
      dt[f].kanrikekkagaku = kdTyousei;
      dt[f].ketteigaku = kdTyousei;
      dt[f].kyoudaiJougen = true; // 兄弟上限実施されているフラグ
    });
    // 兄弟調整の結果の残額を記述
    // 兄弟で共通の値となる
    brosers.map(f=>{dt[f].kdLessPrice = lessPrice;});
    // 兄弟の管理結果フラグを長男長女に合わせる
    // const brosKanrikekka = dt[brosers[0]].kanriKekka;
    brosers.map(f=>{
      dt[f].kanriKekka = kanriKekka;
      // 管理結果が 0 以外だったら上限管理事業所に自事業所を入れる
      // 自事業所を入れる条件追加。すでに管理事業所が設定されている場合は上書きしない
      if (kanriKekka && !dt[f].jougenJi) dt[f].jougenJi = jino;
    });
    
  });
  return dt;
}

// 兄弟絡みの上限管理を確定させ記録を行う
// 兄弟を含む上限管理はまず兄弟間での上限管理を行った上で事業所間の上限管理を経て決定される
// なので二つの上限管理を行った後に記録を行う。
// これなにをやってるか不明。いらないんじゃないの？ 2022/05/27
const makeBrosRecord = (bdt, users) => {
  Object.keys(bdt).filter(e=>parseInt(bdt[e].brosIndex) === 1).map(e=>{
    const cbdt = bdt[e];
    const bros = comMod.getBrothers(e, users, true);
    // console.log(bros, 'bros')
    // 格納すべき項目
    // {amount:'', name:'', ichiwari:'', kettei:'', lname: ''}
    const brosJougen = bros.map(f=>{
      const brosBdt = bdt['UID' + f.uid];
      return({
        amount: brosBdt.userSanteiTotal,
        name: '', // ここには事業所名が入る。自社の場合は空白を返しておく
        lname: '', 
        ichiwari: Math.floor(brosBdt.userSanteiTotal * 0.1),
        kettei: brosBdt.ketteigaku,
        UID: 'UID' + f.uid,
        userName: f.name,
        hno: f.hno,
        brosIndex: f.brosIndex,
      })
    });
    cbdt.brosJougen = brosJougen;
  });
}

// 自治体助成を付与する
const makeJichitaiJosei = (schTmp, com) => {
  // 助成自治体の情報をcomから取得
  const cities = com.etc.cities;
  if (!cities) return null; // そもそも該当オブジェクトがない場合
  Object.keys(schTmp).map(e=>{
    const o = schTmp[e];
    const thisCity = cities.find(f=>f.no === o.scityNo); // com記述該当自治体
    if (!thisCity) return false; // 該当自治体なし
    // 自治体助成に決定学をそのまま記載。補助率等が出てきたら別途検討する
    // 決定額が0の場合は助成が発生しない 発生するときのみ自治体番号を記述する
    if (thisCity.josei && o.ketteigaku){
      o.jichiJosei = o.ketteigaku;
      o.joseiNo = thisCity.joseiNo;
    }
  });
}
// 児発無償化の判断を行う
// 児発無償化のフラグはdAddiction配下にある
// 事業所全体とユーザー単位でしか設定できないため一個でもあったらそれを設定値とする
const isMusyouka = (schTmp) => {
  const srcKey = '児童発達支援無償化';
  const trgKey = 'musyouka';
  // UID配下のDIDのみ舐める
  Object.keys(schTmp).filter(e=>/^UID[0-9]+/.test(e)).map(e=>{
    const uObj = schTmp[e];
    Object.keys(uObj).filter(f=>/^D2[0-9]+/.test(f)).map(f=>{
      const dObj = schTmp[e][f];
      const trg = comMod.findDeepPath(dObj, ['dAddiction', srcKey]);
      if (trg){
        uObj[trgKey] = trg;
      }
    });
  })
}
// 多子軽減判断
const isTashikeigen = (schTmp) => {
  const srcKey = '多子軽減措置';
  const trgKey = 'tashikeigen';
  // UID配下のDIDのみ舐める
  Object.keys(schTmp).filter(e=>/^UID[0-9]+/.test(e)).map(e=>{
    const uObj = schTmp[e];
    Object.keys(uObj).filter(f=>/^D2[0-9]+/.test(f)).map(f=>{
      if (uObj.musyouka) return false; // 無償化のときは対象外
      const dObj = schTmp[e][f];
      const trg = comMod.findDeepPath(dObj, ['dAddiction', srcKey]);
      if (trg){
        uObj[trgKey] = (trg === '第二子軽減')? 2: 3;
      }
    });
  })
}

// 兄弟上限上限月額調整額を入れる
const makeBrosGetsugakutyousei = (schTmp, users) => {
  Object.keys(schTmp).forEach(e => {
    const thisUser = comMod.getUser(e, users);
    if (parseInt(thisUser.brosIndex) === 0) return false;
    const ichiwari = Math.floor(schTmp[e].userSanteiTotal * 0.1);
    const priceLimit = schTmp[e].priceLimit;
    let getsuTyousei = Math.min(parseInt(priceLimit), ichiwari);
    schTmp[e].getsuTyousei = getsuTyousei;
  });
}

// 管理結果手動設定を反映させる
const setForthKanriKekka = (schTmp, sch, users) => {
  Object.keys(schTmp).forEach(e => {
    const u = comMod.getUser(e, users);
    const forthJougenKanrikekka = fdp(
      sch, [u.service, e, 'addiction', '上限管理結果']
    );
    if (parseInt(forthJougenKanrikekka) === 4){
      schTmp[e].kanriKekka = '';
    }
    else if (forthJougenKanrikekka === '設定しない'){
      schTmp[e].kanriKekka = '';
    }
    else if (forthJougenKanrikekka){
      schTmp[e].kanriKekka = forthJougenKanrikekka;
    }
  });
}
// 放デイ以外で児発無償化を無効化
const killMusyouka = (dt) => {
  Object.keys(dt).map(e=>{
    const o = dt[e];
    if (o.service !== '児童発達支援'){
      delete o.musyouka;
    }
  });
}
// 横浜用の上限管理
const setYokohamaJougen = (schTmp, users, schedule) => {
  Object.keys(schTmp).forEach(e=>{
    const o = schTmp[e];
    const isKyoudaiJ = isKyoudaiJougen(schTmp, users, e, schedule);
    const thisUser = comMod.getUser(e, users);
    const isSeccond = parseInt(thisUser.brosIndex) > 1;
    const isYkhm = isYokohama(thisUser.scity_no);
    if (isKyoudaiJ && isSeccond && isYkhm){
      o.priceLimitBk = o.priceLimit;
      o.priceLimit = 0;
    } 
  })
}
// 横浜用上限管理後処理
// 利用者負担額確定したらpriceLimitを調整する
const setYokohamaAgein = (schTmp, users, schedule) => {
  Object.keys(schTmp).forEach(e=>{
    const o = schTmp[e];
    const isKyoudaiJ = isKyoudaiJougen(schTmp, users, e, schedule);
    const thisUser = comMod.getUser(e, users);
    const isSeccond = parseInt(thisUser.brosIndex) > 1;
    const isYkhm = isYokohama(thisUser.scity_no);
    if (isKyoudaiJ && isSeccond && isYkhm){
      o.priceLimit = o.kanrikekkagaku;
    } 

 });
}
// Scheduleオブジェクトをdeep COPYして
// 事業所ごと、日毎、ユーザーごとの加算アイテムをuid.didにまとめる
// 加算で入力設定されたオブジェクトからサービスコードを特定してuid.did.itemsに
// まとめる

export const setBillInfoToSch = (prms, userlist = []) => {
  const { stdDate, schedule, users, com, service, serviceItems} = prms;
  const hasHouday = serviceItems.indexOf('放課後等デイサービス') > -1;
  const hasJihatsu = serviceItems.indexOf('児童発達支援') > -1;
  
  // 児発の項目も追加
  const comAdic = (hasHouday)? com.addiction.放課後等デイサービス: {};
  console.log(hasHouday, comAdic, 'hasHouday, comAdic');
  const ku = hasHouday? comAdic.サービス提供時間区分: '';
  // const teiin = parseInt(comAdic.定員);
  const comAdicHD = (hasJihatsu)? com.addiction.児童発達支援: {}
  // 該当サービスの事業所情報オブジェクトが存在しない場合、エラーで終了
  if (hasHouday && !comAdic){
    const errDetail = {
      description: '放課後等デイサービスの請求に必要な情報がありません。'
    }
    return {billingDt: [], masterRec: {}, result: false, };
  }
  // 該当サービスの事業所情報オブジェクトが存在しない場合、エラーで終了
  if (hasJihatsu && !comAdicHD){
    const errDetail = {
      description: '児童発達支援の請求に必要な情報がありません。'
    }
    return {billingDt: [], masterRec: {}, result: false};
  }
  // Scheduleを要素選択しつつdeep copy
  const schTmp = {};
  const uidsInUsers = users.map(e=>'UID' + e.uid);
  const uidsInSch = Object.keys(schedule).filter(e=>e.indexOf('UID') === 0);
  const uids = Array.from(new Set([...uidsInUsers, ...uidsInSch]));
  // scheduleのキーによるループから予めusersとscheduleから作成したキー配列を使ってループ
  // に変更した もともとはschedule初期化後追加したユーザーがスケジュールの変更なしに
  // 請求処理を回したときに発生するハングアップ対策
  // 多分、これで大丈夫 20220505
  uids.map(e => {
  // Object.keys(schedule).map(e => {
    // uidではない要素はスキップ
    // if (e.indexOf('UID') < 0) return false;
    if (!/UID[0-9]+/.test(e)) return false;
    // userlistが指定されている場合、指定以外はスキップ
    // ここでスキップすると兄弟上限の情報が欠落する。
    // schTmpToArrayでフィルタをかける
    // if (!checkUidFromList(userlist, e)) return false;
    
    const u = comMod.getUser(e, users);
    // ユーザー情報が存在するときのみ実行
    if (Object.keys(u).length){
      if (schedule[e]){
        schTmp[e] = JSON.parse(JSON.stringify(schedule[e]));
      }
      else{
        schTmp[e] = {};
      }
      // 親の名前と電話番号を格納。兄弟での上限管理のため。
      schTmp[e].pname = u.pname;
      schTmp[e].pphone = u.pphone;
      schTmp[e].brosIndex = u.brosIndex;
      // サービス名をここに記載。上限管理付与するかどうかの判断に使う 2021/09/13
      schTmp[e].service = u.service;
      schTmp[e].type = u.type;
      schTmp[e].classroom = u.classroom;
      // if (u.age >= 18){
      //   schTmp[e].pname = u.name;
      //   schTmp[e].pkana = u.kana;
      //   schTmp[e].name = '';
      //   schTmp[e].kana = '';
      // }
      // 2022/03/21 口座情報も追加
      schTmp[e].bank_info = comMod.findDeepPath(u, 'etc.bank_info');
    }
  });
  // 2022/02/07 didが一個も存在しないスケジュールについてはD20000000を追加
  // 利用が一回もなくても上限管理加算などが追加されるケースがあるため
  Object.keys(schTmp).forEach(e=>{
    const existDid = Object.keys(schTmp[e])
    .filter(f=>f.match(/^D2[0-9]+/)).length > 0;
    if (!existDid){
      schTmp[e].D00000000 = {};
      schTmp[e].D00000000.dAddiction = {};
      schTmp[e].D00000000.absence = true;
    }
  });

  // 全ての加算情報を個々のスケジュールデータに集める
  // schTmp[uid][did].dAddinction ←ここにまとめて入れる。
  // 加算じゃないものも全部入れる
  Object.keys(schTmp).map(e => {
    const thisComAdic = (schTmp[e].service === '放課後等デイサービス')
    ?comAdic: comAdicHD;
    Object.keys(schTmp[e]).map(f => {
      // didでない要素はスキップ
      if (!f.match(/^D[0-9]+/)) return false;
      // 日毎の加算要素を追加。nullを追加しても無問題
      const dayAddiction = comMod.findDeepPath(schedule, [schTmp[e].service, f]);
      // 追加先がnullだとエラーになるので。
      if (!schTmp[e][f].dAddiction) schTmp[e][f].dAddiction = {};
      Object.assign(schTmp[e][f].dAddiction, dayAddiction);
      // ユーザーの加算要素を追加 nullを（ｒｙ
      // ユーザ毎の加算項目の取得方法を変更
      // ユーザステートではなくスケジュールの該当項目より取得を行う
      const u = comMod.getUser(e, users);
      const UID = 'UID' + u.uid;
      const uAddiction = comMod.findDeepPath(
        schedule, [u.service, UID, 'addiction']
      );
      // 上限額加算だけはここでは扱わない
      // 上限管理結果もここでは無視する
      if (uAddiction){
        delete uAddiction.利用者負担上限額管理加算;
        delete uAddiction.上限管理結果;
      }
      // const uAddiction = comMod.findDeepPath(u, 'etc.addiction');
      Object.assign(schTmp[e][f].dAddiction, uAddiction);
      // 事業所の（ｒｙ
      Object.assign(schTmp[e][f].dAddiction, thisComAdic);
      // 非表示アイテムには'-1'が記述されているのでそこはオミットする。
      Object.keys(schTmp[e][f].dAddiction).map(g=>{
        const thisVal = schTmp[e][f].dAddiction[g];
        if (thisVal === '-1') delete schTmp[e][f].dAddiction[g];
      })
    })
  });

  // 読みが原則ひらがななので半角カタカナに変換する
  //　名字と名前の間に入っているスペースも削除する
  const convName = (str) => {
    str = str.replace(' ', '');
    str = comMod.convHiraToKata(str);
    str = comMod.zen2han(str);
    return str;
  }
  // schTmpに基本項目「放デイｘｘ」を格納する
  // schTmpに加算項目を追加する
  // 送迎も加算項目として追加する
  // ユーザー情報から必要な情報を集めておく
  let setBillingResult = {result: true};
  Object.keys(schTmp).map(e => {
    const thisUser = comMod.getUser(e, users);
    schTmp[e].hno = thisUser.hno;
    schTmp[e].name = thisUser.name; // 名前は使わないがメッセージ用に
    schTmp[e].pkana = convName(thisUser.pkana);
    schTmp[e].kana = convName(thisUser.kana);
    schTmp[e].scityNo = thisUser.scity_no;
    schTmp[e].startDate = thisUser.startDate.replace(/\-/g, '');
    // 契約日。入力項目になっていないので後から変更が必要
    schTmp[e].keiyakuDate = thisUser.contractDate.replace(/\-/g, '');
    const endDate = thisUser.endDate.replace(/\-/g, '');
    schTmp[e].endDate = (endDate === '00000000') ? '' : endDate;
    schTmp[e].priceLimit = thisUser.priceLimit;
    schTmp[e].volume = thisUser.volume;
    schTmp[e].kinyuuBangou = thisUser.lineNo; // 保険証記入番号
    schTmp[e].countOfUse = 0; // 利用回数カウント
    schTmp[e].countOfKesseki = 0; // 欠席カウント
    schTmp[e].kanriType = thisUser.kanri_type;
    schTmp[e].serviceSyubetu = serviceSyubetu[thisUser.service];
    // 決定サービスコード
    const ketteiPrms = {uid: e, users, schedule}
    schTmp[e].ketteiScode = getKetteiSeriviceCode(ketteiPrms);

    // 上限管理事業所コード
    let jougenJi = '';
    let jougenJiName = '';
    let jougenJiSname = '';
    if (thisUser.kanri_type === '管理事業所') {
      jougenJi = com.jino;
    }
    else if (thisUser.etc && thisUser.kanri_type === '協力事業所') {
      if (thisUser.etc.管理事業所 && thisUser.etc.管理事業所.length > 0) {
        jougenJi = thisUser.etc.管理事業所[0].no;
        jougenJiName = (thisUser.etc.管理事業所[0].lname)?
          thisUser.etc.管理事業所[0].lname : thisUser.etc.管理事業所[0].name;
        jougenJiSname = thisUser.etc.管理事業所[0].name;

      }
    }
    schTmp[e].jougenJi = jougenJi;
    schTmp[e].jougenJiName = jougenJiName;
    schTmp[e].jougenJiSname = jougenJiSname;
    schTmp[e].actualCost = 0;
    schTmp[e].actualCostDetail = [];
    // serviceNameBaseを選択
    // 2021/09/25 放デイの処理のため弄ってる
    const uService = schTmp[e].service;
    const juuShingata = (uService === '放課後等デイサービス')?
    comAdic.重症心身型: comAdicHD.重症心身型;
    const jhCenter = comAdicHD.児童発達支援センター;
    // typeの設定
    let type;
    if (uService === '放課後等デイサービス'){
      type = (juuShingata)? schTmp[e].type: '障害児';
    }
    else if (uService === '児童発達支援'){
      if (!juuShingata && !jhCenter){
        type = '障害児';
      }
      else if (juuShingata && jhCenter){
        type = schTmp[e].type;
      }
      else if (juuShingata && !jhCenter){
        type = (schTmp[e].type === '難聴児')? '障害児': schTmp[e].type;
      }
      else type = '障害児'
    }
    // 定員の定義
    const teiin = (uService === '放課後等デイサービス')
    ? comAdic.定員: comAdicHD.定員;


    let nameBase
    if (uService === '放課後等デイサービス'){
      nameBase = getServiceNameBase(comAdic, schedule, users, e, type);
    }
    else if (uService === '児童発達支援'){
      nameBase = getServiceNameBaseJH(comAdicHD, schedule, users, e, type);
    }

    if (!nameBase){
      const errUser = comMod.getUser(e, users);
      const errDetail = {
        name: errUser,
        description: 
          `放デイXX、児発XXなどのサービスアイテムが特定できませんでした。
          加算設定や事業所設定の間違えで請求できない設定になっている可能性があります。
          ユーザー名 : ${errUser.name}
          保護者名 : ${errUser.pname}
          `,
      }
      setBillingResult = {result: false, errDetail};
      return false;
    }
    Object.keys(schTmp[e]).map(f => {
      // didでない要素はスキップ
      if (!f.match(/^D[0-9]+/)) return false;
      const o = schTmp[e][f];
    
      // 基本項目の追加
      const baseItem = getBaseItem(
        nameBase, o.dAddiction, o.offSchool, o.absence, uService
      );
      if (o.items === undefined) o.items = [];
      if (baseItem) o.items.push(baseItem);
      // 令和３年９月３０日までの上乗せ分
      const tokubetuItem = (uService === '放課後等デイサービス')?
      houdayKasan.find(e=>e.s === tokubetuItemSc):
      jihatsuKasan.find(e=>e.s === tokubetuItemScJH)
      if (stdDate <= '2021-09-01')  o.items.push(tokubetuItem);

      // baseitem存在確認したら利用回数をインクリメント
      if (baseItem) schTmp[e].countOfUse++;
      // 加算項目の追加
      const kasanItem = getKasanItem(
        o.dAddiction, o.offSchool, teiin, ku, o.absence, uService, type
      );
      // 欠席に対してもカウントを実施。
      if (kasanItem.find(e=>KESSEKI_SVC_CODE.indexOf(e.s) > -1)){
        schTmp[e].countOfKesseki++;
      }
      // 欠席の場合の相談支援加算などは欠席でも利用カウント追加
      if (!baseItem && o.absence){
        if (kasanItem.find(e=>SOUDANSIEN_SVC_CODE.indexOf(e.s) > -1)){
          schTmp[e].countOfUse++;
        }
        if (kasanItem.find(e=>KATEI_SVC_CODE.indexOf(e.s) > -1)){
          schTmp[e].countOfUse++;
        }
      }
      o.items = o.items.concat(kasanItem);
      let transfer;
      if (uService === '放課後等デイサービス'){
        transfer = getTrasferItem(o.transfer, o.absence, type);
      }
      else if (uService === '児童発達支援'){
        transfer = getTrasferItemJH(o, e, users, type);
      }
      o.items = o.items.concat(transfer);
      // 管理事業所の場合、上限管理加算をここで入れる
      // 日付オブジェクト配下ではなくitemTotalに直接入れる 2021/09/10
      // if (thisUser.kanri_type === '管理事業所')
      //   o.items.push(JOUGEN_KANRI);
      // 実費項目の積算
      if (o.actualCost && typeof o.actualCost === 'object'){
        Object.keys(o.actualCost).map(f=>{
          if (o.absence)  return false;
          schTmp[e].actualCost += parseInt(o.actualCost[f]);
          // 実費明細作成
          const detail = schTmp[e].actualCostDetail;
          // 2022/01/24 MTU対策
          // 実費自由項目対応
          let i;
          if (o.classroom){
            i = detail.findIndex(g => (
              g.name === f && g.classroom === o.classroom
              && o.actualCost[f] == g.unitPrice
            ));
          }
          else{
            i = detail.findIndex(g => 
              g.name === f && o.actualCost[f] && o.actualCost[f] == g.unitPrice
            );
          }
          // 更新
          if (i >  -1){
            detail[i].count++;
            detail[i].price += parseInt(o.actualCost[f]);
          }
          // 追加
          else{
            detail.push({ 
              name: f, count: 1, 
              price: parseInt(o.actualCost[f]),
              unitPrice: parseInt(o.actualCost[f]),
            });
            // 2022/01/24 MTU対策
            if (o.classroom){
              detail[detail.length - 1].classroom = o.classroom;
            }
          }
        });
      }
      else {
        // console.log('actualcost err', schTmp[e].name, o);
      }
    });
  });
  if (!setBillingResult.result){
    return setBillingResult;
  }
  // マスターレコード 単価や各種集計値などを格納する
  // [今月]は実行時の日付から作成する
  // 放デイと児発の単価をそれぞれ求める。
  // 相違がある場合はここにワーニングだけ仕込んでおく。
  // 地域区分についても複数設定されていたらワーニングを仕込む
  // --------　単価
  const unitPriceSet = new Set();
  serviceItems.map(e=>{
    unitPriceSet.add(unitPrice[e][com.addiction[e].地域区分])  
  });
  const up = Array.from(unitPriceSet)[0];
  const unitPriceWarning = (unitPriceSet.size > 1)
  ?'複数の単価が存在します。':'';

  const masterRec = { 
    unitPrice: up, unitPriceWarning,
    // 地域区分の取得。児発単体の時地域区分が取得できなかった。
    // 放デイがない時は児発のコードから拾う
    // 2022/06/15
    chiikiKubun: chiikiKubun[comAdic.地域区分?comAdic.地域区分: comAdicHD.地域区分],
    jino: com.jino,
    thisMonth: stdDate.substr(0, 7).replace('-', ''), 
    curMonth: comMod.formatDate(new Date(), 'YYYYMM'),
  };
  // 単位のリストを作る
  const clsTmp = [];
  users.map(e=>{
    const clr = e.classroom;
    if (!Object.keys(e).length) return false;
    if (Array.isArray(clr)) clsTmp.push(...clr);
    else if (clr.indexOf(',')) clsTmp.push(...clr.split(','));
    else clsTmp.push(clr);
  })
  const classroomList = Array.from(new Set(clsTmp));

  Object.keys(schTmp).filter(e=>/^UID/.test(e)).forEach(e=>{
    Object.keys(schTmp[e]).filter(f=>/^D2/.test(f)).forEach(f=>{
      const o = schTmp[e][f];
      if (o.offSchool === undefined){
        console.log('offschool undef', schTmp[e].name, e, f);

      }
    })
  });

  // 児発無償化の判断を行う。
  isMusyouka(schTmp);
  // 多子軽減の判断
  isTashikeigen(schTmp);
  // サービスアイテムなどを集計する
  // 処遇改善加算などもここで行う
  totalizeItems(schTmp, masterRec, users, schedule);
  // MTUが存在するか
  let svcByUserMax = 0;
  users.forEach(e => {
    const v = amdcm.classroomCount(e);
    if (v > svcByUserMax) svcByUserMax = v;
  });
  // mtuが存在したら単位別の売上を求める
  if (svcByUserMax > 1){
    classroomList.map(e=>{
      totalizeItems(schTmp, masterRec, users, schedule, e);
    })
  }
  // 放デイに残った児発無償化を無効化する
  killMusyouka(schTmp);
  // 横浜市独自のやつ
  setYokohamaJougen(schTmp, users, schedule);
  // 兄弟調整を行う
  makeBrosTyousei(schTmp, com.jino, );
  // 上限管理を作成する！！！
  makeUpperLimit(schTmp, stdDate);
  // 事業所間上限管理値を決定した上で兄弟の上限値管理結果などを兄弟用のレコードに記載する。
  makeBrosRecord(schTmp, users);
  // 横浜上限菜調整
  setYokohamaAgein(schTmp, users, schedule);
  // 上限月額調整額を入れる。兄弟のみ調整が必要らしい！！！！
  makeBrosGetsugakutyousei(schTmp, users);
  // 管理結果手動設定の反映
  setForthKanriKekka(schTmp, schedule, users);
  
  // // 上限管理結果を見た上で上限管理加算を付与するか判断して追加する
  // // ----->廃止
  // addJougenkanriKasan(schTmp, schedule);
  
  // 自治体助成
  makeJichitaiJosei(schTmp, com);
  // 市区町村、サービス別の集計をマスターレコードに記載
  totlizeCityAndService(schTmp, masterRec, userlist);
  // 18歳以上の処理を行う
  convNameOver18(schTmp, users);
  // 市区町村別にソート
  const billingDt = schTmpToArray(schTmp, userlist);
  // サービス種別順にもソートが必要
  billingDt.sort((a, b)=>(
    a.scityNo+a.serviceSyubetu > b.scityNo+b.serviceSyubetu? 1: -1
  ));
  console.log('schTmp', schTmp);

  return { billingDt, masterRec, result: true};
}

const csvHaed = (outputRec, masterRec, syubetu) =>{
  // 1, REC_NO, 0, REC_CNT, 'K11', 0, JI_NO, 0, 1, THIS_MONTH, 0
  outputRec.push([...headRec]);
  erl(outputRec, JI_NO, masterRec.jino);
  erl(outputRec, THIS_MONTH, masterRec.curMonth);
  erl(outputRec, HEAD_SYUBETU, syubetu);

}
// K112基本 市区町村ごと合計行}
// 引数で渡される市区町村番号により処理を行う
// masterrec.totalizedは放デイ児発の複数データが含まれていることがあるのでそれを合算する
const billingCsvK112_1 = (outputRec, masterRec, scity) =>{
  // 2, REC_NO, 'K112', 1, THIS_MONTH, SCITY_NO, JI_NO, TOTAL_AMOUNT, TOTAL_COUNT, TOTAL_TANNI, TOTAL_AMOUNT, TOTAL_BILLED, 0, TOTAL_USER_BILLED, 0, 0, 0, 0,
  //   TOTAL_COUNT, TOTAL_TANNI, TOTAL_AMOUNT, TOTAL_BILLED, TOKUBETSU_TAISAKU,
  //   TOTAL_USER_BILLED, 0,
  const matched = masterRec.totalized.filter(e=>e.scityNo===scity);
  const userSanteiTotal = matched.reduce((v, e)=>(v + e.userSanteiTotal), 0);
  const countOfUsers = matched.reduce((v, e)=>(v + e.countOfUsers), 0);
  const tanniTotal = matched.reduce((v, e)=>(v + e.tanniTotal), 0);
  const kanrikekkagaku = matched.reduce((v, e)=>(v + e.kanrikekkagaku), 0);
  const jichiJosei = matched.reduce((v, e)=>(v + e.jichiJosei), 0);
  outputRec.push([...kihonK112_1]);
  erl(outputRec, THIS_MONTH, masterRec.thisMonth);
  erl(outputRec, SCITY_NO, scity);
  erl(outputRec, JI_NO, masterRec.jino);
  erl(outputRec, TOTAL_AMOUNT, userSanteiTotal);
  erl(outputRec, TOTAL_COUNT, countOfUsers);
  erl(outputRec, TOTAL_TANNI, tanniTotal);
  // 給付請求額 合計からユーザー負担を除算
  erl(outputRec, TOTAL_BILLED, userSanteiTotal - kanrikekkagaku);
  // 請求額に自治体助成を含んだもの
  erl(
    outputRec, TOTAL_BILLED1, 
    userSanteiTotal - kanrikekkagaku + jichiJosei
  );
  // 自治体助成金関連で処理追加
  erl(outputRec, TOTAL_USER_BILLED, kanrikekkagaku - jichiJosei);
  erl(outputRec, JICHITAI_JOSEI, jichiJosei);
}
// K112明細 サービス種別毎の明細。自発と放デイは別レコードになる
// 引数で与えられた市区町村番号でフィルタを掛けて出力する。
const billingCsvK112_2 = (outputRec, masterRec, scity) =>{
  // 2, REC_NO, 'K112', 2, THIS_MONTH, SCITY_NO, JI_NO, 1, S_SYUBETSU,
  // SRVC_COUNT_TOTAL, SRVC_TANNI_TOTAL, SRVC_AMOUNT, SRVC_BILLED, 0,
  // SRVC_USER_BILLED, 0,
  const matched = masterRec.totalized.filter(e=>e.scityNo===scity);
  matched.map(e=>{
    outputRec.push([...meisaiK112_2]);
    erl(outputRec, THIS_MONTH, masterRec.thisMonth);
    erl(outputRec, SCITY_NO, e.scityNo);
    erl(outputRec, JI_NO, masterRec.jino);
    erl(outputRec, S_SYUBETSU, e.serviceSyubetu);
    erl(outputRec, SRVC_COUNT_TOTAL, e.countOfUsers);
    erl(outputRec, SRVC_TANNI_TOTAL, e.tanniTotal);
    erl(outputRec, SRVC_AMOUNT, e.userSanteiTotal);
    // 給付請求額 合計からユーザー負担を除算
    erl(outputRec, SRVC_BILLED, e.userSanteiTotal - e.kanrikekkagaku);
    // 利用者負担額 自治体助成を除算
    erl(outputRec, SRVC_USER_BILLED, e.kanrikekkagaku - e.jichiJosei);
    erl(outputRec, JICHITAI_JOSEI, e.jichiJosei);
    })
}
const billingCsvK122_4 = (outputRec, masterRec, thisDt) => {
  // 2, REC_NO, 'K122', 4, THIS_MONTH, SCITY_NO, 
  // JI_NO, H_NO, S_SYUBETSU,
  // SYUUKEI_BUNRUI,  SRVC_COUNT,
  // USER_TANNI, KYUUFU_TANKA, 0, TOTAL_BILLED, ICHIWARI1, ICHIWARI2, 
  // GETSUGAKU_TYOUSEI,  '', '', TYOUSEIGO_USER_BILLED, 
  // JOUGEN_USER_BILLED, KETTEI_USER_BILLED, KETTEI_TOTAL_BILLED, 
  outputRec.push([...syuukeiK122_4]);
  erl(outputRec, THIS_MONTH, masterRec.thisMonth);
  erl(outputRec, SCITY_NO, thisDt.scityNo);
  erl(outputRec, JI_NO, masterRec.jino);
  erl(outputRec, H_NO, thisDt.hno);
  erl(outputRec, S_SYUBETSU, thisDt.serviceSyubetu);
  // 欠席対応加算などのカウントを調べる。
  // 相談支援加算などのカウントも追加。
  // 欠席時対応加算と同時算定があった場合の動作が心配
  // let kesseki = 0;
  // const kessekiSvc = [
  //   ...KESSEKI_SVC_CODE, ...SOUDANSIEN_SVC_CODE, ...KATEI_SVC_CODE
  // ];
  // thisDt.itemTotal.filter(e=>kessekiSvc.indexOf(e.s) > -1).map(e=>{
  //   kesseki += e.count;
  // });
  // ユーザー算定がされていて利用回数が0なら1にする
  let countOfUse = thisDt.countOfUse + thisDt.countOfKesseki;
  countOfUse = (thisDt.userSanteiTotal && !countOfUse)? 1: countOfUse;
  erl(outputRec, SRVC_COUNT, countOfUse);
  erl(outputRec, USER_TANNI, thisDt.tanniTotal);
  erl(outputRec, KYUUFU_TANKA, masterRec.unitPrice * 1000);
  const totalBilled = Math.floor(
    (masterRec.unitPrice*100) * thisDt.tanniTotal / 100
  );
  erl(outputRec, TOTAL_BILLED, totalBilled);
  const ichiwari = Math.floor(totalBilled * 0.1);
  // 無償化対象の場合、ここは0が入る
  let ichiwari2 = thisDt.musyouka? 0: ichiwari;
  // 多子軽減措置追加
  if (thisDt.tashikeigen === 2) ichiwari2 = Math.floor(totalBilled * 0.05);
  if (thisDt.tashikeigen === 3) ichiwari2 = 0;
  
  erl(outputRec, ICHIWARI1, ichiwari);
  erl(outputRec, ICHIWARI2, ichiwari2);
  // erl(outputRec,GETSUGAKU_TYOUSEI, thisDt.kanrikekkagaku);
  erl(
    outputRec, GETSUGAKU_TYOUSEI, 
    ichiwari2 < thisDt.priceLimit ? ichiwari2 : thisDt.priceLimit
  );

  erl(outputRec, TYOUSEIGO_USER_BILLED, ''); // 福祉ソフトで未設定項目？
  // 福祉ソフトで0出力を''にしているがゼロでもいいんじゃね？
  // この項目は上限管理が存在しなければ設定しない
  erl(
    outputRec,
    JOUGEN_USER_BILLED, thisDt.kanriKekka ? thisDt.kanrikekkagaku : ''
  )
  erl(outputRec, KETTEI_USER_BILLED, thisDt.kanrikekkagaku);
  erl(
    outputRec, 
    KETTEI_TOTAL_BILLED, thisDt.userSanteiTotal - thisDt.kanrikekkagaku
  );
  erl(outputRec, KOUGAKU_KYUUFU, '');
  erl(outputRec, TOKUBETSU_TAISAKU_K122, '');
  erl(outputRec, JICHITAI_JOSEI, zero2blank(thisDt.jichiJosei));
}

// 契約情報レコード
const billingCsvK122_5 = (outputRec, masterRec, thisDt) => {
  // 2, REC_NO, 'K122', 5, THIS_MONTH, SCITY_NO, JI_NO, H_NO,
  // KETTEI_SRVC_CODE, // 決定サービスコード
  // KEIYAKU_VOL, // 契約量*100
  // KEIYAKU_DATE, // 契約日
  // KEIYAKU_END, // 契約終了日
  // KINYUU_BANGOU, // 事業者記入欄番号 
  outputRec.push([...keiyakuK122_5]);
  erl(outputRec, THIS_MONTH, masterRec.thisMonth);
  erl(outputRec, SCITY_NO, thisDt.scityNo);
  erl(outputRec, JI_NO, masterRec.jino);
  erl(outputRec, H_NO, thisDt.hno);
  erl(outputRec, KETTEI_SRVC_CODE, thisDt.ketteiScode);
  erl(outputRec, KEIYAKU_VOL, parseInt(thisDt.volume) * 100);
  erl(outputRec, KEIYAKU_DATE, thisDt.keiyakuDate);
  erl(outputRec, KEIYAKU_END, thisDt.endDate);
  erl(outputRec, KINYUU_BANGOU, thisDt.kinyuuBangou);
}

// k122 明細レコード サービスコードを記載
const billingCsvK122_3 = (outputRec, masterRec, thisDt) => {
  // 2, REC_NO, 'K122', 3, THIS_MONTH, SCITY_NO, JI_NO, H_NO, 
  // SRVC_CODE, SRVC_TANNI, SRVC_COUNT, SRVC_SANTEI
  
  if (!thisDt.itemTotal) return false; // 利用実績がない場合
  // ソートしておく -> あらかじめソートしてあるのでここでは不要
  // thisDt.itemTotal.sort((a, b)=>(a.s > b.s)? 1: -1);

  // itemTotalをなめる
  thisDt.itemTotal.map(e=>{
    outputRec.push([...serviceMeisaiK122_3]);
    erl(outputRec, THIS_MONTH, masterRec.thisMonth);
    erl(outputRec, SCITY_NO, thisDt.scityNo);
    erl(outputRec, JI_NO, masterRec.jino);
    erl(outputRec, H_NO, thisDt.hno);
    erl(outputRec, SRVC_CODE, e.s);
    erl(outputRec, SRVC_TANNI, e.v);
    erl(outputRec, SRVC_COUNT, e.count);
    erl(outputRec, SRVC_SANTEI, e.tanniNum);
  })
}

// k122日数情報レコード
const billingCsvK122_2 = (outputRec, masterRec, dt) => {
  // 2, REC_NO, 'K122', 2, THIS_MONTH, SCITY_NO, JI_NO, 
  // H_NO, S_SYUBETSU, START_DATE, END_DATE, CNT_USED,
  outputRec.push([...nissuuK122_2]);
  erl(outputRec, THIS_MONTH, masterRec.thisMonth);
  erl(outputRec, SCITY_NO, dt.scityNo);
  erl(outputRec, JI_NO, masterRec.jino);
  erl(outputRec, H_NO, dt.hno);
  erl(outputRec, S_SYUBETSU, dt.serviceSyubetu);
  erl(outputRec, START_DATE, dt.startDate);
  erl(outputRec, END_DATE, dt.endDate);
  // 2022/02/08 利用回数0で算定額がある場合、利用回数を1にする。
  // 2022/03/14 やっぱり今まで通りにする
  // const countOfUse = (!dt.countOfUse && dt.userSanteiTotal)? 1: dt.countOfUse;
  erl(outputRec, CNT_USED, dt.countOfUse);
}

// k122_1 基本レコード
const billingCsvK122_1 = (outputRec, masterRec, dt, kdj) =>{
  // 2, REC_NO, 'K122', 1, THIS_MONTH, SCITY_NO, JI_NO, H_NO, '',
  // PNAME, NAME,
  // CHIKI_CODE, '', JOUGEN, '', '', JOUGEN_JI, JOUGEN_KEKKA, JOUGEN_RES,
  // '', '', USER_TANNI, TOTAL_AMOUNT,
  // JOUGEN_GETSU_TYOUSEI, // 上限月額調整額
  // '', '', '',
  // JOUGEN_TYOUSEIGO, // 上限調整後金額 
  // JOUGEN_KETTEI, // 上限決定後
  // TOTAL_BILLED
  // 上限月額調整 計算用
  // const ichiwari = Math.floor(parseInt(dt.userSanteiTotal) * 0.1);
  // const getsuTyousei = Math.min(ichiwari, dt.priceLimit);
  
  // 横浜で協力事業所で管理結果が未登録の場合は横浜兄弟と判定
  // priceLimitBk を設定
  const yokohama = isYokohama(dt.scityNo);
  const yokohamaKd = (
    yokohama && !dt.kanriKekka && dt.kanriType === '協力事業所'
  );
  if (yokohamaKd){
    dt.priceLimitBk = dt.priceLimit;
    // dt.priceLimit = 0;
    dt.priceLimit = dt.kanrikekkagaku;
    kdj = true; // 兄弟上限フラグも強制オン
  }

  outputRec.push([...kihonK122_1]);
  erl(outputRec, THIS_MONTH, masterRec.thisMonth);
  erl(outputRec, SCITY_NO, dt.scityNo);
  erl(outputRec, JI_NO, masterRec.jino);
  erl(outputRec, H_NO, dt.hno);
  erl(outputRec, PNAME, dt.pkana);
  erl(outputRec, NAME, dt.kana);
  erl(outputRec, CHIKI_CODE, masterRec.chiikiKubun); // 地域区分コード
  // erl(outputRec, JOUGEN, dt.priceLimit);
  // 横浜上限では一時的に上限管理学をゼロにしている。その場合、*bkを作成している
  // *bkがあったら bkを出力 -> bkは出力しない
  erl(
    outputRec, JOUGEN, dt.priceLimit
  );
  // 横浜上限では管理事業所出力も抑制する
  erl(outputRec, JOUGEN_JI, dt.priceLimitBk? '' :dt.jougenJi); // 上限管理事業所
  erl(outputRec, USER_TANNI, dt.tanniTotal); // 単位合計
  erl(outputRec, TOTAL_AMOUNT, dt.userSanteiTotal); // 請求額合計
  // レコードとしては存在しない値？？
  // 国保連に嘘つかれた。月額調整額は兄弟上限のときのみ、1割または上限額の小さい方
  // 2022/06/06
  // getsuTyousei の値の有無だけでは兄弟上限判定を間違うことがある
  // 引数kdjを見る
  // 明示的にゼロ入力
  let jougenGetsuTyousei = kdj? dt.getsuTyousei: dt.tyouseigaku;
  // 兄弟上限でなおかつ、兄弟であれば設定されるべきgetsuTyouseiが未設定の場合
  // =>これは横浜兄弟の子供の場合に発生しうる
  // 決定額を上限額に入れる
  // 同じコードが通所明細にもあるので変更するときは要注意
  jougenGetsuTyousei = (jougenGetsuTyousei === undefined && kdj)?
  dt.kanrikekkagaku: jougenGetsuTyousei;
  jougenGetsuTyousei = jougenGetsuTyousei? jougenGetsuTyousei: 0;
  erl(
    outputRec, JOUGEN_GETSU_TYOUSEI, 
    jougenGetsuTyousei
  );

  // erl(outputRec, JOUGEN_GETSU_TYOUSEI, getsuTyousei); // 上限月額調整
  // null->0を追加
  erl(outputRec, JOUGEN_KEKKA, dt.kanriKekka? dt.kanriKekka: 0); // 管理結果フラグ
  // 上限管理結果が0（上限管理を行っていない）場合は出力しない
  // erl(outputRec, JOUGEN_RES, ((dt.kanriKekka) ? dt.kanrikekkagaku: ''));
  // 意図的に0出力が必要？ 2022/06/08 -> ''に戻す
  erl(outputRec, JOUGEN_RES, ((dt.kanriKekka) ? dt.kanrikekkagaku: ''));
  // 調整ご利用者負担も同じ処理
  // 意図的に0出力が必要？ 2022/06/08 -> ''に戻す
  erl(outputRec, JOUGEN_TYOUSEIGO,((dt.kanriKekka) ? dt.kanrikekkagaku : ''));
  erl(outputRec, JOUGEN_KETTEI, dt.kanrikekkagaku); // 上限決定額
  // 請求額
  erl(outputRec, TOTAL_BILLED, dt.userSanteiTotal - dt.kanrikekkagaku);
  // 助成自治体番号
  erl(outputRec, JOSEIJICHITAI, dt.joseiNo ? dt.joseiNo: '');
  erl(outputRec, KOUGAKU_KYUUFU, '');
  erl(outputRec, TOKUBETSU_TAISAKU_K122, '');
  erl(outputRec, JICHITAI_JOSEI_SEIKYUU, zero2blank(dt.jichiJosei));
  if (isNaN(dt.kanrikekkagaku)){
    console.log('!!!!!!!!detect NaN!!!!!!!!');
    console.log('dt.kanrikekkagaku', dt.kanrikekkagaku);
    console.log('dt.name', dt.name);
  }
  // // 上限管理の結果の金額。
  // const kanriKekkaPrice = (dt.kanrikekka) ? dt.adjust2 : '';
  // erl(outputRec, JOUGEN_RES, kanriKekkaPrice); // 上限管理結果額
  // // 上限管理がされていたら上限管理前の金額。上限管理されていたら上限管理後の金額
  // const jougenGetuTyousei = (dt.kanrikekka) ? dt.adjust2 : dt.adjust1;
  // erl(outputRec, JOUGEN_GETSU_TYOUSEI, jougenGetuTyousei);
  // // 最終結果。月額管理前の金額と管理後の金額安い方 でいいのか？
  // const jougenFinal = (dt.adjust1 < dt.adjust2) ? dt.adjust1 : dt.adjust2
  // erl(outputRec, JOUGEN_KETTEI, jougenFinal);
  // // 保険給付金 総額からユーザー負担を引く
  // erl(outputRec, TOTAL_BILLED, dt.userSanteiTotal - jougenFinal);
}
// 特定の市区町村に対してk122の出力を行う。
// サービス種別二種類に対してそれぞれの出力を行う
const billingCsvUsers = (
    outputRec, billingDt, masterRec, scityNo, users, schedule
  ) =>{
  //    ユーザーヘッダ
  //      K122_1
  // サービスヘッダ
  //      K112_2
  //        サービス詳細
  //        k122_3
  //    ユーザーフッタ
  //      k122_4
  //      k122_5
  // const totalized = e; // 集計業を定義
  // billingDt配列から対象となるサービスを抽出
  [61, 63].map(e=>{
    const target = billingDt.filter(f => (
      (f.serviceSyubetu === e) &&
      (f.scityNo === scityNo)
    ));
    // 対象となる各ユーザーを処理
    target.map(f=>{
      const kdj = isKyoudaiJougen(billingDt, users, f.UID, schedule);
      billingCsvK122_1(outputRec, masterRec, f, kdj);
      billingCsvK122_2(outputRec, masterRec, f);
      billingCsvK122_3(outputRec, masterRec, f);
      billingCsvK122_4(outputRec, masterRec, f);
      billingCsvK122_5(outputRec, masterRec, f);
    });
  })
}

const billingCsvScity = (outputRec, billingDt, masterRec, users, schedule) =>{
  // 市区町村ヘッダ
  //    K112_1
  // サービスヘッダ
  //    K112_2
  //    ユーザーヘッダ
  //      K122_1
  //      K122_2
  //        サービス詳細
  //        k122_3
  //    ユーザーフッタ
  //      k122_4
  //      k122_5
  // マスターレコードの集計データが格納されている配列をなめる
  // 自発が入ってくるとここの構造が変わると思われる -> 2021/10/11 変更中
  
  // ユニークな市区町村番号を得る
  const scitySet = new Set(masterRec.totalized.map(e=>e.scityNo));
  Array.from(scitySet).map(e=>{
    billingCsvK112_1(outputRec, masterRec, e);
    billingCsvK112_2(outputRec, masterRec, e);
    billingCsvUsers(outputRec, billingDt, masterRec, e, users, schedule);
  })
}
// UserSelectDialog で作成された配列で指定された文字列形式UIDが存在するか
const checkUidFromList = (ary, UID) => {
  if (!ary.length)  return true; // 配列が存在しなければtrue
  const a = ary.filter(e=>e.checked).map(e=>'UID' + e.uid);
  if (a.indexOf(UID) > -1)  return true;
  else return false;
}

// setBillInfoToSchで作成されたオブジェクトを元にcsvのベースになる配列を作成する
// 情報は整理する意味でも渡された引数から取得する
// 足りなきゃ何処かにセットする！
export const makeBiling = (billingDt, masterRec, schedule, users)=>{
  // ヘッダレコード出力 K11
  // 市区町村ヘッダ
  //    K112_1
  // サービスヘッダ
  //    K112_2
  //    ユーザーヘッダ
  //      K122_1
  //      K122_2
  //        サービス詳細
  //        k122_3
  //    ユーザーフッタ
  //      k122_4
  //      k122_5
  // エンドレコード出力
  const outputRec = [];
  csvHaed(outputRec, masterRec, 'K11');
  billingCsvScity(outputRec, billingDt, masterRec, users, schedule);
  outputRec.push([...endRecord]); // エンドレコード挿入
  outputRec.map((e, i)=>{
    elmRep(e, REC_NO, i + 1);
  });
  elmRep(outputRec[0], REC_CNT, outputRec.length - 2); // レコード件数記載
  console.log('outputRec', outputRec);
  return outputRec;
}



// 兄弟管理を行った場合、csv出力を行わない 20210613追加
const makeJougenOneUser = (thisUser, masterRec, outputRec, jougenKubun) =>{
  // 2022/05/27
  // 単純にbrosindexを見るのではな兄弟上限を行ったフラグを見てデータ作成判断
  // if (parseInt(thisUser.brosIndex)) return false;
  if (thisUser.kyoudaiJougen) return false;
  outputRec.push([...jgKihonK411_1]);
  // k411_1
  // 2, REC_NO, 'K411', 1, THIS_MONTH, SAKUSEI_KU, SCITY_NO,
  // JI_NO, H_NO, PNAME, NAME,
  // JOUGEN, JOUGEN_KEKKA, ALL_AMOUNT, ALL_TYOUSEI, ALL_JOUGEN,
  // const jgMeisaiK411_2 = [ // 上限管理明細
  //   2, REC_NO, 'K411', 2, THIS_MONTH, SCITY_NO, JI_NO, H_NO, LINE_NO,
  //   KYO_JI, TOTAL_AMOUNT, JOUGEN_TYOUSEIGO, JOUGEN_KETTEI
  // ]

  erl(outputRec, THIS_MONTH, masterRec.thisMonth);
  erl(outputRec, SCITY_NO, thisUser.scityNo);
  erl(outputRec, JI_NO, masterRec.jino);
  erl(outputRec, H_NO, thisUser.hno);
  erl(outputRec, PNAME, thisUser.pkana);
  erl(outputRec, NAME, thisUser.kana);
  erl(outputRec, JOUGEN, thisUser.priceLimit);
  erl(outputRec, JOUGEN_KEKKA, thisUser.kanriKekka);
  erl(outputRec, SAKUSEI_KU, jougenKubun);  // 今のところ常に１

  const jiGrp = thisUser.協力事業所;
  let allAmount = 0;
  let allTyousei = 0;
  let allJougen = 0;
  jiGrp.map(e=>{
    allAmount += e.amount;
    allTyousei += e.tyouseiGaku;
    allJougen += e.kettei;
  });
  erl(outputRec, ALL_AMOUNT, allAmount);
  erl(outputRec, ALL_TYOUSEI, allTyousei);
  erl(outputRec, ALL_JOUGEN, allJougen);
  // 上限管理明細を作成
  let ln = 1; // 行番号
  const makeJougenMeisai = (o)=>{
    outputRec.push([...jgMeisaiK411_2]);
    erl(outputRec, THIS_MONTH, masterRec.thisMonth);
    erl(outputRec, SCITY_NO, thisUser.scityNo);
    erl(outputRec, JI_NO, masterRec.jino);
    erl(outputRec, H_NO, thisUser.hno);
    erl(outputRec, LINE_NO, ln++);
    // 自社のデータなら自社の事業所番号、他社なら他社の
    const jno = (o.name === 'thisOffice') ? masterRec.jino : o.no;
    erl(outputRec, KYO_JI, jno);
    erl(outputRec, TOTAL_AMOUNT, o.amount);
    erl(outputRec, JOUGEN_TYOUSEIGO, o.tyouseiGaku);
    erl(outputRec, JOUGEN_KETTEI, o.kettei);
  }
  // 自社を最初に処理する
  const thisOffice = jiGrp.find(e=>e.name==='thisOffice');
  makeJougenMeisai(thisOffice);
  jiGrp.map(e=>{
    if (e.name === 'thisOffice')  return false; // 自社は処理済。スキップ
    makeJougenMeisai(e);
  });
}

// 兄弟上限かどうかを判定する
// 兄弟間で二人以上が利用か管理事業所として他社の利用があった場合は
// 兄弟管理であると判断する
export const isKyoudaiJougen = (billingDt, users, uid, schedule ) => {
  // billingDt が schTmpでも扱えるようにする
  if (!Array.isArray(billingDt)){
    const t = Object.keys(billingDt).map(e=>{
      return {...billingDt[e], UID: e}
    });
    billingDt = [...t];
  }
  const bros = comMod.getBrothers(uid, users, true); // 自己を含めたuid配列を求める
  const u = comMod.getUser(uid, users);
  let cnt = 0; // 算定か他社利用が存在するかどうか
  bros.forEach(e=>{
    const uid  = (e.uid.indexOf('UID') === 0)? e.uid: 'UID' + e.uid;
    const o = billingDt.find(f=>f.UID === uid);
    if (o && o.userSanteiTotal){
      cnt++;
      return false;
    }
    const sch = schedule[uid];
    const ky = (sch && Array.isArray(sch.協力事業所))? sch.協力事業所: [];
    ky.forEach(e=>{
      if (parseInt(e.amount)) cnt++;
    });
  });
  if (cnt > 1){
    // console.log(u.name, cnt, 'iskyoudaijougen');
    return true;
  }
  else{
    return false;
  }
}

// 上限管理イメージを作成する
export const makeJugenkanri = (
    billingDt, masterRec, jougenKubun, users, schedule
  ) =>{
  const outputRec = [];
  csvHaed(outputRec, masterRec, 'K41');
  Object.keys(billingDt).map(e=>{
    const thisUser = billingDt[e];
    const isKd = isKyoudaiJougen(billingDt, users, billingDt[e].UID, schedule);
    // 管理事業所で管理結果が0以上なら上限管理データ作成
    if (thisUser.kanriKekka > 0 && thisUser.kanriType === '管理事業所' && !isKd)  
      makeJougenOneUser(thisUser, masterRec, outputRec, jougenKubun, users)
  });
  outputRec.push([...endRecord]); // エンドレコード挿入
  outputRec.map((e, i) => {
    elmRep(e, REC_NO, i + 1);
  });
  elmRep(outputRec[0], REC_CNT, outputRec.length - 2); // レコード件数記載
  // 2022/02/18 データ件数が0だったら空の配列を返す
  if (outputRec.length > 2){
    return outputRec;
  }
  else{
    return [];
  }
}

// 提供実績イメージ作成
// 提供実績はカラム数がムダに多いのでカラム位置を定義して配列を扱う
// カラムの位置はCSVの仕様書と合わせるためレコードの作製してから
// 先頭に１カラム挿入する。
// 共用
const C_YEAR_MOMTH = 3; //年月
const C_CITY = 4; //市区
const C_JI = 5; //事業所番号
const C_HNO = 6;  //保険番号
const C_YOUSHIKI = 7; //様式
// 明細レコード
const C_DATE = 9;  //日付
const C_START_TIME = 14;  //開始時刻
const C_END_TIME = 15;  //終了時刻
const C_PICKUP = 21;  //送迎往路
const C_SEND = 22;  //送迎復路
const C_TEIKYOUKEITAI = 34; //提供形態
const C_TEIKYOUJOUKYOU = 36;  //提供状況
const C_KAREN_JIKAN = 23; // 家庭連携 時間数 1.5h -> 0150
const C_KAREN_SANTEI = 24; // 家庭連携算定 
const C_IRYOURENKEI = 74; // 医療連携体制加算
const C_SOUDANSHIEN = 78; // 相談支援加算
// 基本レコード
const C_CNT_SOUGEI = 34;//送迎回数片道
const C_CNT_KATEIREN = 35;//家庭連携回数
const C_CNT_KATEIREN_SAN = 36;//家庭連携算定回数
const C_CNT_HOUMON = 51;//訪問支援特別
const C_CNT_HOUMON_SAN = 52;//訪問支援特別加算算定:
const C_CNT_IRYOU = 117;//医療連携体制加算
const C_SOUDAN = 121;//事業所内相談支援回数:
const C_SYOKUJI = 45;//食事支援回数:

// 利用実績データ1ユーザー分
const makeTeikyouJissekiOneUser = (masterRec, thisUser, outputRec) =>{
  // 最終レコードを書き換えるだけ
  const er = (target, value)=>{
    outputRec[outputRec.length - 1][target] = value;
  }
  // 利用回数の存在しないレコードも発生する 利用無しで上限管理ありの場合。
  // その場合、レコードを出力しない
  // 変更 2022/03/14 欠席のみの場合は出力する
  if (!thisUser.countOfUse && !thisUser.countOfKesseki){
    // outputRec.push([...kihonK611_1]);
    return false;
  }
  // 欠席でも計上するサービスコード
  const kessekiSvc = [
    ...KESSEKI_SVC_CODE, ...SOUDANSIEN_SVC_CODE, ...KATEI_SVC_CODE
  ];


  // console.log(thisUser.countOfUse, 'thisUser.countOfUse');
  // 基本情報レコード
  const kihonK611_1 = Array(155).fill('');
  kihonK611_1[0] = 'REC_NO';
  kihonK611_1[1] = 'K611';
  kihonK611_1[2] = '01';
  outputRec.push([...kihonK611_1]);

  er(C_YEAR_MOMTH, masterRec.thisMonth);
  er(C_CITY, thisUser.scityNo);
  er(C_JI, masterRec.jino);
  er(C_HNO, thisUser.hno);
  er(C_YOUSHIKI, (thisUser.service==='放課後等デイサービス')? '0501': '0301');
  //送迎回数片道
  let sougeiCnt = 0;
  thisUser.itemTotal.filter(e=>SOUGEY_SVC_CODE.indexOf(e.s) > -1)
  .map(e=>{sougeiCnt += e.count});
  er(C_CNT_SOUGEI, sougeiCnt);
  //家庭連携回数 算定回数？
  let kateiRenCnt = 0;
  let kateiRenSantei = 0;
  thisUser.itemTotal.filter(e => KATEI_SVC_CODE.indexOf(e.s) > -1)
  .map(e => {
    kateiRenCnt += e.count;
    // 算定は制限回数を超えない範囲で
    kateiRenSantei = (kateiRenCnt > e.limit) ? e.limit : kateiRenCnt;
  });
  er(C_CNT_KATEIREN, kateiRenSantei);
  er(C_CNT_KATEIREN_SAN, kateiRenSantei);
  // 訪問支援回数
  let houmonCnt = 0;
  let houmonSan = 0;
  // thisUser.itemTotal.filter(e => HOUMON_SVC_CODE.indexOf(e.s) > -1)
  // .map(e => {
  //   houmonCnt += e.count;
  //   // 制限回数チェックして設定
  //   houmonSan = (houmonSan > e.limit) ? e.limit : houmonSan;
  // });
  er(C_CNT_HOUMON, houmonCnt);        //訪問支援特別
  er(C_CNT_HOUMON_SAN, houmonSan);        //訪問支援特別算定
  // 医療連携体制加算回数
  let iryourenkeiCnt = 0;
  thisUser.itemTotal.filter(e => IREN_SVC_CODE.indexOf(e.s) > -1)
  .map(e => { iryourenkeiCnt += e.count });
  er(C_CNT_IRYOU, iryourenkeiCnt);         //医療連携体制加算
  
  // 事業所内相談支援回数
  let soudanCnt = 0;
  thisUser.itemTotal.filter(e => SOUDANSIEN_SVC_CODE.indexOf(e.s) > -1)
  .map(e => { soudanCnt += e.count });
  er(C_SOUDAN, soudanCnt);         //事業所内相談支援回数
  
  // 食事提供
  const syokujiCnt = thisUser.itemTotal
  .filter(e=>SYOKUJI_SVC_CODE.indexOf(e.s) > -1)
  .reduce((v, e) => (v + e.count), 0);
  er(C_SYOKUJI, syokujiCnt? syokujiCnt: '');

  // 処理終了後、最初のカラムに2を挿入。
  outputRec[outputRec.length - 1].unshift(2);

  // 明細情報レコード
  const maisaiK611_2 = Array(97).fill('');
  // 共通項目は予めテンプレにセットしておく
  maisaiK611_2[0] = 'REC_NO';
  maisaiK611_2[1] = 'K611';
  maisaiK611_2[2] = '02';
  maisaiK611_2[C_YEAR_MOMTH] = masterRec.thisMonth;
  maisaiK611_2[C_CITY] = thisUser.scityNo;
  maisaiK611_2[C_JI] = masterRec.jino;
  maisaiK611_2[C_HNO] = thisUser.hno;
  maisaiK611_2[C_YOUSHIKI] = (thisUser.service === '放課後等デイサービス')
  ? '0501' : '0301';
  // 日付オブジェクトのキーを抽出してソートしておく。
  // オブジェクトの検出順が変わることがあるため
  const daysUsed = Object.keys(thisUser)
    .filter(e => e.match(/^D2[0-9]/))
    .sort((a, b) => (a > b) ? 1 : -1);
  // userオブジェクトから日付オブジェクトを抽出して舐める
  daysUsed.map(e=>{
    const o = thisUser[e];
    // 欠席加算の検出
    const kesseki = o.items.find(e => kessekiSvc.indexOf(e.s) > -1);
    // 欠席加算のない「欠席」はスキップ
    if (!kesseki && o.absence)  return false;
    // 欠席対応加算があるか
    const kessekiKasan = o.items.find(e=>KESSEKI_SVC_CODE.indexOf(e.s) > -1);
    // 家庭連携加算あるか
    const karenKasan = o.items.find(e=>KATEI_SVC_CODE.indexOf(e.s) > -1);
    // 相談支援加算あるか
    const soudanKasan = o.items.find(e=>SOUDANSIEN_SVC_CODE.indexOf(e.s) > -1);
    // 医療連携あるか
    const iryouRenkei = o.items.find(e=>IREN_SVC_CODE.indexOf(e.s) > -1);

    outputRec.push([...maisaiK611_2]);
    // 欠席対応加算だけなら8を設定
    er(C_TEIKYOUJOUKYOU, (kessekiKasan && !karenKasan && !soudanKasan)? 8 : '');
    er(C_DATE, parseInt(e.substring(7, 9)));// 日付の日にちだけ キーの下二桁

    // 家庭連携加算実績記述用に分数を取得 明細書き込み
    if (karenKasan){
      const min = parseInt(thisUser[e].dAddiction.家庭連携加算);
      // 90分を 0150 に変換
      const j = comMod.zp(Math.floor(min / 60), 2);
      const m = comMod.zp(Math.round(min / 60 * 100), 2);
      er(C_KAREN_JIKAN, j + m);
      // 算定時間？を設定 60分未満は1 それ以上は 2
      const karenSantei = (min < 60)? 1: 2;
      er(C_KAREN_SANTEI, karenSantei);
    }
    // 欠席の場合、最初のカラム[2]を挿入して終了
    if (kessekiKasan){
      outputRec[outputRec.length - 1].unshift(2);
      return false;
    }
    if (!o.start){
      console.log(thisUser.name, e, 'name date');
    }
    er(C_START_TIME, o.start? o.start.replace(':', ''): '');//開始時刻
    er(C_END_TIME, o.end? o.end.replace(':', ''): '');//終了時刻
    // 送迎ありなし。配列の中身が空白なら送迎なし
    er(C_PICKUP, (o.transfer[0]) ? 1 : '');
    er(C_SEND, (o.transfer[1]) ? 1 : '');
    // er(C_TEIKYOUKEITAI, 1); // 利用実績があれば1
    let teikyoukeitai = '';
    // 児発は無しで良いんだっけ？
    if (thisUser.service === '放課後等デイサービス'){
      teikyoukeitai = o.offSchool? 2 : 1;
    }
    // 家庭連携加算のときは空白になるらしい
    if (karenKasan){
      teikyoukeitai = '';
    }
    if (o.absence)  teikyoukeitai = ''; // 欠席時は出力しない
    er(C_TEIKYOUKEITAI, teikyoukeitai); // 平日=1、休日=2
    // 医療連携加算
    if (iryouRenkei){
      er(C_IRYOURENKEI, iryouRenkei.iryouRenkeiNum)
    }
    // 相談支援加算出力 2022/06/07追加
    if (soudanKasan){
      er(C_SOUDANSHIEN, '1')
    }
    
    // 明細レコードは処理終了後、最初のカラムに2を挿入。
    outputRec[outputRec.length - 1].unshift(2);
  });
}

export const makeTeikyouJisseki = (billingDt, masterRec) =>{
  const outputRec = [];
  // ヘッダの作成
  csvHaed(outputRec, masterRec, 'K61');
  Object.keys(billingDt).map(e=>{
    const thisUser = billingDt[e];
    makeTeikyouJissekiOneUser(masterRec, thisUser, outputRec);
  });
  outputRec.push([...endRecord]); // エンドレコード挿入
  outputRec.map((e, i) => {
    elmRep(e, REC_NO, i + 1);
  });
  elmRep(outputRec[0], REC_CNT, outputRec.length - 2); // レコード件数記載
  // console.log('outputRec stringify', JSON.stringify(outputRec));
  return outputRec;
}
